import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Anchor, Card, Icon } from 'troop-design';
import { Div } from 'basedesign-iswad';

import { getBusinessNewsFeed } from 'services/businesses';

import styles from './BusinessNewsFeed.module.scss';

const BusinessNewsFeed = () => {
	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;

	const [customNewsFeed, setCustomNewsFeed] = useState([]);
	const [newsFeed, setNewsFeed] = useState([]);

	const getIcon = useCallback(newsType => {
		switch (newsType) {
			case 'voted':
				return 'vote';
			case 'vote_streak':
				return 'vote';
			case 'registered':
				return 'dashboard-employee';
			case 'pledged':
				return 'pledge';
			default:
				return 'vote';
		}
	}, []);

	useEffect(() => {
		if (businessId) {
			getBusinessNewsFeed(businessId).then(data => {
				setCustomNewsFeed(
					data.filter(element => element.news_type === 'news' && element)
				);
				setNewsFeed(
					data.filter(element => element.news_type !== 'news' && element)
				);
			});
		}
	}, [businessId]);

	return (
		<div className={styles.scrollContainer}>
			{/* <Div className="card br-rad-px-5 mb2 min-height-px-100 of-hidden">
				<Div
					type="flex"
					hAlign="center"
					vAlign="center"
					className={cx('p1 textWhite f-b', styles.VoteCloseDateHeader)}>
					{' '}
					Vote Close Date :
				</Div>
				<Div
					type="flex"
					hAlign="center"
					vAlign="center"
					className="fs-px-12 p1 w-per-100">
					<Div className="pl3 of-hidden">
						Want to help fulfill these needs? You can {'\n'}personally pledge to
						help fulfill them too!
					</Div>
				</Div>
			</Div> */}
			{customNewsFeed.map(data => (
				<Div className={cx('card br-rad-px-5 mb1', styles.customNewsCard)}>
					<div className={styles.customNewsItemContainer}>
						<img className={styles.customNewsImage} src={data.image_url} />
						<h3>{data.heading}</h3>
						<p className={cx('textFaded fs-px-12', styles.customNewsMessage)}>
							{data.message}
						</p>
						<Anchor
							to={data.cta_link}
							target="_blank"
							className={cx('textPrimary', styles.learnMore)}>
							<span>{data.cta_text}</span>
							{/* <Icon icon="external-site-link" color="icon-gray" width={25} /> */}
						</Anchor>
					</div>
				</Div>
			))}
			{newsFeed.map(data => (
				<Div className="card p2 mb1 br-rad-px-5">
					<div className={cx('textFaded fs-px-10', styles.newsItemContainer)}>
						<div>{data.employee + ': ' + data.message}</div>
						{/* <Icon icon={getIcon(data.news_type)} color="orange" width={50} /> */}
					</div>
				</Div>
			))}
		</div>
	);
};

export default BusinessNewsFeed;
