import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['showComponent'] = (state, action) => {
	state['showComponent'] = true;
	return state;
};
reducerObject['hideComponent'] = (state, action) => {
	state['showComponent'] = false;
	return state;
};
reducerObject['setVotedFromBusiness'] = (state, action) => {
	state['votedFromBusiness'] = action.payload;
	return state;
};
reducerObject['setChosenNeed'] = (state, action) => {
	state['chosenNeed'] = action.payload;
	return state;
};
reducerObject['clearChosenNeed'] = (state, action) => {
	state['chosenNeed'] = {};
	return state;
};

const slice = createSlice({
	name: 'voteCelebration',
	initialState: { showComponent: false, chosenNeed: {}, votedFromBusiness: {} },
	reducers: reducerObject
});

export const {
	showComponent,
	hideComponent,
	setChosenNeed,
	clearChosenNeed,
	setVotedFromBusiness
} = slice.actions;
export default slice.reducer;
