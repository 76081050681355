import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setUpcomingEventForPublicUser'] = (state, action) =>
	action.payload;
reducerObject['clearUpcomingEventForPublicUser'] = (state, action) => {};

const slice = createSlice({
	name: 'upcomingEventForPublicUser',
	initialState: {},
	reducers: reducerObject
});

export const {
	setUpcomingEventForPublicUser,
	clearUpcomingEventForPublicUser
} = slice.actions;
export default slice.reducer;
