export const My_PERSONAL_INFO = [
	{ iconType: 'envelope', value: 'mmmohajer70@gmail.com' },
	{ iconType: 'phone-fill', value: '+1(226)977-0855' },
	{ iconType: 'location-fill', value: 'Ottawa, ON, Canada' }
];

export const MENU_ITEMS = [
	{
		identifier: 'home',
		title: 'Home',
		to: '/',
		toWordpress: true,
		isLocal: false
	},
	{
		identifier: 'needs',
		title: 'Needs',
		to: '/needs',
		toWordpress: false,
		isLocal: false
	},
	{
		identifier: 'need_registration',
		title: 'Need Registration',
		to: '/charity-needs',
		toWordpress: true,
		isLocal: false
	},
	{
		identifier: 'charities',
		title: 'Charities',
		to: '/charity',
		toWordpress: true,
		isLocal: false
	},
	{
		identifier: 'blog',
		title: 'Blog',
		to: '/blog',
		toWordpress: true,
		isLocal: false
	},
	{
		identifier: 'press',
		title: 'Press',
		to: '/press',
		toWordpress: true,
		isLocal: false
	}
];

export const MENU_ITEMS2 = [
	{
		identifier: 'neighbour-account',
		title: 'Neighbour Account',
		to: '/my-account',
		toWordpress: true,
		isLocal: false
	},
	{
		identifier: 'about',
		title: 'About',
		to: '/about',
		toWordpress: true,
		isLocal: false
	},
	{
		identifier: 'contact',
		title: 'Contact',
		to: '/contact-us',
		toWordpress: true,
		isLocal: false
	},
	{
		identifier: 'faq',
		title: 'FAQ',
		to: '/faq',
		toWordpress: true,
		isLocal: false,
		isLocal: false
	},
	{
		identifier: 'terms',
		title: 'Terms',
		to: '/terms',
		toWordpress: true,
		isLocal: false
	},
	{
		identifier: 'privacy',
		title: 'Privacy',
		to: '/privacy',
		toWordpress: true,
		isLocal: false
	}
];

export const AUTO_SCROLL_BEHAVIOR = {
	behavior: 'smooth',
	block: 'start',
	inline: 'nearest',
	alignToTop: true
};

export const SERVICES = [
	{ title: 'Web App Development' },
	{ title: 'Mobile App Development' },
	{ title: 'API Development' },
	{ title: 'Data Analysis' },
	{ title: 'MVP Development' }
];
