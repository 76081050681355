import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Card, Icon } from 'troop-design';
import { Div, Input, Row, Column } from 'basedesign-iswad';
import styles from './VoteStreak.module.scss';

function VoteStreak() {
	const myProfile = useSelector(state => state.users.businessUserProfile);

	useEffect(() => {
		console.log(myProfile.vote_streak);
	}, [myProfile]);

	return (
		<>
			<Div
				type="flex"
				direction="vertical"
				className={cx(
					' card flex flex--ai--start py1 br-rad-px-5 flex--jc--center w-per-100',
					styles.mainContainer
				)}>
				<Row>
					<Column xs={12} sm={12} md={6} lg={6}>
						<Div className="flex flex--jc--center flex--ai--center fs-px-14 my1 br-right-solid-3 br-color-silver">
							{myProfile?.vote_streak > 0 && myProfile?.vote_streak <= 24 ? (
								<Icon icon={`badge-${myProfile?.vote_streak}`} width={150} />
							) : (
								''
							)}
							{myProfile?.vote_streak === 0 ? (
								<Icon icon={'badge-0'} width={150} />
							) : (
								''
							)}
							{myProfile?.vote_streak > 24 ? (
								<Icon icon={'badge-24'} width={150} />
							) : (
								''
							)}
						</Div>
					</Column>
					<Column xs={12} sm={12} md={6} lg={6}>
						<Div
							type="flex"
							hAlign="center"
							vAlign="start"
							direction="vertical"
							className={cx('w-per-100')}>
							<Div
								type="flex"
								hAlign="center"
								vAlign="start"
								className={cx('fs-px-14 mb3')}>
								Personal Vote %
							</Div>
							<Div className={cx('w-per-100')}>
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									className={cx(
										'w-per-100 textSilver height-px-60 fs-px-30 f-b'
									)}>
									{myProfile?.vote_percentage}%
								</Div>
							</Div>
						</Div>
					</Column>
				</Row>
			</Div>
		</>
	);
}

export default VoteStreak;
