import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Heading } from 'troop-design';
import { useDispatch, useSelector } from 'react-redux';
import FeatureFlagForm from './FeatureFlagForm';
import { getEmployees } from 'services/employees';
import { getBusinesses } from 'services/businesses';
import { getFeatureFlags } from 'services/featureFlags';

const AddFeatureFlag = () => {
	const dispatch = useDispatch();
	const params = useParams();
	const { employees } = useSelector(state => state.employees);
	const { businesses } = useSelector(state => state.businesses);
	const dataSet = useSelector(state => state.featureFlags.featureFlags);

	const id = params.id ? Number(params.id) : null;

	useEffect(() => {
		dispatch(getFeatureFlags());
	}, []);

	useEffect(() => {
		dispatch(getEmployees());
	}, []);

	useEffect(() => {
		dispatch(getBusinesses());
	}, []);

	const feature = useMemo(() => {
		if (id != null) {
			const index = dataSet.findIndex(featureFlag => featureFlag.id === id);
			return dataSet[index];
		}
		return {};
	}, [dataSet]);

	return (
		<div>
			<Heading level="1">Add Feature Flag</Heading>
			<FeatureFlagForm
				editMode={!!id}
				feature={feature}
				employees={employees}
				businesses={businesses}
			/>
		</div>
	);
};

export default AddFeatureFlag;
