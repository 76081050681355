import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Wrapper from '../components/Wrapper';

import { getNeedInfoRegisteredByCharity } from 'root/services/charity';

import PublishNeedForm from './subs/PublishNeedForm';
import styles from './PublishNeed.module.scss';

const PublishNeed = () => {
	const { id } = useParams();

	const [activeTab, setActiveTab] = useState('View Details');
	const [needDetails, setNeedDetails] = useState({});

	const getNeedInfoRegisteredByCharityData = async () => {
		const res = await getNeedInfoRegisteredByCharity(id);
		setNeedDetails(res);
	};

	useEffect(() => {
		if (id) {
			getNeedInfoRegisteredByCharityData();
		}
	}, [id]);

	return (
		<>
			<Wrapper pageName="Needs">
				<Div className="p2 w-per-100 max-w-px-500 ml-auto mr-auto bgWhite boxShadowType1">
					<PublishNeedForm needDetails={needDetails} />
				</Div>
			</Wrapper>
		</>
	);
};

export default PublishNeed;
