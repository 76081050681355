import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SHARE_A_CONTACT } from '../constants';
import {
	Col,
	Card,
	Form,
	Button,
	Text,
	Input,
	Icon,
	Heading
} from 'troop-design';
import Modal from 'root/components/Modal';
import { showModal } from 'root/reducers/modals';

export default function ShareAContact({ setFormData }) {
	const dispatch = useDispatch();

	const shareAContactPrompt = useCallback(
		e => {
			setFormData({
				email: e.leadEmail,
				leadCompany: e.leadCompany,
				leadName: e.leadName
			});
			dispatch(showModal('sharePrompt'));
		},
		[setFormData]
	);

	return (
		<Col md={8} className="d-flex">
			<Card className="px-md-5 py-4">
				<Text tag="p" hasWrapper>
					Or, give us the details and we’ll send a note for you. Don’t worry,
					you’ll get to see the message before we send it!
				</Text>
				<Form onSubmit={shareAContactPrompt}>
					<Input
						label="Business Name"
						name="leadCompany"
						validationRules={[
							{
								rule: 'string.isRequired',
								message:
									'Please enter the name of the business you would like to refer'
							}
						]}
					/>
					<Input
						label="Contact Name"
						name="leadName"
						validationRules={[
							{
								rule: 'string.isRequired',
								message:
									'Please enter a valid name of the person who owns the above business'
							}
						]}
					/>
					<Input
						label="Contact Email"
						name="leadEmail"
						validationRules={[
							{
								rule: 'string.isEmail',
								message: 'Please enter a valid email address'
							}
						]}
					/>
					<div className="flex py-2">
						<Button id={SHARE_A_CONTACT} className="mx-1" variant="dark">
							<Icon icon="contacts" size="md" className="me-2" color="white" />
							Send an Email for Me
						</Button>
					</div>
				</Form>
			</Card>
		</Col>
	);
}
