import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';

import { getAllCharities } from 'root/services/charity';

import styles from './NeedsToReview.module.scss';

function NeedsToReview() {
	const history = useHistory();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [charities, setCharities] = useState([]);

	const getAllCharitiesData = async () => {
		const res = await getAllCharities();
		const sortedRes = res.sort((a, b) => b.needs_to_review - a.needs_to_review);
		setCharities(sortedRes);
	};

	useEffect(() => {
		getAllCharitiesData();
	}, []);

	const headLines = [
		{
			value: 'name',
			display: 'Name',
			width: 400,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'number_of_needs_to_review',
			display: 'Number of Needs to Review',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'needs_to_review',
			display: 'Needs to Review',
			width: 200,
			hasSearch: false,
			isSortable: false
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		charities?.forEach(item => {
			tableData.push({
				name: `${item.name}`,
				number_of_needs_to_review: `${item.needs_to_review}`,
				needs_to_review: {
					value: '',
					display: (
						<Div
							onClick={() => history.push(`/needs-to-review/${item.id}`)}
							className={cx('mouse-hand', styles.link)}>
							View
						</Div>
					)
				}
			});
		});
		return tableData;
	}, [charities]);

	return (
		<>
			<Div>NeedsToReview!!!</Div>
			<Div display="flex" hAlign="center" className="mt2 w-per-90 of-x-auto">
				<Table
					headLines={headLines}
					data={data}
					// colWidth={400}
					// tableWidth={'1200px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={9}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
}

export default NeedsToReview;
