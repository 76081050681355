import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Div, Column, Row, Image } from 'basedesign-iswad';

import { getFeedback } from 'root/services/charity';
import { getBackendUrl } from 'root/utils/helpers';
import Icon from 'root/baseComponents/Icon';
import Button from 'root/baseComponents/Button/Button';
import StatsCard from 'root/baseComponents/StatsCard/StatsCard';
import NeedCard from 'root/baseComponents/NeedCard';
import Tooltip from 'root/baseComponents/Tooltip/Tooltip';
import { getSingleNeedInfoForCharityAdmin } from 'root/services/charity';
import CharityWrapper from '../../CharityWrapper/CharityWrapper';
import { setCharityInfo } from 'root/reducers/charityReducers/charityInfo';
import {
	updateNeedByCharityAdmin,
	deleteNeedByCharityAdmin
} from 'root/services/needs';

import GetConfirmationModal from 'root/baseComponents/GetConfirmationModal/GetConfirmationModal';

import Impact from './Impact';
import styles from '../Needs.module.scss';

function IndividualNeed() {
	const location = useLocation();
	const history = useHistory();
	const params = useParams();
	const { need_id } = params;

	// const charityInfo = useSelector(state => state.charityInfo);
	const [testimony, setTestimony] = useState();

	const [images, setImages] = useState([]);
	const [videos, setVideos] = useState([]);
	const [needInfo, setNeedInfo] = useState({});
	const [fulfilledNeedData, setFulfilledNeedData] = useState([]);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [confirmModalText, setConfirmModalText] = useState('');
	const [action, setAction] = useState({});

	const [charityData, setCharityData] = useState({});

	const getSingleNeedInfoForCharityAdminData = async () => {
		try {
			const res = await getSingleNeedInfoForCharityAdmin(need_id);
			console.log(res);
			if (res?.success) {
				setNeedInfo(res?.data?.need_details);
				setFulfilledNeedData(res?.data?.fulfilled_data);
				console.log(res);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getSingleNeedInfoForCharityAdminData();
	}, []);

	useEffect(() => {
		const localTestimonials = [];
		const localImages = [];
		const localVideos = [];
		fulfilledNeedData?.forEach(item => {
			if (item?.media_type === 'testimony') {
				setTestimony(item.testimony);
			} else if (item?.media_type === 'image') {
				localImages.push(item);
			} else {
				localVideos.push(item);
			}
		});
		setImages(localImages);
		setVideos(localVideos);
	}, [fulfilledNeedData]);

	useEffect(() => {
		console.log(needInfo);
	}, [needInfo]);

	return (
		<>
			<CharityWrapper>
				{showConfirmModal && (
					<GetConfirmationModal
						text={confirmModalText}
						setShowModal={setShowConfirmModal}
						confirmationHandler={async () => {
							if (action?.action === 'delete') {
								let res = await deleteNeedByCharityAdmin(action.needId);
								history.push('/');
							}
							if (action?.action === 'reactivate') {
								let res = await updateNeedByCharityAdmin(action.needId, {
									status: 'open'
								});
								history.push('/');
							}
							if (action?.action === 'pause') {
								let res = await updateNeedByCharityAdmin(action.needId, {
									status: 'paused'
								});
								history.push('/');
							}
						}}
					/>
				)}
				<Div className={cx(styles.main)}>
					{/* if need is not active charity can edit it --> edit button */}{' '}
					<Div type="flex" className={cx('mb2 pt1 w-per-100')}>
						<Div type="flex" vAlign="center" className="fs-px-18 ml1">
							Need Description
						</Div>
					</Div>
					<Div className="card p2 br-rad-px-5 m1">
						<Div
							className={cx('f-b mb2 fs-px-24 text-center', styles.needTitle)}>
							{needInfo?.name}
						</Div>
						<Row>
							<Column xs={12} sm={12} md={6} lg={6}>
								<Div>
									<Div type="flex" hAlign="start" className="p2">
										<Div
											type="flex"
											className={cx(
												'br-all-solid-hidden br-rad-px-5 of-hidden w-per-100 pr2 pl2',
												styles.needImage
											)}>
											<img
												src={needInfo?.image}
												className={cx('br-rad-px-5', styles.needImage)}
											/>
										</Div>
									</Div>
								</Div>
							</Column>
							<Column xs={12} sm={12} md={6} lg={6} className="pr2 pl2">
								<Div className="p2">
									<Div className="f-b fs-px-20 mb3 mt1 textGray text-center">
										{needInfo?.short_description}
									</Div>
									<Div className="pr2 pl2 fs-px-16 mb2 text-justify mb3">
										{needInfo?.business_description}
									</Div>

									<Row>
										<Column xsm={12} sm={12} md={6} lg={6}>
											<Div>
												<Div type="flex" hAlign="start" className="">
													<Div
														type="flex"
														vAlign="start"
														className="mb1 textGray">
														<Div type="flex" className="mr2 ml2">
															<Icon
																type="hand-dollar"
																color="#f5891f"
																scale={1.5}
															/>
														</Div>
													</Div>
													<Div className="">
														Donation: $
														{needInfo?.contribution_value || needInfo?.value}
													</Div>
												</Div>
												<Div type="flex" hAlign="start" className="mt1">
													<Div type="flex" className="mr2 ml2">
														<Icon
															type="calendar-day"
															color="#f5891f"
															scale={1.5}
														/>
													</Div>

													<Div className="">
														Submitted:{' '}
														{dayjs(needInfo?.created_at).format('MMM DD, YY')}
													</Div>
												</Div>
												{needInfo?.expiry_date && (
													<Div type="flex" hAlign="start" className="mt1">
														<Div type="flex" className="mr2 ml2">
															<Icon
																type="calendar-xmark"
																color="#f5891f"
																scale={1.5}
															/>
														</Div>

														<Div>
															Close date:{' '}
															{dayjs(needInfo?.expiry_date).format(
																'MMM DD, YY'
															)}
														</Div>
													</Div>
												)}

												{needInfo?.is_fulfilled && needInfo?.fulfilled_date ? (
													<Div type="flex" hAlign="start" className="mt1">
														<Div type="flex" className="mr2 ml2">
															<Icon
																type="calendar-day"
																color="#f5891f"
																scale={1.5}
															/>
														</Div>

														<Div>
															Fulfilled date:{' '}
															{dayjs(needInfo?.fulfilled_date).format(
																'MMM DD, YY'
															)}
														</Div>
													</Div>
												) : (
													''
												)}

												{needInfo?.created_by && (
													<Div
														type="flex"
														hAlign="start"
														className="mt1 oneLine">
														<Div type="flex" className="mr2 ml2">
															<Icon type="pencil" color="#f5891f" scale={1.5} />
														</Div>
														<Div>Submitted by: {needInfo.created_by}</Div>
													</Div>
												)}

												{/* {needInfo?.is_fulfilled && (
										<Div type="flex" vAlign="center" className="mb1 textGray">
											<Div type="flex" className="mr2 ml2">
												<Icon type="check" color="#f5891f" scale={1.5} />
											</Div>
											<Div type="flex" className="">
												Fulfilled by
											</Div>
											<Div type="flex" className={cx('ml1', styles.blurryText)}>
												'business partner name'
											</Div>
											<Div type="flex" className="mr2 ml2">
												<Icon type="lock" color="#f5891f" scale={1} />
											</Div>
										</Div>
									)} */}
											</Div>
										</Column>
										<Column xsm={12} sm={12} md={6} lg={6}>
											<Div
												type="flex"
												vAlign="end"
												hAlign="end"
												className="w-per-100 mr2 mt2 ">
												<Tooltip text="Copy" width={80}>
													<Div
														className="ml1 mouse-hand"
														onClick={() => {
															history.push({
																pathname: '/new-need',
																state: {
																	title: needInfo?.name,
																	value: needInfo?.value,
																	message:
																		needInfo?.business_description ||
																		needInfo?.description,
																	pageTitle: `${needInfo?.name} - duplicate`
																}
															});
														}}>
														{' '}
														<Icon type="copy" color="#f5891f" />
													</Div>
												</Tooltip>
												{needInfo?.status === 'paused' ||
												needInfo?.cur_status === 'Paused' ||
												needInfo?.cur_status === 'Closed' ? (
													<Tooltip text="Reactivate" width={80}>
														<Div
															className="ml1 mouse-hand"
															onClick={() => {
																setConfirmModalText(
																	`Are you sure you want to reactivate this need?`
																);
																setAction({
																	action: 'reactivate',
																	needId: needInfo.id
																});
																setShowConfirmModal(true);
															}}>
															{' '}
															<Icon type="refresh" color="#f5891f" />
														</Div>
													</Tooltip>
												) : (
													''
												)}

												{needInfo?.cur_status === 'Live' && (
													<Tooltip text="Pause" width={80}>
														<Div
															className="ml1 mouse-hand"
															onClick={() => {
																setConfirmModalText(
																	`Are you sure you want to pause this need?`
																);
																setAction({
																	action: 'pause',
																	needId: needInfo.id
																});
																setShowConfirmModal(true);
															}}>
															<Icon type="pause" color="#f5891f" />
														</Div>
													</Tooltip>
												)}

												{needInfo?.cur_status === 'Live' ||
												needInfo?.status === 'paused' ||
												needInfo?.cur_status === 'Paused' ||
												needInfo?.cur_status === 'Closed' ? (
													<Tooltip text="Delete" width={80}>
														<Div
															className="ml1 mouse-hand"
															onClick={() => {
																setConfirmModalText(
																	`Are you sure you want to delete this need?`
																);
																setAction({
																	action: 'delete',
																	needId: needInfo.id
																});
																setShowConfirmModal(true);
															}}>
															<Icon type="delete" color="#f5891f" />
														</Div>
													</Tooltip>
												) : (
													''
												)}
												{/* if need is fulfilled and the charity doesnt send thanks!  */}
												{needInfo?.cur_status === 'Waiting to be fulfilled' &&
												!needInfo?.feedback_sent_by_charity ? (
													<Div type="flex" hAlign="center" className="">
														<Button
															className="mr2 w-px-200 text-center"
															onClick={() =>
																history.push(`/send-feedback/${need_id}`)
															}>
															Send Thanks!
														</Button>
													</Div>
												) : (
													''
												)}
											</Div>
										</Column>
									</Row>
								</Div>{' '}
							</Column>
						</Row>
					</Div>
					{needInfo?.is_fulfilled && (
						<>
							<Div
								type="flex"
								className={cx('mb2 mt4 w-per-100')}
								distributedBetween>
								<Div type="flex" vAlign="center" className="fs-px-20 ml1">
									Your Feedback
								</Div>
							</Div>
							<Div className="card p2 br-rad-px-5 m1">
								<Impact fulfilledData={fulfilledNeedData} />
								{needInfo?.feedback_sent_by_charity && (
									<Div className="mt4">
										<Button
											onClick={() => history.push(`/send-feedback/${need_id}`)}>
											Update Feedback
										</Button>
									</Div>
								)}
							</Div>
						</>
					)}
				</Div>
			</CharityWrapper>
		</>
	);
}

export default IndividualNeed;
