// VENDOR imports
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// imports
import { getActiveMonths } from 'services/impactReports';

export default function useCurrentActiveMonths(period, activationMonth) {
	const dispatch = useDispatch();
	const params = useParams();
	const activeMonths = useSelector(state => state.impactReports?.activeMonths);

	useEffect(() => {
		if (params && params.businessId && activationMonth && period) {
			dispatch(getActiveMonths(params.businessId, activationMonth, period));
		}
	}, [params, period, activationMonth, dispatch]);

	return activeMonths;
}
