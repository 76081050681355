import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { Div, Row, Column} from 'basedesign-iswad';

import CharityWrapper from '../CharityWrapper/CharityWrapper';
import Icon from 'root/baseComponents/Icon';
import Tooltip from 'root/baseComponents/Tooltip';
import styles from './VolunteerNeedDetails.module.scss';

const VolunteerNeedDetails = () => {
	const location = useLocation();

	const [curNeed, setCurNeed] = useState({});
	const [chosenInfo, setChosenInfo] = useState('need_description');

	useEffect(() => {
		if (location?.state?.need) {
			setCurNeed(location.state.need);
		}
	}, [location]);

	return (
		<>
			<CharityWrapper pageName="Needs">
				<Div className={cx(styles.main)}>
					<Div type='flex' distributedBetween className="mb2 w-per-100" >
						<Div>
							Volunteer Need
						</Div>
					<Div type="flex" vAlign="center" className="w-px-70">
						<Tooltip text="Delete" width={80}>
							<Div
								type="flex"
								colorType="transparent"
								isSmall
								className="mouse-hand"
								// onClick={() => {
								// 	setConfirmModalText(
								// 		`Are you sure you want to delete this need?`
								// 	);
								// 	setAction({
								// 		action: 'delete',
								// 		needId
								// 	});
								// 	setShowConfirmModal(true);
								// }}
								>
								<Icon type="delete" color="#f5891f" scale={1.2} />
							</Div>
						</Tooltip>
						<Tooltip text="Copy" width={80}>
							<Div
								colorType="transparent"
								isSmall
								// onClick={() => {
								// 	history.push({
								// 		pathname: '/new-need',
								// 		state: {
								// 			title: need?.name,
								// 			value: need?.value,
								// 			message:
								// 				need?.business_description || need?.description,
								// 			pageTitle: `${need?.name} - duplicate`
								// 		}
								// 	});
								// }}
								className="mouse-hand ml1">
								<Icon type="copy" color="#f5891f" scale={1.2} />
							</Div>
						</Tooltip>
						<Tooltip text="Edit" width={80}>
							<Div
								type="flex"
								colorType="transparent"
								isSmall
								className="mouse-hand ml1"
								// onClick={() => {
								// 	setConfirmModalText(
								// 		`Are you sure you want to delete this need?`
								// 	);
								// 	setAction({
								// 		action: 'delete',
								// 		needId
								// 	});
								// 	setShowConfirmModal(true);
								// }}
								>
								<Icon type="edit" color="#f5891f" scale={1.2} />
							</Div>
						</Tooltip>
					</Div>
					</Div>
					<Row>
						<Column xs={12} sm={12} md={3} lg={3}>
							<Div className='max-height-px-200 '>
								<Div onClick={()=>setChosenInfo('need_description')} className={cx('mouse-hand p1 br-left-solid-2 br-color-primary max-w-px-200', chosenInfo==='need_description' && 'textPrimary bgWhite f-b')}>
									<Div type='flex' className=''>
										<Div className='mr1'>
											<Icon type='file' scale={1} color='#f5891f'/>
										</Div>
										<Div>
											Need Description 
										</Div>
									</Div>	
								</Div>
								<Div onClick={()=>setChosenInfo('location')} className={cx('mouse-hand p1 br-left-solid-2 br-color-primary max-w-px-200', chosenInfo==='location' && 'textPrimary bgWhite f-b')}>
									<Div type='flex' className=''>
										<Div className='mr1'>
											<Icon type='location' scale={1} color='#f5891f'/>
										</Div>
										<Div>
											Location Details
										</Div>
									</Div>	
								</Div>
								<Div onClick={()=>setChosenInfo('contact')} className={cx('mouse-hand p1 br-left-solid-2 br-color-primary max-w-px-200', chosenInfo==='contact' && 'textPrimary bgWhite f-b')}>
									<Div type='flex' className=''>
										<Div className='mr1'>
											<Icon type='user-card' scale={1} color='#f5891f'/>
										</Div>
										<Div>
											Contact Info
										</Div>
									</Div>	
								</Div>
									
							</Div>					
						</Column>
						<Column xs={12} sm={12} md={9} lg={9}>
						<Div className="card br-rad-px-5 fs-px-14">
							<Div className='p2'>
							{chosenInfo==='need_description' &&
								<>
									<Div className="mb1">Name: {curNeed?.name}</Div>
									<Div className="mb1">
										{curNeed?.start_date && `Start Date: ${curNeed.start_date}`}
									</Div>
									<Div className="mb1">
										{curNeed?.end_date && `End Date: ${curNeed.end_date}`}
									</Div>
									<Div className="mb1">
										{curNeed?.details &&  `Details: ${curNeed.details}`}
									</Div>
									<Div className="mb1">
										{curNeed?.is_virtual ? <Div> <Icon type='check' scale={1} color='green'/> Virtual works for us </Div> 
										: 	<Div> <Icon type='close' scale={1} color='green'/> Virtual works for us </Div>}
									</Div>
									<Div className="mb1">
										{curNeed?.is_ongoing_support ?  <Div> <Icon type='check' scale={1} color='green'/>On going support preferred </Div> 
										:   <Div> <Icon type='close' scale={1} color='grey'/>On going support preferred </Div>}
									</Div>
									<Div className="mb1">
										{curNeed?.is_oneoff_req ?  
										<Div> <Icon type='check' scale={1} color='green'/>One-off request </Div> :	
										<Div> <Icon type='close' scale={1} color='grey'/>One-off request </Div>}
									</Div>
									<Div className="mb1">
										{curNeed?.is_team_event ? 
										<Div> <Icon type='check' scale={1} color='green'/> Team event  </Div> :
										<Div> <Icon type='close' scale={1} color='grey'/>Team event </Div>}
									</Div>
									<Div className="mb1">
										{curNeed?.is_background_check_mandatory ? 
										<Div> <Icon type='check' scale={1} color='green'/> Background check is mandatory  </Div> :
										<Div> <Icon type='close' scale={1} color='grey'/> Background check  is mandatory </Div>}
									</Div>
									<Div className="mb1">
										{curNeed?.is_invite_kiddos ?
										<Div> <Icon type='check' scale={1} color='green'/>Invite the kiddos  </Div> :
										<Div> <Icon type='close' scale={1} color='grey'/>Invite the kiddos </Div>}
									</Div>

								</>
							}
							{chosenInfo==='location' &&
									<> 
										<Div className="mb1">
										{curNeed?.has_specified_location ? '': 'This opportunity has no location or is virtual.'}{' '}
										</Div>
										<Div className="mb1">
										Address:	{curNeed?.address && ` ${curNeed.address}`}{' '}
										</Div>
										<Div className="mb1">
										City:	{curNeed?.city && ` ${curNeed.city}`}{' '}
										</Div>
										<Div className="mb1">
										Province:	{curNeed?.province && `${curNeed.province}`}{' '}
										</Div>
										<Div className="mb1">
										Postal Code:		{curNeed?.postal_code && `${curNeed.postal_code}`}
										</Div>
									
								</>
							}
							{chosenInfo==='contact' &&				
									<>
											<Div className="mb1">
												{curNeed?.contact_name &&
													`Contact Name: ${curNeed.contact_name}`}
											</Div>
											<Div className="mb1">
												{curNeed?.contact_email &&
													`Contact Email: ${curNeed.contact_email}`}
											</Div>
											<Div className="mb1">
												{curNeed?.contact_phone &&
													`Contact Phone: ${curNeed.contact_phone}`}
											</Div>
									</>
							}
								</Div>
								</Div>
						</Column>
					</Row>		
				</Div>
			</CharityWrapper>
		</>
	);
};

export default VolunteerNeedDetails;
