import { get, post, put, del } from './http';

export const getUpcomingEvent = () => {
	return get(`/upcoming_business_event/`);
};

export const addParticipantToEvent = data => {
	return post(`/event-participant/${data.event_id}/`);
};

export const getEventParticipants = event_id => {
	return get(`/event-participant/${event_id}/`);
};

export const getAllBusinessEvents = business_id => {
	if (!business_id) {
		return get(`/business-event/`);
	} else {
		return get(`/business-event/?business_id=${business_id}`);
	}
};

export const getUpcomingEventForPublicUser = business_id => {
	return get(`/upcoming_business_event_for_public_user/${business_id}/`);
};

export const addPublicParticipantToEvent = data => {
	return post(`/add-public-participant-to-event/`, data);
};

export const exportBusinessEventParticipant = data => {
	return post(`/export_business_event_participant/`, data);
};
