// VENDOR imports
import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';
import { useHistory } from 'react-router-dom';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';

import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { getAllBusinessEvents } from 'root/services/event';

// style import
import styles from './Events.module.scss';

const Events = ({ business_id = null }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState([]);

	const getAllBusinessEventsData = async () => {
		dispatch(setLoading());
		try {
			const res = await getAllBusinessEvents(business_id);
			dispatch(setLoadingComplete());
			if (res?.length) {
				const localTableData = [];
				res?.forEach(item => {
					localTableData.push({
						event_id: item.id,
						event_name: item.name,
						date: item.date,
						status: item?.status,
						curEvent: item
					});
				});
				setTableData(localTableData);
			}
		} catch (err) {
			console.log(err);
			dispatch(setLoadingComplete());
		}
	};

	useEffect(() => {
		getAllBusinessEventsData();
	}, [business_id]);

	const headLines = [
		{
			value: 'event_name',
			display: 'Event',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'date',
			display: 'Date',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'status',
			display: 'Status',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'details',
			display: 'Details',
			width: 300,
			hasSearch: false,
			isSortable: false
		}
	];

	const actionHandler = status => {
		if (status === 'upcoming') {
			return <Div> Upcoming </Div>;
		} else {
			return <Div className=""> Completed </Div>;
		}
	};

	const data = useMemo(() => {
		if (tableData?.length) {
			const localData = [];
			tableData?.forEach(item => {
				localData.push({
					event_name: item.event_name,
					date: {
						value: item.date,
						display: dayjs(item.date).format('DD MMM YYYY')
					},
					status: {
						value: item.status,
						display: actionHandler(item.status)
					},
					details: {
						value: item.status,
						display: (
							<Button
								onClick={() => {
									if (!business_id) {
										history.push(`/business-single-event/${item.event_id}/`);
									} else {
										history.push(
											`/${business_id}/business-single-event/${item.event_id}/`
										);
									}
								}}>
								View Participants
							</Button>
						)
					}
				});
			});
			return localData;
		} else {
			return [];
		}
	}, [tableData]);

	return (
		<>
		{data?.length > 0 ?
		<>
			<Div className={cx('', styles.main)}>
				<Div className="p2 employeeHeading mb1 mt2">All Events</Div>
				<Div className="w-per-100 flex flex--jc--start pt1 of-x-auto scrollType1">
					<Table
						headLines={headLines}
						data={data}
						// colWidth={400}
						// tableWidth={'1000px'}
						isSortable={true}
						isSelectable={false}
						selectedData={selectedData}
						setSelectedData={setSelectedData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						rowsPerPage={5}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={3}
					/>
				</Div>
			</Div>
		</>  : 
		<>
			<Div className={cx('', styles.main)}> 
				<Div className='card w-per-90 br-rad-px-5 p3 mx2'>
					<Div type='flex' hAlign='start' className='w-per-100 fs-px-28 mb3 text-uppercase'>📦 Kit Packing Events  </Div>
					<Div  type='flex' hAlign='start' className='w-per-100 mb2' > For the teams that want to get their hands dirty </Div>
					<Div  type='flex' hAlign='start' className='w-per-100' >Ask us about creating memorable and hands-on volunteer experiences for your staff through kit-packing. </Div>
					<a href='https://meetings.hubspot.com/kelly366/troop-demo' target='_blank'>
						<Button className='mt2'> Book a demo </Button>
					</a>
				</Div>
			</Div>
		</>
		}

		</>
	);
};

export default Events;
