import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import cx from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { getOnVotingNeeds, getBusinessVotedMonths } from 'root/services/needs';

import {
	Div,
	Row,
	Column,
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	Image
} from 'basedesign-iswad';

// import Loading from 'root/baseComponents/Loading';
import BaseIcon from 'root/baseComponents/Icon';
import { getBackendUrl } from 'root/utils/helpers';
import { getNeeds } from 'root/services/needs';
import { getSummaryOfImpact } from 'root/services/businesses';
import { getFulfilledNeeds } from 'root/services/needs';
import {
	getCurrentReport,
	getCurrentRevenueReport
} from 'root/services/impactReports';

import Text from 'root/baseComponents/Text/Text';
import Pagination from 'root/baseComponents/Paginations';
import FormSelect from 'root/baseComponents/FormSelect';
import StatsCard from 'root/baseComponents/StatsCard';
import LimitedSwipableSlider from 'root/baseComponents/LimitedSwipableSlider';
import LimitedSwipableSliderItem from 'root/baseComponents/LimitedSwipableSlider/subs/LimitedSwipableSliderItem';

import styles from './MyImpact.module.scss';
import VoteDistribution from './subs/VoteDistribution';
import ActTable from './subs/ActTable';
import PictureModal from './subs/PictureModal';

import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

function MyIpmact() {
	const params = useParams();
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location.search);
	let date = urlSearchParams.get('date');

	const [currentPage, setCurrentPage] = useState(1);

	const [src, setSrc] = useState();
	const [moveLeft, setMoveLeft] = useState(false);
	const [moveRight, setMoveRight] = useState(false);
	const [moveToItemWithNum, setMoveToItemWithNum] = useState(1);
	const [currentBusiness, setCurrentBusiness] = useState(1);

	const [showPictureModal, setShowPictureModal] = useState(false);

	dayjs.extend(advancedFormat);
	const dispatch = useDispatch();
	const businesses = useSelector(state => state.businesses.businesses);
	const email = useSelector(state => state?.users?.loggedInUser?.email);

	const [activeNeeds, setActiveNeeds] = useState([]);
	const [votedMonths, setVotedMonths] = useState([]);
	const [curMonth, setCurMonth] = useState('');
	const [monthOptions, setMonthOptions] = useState([]);
	const [monthSelected, setMonthSelected] = useState('');
	const [fulfilledNeedsTemp, setFulfilledNeedsTemp] = useState({});
	const [fulfilledNeeds, setFulfilledNeeds] = useState({});
	const [activatedFulfilledNeed, setActivatedFulfilledNeed] = useState();
	const [testimonials, setTestimonials] = useState([]);
	const [visuals, setVisuals] = useState([]);
	const [reports, setReports] = useState([]);
	const [revenueReport, setRevenueReport] = useState({});
	const [employeePledgedAmount, setEmployeePledgedAmount] = useState('');
	const [summaryOfImpact, setSummaryOfImpact] = useState({});

	const businessId = params?.businessId;

	const getOnVotingNeedsData = async () => {
		try {
			let res;
			if (businessId) {
				if (curMonth) {
					res = await getOnVotingNeeds(curMonth, businessId);
				} else {
					res = await getOnVotingNeeds('', businessId);
				}
			}
			setActiveNeeds(res);
		} catch (err) {
			console.log(err);
		}
	};

	const getBusinessVotedMonthsData = async () => {
		try {
			const res = await getBusinessVotedMonths(businessId);
			const localMonths = [];
			res?.forEach(item => {
				localMonths.push(item.monthDate);
			});
			setVotedMonths(localMonths);
			if (!date) {
				setCurMonth(localMonths?.[0]);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const getSummaryOfImpactData = async () => {
		const res = await getSummaryOfImpact({
			date: curMonth,
			business_id: businessId
		});
		console.log(res);
		setSummaryOfImpact(res);
	};

	useEffect(() => {
		if (businessId && curMonth) {
			getSummaryOfImpactData();
		}
	}, [curMonth, businessId]);

	useEffect(() => {
		if (businesses && businessId) {
			setCurrentBusiness(
				businesses?.filter(
					business => Number(business.id) === Number(businessId)
				)?.[0]
			);
		}
	}, [businesses, businessId]);

	useEffect(() => {
		if (businessId) {
			dispatch(getNeeds(businessId));
		}
	}, [businessId]);

	useEffect(() => {
		getBusinessVotedMonthsData();
	}, [date]);

	useEffect(() => {
		if (curMonth) {
			getOnVotingNeedsData();
		}
	}, [curMonth]);

	useEffect(() => {
		const localOptions = [];
		votedMonths?.forEach(month =>
			localOptions.push({
				shownText: dayjs(month).format('MMM YYYY'),
				value: month
			})
		);
		setMonthOptions(localOptions);
	}, [votedMonths]);

	useEffect(() => {
		const getData = async (byEmployee, needId) => {
			const res = await dispatch(getFulfilledNeeds(needId));
			const localFulfilledNeeds = {};
			localFulfilledNeeds[needId] = { byEmployee, data: res.payload };
			setFulfilledNeedsTemp(localFulfilledNeeds);
		};

		let hasFulfilledNeed = false;

		activeNeeds.forEach(need => {
			if (need?.is_vote_winner) {
				if (need.need.is_fulfilled) {
					hasFulfilledNeed = true;
					getData(false, need.need.id);
					setActivatedFulfilledNeed(need.need.id);
				}
			}
			if (need?.hit_target_by_pledge) {
				hasFulfilledNeed = true;
				getData(true, need.need.id);
			}
		});
		if (!hasFulfilledNeed) {
			setFulfilledNeeds({});
		}
	}, [activeNeeds]);

	useEffect(() => {
		let localAmount = 0;
		console.log(activeNeeds);
		activeNeeds.forEach(need => {
			if (need.hit_target_by_pledge) {
				localAmount += need.need.value;
			}
		});
		setEmployeePledgedAmount(localAmount);
	}, [activeNeeds]);

	useEffect(() => {
		if (activatedFulfilledNeed && fulfilledNeeds) {
			const localTestimony = [];
			const localVisual = [];
			const cur_fulfilled_needs =
				fulfilledNeeds?.[activatedFulfilledNeed]?.data;
			// if (cur_fulfilled_needs) {
			cur_fulfilled_needs?.forEach(item => {
				if (item?.media_type === 'testimony') {
					localTestimony.push(item);
				} else {
					localVisual.push(item);
				}
			});
			setTestimonials(localTestimony);
			setVisuals(localVisual);
			// }
		}
	}, [activatedFulfilledNeed, fulfilledNeeds]);

	useEffect(() => {
		const curKey = Object.keys(fulfilledNeedsTemp)[0];
		if (curKey in fulfilledNeeds) {
		} else {
			const local = { ...fulfilledNeeds };
			local[curKey] = fulfilledNeedsTemp[curKey];
			setFulfilledNeeds(local);
		}
	}, [fulfilledNeedsTemp]);

	useEffect(() => {
		const getReport = async () => {
			const res = await getCurrentReport({
				businessId,
				period: `${dayjs(curMonth).format('YYYY-MM-01')}`
			});
			setReports(res);
		};

		const getRevenueReport = async () => {
			const res = await getCurrentRevenueReport({
				businessId,
				period: `${dayjs(curMonth).format('YYYY-MM-01')}`
			});
			setRevenueReport(res?.[0]);
		};

		if (businessId && curMonth) {
			getReport();
			getRevenueReport();
		}
	}, [businessId, curMonth]);

	useEffect(() => {
		if (date) {
			setCurMonth(date);
			const selectedMonth = [...monthOptions]?.filter(item => {
				return item.value === date;
			});
			setMonthSelected(selectedMonth?.[0]?.shownText);
		}
	}, [monthOptions, date]);

	return (
		<>
			{activeNeeds?.length ? (
				<Div type="flex" className="pt2 pb2 pl4 pr2">
					<Row className="flex flex--jc--start flex--ai--start">
						<Column
							xs={12}
							sm={12}
							md={4}
							lg={4}
							className={cx(
								'w-per-100 flex flex--dir--col flex-jc--start',
								styles.fillHeight
							)}>
							<Div
								type="flex"
								vAlign="start"
								distributedBetween
								className="mb1 height-px-40">
								<Div type="flex" vAlign="start">
									<Div className="employeeHeading mr3 height-px-40">
										Impact Stories
									</Div>
									<Div>
										<Pagination
											numberOfShownPages={3}
											numberOfTotalPages={testimonials.length}
											currentPage={currentPage}
											setCurrentPage={setCurrentPage}
											showFirstLastIcon={false}
										/>
									</Div>
								</Div>
							</Div>
							{testimonials.length ? (
								<Card className="card flex--gr--1 flex--jc--between">
									<Div>
										<CardHeader className="pl4 pr4 pb2 pt3 mb2 fs-px-12">
											<Div>
												{console.log(testimonials?.[currentPage - 1])}
												Need: {testimonials?.[currentPage - 1]?.need_info?.name}
											</Div>
											<Div>
												Community supported:{' '}
												{
													testimonials?.[currentPage - 1]?.need_info
														?.neighbourhood
												}
											</Div>
											<Div>
												Charity:{' '}
												{
													testimonials?.[currentPage - 1]?.need_info
														?.charity_name
												}
											</Div>
											<Div>Date: {dayjs(curMonth).format('MMM YYYY')}</Div>
										</CardHeader>

										<CardBody className="pl4 pr4 pb2 mb2 fs-px-14">
											<Div>Dear {currentBusiness?.name} team,</Div>
											<br />
											{testimonials?.[currentPage - 1]?.testimony && (
												<Text>{testimonials[currentPage - 1].testimony}</Text>
											)}
											<br />
											<br />
											<Div> Your's Truly.</Div>
											<br />
											<Div>{testimonials?.[currentPage - 1]?.citation}</Div>
										</CardBody>
									</Div>
									<CardFooter className="w-per-100 px1 pos-rel">
										<Div
											type="flex"
											hAlign="center"
											vAlign="center"
											onClick={setMoveRight}
											className={cx('mouse-hand', styles.rightAngle)}>
											<BaseIcon type="arrow" className="z-10000" scale={2} />
										</Div>

										<Div
											type="flex"
											hAlign="center"
											vAlign="center"
											onClick={setMoveLeft}
											className={cx('mouse-hand', styles.leftAngle)}>
											<BaseIcon type="arrow" className="z-10000" scale={2} />
										</Div>

										<LimitedSwipableSlider
											moveLeft={moveLeft}
											setMoveLeft={setMoveLeft}
											moveRight={moveRight}
											setMoveRight={setMoveRight}
											moveToItemWithNum={moveToItemWithNum}
											setMoveToItemWithNum={setMoveToItemWithNum}
											sliderContainerWidthMultiplier={visuals.length / 1.5}
											minXDifferenceToMove={10}
											notScrollableOnSwipableElement={false}
											transitionDuration={0.3}>
											{visuals.map((item, idx) => (
												<LimitedSwipableSliderItem
													key={idx}
													className={cx('w-per-100 px1')}>
													{item?.video_src &&
													item?.video_src !== 'undefined' ? (
														item?.video_src && !item.image ? (
															<video width="300" controls>
																<source
																	src={`${getBackendUrl()}/media/${
																		item.video_src
																	}`}
																/>
															</video>
														) : (
															<a href={item.video_src} target="_blank">
																<LazyLoadImage
																	src={`${getBackendUrl()}${item.image}`}
																	className={cx(styles.imageImpact)}
																/>

																{/* <Image
															src={`https://stagingapi.hitroop.com${item.image}`}
															className={cx(styles.imageImpact)}
														/> */}
															</a>
														)
													) : (
														<LazyLoadImage
															src={`${getBackendUrl()}${item.image}`}
															className={cx(styles.imageImpact)}
															onClick={() => {
																setShowPictureModal(true);
																setSrc(`${getBackendUrl()}${item.image}`);
															}}
														/>

														// <Image
														// 	src={`https://stagingapi.hitroop.com${item.image}`}
														// 	className={cx(styles.imageImpact)}
														// 	onClick={() => {
														// 		setShowPictureModal(true);
														// 		setSrc(
														// 			`https://stagingapi.hitroop.com${item.image}`
														// 		);
														// 	}}
														// />
													)}
												</LimitedSwipableSliderItem>
											))}
										</LimitedSwipableSlider>
									</CardFooter>
								</Card>
							) : (
								<Div className="card flex--gr--1">
									<Div className="pl4 pr4 pb2 pt2 fs-px-14">
										Wait for it… this is where you’ll see pictures, video
										messages and notes of thanks when a need is fulfilled.
									</Div>
								</Div>
							)}
						</Column>

						<Column xs={12} sm={12} md={8} lg={8} className="w-per-100">
							<Div className={cx('w-per-100', styles.secondCol)}>
								<Div type="flex" vAlign="center" hAlign="end" className="mb4">
									<Div
										type="flex"
										vAlign="center"
										className="employeeHeading mr2 height-px-40">
										Report Month:
									</Div>
									{monthOptions ? (
										<Div className="">
											<FormSelect
												placeHolder="Select"
												options={monthOptions}
												inputSearchClassName="w-px-100"
												val={curMonth}
												setVal={setCurMonth}
												initialSelected={
													!date ? monthOptions?.[0]?.shownText : monthSelected
												}
											/>
										</Div>
									) : (
										''
									)}
								</Div>
								<Div
									type="flex"
									// direction="horizontal"
									hAlign="center"
									className={cx('w-per-100', styles.statCardsContainer)}>
									<StatsCard
										title="Team Vote"
										amount={summaryOfImpact?.employee_vote_percentage}
										unit="%"
									/>
									<StatsCard
										title="Team Contribution"
										amount={
											'$' +
											parseFloat(summaryOfImpact?.team_contribution).toFixed(2)
										}
									/>
									<StatsCard
										title="Business Contribution"
										amount={
											'$' +
											parseFloat(
												summaryOfImpact?.business_contribution
											).toFixed(2)
										}
									/>
								</Div>
								<Div className="employeeHeading height-px-40">
									Vote Distribution
								</Div>
								<VoteDistribution
									activeNeeds={reports}
									fulfilledNeeds={fulfilledNeeds}
									activatedFulfilledNeed={activatedFulfilledNeed}
									setActivatedFulfilledNeed={setActivatedFulfilledNeed}
								/>
							</Div>
						</Column>
					</Row>
				</Div>
			) : (
				''
			)}
			<Div className="w-per-90">
				<ActTable date={curMonth} businessId={businessId} />
			</Div>

			{showPictureModal && (
				<PictureModal setShowPictureModal={setShowPictureModal} src={src} />
			)}
		</>
	);
}

export default MyIpmact;
