import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Div, Paragraph } from 'basedesign-iswad';

import useLoggedIn from 'hooks/useLoggedIn';
import { validateUser } from 'root/services/user';

import styles from './CharityAdminRoute.module.scss';

const CharityAdminRoute = ({ children }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const loggedIn = useLoggedIn();

	const loggedInUser = useSelector(state => state.users?.loggedInUser);

	const [checked, setChecked] = useState(false);
	const [isCharityAdmin, setIsCharityAdmin] = useState(true);
	const [time, setTime] = useState(5);

	useEffect(() => {
		if (!loggedIn) {
			dispatch(validateUser(location));
		}
	}, []);

	useEffect(() => {
		if (loggedInUser?.groups) {
			if (loggedInUser.groups?.includes('Charity Admin')) {
				setIsCharityAdmin(true);
			} else {
				setIsCharityAdmin(false);
			}
			setChecked(true);
		}
	}, [loggedInUser]);

	useEffect(() => {
		if (checked && !isCharityAdmin) {
			let currentTime = time;
			if (time > 0) {
				setTimeout(() => {
					currentTime -= 1;
					setTime(currentTime);
				}, 1000);
			}
			if (time === 0) {
				history.push('/');
			}
		}
	}, [checked, isCharityAdmin, time]);

	return (
		<>
			{checked && isCharityAdmin ? children : ''}
			{checked && !isCharityAdmin ? (
				<Div>
					<Paragraph> The content of this page is private</Paragraph>
					<Paragraph>You will be redirected to home page in {time}s</Paragraph>
				</Div>
			) : (
				''
			)}
		</>
	);
};

export default CharityAdminRoute;
