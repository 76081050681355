import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Button } from 'troop-design';
import cx from 'classnames';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { getUserEvents } from 'services/user';

import styles from './UserActivities.module.scss';

function UserActivities() {
	const params = useParams();
	const history = useHistory();

	const [activities, setActivities] = useState([]);

	const businessUserId = params.businessUserId;
	const businessId = params.businessId;

	useEffect(() => {
		const getActivities = async () => {
			const res = await getUserEvents({ business_user_id: businessUserId });
			setActivities(res);
		};

		if (businessUserId) {
			getActivities();
		}
	}, [businessUserId]);

	const columns = useMemo(() => {
		let cols = [
			{
				Header: 'Event',
				accessor: 'event',
				filter: 'equals'
			},
			{
				Header: 'Date',
				accessor: 'date',
				filter: 'equals'
			},
			{
				Header: 'Time',
				accessor: 'time',
				filter: 'equals'
			}
		];

		return cols;
	}, []);

	const data = useMemo(() => {
		if (activities) {
			return activities.map(activity => {
				let showingCols = {};
				showingCols['event'] = activity.event;
				showingCols['date'] = activity.created_at?.split('T')?.[0];
				showingCols['time'] = activity.created_at
					?.split('T')?.[1]
					?.split('.')?.[0];
				return showingCols;
			});
		}
	}, [activities]);

	return (
		<>
			{data?.length ? (
				<div>
					<Table
						className={styles.employeesTable}
						data={data}
						columns={columns}
						striped={false}
					/>
				</div>
			) : (
				''
			)}
			<div className={cx(styles.btnContainer)}>
				<Button
					className={cx(styles.backBtn)}
					onClick={() => history.push(`/businesses/${businessId}/employees`)}>
					Go Back
				</Button>
			</div>
		</>
	);
}

export default UserActivities;
