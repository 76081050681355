import React, { useEffect, useState } from 'react';

import { Heading, Button, Text } from 'troop-design';

import { hideModal } from 'reducers/modals';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';

function ChannelSetupPrompt({ success, message = '' }) {
	const [text, setText] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		if (success) {
			setText(
				'Congrats! You must receive an activation message to the specified channel in your Teams. 🎉'
			);
		} else {
			if (message) {
				setText(message);
			} else {
				setText(
					'Something went wrong to properly setup your channel. Please try it again!'
				);
			}
		}
	}, [success, message]);

	return (
		<Modal reduxKey="channelSetupPrompt" size="md">
			<Heading level={2}>Installation Successful</Heading>
			<Text tag="p" hasWrapper>
				{text}
			</Text>
			<Button
				variant="dark"
				outline
				onClick={() => dispatch(hideModal('channelSetupPrompt'))}>
				Dismiss
			</Button>
		</Modal>
	);
}

export default ChannelSetupPrompt;
