import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';
import cx from 'classnames';

import Icon from 'root/baseComponents/Icon';
import Close from 'root/baseComponents/Close';
import Button from 'root/baseComponents/Button';
import { clearModalType } from 'root/reducers/charityReducers/modalType';
import { clearModal } from 'root/utils/modal';

import styles from '../Modal.module.scss';

function RejectCharityAdminModal() {
	const dispatch = useDispatch();

	const header = useSelector(state => state.modalProps.header);
	const text = useSelector(state => state.modalProps.text);
	const rejectRequest = useSelector(state => state.modalProps.reject);
	const item = useSelector(state => state.modalProps.rejectItem);

	return (
		<>
			<Div
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.modalContainer
				)}>
				<Div
					className={cx(
						'pos-fix pos-fix--center br-rad-px-5 card w-per-50 max-w-px-700',
						styles.heading
					)}>
					<Div
						className={cx(
							'p1 bgPrimary'
						)}>
						<Div type="flex" vAlign="center" className="pl1">
							<Icon type="close" color="white" scale={1.5} />
							<Div className="textWhite f-b pl2 ">{header}</Div>
						</Div>
						<Close onClick={() => dispatch(clearModalType())} />
					</Div>
					<Div className="pt1 pl3 pr3 pb2 fs-px-14">
						{text}
					</Div>
					<Div type="flex" hAlign="center" className="pb1 w-per-100">
						<Button 
						className='mr2'
							onClick={() => {
								rejectRequest(item);
								}}
								>
							Yes
						</Button>
	                     <Button
							className="mr2"
							colorType="transparent"
							onClick={() => clearModal(dispatch)}>
							No
						</Button>{' '}
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default RejectCharityAdminModal;
