import React, { useState } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';

import { COLORS } from 'root/constants/vars';

import Anchor from '../../Anchor';

import Logo from 'root/assets/logo.png';

import { nav, subnav } from '../constants';
import styles from '../Header.module.scss';

const DesktopNav = () => {
	const [activeSubs, setActiveSubs] = useState('');

	return (
		<Div
			type="flex"
			distributedBetween
			vAlign="center"
			className={cx('', styles.headerContainer)}>
			<a href="https://hitroop.com">
				<Div className="w-px-150 mx4">
					<img src={Logo} alt="Troop Logo" />
				</Div>
			</a>
			<Div vAlign="center" type="flex" className="mx4">
				{nav?.map((item, idx) => (
					<Div
						type="flex"
						vAlign="center"
						hAlign="center"
						key={idx}
						className={cx(
							'mx1 pos-rel mouse-hand',
							styles.headerNavItemContainer,
							activeSubs === item.identifier && 'textPrimary'
						)}
						onMouseOver={() => setActiveSubs(item.identifier)}
						onMouseLeave={() => setActiveSubs('')}>
						{item?.hasSubs ? (
							<Div>{item.title}</Div>
						) : (
							<Anchor
								toWordPress={item.toWordpress}
								to={item.url}
								isLocal={item.isLocal}>
								<Div>{item.title}</Div>
							</Anchor>
						)}

						{item?.hasSubs && (
							<>
								<Div className="ml1">
									<Icon
										type="angle-down"
										color={
											activeSubs === item.identifier ? COLORS.primary : 'black'
										}
									/>
								</Div>

								{activeSubs === item.identifier && (
									<Div
										className={cx(
											'p2 pos-abs pos-abs--lb z-10000',
											styles.headerSubNavContainer
										)}
										style={{
											width: item?.subContainerWidth || '0px',
											transform: `translateX(${
												item?.subContainerTranslateX || 0
											}px)`
										}}
										onMouseOver={() => setActiveSubs(item.identifier)}
										onMouseLeave={() => setActiveSubs('')}>
										{subnav?.[item.identifier]?.map((subItem, subIdx) => (
											<Anchor
												toWordPress={subItem.toWordpress}
												to={subItem.url}
												isLocal={subItem.isLocal}
												key={subIdx}>
												<Div
													className={cx(
														'mb2',
														styles.headerSubNavItemContainer
													)}>
													{subItem.title}
												</Div>
											</Anchor>
										))}
									</Div>
								)}
							</>
						)}
					</Div>
				))}
				<a
					href={'https://meetings.hubspot.com/kelly366/troop-demo'}
					target="_blank">
					<Div
						type="flex"
						hAlign="center"
						vAlign="center"
						className={cx(
							'w-px-100 textWhite height-px-40 br-all-hidden-1 br-rad-px-5',
							styles.headerDemoBtn
						)}>
						Demo
					</Div>
				</a>
			</Div>
		</Div>
	);
};

export default DesktopNav;
