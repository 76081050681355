import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import { getSingleCharity } from 'root/services/charity';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import Wrapper from '../components/Wrapper';

import CharityEditForm from './subs/CharityEditForm';
import styles from './CharityEdit.module.scss';

function CharityEdit() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();

	const [charity, setCharity] = useState({});

	const getSingleCharityData = async () => {
		dispatch(setLoading());
		try {
			const res = await getSingleCharity(id);
			setCharity(res);
			dispatch(setLoadingComplete());
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
		}
	};

	useEffect(() => {
		if (id) {
			getSingleCharityData();
		}
	}, [id]);
	return (
		<>
			<Wrapper pageName="Charities">
				<Div className="p2 bgWhite boxShadowType1 w-per-90 max-w-px-500 mr-auto ml-auto">
					<CharityEditForm charity={charity} />
				</Div>
			</Wrapper>
		</>
	);
}

export default CharityEdit;
