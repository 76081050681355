import React, { useEffect, useRef, useState } from 'react';
import { Card, Icon, Anchor } from 'troop-design';
import cx from 'classnames';
import parse from 'html-react-parser';

import styles from '../EmployeeFAQ.module.scss';

const QuestionCard = ({ question, answer, key }) => {
	const answerRef = useRef();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Card key={key} className={cx(styles.questionAnswerContainer)}>
			<div
				className={cx(styles.question)}
				onClick={() => {
					answerRef.current?.classList.toggle(styles.answer);
					setIsOpen(!isOpen);
				}}>
				{question}
				<div>
					{!isOpen ? (
						<Icon className={cx(styles.icon)} icon="chevron-down" width={20} />
					) : (
						<Icon className={cx(styles.icon)} icon="chevron-up" width={20} />
					)}
				</div>
			</div>
			<div ref={el => (answerRef.current = el)} className={cx(styles.answer)}>
				{parse(answer)}
			</div>
		</Card>
	);
};

export default QuestionCard;
