export const items = [
	{
		question: `I'm signed in. Now what?`,
		answer: `Once you sign in to your employee dashboard, the magic happens.
                 You’ll see the three needs your team is voting on each month, you
                 can track the need you voted for, plus pledge personal funds towards
                 any needs you really want to see fulfilled. If a need gets enough
                 pledges and hits the target (happy days!), we’ll get to work
                 fulfilling it and share your impact through the impact report
                 section.`
	},
	{
		question: `What happens if the need target I pledged for isn’t fulfilled?`,
		answer: `If the target isn’t hit, the funds will go back into your ‘Personal Funds’
                 account so you can pledge to fulfill another need in the future.`
	},
	{
		question: `Do I get a tax receipt?`,
		answer: `For needs from registered charities, Yes! You can tell if the need comes from a registered charity if it has this icon on the need image (ADD ICON).  You will be issued one charitable tax receipt for all of the funds successfully pledged to fulfill local needs for registered charities. If you pledge funds towards a need that does not hit the target and your funds are moved back into your Account Balance, your pledge  will not be eligible for a tax receipt.  Tax receipts will be available within 5 business days from the end of the previous year and can be found in your Account details.`
	},
	{
		question: `Does Troop charge a fee?`,
		answer: `Your company pays the Troop fee for their employees. 
                 If you pledge tp fulfill a need and the need target is successfully 
                 reached before the pledge deadline, 100% of your
				 contribution will go towards fulfilling the need, less a credit card
                 payment processing fee of 2.5%.`
	},
	{
		question: `How do you find your needs?`,
		answer: `All of the needs on Troop come from registered charities, nonprofits
                or community organizations and fall into one of two categories:
                <br />
                <p className={cx(styles.numbered)}>
                    1) a need for the charity/nonprofit/community organization (items
                    that will benefit the organization or a large number of people the
                    organization serves) e.g a dishwasher for a crisis centre, a
                    stockpile of personal hygiene products for a youth home, etc.
                </p>
                <p className={cx(styles.numbered)}>
                    2) a specific need for a person or family supported by the
                    charity/nonprofit, e.g a newcomer mom in need of a baby stroller,
                    a low-income family in need of a used bike for transportation,
                    etc.
                </p>
                <p>(P.S. These are all examples of needs we’ve crushed!)</p>
                <p>
                    We work directly with representatives from the
                    charity/nonprofit/community organization to confirm the need,
                    write up the story, post it on the site and then share it with the
                    Troopers. That’s when the magic happens!
                </p>
                <p>
                    For more information about how we find, pick, and vet our needs,
                    visit
                    <a
                        href="https://hitroop.com/faq/"
                        target="_blank"
                        className="faqLink">
                        https://hitroop.com/faq/
                    </a>
                    .
                </p>`
	},
	{
		question: `Can we submit needs from charities we support?`,
		answer: `Yes, please! If you support a local charity that you know has a
                need, let the Troop team know about it. You can email them at
                hello@hitroop.com or get the charity to submit a need request form
                <a
                    href="https://hitroop.com/charity-needs/"
                    target="_blank"
                    className="faqLink">
                    here
                </a>
                .`
	},
	{
		question: `How do I contact you if I run into an issue?`,
		answer: `By emailing us at hello@hitroop.com. If you encounter any problems
                or errors, or if you have a question we haven't answered here, reach
                out and we’ll do our best to respond in a timely manner.`
	},
	{
		question: `How do I request a refund?`,
		answer: `You can request a refund by emailing us at hello@hitroop.com. We try
                 to have all refund requests handled in 72 hours. 
                 Refunds can only be granted for funds that have been pledged but the target hasn’t been hit. `
	},
	{
		question: `How does the vote streak work?`,
		answer: `Your vote streak calculates the number of months you have voted in a row after January 2022.
`
	},
	{
		question: `Where do the needs from the ‘Acts of Kindness’ section come from?`,
		answer: `Troop has partnered with Geenees Creative Labs Inc (URL) to source and fulfill the Wishes shared through our Acts of Kindness portal.  Geenees is a platform that allows charities and nonprofits to share their Wishes and Wishes of the families they support with a wider network.`
	}
];
