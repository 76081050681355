import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import {
	Spinner,
	Form,
	Input,
	Button,
	Heading,
	Text,
	Icon
} from 'troop-design';
import { useHistory } from 'react-router-dom';
import ReactGA from "react-ga4";

import { hideModal } from 'reducers/modals';
import { get, set, del } from 'utils/storage';

import Modal from 'components/Modal';

import styles from '../DoGoodFund.module.scss';

const AddFundModal = () => {
	const history = useHistory();

	const dispatch = useDispatch();
	const [amountFromState, setAmountFromState] = useState('');

	const handleSubmit = values => {
		ReactGA.event({
			event_name: 'add_funds',
			category: "Button Click",
			action: "Add Funds clicked"
		  });
		const { amount } = values;
		set('do-good-amount', amount);
		setAmountFromState(amount);
		dispatch(hideModal('addFundPopup'));
		history.push({
			pathname: '/stripe-checkout-page',
			state: { type: 'addDirectFund', amount }
		});
	};

	return (
		<Modal dismissable={false} reduxKey="addFundPopup" size="md">
			<div className={cx(styles.modalMainContainer)}>
				<div
					className={cx(styles.modalClose)}
					onClick={() => dispatch(hideModal('addFundPopup'))}>
					<Icon icon="close" width={20} color="black" />
				</div>
				<div className={styles.modalHeader}>
					<Heading level={2}>Add Funds!</Heading>
				</div>
				<div className={cx(styles.modalBody)}>
					<div className={cx(styles.modalBodyHighlight)}>
						Fund your account to make pledges and fulfill needs.
					</div>
					<div className={cx(styles.modalBodyTiny)}>
						If your chosen need doesn't receive enough pledges to get fulfilled,
						<br />
						your dollars will go back into your "do-good" account for future
						use.
					</div>
					<div>
						<Form onSubmit={handleSubmit}>
							<Input
								name="amount"
								label="Fund Amount ($CAD)"
								type="number"
								required
							/>
							<Button variant="primary" className="mb-4">
								Continue
							</Button>
						</Form>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default AddFundModal;
