import React, { useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Div, Row, Column } from 'basedesign-iswad';
import { Heading, Icon, Card } from 'troop-design';

import styles from './SignUpOrLogin.module.scss';

import { set, del } from 'utils/storage';

import { notLoggedInToVote } from 'root/reducers/isLoggedInToVote';
import LoginRegisterForm from 'components/LoginRegisterForm';
import Close from 'root/baseComponents/Close';

import { showGoToVoteTheme, showRegisterTheme } from './utils';

import logo from 'assets/black-logo.png';

const SignUpOrLogin = ({
	incentiveQuotes,
	items,
	iconType = 'heart',
	iconColor = 'yellow',
	useGoToVoteTheme = false,
	useRegisterTheme = true,
	headingWord = 'Signup',
	ctaWord = 'Set a Password',
	isRegisterPage = true,
	isThanksAfterVote = false,
	showBackground = true,
	isLoginAsModal = false,
	setShowLoginModal,
	initialEmail = '',
	businessKey = '',
	setShowErrorMessage,
	setEmailFromState,
	children
}) => {
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (isLoginAsModal) {
			set('loginToVote', true);
		}
	});

	return (
		<>
			<Row className={cx(styles.container)}>
				<Column
					className={cx(
						styles.containerCol,
						useGoToVoteTheme && styles.containerColLeft,
						useRegisterTheme && styles.containerColRegister,
						!isRegisterPage && styles.containerColLogin,
						!showBackground && styles.notShowBackground
					)}>
					{useGoToVoteTheme &&
						showGoToVoteTheme(
							incentiveQuotes,
							styles,
							items,
							iconType,
							iconColor,
							cx,
							Heading,
							Icon,
							history
						)}

					{/* {useRegisterTheme &&
							showRegisterTheme(styles, cx, Heading, Icon, ctaWord)} */}
					{/* {isLoginAsModal && (
						<Div
							className={cx(styles.closeContainer)}
							onClick={() => {
								setShowLoginModal(false);
								del('loginToVote');
								dispatch(notLoggedInToVote());
							}}>
							<Div className="show-flex-in-md-lg">
								<Icon icon="close" color="black" width={20} />
							</Div>
							<Div className="show-flex-in-sm-xsm">
								<Icon icon="close" color="white" width={20} />
							</Div>
						</Div>
					)} */}
				</Column>

				<div className={cx(styles.containerCol)}>
					<div className={cx('pos-rel', styles.containerColRight)}>
						{isLoginAsModal && (
							<Close
								onClick={() => {
									setShowLoginModal(false);
									del('loginToVote');
									dispatch(notLoggedInToVote());
								}}
							/>
						)}
						<Heading level={2} className={cx(styles.containerColHeadingRight)}>
							<div>
								<img className={cx(styles.troopLogo)} src={logo} />
							</div>
							<div className="textInfo">{headingWord}</div>
						</Heading>
						<LoginRegisterForm
							showVoteLink={useGoToVoteTheme && !isThanksAfterVote}
							isRegisterPage={isRegisterPage}
							isLoginAsModal={isLoginAsModal}
							initialEmail={initialEmail}
							businessKey={businessKey}
							setShowLoginModal={setShowLoginModal}
							setShowErrorMessage={setShowErrorMessage}
							setEmailFromState={setEmailFromState}
						/>
					</div>
					<div>
						<p className={cx(styles.approval)}>
							Click "Sign up" to agree to Troop's{' '}
							<span className={cx(styles.underline)}>
								<a
									href="https://hitroop.com/terms/"
									target="_blank"
									className={cx(styles.anchor)}>
									Terms of Service
								</a>
							</span>{' '}
							and acknowledge that Troop's{' '}
							<span className={cx(styles.underline, styles.tiny)}>
								<a
									href="https://hitroop.com/privacy/"
									target="_blank"
									className={cx(styles.anchor, styles.tiny)}>
									Privacy Policy
								</a>
							</span>{' '}
							applies to you.
						</p>
					</div>
				</div>
			</Row>
		</>
	);
};

export default SignUpOrLogin;
