import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	Form,
	Input,
	Select,
	Button,
	ImageUploadWithCropper
} from 'troop-design';
import { showModal } from 'reducers/modals';
import { addFulfilledNeedData } from 'services/needs';

export default function MediaForm(props) {
	const dispatch = useDispatch();
	const [mediaType, setMediaType] = useState('media-type');
	const [resetImage, setResetImage] = useState(false);

	const handleMediaSelect = useCallback(
		e => {
			setMediaType(e.target.value);
		},
		[setMediaType]
	);

	const submitFulfillNeedMedia = useCallback(
		e => {
			const { testimony, video, order, image, citation } = e;

			if (mediaType !== '') {
				const formData = new FormData();
				formData.append('media_type', mediaType);
				formData.append('need_id', props.id);
				formData.append('testimony', testimony);
				formData.append('citation', citation);
				formData.append('video', video);
				formData.append('order', order);
				formData.append('file', image);
				addFulfilledNeedData(formData)
					.then(() => {
						setResetImage(true);
						dispatch(showModal('successSubmit'));
					})
					.catch(() => {
						dispatch(showModal('errorSubmit'));
					});
			}
		},
		[mediaType, dispatch, props.id]
	);

	return (
		<>
			<Form onSubmit={submitFulfillNeedMedia}>
				<Select
					onChange={handleMediaSelect}
					name={`mediaType-${props.id}`}
					value={mediaType}
					label="Media Type"
					valiationRules={[
						{
							rule: 'option.atLeastOne',
							message: 'Please select an option'
						}
					]}>
					<option value="testimony">Testimony</option>
					<option value="video">Video Src</option>
					<option value="image">Image</option>
				</Select>
				{mediaType === 'testimony' && (
					<>
						<Input
							label="Testimony"
							name="testimony"
							id={`testimony-${props.id}`}
							type="multi"
							value=""
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Enter a testimonial'
								}
							]}
						/>
						<Input
							label="Citation"
							name="citation"
							id={`testimony-citation-${props.id}`}
							type="text"
							value=""
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Enter the citation'
								}
							]}
						/>
					</>
				)}
				{mediaType === 'video' && (
					<>
						<Input
							label="Video Src"
							name="video"
							id={`video-${props.id}`}
							value=""
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Please enter a video src'
								}
							]}
						/>
						<ImageUploadWithCropper
							accept={'.jpg, .jpeg, .png'}
							type="file"
							label="Upload Image"
							name="image"
							id={`image-${props.id}`}
							aspect={400 / 200}
							validationRules={[
								{
									function: value => {
										return !!value;
									},
									message: 'Please upload an image'
								}
							]}
							resetImage={resetImage}
							setResetImage={setResetImage}
						/>
					</>
				)}
				{mediaType === 'image' && (
					<ImageUploadWithCropper
						accept={'.jpg, .jpeg, .png'}
						type="file"
						label="Upload Image"
						name="image"
						id={`image-${props.id}`}
						aspect={400 / 200}
						validationRules={[
							{
								function: value => {
									return !!value;
								},
								message: 'Please upload an image'
							}
						]}
						resetImage={resetImage}
						setResetImage={setResetImage}
					/>
				)}
				<Input
					label="Order"
					name="order"
					type="number"
					id={`order-${props.id}`}
					value=""
					validationRules={[
						{
							function: value => {
								return value !== '' && typeof value !== 'undefined';
							},
							message: 'Please enter a valid order'
						}
					]}
				/>
				<Button type="submit" variant="primary">
					Update
				</Button>
			</Form>
		</>
	);
}
