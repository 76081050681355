import React, { useState } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';

import { COLORS } from 'root/constants/vars';

import Anchor from '../../Anchor';

import Logo from 'root/assets/logo.png';

import Hamburger from './Hamburger';
import MobileNav from './MobileNav';
import styles from '../Header.module.scss';

const MobileHeader = () => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [activeSubs, setActiveSubs] = useState('');

	return (
		<Div
			type="flex"
			distributedBetween
			vAlign="center"
			className={cx('p2 pos-rel', styles.headerContainer)}>
			<a href="https://hitroop.com">
				<Div className="w-px-150 mx1">
					<img src={Logo} alt="Troop Logo" />
				</Div>
			</a>
			<Div className={cx('pos-rel', styles.hamburgurContainer)}>
				<Hamburger isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
			</Div>
			<Div>
				<a
					href={`https://meetings.hubspot.com/kelly366/troop-demo`}
					target="_blank">
					<Div
						type="flex"
						hAlign="center"
						vAlign="center"
						className={cx(
							'w-px-80 textWhite height-px-40 br-rad-px-5',
							styles.headerDemoBtn
						)}>
						Demo
					</Div>
				</a>
			</Div>
			<Div
				className={cx(
					'pos-abs pos-abs--lb bgLightGray w-per-100 height-px-50 z-10000',
					styles.mobNavContainer,
					isMenuOpen && styles.mobNavContainerActive
				)}>
				<MobileNav isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
			</Div>
		</Div>
	);
};

export default MobileHeader;
