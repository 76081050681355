import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import NeedCard from 'root/baseComponents/NeedCard';

import { getBackendUrl } from 'root/utils/helpers';

import {
	getInReviewNeeds,
	getAllCharities,
	getFeedbackSentForTroopAdmin
} from 'root/services/charity';

import InfoRow from './subs/InfoRow';
import styles from './NeedsToReviewDetails.module.scss';

function NeedsToReviewDetails() {
	const { id } = useParams();

	const [charityInfo, setCharityInfo] = useState({});
	const [inRveiewNeeds, setInRveiewNeeds] = useState([]);
	const [approvedNeeds, setApprovedNeeds] = useState([]);
	const [feedbackSentNeeds, setFeedbackSentNeeds] = useState([]);

	const getInReviewNeedsData = async () => {
		let res = await getInReviewNeeds({ id, status: 'in_review' });
		console.log(res);
		setInRveiewNeeds(res);

		res = await getInReviewNeeds({ id, status: 'approved' });
		setApprovedNeeds(res);
	};

	const getFeedbackSentForTroopAdminData = async () => {
		let res = await getFeedbackSentForTroopAdmin(id);
		console.log(res);
		setFeedbackSentNeeds(res);
	};

	const getCharityInfo = async () => {
		const res = await getAllCharities(id);
		if (res?.[0]) {
			setCharityInfo(res[0]);
		}
	};

	useEffect(() => {
		if (id) {
			getCharityInfo();
			getInReviewNeedsData();
			getFeedbackSentForTroopAdminData();
		}
	}, [id]);

	return (
		<>
			<Div className={cx('pt2 mb2')}>Charity Information</Div>
			<Div className={cx('card p2')}>
				<InfoRow title="Name" info={charityInfo?.name} />
				<InfoRow title="City" info={charityInfo?.city} />
				<InfoRow title="Province" info={charityInfo?.province} />
				<InfoRow title="Postal Code" info={charityInfo?.postal_code} />
				<InfoRow title="Website" info={charityInfo?.website} />
			</Div>
			<Div className={cx('pt2 mb2')}>Needs in review</Div>
			<Div>
				{inRveiewNeeds.map(n => {
					return (
						<Div className="mb2">
							<NeedCard
								needId={n?.id}
								imageSrc={`${getBackendUrl()}${n.cover_photo}`}
								needName={n.title}
								target={n.monetary_value}
								closeDate={n.fundraising_date}
								status={n.status}
								created_by={n.created_by}
								isTroopAdmin
							/>
						</Div>
					);
				})}
			</Div>
			<Div className={cx('pt2 mb2')}>Approved Needs</Div>
			<Div>
				{approvedNeeds.map(n => {
					return (
						<Div className="mb2">
							<NeedCard
								needId={n.id}
								imageSrc={`${getBackendUrl()}${n.cover_photo}`}
								needName={n.title}
								target={n.monetary_value}
								closeDate={n.fundraising_date}
								status={n.status}
								created_by={n.created_by}
								isTroopAdmin
							/>
						</Div>
					);
				})}
			</Div>

			{/* <Div className={cx('pt2 mb2')}>Sent Feedback Needs</Div>
			<Div>
				{feedbackSentNeeds.map(n => {
					return (
						<Div className="mb2">
							<NeedCard
								needId={n?.id}
								imageSrc={n?.image}
								needName={n?.name}
								target={n?.value}
								closeDate={n?.expiry_date}
								status={'feedback_sent'}
								isTroopAdmin
							/>
						</Div>
					);
				})}
			</Div> */}
		</>
	);
}

export default NeedsToReviewDetails;
