import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Elements } from '@stripe/react-stripe-js';
import { useLocation } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import { stripePromise } from './utils';
import CheckoutForm from './subs/CheckoutForm';
import CheckoutFormDirectAddFund from './subs/CheckOutFormDirectAddFund';
import CheckoutFormForGeenees from './subs/CheckoutFormForGeenees';
import styles from './StripeTokenizeCharge.module.scss';

const StripeTokenizeCharge = () => {
	const location = useLocation();

	const [type, setType] = useState('');
	const [directFundAmount, setDirectFundAmount] = useState(0);
	const [selectedGeeneesWish, setSelectedGeeneesWish] = useState({});
	const [geeneesAmount, setGeeneesAmount] = useState(0);
	const [geeneesUseAccountFund, setGeeneesUseAccountFund] = useState(0);

	useEffect(() => {
		console.log(location?.state);
		if (location?.state?.type) {
			if (location.state.type === 'addDirectFund' && location.state?.amount) {
				setType('addDirectFund');
				setDirectFundAmount(location.state.amount);
			}
			if (location.state.type == 'pledge_need') {
				setType('pledge_need');
			}
			if (location.state.type == 'geenees_purchase') {
				console.log(location.state);
				setType('geenees_purchase');
				setSelectedGeeneesWish(location.state.selectedGeeneesWish);
				setGeeneesAmount(location.state.amount);
				setGeeneesUseAccountFund(location.state.use_do_good_account_fund);
			}
		}
	}, [location?.state]);

	return (
		<>
			{stripePromise && (
				<Elements stripe={stripePromise}>
					<Div
						type="flex"
						hAlign="center"
						vAlign="center"
						className={cx(
							'pl4 pr4 pt2 pb2 w-per-100 max-w-px-600 ml-auto mr-auto br-rad-px-10 w-per-100 height-vh-full',
							styles.formContainer
						)}>
						{type === 'pledge_need' && <CheckoutForm />}
						{type === 'addDirectFund' && directFundAmount > 0 ? (
							<CheckoutFormDirectAddFund amount={directFundAmount} />
						) : (
							''
						)}
						{type === 'geenees_purchase' && geeneesAmount > 0 ? (
							<CheckoutFormForGeenees
								amount={geeneesAmount}
								selectedGeeneesWish={selectedGeeneesWish}
								geeneesUseAccountFund={geeneesUseAccountFund}
							/>
						) : (
							''
						)}
					</Div>
				</Elements>
			)}
		</>
	);
};

export default StripeTokenizeCharge;
