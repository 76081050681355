import React, { useState } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';

import { COLORS } from 'root/constants/vars';

import Anchor from '../../Anchor';

import { nav, subnav } from '../constants';

import styles from '../Header.module.scss';

const MobileNav = ({ isMenuOpen, setIsMenuOpen }) => {
	const [activeSubs, setActiveSubs] = useState('');

	return (
		<>
			{isMenuOpen &&
				nav?.map((item, idx) => (
					<Div type="flex" key={idx}>
						<Div
							className="mb2 mouse-hand"
							onClick={() => {
								if (item?.hasSubs) {
									if (activeSubs !== item.identifier) {
										setActiveSubs(item.identifier);
									} else {
										setActiveSubs('');
									}
								}
							}}>
							<Div type="flex" vAlign="center">
								{item?.hasSubs ? (
									<Div>{item.title}</Div>
								) : (
									<Anchor
										toWordPress={item.toWordpress}
										to={item.url}
										isLocal={item.isLocal}>
										<Div>{item.title}</Div>
									</Anchor>
								)}
								{item?.hasSubs && (
									<Div className="ml1">
										<Icon type="angle-down" color="black" />
									</Div>
								)}
							</Div>
							{activeSubs === item.identifier && (
								<Div>
									{subnav?.[item.identifier]?.map((subItem, subIdx) => (
										<Div className="m2" key={subIdx}>
											<Anchor
												toWordPress={subItem.toWordpress}
												to={subItem.url}
												isLocal={subItem.isLocal}
												key={subIdx}>
												<Div
													className={cx(
														'mb2',
														styles.headerSubNavItemContainer
													)}>
													{subItem.title}
												</Div>
											</Anchor>
										</Div>
									))}
								</Div>
							)}
						</Div>
					</Div>
				))}
		</>
	);
};

export default MobileNav;
