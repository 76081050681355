import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import {
	Card,
	Row,
	Col,
	Image,
	Form,
	Input,
	Button,
	Heading,
	Errors,
	Spinner,
	Text,
	Alert,
	Anchor
} from 'troop-design';
import { useDispatch } from 'react-redux';
import logo from 'assets/logo.png';
import { addNotification } from 'root/reducers/notifications';
import {
	resetPassword,
	validateResetToken,
	completeResetPassword
} from 'services/user';
import { useLocation, useHistory, Link } from 'react-router-dom';
import qs from 'query-string';
import styles from './ForgotPassword.module.scss';
import { getEnv } from 'root/utils/helpers';
import Notifications from 'root/components/Notifications/index';

export default function ForgotPassword(props) {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [errorMessages, setErrorMessages] = useState([]);
	const [loading, setLoading] = useState(false);
	const { resetCode } = qs.parse(location.search);
	const step = resetCode ? 2 : 1;

	useEffect(() => {
		if (step === 2) {
			validateResetToken(resetCode).catch(() => {
				setErrorMessages([
					'Your password token is either invalid or expired. Please try again'
				]);
			});
		}
	}, [step, resetCode]);

	const handleResetSubmission = values => {
		setErrorMessages([]);
		setLoading(true);
		dispatch(resetPassword(values.emailAddress))
			.catch(err => {
				setLoading(false);
				setErrorMessages(['Reset failed..']);
			})
			.then(data => {
				setLoading(false);
			});
	};

	const handleResetComplete = values => {
		completeResetPassword(resetCode, values.password)
			.then(data => {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Successfully updated the password.',
						options: {
							autoDismiss: true
						}
					})
				);
				history.push(getEnv('LOGIN_URL'));
			})
			.catch(err => {
				err?.response?.data?.password?.forEach(error => {
					dispatch(
						addNotification({
							type: 'danger',
							message: error
						})
					);
				});
			});
	};

	const renderStep1 = () => {
		return (
			<>
				<Alert type="info" dismissable={false}>
					<Text hasWrapper tag="span" className="font-size--14 alert">
						Enter your email address and we’ll send you a recovery link.
					</Text>
				</Alert>
				<Errors errors={errorMessages} type="form" />
				<Form onSubmit={handleResetSubmission} resetOnSubmit={false}>
					<Input
						label="Email Address"
						name="emailAddress"
						validationRules={[
							{
								rule: 'string.isEmail',
								message: 'Please enter a valid email address'
							}
						]}
					/>

					<div className={styles.loginButtonContainer}>
						<Button
							variant="primary"
							size="lg"
							className={styles.loginBtn}
							disabled={loading}>
							{!loading ? (
								'Reset password'
							) : (
								<Spinner
									type="sync"
									color="white"
									width={12}
									height={12}
									size={8}
									speed={0.15}
								/>
							)}
						</Button>
						<Anchor component={Link} to="/login" variant="flat">
							Back to login
						</Anchor>
					</div>
				</Form>
			</>
		);
	};

	const renderStep2 = () => {
		return (
			<Form onSubmit={handleResetComplete} resetOnSubmit={false}>
				<Errors errors={errorMessages} type="form" />
				<Input
					label="New Password"
					name="password"
					id="password"
					type="password"
					disabled={errorMessages.length}
					validationRules={[
						{
							function: value =>
								value === document.getElementById('passwordConfirm').value,
							message:
								"The password doesn't match with the confirm password field below."
						},
						{
							rule: 'string.isRequired',
							message: 'Please enter a new password'
						}
					]}
				/>
				<Input
					label="Confirm New Password"
					name="passwordConfirm"
					id="passwordConfirm"
					type="password"
					disabled={errorMessages.length}
					validationRules={[
						{
							function: value =>
								value === document.getElementById('password').value,
							message:
								"The confirm password doesn't match with the password field above."
						},
						{
							rule: 'string.isRequired',
							message: 'Please enter a new password'
						}
					]}
				/>

				<div className={styles.loginButtonContainer}>
					<Button
						variant="primary"
						size="lg"
						className={styles.loginBtn}
						disabled={loading}>
						{!loading ? (
							'Reset password'
						) : (
							<Spinner
								type="sync"
								color="white"
								width={12}
								height={12}
								size={8}
								speed={0.15}
							/>
						)}
					</Button>
					<Anchor component={Link} to="/login" variant="flat">
						Back to login
					</Anchor>
				</div>
			</Form>
		);
	};
	return (
		<div className={cx('container', styles.container)}>
			<Row className="justify-content-center align-items-center w-100">
				<Col md={8} xl={5}>
					<Card className={styles.loginContainer}>
						<div className={styles.logoContainer}>
							<Image src={logo} className={styles.logo} />
							<Heading level={1} className={styles.heading}>
								Reset Password
							</Heading>
						</div>
						<Notifications />
						{step === 1 ? renderStep1() : renderStep2()}
					</Card>
				</Col>
			</Row>
		</div>
	);
}
