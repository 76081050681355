export const emailValidators = [
	{ type: 'required', message: 'Email is required' },
	{
		type: 'email',
		message: 'Must be a valid email address'
	}
];

export const firstNameValidators = [
	{ type: 'required', message: 'First name is required' },
	{
		type: 'minRequired',
		message: 'First name must be at least 2 characters',
		minRequired: 2
	},
	{
		type: 'maxRequired',
		message: 'First name must be less than 100 characters',
		maxRequired: 100
	}
];
