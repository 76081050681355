import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import SliderDot from 'root/components/SliderDot';
import Button from 'root/baseComponents/Button';
import Form from 'root/baseComponents/Form';
import TextBox from 'root/baseComponents/TextBox';
import FormSelect from 'root/baseComponents/FormSelect';

import {
	updateCharityInfo,
	updateCharityUserInfo
} from 'root/services/charity';

import {
	schoolDistrictValidators,
	registeredCharityNameValidators,
	registeredCharityNumberValidators,
	orgWebsiteValidators,
	contactNumberValidators,
	cityValidators,
	postalCodeValidators
} from '../utils';

import { PROVINCE_CHOICES, ORGANIZATION_TYPE_CHOICES } from '../constants';

import styles from '../CharityRegistration.module.scss';

function MoreInfo({ setFormSubmitted, organizationName, email, charityId }) {
	const [organizationType, setOrganizationType] = useState('');
	const [organizationTypeErrorMessage, setOrganizationTypeErrorMessage] =
		useState('');

	const [registeredCharityName, setRegisteredCharityName] =
		useState(organizationName);
	const [
		registeredCharityNameErrorMessage,
		setRegisteredCharityNameErrorMessage
	] = useState('');

	const [registeredCharityNumber, setRegisteredCharityNumber] = useState('');
	const [
		registeredCharityNumberErrorMessage,
		setRegisteredCharityNumberErrorMessage
	] = useState('');

	const [aboutOrganization, setAboutOrganization] = useState('');
	const [aboutOrganizationErrorMessage, setAboutOrganizationErrorMessage] =
		useState('');

	const [schoolDistrict, setSchoolDistrict] = useState('');
	const [schoolDistrictErrorMessage, setSchoolDistrictErrorMessage] =
		useState('');

	const [orgWebsite, setOrgWebsite] = useState('');
	const [orgWebsiteErrorMessage, setOrgWebsiteErrorMessage] = useState('');

	const [contactNumber, setContactNumber] = useState('');
	const [contactNumberErrorMessage, setContactNumberErrorMessage] =
		useState('');

	const [organizationAddress, setOrganizationAddress] = useState('');
	const [organizationAddressErrorMessage, setOrganizationAddressErrorMessage] =
		useState('');

	const [city, setCity] = useState('');
	const [cityErrorMessage, setCityErrorMessage] = useState('');

	const [province, setProvince] = useState('');
	const [provinceErrorMessage, setProvinceErrorMessage] = useState('');

	const [postalCode, setPostalCode] = useState('');
	const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('');

	const [toBeValidatedFields, setToBeValidatedFields] = useState([]);

	const toBeValidatedFields1 = [
		{
			input_name: 'registred_charity_name',
			validators: registeredCharityNameValidators,
			errorMessageHandler: setRegisteredCharityNameErrorMessage
		},

		{
			input_name: 'registred_charity_number',
			validators: registeredCharityNumberValidators,
			errorMessageHandler: setRegisteredCharityNumberErrorMessage
		},

		{
			input_name: 'organization_website',
			validators: orgWebsiteValidators,
			errorMessageHandler: setOrgWebsiteErrorMessage
		},

		{
			input_name: 'contact_number',
			validators: contactNumberValidators,
			errorMessageHandler: setContactNumberErrorMessage
		},

		{
			input_name: 'city',
			validators: cityValidators,
			errorMessageHandler: setCityErrorMessage
		},

		{
			input_name: 'postal_code',
			validators: postalCodeValidators,
			errorMessageHandler: setPostalCodeErrorMessage
		}
	];

	const toBeValidatedFields2 = [
		{
			input_name: 'school_district',
			validators: schoolDistrictValidators,
			errorMessageHandler: setSchoolDistrictErrorMessage
		},

		{
			input_name: 'organization_website',
			validators: orgWebsiteValidators,
			errorMessageHandler: setOrgWebsiteErrorMessage
		},

		{
			input_name: 'city',
			validators: cityValidators,
			errorMessageHandler: setCityErrorMessage
		},

		{
			input_name: 'postal_code',
			validators: postalCodeValidators,
			errorMessageHandler: setPostalCodeErrorMessage
		}
	];

	const toBeValidatedFields3 = [
		{
			input_name: 'organization_website',
			validators: orgWebsiteValidators,
			errorMessageHandler: setOrgWebsiteErrorMessage
		},

		{
			input_name: 'city',
			validators: cityValidators,
			errorMessageHandler: setCityErrorMessage
		},

		{
			input_name: 'postal_code',
			validators: postalCodeValidators,
			errorMessageHandler: setPostalCodeErrorMessage
		}
	];

	useEffect(() => {
		if (organizationType === 'charity') {
			setToBeValidatedFields(toBeValidatedFields1);
		} else if (organizationType === 'school') {
			setToBeValidatedFields(toBeValidatedFields2);
		} else {
			setToBeValidatedFields(toBeValidatedFields3);
		}
	}, [organizationType]);

	const customValidations = () => {
		let validated = true;

		if (!organizationType) {
			setOrganizationTypeErrorMessage('Organization Type is required');
			validated = false;
		}

		if (!province) {
			setProvinceErrorMessage('Province is required');
			validated = false;
		}

		return validated;
	};

	const submitHandler = async () => {
		if (customValidations()) {
			console.log('Here!!!');
			const data = {
				charity_id: charityId,
				charity_name: organizationName,
				registered_name: registeredCharityName,
				registered_charity_number: registeredCharityNumber,
				about_organization: aboutOrganization,
				school_district: schoolDistrict,
				website: orgWebsite,
				charity_type: organizationType,
				address: organizationAddress,
				city,
				province,
				postal_code: postalCode
			};
			const res = await updateCharityInfo(data);
			console.log(res);

			// const userData = {
			// 	charity_name: organizationName,
			// 	email,
			// 	phone: contactNumber
			// };
			// const userRes = await updateCharityUserInfo(userData);
			setFormSubmitted(true);
		}
	};

	return (
		<>
			<div className={cx('pb3', styles.container, styles.containerOtherPages)}>
				<div className={cx(styles.moreInfoContainer)}>
					<div
						className={cx(
							'p1 flex flex--dir--col flex--jc--center flex--ai--center',
							styles.moreInfoContent
						)}>
						<div className={cx('p1', styles.sliderContainer)}>
							<SliderDot isActive={true} />
							<SliderDot isActive={true} />
						</div>
						<h1 className={cx('text-center fs-px-20 my1')}>
							Tell us a bit about your organization
						</h1>
						<p className={cx('my1 text-center fs-px-14')}>
							We need this for regulatory reasons.
						</p>
						<Form
							toBeValidatedFields={toBeValidatedFields}
							className={cx(styles.form)}
							onSubmit={submitHandler}>
							{/* <FormImagePicker labelText="Photo" /> */}
							{/* <FormDatePicker labelText="Birth Date" /> */}
							<FormSelect
								className={cx('mb4')}
								labelText="Organization Type"
								options={ORGANIZATION_TYPE_CHOICES}
								fullWidth
								openOptionsDownWard={true}
								isRequired
								val={organizationType}
								setVal={setOrganizationType}
								errorMessage={organizationTypeErrorMessage}
								errorHandler={setOrganizationTypeErrorMessage}
							/>
							{organizationType === 'school' && (
								<TextBox
									labelText="School District"
									type="text"
									val={schoolDistrict}
									setVal={setSchoolDistrict}
									errorHandler={setSchoolDistrictErrorMessage}
									name="school_district"
									errorMessage={schoolDistrictErrorMessage}
								/>
							)}
							{organizationType === 'charity' && (
								<>
									<TextBox
										isRequired
										labelText="Registered Charity Name"
										type="text"
										val={registeredCharityName}
										setVal={setRegisteredCharityName}
										name="registred_charity_name"
										errorHandler={setRegisteredCharityNameErrorMessage}
										errorMessage={registeredCharityNameErrorMessage}
									/>
									<TextBox
										isRequired
										labelText="CRA Registered Charity #"
										type="text"
										val={registeredCharityNumber}
										setVal={setRegisteredCharityNumber}
										name="registred_charity_number"
										errorHandler={setRegisteredCharityNumberErrorMessage}
										errorMessage={registeredCharityNumberErrorMessage}
									/>
								</>
							)}
							<TextBox
								labelText="About Your Organization"
								type="text"
								val={aboutOrganization}
								setVal={setAboutOrganization}
								name="about_organization"
								errorHandler={setAboutOrganizationErrorMessage}
								errorMessage={aboutOrganizationErrorMessage}
							/>
							<TextBox
								labelText="Organization Website"
								type="text"
								name="organization_website"
								isRequired
								val={orgWebsite}
								setVal={setOrgWebsite}
								errorHandler={setOrgWebsiteErrorMessage}
								errorMessage={orgWebsiteErrorMessage}
							/>
							<TextBox
								className={cx(styles.contactNumber)}
								labelText="Contact Number"
								type="text"
								val={contactNumber}
								setVal={setContactNumber}
								name="contact_number"
								errorHandler={setContactNumberErrorMessage}
								errorMessage={contactNumberErrorMessage}
								isRequired
							/>
							<TextBox
								isRequired
								labelText="Organization Address"
								type="text"
								val={organizationAddress}
								setVal={setOrganizationAddress}
								name="organization_address"
								errorHandler={setOrganizationAddressErrorMessage}
								errorMessage={organizationAddressErrorMessage}
							/>
							<div className={cx(styles.multiInputContainer)}>
								<TextBox
									isRequired
									className={cx('mr1', styles.city)}
									labelText="City"
									type="text"
									val={city}
									setVal={setCity}
									name="city"
									errorMessage={cityErrorMessage}
									errorHandler={setCityErrorMessage}
								/>
								<FormSelect
									isRequired
									className={cx('mr1', styles.province)}
									labelText="Province"
									options={PROVINCE_CHOICES}
									fullWidth
									openOptionsDownWard={false}
									val={province}
									setVal={setProvince}
									errorMessage={provinceErrorMessage}
									errorHandler={setProvinceErrorMessage}
								/>
								<TextBox
									isRequired
									className={cx(styles.postalCode)}
									labelText="Postal Code"
									type="text"
									val={postalCode}
									setVal={setPostalCode}
									name="postal_code"
									errorMessage={postalCodeErrorMessage}
									errorHandler={setPostalCodeErrorMessage}
								/>
							</div>
							<div className={cx('my2 flex flex--jc--end')}>
								<Button type="submit">Continue</Button>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}

export default MoreInfo;
