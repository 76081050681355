import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	employees: [],
	employeeUploadError: false
};

export const employeeSlice = createSlice({
	name: 'employees',
	initialState,
	reducers: {
		getEmployees: (state, action) => {
			state.employees = action.payload;
		},
		addEmployees: (state, action) => {
			state.employees = [...action.payload];
		},
		addEmployee: (state, action) => {
			state.employees.push(action.payload);
		},
		updateEmployee: (state, action) => {
			const index = state.employees.findIndex(
				employee => employee.id === parseInt(action.payload.id, 10)
			);
			state.employees[index] = action.payload.data;
		},
		deleteEmployee: (state, action) => {
			state.employees = state.employees.filter(
				employee => employee.id !== parseInt(action.payload, 10)
			);
		},
		employeeUploadFailed: (state, action) => {
			state.employeeUploadError = action.payload;
		}
	}
});

export default employeeSlice.reducer;
export const {
	getEmployees,
	addEmployees,
	addEmployee,
	updateEmployee,
	deleteEmployee,
	employeeUploadFailed
} = employeeSlice.actions;
