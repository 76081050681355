import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import ReactCrop from 'react-image-crop';
import { Div, Image as BaseImage } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';
import Close from 'root/baseComponents/Close';

import 'react-image-crop/dist/ReactCrop.css';

import { cropImage, getCroppedImg } from '../utils';
import { CANVAS_ID, IMAGE_CROPPER_ID, CROPPER_ID } from '../constants';

import styles from '../ImagePicker.module.scss';

const Cropper = ({
	src,
	setSrc,
	setFile,
	fileName,
	setShowCropper,
	cropInfo,
	cropCompleted,
	setCropCompleted
}) => {
	const aspect = cropInfo?.aspect || 1;
	const minWidth = cropInfo?.minWidth || 200;
	const minHeight = cropInfo?.minHeight || 100;
	const maxWidth = cropInfo?.maxWidth || 500;
	const maxHeight = cropInfo?.maxHeight || 500;
	const useScaledSize = cropInfo?.useScaledSize;

	const [crop, setCrop] = useState();
	const [widthScale, setWidthScale] = useState(1);
	const [heightScale, setHeightScale] = useState(1);
	const [showSubmit, setShowSubmit] = useState(false);

	const doCrop = () => {
		return new Promise(function (resolve, reject) {
			const cropped = cropImage(src, crop.x, crop.y, crop.width, crop.height);
			setTimeout(() => {
				if (cropped) {
					resolve('Stuff worked!');
				} else {
					reject(Error('It broke'));
				}
			}, 1000);
		});
	};

	const cropHandler = e => {
		e.preventDefault();
		doCrop().then(() => {
			setFile(getCroppedImg(setSrc, fileName));
			if (setCropCompleted !== null) {
				setCropCompleted(true);
			}
			setShowCropper(false);
			setTimeout(() => {
				if (setCropCompleted !== null) {
					setCropCompleted(false);
				}
			}, 100);
		});
	};

	const getScaledVal = () => {
		const cropper = document.getElementById(CROPPER_ID);
		const originalImage = new Image();
		originalImage.src = src;
		setTimeout(() => {
			setWidthScale(cropper.offsetWidth / originalImage.width);
			setHeightScale(cropper.offsetHeight / originalImage.height);
		}, 1000);
	};

	useEffect(() => {
		if (useScaledSize && showSubmit) {
			getScaledVal();
		}
	}, [src, useScaledSize, showSubmit]);

	return (
		<>
			<Div
				type="flex"
				direction="vertical"
				hAlign="center"
				vAlign="center"
				className={cx(
					'pos-fix pos-fix--center p3 bgWhite',
					styles.cropperContainer
				)}
				id={CROPPER_ID}>
				<Close
					onClick={() => {
						if (setCropCompleted !== null) {
							setCropCompleted(true);
						}
						setShowCropper(false);
						setTimeout(() => {
							if (setCropCompleted !== null) {
								setCropCompleted(false);
							}
						}, 100);
					}}
				/>
				{showSubmit && (
					<ReactCrop
						aspect={aspect}
						minWidth={minWidth * widthScale}
						minHeight={minHeight * heightScale}
						maxWidth={maxWidth * widthScale}
						maxHeight={maxHeight * heightScale}
						crop={crop}
						onChange={c => {
							setCrop(c);
						}}
						className={styles.reactCropper}>
						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className={cx(styles.cropper)}>
							<BaseImage src={src} id={IMAGE_CROPPER_ID} />
						</Div>
					</ReactCrop>
				)}
				{!showSubmit && (
					<Div
						type="flex"
						hAlign="center"
						vAlign="center"
						className={cx(styles.cropper)}>
						<BaseImage src={src} id={IMAGE_CROPPER_ID} />
					</Div>
				)}
				<Div
					type="flex"
					hAlign="center"
					vAlign="center"
					className="w-per-100 mt2">
					{!showSubmit && (
						<Button
							onClick={() => {
								setCrop({
									width: minWidth,
									height: minWidth / aspect,
									x: 0,
									y: 0,
									unit: 'px'
								});
								setShowSubmit(true);
							}}>
							Crop
						</Button>
					)}
					{showSubmit && <Button onClick={cropHandler}>Submit</Button>}
					<Div className={cx(styles.canvasContainer)}>
						<canvas id={CANVAS_ID} className=""></canvas>
					</Div>
				</Div>
			</Div>
		</>
	);
};

export default Cropper;
