import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Image, Text, Icon, Anchor } from 'troop-design';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { Div, Row, Column } from 'basedesign-iswad';
import { useGA4 } from 'react-ga4';
import ReactGA from "react-ga4";

import Button from 'root/baseComponents/Button';
import BaseIcon from 'root/baseComponents/Icon';
import EligibleForTax from 'root/components/EligibleForTax';
import TopVotedPhoto from 'root/assets/TopVoted.png';

import { submitEmployeeVote } from 'services/landingPages';
import { addNotification } from 'root/reducers/notifications';
import { getPledgedNeedInfo, pledgeNeed } from 'root/services/needs';
import { ICON_WIDTH } from '../constants';
import { showModal } from 'reducers/modals';
import { getClientUrl } from 'utils/helpers';
import { createUSerEvent } from 'services/user';
import { voteNow } from 'root/reducers/users';
import { setChosenNeed, showComponent } from 'root/reducers/voteCelebration';
import { setSeenFeedbackModal } from 'root/reducers/reminders';

import styles from '../EmployeeVoteSection.module.scss';

const excerptLength = 100;

const EmployeeVoteCard = ({
	need,
	businessId,
	voteIsOpen,
	employeeVoted,
	setEmployeeVoted,
	votedNeedId,
	setVotedNeedId,
	monthDate,
	isCompacted,
	showFullText,
	setShowFullText
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	// const { gtag } = useGA4();

	const upcomingEvent = useSelector(state => state.upcomingEvent);
	const email = useSelector(state => state?.users?.loggedInUser?.email);
	const employeeKey = useSelector(
		state => state?.users?.businessUserProfile?.key
	);
	const toBePledgedNeed = useSelector(state => state.needs.toBePledgedNeed);
	const businessKey = useSelector(state => state.businesses.businesses)?.[0]
		?.key;

	// const [src, setSrc] = useState('');

	const [needStatus, setNeedStatus] = useState('');
	const [pledgedAmount, setPledgedAmount] = useState(null);
	const [pledgedInfo, setPledgedInfo] = useState(null);
	const [pledgersNum, setPledgersNum] = useState(0);
	const [pledgePercent, setPledgePercent] = useState(0);
	const [allPledgedAmount, setAllPledgedAmount] = useState(0);
	const [showPledgeInfo, setShowPledgeInfo] = useState(false);
	const [votePageUrl, setVotePageUrl] = useState('');
	const [votedNeed, setVotedNeed] = useState(false);
	const [hitByTarget, setHitByTarget] = useState(false);
	const [words, setWords] = useState([]);
	const [toBeSubmitted, setToBeSubmitted] = useState(false);

	// I need to remove the following part before merging to develop branch
	// useEffect(() => {
	// 	let url = need.need.image;
	// 	const urlSplitted = url.split('http://localhost:8000/');
	// 	setSrc(`https://stagingapi.hitroop.com/${urlSplitted[1]}`);
	// }, [need, voteIsOpen]);

	useEffect(() => {
		const getPledgedInfo = async () => {
			const data = await getPledgedNeedInfo(businessId, need.id);
			setPledgedInfo(data);
			if (Object?.keys(data?.pledger_employees)?.length) {
				setPledgersNum(Object.keys(data.pledger_employees).length);
			}
			setPledgePercent((data?.collected_money / data?.need_value) * 100);
			setAllPledgedAmount(data?.collected_money);
		};
		if (need && businessId) {
			getPledgedInfo();
		}
	}, [need, businessId, toBePledgedNeed]);

	useEffect(() => {
		if (pledgedInfo) {
			if (employeeVoted && votedNeedId && need?.id === votedNeedId) {
				setVotedNeed(true);
			}
			if (dayjs().isAfter(dayjs(`${need.pledge_close_date} 24:00`))) {
				if (need.is_vote_winner) {
					setNeedStatus('TopVoted');
				}
			} else {
				if (Object.keys(pledgedInfo.pledger_employees).includes(email)) {
					setNeedStatus('Pledged');
					setPledgedAmount(pledgedInfo.pledger_employees[email]);
				} else {
					if (voteIsOpen) {
						if (!employeeVoted) {
							setNeedStatus('Vote');
						} else {
							setNeedStatus('Pledge');
						}
					} else {
						if (need.is_vote_winner) {
							setNeedStatus('TopVoted');
						} else {
							setNeedStatus('Pledge');
						}
					}
				}
			}
		}
	}, [need, voteIsOpen, employeeVoted, votedNeedId, email, pledgedInfo]);

	useEffect(() => {
		if (
			!need.is_vote_winner &&
			dayjs().isBefore(dayjs(`${need.pledge_close_date} 24:00`))
		) {
			if (
				employeeVoted ||
				dayjs().isAfter(dayjs(`${need.vote_close_date} 17:00`))
			) {
				setShowPledgeInfo(true);
			}
		}
	}, [employeeVoted, need, pledgersNum]);

	const handleVote = () => {
		createUSerEvent({ event: 'Vote from employee dashboard' });
		const data = {
			email,
			business_id: businessId,
			business_need_id: need.id
		};
		submitEmployeeVote(businessId, data)
			.then(response => {
				setEmployeeVoted(true);
				setVotedNeedId(need.id);
				dispatch(voteNow());
				if (
					upcomingEvent?.name &&
					upcomingEvent?.register_status === 'upcoming'
				) {
					dispatch(showModal('event-form'));
				} else {
					dispatch(setSeenFeedbackModal(false));
				}
				// dispatch(setChosenNeed(need.need));
				// dispatch(showComponent());
				history.push('/');
			})
			.catch(err => {
				dispatch(
					addNotification({
						type: 'danger',
						message: 'An error occurred. Please try again.'
					})
				);
			});
	};

	useEffect(() => {
		if (employeeVoted && businessId && businessKey && monthDate) {
			setVotePageUrl(
				`${getClientUrl()}/vote/${businessId}/${businessKey}/${monthDate}?employee=${employeeKey}&read_only=true`
			);
		} else {
			setVotePageUrl(
				`${getClientUrl()}/vote/${businessId}/${businessKey}/${monthDate}?employee=${employeeKey}`
			);
		}
	}, [employeeVoted, businessId, businessKey, monthDate]);

	useEffect(() => {
		if (allPledgedAmount && need?.need) {
			if (allPledgedAmount >= need.need.value) {
				setHitByTarget(true);
			}
		}
		if (need?.hit_target_by_pledge) {
			setHitByTarget(true);
		}
	}, [allPledgedAmount, need]);

	useEffect(() => {
		console.log(need);
		if (need?.need?.business_description?.split('')?.length) {
			const localWords = need?.need?.business_description?.split('');
			setWords(localWords);
		}
	}, [need]);

	return (
		<>
			<div
				className={cx(
					styles.card,
					need.is_vote_winner && !need.need.is_fulfilled
						? styles.cardWinner
						: ''
					// hitByTarget && styles.cardHitTargetByPledge,
					// need.is_vote_winner && need.need.is_fulfilled
					// 	? styles.cardFulfilled
					// 	: ''
				)}>
				<Div className="w-per-100" type="flex" direction="vertical">
					<div className={cx('bgGray', styles.cardImage)}>
						{/* dayjs().isBefore(dayjs(`${need.vote_close_date} 17:00`) */}
						{votedNeed ? (
							<div className={cx(styles.votedIcon)}>
								{/* <div className={cx(styles.votedIconIcon)}>
									<Icon icon="checkmark-voted" color="black" width={30} />
								</div> */}
								<div>Your Vote</div>
							</div>
						) : (
							''
						)}

						{needStatus === 'Pledged' && votedNeed ? (
							<div className={cx(styles.topVoted)}>

								<img src={TopVotedPhoto} width={100} />
								{/* <div>
									${parseFloat(pledgedAmount).toFixed(2)} Pledged!{' '}
									<img src={TopVotedPhoto} width={100} />
								</div> */}
							</div>
						) : (
							''
						)}
						{/* {showPledgeInfo ? (
						<div className={cx(styles.cardImagePledgeInfo)}>
							<div>
								{`
								$${parseFloat(allPledgedAmount)?.toFixed(2)} of $${parseInt(
									pledgedInfo?.need_value
								).toFixed(2)}
								target`}
							</div>
							<div className={cx(styles.cardImagePledgeInfoPerAll)}>
								<div
									className={cx(styles.cardImagePledgeInfoPerFill)}
									style={{
										width: `${pledgePercent}%`
									}}></div>
							</div>
							<div>
								{pledgersNum > 1
									? `${pledgersNum} Teammates pledged!`
									: `${pledgersNum} Teammate pledged!`}
							</div>
						</div>
					) : (
						''
					)} */}
						{/* {dayjs().isBefore(dayjs(`${need.pledge_close_date} 24:00`)) &&
						!voteIsOpen &&
						!need.hitByTarget &&
						!need.is_vote_winner ? (
							<div className={cx(styles.cardImageInfoBar)}>
								It's not too late
							</div>
						) : (
							''
						)} */}
						{/* {!voteIsOpen && !need.need.is_fulfilled && need.is_vote_winner ? (
							<Div
								type="flex"
								hAlign="center"
								vAlign="center"
								className={cx(styles.votedIcon)}>
								<Div className={cx(styles.votedIconIcon)}>
									<BaseIcon type="star" color="black" />
								</Div>
								<Div>Top Voted</Div>
							</Div>
						) : (
							''
						)} */}

						{need?.is_eligible_for_tax_receipt ? (
							<div className={cx(styles.eligibleContainer)}>
								<EligibleForTax
									isEligible={need?.is_eligible_for_tax_receipt}
								/>
							</div>
						) : (
							''
						)}
						<Image
							src={need.need.image}
							// Need to change src to the above value before merging
							// src={src}
							className={cx(styles.cardImagePhoto)}
						/>
					</div>

					<div
						className={cx(
							styles.cardInfo,
							isCompacted && styles.cardInfoCompacted
						)}>
						{isCompacted && (
							<>
								<div>
									<Div
										type="flex"
										hAlign="start"
										className={cx('mt2', styles.cardInfoBoldShort)}>
										{/* {need.need.community_supported || need.need.name} */}
										{need.need.name}
									</Div>
								</div>
								<Div
									type="flex"
									hAlign="start"
									className={cx(
										'oneLine textFaded',
										styles.cardInfoShortCharity
									)}>
									<Div className=''>{need.need.charity_name}</Div>
								</Div>
				{/* THE GRAY LINE */}
								<div
									style={{
										background: 'silver',
										height: '2px',
										width: '50px'
									}}
								/>
				{/*------------------------- NEED DESCRIPTION FOR DIFFERENT STATES ----------------------*/}
								{needStatus === 'Vote' ? (
									<Div
										type="flex"
										hAlign="start"
										className={cx('w-per-100 text-ltr', styles.needSummary)}>
										<Div>{need.need.business_description}</Div>
									</Div>
								) : (
									''
								)}
								{needStatus === 'Pledge' ? (
									<Div
										type="flex"
										hAlign="start"
										className={cx('w-per-100 text-ltr', styles.pledgeNeedsSummary)}>
										<Div>{need.need.business_description}</Div>
									</Div>
								) : (
									''
								)}
								{needStatus === 'Pledged' && !hitByTarget ?
									<Div
									type="flex"
									hAlign="start"
									className={cx('w-per-100 text-ltr', styles.pledgeNeedsSummary)}>
									<Div>{need.need.business_description}</Div>
								</Div>
							: (
								''
							)}
								{hitByTarget && needStatus !== 'Pledge' && needStatus !== 'Vote' && needStatus !== 'Pledged'? (
									<Div
										type="flex"
										hAlign="start"
										className={cx('w-per-100 text-ltr', styles.needSummary)}>
										<Div>{need.need.business_description}</Div>
									</Div>
								) : ''}
								{need.is_vote_winner && need.need.is_fulfilled && needStatus !== 'Pledge' && needStatus !== 'Vote' && needStatus !== 'Pledged' ? (
									<Div
										type="flex"
										hAlign="start"
										className={cx('w-per-100 text-ltr', styles.needSummary)}>
										<Div>{need.need.business_description}</Div>
									</Div>
								) : (
									''
								)}
								{!need.is_vote_winner && needStatus !== 'Pledge' && needStatus !== 'Vote'  && needStatus !== 'Pledged'   ?  (
									<Div
										type="flex"
										hAlign="start"
										className={cx('w-per-100 text-ltr', styles.needSummary)}>
										<Div>{need.need.business_description}</Div>
									</Div> 
								) : ''}
				{/*-------------------------END OF NEED DESCRIPTION FOR DIFFERENT STATES ----------------------*/}
							</>
						)}
					</div>

					{!isCompacted && (
						<>
							<Div className="mb2 ml2">
								<div>
									<Div
										type="flex"
										hAlign="start"
										className={cx('mt2 mb1', styles.cardInfoBoldShort)}>
										{/* {need.need.community_supported || need.need.name} */}
										{need.need.name}
									</Div>
								</div>
								<Div
									type="flex"
									hAlign="start"
									className={cx('oneLine mb1', styles.cardInfoShortCharity)}>
									<a
										className={cx(
											'text-underline fs-px-12 textFaded mouse-hand'
										)}
										href={`${getClientUrl()}/needs/${
											need.need.charity_slug
										}/tr-${need.need.id}`}
										target="_blank">
										{need.need.charity_name}
										<BaseIcon type="arrow-right-up" color="#707070" />
									</a>
								</Div>
								<div
									style={{
										background: 'silver',
										height: '2px',
										width: '50px'
									}}
								/>
							</Div>

							<Div className={cx(styles.cardInfo, styles.detailedInfo)}>
								{need?.need.business_description}
							</Div>

							{/* {!showFullText && words?.length > excerptLength ? (
									<>
										<div>
											{words.slice(0, excerptLength).join('')}...{' '}
											<span
												className={cx(styles.showMore)}
												onClick={() => setShowFullText(!showFullText)}>
												show more
											</span>{' '}
											<Icon
											className={cx(styles.showMoreIcon)}
											icon="chevron-right"
											color="black"
											width={15}
										/>
										</div>
									</>
								) : (
									<>
										<div>
											{need?.need.business_description}{' '}
											{words?.length > excerptLength ? (
												<span
													className={cx(styles.showMore)}
													onClick={() => setShowFullText(!showFullText)}>
													show less
												</span>
											) : (
												''
											)}
										</div>
									</>
								)} */}
						</>
					)}
				</Div>
				{/* <div className="fs-px-12 my1">Target: {need?.need.value}$</div> */}
				<Div type="flex" direction="vertical" className="w-per-100">
					<Div className="w-per-100">
						{showPledgeInfo ? (
							<div className={cx(styles.cardImagePledgeInfo)}>
								{/* {needStatus === 'Pledged' && !votedNeed ? (
									<div className={cx(styles.votedIcon)}>
										<div className={cx(styles.votedIconIcon)}>
											<Icon icon="heart" color="black" width={30} />
										</div>
										<div>${parseFloat(pledgedAmount).toFixed(2)} Pledged!</div>
									</div>
								) : (
									''
								)} */}
								{needStatus === 'Pledged' ? (
									<Div type="flex" distributedBetween className="w-per-100">
										<div className={cx('ml2')}>
											<div className="fs-px-12 f-b">
												You Pledged ${parseFloat(pledgedAmount).toFixed(2)}
											</div>
										</div>

										<Div type="flex">
											<Div className={cx(styles.pledgedAmount)}>{`$${parseFloat(
												allPledgedAmount
											)?.toFixed(0)} `}</Div>
											<Div>
												{`/ $${parseInt(pledgedInfo?.need_value).toFixed(0)}`}
											</Div>
										</Div>
									</Div>
								) : (
									<Div
										type="flex"
										vAlign="end"
										hAlign="end"
										className="w-per-100 mr3">
										<Div type="flex">
											<Div className={cx(styles.pledgedAmount)}>{`$${parseFloat(
												allPledgedAmount
											)?.toFixed(0)} `}</Div>
											<Div>
												{`/ $${parseInt(pledgedInfo?.need_value).toFixed(0)}`}
											</Div>
										</Div>
									</Div>
								)}

								<div className={cx(styles.cardImagePledgeInfoPerAll)}>
									<div
										className={cx(styles.cardImagePledgeInfoPerFill)}
										style={{
											width: `${pledgePercent}%`
										}}></div>
								</div>
							</div>
						) : (
							''
						)}
					</Div>
					{dayjs().format('M-YY') === dayjs(need?.monthDate).format('M-YY') && (
						<div className={cx(styles.cardFooter)}>
							{!need.is_vote_winner ? (
								<>
									{needStatus === 'Vote' && (
										<>
											{!toBeSubmitted ? (
												<Row>
													<Column xs={6} sm={6} md={6} lg={6}>
														<Button
															className={cx(
																'w-per-50',
																styles.cardFooterBtn,
																styles.cardFooterBtnVote
															)}
															onClick={() => {
																setToBeSubmitted(true);
																ReactGA.event({
																	event_name: 'click_vote',
																	category: "Button Click",
																	action: "Vote clicked"
																  });
																	}
																}
																  >
															VOTE!
														</Button>
													</Column>
													<Column xs={6} sm={6} md={6} lg={6}>
														{' '}
														{isCompacted && (
															<Div
																type="flex"
																vAlign="center"
																hAlign="center"
																className={cx('f-b', styles.learnMore)}>
																<Div
																	type="flex"
																	vAlign="center"
																	className={cx('', styles.anchor)}
																	onClick={() =>
																		history.push('/needs-profiles')
																	}>
																	Learn more
																</Div>
															</Div>
														)}
													</Column>
												</Row>
											) : (
												<Div>
													<Button
														className="w-per-100 mb2 pos-rel"
														onClick={handleVote}>
														<Div type="flex" hAlign="center" vAlign="center">
															<Div className="pos-abs pos-abs--lt">
																<Icon
																	icon="checkmark-voted"
																	color="white"
																	width={'40'}
																/>
															</Div>
															<Div>Submit Vote</Div>
														</Div>
													</Button>
													<Button
														className="w-per-100"
														onClick={() => setToBeSubmitted(false)}
														colorType="cancel">
														Cancel
													</Button>
												</Div>
											)}
										</>
									)}
									{needStatus === 'Pledge' && !hitByTarget ? (
										// <Div className="w-per-100">
										// 	<Button
										// 		className={cx(
										// 			'w-per-50',
										// 			styles.cardFooterBtn,
										// 			styles.cardFooterBtnPledge
										// 		)}
										// 		onClick={() => {	
										// 			dispatch(pledgeNeed(need));
										// 			dispatch(showModal('pledgeNeed'));
										// 			createUSerEvent({
										// 				event: 'Click on Pledge button in Vote Card'
										// 			});
										// 		}}>
										// 		PLEDGE!
										// 	</Button>
										// </Div>
										<Row>
											<Column xs={6} sm={6} md={6} lg={6}>
											<Button
												className={cx(
													'w-per-50',
													styles.cardFooterBtn,
													styles.cardFooterBtnPledge
												)}
												onClick={() => {
													ReactGA.event({
														event_name: 'click_pledge',
														category: "Button Click",
														action: "pledge clicked"
													  });
													dispatch(pledgeNeed(need));
													dispatch(showModal('pledgeNeed'));
													createUSerEvent({
														event: 'Click on Pledge button in Vote Card'
													});
												}}>
												PLEDGE!
											</Button>
													</Column>
													<Column xs={6} sm={6} md={6} lg={6}>
														{' '}
														{isCompacted && (
															<Div
																type="flex"
																vAlign="center"
																hAlign="center"
																className={cx('f-b', styles.learnMore)}>
																<Div
																	type="flex"
																	vAlign="center"
																	className={cx('', styles.anchor)}
																	onClick={() =>
																		history.push('/needs-profiles')
																	}>
																	Learn more
																</Div>
															</Div>
														)}
													</Column>
												</Row>
									) : (
										''
									)}
									{needStatus === 'Pledged' && !hitByTarget ? (
										<Button
											className={cx(
												styles.cardFooterBtn
												// styles.cardFooterBtnPledgeMore
											)}
											onClick={() => {
												ReactGA.event({
													event_name: 'click_pledge',
													category: "Button Click",
													action: "pledge clicked"
												  });
												dispatch(pledgeNeed(need));
												dispatch(showModal('pledgeNeed'));
												createUSerEvent({
													event: 'Click on Pledge More button'
												});
											}}>
											PLEDGE AGAIN!
										</Button>
									) : (
										''
									)}
								</>
							) : (
								''
							)}
						</div>
					)}
					{/* {needStatus === 'Pledged' && !need.is_vote_winner && !hitByTarget ? (
					<div
						className={cx(
							styles.extraInfoToShow,
							styles.extraInfoToShowPledged
						)}>
						${parseFloat(pledgedAmount).toFixed(2)} Pledged!
					</div>
				) : (
					''
				)} */}
					{/* {!voteIsOpen && need.is_vote_winner && need.need.is_fulfilled ? (
					<div
						className={cx(
							styles.extraInfoToShow,
							styles.extraInfoToShowFulfilled
						)}>
						Fulfilled
					</div>
				) : (
					''
				)} */}
					{/* {need.is_vote_winner && !need.need.is_fulfilled ? (
					<div
						className={cx(
							styles.extraInfoToShow,
							styles.extraInfoToShowTopVoted
						)}>
						Top Voted
					</div>
				) : (
					''
				)} */}
					{hitByTarget && (
						<Div className="ml2 textInfo f-b mt2 mb2">
							Fulfilled By Employees
						</Div>
					)}
					{need.is_vote_winner && need.need.is_fulfilled ? (
						<Div className="ml2 textInfo f-b mt2 mb2">Fulfilled By Company</Div>
					) : (
						''
					)}
					{!need.is_vote_winner &&
					!hitByTarget &&
					needStatus !== 'Vote' &&
					needStatus !== 'Pledged' &&
					needStatus !== 'Pledge' ? (
						<Div className="ml2 textFaded f-b mt2 mb2">Not fulfilled</Div>
					) : (
						''
					)}
				</Div>
			</div>
		</>
	);
};

export default EmployeeVoteCard;
