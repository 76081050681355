import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Div, Column, Row, Image } from 'basedesign-iswad';

import { getFeedback } from 'root/services/charity';
import { getBackendUrl } from 'root/utils/helpers';
import Icon from 'root/baseComponents/Icon';
import Button from 'root/baseComponents/Button/Button';
import StatsCard from 'root/baseComponents/StatsCard/StatsCard';
import NeedCard from 'root/baseComponents/NeedCard';
import {
	getNeedInfoRegisteredByCharity,
	deleteNeedRegisteredByCharity
} from 'root/services/charity';
import CharityWrapper from '../../CharityWrapper/CharityWrapper';
import { setCharityInfo } from 'root/reducers/charityReducers/charityInfo';

import styles from '../Needs.module.scss';

function IndividualNewNeed() {
	const params = useParams();
	const history = useHistory();
	const { need_id } = params;

	// const charityInfo = useSelector(state => state.charityInfo);
	const [testimony, setTestimony] = useState();

	const [images, setImages] = useState([]);
	const [videos, setVideos] = useState([]);
	const [needInfo, setNeedInfo] = useState({});
	const [fulfilledNeedData, setFulfilledNeedData] = useState([]);

	const [charityData, setCharityData] = useState({});

	const getNeedInfoRegisteredByCharityData = async () => {
		try {
			const res = await getNeedInfoRegisteredByCharity(need_id);
			if (res?.id) {
				setNeedInfo(res);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getNeedInfoRegisteredByCharityData();
	}, []);

	return (
		<>
			<CharityWrapper>
				<Div className={cx(styles.main)}>
					<Div type="flex" className={cx('mb2 pt1 w-per-100')}>
						<Div type="flex" vAlign="center" className="fs-px-18 ml1">
							Need Description
						</Div>
					</Div>
					<Div className="card p2 br-rad-px-5 m1">
						<Div
							className={cx('f-b mb2 fs-px-24 text-center', styles.needTitle)}>
							{needInfo?.title}
						</Div>
						<Row>
							<Column xs={12} sm={12} md={6} lg={6}>
								<Div type="flex" className="p2">
									<Div
										type="flex"
										className="br-all-solid-hidden br-rad-px-5 of-hidden w-per-100 pr2 pl2">
										<img className="br-rad-px-5" src={needInfo?.cover_photo} />
									</Div>
								</Div>
							</Column>
							<Column xs={12} sm={12} md={6} lg={6} className="pr2 pl2">
								<Div className="p2">
									{needInfo?.recipient && (
										<Div className="f-b fs-px-20 mb3 mt1 textGray text-center">
											Recipient: {needInfo?.recipient}
										</Div>
									)}
									<Div className="pr2 pl2 fs-px-16 mb2 text-justify mb3">
										{needInfo?.message}
									</Div>
									<Div type="flex" vAlign="center" className="mb1 textGray">
										<Div type="flex" className="mr2 ml2">
											<Icon type="hand-dollar" color="#f5891f" scale={1.5} />
										</Div>
										<Div>Target: ${needInfo?.monetary_value}</Div>
									</Div>
								</Div>
							</Column>
						</Row>
					</Div>
				</Div>
			</CharityWrapper>
		</>
	);
}

export default IndividualNewNeed;
