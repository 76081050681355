// VENDOR imports
import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// troop design imports
import { Heading, Table, Spinner, Button } from 'troop-design';

// imports
import Modal from 'components/Modal';
import { showModal, hideModal } from 'reducers/modals';
import { getBusinesses, sendFinalVoteEmail } from 'services/businesses';
import { columns } from './constants/columns';
import { processBusinessDataForTable } from './utils/processBusinessDataForTable';
import styles from './Businesses.module.scss';
import useLoader from 'root/hooks/useLoader';

const Businesses = () => {
	const dispatch = useDispatch();
	const [businessIdForFinalVote, setBusinessIdForFinalVote] = useState(null);
	const businesses = useSelector(state => state.businesses.businesses);
	const [businessLoader] = useLoader('businessLoading', () =>
		dispatch(getBusinesses())
	);

	const showConfirmation = e => {
		setBusinessIdForFinalVote(e.target.id);
		dispatch(showModal('confirmEmail'));
	};

	const triggerFinalVoteEmail = useCallback(() => {
		if (businessIdForFinalVote != null) {
			sendFinalVoteEmail(businessIdForFinalVote);
			dispatch(hideModal('confirmEmail'));
		}
	}, [businessIdForFinalVote, hideModal, sendFinalVoteEmail]);

	const processedBusinesses = processBusinessDataForTable(
		businesses,
		showConfirmation
	);

	return (
		<>
			<Heading level={1} className="mt-0">
				Businesses
			</Heading>
			{businessLoader ? (
				<div className="d-flex justify-content-center">
					<Spinner width={256} height={256} />
				</div>
			) : (
				<Table
					className={styles.businessTable}
					data={processedBusinesses}
					columns={columns}
					striped={false}
					hover
				/>
			)}
			<Modal reduxKey="confirmEmail" size="md">
				<Heading level={3}>
					Are you sure you want to send an email to employees that have not
					voted?
				</Heading>
				<Button
					className="me-2"
					variant="primary"
					outline
					onClick={triggerFinalVoteEmail}>
					Send
				</Button>
				<Button
					variant="primary"
					outline
					onClick={() => dispatch(hideModal('confirmEmail'))}>
					Cancel
				</Button>
			</Modal>
		</>
	);
};

export default Businesses;
