export const nameValidators = [
	{ type: 'required', message: 'Name is required' },
	{
		type: 'minRequired',
		message: ' name must be at least 2 characters',
		minRequired: 2
	},
	{
		type: 'maxRequired',
		message: 'Organization name must be less than 256 characters',
		maxRequired: 256
	}
];

export const cityValidators = [
	{ type: 'required', message: 'City is required' }
];

export const addressValidators = [
	{ type: 'required', message: 'Address is required' }
];

export const postalCodeValidators = [
	{ type: 'required', message: 'Postal Code is required' }
];
