import React, { useState } from 'react';
import cx from 'classnames';
import { Div, Input, Label } from 'basedesign-iswad';
import { Icon } from 'troop-design';

import styles from './TextBox.module.scss';

function TextBox({
	labelText,
	isRequired,
	className,
	inputClassName,
	hasIcon = false,
	iconType = '',
	iconColor = 'black',
	type,
	hasMarginBottom = true,
	val,
	setVal,
	errorHandler,
	onChange,
	isLabelWhite = false,
	hasDefaultOnChange = true,
	...props
}) {
	const [curType, setCurType] = useState(type);

	return (
		<>
			<Div
				className={cx(hasMarginBottom && 'mb4', styles.container, className)}>
				<Div className={cx(styles.labelContainer)}>
					<Label
						className={cx(
							isRequired && 'required',
							styles.label,
							isLabelWhite && styles.labelWhite
						)}>
						{labelText}
					</Label>
				</Div>
				<Div className={cx(styles.inputContainer)}>
					<Input
						className={cx(
							styles.input,
							type === 'password' && styles.inputWithEye,
							inputClassName,
							props?.readOnly && styles.inputInActive
						)}
						type={curType}
						{...props}
						value={val}
						onChange={e => {
							if (hasDefaultOnChange) {
								setVal(e.target.value);
								errorHandler('');
							}
							if (onChange) {
								onChange(e);
							}
						}}
					/>
					{hasIcon && (
						<>
							<div className={cx(styles.eye)}>
								<Icon icon={iconType} color={iconColor} width={14} />
							</div>
						</>
					)}
					{type === 'password' && (
						<>
							<div
								className={cx(
									'mouse-hand',

									styles.eye
								)}
								onClick={() => {
									if (curType === 'password') {
										setCurType('text');
									} else {
										setCurType('password');
									}
								}}>
								<Icon icon="eye" color="black" width={14} />
							</div>
							{curType !== 'password' && (
								<div className={cx(styles.lineThrough)}></div>
							)}
						</>
					)}
				</Div>
			</Div>
		</>
	);
}

export default TextBox;
