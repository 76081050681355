import React, { useState } from 'react';
import cx from 'classnames';
import { Div, Heading } from 'basedesign-iswad';

import styles from '../Invite.module.scss';

const ReferCharityForm = () => {
	return (
		<>
			<Div className="employeeHeading mb1 mt2 ml2">Refer a Charity </Div>
			<Div className="card m2 br-all-hidden-1 br-rad-px-5 p1 w-per-80 mb3">
				<Div className="fs-px-16 mt1 mb1">
					If you support a local charity that has a need, let us know about it!
					You can email us at hello@hitroop.com or get the charity to submit a
					need request form{' '}
					<a href="https://hitroop.com/charity-needs/" target="_blank">
						here.
					</a>{' '}
				</Div>
			</Div>
		</>
	);
};

export default ReferCharityForm;
