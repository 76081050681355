import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Wrapper from '../components/Wrapper';

import { getAllCharities } from 'root/services/charity';

import styles from './NeedsToReview.module.scss';

function NeedsToReview() {
	const history = useHistory();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [charities, setCharities] = useState([]);

	const getAllCharitiesData = async () => {
		let res = await getAllCharities();
		const sortedRes = res.sort((a, b) => b.approved_needs - a.approved_needs);
		setCharities(sortedRes);
	};

	useEffect(() => {
		getAllCharitiesData();
	}, []);

	const headLines = [
		{
			value: 'name',
			display: 'Name',
			width: 400,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'number_of_approved_needs',
			display: 'Number of Approved Needs',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'needs_to_publish',
			display: 'Needs to Publish',
			width: 200,
			hasSearch: false,
			isSortable: false
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		charities?.forEach(item => {
			tableData.push({
				name: `${item.name}`,
				number_of_approved_needs: `${item.approved_needs}`,
				needs_to_publish: {
					value: '',
					display: (
						<Div
							onClick={() => history.push(`/writer-approved-needs/${item.id}`)}
							className={cx('mouse-hand', styles.link)}>
							View
						</Div>
					)
				}
			});
		});
		return tableData;
	}, [charities]);

	return (
		<>
			<Wrapper pageName="Needs">
				<Div display="flex" hAlign="center" className="mt2 w-per-90 of-x-auto">
					<Table
						headLines={headLines}
						data={data}
						// colWidth={400}
						// tableWidth={'1200px'}
						isSortable={true}
						isSelectable={false}
						selectedData={selectedData}
						setSelectedData={setSelectedData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						rowsPerPage={9}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={3}
					/>
				</Div>
			</Wrapper>
		</>
	);
}

export default NeedsToReview;
