import React from 'react';
import cx from 'classnames';
import { Div, Heading } from 'basedesign-iswad';

import PageContainer from '../components/PageContainer/PageContainer';

import AllNeeds from './subs/AllNeeds';
import styles from './FulfilledNeeds.module.scss';

const FulfilledNeeds = () => {
	return (
		<>
			<PageContainer>
				<Div className={cx(styles.headingContainer)}>
					<Heading>Recently Fulfilled Needs</Heading>
					<Div className="textGray f-b">
						You can view all the needs that have been fulfilled below.
					</Div>
				</Div>
				<AllNeeds />
			</PageContainer>
		</>
	);
};

export default FulfilledNeeds;
