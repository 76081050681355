import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon/Icon';
import { setActiveDashboard } from 'root/reducers/activeDashboardItem';

function DashboardItem({ itemName, iconType, to, key }) {
	const dispatch = useDispatch();
	const activeDashboardItem = useSelector(state => state.activeDashboardItem);

	const [iconColor, setIconColor] = useState('gray');
	const [textColor, setTextColor] = useState('textFaded');

	// useEffect(() => {
	// 	console.log(activeDashboardItem);
	// }, [activeDashboardItem]);

	useEffect(() => {
		if (itemName === activeDashboardItem) {
			setIconColor('orange');
			setTextColor('textOrange');
		}
	}, [activeDashboardItem, itemName]);

	return (
		<>
			<Link
				key={key}
				to={to}
				onClick={() => dispatch(setActiveDashboard(itemName))}
				className={textColor}>
				<Div type="flex" className="p1 w-per-100">
					<Div className="mr1">
						<Icon type={iconType} color={iconColor} />
					</Div>
					<Div>{itemName}</Div>
				</Div>
			</Link>
		</>
	);
}

export default DashboardItem;
