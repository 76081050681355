import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import TextBox from 'root/baseComponents/TextBox';
import Form from 'root/baseComponents/Form/Form';
import Button from 'root/baseComponents/Button/Button';

import styles from '../Events.module.scss';
import FormTextArea from 'root/baseComponents/FormTextArea/index';
import FormDatePicker from 'root/baseComponents/FormDatePicker/index';

const EmployeeProfileForm = () => {
	const dispatch = useDispatch();

	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [date, setDate] = useState('');
	const [time, setTime] = useState('');
	const [location, setLocation] = useState('');

	const [name, setName] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');

	const [email, setEmail] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	const [eventName, setEventName] = useState('');
	const [eventNameErrorMessage, setEventNameErrorMessage] = useState('');
	const [eventManager, setEventManager] = useState('');
	const [eventManagerErrorMessage, setEventManagerErrorMessage] = useState('');
	const [eventDescription, setEventDescription] = useState('');

	useEffect(() => {
		if (myProfile?.id) {
			setName(`${myProfile?.user?.first_name} ${myProfile?.user?.last_name}`);
			setEmail(myProfile?.user?.email);
		}
	}, [myProfile]);

	const handleSubmit = async e => {
		// e.preventDefault();
		// const res = await updateBusinessUserProfile(businessId, {
		// 	user: {
		// 		first_name: firstName,
		// 		last_name: lastName,
		// 		email: myProfile?.user?.email
		// 	}
		// });
		// if (businessId) {
		// 	dispatch(
		// 		addNotification({
		// 			type: 'success',
		// 			message: `Successfully updated your profile`
		// 		})
		// 	);
		// 	getBusinessUserProfile(dispatch, businessId);
		// }
	};

	return (
		<>
			<Div className="pt2 mb2 fs-px-20">Event Details</Div>
			<Div className="card br-rad-px-5 w-per-90 p2">
				<Div>Event Name:</Div>
				<Div className="ml2 br-all-solid-1 br-rad-px-5 br-color-silver p1 mt1 mb2 ">
					{eventName}
				</Div>
				<Div>Event Manager:</Div>
				<Div className="ml2 br-all-solid-1 br-rad-px-5 br-color-silver p1 mt1 mb2 ">
					{eventManager}
				</Div>
				<Div>Date:</Div>
				<Div className="ml2 br-all-solid-1 br-rad-px-5 br-color-silver p1 mt1 mb2 ">
					{date}
				</Div>
				<Div>Time:</Div>
				<Div className="ml2 br-all-solid-1 br-rad-px-5 br-color-silver p1 mt1 mb2 ">
					{time}
				</Div>
				<Div>Event Description:</Div>
				<Div className="ml2 br-all-solid-1 br-rad-px-5 br-color-silver p1 mt1 mb2 ">
					{eventDescription}
				</Div>
			</Div>
		</>
	);
};

export default EmployeeProfileForm;
