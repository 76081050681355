import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import PageHeader from 'components/PageHeader';
import LoggedInContent from 'components/LoggedInContent';
import { SearchBar, TopBar } from 'troop-design';
import { useDispatch, useSelector } from 'react-redux';
import useLoggedIn from 'hooks/useLoggedIn';
import Notifications from 'root/components/Notifications/Notifications';
import { handleLogout, getBusinessUserProfile } from 'root/services/user';
import useCurrentPage from 'root/hooks/useCurrentPage';
import Routes from 'root/components/Routes/Routes';
import packageFile from '../../../package.json';
import styles from './Wrapper.module.scss';
import { getCookie, setCookie } from 'root/utils/helpers';
import { toggleUserGroups, setWasBusinessAdmin } from 'root/reducers/users';
import useBusinessAdminLoggedIn from 'hooks/useBusinessAdminLoggedIn';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Logo from 'root/assets/logo.png';

export default function Wrapper({
	hasAlert = false,
	alertNumber = 0,
	onAlertClick = null,
	routes
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const loggedInUser = useSelector(state => state.users.loggedInUser);

	const isBusinessAdmin = useBusinessAdminLoggedIn();

	const userFullyRegistered = useSelector(
		state => state.users.userFullyRegistered
	);
	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;

	const showNav = useSelector(state => state.general.showNav) !== false;
	const navPanelSaved =
		getCookie('navPanelOpened') !== ''
			? getCookie('navPanelOpened') === 'true'
			: true;
	const [navPanelOpened, setNavPanelOpened] = useState(navPanelSaved);
	const isLoggedIn = useLoggedIn();
	const currentPage = useCurrentPage();
	let showNavPanel = true;

	showNavPanel = showNavPanel && userFullyRegistered && showNav;

	const handleNavPanelToggle = e => {
		setCookie('navPanelOpened', e, 365);
		setNavPanelOpened(e);
	};

	const handleSearch = e => {
		console.log('search');
	};

	const handleSignOut = e => {
		e.preventDefault();
		dispatch(handleLogout());
	};

	useEffect(() => {
		if (businessId && isLoggedIn) {
			getBusinessUserProfile(dispatch, businessId);
		}
	}, [isLoggedIn, businessId]);

	return (
		<>
			{showNavPanel && (
				<LoggedInContent>
					<PageHeader
						navPanelOpened={navPanelOpened}
						onNavPanelOpened={handleNavPanelToggle}
						className={styles.siteHeader}
						onSignOut={handleSignOut}
						hasAlert={hasAlert}
						alertNumber={alertNumber}
						onAlertClick={onAlertClick}
					/>
				</LoggedInContent>
			)}
			<main
				className={cx(
					styles.pageContent,
					navPanelOpened && showNavPanel && styles.navOpened,
					styles[`page-${currentPage}`],
					!isLoggedIn && styles.notLoggedIn,
					!showNav && styles.noNavbar
				)}>
				{/* <TopBar
                    className={cx(
                        styles.topBar,
                        navPanelOpened && styles.navOpened,
                        !isLoggedIn && styles.notLoggedIn
                    )}
                >
                    <SearchBar onSearch={handleSearch} />
                </TopBar> */}
				{isLoggedIn && <Notifications />}
				<Routes userFullyRegistered={userFullyRegistered} routes={routes} />

				<div className={cx('pr4', styles.version)}>
					<Div type="flex" hAlign="end" className={cx('w-per-100')}>
						Powered by{' '}
						<a href="https://hitroop.com" target="_blank">
							<Div className={cx('w-px-50', styles.logoContainer)}>
								<img src={Logo} />
							</Div>
						</a>
					</Div>
				</div>
			</main>
		</>
	);
}
