import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Wrapper from '../components/Wrapper';

import CharityAddForm from './subs/CharityAddForm';
import styles from './CharityAdd.module.scss';

const CharityAdd = () => {
	return (
		<>
			<Wrapper pageName="Charities">
				<Div className="w-per-80 max-w-px-500 bgWhite boxShadowType1 p2 ml-auto mr-auto">
					<CharityAddForm />
				</Div>
			</Wrapper>
		</>
	);
};

export default CharityAdd;
