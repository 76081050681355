// VENDOR imports
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// imports
import { getImpactReports } from 'services/impactReports';

export default function useHistoricalReports(fromDate, toDate) {
	const dispatch = useDispatch();
	const params = useParams();
	const historicalReports = useSelector(state => state.impactReports?.reports);

	useEffect(() => {
		if (params && params.businessId) {
			dispatch(getImpactReports(params.businessId, fromDate, toDate));
		}
	}, [params, toDate, fromDate, dispatch]);

	return historicalReports;
}
