import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	landingPages: []
};

export const landingPageSlice = createSlice({
	name: 'landingPages',
	initialState,
	reducers: {
		getLandingPage: (state, action) => {
			const existIndex = state.landingPages.findIndex(
				lp => lp.id === action.payload.id
			);
			if (existIndex !== -1) {
				state.landingPages[existIndex] = action.payload;
			} else {
				state.landingPages.push(action.payload);
			}
		},
		getLandingPages: (state, action) => {
			state.landingPages = action.payload;
		},
		addLandingPage: (state, action) => {
			state.landingPages.push(action.payload);
		}
	}
});

export default landingPageSlice.reducer;
export const { getLandingPage, getLandingPages, addLandingPage } =
	landingPageSlice.actions;
