import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';

import useSuperAdminLoggedIn from 'root/hooks/useSuperAdminLoggedIn';
import useBusinessAdminLoggedIn from 'root/hooks/useBusinessAdminLoggedIn';
import useBusinessEmployeeLoggedIn from 'root/hooks/useBusinessEmployeeLoggedIn';
import useCharityAdminLoggedIn from 'root/hooks/useCharityAdminLoggedIn';
import useTroopWriterLoggedIn from 'root/hooks/useTroopWriterLoggedIn';
import { get } from 'root/utils/storage';

import BusinessAdminApp from 'pages/BusinessAdmin/BusinessAdminApp';
import SharedApp from 'pages/shared/SharedApp';

import { toggleUserGroups } from 'root/reducers/users';

import { routes as businessAdminRoutes } from './BusinessAdmin/routes';
import { routes as superAdminRoutes } from './SuperAdmin/routes';
import { routes as employeeRoutes } from './Employee/routes';
import { routes as sharedRoutes } from './shared/routes';
import EmployeeApp from './Employee/EmployeeApp';
import SuperAdminApp from './SuperAdmin/SuperAdminApp';
import Loading from 'root/components/Loading';
import VoteCelebration from 'root/components/VoteCelebration';

import Dashboard from './CharityAdmin/Dashboard';
import SendFeedback from './CharityAdmin/SendFeedback';
import NewNeed from './CharityAdmin/NewNeed';
import Account from './CharityAdmin/Account';
import Needs from './CharityAdmin/Needs';
import Team from './CharityAdmin/Team';
import AddMember from './CharityAdmin/Team/subs/AddMember';
import EditNeed from './CharityAdmin/Needs/subs/EditNeed';
import AdminFeedback from './CharityAdmin/AdminFeedback';

import PublicNeeds from './PublicRoutes/Needs';
import FulfilledNeeds from './PublicRoutes/FulfilledNeeds';
import SingleNeed from './PublicRoutes/SingleNeed';
import ImpactReport from './PublicRoutes/ImpactReport';
import CharityAdminRegistration from './PublicRoutes/CharityAdminRegistration';
import CharityAdminSignup from './PublicRoutes/CharityAdminSignup';

import WriterHome from './TroopWriter/WriterHome';
import NeedsToReview from './TroopWriter/NeedsToReview/NeedsToReview';
import NeedsToReviewDetails from './TroopWriter/NeedsToReviewDetails';
import PublishNeed from './TroopWriter/PublishNeed';
import Charities from './TroopWriter/Charities';
import CharityEdit from './TroopWriter/CharityEdit';
import CharityAdd from './TroopWriter/CharityAdd';
import WriterNeeds from './TroopWriter/Needs';
import WriterEditNeed from './TroopWriter/EditNeed';
import WriterAddNeed from './TroopWriter/AddNeed';
import IndividualNeed from './CharityAdmin/Needs/subs/IndividualNeed';
import IndividualNewNeed from './CharityAdmin/Needs/subs/IndividualNewNeed';
import NewVolunteerNeed from './CharityAdmin/NewVolunteerNeed';
import VolunteerNeedDetails from './CharityAdmin/VolunteerNeedDetails';

ReactGA.initialize('G-KFJ5WN5S51');

const App = () => {
	const dispatch = useDispatch();
	const isSuperAdmin = useSuperAdminLoggedIn();
	const isBusinessAdmin = useBusinessAdminLoggedIn();
	const isCharityAdmin = useCharityAdminLoggedIn();
	const isTroopWriter = useTroopWriterLoggedIn();

	const isLoading = useSelector(state => state.loaders);
	const voteCelebration = useSelector(state => state.voteCelebration);

	useEffect(() => {
		if (isBusinessAdmin) {
			const previousUserRole = get('userRole');
			if (previousUserRole) {
				dispatch(
					toggleUserGroups({
						groups: [previousUserRole]
					})
				);
			}
		}
	}, [isBusinessAdmin]);

	const userFullyRegistered = useSelector(
		state => state.users.userFullyRegistered
	);

	let parsedSuperAdminRoutes = superAdminRoutes;
	let parsedBusinessAdminRoutes = businessAdminRoutes;
	if (!isBusinessAdmin) {
		parsedBusinessAdminRoutes = businessAdminRoutes.filter(
			route => route.path !== '/'
		);
	}
	if (!isSuperAdmin) {
		parsedSuperAdminRoutes = superAdminRoutes.filter(
			route => route.path !== '/'
		);
	}

	return (
		<>
			{isLoading && <Loading />}
			{voteCelebration.showComponent && <VoteCelebration />}
			<Switch>
				{/* ----------- HOME ROUTE ----------*/}
				{isCharityAdmin && (
					<Route path="/" exact>
						<Dashboard />
					</Route>
				)}

				{isTroopWriter && (
					<Route path="/" exact>
						<WriterHome />
					</Route>
				)}

				{/* ----------- HOME ROUTE ----------*/}
				{parsedBusinessAdminRoutes.map(route => (
					<Route key={`ba-${route.path}`} path={route.path} exact>
						<BusinessAdminApp userFullyRegistered={userFullyRegistered} />
					</Route>
				))}
				{parsedSuperAdminRoutes.map(route => (
					<Route key={`sa-${route.path}`} path={route.path} exact>
						<SuperAdminApp userFullyRegistered={userFullyRegistered} />
					</Route>
				))}
				{employeeRoutes.map(route => (
					<Route key={`er-${route.path}`} path={route.path} exact>
						<EmployeeApp userFullyRegistered={userFullyRegistered} />
					</Route>
				))}
				{sharedRoutes.map(route => (
					<Route key={`sr-${route.path}`} path={route.path} exact>
						<SharedApp userFullyRegistered={userFullyRegistered} />
					</Route>
				))}

				{/* ----------- CHARITY ROUTES ----------*/}
				<Route path="/new-need">
					<NewNeed />
				</Route>
				<Route path="/new-volunteer-need">
					<NewVolunteerNeed />
				</Route>
				<Route path="/charity-needs">
					<Needs />
				</Route>
				<Route path="/organisation-account">
					<Account />
				</Route>
				<Route path="/send-feedback/:id">
					<SendFeedback />
				</Route>
				<Route path="/charity-team">
					<Team />
				</Route>
				<Route path="/charity-add-member">
					<AddMember />
				</Route>
				<Route path="/edit_need/:id">
					<EditNeed />
				</Route>
				<Route path="/individual_need/:need_id">
					<IndividualNeed />
				</Route>
				<Route path="/individual_new_need/:need_id">
					<IndividualNewNeed />
				</Route>
				<Route path="/charity-admin-feedback">
					<AdminFeedback />
				</Route>
				<Route path="/volunteer-need-details/:id">
					<VolunteerNeedDetails />
				</Route>
				{/* --------------------------------------------- */}

				{/* ----------- PUBLIC ROUTES ----------*/}
				<Route exact path="/needs">
					<PublicNeeds />
				</Route>
				<Route path="/fulfilled-needs">
					<FulfilledNeeds />
				</Route>
				<Route exact path="/needs/:slug">
					<SingleNeed />
				</Route>
				<Route exact path="/needs/:charity_name/:need_id">
					<SingleNeed />
				</Route>
				<Route path="/:businessId/public-impact-report">
					<ImpactReport />
				</Route>
				<Route path="/charity-admin-signup/:uuid">
					<CharityAdminRegistration />
				</Route>
				{/* <Route path="/register-me-as-charity-admin">
					<CharityAdminSignup />
				</Route> */}
				{/* --------------------------------------------- */}

				{/* ----------- TROOP WRITER ROUTES ----------*/}
				<Route exact path="/writer-needs-to-review">
					<NeedsToReview />
				</Route>
				<Route exact path="/writer-approved-needs/:id">
					<NeedsToReviewDetails />
				</Route>
				<Route exact path="/writer-publish-registered-need/:id">
					<PublishNeed />
				</Route>
				<Route exact path="/writer-charities">
					<Charities />
				</Route>
				<Route exact path="/writer-charities/:id">
					<CharityEdit />
				</Route>
				<Route exact path="/writer-add-charity">
					<CharityAdd />
				</Route>
				<Route exact path="/writer-needs">
					<WriterNeeds />
				</Route>
				<Route exact path="/writer-needs/:id">
					<WriterEditNeed />
				</Route>
				<Route exact path="/writer-add-need">
					<WriterAddNeed />
				</Route>
				{/* --------------------------------------------- */}
			</Switch>
		</>
	);
};

export default App;
