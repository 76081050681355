import React from 'react';
import cx from 'classnames';
import { Card, CardHeader, CardBody } from 'basedesign-iswad';

import styles from './StatsCard.module.scss';

const StatsCard = ({
	title,
	amount,
	unit = '',
	isAdminView = false,
	onImpactReportPage = false
}) => {
	return (
		<>
			<Card
				className={cx(
					'w-px-300 bgWhite card mx1 w-per-100 mb8 py3 br-rad-px-5',
					!onImpactReportPage && styles.card,
					onImpactReportPage && styles.impactCard
				)}>
				<CardHeader
					className={cx('text-center fs-px-16 mx1', styles.cardHeader)}>
					{title}
				</CardHeader>
				<CardBody
					className={cx('text-center h3 mt1', !isAdminView && styles.nums)}>
					{amount + unit}
				</CardBody>
			</Card>
		</>
	);
};

export default StatsCard;
