import { Switch, Route, Redirect } from 'react-router-dom';
import TroopDashboard from 'pages/SuperAdmin/TroopDashboard';
import Home from 'root/pages/BusinessAdmin/Home/Home';

import CompleteRegistration from 'pages/shared/CompleteRegistration';
import PrivateRoute from 'components/PrivateRoute';
import EmployeeRoute from 'components/EmployeeRoute';
import PublicRoute from 'components/PublicRoute/index';
import SuperAdminRoute from 'components/SuperAdminRoute';

import useSuperAdminLoggedIn from 'hooks/useSuperAdminLoggedIn';
import useBusinessAdminLoggedIn from 'hooks/useBusinessAdminLoggedIn';
import EmployeeHome from 'root/pages/Employee/EmployeeHome/EmployeeHome';

const Routes = ({ userFullyRegistered, routes }) => {
	const isSuperAdmin = useSuperAdminLoggedIn();
	const isBusinessAdmin = useBusinessAdminLoggedIn();

	const renderRoute = route => {
		let { Component } = route;
		if (route.path === '/') {
			if (isSuperAdmin) {
				Component = TroopDashboard;
			} else if (isBusinessAdmin) {
				Component = Home;
			} else {
				Component = EmployeeHome;
			}
		}
		if (route.isEmployee) {
			return <EmployeeRoute Component={Component} from={route.path} />;
		} else if (route.isPrivate) {
			return <PrivateRoute Component={Component} from={route.path} />;
		} else if (route.isSuperAdminOnly) {
			return <SuperAdminRoute Component={Component} from={route.path} />;
		}
		return (
			<PublicRoute
				Component={Component}
				from={route.path}
				showNav={route.showNav}
			/>
		);
	};

	// When the user is not registered, we want to disable all the routes
	if (!userFullyRegistered) {
		return (
			<Switch>
				<PrivateRoute
					Component={CompleteRegistration}
					from="/business-complete-registration"
				/>
				<Redirect to="/business-complete-registration" />
			</Switch>
		);
	}

	return (
		<Switch>
			{routes.map(route => (
				<Route exact path={route.path} key={`route-${route.path}`}>
					{renderRoute(route)}
				</Route>
			))}
		</Switch>
	);
};

export default Routes;
