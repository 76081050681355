import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import TextBox from 'root/baseComponents/TextBox';
import Form from 'root/baseComponents/Form/Form';
import FormSelect from 'root/baseComponents/FormSelect';
import Button from 'root/baseComponents/Button/Button';

import UpdatePassModal from 'root/components/UpdatePassModal';

import {
	getBusinessUserProfile,
	updateBusinessUserProfile
} from 'root/services/user';

import { PROVINCE_CHOICES } from './constants';
import { addNotification } from 'root/reducers/notifications';
import styles from './EmployeeProfileForm.module.scss';

const EmployeeProfileForm = () => {
	const dispatch = useDispatch();

	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [showPassModal, setShowPassModal] = useState(false);

	const [firstName, setFirstName] = useState('');
	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');

	const [lastName, setLastName] = useState('');
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');

	const [address, setAddress] = useState('');
	const [addressErrorMessage, setAddressErrorMessage] = useState('');

	const [city, setCity] = useState('');
	const [cityErrorMessage, setCityErrorMessage] = useState('');

	const [province, setProvince] = useState('');
	const [provinceErrorMessage, setProvinceErrorMessage] = useState('');

	const [postalCode, setPostalCode] = useState('');
	const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('');

	const [country, setCountry] = useState('');
	const [countryErrorMessage, setCountryErrorMessage] = useState('');

	const [contactNumber, setContactNumber] = useState('');
	const [contactNumberErrorMessage, setContactNumberErrorMessage] =
		useState('');

	useEffect(() => {
		if (myProfile?.id) {
			setFirstName(myProfile?.user?.first_name);
			setLastName(myProfile?.user?.last_name);
			setAddress(myProfile?.address);
			setCity(myProfile?.city);
			setProvince(myProfile?.province);
			setPostalCode(myProfile?.postal_code);
			setCountry(myProfile?.country);
			setContactNumber(myProfile?.phone);
		}
	}, [myProfile]);

	const handleSubmit = async e => {
		e.preventDefault();
		if (businessId) {
			const res = await updateBusinessUserProfile(businessId, {
				user: {
					first_name: firstName,
					last_name: lastName,
					email: myProfile?.user?.email
				},
				address,
				city,
				province,
				postal_code: postalCode,
				country,
				phone: contactNumber
			});
			dispatch(
				addNotification({
					type: 'success',
					message: `Successfully updated your profile`
				})
			);
			getBusinessUserProfile(dispatch, businessId);
		}
	};

	return (
		<>
			<Form onSubmit={handleSubmit}>
				<Div>
					<Div className="flex w-per-100">
						<TextBox
							labelText="First Name"
							type="text"
							val={firstName}
							setVal={setFirstName}
							errorHandler={setFirstNameErrorMessage}
							name="firstName"
							errorMessage={firstNameErrorMessage}
							className={cx('mr2 w-px-100')}
						/>
						<TextBox
							labelText="Last Name"
							type="text"
							val={lastName}
							setVal={setLastName}
							errorHandler={setLastNameErrorMessage}
							name="lastName"
							errorMessage={lastNameErrorMessage}
							className={cx('mr2 w-px-100')}
						/>
					</Div>
					<Div className="flex w-per-100">
						<TextBox
							labelText="Address"
							type="text"
							val={address}
							setVal={setAddress}
							name="address"
							errorHandler={setAddressErrorMessage}
							errorMessage={addressErrorMessage}
							className={cx('mr2 w-px-100')}
						/>
						<TextBox
							labelText="City"
							type="text"
							val={city}
							setVal={setCity}
							name="city"
							errorHandler={setCityErrorMessage}
							errorMessage={cityErrorMessage}
							className={cx('mr2 w-px-100')}
						/>
					</Div>
					<Div className="flex w-per-100">
						<Div className="flex w-px-100">
							<FormSelect
								labelText="Province"
								className={cx('mb4 mr2 w-px-100')}
								options={PROVINCE_CHOICES}
								fullWidth
								openOptionsDownWard={true}
								name="province"
								val={province}
								setVal={setProvince}
								initialSelected={myProfile?.province}
								onChange={e => setProvince(e.target.value)}
								errorMessage={provinceErrorMessage}
								errorHandler={setProvinceErrorMessage}
							/>
						</Div>
						<Div className="flex w-per-90">
							<TextBox
								labelText="Postal Code"
								type="text"
								val={postalCode}
								setVal={setPostalCode}
								name="postal_code"
								errorHandler={setPostalCodeErrorMessage}
								errorMessage={postalCodeErrorMessage}
								className={cx('mr2 w-px-100')}
							/>
							<TextBox
								labelText="Contact Number"
								type="text"
								val={contactNumber}
								setVal={setContactNumber}
								name="contact_number"
								errorHandler={setContactNumberErrorMessage}
								errorMessage={contactNumberErrorMessage}
								className={cx('mr2 w-px-100')}
							/>
						</Div>
					</Div>
				</Div>
				<Div type="flex">
					<Div
						onClick={e => {
							e.preventDefault();
							setShowPassModal(true);
						}}
						colorType="transparent"
						className={cx('mr3 mouse-hand', styles.updatePassBtn)}>
						Update Password
					</Div>
					<Div>
						<Button type="submit" className="w-px-100 mouse-hand">
							Save
						</Button>
					</Div>
				</Div>
			</Form>
			{showPassModal && <UpdatePassModal setShowPassModal={setShowPassModal} />}
		</>
	);
};

export default EmployeeProfileForm;
