import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Modal from '../Modal';

import styles from './PageContainer.module.scss';

const PageContainer = ({
	hasFooter = true,
	hasHeader = true,
	hasBg = false,
	children
}) => {
	const headerRef = useRef();
	const footerRef = useRef();

	const [minHeight, setMinHeight] = useState(0);

	useEffect(() => {
		if (window?.innerHeight && footerRef?.current && headerRef?.current) {
			setMinHeight(
				window.innerHeight -
					headerRef.current.clientHeight -
					footerRef.current.clientHeight
			);
		}
	}, [headerRef?.current, footerRef?.current]);

	return (
		<>
			<Modal />
			{hasHeader && (
				<Div ref={el => (headerRef.current = el)}>
					<Header />
				</Div>
			)}
			{hasFooter && hasHeader && (
				<Div className={cx('')} style={{ minHeight }}>
					{children}
				</Div>
			)}
			{hasFooter && (
				<Div ref={el => (footerRef.current = el)}>
					<Footer />
				</Div>
			)}
			{!hasFooter && !hasHeader && (
				<Div
					type="flex"
					vAlign="center"
					className={cx('height-vh-full', hasBg && styles.bg)}>
					<Modal />
					{children}
				</Div>
			)}
		</>
	);
};

export default PageContainer;
