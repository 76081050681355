import React from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import {
	Form,
	Select,
	Button,
	Icon,
	Heading,
	Text,
	Anchor
} from 'troop-design';
import Modal from 'root/components/Modal';
import { showModal } from 'root/reducers/modals';
import styles from './Sectional.module.scss';

export default function Sectional({
	className,
	backgroundColor,
	icon,
	heading,
	variant,
	link,
	linkText,
	infoText,
	children,
	showDropdown,
	select,
	have_box_shadow = true,
	isBgWhite = false
}) {
	const dispatch = useDispatch();
	const sectionHeadingClassName = 'sectionColor-' + variant;
	return (
		<>
			<Modal reduxKey="info" size={'sm'}>
				{infoText}
			</Modal>
			<div
				className={cx(have_box_shadow && styles.sectionalContainer, className)}
				style={{ background: backgroundColor }}>
				<div
					className={cx(
						styles.sectionalHeading,
						styles[sectionHeadingClassName]
					)}>
					<div className={cx('w-per-100', styles.sectionHeadingMain)}>
						<Icon icon={icon} size={'lg'} color={'white'} />
						<div className={cx('employeeHeading')}>{heading.title}</div>
						{heading?.secondary && (
							<Text
								className={styles.secondaryText}
								tag="span"
								hasWrapper={true}>
								{heading.secondary}
							</Text>
						)}
					</div>
					<div>
						{link && linkText && (
							<Anchor className={styles.headingLink} to={link}>
								{linkText}
							</Anchor>
						)}
						{infoText && (
							<Button
								className={'p-0'}
								onClick={() => {
									dispatch(showModal('info'));
								}}
								variant="flat">
								{/** TODO: update to right icon asset */}
								<Icon icon="chat" size="sm" color="white" />
							</Button>
						)}
						{showDropdown && (
							<Form>
								<Select
									onChange={select.onChange}
									className={styles.selectBox}
									formGroupClassName={styles.selectDropdown}
									name={select.name}
									value={select.value}>
									{select.children}
								</Select>
							</Form>
						)}
					</div>
				</div>
				<div className={styles.sectionalBody}>{children}</div>
			</div>
		</>
	);
}
