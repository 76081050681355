// VENDOR imports
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useLoggedIn from 'hooks/useLoggedIn';
import { validateUser } from 'root/services/user';
import { add, deleteKey } from 'reducers/general';

const PublicRoute = ({ Component, showNav }) => {
	const dispatch = useDispatch();
	const location = useLocation();

	const loggedIn = useLoggedIn();
	useEffect(() => {
		if (!loggedIn && showNav) {
			dispatch(validateUser(location, false));
		}
	}, []);

	useEffect(() => {
		if (!showNav) {
			dispatch(add({ key: 'showNav', value: false }));
		}
		return () => {
			dispatch(deleteKey({ key: 'showNav' }));
		};
	}, []);

	return <Component />;
};

PublicRoute.propTypes = {
	Component: PropTypes.func.isRequired,
	showNav: PropTypes.bool
};

PublicRoute.defaultProps = {
	showNav: true
};

export default PublicRoute;
