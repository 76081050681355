import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setPublicModalProps'] = (state, action) => action.payload;
reducerObject['clearPublicModalProps'] = (state, action) => {
	return {};
};

const slice = createSlice({
	name: 'PublicModalProps',
	initialState: {},
	reducers: reducerObject
});

export const { setPublicModalProps, clearPublicModalProps } = slice.actions;
export default slice.reducer;
