import store from 'root/store';
/**
 * Returns the environment variable's value. If not set, then return the default value
 * @param {string} optionName Environment variable name
 * @param {string} defaultValue Default value if not set
 * @returns The environment value
 */
export function getEnv(optionName, defaultValue = '') {
	const optionValue = process.env['REACT_APP_' + optionName];
	return optionValue || defaultValue;
}

export function prodOrStaging() {
	return window.location.origin === getEnv('STAGING_URL')
		? getEnv('BACKEND_STAGING')
		: getEnv('BACKEND_PROD');
}

export function getGtmTag() {
	return window.location.origin === getEnv('STAGING_URL') ||
		process.env.NODE_ENV === 'development'
		? getEnv('STAGING_GTM')
		: getEnv('PROD_GTM');
}

export function getBackendUrl() {
	return process.env.NODE_ENV === 'development'
		? getEnv('BACKEND')
		: prodOrStaging();
}

export const getClientUrl = () => {
	const curUrl = window.location.href;
	let curClientUrl = getEnv('URL');
	if (curUrl.includes(getEnv('STAGING_URL'))) {
		curClientUrl = getEnv('STAGING_URL');
	}
	return curClientUrl;
};

export const getStripePublishableApiKey = () => {
	const curUrl = window.location.href;
	let StripePublishableApiKey = getEnv('STRIPE_PUBLISHABLE_API_KEY');
	if (curUrl.includes(getEnv('STAGING_URL'))) {
		StripePublishableApiKey = getEnv('STRIPE_PUBLISHABLE_API_KEY_TEST');
	}
	return StripePublishableApiKey;
};

/**
 * Sets the given cookie
 * @param {string} cname Cookie name
 * @param {string} cvalue Cookie value
 * @param {int} exdays Number of days to keep in storage
 */
export function setCookie(cname, cvalue, exdays = 2) {
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * Gets the value for the given cookie name
 * @param {string} cname Cookie name
 * @returns strong
 */
export function getCookie(cname) {
	var name = cname + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

/**
 * Delete the given cookie
 * @param {string} name Cookie to delete
 */
export function deleteCookie(name) {
	setCookie(name, '', -1);
}

// Given the capability, check if the user has permission to do those
export function canUserDo(capability) {
	const allPermissions = store.getState().UserState.get('userCapabilities');
	if (!allPermissions || allPermissions.size === 0) {
		return false;
	}
	return allPermissions.includes(capability);
}

/**
 * Returns the username of the currently logged in user
 * @return {string} The username of the logged in user
 */
export function getUsername() {
	const currentUser = store.getState().UserState.get('currentUser');
	if (currentUser) {
		return currentUser.get('name');
	}
	return 'Anonymous';
}

/**
 * Checks if the current user is logged in
 * @return {bool} Returns whether the user is logged in
 */
export function loggedIn() {
	const currentUser = store.getState().UserState.get('currentUser');
	return currentUser && currentUser.get('email');
}

/**
 * Save the given value in the session storage with the given key
 * @param {string} key session key
 * @param {mixed} value Value to save
 */
export function saveSession(key, value) {
	sessionStorage.setItem(
		key,
		typeof value === 'object' ? JSON.stringify(value) : value
	);
}

/**
 * Gets the session value for the given key
 * @param {string} key Get the data from the session for the given key
 * @returns mixed data
 */
export function getSession(key) {
	const data = sessionStorage.getItem(key);
	try {
		return JSON.parse(data);
	} catch (error) {
		return data;
	}
}

export const arraysEqual = (a, b) => {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	// If you don't care about the order of the elements inside
	// the array, you should sort both arrays here.
	// Please note that calling sort on an array will modify that array.
	// you might want to clone your array first.

	for (var i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
};

export const isEqual = (a, b) => {
	return JSON.stringify(a) === JSON.stringify(b);
};

export const generateKey = (length = 16) => {
	var result = '';
	var characters =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!?.><,=-)(*&^%$#@!~';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const showErrorAPIAlert = error => {
	if (error && error?.response?.data) {
		if (typeof error.response.data === 'object') {
			const msg = error.response.data['message']
				.replace(/\[|\]/g, '')
				.split(',');
			return msg?.[0].replace("'", '');
		} else if (typeof error.response.data === 'string') {
			if (Array.isArray(error.response.data)) {
				error.response.data.forEach(err => {
					if (typeof err === 'string') {
						return err;
					} else if (err?.message) {
						if (typeof err.message === 'string') {
							return err.message;
						}
					}
				});
			} else {
				return error.response.data;
			}
		}
	}
};
