import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateBusiness } from 'services/businesses';
import { provinces } from 'constants/locale';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';
import styles from '../AccountSettings.module.scss';
import { useHistory } from 'react-router-dom';

import TextBox from 'root/baseComponents/TextBox';
import Form from 'root/baseComponents/Form/Form';
import FormSelect from 'root/baseComponents/FormSelect';
import Button from 'root/baseComponents/Button/Button';
import FormImagePicker from 'root/baseComponents/FormImagePicker';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import { getBusinessInfo, updateBusinessInfo } from 'root/services/businesses';

import { PROVINCE_CHOICES } from './constants';
import { addNotification } from 'root/reducers/notifications';

export default function AccountSettings({ business }) {
	const dispatch = useDispatch();
	const history = useHistory();

	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [showPassModal, setShowPassModal] = useState(false);

	const [companyName, setCompanyName] = useState('');
	const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState('');

	const [companyAddress, setCompanyAddress] = useState('');
	const [companyAddressErrorMessage, setCompanyAddressErrorMessage] =
		useState('');

	const [city, setCity] = useState('');
	const [cityErrorMessage, setCityErrorMessage] = useState('');

	const [province, setProvince] = useState('');
	const [provinceErrorMessage, setProvinceErrorMessage] = useState('');

	const [postalCode, setPostalCode] = useState('');
	const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('');

	const [phone, setPhone] = useState('');
	const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

	const [photo, setPhoto] = useState('');
	const [photoErrorMessage, setPhotoErrorMessage] = useState('');

	const [phtoHasChanged, setPhotoHasChanged] = useState(false);
	const [initialSrc, setInitialSrc] = useState('');

	const getBusinessData = async () => {
		dispatch(setLoading());
		try {
			const res = await getBusinessInfo(business?.id);
			if (res?.id) {
				setCompanyName(res.name);
				setCompanyAddress(res.address);
				setCity(res.city);
				setProvince(res.province);
				setPhone(res.phone);
				setPostalCode(res.postal_code);
				setPhoto(res.logo);
				setInitialSrc(res.logo);
			}
			dispatch(setLoadingComplete());
		} catch (e) {
			history.push('/');
			console.log(e);
			dispatch(setLoadingComplete());
		}
	};

	useEffect(() => {
		if (business?.id) {
			getBusinessData();
		}
	}, [business]);

	useEffect(() => {
		if (photo && initialSrc) {
			if (photo !== initialSrc) {
				setPhotoHasChanged(true);
			} else {
				setPhotoHasChanged(false);
			}
		}
	}, [photo, initialSrc]);

	const customValidations = () => {
		let validated = true;
		return validated;
	};

	const handleSubmit = async e => {
		if (customValidations() && business?.id) {
			const formData = new FormData();
			formData.append('name', companyName);
			formData.append('address', companyAddress);
			formData.append('city', city);
			if (phtoHasChanged) {
				formData.append('logo', photo);
				formData.append('file', photo);
			}
			formData.append('postal_code', postalCode);
			formData.append('phone', phone);
			formData.append('province', province);
			dispatch(setLoading());
			try {
				const res = await updateBusinessInfo(business.id, formData);
				if (res?.id) {
					console.log(res);
				} else {
					console.log(res);
				}
				dispatch(setLoadingComplete());
				dispatch(
					addNotification({
						type: 'success',
						message: 'Business profile updated successfully.',
						options: {
							autoDismiss: true
						}
					})
				);
				history.push('/');
			} catch (err) {
				console.log(err);
				dispatch(setLoadingComplete());
			}
		}
	};

	return (
		<>
			<Form className="w-per-100" onSubmit={handleSubmit}>
				<Div className="w-per-100">
					<Div className="flex w-per-100">
						<TextBox
							labelText="Company Name"
							type="text"
							val={companyName}
							setVal={setCompanyName}
							errorHandler={setCompanyNameErrorMessage}
							name="companyName"
							errorMessage={companyNameErrorMessage}
							className={cx('mr2 w-px-100')}
						/>
						<TextBox
							labelText="Company Address"
							type="text"
							val={companyAddress}
							setVal={setCompanyAddress}
							errorHandler={setCompanyAddressErrorMessage}
							name="companyAddress"
							errorMessage={companyAddressErrorMessage}
							className={cx('mr2 w-px-100')}
						/>
					</Div>
					<Div className="flex w-per-100">
						<TextBox
							labelText="City"
							type="text"
							val={city}
							setVal={setCity}
							name="city"
							errorHandler={setCityErrorMessage}
							errorMessage={cityErrorMessage}
							className={cx('mr2 w-px-100')}
						/>
						<TextBox
							labelText="Postal Code"
							type="text"
							val={postalCode}
							setVal={setPostalCode}
							name="postal_code"
							errorHandler={setPostalCodeErrorMessage}
							errorMessage={postalCodeErrorMessage}
							className={cx('mr2 w-px-100')}
						/>
					</Div>
					<Div className="flex w-per-100">
						<Div className="flex w-px-100">
							<FormSelect
								labelText="Province"
								className={cx('mb4 mr2 w-px-100')}
								options={PROVINCE_CHOICES}
								fullWidth
								openOptionsDownWard={true}
								name="province"
								val={province}
								setVal={setProvince}
								initialSelected={province}
								onChange={e => setProvince(e.target.value)}
								errorMessage={provinceErrorMessage}
								errorHandler={setProvinceErrorMessage}
							/>
						</Div>
						<Div className="flex w-per-90">
							<TextBox
								labelText="Phone"
								type="text"
								val={phone}
								setVal={setPhone}
								name="phone"
								errorHandler={setPhoneErrorMessage}
								errorMessage={phoneErrorMessage}
								className={cx('mr2 w-px-100')}
							/>
						</Div>
					</Div>
					<Div className="flex w-per-90">
						<FormImagePicker
							labelText=""
							file={photo}
							setFile={setPhoto}
							initialSrc={initialSrc}
							errorMessage={photoErrorMessage}
							errorHandler={setPhotoErrorMessage}
							id="impactstoryphoto1"
							hasCropper={false}
							showDefaultPrev={true}
							accept=".jpg,.jpeg,.png"
							placeHolderType={2}
							placeHolderText="Update Logo"
							className="w-px-150"
							useDefaultClassName={true}
						/>
					</Div>
				</Div>
				<Button type="submit" className="w-px-100 mouse-hand">
					Save
				</Button>
			</Form>
		</>
	);
}
