import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { login } from 'services/user';
import { addNotification } from 'reducers/notifications';

import CharityRegistration from 'root/components/CharityRegistration';
import MoreInfo from 'root/components/CharityRegistration/subs/MoreInfo';
// import NeedInfo from 'root/components/CharityRegistration/subs/NeedInfo';

import styles from './CharitySignup.module.scss';

const CharitySignup = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [charityRegistrationSubmitted, setCharityRegistrationSubmitted] =
		useState(false);
	const [moreInfoSubmitted, setMoreInfoSubmitted] = useState(false);
	const [needInfoSubmitted, setNeedInfoSubmitted] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [organizationName, setOrganizationName] = useState('');
	const [charityId, setCharityId] = useState('');
	const [charityUserId, setCharityUserId] = useState('');

	const handleLogin = () => {
		dispatch(login(email, password, '/')).catch(err => {
			dispatch(
				addNotification({
					type: 'danger',
					message: 'There was an error while logging in. Please try again',
					options: {
						dismissable: true
					}
				})
			);
		});
	};

	useEffect(() => {
		if (moreInfoSubmitted) {
			handleLogin();
		}
	}, [moreInfoSubmitted]);

	return (
		<>
			{!charityRegistrationSubmitted ? (
				<CharityRegistration
					setFormSubmitted={setCharityRegistrationSubmitted}
					email={email}
					setEmail={setEmail}
					password={password}
					setPassword={setPassword}
					organizationName={organizationName}
					setOrganizationName={setOrganizationName}
					setCharityId={setCharityId}
					setCharityUserId={setCharityUserId}
				/>
			) : (
				''
			)}
			{charityRegistrationSubmitted && !moreInfoSubmitted ? (
				<MoreInfo
					setFormSubmitted={setMoreInfoSubmitted}
					email={email}
					organizationName={organizationName}
					charityId={charityId}
				/>
			) : (
				''
			)}
			{/* {charityRegistrationSubmitted && moreInfoSubmitted ? (
				<NeedInfo
					setFormSubmitted={setNeedInfoSubmitted}
					charityId={charityId}
					charityUserId={charityUserId}
				/>
			) : (
				''
			)} */}
		</>
	);
};

export default CharitySignup;
