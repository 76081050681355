import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div, Image } from 'basedesign-iswad';
import { Heading, Icon, Button } from 'troop-design';

import Close from 'root/baseComponents/Close/Close';

import { checkIfUserHasPassword } from 'root/services/user';
import { hideComponent, clearChosenNeed } from 'root/reducers/voteCelebration';
import { get } from 'utils/storage';

import CelebPhoto from 'root/assets/vote_celebration.png';

import styles from './VoteCelebration.module.scss';

const VoteCelebration = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const voteCelebration = useSelector(state => state.voteCelebration);
	const loggedInUser = useSelector(state => state.users.loggedInUser);

	const [useRegularTheme, setUseRegularTheme] = useState(true);
	const [isForNeighbours, setIsForNeighbours] = useState(false);

	useEffect(() => {
		if (voteCelebration?.votedFromBusiness?.id) {
			if (!voteCelebration?.votedFromBusiness?.has_employee_feature) {
				setUseRegularTheme(false);
			}
			if (voteCelebration?.votedFromBusiness?.is_for_neighbours) {
				setIsForNeighbours(true);
			}
		}
	}, [voteCelebration]);

	return (
		<>
			<div className={cx(styles.container)}>
				<Div
					className={cx(
						'pos-abs pos-abs--lt w-per-100 height-vh-full bgWhite op-90'
					)}
				/>
				<Div
					className={cx(
						'pos-fix pos-fix--lt w-per-100 height-vh-full',
						styles.celebImage
					)}>
					<img src={CelebPhoto} />
				</Div>
				<div className={cx(styles.card, 'pos-rel')}>
					<Close
						onClick={async () => {
							dispatch(hideComponent());
							dispatch(clearChosenNeed());
							if (useRegularTheme) {
								if (loggedInUser) {
									history.push('/');
								} else {
									const cur_email = get('votedUserEmail');
									if (cur_email) {
										try {
											const res = await checkIfUserHasPassword({
												email: cur_email
											});
											if (res?.success) {
												history.push('/');
											}
										} catch (e) {
											history.push('/employee-signup');
										}
									} else {
										history.push('/employee-signup');
									}
								}
							}
						}}
					/>
					<div className={cx(styles.cardLeft)}>
						<div className={cx(styles.cardLeftContainer)}>
							<div className={cx(styles.photoContainer)}>
								<div className={cx(styles.votedIcon)}>
									<div className={cx(styles.votedIconIcon)}>
										<Icon icon="checkmark-voted" color="white" width={50} />
									</div>
									<div>Your Vote</div>
								</div>
								<Image
									className={cx(styles.photo)}
									src={voteCelebration['chosenNeed']?.image}
								/>
							</div>
							<p>{voteCelebration['chosenNeed']?.charity_name}</p>
							<p>
								{voteCelebration['chosenNeed']?.community_supported ||
									voteCelebration['chosenNeed']?.name}
							</p>
						</div>
					</div>
					{useRegularTheme ? (
						<div className={cx(styles.cardRight)}>
							<Heading level={2}>Great Choice!</Heading>
							<p className={cx(styles.centredText)}>
								We've counted your vote. Do more good with the new Troop
								Dashboard
							</p>
							<div className={cx(styles.registerContainer)}>
								<div className={cx(styles.registerItem)}>
									<div className={cx(styles.registerItemHeart)}>
										<Icon icon="heart" color="yellow" width={40} />
									</div>
									<div className={cx(styles.registerItemContent)}>
										Read letters from the heart
									</div>
								</div>

								<div className={cx(styles.registerItem)}>
									<div className={cx(styles.registerItemHeart)}>
										<Icon icon="heart" color="yellow" width={40} />
									</div>
									<div className={cx(styles.registerItemContent)}>
										Get community updates
									</div>
								</div>

								<div className={cx(styles.registerItem)}>
									<div className={cx(styles.registerItemHeart)}>
										<Icon icon="heart" color="yellow" width={40} />
									</div>
									<div className={cx(styles.registerItemContent)}>
										Track votes and fulfill needs
									</div>
								</div>
							</div>
							<Button
								className={cx(styles.btn)}
								onClick={async () => {
									dispatch(hideComponent());
									dispatch(clearChosenNeed());
									if (loggedInUser) {
										history.push('/');
									} else {
										const cur_email = get('votedUserEmail');
										if (cur_email) {
											try {
												const res = await checkIfUserHasPassword({
													email: cur_email
												});
												if (res?.success) {
													history.push('/');
												}
											} catch (e) {
												history.push('/employee-signup');
											}
										} else {
											history.push('/employee-signup');
										}
									}
								}}>
								Let's Go
							</Button>
						</div>
					) : !isForNeighbours ? (
						<div className={cx(styles.cardRightNoEmployeeFeature)}>
							<Heading level={2}>Great Choice!</Heading>
							<p className={cx(styles.centredTextNoEmployeeFeature)}>
								Your vote has been counted. Stay tuned to find out which need
								will be fulfilled!
							</p>
						</div>
					) : (
						<div className={cx(styles.cardRightNoEmployeeFeature)}>
							<Heading level={2} className="mb2">
								Great Choice!
							</Heading>
							<p type="flex" hAlign="center" className={cx('text-center')}>
								Use{' '}
								<a
									href="https://hitroop.com/?utm_source=vote%20page&utm_medium=neighbour%20campaign"
									target="_blank"
									className={cx('textBlack', styles.linkContainer)}>
									Troop
								</a>{' '}
								to engage your employees in your 2023 corporate giving strategy!
								Book a{' '}
								<a
									href="https://go.hitroop.com/campaign-demo"
									target="_blank"
									className={cx('textBlack', styles.linkContainer)}>
									demo
								</a>{' '}
								today!
							</p>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default VoteCelebration;
