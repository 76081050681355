import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import AddressInMap from 'root/components/AddressInMap';

import styles from '../SingleNeed.module.scss';

const Address = ({ address }) => {
	return (
		<>
			<Div className="w-per-100 px2">
				<Div type="flex" hAlign="start" className="w-per-100 mb4">
					Location: {address}
				</Div>
				{/* <Div>
					<AddressInMap provider="mapBox" address={address} />
				</Div> */}
			</Div>
		</>
	);
};

export default Address;
