import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Table, Button } from 'troop-design';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { getPledgedNeedInfo } from 'root/services/needs';

import styles from './PledgeDetails.module.scss';

const PledgeDetails = () => {
	const params = useParams();
	const history = useHistory();

	const needId = params.needId;
	const businessId = params.businessId;

	const [pledges, setPledges] = useState(null);

	useEffect(() => {
		const getPledgedInfo = async () => {
			const data = await getPledgedNeedInfo(businessId, needId);
			setPledges(data.pledger_employees);
		};
		if (needId && businessId) {
			getPledgedInfo();
		}
	}, [needId, businessId]);

	const columns = useMemo(() => {
		let cols = [
			{
				Header: 'Email',
				accessor: 'email',
				filter: 'equals'
			},
			{
				Header: 'Amount',
				accessor: 'amount',
				filter: 'equals'
			}
		];

		return cols;
	}, []);

	const data = useMemo(() => {
		if (pledges) {
			return Object.keys(pledges).map(key => {
				let showingCols = {};
				showingCols['email'] = key;
				showingCols['amount'] = pledges[key];
				return showingCols;
			});
		}
	}, [pledges]);

	return (
		<>
			{data?.length ? (
				<div>
					<Table
						className={styles.employeesTable}
						data={data}
						columns={columns}
						striped={false}
					/>
				</div>
			) : (
				''
			)}
			<div className={cx(styles.btnContainer)}>
				<Button
					className={cx(styles.backBtn)}
					onClick={() =>
						history.push(`/businesses/${businessId}/list-pledges`)
					}>
					Go Back
				</Button>
			</div>
		</>
	);
};

export default PledgeDetails;
