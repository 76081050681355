import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { Div, Card, Image } from 'basedesign-iswad';

import Form from 'root/baseComponents/Form/Form';
import Button from 'root/baseComponents/Button/Button';
import CharityWrapper from '../CharityWrapper';
import FormTextArea from 'root/baseComponents/FormTextArea/FormTextArea';
import FormImagePicker from 'root/baseComponents/FormImagePicker';
import Close from 'root/baseComponents/Close';
import Modal from 'root/baseComponents/Modal/Modal';

import { setModalProps } from 'root/reducers/charityReducers/modalProps';
import { setModalType } from 'root/reducers/charityReducers/modalType';

import { COLORS } from 'root/constants/vars';

import {
	sendFeedback,
	getNeedInfoRegisteredByCharity,
	getFeedback,
	deleteFeedback
} from 'root/services/charity';
import { addAlertItem } from 'root/utils/notifications';
import { getBackendUrl } from 'root/utils/helpers';

import styles from './SendFeedback.module.scss';
import TextBox from 'root/baseComponents/TextBox/TextBox';
import Icon from 'root/baseComponents/Icon/Icon';
import Tooltip from 'root/baseComponents/Tooltip/Tooltip';

function SendFeedback() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { id } = useParams();

	const [needInfo, setNeedInfo] = useState({});

	const [from, setFrom] = useState(['']);
	const [fromErrorMessage, setFromErrorMessage] = useState(['']);

	const [impactStory, setImpactStory] = useState(['']);
	const [impactStoryErrorMessage, setImpactStoryErrorMessage] = useState(['']);

	const [photo, setPhoto] = useState('');
	const [photoErrorMessage, setPhotoErrorMessage] = useState('');

	const [video, setVideo] = useState('');
	const [videoErrorMessage, setVideoErrorMessage] = useState('');

	const [allPhotoSrcs, setAllPhotoSrcs] = useState([]);
	const [allVideoSrcs, setAllVideoSrcs] = useState([]);

	const [allSavedPhotoSrcs, setAllSavedPhotoSrcs] = useState({});
	const [allSavedVideoSrcs, setAllSavedVideoSrcs] = useState({});

	const [allPhotos, setAllPhotos] = useState([]);
	const [allVideos, setAllVideos] = useState([]);

	const [isSaveDisabled, setIsSaveDisabled] = useState(false);
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	const [showTooltip, setShowTooltip] = useState(false);

	const [cropCompleted, setCropCompleted] = useState(false);

	const getNeedInfoRegisteredByCharityData = async () => {
		const res = await getNeedInfoRegisteredByCharity(id);
		setNeedInfo(res);
	};

	const getFeedbackData = async () => {
		const res = await getFeedback(id);
		console.log(res);
		const localTestimony = res.filter(item => item.media_type === 'testimony');
		console.log(localTestimony);

		if (localTestimony?.length) {
			const localImpactStory = [];
			const localFrom = [];
			localTestimony.forEach(item => {
				localImpactStory.push(item?.text);
				localFrom.push(item?.citation);
			});
			setFrom(localFrom);
			setImpactStory(localImpactStory);
		}
		const localImages = res.filter(item => item.media_type === 'image');
		const localImageSrcs = {};
		localImages.forEach(item => {
			localImageSrcs[item.id] = `${getBackendUrl()}${item.image}`;
		});
		setAllSavedPhotoSrcs(localImageSrcs);

		const localVideos = res.filter(item => item.media_type === 'video');
		const localVideoSrcs = {};
		localVideos.forEach(item => {
			localVideoSrcs[item.id] = `${getBackendUrl()}${item.video}`;
		});
		setAllSavedVideoSrcs(localVideoSrcs);
	};

	useEffect(() => {
		if (id) {
			getFeedbackData();
		}
	}, [id]);

	useEffect(() => {
		if (photo && cropCompleted) {
			let curAllPhotos = [...allPhotos];
			let curAllPhotoSrcs = { ...allPhotoSrcs };
			curAllPhotos.push(photo);
			setAllPhotos(curAllPhotos);
			curAllPhotoSrcs[photo.name] = URL.createObjectURL(photo);
			setAllPhotoSrcs(curAllPhotoSrcs);
			console.log(curAllPhotos);
		}
	}, [photo, cropCompleted]);

	useEffect(() => {
		if (video) {
			let curAllVideos = [...allVideos];
			let curAllVideoSrcs = { ...allVideoSrcs };
			curAllVideos.push(video);
			setAllVideos(curAllVideos);
			curAllVideoSrcs[video.name] = URL.createObjectURL(video);
			setAllVideoSrcs(curAllVideoSrcs);
		}
	}, [video]);

	const saveHandler = async e => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('need_id', id);
		formData.append('is_draft', true);
		let count = 0;
		from.forEach(p => {
			count += 1;
			formData.append(`citation${count}`, p);
		});
		count = 0;
		impactStory.forEach(p => {
			count += 1;
			formData.append(`testimony${count}`, p);
		});
		count = 0;
		allPhotos.forEach(p => {
			count += 1;
			formData.append(`image${count}`, p);
		});
		count = 0;
		allVideos.forEach(v => {
			count += 1;
			formData.append(`video${count}`, v);
		});
		try {
			setIsSaveDisabled(true);
			setIsSubmitDisabled(true);
			const res = await sendFeedback(formData);
			addAlertItem(dispatch, `Data has been successfully saved!`, 'success');
			history.go(0);
		} catch (err) {
			console.log(err);
			addAlertItem(
				dispatch,
				'Something went wrong; please try again!',
				'error'
			);
			setIsSaveDisabled(false);
			setIsSubmitDisabled(false);
		}
	};

	const submitHandler = async () => {
		const formData = new FormData();
		formData.append('need_id', id);
		formData.append('text', impactStory);
		let count = 0;
		from.forEach(p => {
			count += 1;
			formData.append(`citation${count}`, p);
		});
		count = 0;
		impactStory.forEach(p => {
			count += 1;
			formData.append(`testimony${count}`, p);
		});
		count = 0;
		allPhotos.forEach(p => {
			count += 1;
			formData.append(`image${count}`, p);
		});
		{
			allPhotos.length < 1 &&
				dispatch(
					setModalProps({
						text: 'You should upload at least one photo.',
						header: 'Error'
					})
				);
			dispatch(setModalType('danger'));
			setIsSaveDisabled(false);
			setIsSubmitDisabled(false);
		}
		count = 0;
		allVideos.forEach(v => {
			count += 1;
			formData.append(`video${count}`, v);
		});
		try {
			// if (allPhotos.length === 0) {
			// 	dispatch(
			// 		setModalProps({
			// 			text: 'You should upload at least one photo.',
			// 			header: 'Error'
			// 		})
			// 	);
			// 	dispatch(setModalType('danger'));
			// 	setIsSaveDisabled(false);
			// 	setIsSubmitDisabled(false);
			// } else {
			setIsSubmitDisabled(true);
			setIsSaveDisabled(true);
			const res = await sendFeedback(formData);
			console.log(res);
			dispatch(
				setModalProps({ text: 'successfuly completed.', header: 'Success' })
			);
			dispatch(setModalType('success'));

			history.push('/charity-needs');
			// }
		} catch (err) {
			console.log(err);
			addAlertItem(
				dispatch,
				'Something went wrong; please try again!',
				'error'
			);
			setIsSaveDisabled(false);
			setIsSubmitDisabled(false);
		}
	};

	const deletePhotoHandler = k => {
		const curAllPhotos = allPhotos.filter(p => p.name !== k);
		setAllPhotos(curAllPhotos);
		const curAllPhotoSrcs = { ...allPhotoSrcs };
		delete curAllPhotoSrcs[k];
		setAllPhotoSrcs(curAllPhotoSrcs);
	};

	const deleteVideoHandler = k => {
		const curAllVideos = allVideos.filter(v => v.name !== k);
		setAllVideos(curAllVideos);
		const curAllVideoSrcs = { ...allVideoSrcs };
		delete curAllVideoSrcs[k];
		setAllVideoSrcs(curAllVideoSrcs);
	};

	const deletePhotoFeedbackHandler = id => {
		deleteFeedback(id);
		const localSavedPhotoSrcs = { ...allSavedPhotoSrcs };
		delete localSavedPhotoSrcs[id];
		setAllSavedPhotoSrcs(localSavedPhotoSrcs);
	};

	const deleteVideoFeedbackHandler = id => {
		deleteFeedback(id);
		const localSavedVideoSrcs = { ...allSavedVideoSrcs };
		delete localSavedVideoSrcs[id];
		setAllSavedVideoSrcs(localSavedVideoSrcs);
	};

	return (
		<>
			<CharityWrapper pageName="">
				<Div className={cx(styles.main)}>
					<Div
						type="flex"
						vAlign="center"
						showIn={['md', 'lg']}
						className={cx('mb2', styles.heading)}
						distributedBetween>
						<Div className="employeeHeader">Impact Stories</Div>
						<Div type="flex">
							<Button
								onClick={() => {
									const localImpactStory = [...impactStory];
									const localFrom = [...from];
									localImpactStory.push('');
									localFrom.push('');
									setImpactStory(localImpactStory);
									setFrom(localFrom);
								}}>
								{' '}
								+ Add Testimonial
							</Button>
						</Div>
					</Div>
					<Div
						type="flex"
						vAlign="center"
						showIn={['xs', 'sm']}
						className={cx('mb2')}
						distributedBetween>
						<Div className="employeeHeader">Impact Stories</Div>
						<Div type="flex">
							<Button
								onClick={() => {
									const localImpactStory = [...impactStory];
									const localFrom = [...from];
									localImpactStory.push('');
									localFrom.push('');
									setImpactStory(localImpactStory);
									setFrom(localFrom);
								}}>
								{' '}
								+ Add Testimony
							</Button>
						</Div>
					</Div>
					<Card
						className={cx(
							'card pt4 pl8 pr8 pb3 br-all-hidden-1 br-rad-px-5',
							styles.card
						)}>
						<Div className="mb2 fs-16">
							{' '}
							✨ This feedback is shared with all employees and is a great way
							to bring the impact home and deliver the 'feel goods' that keep
							Troopers fulfilling more local needs.
						</Div>
						<Form onSubmit={submitHandler}>
							<Div type="flex" className={cx(styles.testimonyContainer)}>
								{from?.map((impact, idx) => (
									<Div key={idx} className="p1 mb1">
										<Div
											type="flex"
											distributedBetween
											className={'w-per-100 mb1'}>
											<Div className="textPrimary f-b">
												Testimonial {idx + 1}:
											</Div>
											{idx >= 1 && (
												<Div
													className="mouse-hand"
													onClick={() => {
														const localFrom = [...from];
														localFrom.splice(from?.[idx], 1);
														setFrom(localFrom);
														const localImpact = [...impactStory];
														localImpact.splice(impactStory?.[idx], 1);
														setImpactStory(localImpact);
													}}>
													<Icon
														type="delete"
														color={COLORS.primary}
														scale={1.5}
													/>
												</Div>
											)}
										</Div>
										<Div type="flex">
											<Div className="mr1">Impact Stories:</Div>
											<Div
												type="flex"
												vAlign="center"
												className="textFaded fs-px-12">
												Send some love back to the Troopers who fulfilled your
												need.
											</Div>
										</Div>

										<FormTextArea
											placeHolder="Thank you so much for the bed sheets! Did you know that we have 237 women and children who come through our doors every year and it’s important to be able to welcome them with clean sheets! Thanks for helping your neighbours in need."
											val={impactStory?.[idx]}
											setVal={setImpactStory}
											errorMessage={impactStoryErrorMessage?.[idx]}
											errorHandler={setImpactStoryErrorMessage}
											hasDefaultOnChange={false}
											onChange={e => {
												const localImpact = [...impactStory];
												localImpact[idx] = e.target.value;

												setImpactStory(localImpact);
											}}
										/>
										<Div type="flex">
											<Div className="mr1">From:</Div>
											{/* <Div
												type="flex"
												vAlign="center"
												className="textFaded fs-px-12">
												Send some love back to the Troopers who fulfilled your
												need.
											</Div> */}
										</Div>
										<TextBox
											placeHolder="John Doe, Manager"
											val={from?.[idx]}
											setVal={setFrom}
											errorMessage={fromErrorMessage?.[idx]}
											errorHandler={setFromErrorMessage}
											hasDefaultOnChange={false}
											onChange={e => {
												const localFrom = [...from];
												localFrom[idx] = e.target.value;
												setFrom(localFrom);
											}}
										/>
									</Div>
								))}{' '}
							</Div>
							<Div type="flex">
								<Div className="mr1">Photos:</Div>
								<Div type="flex" vAlign="center" className="textFaded fs-px-12">
									Add a photo or video message! Help us show the Troopers how
									your need was fulfilled. (We love helping kiddos, but would
									prefer if you don’t share images of children under the age of
									18)
								</Div>
							</Div>

							<Div type="flex" className="mt2">
								<Tooltip text="JPG, PNG, HEIC" width={150}>
									<FormImagePicker
										labelText=""
										file={photo}
										setFile={setPhoto}
										errorMessage={photoErrorMessage}
										errorHandler={setPhotoErrorMessage}
										id="impactstoryphoto1"
										hasCropper={true}
										cropInfo={{ aspect: 4 / 3 }}
										showDefaultPrev={false}
										accept=".jpg,.jpeg,.png,.heic"
										placeHolderType={2}
										placeHolderText="Add Image"
										className="w-px-150"
										useDefaultClassName={false}
										cropCompleted={cropCompleted}
										setCropCompleted={setCropCompleted}
									/>
								</Tooltip>
								<Tooltip text="Upload a video" width={200}>
									<FormImagePicker
										labelText=""
										file={video}
										setFile={setVideo}
										errorMessage={videoErrorMessage}
										errorHandler={setVideoErrorMessage}
										id="impactstoryvideo1"
										hasCropper={false}
										showDefaultPrev={false}
										accept=".mp4,.mkv"
										placeHolderType={2}
										placeHolderText="Add Video"
										className="w-px-150"
										useDefaultClassName={false}
									/>{' '}
								</Tooltip>
							</Div>
							<Div type="flex" className="flex-wrap">
								{Object.keys(allSavedPhotoSrcs).map((k, idx) => (
									<Div
										type="flex"
										vAlign="center"
										hAlign="center"
										key={idx}
										className={cx(
											'w-px-200 mb1 mr1 pos-rel br-all-solid-2 p3 br-rad-px-5 br-color-primary'
										)}>
										<Close onClick={() => deletePhotoFeedbackHandler(k)} />
										<Div>
											<Image
												src={allSavedPhotoSrcs[k]}
												className="w-per-100 max-w-px-200 max-height-px-200"
											/>
										</Div>
									</Div>
								))}

								{Object.keys(allSavedVideoSrcs).map((k, idx) => (
									<Div
										type="flex"
										vAlign="center"
										hAlign="center"
										key={idx}
										className={cx(
											'w-px-200 height-px-200 mb1 mr1 pos-rel br-all-solid-2 p2 br-rad-px-5 br-color-primary'
										)}>
										<Close onClick={() => deleteVideoFeedbackHandler(k)} />
										<video width="160" controls>
											<source src={allSavedVideoSrcs[k]} />
										</video>
									</Div>
								))}

								{Object.keys(allPhotoSrcs).map((k, idx) => (
									<Div
										type="flex"
										vAlign="center"
										hAlign="center"
										key={idx}
										className={cx(
											'w-px-200 mb1 mr1 pos-rel br-all-solid-2 p3 br-rad-px-5 br-color-primary'
										)}>
										<Close onClick={() => deletePhotoHandler(k)} />
										<Div>
											<Image
												src={allPhotoSrcs[k]}
												className="w-per-100 max-w-px-200 max-height-px-200"
											/>
										</Div>
									</Div>
								))}

								{Object.keys(allVideoSrcs).map((k, idx) => (
									<Div
										type="flex"
										vAlign="center"
										hAlign="center"
										key={idx}
										className={cx(
											'w-px-200 height-px-200 mb1 mr1 pos-rel br-all-solid-2 p2 br-rad-px-5 br-color-primary'
										)}>
										<Close onClick={() => deleteVideoHandler(k)} />
										<video width="160" controls>
											<source src={allVideoSrcs[k]} />
										</video>
									</Div>
								))}
							</Div>
							<Button
								onClick={saveHandler}
								disabled={isSaveDisabled}
								colorType="transparent"
								className="mt5 mr2 w-px-200 mouse-hand">
								Save Feedback
							</Button>
							<Button
								disabled={isSubmitDisabled}
								className="mt5 w-px-200 mouse-hand">
								Feedback Completed
							</Button>
						</Form>
					</Card>
				</Div>
			</CharityWrapper>
		</>
	);
}

export default SendFeedback;
