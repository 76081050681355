import React, {
	useState,
	useEffect,
	useMemo,
	useRef,
	useCallback
} from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Card, Button, Heading, Image, Anchor } from 'troop-design';
import { useDispatch } from 'react-redux';

import { showModal } from 'reducers/modals';

import { getEnv } from 'utils/helpers';

import { microsoftAuthGetToken } from 'services/user';
import { activateTeamsChannelForBusiness } from 'services/teams';
import ChannelSetupPrompt from './components/ChannelSetupPrompt';
import Loading from 'root/components/Loading';
import styles from './TeamsRedirect.module.scss';
import PermissionSetupPrompt from './components/PermissionSetupPrompt';

import permissions from 'assets/permission2.png';
import temasInstallSuccess from 'assets/temasInstallSuccess.png';
import teamsChannelSetup from 'assets/teamsChannelSetup.png';
import teamsActivationMsg from 'assets/teamsActivationMsg.png';

const TeamsRedirect = () => {
	const setupRef = useRef();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const queries = queryString.parse(search);
	const code = queries?.code || '';

	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [loading, setLoading] = useState(false);
	const [channelActivatedSuccess, setChannelActivatedSuccess] = useState(false);
	const [channelActivatedError, setChannelActivatedError] = useState(false);
	const [channelSetupMessage, setChannelSetupMessage] = useState('');
	const [teamsRedirectLink, setTeamsRedirectLink] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const [teamName, setTeamName] = useState('');
	const [channelName, setChannelName] = useState('');

	const business = useSelector(state => state.businesses.businesses);
	const business_id = useMemo(() => business?.[0]?.id || false, [business]);

	useEffect(() => {
		const getAuthToken = async () => {
			try {
				const res = await microsoftAuthGetToken({
					code,
					method: 'teams',
					business_id
				});
				if ('access_token' in res['Authorization']) {
					setError(false);
					setSuccess(true);
					dispatch(showModal('permissionSetupPrompt'));
				} else {
					setSuccess(false);
					setError(true);
					dispatch(showModal('permissionSetupPrompt'));
				}
			} catch (err) {
				console.log(err);
				setSuccess(false);
				setError(true);
				dispatch(showModal('permissionSetupPrompt'));
			}
		};
		if (code && business_id) {
			console.log(code.length);
			getAuthToken();
		}
	}, [code, business_id]);

	useEffect(() => {
		const curUrl = window.location.href;
		let curClientUrl = getEnv('URL');
		let teamsClientId = getEnv('MICROSOFT_AUTH_CLIENT_ID');
		if (curUrl.includes(getEnv('STAGING_URL'))) {
			curClientUrl = getEnv('STAGING_URL');
			teamsClientId = getEnv('MICROSOFT_AUTH_CLIENT_ID_STAGING');
		}

		const redirectLink = `${getEnv(
			'MICROSOFT_AUTH_AUTHORIZE_URL'
		)}?client_id=${teamsClientId}&scope=${getEnv(
			'MICROSOFT_AUTH_SCOPES'
		)}&redirect_uri=${curClientUrl}/${getEnv(
			'MICROSOFT_AUTH_REDIRECT_URL'
		)}&response_type=code&response_mode=query`;
		setTeamsRedirectLink(redirectLink);
	}, []);

	const copyToClipBoard = () => {
		navigator.clipboard.writeText(teamsRedirectLink);
		setIsCopied(true);
	};

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	useEffect(() => {
		if (success) {
			setupRef.current.scrollIntoView();
		}
	}, [success]);

	const submitHandler = useCallback(
		e => {
			const handler = async () => {
				setLoading(true);
				try {
					const res = await activateTeamsChannelForBusiness({
						team_name: teamName,
						channel_name: channelName,
						business_id
					});
					setLoading(false);
					if (res?.success) {
						setChannelSetupMessage('');
						setChannelActivatedError(false);
						setChannelActivatedSuccess(true);
						dispatch(showModal('channelSetupPrompt'));
					} else {
						setChannelSetupMessage('');
						setChannelActivatedSuccess(false);
						setChannelActivatedError(true);
						dispatch(showModal('channelSetupPrompt'));
					}
				} catch (err) {
					setChannelActivatedSuccess(false);
					if (err?.response?.data?.message) {
						setChannelSetupMessage(err.response.data.message);
					} else {
						setChannelSetupMessage('');
					}
					setChannelActivatedError(true);
					dispatch(showModal('channelSetupPrompt'));
					setLoading(false);
				}
			};
			if (teamName && channelName && business_id) {
				e.preventDefault();
				handler();
			}
		},
		[teamName, channelName, business_id]
	);

	return (
		<div className={cx(styles.teamsContainer)}>
			<Heading level={1}>Team's Integration Install Guide</Heading>
			<Card>
				<p>
					If you have any issues with your installation, please give us a shout
					at <a href="mailto:hello@hitroop.com">hello@hitroop.com</a> and we
					will hepl you right away.
				</p>
			</Card>
			<Heading level={3}>Get started: install the app</Heading>
			<div className={cx(styles.section)}>
				<Card>
					<p>Step 1: Click the "Install Now" button</p>
					{teamsRedirectLink && (
						<div className={cx(styles.btnContainer)}>
							<Anchor
								className={cx('mx-1 bgPrimary', styles.btn, styles.link)}
								to={teamsRedirectLink}>
								Install Now
							</Anchor>

							<Button
								variant="dark"
								className={cx('mx-1', styles.btn)}
								onClick={copyToClipBoard}>
								Copy Install Link
							</Button>
							{isCopied && <div className={cx(styles.copied)}>Copied</div>}
						</div>
					)}
					<div className={cx(styles.break)}></div>
				</Card>
				<Card>
					<p>
						Select the microsoft admin account of your organization. (An account
						with Global Adminstration role)
					</p>
				</Card>
				<Card>
					<p>
						Step3: Please read, concent via checkbox ☑️, and accept the
						permssions pop-up.
					</p>
					<div className={cx(styles.imgContainer)}>
						<img src={permissions} className={cx(styles.img)} />
					</div>
				</Card>
				<Card>
					<p>
						Step4: After the installation is complete you will receive this
						following pop-up. (You may dismiss the pop-up and move on to the
						next section.)
					</p>
					<div className={cx(styles.imgContainer)}>
						<img
							src={temasInstallSuccess}
							className={cx(styles.img, styles.biggerImg)}
						/>
					</div>
				</Card>
			</div>
			<div ref={el => (setupRef.current = el)}>
				<Heading level={3}>Configure Teams: Setup Your Channel</Heading>
				<div className={cx(styles.section)}>
					<Card>
						<p>
							<span className={cx(styles.txt_bold)}>Step1:</span> After teams
							installation, we need to setup your Team Channels.
						</p>
						<div className={cx(styles.listContainer)}>
							<p>
								- Create a new team name (we suggest calling it something like
								Social Impact) and add your emaployees to this new team
							</p>
							<p>- Create a channel name (we suggest Troop)</p>
							<p>- Click Submit</p>
						</div>
						<form onSubmit={submitHandler} className={cx(styles.formContainer)}>
							<span className={cx(styles.txt_bold)}>
								<div>Setup Your Channel</div>
							</span>
							<div className={cx(styles.inputContainer)}>
								<div className={cx(styles.label)}>
									<label>Team name</label>
								</div>
								<div className={cx(styles.input)}>
									<input
										type="text"
										className={cx(styles.inputText)}
										value={teamName}
										onChange={e => setTeamName(e.target.value)}
									/>
								</div>
							</div>
							<div className={cx(styles.inputContainer)}>
								<div className={cx(styles.label)}>
									<label>Channel name</label>
								</div>
								<div className={cx(styles.input)}>
									<input
										type="text"
										className={cx(styles.inputText)}
										value={channelName}
										onChange={e => setChannelName(e.target.value)}
									/>
								</div>
							</div>
							<div className={cx(styles.inputContainer)}>
								<input type="submit" className={cx(styles.inputSubmit)} />
							</div>
						</form>
					</Card>
					<Card>
						<p>
							<span className={cx(styles.txt_bold)}>Step2:</span> After
							submitting the Team and Channel name, you will recieve this image!
						</p>
						<div className={cx(styles.imgContainer)}>
							<img
								src={teamsChannelSetup}
								className={cx(styles.img, styles.biggerImg)}
							/>
						</div>
					</Card>
					<Card>
						<p>
							<span className={cx(styles.txt_bold)}>Final Step:</span> Navigate
							to Teams and make sure that you have received our welcome message:
						</p>
						<div className={cx(styles.imgContainer)}>
							<img
								src={teamsActivationMsg}
								className={cx(
									styles.img,
									styles.biggerImg,
									styles.activationImg
								)}
							/>
						</div>
					</Card>
				</div>
			</div>
			<div>
				<Card>
					<p className={cx(styles.highlighted)}>
						That's it! Your team will now receive monthly vote notifications,
						last call reminders, and impact photos and images to your Microsoft
						Team Channel from Troop. 🎉
					</p>
				</Card>
			</div>
			{success && <PermissionSetupPrompt success={true} />}
			{error && <PermissionSetupPrompt success={false} />}
			{channelActivatedSuccess && (
				<ChannelSetupPrompt success={true} message={channelSetupMessage} />
			)}
			{channelActivatedError && (
				<ChannelSetupPrompt success={false} message={channelSetupMessage} />
			)}
			{loading && <Loading />}
		</div>
	);
};

export default TeamsRedirect;
