import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { getPaymentIntent } from 'services/businesses';
import CheckoutForm from './components/CheckoutForm';

export default function BusinessPayment({ businessId }) {
	const [payment, setPayment] = useState(null);

	const params = useParams();

	const id = params.businessId ? Number(params.businessId) : businessId;

	useEffect(() => {
		getPaymentIntent(id).then(data => {
			if (data.error == false) {
				setPayment({
					monthlyContribution: Number(data.monthly_contribution).toFixed(2),
					troopFee: Number(data.troop_fee).toFixed(2),
					hst: Number(data.hst).toFixed(2),
					total: (data.total / 100).toFixed(2)
				});
			}
		});
	}, []);

	return (
		<div>
			<CheckoutForm businessId={id} payment={payment} />
		</div>
	);
}
