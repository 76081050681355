import React, { useState } from 'react';
import cx from 'classnames';
import { Div, Row, Column } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';
import dayjs from 'dayjs';

import { getBackendUrl } from 'root/utils/helpers';
import { getNeedsRegisteredByCharity } from 'root/services/charity';

import styles from '../SingleNeed.module.scss';
import { useEffect } from 'react';

const NeedInfo = ({ need }) => {
	const [statusIcon, setStatusIcon] = useState('');
	const [statusText, setStatusText] = useState('');
	const [taxReceipt, setTaxReceipt] = useState(true);
	const [needs, setNeeds] = useState([]);

	// useEffect(() => {
	// 	if (need?.is_vote_winner) {
	// 		setStatusIcon('circle-check');
	// 		setStatusText('Fulfilled');
	// 	} else if (need?.is_voting) {
	// 		setStatusIcon('vote');
	// 		setStatusText('On voting');
	// 	} else if (need?.status === 'closed') {
	// 		setStatusIcon('circle-xmark');
	// 		setStatusText('Closed');
	// 	} else {
	// 		setStatusIcon('clock');
	// 		setStatusText('Pending');
	// 	}
	// }, [need]);

	useEffect(() => {
		console.log(need);
	}, [need]);

	const getNeeds = async () => {
		const res = await getNeedsRegisteredByCharity();
		setNeeds(res);
	};

	useEffect(() => {
		getNeeds();
		console.log(needs);
	}, []);

	return (
		<>
			<Div className={cx(styles.contentContainer)}>
				<Row>
					<Column xs={12} sm={12} md={6} lg={8}>
						<Div className="">
							<Div className={cx('f-b mb3', styles.needTitle)}>
								{need?.name}
							</Div>
							<Div className="w-per-100 mb3">
								<img src={need?.image} />
							</Div>
							<Div className="f-b fs-px-20 mb3 textGray upperCase text-center">
								{need?.charity_name}
							</Div>
							<Div className="f-b fs-px-22 mb2 upperCase text-center">
								{need?.subtitle}
							</Div>
							<Div
								className="textGray mb3"
								dangerouslySetInnerHTML={{
									__html: need?.business_description || need?.description
								}}
							/>
						</Div>
					</Column>
					<Column xs={12} sm={12} md={6} lg={4} className="pr2 pl2">
						<Div type="flex" vAlign="end">
							<Div className={cx('w-per-100', styles.infoCard)}>
								<Div
									type="flex"
									vAlign="center"
									// hAlign="center"
									direction="vertical"
									className={cx(
										'ml-auto mr-auto min-height-px-300 w-per-80 br-all-solid-1 br-color-primary br-rad-px-5 boxShadowType1 pl2 pr2 pb3 pt3'
									)}>
									{need?.is_eligible_for_tax && (
										<Div type="flex" className="ml5 mb3 textGray">
											<Div className="mr2">
												<Icon type="circle-check" color="#f5891f" scale={1.5} />
											</Div>
											<Div type="flex">
												{' '}
												{need?.is_fulfilled
													? 'Fulfilled'
													: 'Eligible for charity tax receipt'}
											</Div>
										</Div>
									)}
									{need?.value && (
										<Div type="flex" className="ml5 textGray">
											<Div className="mr2">
												<Icon type="hand-dollar" color="#f5891f" scale={1.5} />
											</Div>
											<Div type="flex">{'$' + need?.value}</Div>
										</Div>
									)}
									{need?.charity_city && (
										<Div type="flex" className="ml5 mt3 textGray">
											<Div className="mr2">
												<Icon type="location" color="#f5891f" scale={1.5} />
											</Div>
											<Div type="flex">
												{need?.charity_location.province +
													', ' +
													need?.charity_city}
											</Div>
										</Div>
									)}
									{need?.charity_website && (
										<Div type="flex" className="ml5 mt3 textGray">
											<Div type="flex" className="mr2 ">
												<Icon type="globe" color="#f5891f" scale={1.5} />
											</Div>
											<Div type="flex">
												{' '}
												<a
													href={need?.charity_website}
													target="_blank"
													className={cx(
														'textPrimary mouse-hand',
														styles.charityWebsite
													)}>
													Charity Website
												</a>
											</Div>
										</Div>
									)}
									{need?.community_supported && (
										<Div type="flex" className="ml5 mt3 textGray">
											<Div type="flex" className="mr2 ">
												<Icon type="gift" color="#f5891f" scale={1.5} />
											</Div>
											<Div type="flex"> {need?.community_supported}</Div>
										</Div>
									)}
								</Div>
								<Div
									className={cx(
										'w-per-80 ml-auto mr-auto mt2 mb2 text-center'
									)}>
									<a
										href={'https://meetings.hubspot.com/kelly366/troop-demo'}
										target="_blank"
										className="">
										<Div className="w-per-100 bgPrimary p2 textWhite mouse-hand br-rad-px-5">
											I Want To Help!
										</Div>
									</a>
								</Div>
							</Div>
						</Div>
					</Column>
				</Row>
			</Div>
		</>
	);
};

export default NeedInfo;
