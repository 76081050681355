import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setCharityInfo'] = (state, action) => action.payload;

const slice = createSlice({
	name: 'charityInfo',
	initialState: {},
	reducers: reducerObject
});

export const { setCharityInfo } = slice.actions;
export default slice.reducer;
