// VENDOR imports
import dayjs from 'dayjs';
import currency from 'utils/currency';

// troop design imports
import {
	Anchor,
	Button,
	Dropdown,
	DropdownToggle,
	DropdownContent,
	Icon
} from 'troop-design';
import { Link } from 'react-router-dom';

// imports
import { calculateMonthsActive } from './calculateMonthsActive';
import { getBackendUrl } from 'root/utils/helpers';
import styles from '../Businesses.module.scss';

const processBusinessDataForTable = (businesses, showConfirmation) => {
	const processedBusinesses = [];
	businesses.forEach(business => {
		const troopFee = Math.max(business.monthly_contribution * 0.2, 75);
		const processedBusiness = {};
		// processedBusiness['checkbox'] = (<div>
		//     <input type="checkbox" name="selectBusiness" id={business.id} value={business.name} />
		//     <label for={business.id}></label>
		// </div>);
		processedBusiness['company'] = (
			<Anchor
				component={Link}
				to={`/businesses/${business.id}`}
				className="mb-2">
				{business.name}
			</Anchor>
		);
		processedBusiness['activationDate'] = dayjs(
			business.activation_date
		).format('MMMM/DD/YYYY');
		processedBusiness['needContribution'] = currency.format(
			business.monthly_contribution
		);
		processedBusiness['troopFee'] = currency.format(troopFee);
		processedBusiness['total'] = currency.format(
			business.monthly_contribution + troopFee
		);
		processedBusiness['employees'] = business.employee_count;
		processedBusiness['monthsActive'] = calculateMonthsActive(
			dayjs(business.activation_date)
		);
		processedBusiness['action'] = (
			<Dropdown>
				<DropdownToggle className={styles.roundedButton}>
					<Icon icon="more" size="lg" />
				</DropdownToggle>
				<DropdownContent className={styles.dropdownContent}>
					<div className={styles.buttonContainer}>
						<Anchor
							variant="primary"
							size="xs"
							component={Link}
							to={`/businesses/${business.id}/build-vote-landing-page`}
							className="mb-2">
							Build vote
						</Anchor>
						<Anchor
							variant="danger"
							size="xs"
							component={Link}
							to={`/businesses/${business.id}/employees`}
							className="mb-2">
							Employees
						</Anchor>
						<Anchor
							variant="dark"
							size="xs"
							target="_blank"
							to={`${getBackendUrl()}/admin/troop_app/business/${
								business.id
							}/change`}
							className="mb-2">
							Update
						</Anchor>
						<Button
							id={`${business.id}`}
							variant="success"
							size="xs"
							className="mb-2"
							onClick={showConfirmation}>
							Vote Reminder
						</Button>
					</div>
				</DropdownContent>
			</Dropdown>
		);
		processedBusinesses.push(processedBusiness);
	});
	return processedBusinesses;
};

export { processBusinessDataForTable };
