import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import StripeTokenizeCharge from 'root/baseComponents/StripeTokenizeCharge';

const TestNewStripe = () => {
	return (
		<>
			<StripeTokenizeCharge />
		</>
	);
};

export default TestNewStripe;
