import React, { useState } from 'react';
import cx from 'classnames';
import { Div, Row, Column, Heading } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';
import Anchor from '../../Anchor';

import { COLORS } from 'root/constants/vars';

import Logo from 'root/assets/troop_white-logo.png';

import styles from '../Footer.module.scss';

const SOCIAL_MEDIA_ICONS = [
	{ icon: 'linkedin', url: 'https://www.linkedin.com/company/jointeamtroop/' },
	{ icon: 'instagram-fill', url: 'https://www.instagram.com/jointeamtroop/' },
	{ icon: 'twitter', url: 'https://twitter.com/jointeamtroop' },
	{ icon: 'facebook', url: 'https://www.facebook.com/JoinTeamTroop/' }
];

const AboutSection = () => {
	const [activeSocialIcon, setActiveSocialIcon] = useState('');
	return (
		<>
			<Div
				direction="vertical"
				type="flex"
				hAlign="center"
				vAlign="center"
				className="w-per-100">
				<Div className="my3">
					<img src={Logo} />
				</Div>
				<Div className="p3">
					Discover and fulfill local, everyday needs near you.
				</Div>
				<Div type="flex" className="mb3">
					{SOCIAL_MEDIA_ICONS?.map((item, idx) => (
						<a
							href={item.url}
							className="w-px-40 height-px-40 mx2 "
							key={idx}
							target="_blank">
							<Div
								type="flex"
								hAlign="center"
								vAlign="center"
								className="w-per-100 height-px-40 bgWhite br-all-hidden-1 br-rad-px-5">
								<Icon type={item.icon} color={COLORS.primary} scale={1.25} />
							</Div>
						</a>
					))}
				</Div>
			</Div>
		</>
	);
};

export default AboutSection;
