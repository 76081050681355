import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import cx from 'classnames';
import {
	ImageUploadWithCropper,
	Heading,
	Button,
	Form,
	Input,
	Select,
	Icon,
	Card,
	Row,
	Col
} from 'troop-design';
import { addNotification } from 'root/reducers/notifications';
import { createCustomNewsItem, getBusinesses } from 'services/businesses';
import { getNearestMonth } from 'utils/date';

import styles from './CreateCustomNewsFeed.module.scss';

export default function CreateCustomNewsFeed() {
	dayjs.extend(weekday);
	const dispatch = useDispatch();
	const { businesses } = useSelector(state => state.businesses);
	const [resetImage, setResetImage] = useState(false);

	const [heading, setHeading] = useState('');
	const [bodyText, setBodyText] = useState('');
	const [ctaText, setCtaText] = useState('');
	const [ctaLink, setCtaLink] = useState('');
	const [businessList, setBusinessList] = useState([]);
	const nearestMonth = getNearestMonth().format('YYYY-MM-DD');
	const endDate = getNearestMonth().add(1, 'month').format('YYYY-MM-DD');

	useEffect(() => {
		dispatch(getBusinesses());
	}, []);

	const handleBusinessSet = target => {
		let value = target.value;
		value = [];
		target
			?.querySelectorAll('option:checked')
			?.forEach(entry => entry.value !== '' && value.push(entry.value));
		setBusinessList([...value]);
	};

	const handleSubmit = values => {
		const formData = new FormData();
		formData.append('businesses', businessList);
		formData.append('heading', values.heading);
		formData.append('message', values.bodyText);
		formData.append('cta_text', values.ctaText);
		formData.append('cta_link', values.ctaLink);
		formData.append('start_date', values.startDate);
		formData.append('end_date', values.endDate);
		formData.append('file', values.image);
		createCustomNewsItem(formData)
			.then(resp => {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Sucessfully created custom news item!'
					})
				);
				setBusinessList([]);
				setHeading('');
				setBodyText('');
				setCtaText('');
				setCtaLink('');
				setResetImage(true);
				window.scrollTo(0, 0);
			})
			.catch(err => {
				dispatch(
					addNotification({
						type: 'danger',
						message: 'Missing businesses, please select from the list below'
					})
				);
				window.scrollTo(0, 0);
			});
	};

	return (
		<div>
			<Row className={'mt-5'}>
				<Col xs={12} md={8} className="d-flex">
					<Heading level={1}>Create Custom News Item</Heading>
				</Col>
			</Row>
			<Form onSubmit={handleSubmit}>
				<Card>
					<ImageUploadWithCropper
						accept={'.jpg, .jpeg, .png'}
						type="file"
						label="Upload Image"
						name="image"
						id={`image`}
						aspect={400 / 200}
						validationRules={[
							{
								function: value => {
									return !!value;
								},
								message: 'Please upload an image'
							}
						]}
						resetImage={resetImage}
						setResetImage={setResetImage}
					/>
					<Input
						label="Heading"
						name="heading"
						value={heading}
						onKeyUp={e => setHeading(e.target.value)}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter a valid heading'
							}
						]}
					/>
					<Input
						label="Body Text"
						name="bodyText"
						value={bodyText}
						onKeyUp={e => setBodyText(e.target.value)}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter a valid message'
							}
						]}
					/>
					<Input
						label="Start date"
						name="startDate"
						type="date"
						value={nearestMonth}
					/>
					<Input label="End date" name="endDate" type="date" value={endDate} />
					<Input
						label="CTA Text"
						name="ctaText"
						value={ctaText}
						onKeyUp={e => setCtaText(e.target.value)}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter a valid cta text'
							}
						]}
					/>
					<Input
						label="CTA Link"
						name="ctaLink"
						value={ctaLink}
						onKeyUp={e => setCtaLink(e.target.value)}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter a valid cta link'
							}
						]}
					/>
					<Select
						className={cx(styles.type, styles.multiSelect)}
						name={`conditionBusinesses`}
						label="Select Businesses"
						value={''}
						onChange={e => handleBusinessSet(e.target)}
						multiple>
						{businesses.map(business => (
							<option key={business.id} value={business.id}>
								{business.name}
							</option>
						))}
					</Select>
				</Card>
				<Card className={styles.proceedBar}>
					<Button
						variant="success"
						className={cx('me-4 d-inline-flex align-items-center')}>
						<Icon
							icon="checkmark"
							size="md"
							color="white"
							className={cx('me-2', styles.fillWhite)}
						/>
						Create
					</Button>
				</Card>
			</Form>
		</div>
	);
}
