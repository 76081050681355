import React from 'react';
import cx from 'classnames';
import { useParams, useLocation } from 'react-router';

import SignUpOrLogin from 'components/SignUpOrLogin';
import LoginRegisterForm from 'components/LoginRegisterForm';

import styles from './SuggestToRegister.module.scss';

const SignUpOrLoginItems = [
	'Fulfill local needs',
	'Track your company votes',
	'Read letters from the heart',
	'Get community updates'
];
const SignUpOrLoginIncentiveQuotes = [
	'Get the Ball Rolling!',
	'Signup to Vote & More!'
];

const SuggestToRegister = () => {
	const location = useLocation();
	const params = useParams();
	const urlSearchParams = new URLSearchParams(location.search);

	let employeeKey = urlSearchParams.get('employee');
	const id = parseInt(params.id, 10);
	const date = params.date;
	const businessKey = params.businessKey;

	return (
		<>
			<SignUpOrLogin
				items={SignUpOrLoginItems}
				incentiveQuotes={SignUpOrLoginIncentiveQuotes}
				useGoToVoteTheme={true}
				useRegisterTheme={false}
				isRegisterPage={true}
				voteLink={
					employeeKey
						? `/vote/${id}/${businessKey}/${date}?employee=${employeeKey}`
						: `/vote/${id}/${businessKey}/${date}`
				}
			/>
		</>
	);
};

export default SuggestToRegister;
