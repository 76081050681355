import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Row,
	Col,
	Form,
	Input,
	Button,
	File,
	Errors,
	Select,
	Alert,
	Text
} from 'troop-design';
import RegisterContainer from 'components/RegisterContainer';
import useRedirectTo from 'hooks/useRedirectTo';
import { addBusiness } from 'services/businesses';
import { bulkEmployeeUpload } from 'services/employees';
import { addNotification } from 'root/reducers/notifications';
import { provinces } from 'root/constants/locale';
import cx from 'classnames';
import { setIsFullyRegistered } from 'root/reducers/users';
import styles from './CompleteRegistration.module.scss';
import Notifications from 'root/components/Notifications/index';

export default function CompleteRegistration() {
	const dispatch = useDispatch();
	const redirectTo = useRedirectTo();
	const [errorMessages, setErrorMessages] = useState([]);
	const userFullyRegistered = useSelector(
		state => state.users.userFullyRegistered
	);
	const handleRegister = useCallback(
		e => {
			const {
				phone,
				name,
				website,
				address,
				city,
				province,
				postal_code,
				monthly_contribution,
				employees,
				logo
			} = e;
			const businessFormData = new FormData();
			businessFormData.append('phone', phone);
			businessFormData.append('name', name);
			businessFormData.append('website', website);
			businessFormData.append('address', address);
			businessFormData.append('city', city);
			businessFormData.append('province', province);
			businessFormData.append('postal_code', postal_code);
			businessFormData.append('file', logo);
			businessFormData.append('monthly_contribution', monthly_contribution);
			addBusiness(businessFormData)
				.then(data => {
					dispatch(setIsFullyRegistered(true));
					if (employees === null || employees === '') {
						const businessId = data[0].id;
						const businessKey = data[0].key;
						redirectTo(
							`/businesses/${businessId}/${businessKey}/business-payment`
						);
					} else {
						const businessId = data.id;
						const businessKey = data.key;
						const employeesData = {
							file: employees
						};
						dispatch(bulkEmployeeUpload(businessId, employeesData));
						redirectTo(
							`/businesses/${businessId}/${businessKey}/business-payment`
						);
					}
				})
				.catch(err => {
					setErrorMessages(err.response.data.errors);
					dispatch(
						addNotification({
							type: 'danger',
							message: 'Something went wrong'
						})
					);
					console.log('err: ', err.response.data);
				});
		},
		[redirectTo, dispatch]
	);

	if (userFullyRegistered) {
		return (
			<Alert type="danger" dismissable={false}>
				You have already completed your registration.
			</Alert>
		);
	}

	return (
		<RegisterContainer>
			<Notifications />
			<Errors messages={errorMessages} />
			<Form onSubmit={handleRegister} resetOnSubmit={false}>
				<Row className="mb-5">
					<Col xs={12}>
						<Input
							label="Company Name"
							name="name"
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Please enter a valid company name'
								}
							]}
						/>
					</Col>
					<Col xs={12}>
						<Input label="Company URL" name="website" />
					</Col>
					<Col xs={12}>
						<Input
							label="Phone"
							name="phone"
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Please enter the phone number'
								}
							]}
						/>
					</Col>
					<Col xs={12}>
						<Input
							label="Street Address"
							name="address"
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Please enter the company address'
								}
							]}
						/>
					</Col>
					<Col md={4}>
						<Input
							label="City"
							name="city"
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Please enter the city'
								}
							]}
						/>
					</Col>
					<Col md={4}>
						<Select
							label="Provinces"
							name="province"
							validationRules={[
								{
									rule: 'option.atLeastOne',
									message: 'Please enter the province'
								}
							]}>
							{Object.keys(provinces).map(key => (
								<option key={key} value={key}>
									{provinces[key]}
								</option>
							))}
						</Select>
					</Col>
					<Col md={4}>
						<Input
							label="Postal Code"
							name="postal_code"
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Please enter the postal code'
								}
							]}
						/>
					</Col>
					<Col md={6} className="mb-4">
						<File
							accept={'.jpg, .png, .jpeg, .gif'}
							type="file"
							label="Logo"
							name="logo"
						/>
					</Col>
					<Col md={6} className="mb-4">
						<File
							accept={'.csv, .xlsx'}
							type="file"
							label="Employees"
							name="employees"
						/>
					</Col>
					<Col xs={12}>
						<Input
							label="Monthly Contribution ($)"
							name="monthly_contribution"
							placeholder="$"
							validationRules={[
								{
									rule: 'string.isRequired',
									message: 'Please enter a monthly contribution'
								}
							]}
						/>
						<Text hasWrapper className={styles.helperText}>
							This is your monthly contribution that will go towards fulfilling
							local needs every month. We recommend between $5-$30 per employee.
						</Text>
					</Col>
				</Row>
				<div className={styles.loginButtonContainer}>
					<Button
						variant="primary"
						size="lg"
						className={cx(styles.loginBtn, 'nowrap')}>
						Complete Registration
					</Button>
				</div>
			</Form>
		</RegisterContainer>
	);
}
