import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};

reducerObject['setMustShowPledgePopUpModal'] = (state, action) => {
	const localState = { ...state };
	localState['mustShowPledgePopUpModal'] = action.payload;
	return localState;
};

reducerObject['setMustshowAddressPopUpModal'] = (state, action) => {
	const localState = { ...state };
	localState['mustShowAddressPopUpModal'] = action.payload;
	return localState;
};

reducerObject['setMustShowFeedbackModal'] = (state, action) => {
	const localState = { ...state };
	localState['mustShowFeedbackModal'] = action.payload;
	return localState;
};

reducerObject['setSeenAddressModal'] = (state, action) => {
	const localState = { ...state };
	localState['seenAddressModal'] = action.payload;
	return localState;
};

reducerObject['setSeenPledgeModal'] = (state, action) => {
	const localState = { ...state };
	localState['seenPledgeModal'] = action.payload;
	return localState;
};

reducerObject['setSeenFeedbackModal'] = (state, action) => {
	const localState = { ...state };
	localState['seenFeedbackModal'] = action.payload;
	return localState;
};

const slice = createSlice({
	name: 'reminders',
	initialState: {
		mustShowPledgePopUpModal: false,
		mustShowAddressPopUpModal: false,
		mustShowFeedbackModal: false,
		seenAddressModal: false,
		seenPledgeModal: false,
		seenFeedbackModal: true
	},
	reducers: reducerObject
});

export const {
	setMustShowPledgePopUpModal,
	setMustshowAddressPopUpModal,
	setMustShowFeedbackModal,
	setSeenAddressModal,
	setSeenPledgeModal,
	setSeenFeedbackModal
} = slice.actions;
export default slice.reducer;
