import React from 'react';
import cx from 'classnames';
import { Div, Label, Input } from 'basedesign-iswad';

import styles from './FormCheckBox.module.scss';

function FormSelect({
	fullWidth = false,
	labelText,
	className,
	isRequired,
	errorMessage,
	errorHandler,
	...props
}) {
	return (
		<>
			<Div className={cx('pos-rel', styles.container, className)}>
				{labelText && (
					<Div className={cx(styles.labelContainer)}>
						<Label className={cx(isRequired && 'required', styles.label)}>
							{labelText}
						</Label>
					</Div>
				)}
				<Div className={cx(styles.inputContainer)}>
					<Input
						type={'checkbox'}
						className={cx(styles.viewClassName)}
						{...props}
					/>
				</Div>
				{errorMessage && (
					<Div
						className={cx(
							'iswad_input_errorMessage',
							errorMessage && 'iswad_input_errorMessage_active'
						)}>
						{errorMessage}
					</Div>
				)}
			</Div>
		</>
	);
}

export default FormSelect;
