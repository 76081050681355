import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button/Button';
import TextBox from 'root/baseComponents/TextBox/TextBox';
import Form from 'root/baseComponents/Form/Form';

import { generateKey } from 'root/utils/helpers';
import { addCharityMember } from 'root/services/charity';
import { addNotification } from 'root/reducers/notifications';
import { addAlertItem } from 'root/utils/notifications';

import CharityWrapper from '../../CharityWrapper';
import styles from '../Team.module.scss';

function AddMember() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [email, setEmail] = useState();
	const [password, setPassword] = useState();

	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState();
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState();
	const [emailErrorMessage, setEmailErrorMessage] = useState();
	const [passwordErrorMessage, setPasswordErrorMessage] = useState();

	useEffect(() => {
		const newKey = generateKey(8);
		setPassword(newKey);
	}, []);

	const submitHandler = async () => {
		try {
			const res = await addCharityMember({
				first_name: firstName,
				last_name: lastName,
				email,
				otp: password
			});
			if (res?.data) {
				addAlertItem(
					dispatch,
					`New member has been successfully added!`,
					'success'
				);
				history.push('/charity-team');
			} else {
				addAlertItem(dispatch, `${res.data}`, 'error');
			}
		} catch (err) {
			console.log(err.response.data.message);
			addAlertItem(dispatch, `${err.response.data.message}`, 'error');
		}
	};

	return (
		<>
			<CharityWrapper>
				<Div className={cx('pt3 pl2 pr2', styles.main)}>
					<Div className="employeeHeading mb2"> Add a new member</Div>
					<Div className="card w-per-80 pl2 pr2 pt2 pb2">
						<Form onSubmit={submitHandler}>
							<Div className="flex">
								<TextBox
									labelText="First name"
									type="text"
									className="mr2"
									val={firstName}
									setVal={setFirstName}
									errorHandler={setFirstNameErrorMessage}
									name="first_name"
									errorMessage={firstNameErrorMessage}
								/>
								<TextBox
									labelText="Last name"
									type="text"
									val={lastName}
									setVal={setLastName}
									errorHandler={setLastNameErrorMessage}
									name="last_name"
									errorMessage={lastNameErrorMessage}
								/>
							</Div>
							<Div className="flex">
								<TextBox
									labelText="Email"
									type="text"
									val={email}
									setVal={setEmail}
									errorHandler={setEmailErrorMessage}
									name="email"
									className="mr2"
									errorMessage={emailErrorMessage}
								/>
								<TextBox
									labelText="Temporary Password"
									type="password"
									val={password}
									setVal={setPassword}
									errorHandler={setPasswordErrorMessage}
									name="password"
									errorMessage={passwordErrorMessage}
								/>
							</Div>
							<Button> Add Member</Button>
						</Form>
					</Div>
				</Div>
			</CharityWrapper>
		</>
	);
}

export default AddMember;
