import React, { useState } from 'react';

import Sectional from 'root/components/Sectional';
import PledgeProgressBar from 'root/components/PledgeProgressBar';
import ProfileHeader from 'root/components/ProfileHeader/ProfileHeader';
import { Tab, TabItem, TabContent, Icon, Tutorial, Button } from 'troop-design';

import tutorial1 from './data/Tutorial - Community Needs Photo - 1.png';
import tutorial2 from './data/Tutorial - Action Board Photo - 2.png';
import tutorial3 from './data/Tutorial - Personal Pledging Photo - 3.png';
import tutorial4 from './data/Tutorial - Vote Streak Photo - 4.png';
import tutorial5 from './data/Tutorial - My Impact Photo - 5.png';

export default function DevLibrary() {
	const [selectState, setSelectState] = useState(0);

	const [tutorialSlideNumber, setTutorialSlideNumber] = useState(-1);

	const tutorialData = React.useMemo(
		() => [
			{
				imgSrc: tutorial1,
				imgAlt: 'community needs tutorial image',
				modalTitle: 'Community Needs',
				modalContent:
					'Here’s a summary of your do good options for the month.\n\nAfter you vote you can Pledge to help ensure your favorite need gets fulfilled!!',
				modalArrowPosition: 'leftTop',
				modalButtonText: 'Next',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '74%', top: '7.5%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '74%', top: '7.5%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			},
			{
				imgSrc: tutorial2,
				imgAlt: 'action board tutorial image',
				modalTitle: 'Action Board',
				modalContent:
					'Keep up to date with vote and pledge dates, community events, and your workplace activity!',
				modalArrowPosition: 'rightTop',
				modalButtonText: 'Next',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '53%', top: '7.5%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '53%', top: '7.5%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			},
			{
				imgSrc: tutorial3,
				imgAlt: 'personal pledging tutorial image',
				modalTitle: 'Personal Pledging',
				modalContent:
					'See your recent pledges and add funds to fulfill more needs.\n\nIf you make a pledge and the target value isn’t hit, your funds will go back into your account to be used to fulfill more needs in the future.',
				modalArrowPosition: 'bottomRight',
				modalButtonText: 'Next',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '15%', top: '24%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '15%', top: '24%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			},
			{
				imgSrc: tutorial4,
				imgAlt: 'your vote streak image tutorial',
				modalTitle: 'Your Vote Streak',
				modalContent: 'Every vote matters! How high will you go?!',
				modalArrowPosition: 'bottomRight',
				modalButtonText: 'Next',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '40%', top: '40%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '40%', top: '40%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			},
			{
				imgSrc: tutorial5,
				imgAlt: 'alt text for testing',
				modalTitle: 'My Impact',
				modalContent:
					'See your impact up close and personal with impact stories from the people and communities you’ve helped!',
				modalArrowPosition: 'leftTop',
				modalButtonText: 'Done',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '13%', top: '28%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '13%', top: '35%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			}
		],
		[]
	);

	const handleSelect = e => {
		setSelectState(e.target.value);
	};
	const getOptions = () => {
		return [0, 1, 2, 3].map(value => {
			return <option value={value}>{value}</option>;
		});
	};
	return (
		<>
			<div>Dev Library</div>
			<div className="py-4">
				{/* <ProfileHeader headerColor="primary" userName="John Doe">
					<Sectional
						className={'my-4'}
						backgroundColor={'#FFFFFF'}
						variant={'primary'}
						icon={'emoji'}
						heading={{ title: 'Heading: ', secondary: 'secondary text' }}
						link={'app.hitroop.com'}
						linkText={'[Learn more]'}>
						<div>Content</div>
					</Sectional>
					<Sectional
						className={'my-4'}
						backgroundColor={'#FFFFFF'}
						variant={'secondary'}
						icon={'emoji'}
						heading={{ title: 'Heading: ', secondary: 'secondary text' }}
						infoText={'info text'}>
						<div>Content</div>
					</Sectional>
					<Sectional
						className={'my-4'}
						backgroundColor={'#FFFFFF'}
						variant={'tertiary'}
						icon={'emoji'}
						heading={{ title: 'Heading' }}
						link={'app.hitroop.com'}
						linkText={'[Learn more]'}>
						<div>Content</div>
					</Sectional>
				</ProfileHeader> */}
				<Sectional
					className={'my-4'}
					backgroundColor={'#FFFFFF'}
					variant={'primary'}
					icon={'emoji'}
					heading={{ title: 'Heading: ', secondary: 'secondary text' }}
					link={'app.hitroop.com'}
					linkText={'[Learn more]'}>
					<div>Content</div>
				</Sectional>
				<Sectional
					className={'my-4'}
					backgroundColor={'#FFFFFF'}
					variant={'secondary'}
					icon={'emoji'}
					heading={{ title: 'Heading: ', secondary: 'secondary text' }}
					infoText={'info text'}>
					<div>Content</div>
				</Sectional>
				<Sectional
					className={'my-4'}
					backgroundColor={'#FFFFFF'}
					variant={'tertiary'}
					icon={'emoji'}
					heading={{ title: 'Heading' }}
					link={'app.hitroop.com'}
					linkText={'[Learn more]'}>
					<div>Content</div>
				</Sectional>
				<Sectional
					className={'my-4'}
					backgroundColor={'#FFFFFF'}
					variant={'primary'}
					icon={'emoji'}
					heading={{ title: 'Heading: ', secondary: 'secondary text' }}
					showDropdown={true}
					select={{
						onChange: handleSelect,
						name: 'test',
						value: selectState,
						children: getOptions()
					}}>
					<div>Content</div>
				</Sectional>
			</div>
			<div>
				<PledgeProgressBar
					completedVariant={'primary'}
					incompleteVariant={'white'}
					currentValue={50}
					max={100.5}
					numberOfPledges={3}
				/>
			</div>
			<div>
				<h2>Regular Text Tab - Primary color</h2>
				<Tab openedTabIndex={2} tabColor="orange">
					<TabItem onClick={() => console.log('here')}>First</TabItem>
					<TabItem>Second</TabItem>
					<TabItem>Third</TabItem>
					<TabItem>Fourth</TabItem>
					<TabContent>First content</TabContent>
					<TabContent>Second content</TabContent>
					<TabContent>Third content</TabContent>
					<TabContent>Fourth content</TabContent>
				</Tab>
				<h2>Tab with Icons - Secondary color</h2>
				<Tab openedTabIndex={2} tabColor="purple-2" iconStyle="stacked">
					<TabItem onClick={() => console.log('here')}>
						<Icon size="md" icon="user" />
						First
					</TabItem>
					<TabContent>First content</TabContent>
					<TabItem onClick={() => console.log('There')}>
						<Icon size="md" icon="gear" />
						Second
					</TabItem>
					<TabContent>Second content</TabContent>
					<TabItem onClick={() => console.log('and here again')}>
						<Icon size="md" icon="help" />
						Third
					</TabItem>
					<TabContent>Third content</TabContent>
				</Tab>
				<h2>Tab with Icons vertical order - dark color</h2>
				<Tab openedTabIndex={2} tabColor="teal" iconStyle="horizontal">
					<TabItem onClick={() => console.log('here')}>
						<Icon size="md" icon="user" />
						First
					</TabItem>
					<TabContent>First content</TabContent>
					<TabItem onClick={() => console.log('There')}>
						<Icon size="md" icon="gear" />
						Second
					</TabItem>
					<TabContent>Second content</TabContent>
					<TabItem onClick={() => console.log('and here again')}>
						<Icon size="md" icon="help" />
						Third
					</TabItem>
					<TabContent>Third content</TabContent>
				</Tab>
			</div>
			<div>
				<h2>Tutorial</h2>
				<Button
					onClick={() => {
						setTutorialSlideNumber(tutorialSlideNumber + 1);
					}}>
					Start Tutorial
				</Button>
				<Tutorial
					slides={tutorialData}
					currentSlide={tutorialSlideNumber}
					nextStep={() => {
						if (tutorialData.length === tutorialSlideNumber + 1) {
							setTutorialSlideNumber(-1);
						} else {
							setTutorialSlideNumber(tutorialSlideNumber + 1);
						}
					}}
					closeTutorial={() => {
						setTutorialSlideNumber(-1);
					}}
				/>
			</div>
		</>
	);
}
