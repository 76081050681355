import React, { useEffect } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';
import { useSelector, useDispatch } from 'react-redux';
import WriterRoute from '../WriterRoute';

import Modal from 'root/baseComponents/Modal';
import Notification from 'root/baseComponents/Notification';
import Notifications from 'root/components/Notifications';
import { setActiveDashboard } from 'root/reducers/activeDashboardItem';

import Header from './subs/Header';
import SdiebarDashboard from './subs/SdiebarDashboard';
import styles from './Wrapper.module.scss';

function Wrapper({ pageName, children }) {
	const dispatch = useDispatch();
	const charitySidebarState = useSelector(state => state.charitySidebarState);

	useEffect(() => {
		if (pageName) {
			dispatch(setActiveDashboard(pageName));
		}
	}, [pageName]);

	return (
		<>
			<WriterRoute>
				<Modal />
				<Notification />
				<Div
					type="flex"
					vAlign="center"
					direction="vertical"
					className={cx(
						'pos-fix pos-fix--rt bgWhite mt1',
						styles.notifContainer
					)}>
					<Notifications />
				</Div>
				<Header />
				<SdiebarDashboard />
				<Div
					className={cx(
						styles.pageContainer,
						charitySidebarState && styles.pageContainerPushToRight
					)}>
					{children}
				</Div>
			</WriterRoute>
		</>
	);
}

export default Wrapper;
