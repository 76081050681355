import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon/Icon';
import { COLORS } from 'root/constants/vars';

import ResponsiveSwipeableSlider from 'root/baseComponents/ResponsiveSwipeableSlider/ResponsiveSwipeableSlider';
import ResponsiveSwipeableSliderItem from 'root/baseComponents/ResponsiveSwipeableSlider/subs/ResponsiveSwipeableSliderItem';

import { getBackendUrl } from 'root/utils/helpers';

import styles from '../Needs.module.scss';

const Impact = ({ fulfilledData }) => {
	const [testimonies, setTestimonies] = useState([]);
	const [imgs, setImgs] = useState([]);
	const [videos, setVideos] = useState([]);
	const [media, setMedia] = useState([]);
	const [curTestimony, setCurTestimony] = useState('');
	const [curCitation, setCurCitation] = useState('');
	const [activatedTestimony, setActivatedTestimony] = useState(0);

	const [moveRight, setMoveRight] = useState(false);
	const [moveLeft, setMoveLeft] = useState(false);
	const [moveToItemWithNum, setMoveToItemWithNum] = useState(0);
	const [mustShowSlider, setMustShowSlider] = useState(true);

	useEffect(() => {
		if (fulfilledData?.length) {
			const localTestimonies = [];
			const localImgs = [];
			const localVideos = [];
			const localMedia = [];

			fulfilledData.forEach(d => {
				if (d.media_type === 'testimony') {
					localTestimonies.push({ text: d.testimony, citation: d.citation });
				}
				if (d.media_type === 'image') {
					localImgs.push({ src: `${getBackendUrl()}${d.image}` });
					localMedia.push({
						type: 'img',
						src: `${getBackendUrl()}${d.image}`
					});
				}
				if (d.media_type === 'video') {
					localVideos.push({ src: `${getBackendUrl()}/${d.video_src}` });
					if (!d?.image) {
						localMedia.push({
							type: 'video',
							video_src: `${getBackendUrl()}/media/${d.video_src}`
						});
					} else {
						localMedia.push({
							type: 'video',
							video_src: `${d.video_src}`,
							img_src: `${getBackendUrl()}${d.image}`
						});
					}
				}
				setTestimonies(localTestimonies);
				setImgs(localImgs);
				setVideos(localVideos);
				setMedia(localMedia);
				setCurTestimony(localTestimonies?.[0]?.['text']);
				setCurCitation(localTestimonies?.[0]?.['citation']);
			});
		}
	}, [fulfilledData]);

	useEffect(() => {
		console.log(media);
	}, [media]);

	return (
		<>
			<Div className="bgWhite">
				<Div type="flex" className="mb3">
					{testimonies?.map((item, idx) => (
						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className={cx(
								'w-px-30 height-px-30 br-rad-px-5 mouse-hand mr2 br-color-primary',
								activatedTestimony === idx
									? 'bgPrimary textWhite'
									: 'textBlack br-all-solid-1'
							)}
							key={idx}
							onClick={() => {
								setCurTestimony(item?.['text']);
								setCurCitation(item?.['citation']);
								setActivatedTestimony(idx);
							}}>
							{idx + 1}
						</Div>
					))}
				</Div>
				<Div className="mb4">
					<Div className="textGray">{curTestimony}</Div>
					<Div className="textGray">Regards,</Div>
					<Div className="mt3 textGray">{curCitation}</Div>
				</Div>
				<Div className="w-per-100 pos-rel">
					{media?.length && (
						<ResponsiveSwipeableSlider
							moveRight={moveRight}
							setMoveRight={setMoveRight}
							moveLeft={moveLeft}
							setMoveLeft={setMoveLeft}
							moveToItemWithNum={moveToItemWithNum}
							setMoveToItemWithNum={setMoveToItemWithNum}
							mustShowSlider={mustShowSlider}
							setMustShowSlider={setMustShowSlider}>
							{media?.map((item, idx) => (
								<Div key={idx} className="w-per-100">
									{console.log(item)}
									<Div className="w-px-200 height-px-200 mr2">
										{item.type === 'img' && <img src={item.src} />}
										{item.type === 'video' && !item.img_src ? (
											<video width="200" height="200" controls>
												<source src={item.video_src} />
											</video>
										) : (
											''
										)}
										{item.type === 'video' && item.img_src ? (
											<a href={item.video_src} target="_blank">
												<img src={item.img_src} />
											</a>
										) : (
											''
										)}
									</Div>
								</Div>
							))}
						</ResponsiveSwipeableSlider>
					)}
					{mustShowSlider && (
						<>
							<Div
								className={cx(
									'pos-abs w-px-30 height-px-30',
									styles.impactImgLeft
								)}>
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									className="w-px-30 height-px-30 bgWhite br-all-solid-1 br-color-primary boxShadowType1 br-rad-per-50 mouse-hand"
									onClick={() => setMoveLeft(true)}>
									<Icon type="angle-left" scale={1.5} color={COLORS.primary} />
								</Div>
							</Div>

							<Div
								className={cx(
									'pos-abs w-px-30 height-px-30',
									styles.impactImgRight
								)}>
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									className="w-px-30 height-px-30 bgWhite br-all-solid-1 br-color-primary boxShadowType1 br-rad-per-50 mouse-hand"
									onClick={() => setMoveRight(true)}>
									<Icon type="angle-right" scale={1.5} color={COLORS.primary} />
								</Div>
							</Div>
						</>
					)}
				</Div>
			</Div>
		</>
	);
};

export default Impact;
