import { get, post, put, del } from './http';
import {
	getFeatureFlag as getFeatureFlagRx,
	getFeatureFlags as getFeatureFlagsRx,
	addFeatureFlag as addFeatureFlagRx,
	deleteFeatureFlag as deleteFeatureFlagRx,
	updateFeatureFlag as updateFeatureFlagRx,
	getFlags as getFlagsRx
} from 'root/reducers/featureFlags';

export const addFeatureFlag = data => dispatch => {
	return post(`/features/`, data).then(resp => {
		dispatch(addFeatureFlagRx(resp[0]));
	});
};

export const getFlags = () => dispatch => {
	return get('/feature_statuses/').then(data => {
		dispatch(getFlagsRx(data));
	});
};
export const getFeatureFlags = () => dispatch => {
	return get('/features/').then(data => {
		dispatch(getFeatureFlagsRx(data));
	});
};

export const getFeatureFlag = id => dispatch => {
	return get(`/feature-flags/${id}/`).then(data => {
		dispatch(getFeatureFlagRx(data));
	});
};

export const updateFeatureFlag = (id, data) => dispatch => {
	return put(`/features/${id}/`, data).then(data => {
		dispatch(updateFeatureFlagRx(data));
	});
};

export const deleteFeatureFlag = id => dispatch => {
	return del(`/features/${id}/`).then(data => {
		dispatch(deleteFeatureFlagRx(data));
	});
};
