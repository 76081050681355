import React from 'react';
import { useHistory } from 'react-router-dom';

import { Heading, Button, Text } from 'troop-design';

import { hideModal } from 'reducers/modals';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';

function Prompt({ businessName, email }) {
	const history = useHistory();
	const dispatch = useDispatch();

	return (
		<Modal reduxKey="addEmployee" size="md">
			<Heading level={2}>Add Confirmation</Heading>
			<Text tag="p" hasWrapper>
				Thanks for signing up! You've been added to the team that will get to
				vote to fulfill local needs. Complete your registration and we will
				notify you when it's time to vote.
			</Text>
			<Button
				variant="dark"
				outline
				onClick={() => {
					dispatch(hideModal('addEmployee'));
					history.push(`/employee-register?email=${email}`);
				}}>
				Sign In
			</Button>
		</Modal>
	);
}

export default Prompt;
