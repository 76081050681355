import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { Card, Button, Heading, Image, Anchor } from 'troop-design';
import { useDispatch } from 'react-redux';

import { showModal } from 'reducers/modals';

import { getEnv } from 'utils/helpers';

import { slackAppActivate, createSlackbusiness } from 'services/slack';
import styles from './SlackRedirect.module.scss';

import slackInstallStep from 'assets/Slack-Install-Step.png';
import troopAppSlack1 from 'assets/troop app-slack-1.png';
import troopAppSlack2 from 'assets/troop app-slack-2.png';
import troopAppSlack3 from 'assets/troop app-slack-3.png';
import troopAppSlack4 from 'assets/troop app-slack-4.png';

import Prompt from './components/Prompt';

const SlackRedirect = () => {
	const setupRef = useRef();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const queries = queryString.parse(search);
	const code = queries?.code || false;

	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const [slackRedirectLink, setSlackRedirectLink] = useState(false);
	const [isCopied, setIsCopied] = useState(false);

	const business = useSelector(state => state.businesses.businesses);
	const business_id = useMemo(() => business?.[0]?.id || false, [business]);

	const addSlackBusiness = async payload => {
		try {
			const res = await createSlackbusiness(payload);
			setSuccess(true);
			dispatch(showModal('thanksInstallingSlack'));
		} catch (e) {
			console.log(e.response);
			setError(true);
			dispatch(showModal('thanksInstallingSlack'));
		}
	};

	const passCodeToActivateApp = async () => {
		try {
			const res = await slackAppActivate({ code });
			const payload = {
				authed_user_id: res.authed_user.id,
				access_token: res.access_token,
				token_type: res.token_type,
				team_name: res.team.name,
				team_id: res.team.id,
				bot_user_id: res.bot_user_id,
				business: business_id
			};
			addSlackBusiness(payload);
		} catch (e) {
			setError(true);
			console.log(e.response);
		}
	};

	useEffect(() => {
		if (code && business_id) {
			passCodeToActivateApp();
		}
	}, [code, business_id]);

	useEffect(() => {
		const curUrl = window.location.href;
		let curClientUrl = getEnv('URL');
		let slackClientId = getEnv('SLACK_CLIENT_ID');
		if (curUrl.includes(getEnv('STAGING_URL'))) {
			curClientUrl = getEnv('STAGING_URL');
			slackClientId = getEnv('SLACK_CLIENT_ID_STAGING');
		}

		const redirectLink = `${getEnv(
			'SLACK_AUTHORIZE_URL'
		)}?client_id=${slackClientId}&scope=${getEnv(
			'SLACK_SCOPES'
		)}&user_scope=${getEnv(
			'SLACK_USER_SCOPES'
		)}&redirect_uri=${curClientUrl}/${getEnv('SLACK_REDIRECT_URL')}`;
		setSlackRedirectLink(redirectLink);
	}, []);

	const copyToClipBoard = () => {
		navigator.clipboard.writeText(slackRedirectLink);
		setIsCopied(true);
	};

	useEffect(() => {
		if (isCopied) {
			setTimeout(() => {
				setIsCopied(false);
			}, 1000);
		}
	}, [isCopied]);

	useEffect(() => {
		if (success) {
			setupRef.current.scrollIntoView();
		}
	}, [success]);

	return (
		<div>
			<Heading level={1}>Install the Slack integration</Heading>
			<Card>
				<p>
					Meet your team where they are and build even more social impact into
					your company culture. With automatic vote notifications, impact photos
					and messages, and easy access commands, the Troop Slack app integrates
					"doing good" into your company's daily routine.
					<br />
					<a
						href="https://hitroop.com/slack/"
						target="_blank"
						className={cx(styles.externalLink)}>
						Learn more here.
					</a>
				</p>
			</Card>
			<Card>
				<Heading level={3}>Get started: Install the app</Heading>
				<p>
					Step 1: Make sure you have your company's Slack workspace selected.
				</p>
				<p>Step 2: Click "Allow."</p>
				<div className={cx(styles.imageContainer)}>
					<Image src={slackInstallStep} className={cx(styles.image)} />
				</div>
				{slackRedirectLink && (
					<div className={cx(styles.btnContainer)}>
						<Anchor
							className={cx('mx-1 bgPrimary', styles.btn, styles.link)}
							to={slackRedirectLink}>
							Install Now
						</Anchor>

						<Button
							variant="dark"
							className={cx('mx-1', styles.btn)}
							onClick={copyToClipBoard}>
							Copy Install Link
						</Button>
						{isCopied && <div className={cx(styles.copied)}>Copied</div>}
					</div>
				)}
				<div className={cx(styles.break)}></div>
				<p>
					If you have any issues with your installation, please give us a shout
					at <a href="mailto:hello@hitroop.com">hello@hitroop.com</a> and we'll
					help you out right away.
				</p>
			</Card>
			<div ref={el => (setupRef.current = el)}>
				<Card>
					<Heading level={3}>Set up your channel</Heading>
					<div>
						<p>
							1){' '}
							<Anchor
								to="https://slack.com/help/articles/201402297-Create-a-channel"
								target="_blank"
								className={cx(styles.externalLink)}>
								Create a new Slack channel{' '}
							</Anchor>{' '}
							(we suggest calling it something like #social-impact or
							#companyname-impact).
						</p>
						<p>2) Mention @Troop in a message to invite us to the channel.</p>
						<p>
							3) Send the{' '}
							<Anchor
								to="https://slack.com/help/articles/201259356-Slash-commands-in-Slack"
								target="_blank"
								className={cx(styles.externalLink)}>
								slash command{' '}
							</Anchor>{' '}
							"/activate-troop" to complete the set-up.
						</p>
					</div>
					<div className={cx(styles.break)}></div>
					<div>
						<p>
							That's it! Your team will now receive monthly vote notifications,
							last call reminders, and impact photos and messages to your Slack
							channel from Troop.
						</p>
					</div>
					<div>
						<p>
							Check out this quick step-by-step tutorial on how to download and
							install the Slack app below!
						</p>
					</div>
					<div className={cx(styles.video)}>
						<iframe
							src="https://www.youtube.com/embed/y5AqjZYQ4hY"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
							className={cx(styles.video)}></iframe>
					</div>
				</Card>
			</div>
			<Card>
				<Heading level={3}>Easy-to-use slash commands</Heading>
				<div>
					<div>
						<p>
							How to use: Type the slash command into the message box, and then
							add your own text.
						</p>
						<div className={cx(styles.imageContainer)}>
							<Image src={troopAppSlack1} className={cx(styles.image_slack)} />
						</div>
					</div>
					<div>
						<p>
							<span className={cx(styles.txt_bold)}>/vote</span> <br />
							Use this command to auto-fill your current vote link. Bump up your
							participation score by giving your team a nudge in the Slack
							channel to get their votes in.
						</p>
						<div className={cx(styles.imageContainer)}>
							<Image src={troopAppSlack2} className={cx(styles.image_slack)} />
						</div>
					</div>
					<div>
						<p>
							<span className={cx(styles.txt_bold)}>/participation</span> <br />
							Want to give your team a little encouragement? This command fills
							in your current vote participation rate as well as the vote
							numbers.
						</p>
						<div className={cx(styles.imageContainer)}>
							<Image src={troopAppSlack3} className={cx(styles.image_slack)} />
						</div>
					</div>
					<div>
						<p>
							<span className={cx(styles.txt_bold)}>/impact</span> <br />
							Troop automagically sends out impact reports and messages every
							month, but you can also pull up the link to your most recent
							impact report with this command.
						</p>
						<div className={cx(styles.imageContainer)}>
							<Image src={troopAppSlack4} className={cx(styles.image_slack)} />
						</div>
					</div>
				</div>
			</Card>
			{success && <Prompt success={true} />}
			{error && <Prompt success={false} />}
		</div>
	);
};

export default SlackRedirect;
