import React, { useMemo } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Chart from 'root/baseComponents/Chart';

import styles from '../Dashboard.module.scss';

const PIE_CHART_DATA = [
	{ NeedType: 'Waiting for Impact', NeedTypePercentage: 25 },
	{ NeedType: 'In Review', NeedTypePercentage: 15 },
	{ NeedType: 'Under Vote', NeedTypePercentage: 30 },
	{ NeedType: 'Active', NeedTypePercentage: 20 },
	{ NeedType: 'Paused', NeedTypePercentage: 10 },
	{ NeedType: 'Fulfilled', NeedTypePercentage: 10 }
];

const DATA_LABEL_FORMATER = (value, ctx) => {
	const curLabel = ctx.chart.data.labels[ctx.dataIndex];
	let sum = 0;
	let dataArr = ctx.chart.data.datasets[0].data;
	dataArr.map(data => {
		sum += data;
	});
	let percentage = ((value * 100) / sum).toFixed(2) + '%';
	if (parseInt(percentage) > 1) {
		return `${parseInt(percentage)}%`;
	} else {
		return '';
	}
};

const PieChart = ({ needTypePercentage }) => {
	const data = useMemo(() => {
		if (needTypePercentage) {
			const labels = [];
			const percentages = [];
			needTypePercentage.forEach(d => {
				labels.push(d['NeedType']);
				percentages.push(d['NeedTypePercentage']);
			});
			const localData = {
				labels: labels,
				datasets: [
					{
						label: '% of population',
						data: percentages,
						borderColor: [
							'#ffa9f1',
							'#666dff',
							'silver',
							'#17b431',
							'#fff34e',
							'#26335d'
						],
						backgroundColor: [
							'#ffa9f1',
							'#666dff',
							'silver',
							'#17b431',
							'#fff34e',
							'#26335d'
						],
						borderWidth: 1
					}
				]
			};
			return localData;
		}
	}, [needTypePercentage]);
	return (
		<>
			<Chart
				type="doughnut" //change to doughnut 
				data={data}
				showLegend={true}
				titleText="Percentage for Each Type of Needs"
				showDataLabel={true}
				dataLabelFormatter={DATA_LABEL_FORMATER}
			/>
		</>
	);
};

export default PieChart;
