import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div, Image } from 'basedesign-iswad';
import { useDispatch, useSelector } from 'react-redux';
import { setCharitySidebarState } from 'root/reducers/charityReducers/charitySidebarState';
import Icon from 'root/baseComponents/Icon';
import { COLORS } from 'root/constants/vars';
import { getInReviewNeeds } from 'root/services/charity';

import Logo from 'root/assets/logo.png';
import styles from '../Wrapper.module.scss';

function Header() {
	const history = useHistory();
	const dispatch = useDispatch();
	const charitySidebarState = useSelector(state => state.charitySidebarState);

	const [isMenuOpen, setIsMenuOpen] = useState(true);
	const [numberOfInReviewNeeds, setNumberOfInReviewNeeds] = useState(0);

	const getInReviewNeedsData = async () => {
		const res = await getInReviewNeeds({ status: 'approved' });
		if (res?.length) {
			setNumberOfInReviewNeeds(res.length);
		}
	};

	useEffect(() => {
		getInReviewNeedsData();
	}, []);

	return (
		<>
			<Div
				type="flex"
				vAlign="center"
				className="pos-fix z-100 pos-fix--lt w-per-100 height-px-60 bgWhite boxShadowType1 p2">
				<Div
					className={cx(
						'mouse-hand',
						styles.hamburger,
						isMenuOpen && styles.close
					)}
					onClick={() => {
						setIsMenuOpen(!isMenuOpen);
						dispatch(setCharitySidebarState(!charitySidebarState));
					}}>
					<span
						className={cx(
							styles.line,
							styles.line1,
							isMenuOpen && styles.line1close
						)}></span>
					<span
						className={cx(
							styles.line,
							styles.line2,
							isMenuOpen && styles.line2close
						)}></span>
					<span
						className={cx(
							styles.line,
							styles.line3,
							isMenuOpen && styles.line3close
						)}></span>
				</Div>

				<Div
					type="flex"
					vAlign="center"
					hAlign="center"
					className="flex--jc--center w-per-100 height-px-60">
					<Div className="w-px-100">
						<Image src={Logo} />
					</Div>
				</Div>
				<Div
					type="flex"
					hAlign="start"
					vAlign="center"
					className="pos-rel mouse-hand"
					onClick={() => history.push('/writer-needs-to-review')}>
					<Div className="flex flex--jc--center flex--ai--center textWhite mr1 br-rad-per-50 height-px-30 w-px-30 pos-rel">
						<Icon type="bell" color={COLORS.primary} scale={1.2} />
						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className={cx(
								'pos-abs textBlack fs-px-10 w-px-20 height-px-20 br-rad-per-50 bgInfo textWhite f-b',
								styles.notifTextContainer
							)}>
							{numberOfInReviewNeeds}
						</Div>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default Header;
