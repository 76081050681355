import React, { useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useSelector, useDispatch } from 'react-redux';

import { Heading, Button, Text, Table, Row, Col, Icon } from 'troop-design';

import { hideModal } from 'reducers/modals';
import { confirmEmployeeList } from 'services/employees';
import Modal from 'components/Modal';
import styles from '../Employees.module.scss';

function ConfirmEmployee({ businessId, employeeList }) {
	dayjs.extend(isSameOrBefore);
	const [activeEmployees, setActiveEmployees] = useState([]);
	const [inactiveEmployees, setInactiveEmployees] = useState([]);
	const [selectedActiveEmployees, setSelectedActiveEmployees] = useState([]);
	const [selectedInactiveEmployees, setSelectedInactiveEmployees] = useState(
		[]
	);
	const dispatch = useDispatch();

	const today = dayjs().format('YYYY-MM-DD');
	const todayNoTime = dayjs(today);

	const confirm = () => {
		dispatch(
			confirmEmployeeList(businessId, {
				active: activeEmployees,
				inactive: inactiveEmployees
			})
		);
		dispatch(hideModal('confirmEmployee'));
	};

	const activateEmployee = e => {
		let active = [];
		selectedInactiveEmployees.forEach(email => {
			const employee = inactiveEmployees.find(
				employee => employee.email === email
			);
			active.push(employee);
		});
		const inactive = inactiveEmployees.filter(employee => {
			return selectedInactiveEmployees.indexOf(String(employee.email)) === -1;
		});
		setActiveEmployees([...activeEmployees, ...active]);
		setInactiveEmployees(inactive);
		setSelectedActiveEmployees([]);
		setSelectedInactiveEmployees([]);
	};

	const inactivateEmployee = e => {
		let inactive = [];
		selectedActiveEmployees.forEach(email => {
			const employee = activeEmployees.find(
				employee => employee.email === email
			);
			inactive.push(employee);
		});
		const active = activeEmployees.filter(employee => {
			return selectedActiveEmployees.indexOf(String(employee.email)) === -1;
		});
		setActiveEmployees(active);
		setInactiveEmployees([...inactiveEmployees, ...inactive]);
		setSelectedActiveEmployees([]);
		setSelectedInactiveEmployees([]);
	};

	const selectActiveEmployee = e => {
		if (e.target.checked == true) {
			const currentSelectedList = [...selectedActiveEmployees];
			currentSelectedList.push(e.target.value);
			setSelectedActiveEmployees(currentSelectedList);
		} else {
			const currentSelectedList = selectedActiveEmployees.filter(email => {
				if (email !== e.target.value) {
					return email;
				}
			});
			setSelectedActiveEmployees(currentSelectedList);
		}
	};

	const selectInactiveEmployee = e => {
		if (e.target.checked == true) {
			const currentSelectedList = [...selectedInactiveEmployees];
			currentSelectedList.push(e.target.value);
			setSelectedInactiveEmployees(currentSelectedList);
		} else {
			const currentSelectedList = selectedInactiveEmployees.filter(email => {
				if (email !== e.target.value) {
					return email;
				}
			});
			setSelectedInactiveEmployees(currentSelectedList);
		}
	};

	const inactiveEmployeeColumns = useMemo(() => {
		return [
			{
				Header: '',
				accessor: 'checkbox',
				filter: 'equals'
			},
			{
				Header: 'Name',
				accessor: 'name',
				filter: 'equals'
			},
			{
				Header: 'Email',
				accessor: 'email',
				filter: 'equals'
			}
		];
	}, []);

	const activeEmployeeColumns = useMemo(() => {
		return [
			{
				Header: '',
				accessor: 'checkbox',
				filter: 'equals'
			},
			{
				Header: 'Name',
				accessor: 'name',
				filter: 'equals'
			},
			{
				Header: 'Email',
				accessor: 'email',
				filter: 'equals'
			}
		];
	}, []);

	const activeEmployeesData = useMemo(() => {
		return activeEmployees.map(employee => {
			let showingCol = {};
			showingCol['checkbox'] = (
				<input
					onChange={selectActiveEmployee}
					type="checkbox"
					name="employee"
					value={employee.email}
					checked={
						selectedActiveEmployees.indexOf(String(employee.email)) !== -1
					}
				/>
			);
			showingCol['name'] = employee.first_name + ' ' + employee.last_name;
			showingCol['email'] = employee.email;
			return showingCol;
		});
	}, [activeEmployees, selectedActiveEmployees]);

	const inactiveEmployeesData = useMemo(() => {
		return inactiveEmployees.map(employee => {
			let showingCol = {};
			showingCol['checkbox'] = (
				<input
					onChange={selectInactiveEmployee}
					type="checkbox"
					name="employee"
					value={employee.email}
					checked={
						selectedInactiveEmployees.indexOf(String(employee.email)) !== -1
					}
				/>
			);
			showingCol['name'] = employee.first_name + ' ' + employee.last_name;
			showingCol['email'] = employee.email;
			return showingCol;
		});
	}, [inactiveEmployees, selectedInactiveEmployees]);

	useEffect(() => {
		if (employeeList) {
			const active = employeeList.filter(employee => {
				if (employee.status === 'ACTIVE') {
					return employee;
				}
			});
			const inactive = employeeList.filter(employee => {
				if (employee.status !== 'ACTIVE') {
					return employee;
				}
			});
			setActiveEmployees(active);
			setInactiveEmployees(inactive);
		}
	}, [employeeList]);

	useEffect(() => {
		return () => {
			dispatch(hideModal('confirmEmployee'));
		};
	}, []);

	return (
		<Modal reduxKey={'confirmEmployee'} size="xl">
			<Row>
				<Col xs={12} md={5}>
					<Heading level={2}>Active Employees</Heading>
					<Table
						data={activeEmployeesData}
						columns={activeEmployeeColumns}
						paginate
						enableFilters
						striped={false}
						hover
						checked
					/>
				</Col>
				<Col className={styles.activeInactiveButtonContainer} xs={12} md={2}>
					<Button
						onClick={activateEmployee}
						className="mx-1"
						variant={
							selectedInactiveEmployees.length === 0 ? 'disabled' : 'dark'
						}
						disabled={selectedInactiveEmployees.length === 0}>
						<Icon
							icon="chevron-left"
							size="md"
							className="me-2"
							color="currentColor"
						/>
					</Button>
					<Button
						onClick={inactivateEmployee}
						className="m-1"
						variant={selectedActiveEmployees.length === 0 ? 'disabled' : 'dark'}
						disabled={selectedActiveEmployees.length === 0}>
						<Icon
							icon="chevron-right"
							size="md"
							className="me-2"
							color="currentColor"
						/>
					</Button>
				</Col>
				<Col className={styles.inactiveEmployeesTable} xs={12} md={5}>
					<Heading level={2}>Employees that will be inactive</Heading>
					<Table
						data={inactiveEmployeesData}
						columns={inactiveEmployeeColumns}
						paginate
						enableFilters
						striped={false}
						hover
					/>
				</Col>
			</Row>
			<Button variant="primary" className="mt-2 mx-1" onClick={confirm}>
				Confirm
			</Button>
			<Button
				variant="danger"
				className="mt-2 mx-2"
				onClick={() => dispatch(hideModal('confirmEmployee'))}>
				Cancel
			</Button>
		</Modal>
	);
}

export default ConfirmEmployee;
