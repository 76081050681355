import React from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';

import Wrapper from '../components/Wrapper/Wrapper';

import styles from './WriterHome.module.scss';

const WriterHome = () => {
	const history = useHistory();
	return (
		<>
			<Wrapper pageName="Dashboard">
				<Div type="flex" direction="vertical" className="p2">
					<Div className="mb2">
						<Button
							className="w-px-250"
							onClick={() => history.push('/writer-add-charity')}>
							Create New Charity
						</Button>
					</Div>
					<Div>
						<Button
							className="w-px-250"
							onClick={() => history.push('/writer-add-need')}>
							Create New Need
						</Button>
					</Div>
				</Div>
			</Wrapper>
		</>
	);
};

export default WriterHome;
