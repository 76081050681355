import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import styles from '../Header.module.scss';

const Hamburger = ({ isMenuOpen, setIsMenuOpen }) => {
	return (
		<>
			<Div
				className={cx(
					'mouse-hand',
					styles.hamburger,
					isMenuOpen && styles.close
				)}
				onClick={() => {
					setIsMenuOpen(!isMenuOpen);
				}}>
				<span
					className={cx(
						styles.line,
						styles.line1,
						isMenuOpen && styles.line1close
					)}></span>
				<span
					className={cx(
						styles.line,
						styles.line2,
						isMenuOpen && styles.line2close
					)}></span>
				<span
					className={cx(
						styles.line,
						styles.line3,
						isMenuOpen && styles.line3close
					)}></span>
			</Div>
		</>
	);
};

export default Hamburger;
