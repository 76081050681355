import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div, Image, Row, Column } from 'basedesign-iswad';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Select from 'root/baseComponents/Select/Select';
import Form from 'root/baseComponents/Form/Form';
import Icon from 'root/baseComponents/Icon';
import StatsCard from 'root/baseComponents/StatsCard';
import LimitedSwipableSlider from 'root/baseComponents/LimitedSwipableSlider';
import LimitedSwipableSliderItem from 'root/baseComponents/LimitedSwipableSlider/subs/LimitedSwipableSliderItem';
import Table from 'root/baseComponents/Table';

import { LAST_TWELVE_MONTHS_STRING } from 'root/constants/locale';
import { format } from 'root/utils/currency';
import useHistoricalReports from '../../shared/ImpactReports/hooks/useHistoricalReports';
import useHistoricalReportsData from '../../shared/ImpactReports/hooks/useHistoricalReportData';

import styles from './ImpactReport.module.scss';
import PageContainer from '../components/PageContainer/PageContainer';

const tableData = [
	{
		organisation: 'Matthew House',
		need: 'Laptop for a newcomer teen',
		month: 'January 23',
		contribution: 750
	},
	{
		organisation: 'Matthew House',
		need: 'Laptop for a newcomer teen',
		month: 'January 23',
		contribution: 750
	},
	{
		organisation: 'Matthew House',
		need: 'Laptop for a newcomer teen',
		month: 'January 23',
		contribution: 750
	},
	{
		organisation: 'Matthew House',
		need: 'Laptop for a newcomer teen',
		month: 'January 23',
		contribution: 750
	}
];
const visuals = [
	{
		id: 1,
		image_src: 'https://picsum.photos/seed/picsum/200/300',
		video_src: ''
	},
	{
		id: 2,
		image_src: 'https://picsum.photos/seed/picsum/200/300',
		video_src: 'https://picsum.photos/200/300?grayscale'
	},
	{
		id: 3,
		image_src: 'https://picsum.photos/seed/picsum/200/300',
		video_src: ''
	},
	{
		id: 3,
		image_src: 'https://picsum.photos/seed/picsum/200/300',
		video_src: ''
	},
	{
		id: 3,
		image_src: 'https://picsum.photos/seed/picsum/200/300',
		video_src: ''
	}
];

const ImpactReport = () => {
	// const params = useParams();
	let { businessId } = useParams();
	// const location = useLocation();
	// const urlSearchParams = new URLSearchParams(location.search);
	// let businessId = urlSearchParams.get('business_id');

	const history = useHistory();
	const reports = useSelector(state => state.impactReports?.reports);

	const [moveLeft, setMoveLeft] = useState(false);
	const [moveRight, setMoveRight] = useState(false);
	const [moveToItemWithNum, setMoveToItemWithNum] = useState(1);

	const year = dayjs().year();
	const to = year;
	const from = dayjs().subtract(12, 'month').format('YYYY');
	const [historicalPeriod, setHistoricalPeriod] = useState(
		LAST_TWELVE_MONTHS_STRING
	);
	const [fromDate, setFromDate] = useState(`${from}-01`);
	const [toDate, setToDate] = useState(`${to}-12`);
	const historicalReports = useHistoricalReports(fromDate, toDate);
	const formattedHistoricalReportData = useHistoricalReportsData(
		historicalReports,
		historicalPeriod
	);

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const handleHistoricalData = useCallback(
		e => {
			setHistoricalPeriod(e.target.value);
			if (e.target.value === LAST_TWELVE_MONTHS_STRING) {
				const today = dayjs();
				setToDate(today.format('YYYY-MM'));
				setFromDate(dayjs().subtract(12, 'month').format('YYYY-MM'));
			} else {
				setFromDate(`${e.target.value}-01`);
				setToDate(`${e.target.value}-12`);
			}
		},
		[setHistoricalPeriod, setFromDate, setToDate]
	);

	const activationDate = useMemo(
		() => dayjs(),
		[]
		// () =>
		// business?.activation_date || dayjs(),
		// [business]
	);

	const headLines = [
		{
			value: 'organisation',
			display: 'ORGANISATION',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'need',
			display: 'NEED',
			width: 500,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'month',
			display: 'MONTH',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'contribution',
			display: 'CONTRIBUTIONS',
			width: 200,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		tableData?.map(item => {
			tableData.push({
				organisation: item.organisation,
				need: {
					value: item.need,
					display: (
						<Div className={cx('mouse-hand', styles.tableLink)}>
							{item.need}
						</Div>
					)
				},
				month: item.month,
				contribution: item.contribution
			});
		});

		return tableData;
	}, [reports]);

	return (
		<>
			<Div className={cx('p2', styles.bodyPageContainer)}>
				<Div
					type="flex"
					hAlign="start"
					vAlign="center"
					className="f-b fs-px-24 min-w-px-150 mt2 mb4">
					{/* <Div
							type="flex"
							vAlign="center"
							hAlign="center"
							className="w-px-100 height-px-60 bgPrimary mr2">
							{' '}
							LOGO{' '}
						</Div> */}
					<Div className="br-bottom-solid-2 br-color-primary">
						{' '}
						Impact Report
					</Div>
				</Div>
				<Row>
					<Column xs={12} sm={12} md={9} lg={9}>
						<Div type="flex" direction="vertical">
							<Div className="mb1">Impact Story </Div>
							<Div className="card br-all-hidden-1 br-rad-px-5">
								<Div>
									<Row>
										<Column xs={12} sm={12} md={12} lg={6}>
											<Div
												type="flex"
												hAlign="start"
												vAlign="center"
												direction="vertical"
												className="height-px-400 mr2">
												<Div className="pl4 pr4 pb2 pt3 fs-px-12">
													<Div>Need: Laptop for a Newcomer Teen</Div>
													<Div>Neighbourhood: Trinity-Bellwoods</Div>
													<Div>Date: Jan 2023</Div>
												</Div>
												<Div className="pl4 pr4 pb2 mb2 fs-px-14">
													<Div>Dear ... team,</Div>
													<br />
													Thank you so much for your support and sharing the
													need for boots for the women in our shelter. Due to
													the donation, all our women in the shelter will be
													warm this winter. Thank you so much!
													<br />
													<br />
													<br />
													<Div> Your's Truly.</Div>
													<br />
													<Div>Leslie Cunningham, Program Manager</Div>
												</Div>
											</Div>
										</Column>
										<Column
											xs={12}
											sm={12}
											md={12}
											lg={6}
											className="pr2 of-hidden">
											<Div
												type="flex"
												hAlign="center"
												vAlign="center"
												className="pos-rel of-hidden mr2 pr2">
												<Div
													type="flex"
													hAlign="center"
													vAlign="center"
													onClick={setMoveRight}
													className={cx('mouse-hand', styles.rightAngle)}>
													<Icon
														type="arrow"
														color="red"
														className="z-10000"
														scale={2}
													/>
												</Div>

												<Div
													type="flex"
													hAlign="center"
													vAlign="center"
													onClick={setMoveLeft}
													className={cx('mouse-hand', styles.leftAngle)}>
													<Icon
														type="arrow"
														color="red"
														className="z-10000"
														scale={2}
													/>
												</Div>
												<LimitedSwipableSlider
													moveLeft={moveLeft}
													setMoveLeft={setMoveLeft}
													moveRight={moveRight}
													setMoveRight={setMoveRight}
													moveToItemWithNum={moveToItemWithNum}
													setMoveToItemWithNum={setMoveToItemWithNum}
													sliderContainerWidthMultiplier={visuals.length / 1.5}
													minXDifferenceToMove={10}
													notScrollableOnSwipableElement={false}
													transitionDuration={0.3}>
													{visuals.map((item, idx) => (
														<LimitedSwipableSliderItem
															key={idx}
															className={cx('w-per-100 px1')}>
															{item?.video_src &&
															item?.video_src !== 'undefined' ? (
																<a href={item.video_src} target="_blank">
																	<Image
																		src={item.video_src}
																		className={cx(styles.imageImpact)}
																	/>
																</a>
															) : (
																<Image
																	src={item.image_src}
																	className={cx(styles.imageImpact)}
																	// onClick={() => {
																	// 	setShowPictureModal(true);
																	// 	setSrc(`${getBackendUrl()}${item.image}`);
																	// }}
																/>
															)}
														</LimitedSwipableSliderItem>
													))}
												</LimitedSwipableSlider>
											</Div>
										</Column>
									</Row>
								</Div>
							</Div>
							<Div className="mr3">
								<Div className="employeeHeading mb3 mt2">
									Organizations Supported
								</Div>
								<Table
									headLines={headLines}
									data={data}
									colWidth={400}
									tableWidth={'950px'}
									isSortable={true}
									isSelectable={false}
									selectedData={selectedData}
									setSelectedData={setSelectedData}
									sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
									rowsPerPage={5}
									currentPage={currentPage}
									setCurrentPage={setCurrentPage}
									showDefaultPagination={true}
									numberOfShownPages={3}
								/>
							</Div>
						</Div>
					</Column>
					<Column xs={12} sm={12} md={3} lg={3}>
						<Div
							type="flex"
							hAlign="center"
							vAlign="start"
							direction="vertical"
							className={cx('w-per-100', styles.statCardsContainer)}>
							<Div
								type="flex"
								direction="vertical"
								hAlign="start"
								className="w-per-100">
								<Div className="mb1 w-per-80"> Business Stats </Div>
								<Div type="flex" className="w-per-90 ml1">
									<Form>
										<Select options={['Last 12 month', '2023']} />

										{/* </Select> */}
									</Form>
								</Div>
							</Div>

							<StatsCard
								title="Need Contribution"
								// amount={format(
								// 	Math.round(
								// 		formattedHistoricalReportData?.needContribution || 0
								// 	),
								// 	'en-CA',
								// 	'CAD',
								// 	0
								// )}
								amount={800}
								unit="$"
								onImpactReportPage={true}
							/>
							<StatsCard
								title="Organizations Supported"
								// amount={
								// 	formattedHistoricalReportData?.organizationSupported
								// 		?.length || 0
								// }
								amount="6"
								onImpactReportPage={true}
							/>
							<StatsCard
								title="Avg. Employee Participation"
								// amount={Math.round(formattedHistoricalReportData?.avgVote || 0)}
								amount="20"
								unit="$"
								onImpactReportPage={true}
							/>
							<StatsCard
								title="Customers Count"
								amount={420}
								onImpactReportPage={true}
							/>
							<StatsCard
								title="Customers engagement"
								amount={300}
								unit="$"
								onImpactReportPage={true}
							/>
						</Div>
					</Column>
				</Row>
			</Div>
		</>
	);
};

export default ImpactReport;
