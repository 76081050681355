import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { Icon } from 'troop-design';
import { useHistory } from 'react-router-dom';

import Form from 'root/baseComponents/Form';
import Button from 'root/baseComponents/Button';
import FormInput from 'root/baseComponents/FormInput';

import SliderDot from 'root/components/SliderDot';

import { registerCharityAdmin } from 'root/services/charity';

import Logo from '../../assets/troop_white-logo.png';

import styles from './CharityRegistration.module.scss';

import {
	organizationNameValidators,
	firstNameValidators,
	lastNameValidators,
	emailValidators,
	passwordValidators
} from './utils';

const ITEMS = [
	{
		iconType: 'pledge',
		text: 'Are you a charity or not-for-profit that would like to register a need to be featured on Troop?'
	},
	{
		iconType: 'pledge',
		text: 'Register Now.'
	},
	{
		iconType: 'pledge',
		text: "Connect your organzation's needs to your community through Troop!"
	}
];

function CharityRegistration({
	setFormSubmitted,
	email,
	setEmail,
	password,
	setPassword,
	organizationName,
	setOrganizationName,
	setCharityId,
	setCharityUserId
}) {
	const history = useHistory();

	const [organizationNameErrorMessage, setOrganizationNameErrorMessage] =
		useState('');

	const [firstName, setFirstName] = useState('');
	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');

	const [lastName, setLastName] = useState('');
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');

	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

	const toBeValidatedFields = [
		{
			input_name: 'organization_name',
			validators: organizationNameValidators,
			errorMessageHandler: setOrganizationNameErrorMessage
		},

		{
			input_name: 'first_name',
			validators: firstNameValidators,
			errorMessageHandler: setFirstNameErrorMessage
		},

		{
			input_name: 'last_name',
			validators: lastNameValidators,
			errorMessageHandler: setLastNameErrorMessage
		},

		{
			input_name: 'email',
			validators: emailValidators,
			errorMessageHandler: setEmailErrorMessage
		},

		{
			input_name: 'password',
			validators: passwordValidators,
			errorMessageHandler: setPasswordErrorMessage
		}
	];

	const handleSubmit = async () => {
		const data = {
			first_name: firstName,
			last_name: lastName,
			email,
			password,
			charity_name: organizationName
		};
		const res = await registerCharityAdmin(data);
		setCharityId(res.charity_data.id);
		setCharityUserId(res.charity_user_data.id);
		setFormSubmitted(true);
	};

	return (
		<>
			<div className={cx(styles.container, styles.containerFirstPage, 'bgInfo')}>
				<div className={cx(styles.containerLeft)}>
					<div className={cx(styles.logoContainer)}>
						<img className={cx(styles.logoImg)} src={Logo} />
					</div>
					<div className={cx(styles.items)}>
						{ITEMS.map((item, idx) => (
							<div className={cx(styles.item)} key={idx}>
								<div className={cx(styles.itemIcon)}>
									<Icon icon={item.iconType} width={45} color="white" />
								</div>
								<div className={cx(styles.itemText, 'textWhite')}>{item.text}</div>
							</div>
						))}
					</div>
				</div>
				<div className={cx(styles.containerRight)}>
					<div className={cx(styles.containerRightContent)}>
						{/* <div className={cx(styles.sliderContainer)}>
							<SliderDot isActive={true} />
							<SliderDot />
						</div> */}
						<h1 className={cx('text-center my1')}>
							Register your organization with Troop
						</h1>
						<p className={cx('my1 text-center fs-px-14')}>
							Before you start, we should mention that we only accept need
							requests from registered charities, incorporated non-profits, and
							schools.
						</p>
						<Form
							toBeValidatedFields={toBeValidatedFields}
							className={cx(styles.form)}
							onSubmit={handleSubmit}>
							<FormInput
								labelText="Organization Name"
								type="text"
								name="organization_name"
								isRequired
								value={organizationName}
								onChange={e => {
									setOrganizationName(e.target.value);
									setOrganizationNameErrorMessage('');
								}}
								errorMessage={organizationNameErrorMessage}
							/>
							<div className={cx(styles.keepTwoInput)}>
								<FormInput
									labelText="First Name"
									type="text"
									name="first_name"
									isRequired
									value={firstName}
									onChange={e => {
										setFirstName(e.target.value);
										setFirstNameErrorMessage('');
									}}
									errorMessage={firstNameErrorMessage}
								/>
								<FormInput
									labelText="Last Name"
									type="text"
									name="last_name"
									isRequired
									value={lastName}
									onChange={e => {
										setLastName(e.target.value);
										setLastNameErrorMessage('');
									}}
									errorMessage={lastNameErrorMessage}
								/>
							</div>
							<FormInput
								labelText="Contact Email"
								type="text"
								name="email"
								isRequired
								value={email}
								onChange={e => {
									setEmail(e.target.value);
									setEmailErrorMessage('');
								}}
								errorMessage={emailErrorMessage}
							/>
							<FormInput
								labelText="Password"
								type="password"
								name="password"
								isRequired
								value={password}
								onChange={e => {
									setPassword(e.target.value);
									setPasswordErrorMessage('');
								}}
								errorMessage={passwordErrorMessage}
							/>
							<p className={cx('fs-px-12 my3 text-center')}>
								Click "Sign Up" to agree to Troop's Terms of Service and
								acknowledge that Troop's Privacy Policy applies to you.
							</p>
							<div
								className={cx('w-per-100 max-w-px-200', styles.btnContainer)}>
								<Button fullWidth={true}>Submit</Button>
							</div>
							<p className="fs-px-12 mt3 text-center">
								Already Registered?{' '}
								<span
									className="f-b textPrimary mouse-hand"
									onClick={() => history.push('/login')}>
									LOGIN
								</span>
							</p>
						</Form>
					</div>
				</div>
			</div>
		</>
	);
}

export default CharityRegistration;
