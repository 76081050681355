import dayjs from 'dayjs';

// Return the nearest month based on today's date
export const getNearestMonth = date => {
	const today = date ? dayjs(date) : dayjs();
	const thisMonth = today.startOf('month');
	const nextMonth = today.add(1, 'month').startOf('month');
	if (Math.abs(today.diff(thisMonth)) < Math.abs(today.diff(nextMonth))) {
		return thisMonth;
	}
	return nextMonth;
};
