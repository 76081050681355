import React from 'react';
import cx from 'classnames';
import { Div, Label } from 'basedesign-iswad';

import Select from 'root/baseComponents/Select';

import styles from './FormSelect.module.scss';

function FormSelect({
	options,
	fullWidth = false,
	labelText,
	className,
	inputSearchClassName,
	val,
	setVal,
	isRequired,
	initialSelected = '',
	errorMessage,
	errorHandler,
	itemsCenteralized = true,
	isInNeedsPage = false,
	...props
}) {
	return (
		<>
			<Div className={cx('pos-rel', styles.container, className)}>
				{labelText && (
					<Div className={cx(styles.labelContainer)}>
						<Label className={cx(isRequired && 'required', styles.label)}>
							{labelText}
						</Label>
					</Div>
				)}
				<Div className={cx(!isInNeedsPage && styles.inputContainer)}>
					<Select
						fullWidth={fullWidth}
						className={cx(
							isInNeedsPage ? cx(styles.select, "br-all-solid-1") : styles.select
						)}
						hasBorder={!isInNeedsPage}
						selectValue={val}
						setSelectValue={setVal}
						options={options}
						optinsContainerClassName={cx(
							'flex flex--ai--center flex--dir--col',
							styles.optionContainer
						)}
						optionClassName={cx(
							'mouse-hand w-per-100 flex p1',
							styles.option,
							itemsCenteralized ? 'flex--jc--center' : 'flex--jc--start'
						)}
						defaultViewClassName={cx(styles.viewClassName)}
						inputSearchClassName={inputSearchClassName}
						initialSelected={initialSelected}
						errorHandler={errorHandler}
						{...props}
					/>
				</Div>
				{errorMessage && (
					<Div
						className={cx(
							'iswad_input_errorMessage',
							errorMessage && 'iswad_input_errorMessage_active'
						)}>
						{errorMessage}
					</Div>
				)}
			</Div>
		</>
	);
}

export default FormSelect;
