import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Div, Image } from 'basedesign-iswad';

import { getFeedback } from 'root/services/charity';
import { getBackendUrl } from 'root/utils/helpers';

import styles from './ViewNeedFeedback.module.scss';

function ViewNeedFeedback() {
	const { id } = useParams();

	const [testimony, setTestimony] = useState();
	const [images, setImages] = useState([]);
	const [videos, setVideos] = useState([]);

	const getFeedbackData = async () => {
		const res = await getFeedback(id);
		const localTestimony = res.find(item => item.media_type === 'testimony');
		const localImages = res.filter(item => item.media_type === 'image');
		const localVideos = res.filter(item => item.media_type === 'video');
		if (localTestimony) {
			setTestimony(localTestimony.text);
		}
		if (localImages) {
			setImages(localImages);
		}
		if (localVideos) {
			setVideos(localVideos);
		}
	};

	useEffect(() => {
		if (id) {
			getFeedbackData();
		}
	}, [id]);
	return (
		<>
			<Div className="pt2 mb2 fs-px-20">Feedback</Div>
			<Div className="card br-rad-px-5 w-per-90 p2">
				<Div>Impact Story:</Div>
				<Div className="ml2 br-all-solid-1 br-rad-px-5 br-color-silver p1 mt1 mb2 ">
					{testimony}
				</Div>
				<Div className="mb1">Impact Images: </Div>

				<Div type="flex" className="flex-wrap pl2">
					{images.map((item, idx) => (
						<Div
							type="flex"
							className="w-px-200 mr2 mb1 p2 br-all-solid-1 br-color-silver br-rad-px-5 flex--jc--center flex--ai--center"
							key={idx}>
							<Image src={`${getBackendUrl()}${item.image}`} />
						</Div>
					))}
				</Div>
				{videos.length > 0 && <Div className="mb1 mt1">Impact Videos:</Div>}
				<Div type="flex" className="flex-wrap pl2">
					{videos.map((item, idx) => (
						<Div
							className="w-px-200 mr2 mb1 p2 br-all-solid-1 br-color-silver br-rad-px-5 flex--jc--center flex--ai--center"
							key={idx}>
							<video width="160" controls>
								<source src={`${getBackendUrl()}${item.video}`} />
							</video>
						</Div>
					))}
				</Div>
			</Div>
		</>
	);
}

export default ViewNeedFeedback;
