import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import FormDatePicker from 'root/baseComponents/FormDatePicker';
import SingleCheckBox from 'root/baseComponents/SingleCheckBox';
import FormSelect from 'root/baseComponents/FormSelect';

import { getCommentReport } from 'root/services/report';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import styles from '../PurchasesAndSavings.module.scss';

const Comments = () => {
	const dispatch = useDispatch();
	const businesses = useSelector(state => state.businesses?.businesses);

	const headLines = [
		{
			value: 'email',
			display: 'Email',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'rate',
			display: 'Rate',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'comment',
			display: 'Comment',
			width: 400,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'future_feature',
			display: 'Future Feature',
			width: 350,
			hasSearch: false,
			isSortable: true
		}
	];

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [reports, setReports] = useState([]);
	const [chosenDate, setChosenDate] = useState(new Date());
	const [chosenDateErrorMessage, setChosenDateErrorMessage] = useState('');
	const [numberOfRates, setNumberOfRates] = useState(0);
	const [sumRates, setSumRates] = useState(0);
	const [showMonthlyReport, setShowMonthlyReport] = useState(false);
	const [showMonthlyReportErrorMessage, setShowMonthlyReportErrorMessage] =
		useState('');
	const [businessChoices, setBusinessChoices] = useState([]);
	const [curBusiness, setCurBusiness] = useState(0);
	const [curBusinessErrorMessage, setCurBusinessErrorMessage] = useState('');

	useEffect(() => {
		if (businesses?.length) {
			const localBusinessChoices = [];
			localBusinessChoices.push({ value: 0, shownText: 'All' });
			businesses?.forEach(business => {
				localBusinessChoices.push({
					value: business.id,
					shownText: business.name
				});
			});
			setBusinessChoices(localBusinessChoices);
		}
	}, businesses);

	const getCommentReportData = async () => {
		dispatch(setLoading());
		const res = await getCommentReport(
			dayjs(chosenDate).format('YYYY-MM'),
			curBusiness
		);
		dispatch(setLoadingComplete());
		const localReports = [];
		let localResponse = [];
		let localSumRates = 0;
		let localNumberOfRates = 0;
		if (showMonthlyReport) {
			localResponse = res?.monthly_report;
		} else {
			localResponse = res.report;
		}
		localResponse?.forEach(item => {
			localNumberOfRates += 1;
			localSumRates += item.rate;
			localReports.push({
				email: {
					value: item.employee_email,
					display: <Div>{item.employee_email}</Div>
				},
				rate: {
					value: item.rate,
					display: <Div>{item.rate}</Div>
				},
				comment: {
					value: item.text,
					display: <Div>{item.text ? item.text : '-'}</Div>
				},
				future_feature: {
					value: item.selected_future_feature,
					display: (
						<Div>
							{item.selected_future_feature &&
							item.selected_future_feature !== 'False'
								? item.selected_future_feature
								: '-'}
						</Div>
					)
				}
			});
		});
		setNumberOfRates(localNumberOfRates);
		setSumRates(localSumRates);
		setReports(localReports);
	};

	useEffect(() => {
		if (chosenDate) {
			getCommentReportData();
		}
	}, [chosenDate, showMonthlyReport, curBusiness]);

	const data = useMemo(() => {
		if (reports) {
			console.log(reports);
			const tableData = [];
			reports.forEach(item => {
				tableData.push({
					email: item.email,
					rate: item.rate,
					comment: item.comment,
					future_feature: item.future_feature
				});
			});

			return tableData;
		}
	}, [reports]);

	return (
		<>
			<Div
				type="flex"
				direction="vertical"
				hAlign="start"
				className="w-per-80 max-w-px-500 pr2 pl2">
				<Div>
					<FormDatePicker
						chosenDate={chosenDate}
						setChosenDate={setChosenDate}
						errorMessage={chosenDateErrorMessage}
						errorHandler={setChosenDateErrorMessage}
						labelText="Date:"
						dateFormat="MM-yyyy"
					/>
				</Div>
				<Div className="">
					<FormSelect
						className={cx('mb4')}
						labelText="Business"
						options={businessChoices}
						fullWidth
						openOptionsDownWard={true}
						val={curBusiness}
						setVal={setCurBusiness}
						errorMessage={curBusinessErrorMessage}
						errorHandler={setCurBusinessErrorMessage}
						initialSelected="All"
					/>
				</Div>

				<Div className="mb2">
					<SingleCheckBox
						labelText="Show monthly Report?"
						selected={showMonthlyReport}
						setSelected={setShowMonthlyReport}
						errorMessage={showMonthlyReportErrorMessage}
						errorHandler={setShowMonthlyReportErrorMessage}
					/>
				</Div>
			</Div>

			<Div className="w-per-80 ml2 mr2 mb2 p2 bgWhite card">
				<Div type="flex" className="my1">
					<Div className="w-px-350">Number of Ratings</Div>
					<Div>{numberOfRates}</Div>
				</Div>

				<Div type="flex" className="my1">
					<Div className="w-px-350">Average of Ratings</Div>
					<Div>
						{numberOfRates > 0
							? parseFloat(sumRates / numberOfRates)?.toFixed(2)
							: '-'}
					</Div>
				</Div>
			</Div>

			<Div className="w-per-80 pr2 pl2 flex flex--jc--start">
				<Table
					headLines={headLines}
					data={data}
					// colWidth={400}
					// tableWidth={'2000px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={20}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
};

export default Comments;
