import React, { useState } from 'react';
import cx from 'classnames';
import { Div, Input, Label } from 'basedesign-iswad';
import { Icon } from 'troop-design';

import styles from './FormInput.module.scss';

function FormInput({
	labelText,
	isRequired,
	className,
	hasIcon = false,
	iconType = '',
	iconColor = 'black',
	type,
	hasMarginBottom = true,
	hasBorder,
	...props
}) {
	const [curType, setCurType] = useState(type);

	return (
		<>
			<Div
				className={cx(hasMarginBottom && 'mb4', styles.container, className)}>
				<Div className={cx(styles.labelContainer)}>
					<Label className={cx(isRequired && 'required', styles.label)}>
						{labelText}
					</Label>
				</Div>
				<Div className={cx(styles.inputContainer)}>
					<Input
						className={cx(
							'br-all-solid-1',
							styles.input,
							hasBorder && styles.inputHasBorder,
							type === 'password' && styles.inputWithEye
						)}
						type={curType}
						{...props}
					/>
					{hasIcon && (
						<>
							<div className={cx(styles.eye)}>
								<Icon icon={iconType} color={iconColor} width={14} />
							</div>
						</>
					)}
					{type === 'password' && (
						<>
							<div
								className={cx('mouse-hand', styles.eye)}
								onClick={() => {
									if (curType === 'password') {
										setCurType('text');
									} else {
										setCurType('password');
									}
								}}>
								<Icon icon="eye" color="black" width={14} />
							</div>
							{curType !== 'password' && (
								<div className={cx(styles.lineThrough)}></div>
							)}
						</>
					)}
				</Div>
			</Div>
		</>
	);
}

export default FormInput;
