import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	users: [],
	usersLoading: false,
	loggedIn: false,
	loggedInUser: {},
	currentUserRoles: [],
	notYetChangedToEmployeeOnLanding: true,
	userFullyRegistered: true,
	businessUserProfile: {},
	// used for header toggle purposes
	wasBusinessAdmin: false,
	justVoted: false
};

export const userSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			state.usersLoading = action.payload;
		},
		getUsers: (state, action) => {
			state.users = action.payload;
		},
		addEmployees: (state, action) => {
			state.users.push(action.payload);
		},
		updateEmployee: (state, action) => {
			const index = state.users.findIndex(
				user => user.id === action.payload.id
			);
			state.users[index] = action.payload.data;
		},
		deleteEmployee: (state, action) => {
			const index = state.users.findIndex(
				user => user.id === action.payload.id
			);
			state.users.splice(index, 1);
		},

		// Login
		loginUser: (state, action) => {
			state.loggedIn = true;
			state.loggedInUser = action.payload;
			state.currentUserRoles = action.payload.groups;
		},

		// Set the user as fully registered
		setIsFullyRegistered: (state, action) => {
			state.userFullyRegistered = action.payload;
		},

		toggleUserGroups: (state, action) => {
			state.currentUserRoles = action.payload.groups;
			state.loggedInUser = {
				...state.loggedInUser,
				groups: action.payload.groups
			};
		},

		changeToEmployeeOnLanding: (state, action) => {
			state.notYetChangedToEmployeeOnLanding = false;
		},

		setWasBusinessAdmin: (state, action) => {
			state.wasBusinessAdmin = action.payload;
		},

		//logout
		logoutUser: (state, action) => {
			state.loggedIn = false;
			state.loggedInUser = {};
			state.currentUserRoles = [];
			state.wasBusinessAdmin = false;
		},

		setBusinessUserProfile: (state, action) => {
			state.businessUserProfile = action.payload;
		},
		voteNow: (state, action) => {
			state.justVoted = true;
		}
	}
});

export default userSlice.reducer;
export const {
	addEmployees,
	updateEmployee,
	deleteEmployee,
	loginUser,
	logoutUser,
	setIsFullyRegistered,
	setBusinessUserProfile,
	toggleUserGroups,
	setWasBusinessAdmin,
	voteNow,
	changeToEmployeeOnLanding
} = userSlice.actions;
