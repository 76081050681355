import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Div } from 'basedesign-iswad';

import TopBar from './subs/TopBar';
import WishTypeCard from './subs/WishTypeCard';
import { getGeeneesNeeds } from 'root/services/geenees';
import { COLORS } from 'root/constants/vars';

import Icon from 'root/baseComponents/Icon';

import PurchaseModal from './subs/PurchaseModal';
import styles from './GeneesProduct.module.scss';

function GeneesProduct() {
	const selectedGeeneesWish = useSelector(state => state.selectedGeeneesWish);

	const [wishLists, setWishLists] = useState([]);
	const [items, setItems] = useState([]);
	const [filters, setFilters] = useState({});

	const [wishType, setWishType] = useState('all_types');
	const [location, setLocation] = useState('all_locations');
	const [cause, setCause] = useState('all_causes');

	const getGeeneesNeedsHandler = async (data = {}) => {
		const res = await getGeeneesNeeds({ data });
		if (res?.data) {
			console.log(res.data);
			setFilters(res.data.filters);
			const localWishLists = [];
			res.data.wishlists?.forEach(item => {
				if (item?.organization?.logo && item?.number_of_available_wishes >= 1) {
					localWishLists.push(item);
				}
			});
			setWishLists(localWishLists);
		}
	};

	useEffect(() => {
		if (wishType && location && cause) {
			const data = {
				wish_types: [wishType],
				locations: [location],
				causes: [cause]
			};
			console.log(data);
			getGeeneesNeedsHandler(data);
		}
	}, [wishType, location, cause]);

	useEffect(() => {
		if (wishLists?.length >= 3) {
			setItems(wishLists.slice(0, 6));
		} else {
			setItems([...wishLists]);
		}
	}, [wishLists]);

	const showMoreClickHandler = () => {
		let localItems = [];
		const lastIdx = items.length - 1;
		const remainingItems = wishLists.slice(lastIdx);
		if (remainingItems?.length >= 3) {
			localItems = [...items, ...remainingItems.slice(0, 3)];
		} else {
			localItems = [...items, ...remainingItems];
		}
		setItems(localItems);
	};

	const showLessClickHandler = () => {
		setItems(wishLists.slice(0, 3));
	};

	return (
		<>
			<Div className="bgGrayBright min-height-vh-full pl4 pr4 pt2 pb2">
				<TopBar
					filters={filters}
					wishType={wishType}
					setWishType={setWishType}
					location={location}
					setLocation={setLocation}
					cause={cause}
					setCause={setCause}
				/>
				<Div className={cx(styles.wishCardContainer)}>
					{items?.map(item => {
						if (
							item?.organization?.logo &&
							item?.number_of_available_wishes >= 1
						) {
							return (
								<WishTypeCard
									key={item.identifier}
									imageSrc={item?.image_url}
									applicant={
										item.type === 'organization'
											? item?.organization?.name
											: item.title
									}
									numberOfWishes={item?.number_of_available_wishes}
									aboutNeed={item?.story}
									sponsoredBy={
										item.type === 'organization'
											? item?.organization?.name
											: item?.organization?.name
									}
									wishes={item?.wishes}
									isOrganization={item.type === 'organization'}
									has_reciept={item?.organization?.supports_tax_receipts}
								/>
							);
						}
					})}
				</Div>
				{items?.length < wishLists?.length && (
					<Div type="flex" hAlign="center" vAlign="center" className="">
						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className="w-px-70 height-px-70 mouse-hand"
							onClick={showMoreClickHandler}>
							<Icon type="circle-plus" scale={4} color={COLORS.info} />
						</Div>
					</Div>
				)}
			</Div>
			{selectedGeeneesWish?.identifier && <PurchaseModal />}
		</>
	);
}

export default GeneesProduct;
