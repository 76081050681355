// VENDOR imports
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

// imports
import { getNeeds } from 'services/needs';
import { getImpactReport } from 'services/impactReports';
import { getBusiness } from 'root/services/businesses';

export default function useCurrentReport(currentPeriod) {
	const dispatch = useDispatch();
	const params = useParams();
	const impactReport = useSelector(
		state => state.impactReports?.currentReport?.[currentPeriod]
	);

	useEffect(() => {
		dispatch(getBusiness(params.businessId));
	}, [params.businessId]);

	useEffect(() => {
		dispatch(getNeeds(params.businessId));
	}, [params.businessId]);

	useEffect(() => {
		if (params && params.businessId) {
			dispatch(getImpactReport(params.businessId, currentPeriod));
		}
	}, [params, currentPeriod, dispatch]);

	return impactReport;
}
