import React from 'react';
import cx from 'classnames';
import { Div, Image } from 'basedesign-iswad';

import Close from 'root/baseComponents/Close';

import styles from '../ImagePicker.module.scss';

const ImagePreview = ({
	src,
	setFile,
	setSrc,
	setFileName,
	inputFileField,
	setInitialSrc = null
}) => {
	return (
		<>
			{src && (
				<Div
					type="flex"
					hAlign="center"
					vAlign="center"
					className={cx(
						'ml1 pos-rel mouse-hand',
						styles.imagePreviewContainer
					)}>
					<Image src={src} />
					<Div>
						<Close
							className=""
							onClick={() => {
								setFile('');
								setSrc('');
								setFileName('');
								inputFileField.current.value = null;
								if (setInitialSrc) {
									setInitialSrc('');
								}
							}}
						/>
					</Div>
				</Div>
			)}
		</>
	);
};

export default ImagePreview;
