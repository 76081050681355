// VENDOR imports
import { useMemo } from 'react';
import dayjs from 'dayjs';

// imports
import { LAST_TWELVE_MONTHS_STRING } from 'root/constants/locale';

export default function useHistoricalReportsData(
	historicalReports,
	historicalPeriod
) {
	const historicalReportData = useMemo(() => {
		const today = dayjs();
		let avgVote = 0;
		let needContribution = 0;
		let organizationSupported = [];
		let activeMonths = 0;
		let voteParticipationData = [];
		if (historicalPeriod === LAST_TWELVE_MONTHS_STRING) {
			[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach(value => {
				const period = dayjs(today.format('YYYY-MM'))
					.subtract(value, 'month')
					.format('YYYY-MM');
				const report = historicalReports[period];
				console.log(report);
				if (report) {
					activeMonths = activeMonths + 1;
					avgVote = avgVote + report.total_votes_percentage;
					needContribution = needContribution + report.need_contribution;
					report.needs.forEach(need => {
						if (need.is_vote_winner) {
							organizationSupported.push(need);
						}
					});
					voteParticipationData.push({
						x: dayjs(period).format('MMM'),
						y: Math.round(report?.total_votes_percentage, 2)
					});
				}
			});
		} else {
			[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach(value => {
				const report =
					historicalReports[
						dayjs(`${historicalPeriod}-12`)
							.subtract(value, 'month')
							.format('YYYY-MM')
					];
				if (report) {
					activeMonths = activeMonths + 1;
					avgVote = avgVote + report.total_votes_percentage;
					needContribution = needContribution + report.need_contribution;
					report.needs.forEach(need => {
						if (need.is_vote_winner) {
							organizationSupported.push(need);
						}
					});
					voteParticipationData.push({
						x: dayjs(`${historicalPeriod}-12`)
							.subtract(value, 'month')
							.format('MMM'),
						y: Math.round(report.total_votes_percentage, 2)
					});
				}
			});
		}
		return {
			avgVote: Math.round(avgVote / activeMonths),
			needContribution: Math.round(needContribution),
			organizationSupported: organizationSupported,
			voteParticipation: voteParticipationData.reverse()
		};
	}, [historicalReports, historicalPeriod]);

	return historicalReportData;
}
