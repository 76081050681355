import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Heading, Icon } from 'troop-design';
import { Div } from 'basedesign-iswad';

import Modal from 'components/Modal';
import { makeATaxReceiptVoid } from 'root/services/charity';
import Close from 'root/baseComponents/Close';

import { hideModal } from 'reducers/modals';

import styles from '../ViewAllReceipts.module.scss';

const VoidReceiptConfirmation = ({
	setShowVoidConfirmationPopUp,
	receiptNumberToVoid,
	setReceiptNumberToVoid
}) => {
	const history = useHistory();
	const dispatch = useDispatch();

	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.modalContainer
				)}>
				<Div
					type="flex"
					direction="vertical"
					distributedBetween
					className="w-per-90 max-w-px-500 bgWhite boxShadowType1 p2 pos-rel height-px-200">
					<Close
						onClick={() => {
							setShowVoidConfirmationPopUp(false);
							setReceiptNumberToVoid('');
						}}
					/>
					<Div>
						Are you sure you want to void the tax receipt number #
						{receiptNumberToVoid}?
					</Div>
					<Div className="mt2">
						<Button
							className="mr2 w-px-100 flex flex--jc--center bgGreen textWhite"
							onClick={() => {
								setShowVoidConfirmationPopUp(false);
								setReceiptNumberToVoid('');
							}}>
							No
						</Button>
						<Button
							className="w-px-100 flex flex--jc--center bgRed textWhite"
							onClick={async () => {
								const res = await makeATaxReceiptVoid({
									seq_receipt_number: receiptNumberToVoid
								});
								setShowVoidConfirmationPopUp(false);
								setReceiptNumberToVoid('');
								history.go(0);
							}}>
							Yes
						</Button>
					</Div>
				</Div>
			</Div>
		</>
	);
};

export default VoidReceiptConfirmation;
