import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Div, Image } from 'basedesign-iswad';
import { useDispatch, useSelector } from 'react-redux';
import { setCharitySidebarState } from 'root/reducers/charityReducers/charitySidebarState';
import { setCharityInfo } from 'root/reducers/charityReducers/charityInfo';

import { getCharityUSerInfo } from 'root/services/charity';
import Logo from 'root/assets/troop_white-logo.png';
import styles from '../CharityWrapper.module.scss';

function Header() {
	const dispatch = useDispatch();
	const charitySidebarState = useSelector(state => state.charitySidebarState);

	const [isMenuOpen, setIsMenuOpen] = useState(true);
	const [charityData, setCharityData] = useState({});
	const [abbreviatedCharityName, setAbbreviatedCharityName] = useState('');

	const getUserInfo = async () => {
		const res = await getCharityUSerInfo();
		if (res?.data?.charity) {
			setCharityData(res.data.charity);
			dispatch(setCharityInfo(res.data.charity));
		}
	};

	useEffect(() => {
		getUserInfo();
	}, []);

	useEffect(() => {
		if (charityData?.name) {
			const charitySplitted = charityData.name.split(' ').slice(0, 2);
			let localString = '';
			charitySplitted?.forEach(part => {
				localString += part[0];
			});
			setAbbreviatedCharityName(localString);
		}
	}, [charityData]);

	return (
		<>
			<Div
				type="flex"
				vAlign="center"
				className="pos-fix z-10000 pos-fix--lt w-per-100 height-px-60 bgInfo boxShaodwType1 p2">
				<Div
					className={cx(
						'mouse-hand',
						styles.hamburger,
						isMenuOpen && styles.close
					)}
					onClick={() => {
						setIsMenuOpen(!isMenuOpen);
						dispatch(setCharitySidebarState(!charitySidebarState));
					}}>
					<span
						className={cx(
							styles.line,
							styles.line1,
							isMenuOpen && styles.line1close
						)}></span>
					<span
						className={cx(
							styles.line,
							styles.line2,
							isMenuOpen && styles.line2close
						)}></span>
					<span
						className={cx(
							styles.line,
							styles.line3,
							isMenuOpen && styles.line3close
						)}></span>
				</Div>

				<Div
					type="flex"
					vAlign="center"
					hAlign="center"
					className="flex--jc--center w-per-100 height-px-60">
					<Div className="w-px-100">
						<Image src={Logo} />
					</Div>
				</Div>
				<Div type="flex" hAlign="start" vAlign="center" className="w-px-200">
					<Div className="flex flex--jc--center flex--ai--center textWhite mr1 bgOrange br-rad-per-50 height-px-30 w-px-30">
						{abbreviatedCharityName}
					</Div>
					<Div className='textWhite'> {charityData?.name} </Div>
				</Div>
			</Div>
		</>
	);
}

export default Header;
