import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import useFindBusiness from 'root/hooks/useFindBusiness';
import { hasEmployeeVoted } from 'services/landingPages';
import { addEmployeeAndUpdateVote } from 'services/employees';
import { Heading, Row, Col, Card, Form, Button, Text } from 'troop-design';

export default function UnknownEmployeeVote() {
	const [employeeVoted, setEmployeeVoted] = useState(false);
	const dispatch = useDispatch();
	const params = useParams();
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location.search);

	const id = Number(params.businessId);
	const business = useFindBusiness({ id });

	const email = urlSearchParams.get('email').toLowerCase();
	const firstname = urlSearchParams.get('firstname');
	const businessNeedId = urlSearchParams.get('business_need_id');
	const needName = urlSearchParams.get('need_name');

	const confirmUnknownEmployee = useCallback(
		e => {
			const formData = new FormData();
			formData.append('business_id', business.id);
			email && formData.append('email', email);
			firstname && formData.append('firstname', firstname);
			businessNeedId && formData.append('business_need_id', businessNeedId);

			dispatch(addEmployeeAndUpdateVote(business.id, formData));
			setEmployeeVoted(true);
		},
		[business]
	);

	useEffect(() => {
		if (!email) {
			return null;
		}
		hasEmployeeVoted(email, business?.id)
			.then(data => {
				setEmployeeVoted(data.has_voted);
			})
			.catch(() => {
				setEmployeeVoted(true);
			});
	}, [email, business]);

	return (
		<>
			<Heading level={1}>We received the following vote: </Heading>
			<Row>
				<Col md={6} className="d-flex">
					<Card className="px-5">
						<Heading level={2}>
							Business: <strong>{business?.name}</strong>
						</Heading>
						<Text tag="p" hasWrapper>
							Employee Email: <strong>{email}</strong>
						</Text>
						<Text tag="p" hasWrapper>
							Employee First name: <strong>{firstname}</strong>
						</Text>
						<Text tag="p" hasWrapper>
							Voted For: <strong>{needName}</strong>
						</Text>
						<Form onSubmit={confirmUnknownEmployee}>
							<div className="py-2">
								{!employeeVoted ? (
									<Text className="mb-2" tag="div" hasWrapper>
										Review the details above and click the button below to add
										this employee and count their vote!
									</Text>
								) : (
									<Text className="mb-2" tag="div" hasWrapper>
										<strong>
											This employee has already been added and voted!
										</strong>
									</Text>
								)}
								<Button disabled={employeeVoted} variant="primary">
									Add Employee and Update Vote
								</Button>
							</div>
						</Form>
					</Card>
				</Col>
			</Row>
		</>
	);
}
