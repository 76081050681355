import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import { getAllNeeds } from 'root/services/needs';

import Pagination from 'root/baseComponents/Paginations';
import FormSelect from 'root/baseComponents/FormSelect';

import { NeedCard } from '../../components/Card';

import { NUMBER_OF_NEEDS_IN_EACH_PAGE } from '../constants';
import styles from '../Needs.module.scss';

const AllNeeds = () => {
	const [needs, setNeeds] = useState([]);
	const [numberOfNeeds, setNumberOfNeeds] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [numberOfTotalPages, setNumberOfTotalPages] = useState(5);
	const [needTypes, setNeedTypes] = useState([]);
	const [needType, setNeedType] = useState('');
	const [needNeighbourhoods, setNeedNeighbourhoods] = useState([]);
	const [needNeighbourhood, setNeedNeighbourhood] = useState('');
	const [needProvinces, setNeedProvinces] = useState([]);
	const [needProvince, setNeedProvince] = useState('');

	const getAllNeedsData = async () => {
		try {
			const res = await getAllNeeds(
				currentPage,
				needType,
				needNeighbourhood,
				needProvince
			);
			console.log(res);
			if (res?.needs?.length) {
				setNeeds(res.needs);
				setNumberOfNeeds(res.number_of_needs);
				const localTypes = [];
				if (res?.need_types?.length) {
					localTypes.push({ value: '', shownText: 'All types' });
					res.need_types.forEach(item => {
						localTypes.push({ value: item, shownText: item });
					});
					localTypes.push({ value: 'Other', shownText: 'Other' });
					setNeedTypes(localTypes);
				}
				const localNeighbourhoods = [];
				if (res?.neighbourhood?.length) {
					localNeighbourhoods.push({ value: '', shownText: 'All cities' });
					res.neighbourhood.forEach(item => {
						localNeighbourhoods.push({ value: item, shownText: item });
					});
					setNeedNeighbourhoods(localNeighbourhoods);
				}
				const localProvinces = [];
				if (res?.provinces?.length) {
					localProvinces.push({ value: '', shownText: 'All provinces' });
					res.provinces.forEach(item => {
						localProvinces.push({ value: item, shownText: item });
					});
					setNeedProvinces(localProvinces);
				}
			} else {
				setNeeds([]);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getAllNeedsData();
	}, [currentPage, needType, needNeighbourhood, needProvince]);

	useEffect(() => {
		let localTotalPages;
		if (Number.isInteger(numberOfNeeds / NUMBER_OF_NEEDS_IN_EACH_PAGE)) {
			localTotalPages = numberOfNeeds / NUMBER_OF_NEEDS_IN_EACH_PAGE;
		} else {
			localTotalPages =
				parseInt(numberOfNeeds / NUMBER_OF_NEEDS_IN_EACH_PAGE) + 1;
		}
		setNumberOfTotalPages(localTotalPages);
	}, [numberOfNeeds]);

	useEffect(() => {
		setCurrentPage(1);
	}, [needType, needNeighbourhood]);

	return (
		<>
			<Div
				type="flex"
				distributedBetween
				className={cx(styles.allNeedsContainer)}>
				<Div className="w-px-350">
					<FormSelect
						className={cx('mb4')}
						labelText=""
						options={needTypes}
						fullWidth
						openOptionsDownWard={true}
						isRequired
						val={needType}
						initialSelected="All types"
						setVal={setNeedType}
						isInNeedsPage={true}
						itemsCenteralized={false}
						// inputSearchClassName="br-all-solid-1"
					/>
				</Div>
				<Div className="w-px-350">
					<FormSelect
						className={cx('mb4')}
						labelText=""
						options={needProvinces}
						fullWidth
						openOptionsDownWard={true}
						isRequired
						val={needProvince}
						initialSelected="All provinces"
						setVal={setNeedProvince}
						isInNeedsPage={true}
						itemsCenteralized={false}
						// inputSearchClassName="br-all-solid-1"
					/>
				</Div>
				<Div className="w-px-350">
					<FormSelect
						className={cx('mb4')}
						labelText=""
						options={needNeighbourhoods}
						fullWidth
						openOptionsDownWard={true}
						isRequired
						val={needNeighbourhood}
						initialSelected="All cities"
						setVal={setNeedNeighbourhood}
						isInNeedsPage={true}
						itemsCenteralized={false}
					/>
				</Div>
			</Div>
			<Div className={cx(styles.allNeedsContainer)}>
				{needs?.map(item => (
					<>
						<NeedCard
							photoUrl={item.image}
							name={item.name}
							province={item.charity_province}
							neighbourhood={item.neighbourhood}
							value={item.value}
							city={item.charity_city}
							charityName={item.charity_name}
							charitySlug={item.charity_slug}
							shortDescription={item.short_description}
							key={item.id}
							slug={item.slug}
							needId={item.id}
							is_fulfilled={item.is_fulfilled}
						/>
					</>
				))}
			</Div>
			<Div className="my4">
				<Pagination
					numberOfShownPages={5}
					currentPage={currentPage}
					numberOfTotalPages={numberOfTotalPages}
					setCurrentPage={setCurrentPage}
				/>
			</Div>
		</>
	);
};

export default AllNeeds;
