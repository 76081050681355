import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Close from 'root/baseComponents/Close';
import Button from 'root/baseComponents/Button';

import { deleteSingleCharity } from 'root/services/charity';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import styles from '../Charities.module.scss';

const ConfirmModal = ({
	modalCharityName,
	modalCharityId,
	setShowConfirmModal
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'pos-fix pos-fix--lt height-vh-full w-per-100 z-1000',
					styles.modalMainContainer
				)}>
				<Div className="pos-rel bgWhite w-per-80 max-w-px-500 p2">
					<Close onClick={() => setShowConfirmModal(false)} />
					<Div className="my2">
						Are you sure you want to delete charity {modalCharityName}?
					</Div>
					<Button
						className="w-px-100 mr2"
						colorType="danger"
						onClick={async () => {
							dispatch(setLoading());
							await deleteSingleCharity(modalCharityId);
							dispatch(setLoadingComplete());
							setShowConfirmModal(false);
							history.go(0);
						}}>
						Yes
					</Button>
					<Button
						className="w-px-100"
						colorType="transparent"
						onClick={() => setShowConfirmModal(false)}>
						No
					</Button>
				</Div>
			</Div>
		</>
	);
};

export default ConfirmModal;
