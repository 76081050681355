import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Heading, Icon } from 'troop-design';

import Modal from 'components/Modal';

import { hideModal } from 'reducers/modals';
import { get, set, del } from 'utils/storage';

import styles from './ThanksPayment.module.scss';
import { doNotChangeContentOfThanksMessage } from 'root/reducers/needs';

const STRIPE_PAYMENT_SUCCESSFUL = 'STRIPE_PAYMENT_SUCCESSFUL';

const ThanksPayment = ({ setShowThanksPayment }) => {
	const dispatch = useDispatch();
	const changeThanksMessage = useSelector(
		state => state.needs.changeThanksMessage
	);

	return (
		<Modal dismissable={false} reduxKey="thanksPayment" size="md">
			<div
				id={STRIPE_PAYMENT_SUCCESSFUL}
				className={cx(styles.modalMainContainer)}>
				<div
					className={cx(styles.modalClose)}
					onClick={() => {
						del('stripe_payment_status');
						dispatch(hideModal('thanksPayment'));
						if (setShowThanksPayment) {
							setShowThanksPayment(false);
						}
						dispatch(doNotChangeContentOfThanksMessage());
					}}>
					<div className={cx(styles.modalCloseContainer)}>
						<div>
							<Icon icon="close" width={15} color="black" />
						</div>
					</div>
				</div>
				<div className={styles.modalHeader}>
					<Heading level={2}>Success!</Heading>
				</div>
				<div className={cx(styles.modalBody)}>
					<p className={styles.tiny}>
						Thank you for contributing to fulfill even more local needs. If the
						need you pledge for becomes the top voted need by your company, or
						if the target amount is not reached before the pledge deadline, your
						contribution will go back into your personal funds account for
						future pledges.
					</p>
					<div className={cx(styles.btnContainer)}>
						<Button
							onClick={() => {
								del('stripe_payment_status');
								dispatch(hideModal('thanksPayment'));
								if (setShowThanksPayment) {
									setShowThanksPayment(false);
								}
								dispatch(doNotChangeContentOfThanksMessage());
							}}>
							Got it!
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ThanksPayment;
