// VENDOR imports
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import useLoggedIn from 'hooks/useLoggedIn';
import useBusinessAdminLoggedIn from 'hooks/useBusinessAdminLoggedIn';
import useSuperAdminLoggedIn from 'hooks/useSuperAdminLoggedIn';
import { validateUser } from 'root/services/user';

const PrivateRoute = props => {
	const location = useLocation();
	const dispatch = useDispatch();
	const isBusinessAdmin = useBusinessAdminLoggedIn();
	const loggedIn = useLoggedIn();
	const isSuperAdmin = useSuperAdminLoggedIn();

	useEffect(() => {
		if (!loggedIn) {
			dispatch(validateUser(location));
		}
	}, [dispatch, validateUser, loggedIn]);

	return isBusinessAdmin || isSuperAdmin ? (
		<props.Component />
	) : (
		<Redirect to={{ pathname: '/404', state: { from: location } }} />
	);
};

export default PrivateRoute;
