import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import styles from './Anchor.module.scss';

const Anchor = ({
	isLocal = true,
	toWordPress = false,
	to = '/',
	type = 1,
	children,
	...props
}) => {
	const [url, setUrl] = useState('/');

	useEffect(() => {
		if (!toWordPress) {
			setUrl(to);
		} else {
			setUrl(`https://hitroop.com${to}`);
		}
	}, [to, toWordPress]);
	return (
		<>
			<a
				className={cx(type === 1 && styles.type1, type === 2 && styles.type2)}
				href={url}
				target={!isLocal ? '_blank' : ''}
				{...props}>
				{children}
			</a>
		</>
	);
};

export default Anchor;
