import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

const VoteCard = ({
	src,
	value,
	short_description,
	open_date,
	close_date,
	pledge_close_date,
	mid_vote_reminder_date,
	is_vote_winner,
	is_fulfilled
}) => {
	return (
		<>
			<Div className="w-px-400 bgWhite mb4 pb2">
				<Div className="mb2">
					<img src={src} />
				</Div>
				<Div className="px2 mb2">{short_description}</Div>
				<Div className="px2 mb2">Target: ${value}</Div>
				<Div className="px2 mb2">Vote Open Date: {open_date}</Div>
				<Div className="px2 mb2">Mid Reminder Date{mid_vote_reminder_date}</Div>
				<Div className="px2 mb2">Vote Close Date: {close_date}</Div>
				<Div className="px2 mb2">Pledge Close Date: {pledge_close_date}</Div>
				{is_fulfilled ? <Div>Fulfilled by the business</Div> : ''}
				{is_vote_winner && !is_fulfilled ? (
					<Div>Selected as winner Vote</Div>
				) : (
					''
				)}
			</Div>
		</>
	);
};

export default VoteCard;
