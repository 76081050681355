import React, { useState } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Div, Card, Input, Label } from 'basedesign-iswad';
import { Divider } from 'troop-design';

import Button from 'root/baseComponents/Button/Button';
import CharityWrapper from '../CharityWrapper';
import TextBox from 'root/baseComponents/TextBox/TextBox';
import FormTextArea from 'root/baseComponents/FormTextArea/FormTextArea';
import Form from 'root/baseComponents/Form/Form';
import FormSelect from 'root/baseComponents/FormSelect';
import FormDatePicker from 'root/baseComponents/FormDatePicker';
import { setModalType } from 'root/reducers/charityReducers/modalType';
import { setModalProps } from 'root/reducers/charityReducers/modalProps';
import { registerNewVolunteerCharity } from 'root/services/charity';

import { PROVINCE_CHOICES } from './constants';

// import { monetaryValueValidators, needTitleValidators } from './utils';
import styles from './NewVolunteerNeed.module.scss';
import FormImagePicker from 'root/baseComponents/FormImagePicker';
import CheckBox from 'root/baseComponents/CheckBox/CheckBox';
// import {
// 	NEED_OWNER_CHOICES,
// 	MESSAGE_TEXT_AREA_PLACE_HOLDER
// } from './constants';

function NewVolunteerNeed() {
	// const toBeValidatedFields = [];

	const history = useHistory();
	const dispatch = useDispatch();

	const [name, setName] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');

	const [details, setDetails] = useState('');
	const [detailsErrorMessage, setDetailsErrorMessage] = useState('');

	const [specificRequirement, setSpecificRequirement] = useState('');
	const [specificRequirementErrorMessage, setSpecificRequirementErrorMessage] =
		useState('');

	// const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	const toBeValidatedFields = [];

	const [startDate, setStartDate] = useState('');
	const [startDateErrorMessage, setStartDateErrorMessage] = useState('');

	const [startTime, setStartTime] = useState('');
	const [startTimeErrorMessage, setStartTimeErrorMessage] = useState('');

	const [endTime, setEndTime] = useState('');
	const [endTimeErrorMessage, setEndTimeErrorMessage] = useState('');

	const [isTeamEvent, setIsTeamEvent] = useState(false);
	const [isInviteKiddos, setIsInviteKiddos] = useState(false);
	const [isOneoffRequest, setIsOneoffRequest] = useState(false);
	const [isOngoingSupport, setIsOngoingSupport] = useState(false);
	const [isVirtualWork, setIsVirtualWork] = useState(false);
	const [isBackgroundCheck, setIsBackgroundCheck] = useState(false);

	const [hasDate, setHasDate] = useState(false);

	const [hasLocation, setHasLocation] = useState(false);

	const [city, setCity] = useState('');
	const [cityErrorMessage, setCityErrorMessage] = useState('');

	const [address, setAddress] = useState('');
	const [addressErrorMessage, setAddressErrorMessage] = useState('');

	const [province, setProvince] = useState('');
	const [provinceErrorMessage, setProvinceErrorMessage] = useState('');

	const [postalcode, setPostalcode] = useState('');
	const [postalcodeErrorMessage, setPostalcodeErrorMessage] = useState('');

	const [contactName, setContactName] = useState('');
	const [contactNameErrorMessage, setContactNameErrorMessage] = useState('');

	const [contactPhone, setContactPhone] = useState('');
	const [contactPhoneErrorMessage, setContactPhoneErrorMessage] = useState('');

	const [contactEmail, setConatctEmail] = useState('');
	const [conatctEmailErrorMessage, setConatctEmailErrorMessage] = useState('');

	const customValidations = () => {
		let validated = true;

		if (!name) {
			setNameErrorMessage('Name is required');
			validated = false;
		}

		// if (!details) {
		// 	setDetailsErrorMessage('You must send a message to us!');
		// 	validated = false;
		// }

		// if (isNaN(monetaryValue) !== false) {
		// 	setMonetaryValueErrorMessage('Please enter a valid amount');
		// 	validated = false;
		// }

		// if (!monetaryValue) {
		// 	setMonetaryValueErrorMessage('Monetary Value is required');
		// 	validated = false;
		// }

		// if (!needTitle) {
		// 	setNeedTitleErrorMessage('Need title is required');
		// 	validated = false;
		// }

		// if (dayjs(fundRaisingDate).isBefore(dayjs(new Date()))) {
		// 	setFundRaisingDateErrorMessage('The deadline should be in future.');
		// 	validated = false;
		// }

		return validated;
	};

	// details = models.TextField(blank=True, null=True)
	// requirements = models.TextField(blank=True, null=True)
	// is_team_event = models.BooleanField(default=False)
	// is_invite_kiddos = models.BooleanField(default=False)
	// is_oneoff_req = models.BooleanField(default=False)
	// is_ongoing_support = models.BooleanField(default=False)
	// is_virtual = models.BooleanField(default=False)
	// is_background_check_mandatory = models.BooleanField(default=False)
	// has_specific_date = models.BooleanField(default=False)
	// has_specified_location = models.BooleanField(default=False)
	// start_date = models.DateField(blank=True, null=True)
	// end_date = models.DateField(blank=True, null=True)
	// address = models.CharField(max_length=256, blank=True, null=True)
	// city = models.CharField(max_length=256, blank=True, null=True)
	// province = models.CharField(
	//     max_length=50, choices=PROVINCE_CHOICES, null=True, blank=True)
	// postal_code = models.CharField(max_length=10, blank=True, null=True)
	// contact_name = models.CharField(max_length=256, blank=True, null=True)
	// contact_phone = models.CharField(max_length=256, blank=True, null=True)

	const submitHandler = async e => {
		if (customValidations()) {
			const bodyData = {
				name,
				details,
				requirements: specificRequirement,
				is_team_event: isTeamEvent,
				is_invite_kiddos: isInviteKiddos,
				is_oneoff_req: isOneoffRequest,
				is_ongoing_support: isOngoingSupport,
				is_virtual: isVirtualWork,
				is_background_check_mandatory: isBackgroundCheck,
				has_specific_date: hasDate,
				has_specified_location: hasLocation,
				address,
				city,
				province,
				start_time: startTime,
				postal_code: postalcode,
				contact_name: contactName,
				contact_email: contactEmail,
				contact_phone: contactPhone
			};
			if (startDate) {
				bodyData['start_date'] = dayjs(startDate).format('YYYY-MM-DD');
			}
			try {
				const res = await registerNewVolunteerCharity(bodyData);
				dispatch(setModalType('success'));
				dispatch(
					setModalProps({
						text: 'Thank you for submitting the volunteer opportunity. The Troop team is on the case.',
						header: 'Volunteer opportunity submitted!'
					})
				);
			} catch (err) {
				console.log(err);
				dispatch(setModalType('danger'));
				dispatch(
					setModalProps({
						text: 'Something went wrong, please try again!',
						header: 'Volunteer opportunity submisstion error!'
					})
				);
			}
		}
	};

	return (
		<>
			<CharityWrapper pageName="">
				<Div className={cx(styles.main)}>
					<Div className="employeeHeader mb2">New Volunteer Need Setup</Div>

					<Card className={cx('card pl8 pr8 pb3', styles.card)}>
						<Div className="mt3 flex flex--jc--center flex--ai--center textPrimary fs-px-20 f-b">
							Tell us how the Troopers can help
						</Div>
						<Div className="mt1 mb2 flex flex--jc--center flex--ai--center textFaded f-b">
							Let's make it shine! ✨
						</Div>
						<Div className="flex flex--jc--center flex--ai--center">
							<Divider width={200} />
						</Div>
						<Form
							toBeValidatedFields={toBeValidatedFields}
							className={cx(styles.form)}
							onSubmit={submitHandler}>
							<TextBox
								labelText="Name (let’s call it something):"
								type="text"
								val={name}
								setVal={setName}
								errorHandler={setNameErrorMessage}
								name="name"
								errorMessage={nameErrorMessage}
							/>
							<FormTextArea
								isRequired
								labelText="Volunteer Opportunity Details (tell the Troopers more about what you need and why):"
								val={details}
								errorMessage={detailsErrorMessage}
								setVal={setDetails}
								errorHandler={setDetailsErrorMessage}
							/>
							<FormTextArea
								isRequired
								labelText="What are the specific requirements? (Tell us how many days/hours and more about the type of work you need help with) "
								val={specificRequirement}
								setVal={setSpecificRequirement}
								errorMessage={specificRequirementErrorMessage}
								errorHandler={setSpecificRequirementErrorMessage}
							/>
							<Div className="textFaded fs-px-14">
								Tell us more so we can share this opportunity with the right
								people:
							</Div>
							<Div className="textFaded w-px-300 pl1 fs-px-14">
								<CheckBox
									labelText="Team event"
									isRequired={false}
									className="mb1 mt1"
									onBoxClick={() => setIsTeamEvent(!isTeamEvent)}
									checked={isTeamEvent}
									distributedBetween={false}
								/>
								<CheckBox
									labelText="Invite the kiddos"
									isRequired={false}
									className="mb1"
									onBoxClick={() => setIsInviteKiddos(!isInviteKiddos)}
									checked={isInviteKiddos}
									distributedBetween={false}
								/>
								<CheckBox
									labelText="One-off request 
                                    "
									isRequired={false}
									className="mb1"
									onBoxClick={() => setIsOneoffRequest(!isOneoffRequest)}
									checked={isOneoffRequest}
									distributedBetween={false}
								/>
								<CheckBox
									labelText="Ongoing support preferred"
									isRequired={false}
									className="mb1"
									onBoxClick={() => setIsOngoingSupport(!isOngoingSupport)}
									checked={isOngoingSupport}
									distributedBetween={false}
								/>
								<CheckBox
									labelText="Virtual works for us 
                                    "
									isRequired={false}
									className="mb1"
									onBoxClick={() => setIsVirtualWork(!isVirtualWork)}
									checked={isVirtualWork}
									distributedBetween={false}
								/>
								<CheckBox
									labelText="Background checks are mandatory"
									isRequired={false}
									className="mb3"
									onBoxClick={() => setIsBackgroundCheck(!isBackgroundCheck)}
									checked={isBackgroundCheck}
									distributedBetween={false}
								/>
							</Div>
							<CheckBox
								labelText="There is no specific date for this volunteer opportunity."
								isRequired={false}
								className="mb1 textFaded  fs-px-14"
								onBoxClick={() => setHasDate(!hasDate)}
								checked={!hasDate}
								distributedBetween={false}
								isRadioButton={true}
							/>
							<CheckBox
								labelText="There is a specific date for this volunteer opportunity."
								isRequired={false}
								className="mb3 textFaded  fs-px-14"
								onBoxClick={() => setHasDate(!hasDate)}
								checked={hasDate}
								distributedBetween={false}
								isRadioButton={true}
							/>
							{hasDate && (
								<Div type="flex">
									<FormDatePicker
										labelText="Start Date"
										chosenDate={startDate}
										setChosenDate={setStartDate}
										errorMessage={startDateErrorMessage}
										errorHandler={setStartDateErrorMessage}
										inputClassName={'max-w-px-200'}
										placeHolder="DD-MM-YYYY"
										isRequired={false}
									/>

									<TextBox
										labelText="Start time: (EST)"
										type="text"
										val={startTime}
										setVal={setStartTime}
										errorHandler={setStartTimeErrorMessage}
										name="start_time"
										errorMessage={startTimeErrorMessage}
									/>
									{/* <FormDatePicker
										labelText="End time: (EST)"
										chosenDate={endTime}
										setChosenDate={setEndTime}
										errorMessage={endTimeErrorMessage}
										errorHandler={setEndTimeErrorMessage}
										inputClassName={'max-w-px-200'}
										placeHolder="DD-MM-YYYY"
										isRequired={false}
									/> */}
								</Div>
							)}
							<CheckBox
								labelText="This opportunity has no location or is virtual."
								isRequired={false}
								className="mb1 textFaded  fs-px-14"
								onBoxClick={() => setHasLocation(!hasLocation)}
								checked={!hasLocation}
								distributedBetween={false}
								isRadioButton={true}
							/>
							<CheckBox
								labelText="This opportunity has one location."
								isRequired={false}
								className="mb1 textFaded  fs-px-14"
								onBoxClick={() => setHasLocation(!hasLocation)}
								checked={hasLocation}
								distributedBetween={false}
								isRadioButton={true}
							/>
							{hasLocation && (
								<Div type="flex">
									<TextBox
										labelText="Address:"
										type="text"
										val={address}
										setVal={setAddress}
										errorHandler={setAddressErrorMessage}
										name="need_address"
										errorMessage={addressErrorMessage}
									/>
									<Div className="w-px-200 ml2">
										<TextBox
											labelText="City:"
											type="text"
											val={city}
											setVal={setCity}
											errorHandler={setCityErrorMessage}
											name="need_city"
											className="w-px-200"
											errorMessage={cityErrorMessage}
										/>
									</Div>
								</Div>
							)}
							<Div className="w-px-200">
								<TextBox
									labelText="Postal code:"
									type="text"
									val={postalcode}
									setVal={setPostalcode}
									errorHandler={setPostalcodeErrorMessage}
									name="need_postalcode"
									errorMessage={postalcodeErrorMessage}
								/>
							</Div>
							<Div className="w-px-200 pos-rel">
								<FormSelect
									isRequired
									className={cx('mr1', styles.province)}
									labelText="Province"
									options={PROVINCE_CHOICES}
									fullWidth
									openOptionsDownWard={true}
									val={province}
									setVal={setProvince}
									errorMessage={provinceErrorMessage}
									errorHandler={setProvinceErrorMessage}
								/>
							</Div>
							<TextBox
								labelText="Contact name:"
								type="text"
								val={contactName}
								setVal={setContactName}
								errorHandler={setContactNameErrorMessage}
								name="contact_name"
								errorMessage={contactNameErrorMessage}
							/>
							<TextBox
								labelText="Contact phone:"
								type="text"
								val={contactPhone}
								setVal={setContactPhone}
								errorHandler={setContactPhoneErrorMessage}
								name="contact_phone"
								errorMessage={contactPhoneErrorMessage}
							/>
							<TextBox
								labelText="Contact Email:"
								type="text"
								val={contactEmail}
								setVal={setConatctEmail}
								errorHandler={setConatctEmailErrorMessage}
								name="contact_email"
								errorMessage={conatctEmailErrorMessage}
							/>
							<div className={cx('my2 flex flex--jc--end')}>
								<Button>Submit</Button>
							</div>
						</Form>
					</Card>
				</Div>
			</CharityWrapper>
		</>
	);
}

export default NewVolunteerNeed;
