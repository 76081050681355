import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import {
	Heading,
	Table,
	Button,
	Anchor,
	Form,
	Input,
	Icon,
	Text,
	Card,
	Row,
	Col,
	Container
} from 'troop-design';
import { useSelector, useDispatch } from 'react-redux';
import { getNeeds } from 'services/needs';
import { showModal } from 'reducers/modals';
import cx from 'classnames';
import Modal from 'components/Modal';
import VotePage from 'components/VotePage';
import { useParams } from 'react-router';
import { addLandingPage } from 'services/landingPages';
import useFindBusiness from 'root/hooks/useFindBusiness';
import { newNeedsColumns, pastNeedsColumns } from './utils/voteLandingPageData';
import styles from './BuildVoteLandingPage.module.scss';
import currency from 'utils/currency';
import { arraysEqual } from 'utils/helpers';
import { getNearestMonth } from 'utils/date';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

import VoteList from './subs/VoteList';

export default function BuildVoteLandingPage() {
	dayjs.extend(weekday);
	const params = useParams();
	const businessId = parseInt(params.businessId, 10);
	const business = useFindBusiness({ id: businessId });

	const [selectedNeeds, setSelectedNeeds] = useState([]);
	const [needIds, setNeedIds] = useState([]);
	const [heading, setHeading] = useState('');
	const [bodyText, setBodyText] = useState(
		`Here's a list of just a few local needs in your area. Cast your vote for the need you want to see fulfilled.`
	);
	const dispatch = useDispatch();
	const needs = useSelector(state => state.needs.needs);
	const pastNeeds = useSelector(state =>
		state.needs.businessNeeds.filter(need => need.business === businessId)
	);
	const nearestMonth = getNearestMonth().format('YYYY-MM-DD');
	const endDate = getNearestMonth().add(1, 'month').format('YYYY-MM-DD');
	const endTime = '17:00';
	const lastCallReminderDate = dayjs(endDate)
		.subtract(3, 'day')
		.add(9, 'hour')
		.format('YYYY-MM-DD');
	const midVoteReminderDate = dayjs(lastCallReminderDate)
		.weekday(-6)
		.format('YYYY-MM-DD');
	const employeePledgeCloseDate = dayjs(endDate)
		.add(7, 'day')
		.add(9, 'hour')
		.format('YYYY-MM-DD');

	useEffect(() => {
		dispatch(getNeeds()); // All needs
		dispatch(getNeeds(businessId)); // Fulfilled needs
	}, [businessId, dispatch]);

	useEffect(() => {
		if (business) {
			setHeading(`${business.name}'s Do Good Options`);
		}
	}, [business]);

	const needsData = React.useMemo(() => {
		return needs
			.filter(need => need.status !== 'closed')
			.map(need => ({
				id: need.id,
				// charity: need.charity_name,
				charity: (
					<Anchor to={need.need_website} target="_blank">
						{need.charity_name}
					</Anchor>
				),
				needType: need.need_type,
				description: need.short_description,
				target: currency.format(need.value, 'en-CA', 'CAD', 0),
				location: need.neighbourhood
			}));
	}, [needs]);

	const pastNeedsData = React.useMemo(() => {
		const pastNeedsMergedWithNeeds = pastNeeds.map(need => {
			const matchingNeed = needs.find(n => n.id === need.need);
			return {
				...need,
				fullInfo: matchingNeed
			};
		});
		return pastNeedsMergedWithNeeds.map(need => ({
			id: need.id,
			charity: need?.fullInfo?.charity_name,
			name: (
				<Anchor to={need?.fullInfo?.need_website} target="_blank">
					{need?.fullInfo?.name}
				</Anchor>
			),
			voted: need.vote_count,
			month: need.vote_close_date
				? dayjs(need.vote_close_date).format('MMMM')
				: '-',
			result: need.is_vote_winner ? 'Winner' : 'Vote Option'
		}));
	}, [pastNeeds]);

	const handlePreview = e => {
		e.preventDefault();
		dispatch(showModal('preview'));
	};

	const renderProceedTab = () => {
		if (needIds?.length) {
			return (
				<Card className={styles.proceedBar}>
					<Button
						variant="success"
						outline
						className={cx('me-4 d-inline-flex align-items-center')}
						onClick={handlePreview}>
						<Icon icon="search" size="md" className="me-2" color="teal" />
						Preview
					</Button>
					<Button
						variant="success"
						className={cx('me-4 d-inline-flex align-items-center')}>
						<Icon
							icon="checkmark"
							size="md"
							color="white"
							className={cx('me-2', styles.fillWhite)}
						/>
						Add Vote Landing Page
					</Button>
				</Card>
			);
		}
	};

	const handleSubmit = values => {
		const data = {
			business_id: businessId,
			need_ids: needIds,
			vote_page_title: values.heading,
			vote_page_description: values.bodyText,
			monthDate: values.voteOpenDate,
			vote_opened_date: values.voteOpenDate,
			vote_close_date: values.voteCloseDate,
			vote_close_time: values.voteCloseTime,
			first_reminder_date: values.firstReminderDate,
			mid_vote_reminder_date: values.midReminderDate,
			pledge_close_date: values.employeePledgeCloseDate
		};
		dispatch(addLandingPage(data, businessId));
	};

	const handleCheck = checkedValues => {
		const newCheckedValues = checkedValues.map(value => value.id);
		if (!arraysEqual(selectedNeeds, newCheckedValues)) {
			setSelectedNeeds(newCheckedValues);
		}
	};

	const renderTable = useMemo(
		() => () => {
			return (
				<>
					<Text hasWrapper>Select the needs to continue..</Text>
					<VoteList needIds={needIds} setNeedIds={setNeedIds} />
					{/* <Table
						className={cx(styles.employeesTable, 'mb-5')}
						data={needsData}
						columns={newNeedsColumns}
						paginate
						enableFilters
						striped={false}
						hover
						isSelectable
						onCheck={handleCheck}
					/>
					<Heading level={2}>Previous Vote Options</Heading>
					<Table
						className={cx(styles.employeesTable, styles.pastEmployeesTable)}
						data={pastNeedsData}
						columns={pastNeedsColumns}
						paginate
						enableFilters
						striped={false}
						hover
					/>
					<div className={styles.pastEmployeesTableSpacing} /> */}
				</>
			);
		},
		[needsData, newNeedsColumns, pastNeedsData, pastNeedsColumns]
	);

	// useEffect(() => {
	// 	console.log(needIds);
	// }, [needIds]);

	return (
		<div>
			<Row className={'mt-5'}>
				<Col xs={12} md={8} className="d-flex">
					<div className="employeeHeading mt2 mb2"> Build Landing Page </div>
				</Col>
			</Row>
			<Form onSubmit={handleSubmit}>
				<Card>
					<Input
						label="Heading"
						name="heading"
						value={heading}
						onKeyUp={e => setHeading(e.target.value)}
					/>
					<Input
						label="Body Text"
						name="bodyText"
						value={bodyText}
						onKeyUp={e => setBodyText(e.target.value)}
					/>
					<Input
						label="Vote Open date"
						name="voteOpenDate"
						type="date"
						value={nearestMonth}
					/>
					<Input
						label="Vote Closing date"
						name="voteCloseDate"
						type="date"
						value={endDate}
					/>
					<Input
						label="Vote Closing time"
						name="voteCloseTime"
						value={endTime}
					/>
					<Input
						label="Last call email reminder date"
						name="firstReminderDate"
						type="date"
						value={lastCallReminderDate}
					/>
					<Input
						label="Mid vote email reminder date"
						name="midReminderDate"
						type="date"
						value={midVoteReminderDate}
					/>
					<Input
						label="Employee pledge close date"
						name="employeePledgeCloseDate"
						type="date"
						value={employeePledgeCloseDate}
					/>
				</Card>
				{renderProceedTab()}
			</Form>
			{renderTable()}
			<Modal reduxKey="preview" size="lg">
				<VotePage
					title={heading}
					logo={business?.logo}
					bodyText={bodyText}
					needs={[...needs].filter(need => needIds?.includes(need.id))}
				/>
			</Modal>
		</div>
	);
}
