const newNeedsColumns = [
	{
		Header: 'Need Id',
		accessor: 'id',
		filter: 'equals'
	},
	{
		Header: 'Charity',
		accessor: 'charity',
		filter: 'fuzzyText'
	},
	{
		Header: 'Need Type',
		accessor: 'need_type',
		filter: 'fuzzyText'
	},
	{
		Header: 'Description',
		accessor: 'description',
		filter: 'equals'
	},
	{
		Header: 'Target',
		accessor: 'target',
		filter: 'includes'
	},
	{
		Header: 'Location',
		accessor: 'location',
		filter: 'fuzzyText'
	}
];

const pastNeedsColumns = [
	{
		Header: 'Need Id',
		accessor: 'id',
		filter: 'equals'
	},
	{
		Header: 'Charity',
		accessor: 'charity',
		filter: 'equals'
	},
	{
		Header: 'Name',
		accessor: 'name',
		filter: 'equals'
	},
	{
		Header: 'Voted',
		accessor: 'voted',
		filter: 'equals'
	},
	{
		Header: 'Month',
		accessor: 'month',
		filter: 'equals'
	},
	{
		Header: 'Result',
		accessor: 'result',
		filter: 'equals'
	}
];

export { newNeedsColumns, pastNeedsColumns };
