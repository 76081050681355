import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import { retrieveAllCharities } from 'root/services/charity';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';

import Wrapper from '../components/Wrapper';
import ConfirmModal from './subs/ConfirmModal';
import styles from './Charities.module.scss';

const Charities = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [charities, setCharities] = useState([]);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [modalCharityName, setModalCharityName] = useState('');
	const [modalCharityId, setModalCharityId] = useState('');

	const retrieveAllCharitiesData = async () => {
		dispatch(setLoading());
		try {
			const res = await retrieveAllCharities();
			dispatch(setLoadingComplete());
			const localCharities = [];
			res.forEach(item => {
				localCharities.push({
					name: item.name,
					registered_name: item.registered_name,
					registered_number: item.registered_charity_number,
					id: item.id
				});
			});
			setCharities(localCharities);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		retrieveAllCharitiesData();
	}, []);

	const headLines = [
		{
			value: 'id',
			display: 'ID',
			width: 200,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'name',
			display: 'Name',
			width: 300,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'registered_name',
			display: 'Registered Name',
			width: 400,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'registered_number',
			display: 'Registered Number',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'edit',
			display: 'Edit',
			width: 150,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'delete',
			display: 'Delete',
			width: 150,
			hasSearch: false,
			isSortable: false
		}
	];

	const data = useMemo(() => {
		if (charities) {
			const tableData = [];
			charities.forEach(item => {
				tableData.push({
					id: item.id,
					name: item.name,
					registered_name: item.registered_name,
					registered_number: item.registered_number,
					edit: {
						value: item.id,
						display: (
							<Div
								type="flex"
								hAlign="start"
								vAlign="center"
								className="w-per-100">
								<Button
									colorType="transparent-success"
									onClick={() => history.push(`/writer-charities/${item.id}`)}>
									Edit
								</Button>
							</Div>
						)
					},
					delete: {
						value: item.id,
						display: (
							<Div
								type="flex"
								hAlign="start"
								vAlign="center"
								className="w-per-100">
								<Button
									colorType="danger"
									onClick={() => {
										setModalCharityName(item.name);
										setModalCharityId(item.id);
										setShowConfirmModal(true);
									}}>
									Delete
								</Button>
							</Div>
						)
					}
				});
			});

			return tableData;
		} else {
			return [];
		}
	}, [charities]);

	return (
		<>
			<Wrapper pageName="Charities">
				<Div className="p2 ">
					<Button
						className="w-px-250"
						onClick={() => history.push('/writer-add-charity')}>
						Create New Charity
					</Button>
				</Div>
				<Div className="pt4 w-per-100 pr2 pl2 flex flex--jc--start of-x-auto scrollType1">
					<Table
						headLines={headLines}
						data={data}
						// colWidth={400}
						// tableWidth={'1000px'}
						isSortable={true}
						isSelectable={false}
						selectedData={selectedData}
						setSelectedData={setSelectedData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						rowsPerPage={10}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={3}
					/>
				</Div>
				{showConfirmModal && (
					<ConfirmModal
						modalCharityName={modalCharityName}
						modalCharityId={modalCharityId}
						setShowConfirmModal={setShowConfirmModal}
					/>
				)}
			</Wrapper>
		</>
	);
};

export default Charities;
