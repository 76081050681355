import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div, Heading } from 'basedesign-iswad';

import { getAllFulfilledNeeds } from 'root/services/needs';
import Button from 'root/baseComponents/Button';

import { NeedCard } from '../../components/Card';

import styles from '../SingleNeed.module.scss';

const FulfilledNeeds = () => {
	const history = useHistory();

	const [fulfilledNeeds, setFulfilledNeeds] = useState([]);

	const getAllFulfilledNeedsData = async () => {
		try {
			const res = await getAllFulfilledNeeds(1, '', '');
			if (res?.needs?.length) {
				setFulfilledNeeds(res.needs);
			} else {
				setFulfilledNeeds([]);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getAllFulfilledNeedsData();
	}, []);

	useEffect(() => {
		console.log(fulfilledNeeds?.slice(0, 3));
	}, [fulfilledNeeds]);

	return (
		<>
			<Div className={cx('py4')}>
				<Div
					className={cx('ml-auto mr-auto', styles.fulfilledContentContainer)}>
					<Heading className={cx('textGray', styles.contentContainer)}>
						Take a look at other needs we've fulfilled
					</Heading>
					<Div className={cx(styles.allNeedsContainer)}>
						{fulfilledNeeds?.slice(0, 3)?.map(item => (
							<NeedCard
								photoUrl={item.image}
								name={item.name}
								neighbourhood={item.neighbourhood}
								value={item.value}
								city={item.charity_city}
								charityName={item.charity_name}
								shortDescription={item.short_description}
								key={item.id}
								charitySlug={item.charity_slug}
								needId={item.id}
								is_fulfilled={item.is_fulfilled}
							/>
						))}
					</Div>
					<Div type="flex" hAlign="center" className="my2 w-per-100">
						<Button
							className="w-px-200"
							onClick={() => history.push('/fulfilled-needs')}>
							View All
						</Button>
					</Div>
				</Div>
			</Div>
		</>
	);
};

export default FulfilledNeeds;
