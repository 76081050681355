import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';

import { retreiveEmailLogs } from 'root/services/emails';

function EmailLogs() {
	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [logs, setLogs] = useState([]);

	const retrieveAllLogsData = async () => {
		const res = await retreiveEmailLogs();
		console.log(res);
		if (res?.length) {
			setLogs([...res]);
		}
	};

	useEffect(() => {
		retrieveAllLogsData();
	}, []);

	const headLines = [
		{
			value: 'business',
			display: 'Business',
			width: 200,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'email',
			display: 'Email',
			width: 300,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'sent_from_server',
			display: 'Sent',
			width: 100,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'date',
			display: 'Date',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'type',
			display: 'Type',
			width: 200,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'is_campaign',
			display: 'Is Campaign',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'template_id',
			display: 'Template ID',
			width: 200,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		logs?.forEach(item => {
			tableData.push({
				business: item?.business_info,
				email: item?.user_info?.email,
				sent_from_server: item?.sent_from_server ? 'Yes' : 'No',
				date: dayjs(item?.created_at).format('DD-MMM-YY'),
				type: item?.email_type,
				is_campaign: item?.is_campaign ? 'Yes' : 'No',
				template_id: item?.email_template_id
			});
		});

		return tableData;
	}, [logs]);

	return (
		<>
			<Div className="w-per-90 ml2 mr2 flex flex--jc--start pt2 of-x-auto scrollType1">
				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={10}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
}

export default EmailLogs;
