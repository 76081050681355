import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { sendBusinessReferral } from 'services/businesses';
import { TELL_A_FRIEND, TELL_A_FRIEND_SUBJECT } from '../constants';
import {
	Heading,
	Col,
	Card,
	Form,
	Button,
	Text,
	Input,
	Icon
} from 'troop-design';

export default function TellAFriend({ business, user }) {
	const dispatch = useDispatch();
	const tellAFriend = useCallback(
		e => {
			const finalData = new FormData();
			finalData.append('currentBusiness', business.name);
			finalData.append('email', e.leadEmail);
			finalData.append('leadCompany', e.leadCompany);
			finalData.append('leadName', e.leadName);
			finalData.append('trigger', 'user_triggered');
			dispatch(sendBusinessReferral(business.id, finalData));
			const body =
				'Hi ' +
				e.leadName +
				',\n\nWe recently implemented a social impact program at ' +
				business.name +
				' and I thought it might be a good fit for you over at ' +
				e.leadCompany +
				'.\n\nWe’ve partnered with a social enterprise called Troop (HiTroop.com) and their approach to CSR is quite unique. Their solution is a monthly giving program that lets our employees choose which causes we donate to. They focus on tangible needs from local, neighbourhood charities (like a new mom who needs a baby stroller in Parkdale). They even send us a report every month that has pictures and messages from the charities/neighbours so we can see the impact of our contributions.\n\n' +
				'It’s been well received by the team; it’s low effort and they’re enjoying getting to decide on the charities, and the team feels more connected to our initiatives because of it. The program was super simple to get started (literally a list of employees and sending an internal email) and it’s been a great timesaver as I haven’t had to manage it day to day.\n\n' +
				'Plus, because you’re being referred, they’re offering to waive your first month service fee.\n\n' +
				'Here’s how you can get in contact with each other:\n\n' +
				'Kelly Emery, founder\n\n' +
				'kelly@hitroop.com\n\n' +
				'https://www.linkedin.com/in/kelly-emery-b3607875/\n\n' +
				'All the best,\n\n' +
				user.first_name;

			document.location = `mailto:${
				e.leadEmail
			}?subject=${TELL_A_FRIEND_SUBJECT}&body=${encodeURIComponent(body)}`;
		},
		[user, business, dispatch]
	);

	return (
		<Col md={8} className="d-flex">
			<Card className="px-md-5 py-4">
				<Text tag="p" hasWrapper>
					Love Troop? Spread the word! Share your Troop experience and help us
					connect more companies with local causes. Plus, Troop will top up your
					need contribution budget by $500 for every new business partner who
					signs up for a min of 3 months. Let’s make an even bigger impact
					together!
				</Text>
				<Form onSubmit={tellAFriend}>
					<Input
						label="Business Name"
						name="leadCompany"
						validationRules={[
							{
								rule: 'string.isRequired',
								message:
									'Please enter the name of the business you would like to refer'
							}
						]}
					/>
					<Input
						label="Contact Name"
						name="leadName"
						validationRules={[
							{
								rule: 'string.isRequired',
								message:
									'Please enter a valid name of the person who owns the above business'
							}
						]}
					/>
					<Input
						label="Contact Email"
						name="leadEmail"
						validationRules={[
							{
								rule: 'string.isEmail',
								message: 'Please enter a valid email address'
							}
						]}
					/>
					<div className="flex py-2">
						<Button id={TELL_A_FRIEND} className="mx-1" variant="primary">
							<Icon icon="email" size="md" className="me-2" color="white" />
							Email a Friend
						</Button>
					</div>
				</Form>
			</Card>
		</Col>
	);
}
