import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Address from './Address';
import styles from '../SingleNeed.module.scss';

const NeedSummary = ({ need }) => {
	return (
		<>
			<Div
				className={cx(
					'w-per-100 bgLightGray py3 textGray',
					styles.needSummary
				)}>
				<Div className={cx(styles.contentContainer)}>
					<Div className="f-b fs-px-24 mb3 textBlack">
						More Details: {need?.charity_name}
					</Div>
					<Div className="px3 mb3">{need?.need_summary}</Div>
					{/* <Div>Location:</Div>
					<Div>
						{need?.charity_location?.address}, {need?.charity_location?.city},{' '}
						{need?.charity_location?.province},{' '}
						{need?.charity_location?.postal_code}
					</Div> */}
					<Div>
						<Address
							address={`${need?.charity_location?.address}, ${need?.charity_location?.city}
						${need?.charity_location?.province}
						${need?.charity_location?.postal_code}`}
						/>
					</Div>
				</Div>
			</Div>
		</>
	);
};

export default NeedSummary;
