import { deleteCookie, getCookie, getEnv } from 'root/utils/helpers';
import { get, post, put } from './http';
import { setCookie } from 'root/utils/helpers';
import { del } from 'root/utils/storage';
import {
	loginUser,
	logoutUser,
	setIsFullyRegistered,
	setBusinessUserProfile
} from 'reducers/users';
import { addNotification } from 'root/reducers/notifications';
import history from 'root/utils/history';

export const getUserInfo = (dispatch, redirectToLastPageCallback) => {
	get('/userinfo/').then(data => {
		dispatch(loginUser(data));
		dispatch(
			setIsFullyRegistered(
				data.redirect_url !== '/business-complete-registration/'
			)
		);
		redirectToLastPageCallback &&
			redirectToLastPageCallback(data.redirect_url, dispatch);
	});
};

export const login = (username, password, lastUrl) => {
	const clientId = getEnv('CLIENT_ID');
	const clientSecret = getEnv('CLIENT_SECRET');
	const tokenEndpoint = getEnv('TOKEN_ENDPOINT');

	const data = {
		username: username.toLowerCase(),
		password,
		grant_type: 'password',
		client_id: clientId,
		client_secret: clientSecret
	};

	const handleSuccessLogin = (linkToRedirectTo, dispatch) => {
		let redirectLink = lastUrl;
		if (lastUrl.pathname === getEnv('LOGIN_URL') || lastUrl.pathname === '/') {
			redirectLink = linkToRedirectTo;
		}
		history.push(redirectLink);
		// dispatch(
		// 	addNotification({
		// 		type: 'success',
		// 		message: 'Successfully logged in.',
		// 		options: {
		// 			autoDismiss: true
		// 		}
		// 	})
		// );
	};

	return dispatch =>
		post(tokenEndpoint, data, 'post').then(data => {
			setCookie('accessToken', data.access_token);
			setCookie('refreshToken', data.refresh_token);
			getUserInfo(dispatch, handleSuccessLogin);
			return data;
		});
};

export function handleLogout(redirect = true) {
	deleteCookie('accessToken');
	deleteCookie('refreshToken');
	if (redirect) {
		history.push(getEnv('LOGIN_URL'));
	}
	return dispatch => {
		del('userRole');
		dispatch(logoutUser());
		dispatch(
			addNotification({
				type: 'danger',
				message: 'Successfully logged out.',
				options: {
					autoDismiss: true
				}
			})
		);
	};
}

export function resetPassword(email) {
	return dispatch => {
		return post('/reset_password/', { email }).then(response => {
			dispatch(
				addNotification({
					type: 'success',
					message:
						'You will receive an email with the reset instructions shortly.'
				})
			);
			return response;
		});
	};
}

export function validateResetToken(token) {
	return post('/reset_password/validate_token/', { token });
}

export function completeResetPassword(token, password) {
	return post('/reset_password/confirm/', { token, password });
}

export const handleRenewAccessToken = () => {
	const clientId = getEnv('CLIENT_ID');
	const clientSecret = getEnv('CLIENT_SECRET');
	const tokenEndpoint = getEnv('TOKEN_ENDPOINT');
	const refreshToken = getCookie('refreshToken');

	const data = {
		refresh_token: refreshToken,
		grant_type: 'refresh_token',
		client_id: clientId,
		client_secret: clientSecret
	};
	return post(tokenEndpoint, data, 'post')
		.then(data => {
			setCookie('accessToken', data.access_token);
			setCookie('refreshToken', data.refresh_token);
			return data;
		})
		.catch(err => {
			handleLogout();
		});
};

export const validateUser =
	(lastUrl, forceLogin = true) =>
	dispatch => {
		return get('/validate-user/')
			.then(data => {
				getUserInfo(dispatch, () => {
					history.push(lastUrl);
					dispatch(
						addNotification({
							type: 'success',
							message: 'Successfully logged in using previous session.',
							options: {
								autoDismiss: true
							}
						})
					);
				});
			})
			.catch(() => {
				handleLogout(false);
				if (forceLogin) {
					history.push(getEnv('LOGIN_URL'), { from: lastUrl });
				}
			});
	};

export function registerBusinessAdmin(userData) {
	return post('/business_admin_register/', userData, 'post');
}

export function registerBusinessEmployee(userData) {
	return post('/business_employee_register/', userData, 'post');
}

// Get Troop Admin Data
export const getAdminData = () => {
	return get('/troop_admin/');
};

// Get needs contributions
export const getNeedsContributions = () => {
	return get('/users/need-contributions/');
};

// Get average employee engagement
export const getEmployeeEngagements = () => {
	return get('/users/employee-engagements/');
};

export const checkIsHuman = data => {
	return post('/is_human_or_robot/', data);
};

export const getBusinessUserProfile = (dispatch, businessId) => {
	get(`/businesses/${businessId}/employees/me/`).then(data => {
		dispatch(setBusinessUserProfile(data));
	});
};

export const updateBusinessUserProfile = (businessId, data) => {
	return put(`/businesses/${businessId}/employees/me/`, data);
};

export const getEmployeeDetailsFromKey = businessUserKey =>
	get(`/employees/${businessUserKey}/employee_details/`);

export const addMoneyToBeProcessed = data => {
	return post(`/add_money_to_be_processed/`, data);
};

export const clearMoneyToBeProcessed = data => {
	return post(`/clear_money_to_be_processed/`, data);
};

export const employeeShareSlackPledge = data => {
	return post(`/employee_share_slack_pledge/`, data);
};

export const employeeShareTeamsPledge = data => {
	return post(`/employee_share_teams_pledge/`, data);
};

export const checkIfIsFirstLogin = data => {
	return post(`/check_if_is_first_login/`, data);
};

export const createUSerEvent = data => {
	return post(`/user_actions/`, data);
};

export const getUserEvents = data => {
	if (data?.business_user_id) {
		return get(`/user_actions/?business_user_id=${data.business_user_id}`);
	}
	return get(`/user_actions/`);
};

export const checkIfUserAlreadyLoggedIn = data => {
	return post(`/check_if_user_already_logged_in/`, data);
};

export const getEmployeeStripeTransactions = business_user_id => {
	return get(`/employee-stripe-payment/${business_user_id}`);
};

export const getEmployeePledgeTransactions = business_user_id => {
	return get(`/employee_pledged_need/${business_user_id}`);
};

export const googleAuthGetToken = data => {
	return post(`/google_auth_login/`, data);
};

export const googleAuthHandleToken = data => {
	return post(`/google_auth_handle_token/`, data);
};

export const microsoftAuthGetToken = data => {
	return post(`/microsoft_auth_login/`, data);
};

export const microsoftAuthHandleToken = data => {
	return post(`/microsoft_auth_handle_token/`, data);
};

export const checkIfUserHasPassword = data => {
	return post(`/api/user-has-password/`, data);
};

export const updatePassword = data => {
	return post(`/update_password/`, data);
};
