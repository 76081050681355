import React, { useState, useEffect, useMemo } from 'react';
import Table from 'root/baseComponents/Table';
import { Div } from 'basedesign-iswad';
import cx from 'classnames';
import KindnessViewModal from './KindnessViewModal';

import { retrieveAllGeeneesImpacts } from 'root/services/geenees';

import { ACTS } from './constants';
import styles from '../MyImpact.module.scss';

const ActTable = ({ date, businessId }) => {
	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [impacts, setImpacts] = useState([]);
	const [note, setNote] = useState('');
	const [images, setImages] = useState([]);

	const [showKindnessModal, setShowKindnessModal] = useState(false);

	const retrieveAllGeeneesImpactsData = async () => {
		const res = await retrieveAllGeeneesImpacts({
			date,
			business_id: businessId
		});
		console.log(res);
		const localImpacts = [];
		res?.forEach(item => {
			const pics = [];
			item?.images?.forEach(pic => {
				if (pic) {
					pics.push({ src: pic });
				}
			});
			localImpacts.push({
				organization: item.organization,
				item: item.title,
				value: item.value,
				impact: {
					note: item?.note || '',
					images: pics
				}
			});
		});
		setImpacts(localImpacts);
	};

	useEffect(() => {
		if (date) {
			retrieveAllGeeneesImpactsData();
		}
	}, [date]);

	const headLines = [
		{
			value: 'organization',
			display: 'Organization',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'item',
			display: 'Item',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'value',
			display: 'Value',
			width: 150,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'impact',
			display: 'Impact',
			width: 150,
			hasSearch: false,
			isSortable: false
		}
	];

	// const data = useMemo(() => {
	// 	const tableData = [];
	// 	ACTS.forEach(item => {
	// 		tableData.push({
	// 			item: `${item.item}`,
	// 			value: `${'$' + item.value}`,
	// 			organization: `${item.organization}`,
	// 			impact: {
	// 				value: '',
	// 				display: (
	// 					<Div
	// 						// onClick={() => history.push(`/impacts-acts`)}
	// 						className={cx('mouse-hand', styles.tableLink)}
	// 						onClick={() => setShowKindnessModal(true)}>
	// 						View
	// 					</Div>
	// 				)
	// 			}
	// 		});
	// 	});
	// 	return tableData;
	// }, [ACTS, date]);

	const data = useMemo(() => {
		if (impacts?.length) {
			const tableData = [];
			impacts.forEach(item => {
				tableData.push({
					item: `${item.item}`,
					value: `${'$' + parseFloat(item.value).toFixed(2)}`,
					organization: `${item.organization}`,
					impact: {
						value: '',
						display: item.impact?.note ? (
							<Div
								// onClick={() => history.push(`/impacts-acts`)}
								className={cx('mouse-hand', styles.tableLink)}
								onClick={() => {
									setNote(item.impact.note);
									if (item.impact.images.length) {
										setImages([...item.impact.images]);
									} else {
										setImages([]);
									}
									setShowKindnessModal(true);
								}}>
								View
							</Div>
						) : (
							<Div>-</Div>
						)
					}
				});
			});
			return tableData;
		} else {
			return [];
		}
	}, [impacts]);

	return (
		<>
			{data?.length >= 1 ? (
				<>
					<Div className="mb2 mt2 ml4 employeeHeading">
						{' '}
						Act of Kindness Report
					</Div>
					<Div
						display="flex"
						hAlign="center"
						className="mt2 ml3 w-per-90 of-x-auto">
						<Table
							headLines={headLines}
							data={data}
							// colWidth={400}
							tableWidth={'900px'}
							isSortable={true}
							isSelectable={false}
							selectedData={selectedData}
							setSelectedData={setSelectedData}
							sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
							rowsPerPage={9}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							showDefaultPagination={true}
							numberOfShownPages={3}
						/>
					</Div>
					{showKindnessModal && (
						<KindnessViewModal
							setShowKindnessModal={setShowKindnessModal}
							note={note}
							images={images}
						/>
					)}
				</>
			) : (
				''
			)}
		</>
	);
};

export default ActTable;
