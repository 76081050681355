export const showGoToVoteTheme = (
	incentiveQuotes,
	styles,
	items,
	iconType,
	iconColor,
	cx,
	Heading,
	Icon,
	history
) => {
	return (
		<>
			<div
				className={cx(styles.closeContainer)}
				onClick={() => {
					const queryInUrl = history.location.search;
					const voteUrl = `${history.location.pathname.replace(
						'register-to-vote',
						'vote'
					)}${queryInUrl}`;
					history.push(voteUrl);
				}}>
				<Icon icon="close" color="black" width={20} />
			</div>
			<div className="pt2">
				{incentiveQuotes.map((quote, idx) => (
					<Heading
						level={2}
						className={cx('mb2 text-center', styles.containerColLeftHeading)}
						key={idx}>
						{quote}
					</Heading>
				))}
			</div>

			<div className={cx(styles.bodyContainer)}>
				<div className={cx(styles.body)}>
					{items.map((item, idx) => (
						<div key={idx} className={cx(styles.bodyItem)}>
							<div className={cx(styles.bodyItemHeart)}>
								<Icon icon={iconType} color={iconColor} width={40} />
							</div>
							<div className={cx(styles.bodyItemContent)}>{item}</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

export const showRegisterTheme = (styles, cx, Heading, Icon, ctaWord) => {
	return (
		<>
			{/* <div className={cx(styles.containerColRegisterMain)}>
				<Heading
					level={2}
					className={cx('text-center', styles.containerColRegisterMainHeading)}>
					{' '}
					Hello! 👋
				</Heading>
				<p className={cx(styles.registerText)}>We're ramping up security.</p>
				<p className={cx(styles.registerText)}>
					<span className={cx(styles.underline)}>{ctaWord}</span> to access
					voting and more!
				</p>
				<div className={cx(styles.registerContainer)}>
					<div className={cx(styles.registerItem)}>
						<div className={cx(styles.registerItemHeart)}>
							<Icon icon="heart" color="yellow" width={40} />
						</div>
						<div className={cx(styles.registerItemContent)}>
							Read Impact Stories
						</div>
					</div>

					<div className={cx(styles.registerItem)}>
						<div className={cx(styles.registerItemHeart)}>
							<Icon icon="heart" color="yellow" width={40} />
						</div>
						<div className={cx(styles.registerItemContent)}>
							Get community updates
						</div>
					</div>

					<div className={cx(styles.registerItem)}>
						<div className={cx(styles.registerItemHeart)}>
							<Icon icon="heart" color="yellow" width={40} />
						</div>
						<div className={cx(styles.registerItemContent)}>
							Track votes and fulfill needs
						</div>
					</div>
				</div>
			</div> */}
		</>
	);
};
