import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Chart from 'root/baseComponents/Chart';

import styles from '../Dashboard.module.scss';
import AllNeeds from 'root/pages/PublicRoutes/FulfilledNeeds/subs/AllNeeds';

const SINGLE_LINE_CHART_DATA = [
	{ x: -3, y: 9 },
	{ x: -2, y: 4 },
	{ x: -1, y: 1 },
	{ x: 0, y: 0 },
	{ x: 1, y: 1 },
	{ x: 2, y: 4 },
	{ x: 3, y: 9 }
];

const Y_AXIS_OPTIONS = {
	ticks: {
		callback: function (value, index, ticks) {
			return parseFloat(value);
		},
		beginAtZero: true,
		color: 'black',
		stepSize: 5
	},
	title: {
		display: true,
		text: 'Number of added needs',
		font: {
			size: 14
		}
	},
	grid: {
		display: true,
		drawTicks: true,
		drawOnChartArea: false,
		color: 'silver'
	}
};

const X_AXIS_OPTIONS = {
	ticks: {
		beginAtZero: true,
		color: 'black'
	},
	title: {
		display: true,
		text: 'Date',
		font: {
			size: 14
		}
	},
	grid: {
		display: true,
		drawTicks: true,
		drawOnChartArea: false,
		color: 'black'
	}
};

const LineChart = ({ allNeeds, year }) => {
	const [lineChartData, setLineCahrtData] = useState([
		{ x: 'Jan', y: 0 },
		{ x: 'Feb', y: 0 },
		{ x: 'Mar', y: 0 },
		{ x: 'Apr', y: 0 },
		{ x: 'May', y: 0 },
		{ x: 'Jun', y: 0 },
		{ x: 'Jul', y: 0 },
		{ x: 'Aug', y: 0 },
		{ x: 'Sep', y: 0 },
		{ x: 'Oct', y: 0 },
		{ x: 'Nov', y: 0 },
		{ x: 'Dec', y: 0 }
	]);

	useEffect(() => {
		let localLineChartData;
		if (year === 'last_12_months') {
			localLineChartData = [];
			const today = dayjs();
			for (let i = 11; i >= 0; i--) {
				let curMonth = today.subtract(i, 'month').format('MMM');
				localLineChartData.push({ x: curMonth, y: 0 });
			}
		} else {
			localLineChartData = [
				{ x: 'Jan', y: 0 },
				{ x: 'Feb', y: 0 },
				{ x: 'Mar', y: 0 },
				{ x: 'Apr', y: 0 },
				{ x: 'May', y: 0 },
				{ x: 'Jun', y: 0 },
				{ x: 'Jul', y: 0 },
				{ x: 'Aug', y: 0 },
				{ x: 'Sep', y: 0 },
				{ x: 'Oct', y: 0 },
				{ x: 'Nov', y: 0 },
				{ x: 'Dec', y: 0 }
			];
		}
		allNeeds?.forEach(item => {
			let createdMonth = dayjs(item.created_at).format('MMM');
			let curItem = localLineChartData?.filter(
				item => item.x === createdMonth
			)[0];
			curItem.y += 1;
		});
		setLineCahrtData(localLineChartData);
	}, [allNeeds]);

	const data = useMemo(() => {
		if (lineChartData) {
			const x = [];
			const y = [];
			lineChartData.forEach(d => {
				x.push(d['x']);
				y.push(d['y']);
			});
			const localData = {
				labels: x,
				datasets: [
					{
						label: '',
						fill: false,
						data: y,
						borderColor: '#666dff',
						backgroundColor: '#666dff'
					}
				]
			};
			return localData;
		}
	}, [lineChartData]);
	return (
		<>
			<Chart
				type="line"
				data={data}
				showLegend={false}
				titleText="Number of added needs per month"
				yAxisOptions={Y_AXIS_OPTIONS}
				xAxisOptions={X_AXIS_OPTIONS}
				lineBorderWidth={2}
				pointBorderWidth={1}
				pointRadius={4}
			/>
		</>
	);
};

export default LineChart;
