import CompleteRegistration from './CompleteRegistration/CompleteRegistration';
import Error404 from './Error404/Error404';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ImpactReports from './ImpactReports/ImpactReports';
import Login from './Login/Login';
import Register from './Register/Register';
import BusinessPayment from './BusinessPayment/BusinessPayment';
import SucessPayment from './SuccessPayment/SuccessPayment';
import CancelPayment from './CancelPayment/CancelPayment';
import AddNewEmployee from '../SuperAdmin/AddNewEmployee';
import SuggestToRegister from './SuggestToRegister';
import CharitySignup from './CharitySignup';
import EmployeeRegistration from './EmployeeRegistration/EmployeeRegistration';
import VoteLandingPage from './VoteLandingPage/VoteLandingPage';
import VoteLandingPageForCustomers from './VoteLandingPageForCustomers/VoteLandingPageForCustomers';
import StripeConnection from './StripeConnection/StripeConnection';
import CharityAdminSignup from './CharityAdminSignup/CharityAdminSignup';

export const routes = [
	{
		path: '/business-complete-registration',
		Component: CompleteRegistration
	},
	{
		path: '/404',
		Component: Error404,
		showNav: false
	},
	{
		path: '/forgot-password',
		Component: ForgotPassword,
		isPrivate: false,
		showNav: false
	},
	{
		path: '/reports/:businessId/:businessKey',
		Component: ImpactReports,
		isPrivate: false
	},
	{ path: '/login', Component: Login, isPrivate: false, showNav: false },
	{
		path: '/login/:platform',
		Component: Login,
		isPrivate: false,
		showNav: false
	},
	{
		path: '/register',
		Component: Register,
		isPrivate: false,
		showNav: false
	},
	{
		path: '/businesses/:businessId/:businessKey/business-payment',
		Component: BusinessPayment,
		isPrivate: true
	},
	{
		path: '/successful-payment',
		Component: SucessPayment,
		isPrivate: true
	},
	{
		path: '/cancel-payment',
		Component: CancelPayment,
		isPrivate: true
	},
	{
		path: '/add-employee/:id/:businessKey',
		Component: AddNewEmployee
	},
	{
		path: '/register-to-vote/:id/:businessKey/:date',
		Component: SuggestToRegister,
		isPrivate: false,
		showNav: false
	},
	{
		path: '/charity-signup',
		Component: CharitySignup,
		isPrivate: false,
		showNav: false
	},
	{
		path: '/employee-register',
		Component: EmployeeRegistration,
		isPrivate: false,
		showNav: false
	},
	{
		path: '/vote/:id/:businessKey/:date',
		Component: VoteLandingPage,
		showNav: false
	},
	{
		path: '/customer-vote/:id/:businessKey/:date',
		Component: VoteLandingPageForCustomers,
		showNav: false
	},
	{
		path: '/connect-stripe',
		Component: StripeConnection,
		isPrivate: false,
		showNav: false
	},
	{
		path: '/register-me-as-charity-admin',
		Component: CharityAdminSignup,
		isPrivate: false,
		showNav: false
	}
];
