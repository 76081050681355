import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	currentReport: {},
	reports: {},
	activeMonths: []
};

export const impactReports = createSlice({
	name: 'impactReports',
	initialState,
	reducers: {
		getImpactReport: (state, action) => {
			state.currentReport = action.payload.data;
		},
		getImpactReports: (state, action) => {
			state.reports = action.payload.data;
		},
		getActiveMonths: (state, action) => {
			state.activeMonths = action.payload.data.activeMonths
				? action.payload.data.activeMonths.sort().reverse()
				: [];
		}
	}
});

export default impactReports.reducer;
export const { getImpactReport, getImpactReports, getActiveMonths } =
	impactReports.actions;
