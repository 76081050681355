import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';
import cx from 'classnames';

import Icon from 'root/baseComponents/Icon';
import Close from 'root/baseComponents/Close';
import Button from 'root/baseComponents/Button';
import { clearPublicModalType } from 'root/reducers/publicModalType';
import { clearModal } from 'root/utils/modal';

import styles from '../Modal.module.scss';

function CharitySignupModal() {
	const dispatch = useDispatch();

	const header = useSelector(state => state.publicModalProps.header);
	const text = useSelector(state => state.publicModalProps.text);

	return (
		<>
			<Div
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.modalContainer
				)}>
				<Div
					className={cx(
						'pos-fix pos-fix--center br-rad-px-5 card w-per-50 max-w-px-700',
						styles.heading
					)}>
					<Div className={cx('p1 bgPrimary')}>
						<Div type="flex" vAlign="center" className="pl1">
							<Icon type="circle-check" color="white" scale={1.5} />
							<Div className="textWhite f-b pl2 ">{header}</Div>
						</Div>
						<Close onClick={() => dispatch(clearPublicModalType())} />
					</Div>
					<Div className="pt1 pl3 pr3 pb2 fs-px-14">{text}</Div>
					<Div type="flex" hAlign="center" className="pb1 w-per-100">
						<Button
							className="mr2"
							colorType="transparent"
							onClick={() => clearModal(dispatch)}>
							OK
						</Button>{' '}
					</Div>
					{console.log('this is a modal')}
				</Div>
			</Div>
		</>
	);
}

export default CharitySignupModal;
