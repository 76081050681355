export const emailValidators = [
	{ type: 'required', message: 'Email is required' },
	{
		type: 'email',
		message: 'Must be a valid email address'
	}
];

export const passwordValidators = [
	{ type: 'required', message: 'Password is required' },
	{
		type: 'minRequired',
		message: 'Password must be at least 6 characters',
		minRequired: 6
	},
	{
		type: 'maxRequired',
		message: 'Password be less than 100 characters',
		maxRequired: 100
	}
];
