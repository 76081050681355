import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import FormDatePicker from 'root/baseComponents/FormDatePicker';

import { getVoteReport } from 'root/services/report';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import styles from '../PurchasesAndSavings.module.scss';

const Votes = () => {
	const dispatch = useDispatch();
	const headLines = [
		{
			value: 'business',
			display: 'Business',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'total_votes',
			display: 'Total Votes',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'from_dashboard',
			display: 'Votes From Dashboard',
			width: 250,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'vote_percentage_from_dashboard',
			display: 'Percentage %',
			width: 175,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'user_actions',
			display: 'Othe User Actions',
			width: 200,
			hasSearch: false,
			isSortable: true
		}
	];

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [reports, setReports] = useState([]);
	const [chosenDate, setChosenDate] = useState(new Date());
	const [chosenDateErrorMessage, setChosenDateErrorMessage] = useState('');
	const [totalVotes, setTotalVotes] = useState(0);
	const [votesFromDashboard, setVotesFromDashboard] = useState(0);
	const [userActions, setUserActions] = useState(0);

	const getVoteReportData = async () => {
		dispatch(setLoading());
		const res = await getVoteReport(dayjs(chosenDate).format('YYYY-MM'));
		dispatch(setLoadingComplete());
		const localReports = [];
		let localTotalVotes = 0;
		let localVotesFromDashboard = 0;
		let localUserActions = 0;
		res?.report?.forEach(item => {
			localTotalVotes += item.total_votes;
			localVotesFromDashboard += item.from_dashboard;
			localUserActions += item.user_actions;

			localReports.push({
				business: item.business_name,
				total_votes: {
					value: item.total_votes,
					display: <Div>{item.total_votes}</Div>
				},
				from_dashboard: {
					value: item.from_dashboard,
					display: <Div>{item.from_dashboard}</Div>
				},
				user_actions: {
					value: item.user_actions,
					display: <Div>{item.user_actions}</Div>
				},
				vote_percentage_from_dashboard: {
					value:
						item.total_votes > 0
							? parseFloat(item.from_dashboard / item.total_votes)?.toFixed(2)
							: '',
					display: (
						<Div>
							{item.total_votes > 0
								? parseFloat(item.from_dashboard / item.total_votes)?.toFixed(
										2
								  ) * 100
								: '-'}
						</Div>
					)
				}
			});
		});
		setReports(localReports);
		setTotalVotes(localTotalVotes);
		setVotesFromDashboard(localVotesFromDashboard);
		setUserActions(localUserActions);
	};

	useEffect(() => {
		if (chosenDate) {
			getVoteReportData();
		}
	}, [chosenDate]);

	const data = useMemo(() => {
		if (reports) {
			console.log(reports);
			const tableData = [];
			reports.forEach(item => {
				tableData.push({
					business: item.business,
					total_votes: item.total_votes,
					from_dashboard: item.from_dashboard,
					vote_percentage_from_dashboard: item.vote_percentage_from_dashboard,
					user_actions: item.user_actions
				});
			});

			return tableData;
		}
	}, [reports]);

	useEffect(() => {
		console.log(dayjs(chosenDate).format('YYYY-MM'));
	}, [chosenDate]);

	return (
		<>
			<Div className="w-per-80 max-w-px-300 pr2 pl2">
				<FormDatePicker
					chosenDate={chosenDate}
					setChosenDate={setChosenDate}
					errorMessage={chosenDateErrorMessage}
					errorHandler={setChosenDateErrorMessage}
					labelText="Date:"
					dateFormat="MM-yyyy"
				/>
			</Div>
			<Div className="w-per-80 ml2 mr2 mb2 p2 bgWhite card">
				<Div type="flex" className="my1">
					<Div className="w-px-350">Total Votes</Div>
					<Div>{totalVotes}</Div>
				</Div>
				<Div type="flex" className="my1">
					<Div className="w-px-350">All Votes From Dashboard</Div>
					<Div>{votesFromDashboard}</Div>
				</Div>
				<Div type="flex" className="my1">
					<Div className="w-px-350">Pecentage of Votes From Dashboard %</Div>
					<Div>
						{totalVotes > 0
							? parseFloat(votesFromDashboard / totalVotes) * 100
							: '-'}
					</Div>
				</Div>
				<Div type="flex" className="my1">
					<Div className="w-px-350">Total Other User Actions</Div>
					<Div>{userActions}</Div>
				</Div>
			</Div>
			<Div className="w-per-80 pr2 pl2 flex flex--jc--start">
				<Table
					headLines={headLines}
					data={data}
					// colWidth={400}
					// tableWidth={'2000px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={20}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
};

export default Votes;
