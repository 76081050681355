import dayjs from 'dayjs';

const extractDataFromAdmin = (
	data,
	setTotalBusinesses,
	setTotalEmployees,
	setMrr,
	setNeedContribution,
	setEngagement
) => {
	const today = dayjs();
	// because dayjs month index starts at 0 we can just
	// use current month to represent previous month
	const previousMonth = today.month();
	const year = today.year();

	let previousMonthData = null;

	if (previousMonth < 10) {
		previousMonthData = data[`${year}_0${previousMonth}`];
	} else {
		previousMonthData = data[`${year}_${previousMonth}`];
	}

	if (previousMonthData) {
		setTotalBusinesses(previousMonthData['list'].length);
		setTotalEmployees(previousMonthData['sum_total_employees']);
		setMrr(previousMonthData['sum_troop_revenue']);
		setNeedContribution(previousMonthData['sum_need_contribution']);
		const totalEmployees = previousMonthData['sum_total_employees'];
		const totalVotes = previousMonthData['sum_total_votes'];
		setEngagement(parseFloat(totalVotes / totalEmployees).toFixed(2));
	}
};

export { extractDataFromAdmin };
