import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import slugify from 'slugify';
import { slugifyOptions } from 'root/constants/plugins';
import {
	Card,
	Row,
	Col,
	Form,
	Input,
	Switch,
	Button,
	Alert,
	Select,
	Heading
} from 'troop-design';
import { addFeatureFlag, updateFeatureFlag } from 'root/services/featureFlags';
import styles from './FeatureFlags.module.scss';

export default function FeatureFlagForm({
	editMode,
	feature,
	employees,
	businesses,
	onSubmit
}) {
	const dispatch = useDispatch();
	const [type, setType] = useState('');
	const [data, setData] = useState(
		feature || {
			name: '',
			description: '',
			flag: '',
			enabled: false,
			conditions: []
		}
	);
	const [conditions, setConditions] = useState([]);
	const [enabled, setEnabled] = useState(feature?.enabled);

	useEffect(() => {
		if (feature?.conditions) {
			const localConditions = []
			feature.conditions.forEach((item) => {
				const curCond = {}
				console.log(item)
				curCond["conditionRule"] = item.conditionRule
				curCond["id"] = item.id
				curCond["options"] = {
					type: item.options.type,
					value: [...item.options.value]
				}
				localConditions.push({...curCond})
			})
			console.log(localConditions)
			setConditions([...localConditions])
		}
	}, [feature])

	/**
	 * Condition structure:
	 * {
	 *    type: 'include',
	 *    id: 234cgf,
	 *    options: {
	 *       type: 'user',
	 *       value: [1,2,3]
	 *   }
	 * }
	 */

	const flagTypes = ['user', 'business'];
	const additionalFlagTypes = flagTypes.filter(type => type !== 'app');

	const handleSlugify = useCallback(e => {
		const slug = slugify(e.target.value, slugifyOptions);
		document.getElementById('flag').value = slug;
	}, []);

	const handleClick = e => {
		e.preventDefault();
		setConditions([
			...conditions,
			{
				conditionRule: 'include',
				id: uuid(),
				options: { type: 'user', value: [] }
			}
		]);
	};

	const handleConditionSet = (type, id, target) => {
		let value = target.value;
		if (type === 'value') {
			value = [];
			target
				?.querySelectorAll('option:checked')
				?.forEach(entry => value.push(entry.value));
		}
		const newConditions = [...conditions];
		const condition = newConditions.find(entry => entry.id === id);
		if (type === 'rule') {
			condition.conditionRule = value;
		} else {
			condition.options[type] = value;
		}
		setConditions(newConditions);
	};

	const handleSubmit = values => {
		const dataToSubmit = {
			name: values.name,
			description: values.description,
			flag: values.flag,
			enabled: !!values.enabled,
			conditions: [...conditions]
		};
		setConditions([]);
		setData({
			name: '',
			description: '',
			flag: '',
			enabled: false,
			conditions: []
		});
		if (editMode) {
			dispatch(updateFeatureFlag(data.id, dataToSubmit));
		} else {
			dispatch(addFeatureFlag(dataToSubmit));
		}
	};

	const renderEmployeesOptions = (id, value) => {
		return (
			<Select
				className={cx(styles.type, styles.multiSelect)}
				name={`conditionUsers${id}`}
				label="Select Users"
				value={value}
				onChange={e => handleConditionSet('value', id, e.target)}
				multiple>
				{employees.map(user => (
					<option key={user.id} value={user.id}>
						{user.user.first_name} {user.user.last_name}
					</option>
				))}
			</Select>
		);
	};

	const renderBusinessesOptions = (id, value) => {
		return (
			<Select
				className={cx(styles.type, styles.multiSelect)}
				name={`conditionBusinesses${id}`}
				label="Select Businesses"
				value={value}
				onChange={e => handleConditionSet('value', id, e.target)}
				multiple>
				{businesses.map(business => (
					<option key={business.id} value={business.id}>
						{business.name}
					</option>
				))}
			</Select>
		);
	};

	const renderCondition = (condition, index) => {
		const { conditionRule, type, id, options } = condition;
		const { type: conditionType, value } = options;
		return (
			<Row key={id} className={cx(index % 2 === 0 && styles.conditionEven)}>
				<Col xs={12}>
					<Heading level={2} className={styles.conditionHeading}>
						{`Condition ${index + 1}`}
						<span className={styles.removeCondition}>
							<Button
								className={styles.removeCondition}
								onClick={e => removeCondition(e, id)}
								variant="danger"
								size="xs">
								Remove
							</Button>
						</span>
					</Heading>
				</Col>
				<Col md={6}>
					<Select
						className={styles.type}
						name={`conditionRule${id}`}
						label="Condition Rule"
						value={conditionRule}
						onChange={e => handleConditionSet('rule', id, e.target)}>
						<option value="include">Include</option>
						<option value="exclude">Exclude</option>
					</Select>
				</Col>
				<Col md={6}>
					<Select
						className={cx(styles.type)}
						name={`conditionType${id}`}
						label="Condition Type"
						value={conditionType}
						onChange={e => handleConditionSet('type', id, e.target)}>
						{flagTypes.map(flagType => (
							<option key={flagType} value={flagType}>
								{flagType}
							</option>
						))}
					</Select>
				</Col>
				<Col md={12}>
					{conditionType === 'user' && renderEmployeesOptions(id, value)}
					{conditionType === 'business' && renderBusinessesOptions(id, value)}
				</Col>
			</Row>
		);
	};

	const removeCondition = (e, id) => {
		e.preventDefault();
		const newConditions = [...conditions];
		newConditions.splice(
			newConditions.findIndex(entry => entry.id === id),
			1
		);
		setConditions(newConditions);
	};

	const renderConditions = () =>
		conditions.map((condition, index) => renderCondition(condition, index));

	return (
		<Card>
			<Form onSubmit={handleSubmit}>
				<Row>
					<Col md={6}>
						<Input name="name" label="Name" value={data.name} />
					</Col>
					<Col md={5}>
						<Input id="flag" name="flag" label="Flag Name" value={data.flag} />
					</Col>
				</Row>
				<Input
					name="description"
					label="Description"
					value={data.description}
					type="multi"
				/>
				<Row>
					<Col md={10}>
						{type === 'app' && (
							<Alert type="info" dismissable={false}>
								If the type is app, the flag will take effect for anyone and
								everyone.
							</Alert>
						)}
						{type === 'user' && (
							<Alert type="info" dismissable={false}>
								This will enable the feature to the selected users below.
							</Alert>
						)}
						{type === 'business' && (
							<Alert type="info" dismissable={false}>
								This will enable the feature to the users of the selected
								businesses below.
							</Alert>
						)}
					</Col>
				</Row>
				{renderConditions()}
				<div className={styles.formAction}>
					<Button
						className="me-4"
						variant="secondary"
						type="submit"
						onClick={handleClick}>
						Add condition
					</Button>
					<Button type="submit" variant="primary" className="mb-4">
						Submit
					</Button>
				</div>
			</Form>
		</Card>
	);
}

FeatureFlagForm.defaultProps = {
	data: {}
};
