import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setLoading'] = (state, action) => true;
reducerObject['setLoadingComplete'] = (state, action) => false;

const slice = createSlice({
	name: 'loaders',
	initialState: false,
	reducers: reducerObject
});

export const { setLoading, setLoadingComplete } = slice.actions;
export default slice.reducer;
