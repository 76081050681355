import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { Div } from 'basedesign-iswad';
import { Text } from 'troop-design';

import { hasEmployeeVoted } from 'services/landingPages';
import { getOnVotingNeeds, getBusinessVotedMonths } from 'root/services/needs';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import EmployeeVoteCard from './subs/EmployeeVoteCard';

import { headingInCompactedForm, headingInDetialedForm } from './utils';
import Loading from './subs/Loading';

import styles from './EmployeeVoteSection.module.scss';

moment.tz.setDefault('America/New_York');

const EmployeeVoteSection = ({ isCompacted = false }) => {
	const dispatch = useDispatch();
	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;
	const email = useSelector(state => state?.users?.loggedInUser?.email);

	const [activedBusinessNeeds, setActivedBusinessNeeds] = useState([]);
	const [activeNeeds, setActiveNeeds] = useState([]);
	const [monthDate, setMonthDate] = useState('');
	const [voteNotStartedYet, setVoteNotStartedYet] = useState(false);
	const [voteIsOpen, setVoteIsOpen] = useState(false);
	const [employeeVoted, setEmployeeVoted] = useState(false);
	const [votedNeedId, setVotedNeedId] = useState(null);
	const [showFullText, setShowFullText] = useState(false);
	const [votedMonths, setVotedMonths] = useState([]);
	const [curMonth, setCurMonth] = useState('');

	const getOnVotingNeedsData = async () => {
		// dispatch(setLoading());
		try {
			let res;
			if (curMonth) {
				res = await getOnVotingNeeds(curMonth);
			} else {
				res = await getOnVotingNeeds();
			}

			// dispatch(setLoadingComplete());
			setActivedBusinessNeeds(res);
		} catch (err) {
			// dispatch(setLoadingComplete());
			console.log(err);
		}
	};

	const getBusinessVotedMonthsData = async () => {
		dispatch(setLoading());
		try {
			const res = await getBusinessVotedMonths(businessId);
			dispatch(setLoadingComplete());
			const localMonths = [];
			res?.forEach(item => {
				localMonths.push(item.monthDate);
			});
			setVotedMonths(localMonths);
			setCurMonth(localMonths?.[0]);
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
		}
	};

	const getEmployeeVote = async () => {
		if (businessId && email) {
			try {
				const localHasVoted = await hasEmployeeVoted(
					email,
					businessId,
					curMonth
				);
				if (localHasVoted && localHasVoted?.has_voted) {
					setEmployeeVoted(localHasVoted.has_voted);
					setVotedNeedId(localHasVoted['voted_business_need_id']);
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	useEffect(() => {
		if (businessId) {
			getBusinessVotedMonthsData();
		}
	}, [businessId]);

	useEffect(() => {
		getOnVotingNeedsData();
	}, [curMonth]);

	useEffect(() => {
		getEmployeeVote();
	}, [businessId, email, curMonth]);

	useEffect(() => {
		if (activedBusinessNeeds) {
			let localNeeds = [];
			activedBusinessNeeds.map(need => {
				if (!localNeeds.includes(need.monthDate)) {
					localNeeds.push(need.monthDate);
				}
			});
		}
	}, [activedBusinessNeeds]);

	useEffect(() => {
		if (activedBusinessNeeds) {
			let localNeeds = [...activedBusinessNeeds];

			if (localNeeds.length >= 3) {
				setMonthDate(localNeeds[0].monthDate);
				if (
					moment().isBefore(moment(localNeeds[0].vote_opened_date)) &&
					moment(curMonth).isSame(moment(localNeeds[0].monthDate))
				) {
					setVoteNotStartedYet(true);
				} else if (
					moment().isBefore(
						moment(
							`${localNeeds[0].vote_close_date} ${localNeeds[0].vote_close_time}`
						)
					) &&
					moment().isAfter(moment(localNeeds[0].vote_opened_date))
				) {
					setVoteNotStartedYet(false);
					setVoteIsOpen(true);
				} else {
					setVoteNotStartedYet(false);
				}
			}
			setActiveNeeds(localNeeds);
		}
	}, [curMonth, activedBusinessNeeds]);

	return (
		<>
			<div className={cx(styles.voteSectionRow)}>
				<div className={cx(styles.container)}>
					<div
						className={cx(
							'p1 employeeHeading',
							styles.head,
							isCompacted && styles.headIsCompacted
						)}>
						{isCompacted
							? headingInCompactedForm(
									cx,
									styles,
									moment,
									monthDate,
									voteIsOpen,
									activeNeeds,
									Text
							  )
							: headingInDetialedForm(
									cx,
									styles,
									moment,
									votedMonths,
									setCurMonth
							  )}
					</div>
					<div
						className={cx(
							'flex--gr--1 w-per-100',
							activeNeeds?.length > 0 ? styles.body : 'flex flex--dir--col'
						)}>
						{
							!voteNotStartedYet && activeNeeds?.length > 0
								? activeNeeds.map(need => (
										<EmployeeVoteCard
											key={need.id}
											need={need}
											businessId={businessId}
											voteIsOpen={voteIsOpen}
											employeeVoted={employeeVoted}
											setEmployeeVoted={setEmployeeVoted}
											votedNeedId={votedNeedId}
											setVotedNeedId={setVotedNeedId}
											monthDate={monthDate}
											isCompacted={isCompacted}
											showFullText={showFullText}
											setShowFullText={setShowFullText}
										/>
								  ))
								: ''
							// <Div
							// 	type="flex"
							// 	direction="vertical"
							// 	hAlign="center"
							// 	vAlign="center"
							// 	className="w-per-100 bgWhite boxShaodwType1 flex--gr--1 height-per-100">
							// 	<Div className="fs-px-12 f-b">
							// 		Processing the current month's votes ...
							// 	</Div>
							// 	<Loading />
							// </Div>
						}
					</div>
					{voteNotStartedYet && (
						<Div
							type="flex"
							className={cx(
								'card flex--gr--1 p2 w-per-100',
								styles.emptyContainer
							)}>
							There is no active vote for this month.
						</Div>
					)}
				</div>
			</div>
		</>
	);
};

export default EmployeeVoteSection;
