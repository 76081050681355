import React, { useMemo } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Heading, Text } from 'troop-design';
import { Div, Column, Card } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';

import styles from '../Home.module.scss';

export default function FulfilledNeedCard(props) {
	const fulfillDate = useMemo(() => {
		return props.fulfillDate != null
			? dayjs(props.fulfillDate).format('MMM YYYY')
			: '';
	}, [props.fulfillDate]);

	return (
		<>
			<Card className="card w-per-100  mb2 p1">
				<Div type="flex">
					<Column xs={12} sm={12} md={8} lg={8}>
						<Div
							type="flex"
							direction="vertical"
							className={cx(styles.fulfilledCardFirstColumn)}
							distributedBetween>
							<Div className=''>
								<Div
									type="flex"
									className="fs-px-14 f-b pt1 mr2 ml1">{`${props.needHeading}`}</Div>
								<Div className="fs-px-12 ml1 ">{props.needType}</Div>
								{/* <Div className="fs-px-14">{props.communitySupported}</Div> */}
							</Div>
							<Div type="flex" vAlign="end" className="fs-px-12 pb1 ml1">
								{fulfillDate}
							</Div>
						</Div>
					</Column>

					<Column xs={12} sm={12} md={4} lg={4} className="ml1">
						<Div type="flex" direction="vertical">
							<Div className="mb2">
								<Div type="flex" hAlign="end" className="fs-px-12 pt1">
									Status:{' '}
									<Div
										type="flex"
										hAlign="end"
										className="ml1 fs-px-12 textOrange">
										{props.isFulfilled ? 'Fulfilled' : 'Processing'}
									</Div>
								</Div>
								{/* <Div
									type="flex"
									hAlign="end"
									vAlign="start"
									className="textGray fs-px-12">
									Votes:
								</Div> */}
							</Div>
							{/* <Div type="flex" hAlign="end" className="fs-px-12 ml1">
								<Button
									isSmall={true}
									className={cx(
										'max-height-px-30 flex flex--jc--center flex--ai--center',
										styles.cardButton
									)}>
									Vote Details
								</Button>
							</Div> */}
						</Div>
					</Column>
				</Div>
			</Card>
		</>
	);
}
