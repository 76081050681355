import React from 'react';
import cx from 'classnames';
import {Div} from "basedesign-iswad"

import { useParams, useLocation } from 'react-router';

import SignUpOrLogin from 'components/SignUpOrLogin';

import styles from './Login.module.scss';

const Login = () => {
	const location = useLocation();
	return (
		<>
			<Div className={cx(styles.formContainer)}>
			<SignUpOrLogin
				isRegisterPage={false}
				ctaWord="Login"
				headingWord="Login"
			/>
			</Div>
		</>
	);
};

export default Login;
