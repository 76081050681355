import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	needs: [],
	businessNeeds: [],
	fulfilledNeeds: [],
	toBePledgedNeed: {},
	pledgeAmount: 0,
	changeThanksMessage: false,
	toBePledgedNeedAfterCheckout: {}
};

export const needsSlice = createSlice({
	name: 'needs',
	initialState,
	reducers: {
		getNeeds: (state, action) => {
			state.needs = action.payload;
		},
		getBusinessNeeds: (state, action) => {
			state.businessNeeds = action.payload;
		},
		getFulfilledNeeds: (state, action) => {
			state.fulfilledNeeds = action.payload;
		},
		addNeed: (state, action) => {
			state.needs.push(action.payload);
		},
		updateNeed: (state, action) => {
			const index = state.needs.findIndex(
				employee => employee.id === parseInt(action.payload.id, 10)
			);
			state.needs[index] = action.payload.data;
		},
		deleteNeed: (state, action) => {
			state.needs = state.needs.filter(
				employee => employee.id !== parseInt(action.payload, 10)
			);
		},
		pledgeNeed: (state, action) => {
			state.toBePledgedNeed = action.payload;
		},
		clearPledgeNeed: (state, action) => {
			state.toBePledgedNeed = {};
		},
		pledgeAmount: (state, action) => {
			state.pledgeAmount = action.payload;
		},
		changeContentOfThanksMessage: (state, action) => {
			state.changeThanksMessage = true;
		},
		doNotChangeContentOfThanksMessage: (state, action) => {
			state.changeThanksMessage = false;
		},
		setToBePledgedNeedAfterCheckout: (state, action) => {
			state.toBePledgedNeedAfterCheckout = action.payload;
		},
		clearToBePledgedNeedAfterCheckout: (state, action) => {
			state.toBePledgedNeedAfterCheckout = {};
		}
	}
});

export default needsSlice.reducer;
export const {
	getNeeds,
	getBusinessNeeds,
	getFulfilledNeeds,
	addNeed,
	updateNeed,
	deleteNeed,
	pledgeNeed,
	clearPledgeNeed,
	pledgeAmount,
	changeContentOfThanksMessage,
	doNotChangeContentOfThanksMessage,
	setToBePledgedNeedAfterCheckout,
	clearToBePledgedNeedAfterCheckout
} = needsSlice.actions;
