import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBusinesses } from 'services/businesses';
import useLoggedIn from './useLoggedIn';
import useSuperAdminLoggedIn from './useSuperAdminLoggedIn';

export default function useCurrentBusiness() {
	const loggedIn = useLoggedIn();
	const isSuperAdmin = useSuperAdminLoggedIn();
	const dispatch = useDispatch();

	const currentBusiness = useSelector(
		state => state.businesses.businesses?.[0]
	);

	useEffect(() => {
		if (!currentBusiness) {
			dispatch(getBusinesses());
		}
	}, []);

	if (isSuperAdmin) {
		return false;
	}

	return loggedIn && currentBusiness;
}
