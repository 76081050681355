export const COLORS = {
	primary: '#f5891f',
	faded: '#707070',
	info: '#0fafa3',
	inverse: '#f3f3f3',
	warning: '#feba13',
	success: '#0fafa3',
	danger: '#c24c4a',
	grayBright: '#f3f3f3',
	grayDark: '#777'
};

export const AUTOMATIC_REMOVE_ALERT_TIME_IN_SECONDS = 10;
