import React, { useMemo } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import styles from '../Home.module.scss';
import { Div, Column, Card } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';

export default function VotingNeed(props) {
	const fulfillDate = useMemo(() => {
		return props.fulfillDate != null
			? dayjs(props.fulfillDate).format('MMM YYYY')
			: '';
	}, [props.fulfillDate]);
	return (
		<>
			<Card className="card br-rad-px-5  w-per-100 mb2 pt1 pb1 pr1 pl3">
				<Div type="flex">
					<Column xs={12} sm={12} md={8} lg={8}>
						<Div className=''>
							<Div className="fs-px-14 f-b">{props.need?.need_name}</Div>
							<Div className="fs-px-12 mb2">{props.need?.charity_name}</Div>
						</Div>
						<Div className="fs-px-12 mb2">{fulfillDate}</Div>
					</Column>

					<Column xs={12} sm={12} md={4} lg={4} className="ml1">
						<Div type="flex" direction="vertical">
							<Div type="flex" hAlign="end" className="fs-px-14">
								Status:
								<Div className="ml1 fs-px-14 textOrange">
									{' '}
									{props.isFulfilled ? 'Fulfilled' : 'Voting'}
								</Div>
							</Div>
							<Div type="flex" hAlign="end" vAlign="start" className="fs-px-12">
								Votes: {props.need?.total_need_votes}
							</Div>
						</Div>
					</Column>
				</Div>
			</Card>
		</>
	);
}
