import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEye,
	faImage,
	faCalendarDays,
	faClose,
	faAngleRight,
	faAngleLeft,
	faDashboard,
	faHeart,
	faGear,
	faCirclePlus,
	faSignOut,
	faUsers,
	faPencil,
	faCircleInfo,
	faCircleExclamation,
	faReceipt,
	faCheck,
	faPlusCircle,
	faMinusCircle,
	faAngleDoubleLeft,
	faAngleDoubleRight,
	faAddressCard,
	faBell,
	faCircleCheck,
	faCircleXmark,
	faCircleMinus,
	faGift,
	faCheckToSlot,
	faLocationDot,
	faHandHoldingDollar,
	faClock,
	faGlobe,
	faLock,
	faPenToSquare,
	faTrashCan,
	faCopy,
	faPause,
	faPlay,
	faArrowsRotate,
	faEnvelope,
	faCalendarDay,
	faCalendarXmark,
	faUpRightFromSquare,
	faFileLines,
	faUserGraduate,
	faUser,
	faLink
} from '@fortawesome/free-solid-svg-icons';
import {
	faSquareInstagram,
	faJs,
	faCss3Alt,
	faLinkedin,
	faTwitter,
	faFacebook
} from '@fortawesome/free-brands-svg-icons';

import Star from '../../assets/icons/star.svg';
import StarFill from '../../assets/icons/star-fill.svg';
import Arrow from '../../assets/icons/arrow.svg';
import Calendar from '../../assets/icons/calendar.svg';
import Pdf from '../../assets/icons/pdf.svg';
import Exclamation from '../../assets/icons/exclamation.svg';

function Icon({ type, color, width, scale, iconClassName }) {
	let iconTypes = {
		refresh: (
			<FontAwesomeIcon
				icon={faArrowsRotate}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'arrow-right-up': (
			<FontAwesomeIcon
				icon={faUpRightFromSquare}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		lock: (
			<FontAwesomeIcon
				icon={faLock}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		pause: (
			<FontAwesomeIcon
				icon={faPause}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		play: (
			<FontAwesomeIcon
				icon={faPlay}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		edit: (
			<FontAwesomeIcon
				icon={faPenToSquare}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		copy: (
			<FontAwesomeIcon
				icon={faCopy}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		delete: (
			<FontAwesomeIcon
				icon={faTrashCan}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		eye: (
			<FontAwesomeIcon
				icon={faEye}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'user-card': (
			<FontAwesomeIcon
				icon={faAddressCard}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		pencil: (
			<FontAwesomeIcon
				icon={faPencil}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		gift: (
			<FontAwesomeIcon
				icon={faGift}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		globe: (
			<FontAwesomeIcon
				icon={faGlobe}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		calendar: (
			<FontAwesomeIcon
				icon={faCalendarDays}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'calendar-day': (
			<FontAwesomeIcon
				icon={faCalendarDay}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'calendar-xmark': (
			<FontAwesomeIcon
				icon={faCalendarXmark}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		envelope: (
			<FontAwesomeIcon
				icon={faEnvelope}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		file: (
			<FontAwesomeIcon
				icon={faFileLines}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'hand-dollar': (
			<FontAwesomeIcon
				icon={faHandHoldingDollar}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'circle-minus': (
			<FontAwesomeIcon
				icon={faCircleMinus}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'circle-xmark': (
			<FontAwesomeIcon
				icon={faCircleXmark}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'circle-check': (
			<FontAwesomeIcon
				icon={faCircleCheck}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'circle-info': (
			<FontAwesomeIcon
				icon={faCircleInfo}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'circle-exclamation': (
			<FontAwesomeIcon
				icon={faCircleExclamation}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		users: (
			<FontAwesomeIcon
				icon={faUsers}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'image-upload': (
			<FontAwesomeIcon
				icon={faImage}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'sign-out': (
			<FontAwesomeIcon
				icon={faSignOut}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		link: (
			<FontAwesomeIcon
				icon={faLink}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		vote: (
			<FontAwesomeIcon
				icon={faCheckToSlot}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		close: (
			<FontAwesomeIcon
				icon={faClose}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		clock: (
			<FontAwesomeIcon
				icon={faClock}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'angle-right': (
			<FontAwesomeIcon
				icon={faAngleRight}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'angle-left': (
			<FontAwesomeIcon
				icon={faAngleLeft}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'angle-up': (
			<FontAwesomeIcon
				icon={faAngleLeft}
				style={{ color, width, transform: `rotate(90deg)` }}
			/>
		),
		'angle-down': (
			<FontAwesomeIcon
				icon={faAngleLeft}
				style={{ color, width, transform: `rotate(-90deg)` }}
			/>
		),
		'dashboard-fill': (
			<FontAwesomeIcon
				icon={faDashboard}
				style={{ color, width, transform: `scale(${scale})` }}
				className={iconClassName}
			/>
		),
		'heart-fill': (
			<FontAwesomeIcon
				icon={faHeart}
				style={{ color, width, transform: `scale(${scale})` }}
				className={iconClassName}
			/>
		),
		gear: (
			<FontAwesomeIcon
				icon={faGear}
				style={{ color, width, transform: `scale(${scale})` }}
				className={iconClassName}
			/>
		),
		'circle-plus': (
			<FontAwesomeIcon
				icon={faCirclePlus}
				style={{ color, width, transform: `scale(${scale})` }}
				className={iconClassName}
			/>
		),
		receipt: (
			<FontAwesomeIcon
				icon={faReceipt}
				style={{ color, width, transform: `scale(${scale})` }}
				className={iconClassName}
			/>
		),
		check: (
			<FontAwesomeIcon
				icon={faCheck}
				style={{ color, width, transform: `scale(${scale})` }}
				className={iconClassName}
			/>
		),
		linkedin: (
			<FontAwesomeIcon
				icon={faLinkedin}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'instagram-fill': (
			<FontAwesomeIcon
				icon={faSquareInstagram}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		twitter: (
			<FontAwesomeIcon
				icon={faTwitter}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		facebook: (
			<FontAwesomeIcon
				icon={faFacebook}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		location: (
			<FontAwesomeIcon
				icon={faLocationDot}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'plus-circle': (
			<FontAwesomeIcon
				icon={faPlusCircle}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		'minus-circle': (
			<FontAwesomeIcon
				icon={faMinusCircle}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		angleDoubleLeft: (
			<FontAwesomeIcon
				icon={faAngleDoubleLeft}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		angleDoubleRight: (
			<FontAwesomeIcon
				icon={faAngleDoubleRight}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		bell: (
			<FontAwesomeIcon
				icon={faBell}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		date: (
			<FontAwesomeIcon
				icon={faCalendarDays}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		admin: (
			<FontAwesomeIcon
				icon={faUserGraduate}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		),
		user: (
			<FontAwesomeIcon
				icon={faUser}
				style={{ color, width, transform: `scale(${scale})` }}
			/>
		)
	};

	iconTypes['star'] = (
		<Star
			fill={color}
			stroke={color}
			style={{ transform: `scale(${scale})` }}
			stroke-width={0.3}
		/>
	);
	iconTypes['pdf'] = (
		<Pdf
			fill={'red'}
			stroke={'red'}
			style={{ transform: `scale(${scale})` }}
			stroke-width={0.2}
		/>
	);
	iconTypes['arrow'] = (
		<Arrow
			fill={color}
			stroke={color}
			style={{ transform: `scale(${scale})` }}
			stroke-width={0.3}
		/>
	);
	iconTypes['calendar'] = (
		<Calendar
			fill={color}
			stroke={color}
			style={{ transform: `scale(${scale})` }}
			stroke-width={0.3}
		/>
	);
	iconTypes['pdf'] = (
		<Pdf
			fill={'red'}
			stroke={'red'}
			style={{ transform: `scale(${scale})` }}
			stroke-width={0.3}
		/>
	);
	iconTypes['exclamation'] = (
		<Exclamation
			fill={color}
			stroke={color}
			style={{ transform: `scale(${scale})` }}
			stroke-width={0.3}
		/>
	);
	iconTypes['star-fill'] = (
		<StarFill
			fill={color}
			stroke={'black'}
			style={{ transform: `scale(${scale})` }}
			stroke-width={0.3}
		/>
	);

	return <>{iconTypes[type]}</>;
}

Icon.propTypes = {
	type: PropTypes.oneOf([
		'arrow',
		'angle-up',
		'angle-down',
		'angle-left',
		'angle-right',
		'bell',
		'users',
		'close',
		'calendar',
		'check',
		'circle-check',
		'circle-exclamation',
		'circle-info',
		'circle-xmark',
		'copy',
		'eye',
		'edit',
		'envelope',
		'dashboard',
		'delete',
		'date',
		'file',
		'image-upload',
		'gift',
		'circle-minus',
		'star',
		'star-fill',
		'dashboard-fill',
		'heart-fill',
		'gear',
		'circle-plus',
		'calendar',
		'pdf',
		'clock',
		'pencil',
		'pause',
		'play',
		'sign-out',
		'star-fill',
		'instagram-fill',
		'linkedin',
		'link',
		'location',
		'lock',
		'twitter',
		'facebook',
		'plus-circle',
		'minus-circle',
		'angleDoubleLeft',
		'angleDoubleRight',
		'vote',
		'user-card',
		'globe',
		'refresh',
		'calendar-day',
		'calendar-xmark',
		'arrow-right-up',
		'admin',
		'user'
	]),
	color: PropTypes.string,
	width: PropTypes.string
};

Icon.defaultProps = {
	type: 'dashboard',
	color: 'black',
	width: '16px'
};

export default Icon;
