import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Div, Heading } from 'basedesign-iswad';

import Anchor from '../../Anchor';

import { MENU_ITEMS, MENU_ITEMS2 } from '../constants';

import styles from '../Footer.module.scss';

const LinksSection = () => {
	return (
		<>
			<Div className="fs-px-14">
				<Div type="flex" hAlign="center" className="my3">
					<Div className="mx8">
						{MENU_ITEMS?.map((item, idx) => (
							<Div key={idx} type="flex" vAlign="center" className="mt1">
								<Div className="mouse-hand">
									<Anchor
										type={2}
										toWordPress={item.toWordpress}
										to={item.to}
										isLocal={item.isLocal}>
										{item.title}
									</Anchor>
								</Div>
							</Div>
						))}
					</Div>

					<Div className="">
						{MENU_ITEMS2?.map((item, idx) => (
							<Div key={idx} type="flex" vAlign="center" className="mt1">
								<Div className="mouse-hand">
									<Anchor
										type={2}
										toWordPress={item.toWordpress}
										to={item.to}
										isLocal={item.isLocal}>
										{item.title}
									</Anchor>
								</Div>
							</Div>
						))}
					</Div>
				</Div>
			</Div>
		</>
	);
};

export default LinksSection;
