import React from 'react';
import cx from 'classnames';
import { Div, Row, Column } from 'basedesign-iswad';

import Modal from 'root/baseComponents/Modal/Modal';

import SignupForm from './subs/SignupForm';
import logo from 'assets/black-logo.png';
import signup from 'assets/login.png';
import styles  from './CharityAdminSignup.module.scss';

const CharityAdminSignup = () => {
	return (
		<>
			{/* <Modal /> */}
			<Div className={cx(styles.container)}>
			<Modal />
			<Row className=''>
			<Column xs={0} sm={0} md={7} lg={7}>
				<Div type='flex' hAlign='end' >
					<Div className={cx('max-w-px-600 boxShadowType1',styles.imageContainer)}>
					<img  src={signup} />
					</Div>
				</Div>
			</Column>
			<Column xs={0} sm={0} md={5} lg={5}>
				<Div showIn={['md', 'lg']} type='flex' hAlign='start' vAlign='start' className='bgWhite boxShadowType1'>
					<Div className="w-per-100 p2 max-w-px-500">
						<Div type='flex' hAlign='center' vAlign='center' className='mb3 f-b textInfo fs-px-20'>
						<Div>
							<img className={cx(styles.troopLogo)} src={logo} />
							</Div>
							<Div>Charity Admin Signup </Div>				
						</Div>
						<Div >
							<SignupForm />
						</Div>
					</Div>
				</Div>
			</Column>
			</Row>

			</Div>
			<Div showIn={['xs', 'sm']} type='flex' hAlign='center' vAlign='center' className=' br-rad-px-10 w-per-100 bgWhite boxShadowType1 mr2 ml2'>
					<Div className="w-per-100 p2 max-w-px-500">
						<Div type='flex' hAlign='center' vAlign='center' className='mb3 f-b textInfo fs-px-20'>
						<Div>
							<img className={cx(styles.troopLogo)} src={logo} />
							</Div>
							<Div>Charity Admin Signup </Div>				
						</Div>
						<Div className={cx()}>
							<SignupForm />
						</Div>
					</Div>
			</Div>
		</>
	);
};

export default CharityAdminSignup;
