import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { Card } from 'troop-design';
import { Div, Row, Column, Heading, Input } from 'basedesign-iswad';

import AddFundModal from '../DoGoodFund/subs/AddFundModal';
import { showModal, hideModal } from 'reducers/modals';

import { set } from 'utils/storage';

import Button from 'root/baseComponents/Button';

import TableRow from './subs/TableRow';

import styles from './PersonalPledging.module.scss';

function PersonalPledging() {
	const dispatch = useDispatch();
	const history = useHistory();

	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [amount, setAmount] = useState(0);
	const [pledgedHistory, setPledgedHistory] = useState([]);

	const handleSubmit = () => {
		set('do-good-amount', amount);
		history.push({
			pathname: '/employee-checkout',
			state: { doGoodAmount: parseFloat(amount) }
		});
	};

	useEffect(() => {
		if (myProfile?.pledgedNeeds) {
			setPledgedHistory(myProfile.pledgedNeeds);
		}
	}, [myProfile]);

	return (
		<>
			<Div
				type="flex"
				direction="vertical"
				className={cx(
					' card flex flex--ai--start py1 br-rad-px-5 flex--jc--center w-per-100',
					styles.mainContainer
				)}>
				<Row className="flex--ai--start">
					{/* className={cx('flex w-per-100 br-bottom-solid-1 br-color-silver')} */}
					<Column
						xs={12}
						sm={12}
						md={6}
						lg={6}
						className={cx(
							'flex flex--jc--center flex--ai--center fs-px-14 my1 br-right-solid-3 br-color-silver'
						)}>
						<Div type="flex" hAlign="center" className="w-per-100 mb3">
							Account Balance
						</Div>
						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className={'mb3 p1 fs-px-26 textSilver'}>
							${(myProfile?.saving || 0)?.toFixed(2) || 0}
						</Div>
						<Div type="flex" hAlign="center">
							<Button
								colorType="transparent"
								className="pt2"
								onClick={() => dispatch(showModal('addFundPopup'))}>
								{' '}
								{'Add Funds >'}
							</Button>
						</Div>
					</Column>
					<Column
						xs={12}
						sm={12}
						md={6}
						lg={6}
						className={cx(
							'flex flex--jc--center flex--ai--center fs-px-14 my1'
						)}>
						<Div type="flex" hAlign="center" className="w-per-100 mb3">
							This Months Pledges
						</Div>
						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className={'p1 fs-px-26 mb3 textSilver'}>
							${(myProfile?.user_active_pledges_amount || 0)?.toFixed(2) || 0}
						</Div>
						<Div type="flex" hAlign="center">
							<Button
								colorType="transparent"
								className="pt2"
								onClick={() => history.push('/employee-profile')}>
								{' '}
								{'See History >'}
							</Button>
						</Div>
					</Column>
				</Row>
				{/* <Div className="show-flex-in-lg w-per-100"> */}
				{/* <Row
						className={cx('flex w-per-100 br-bottom-solid-1 br-color-silver')}>
						<Column
							xs={12}
							sm={12}
							md={12}
							lg={4}
							className={cx(
								'flex flex--jc--center flex--ai--center fs-px-14 my1'
							)}>
							Account Balance
						</Column>
						<Column
							xs={12}
							sm={12}
							md={12}
							lg={8}
							className={cx(
								'flex flex--jc--center flex--ai--center fs-px-14  my1'
							)}>
							This month Pledges
						</Column>
					</Row> */}
				{/* </Div> */}
				{/* <Row className={cx(styles.table, 'w-per-100')}>
					<Column
						xs={12}
						sm={12}
						md={12}
						lg={4}
						className={cx(styles.personalPledgeFirstColumn)}>
						<Div
							type="flex"
							direction="vertical"
							hAllign="center"
							distributedBetween
							className="w-per-100">
							<Div
								type="flex"
								hAlign="center"
								vAlign="center"
								className={'my1 p1 fs-px-26'}>
								${(myProfile?.saving || 0)?.toFixed(2) || 0}
							</Div>
							<Div type="flex" hAlign="center" className="w-per-100 mt1">
								<Button onClick={() => dispatch(showModal('addFundPopup'))}>
									Add Funds
								</Button>
							</Div>
						</Div>
					</Column>
					<Column
						xs={12}
						sm={12}
						md={12}
						lg={8}
						className={cx(styles.pledgeHistory)}>
						{pledgedHistory.map((item, idx) => (
							<TableRow key={idx}>
								<Div className={cx('fs-px-12 pl1', styles.pledgedNeeds)}>
									{item.business_need_short_description}
								</Div>
								<Div className="fs-px-12">${item.amount}</Div>
							</TableRow>
						))}
					</Column>
				</Row> */}
			</Div>
			<AddFundModal />
		</>
	);
}

export default PersonalPledging;
