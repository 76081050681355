import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Div } from 'basedesign-iswad';
import { useHistory } from 'react-router-dom';

import { addActiveCardToStripeCustomer } from 'root/services/stripe';
// import { addAlertItem } from '@/utils/notifications';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import {
	clearSelectedGeeneesWish,
	setSelectedGeeneesWish
} from 'root/reducers/selectedGeeneesWish';
import {
	setSelectedNeedToFulfill,
	clearSelectedNeedToFulfill,
	paymentDone
} from 'root/reducers/geenees';
import {
	checkHasEnoughMoneyToFulfillAGeeneesNeed,
	fulfillAGeeneesNeed
} from 'root/services/geenees';
import { showModal } from 'root/reducers/modals';

import Button from 'root/baseComponents/Button';
import Form from 'root/baseComponents/Form';

import { options } from '../utils';
import styles from '../StripeTokenizeCharge.module.scss';

const CheckoutFormForGeenees = ({
	amount,
	selectedGeeneesWish,
	geeneesUseAccountFund
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();
	const business = useSelector(state => state.businesses.businesses)?.[0];

	const fulFillTheNeed = async () => {
		const needData = {
			geenees_need_identifier: selectedGeeneesWish?.identifier,
			geenees_need_value: selectedGeeneesWish?.price?.total,
			geenees_need_item_fee: selectedGeeneesWish?.price?.item_price,
			geenees_need_tax_fee: selectedGeeneesWish?.price?.taxes_and_delivery,
			geenees_need_processing_fee: selectedGeeneesWish?.price?.processing_fee,
			supported_organization: selectedGeeneesWish?.organization,
			geenees_need_title: selectedGeeneesWish?.title,
			use_do_good_account_fund: geeneesUseAccountFund
		};
		try {
			const needRes = await fulfillAGeeneesNeed(needData);
			console.log(needRes);
			dispatch(clearSelectedGeeneesWish());
			if (needRes?.success) {
				dispatch(setSelectedNeedToFulfill(selectedGeeneesWish));
				dispatch(paymentDone());
				dispatch(setLoadingComplete());
				history.push({
					pathname: '/',
					state: { comingToViewReceipts: true }
				});
			} else {
				console.log(needRes);
			}
		} catch (err) {
			dispatch(clearSelectedGeeneesWish());
			dispatch(clearSelectedNeedToFulfill());
			console.log(err);
		}
	};

	const [token, setToken] = useState('');
	const bodyData = {
		token,
		amount
	};
	const [sendTokenReq, setSendTokenReq] = useState(false);
	const sendReqToHandleAddingCard = async () => {
		try {
			const res = await addActiveCardToStripeCustomer(bodyData);
			console.log(res);
			if (res?.success) {
				console.log(res);
				dispatch(clearSelectedGeeneesWish());
				dispatch(setSelectedNeedToFulfill(selectedGeeneesWish));
				fulFillTheNeed();
			} else {
				dispatch(setLoadingComplete());
			}
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
		}
	};
	useEffect(() => {
		if (sendTokenReq) {
			sendReqToHandleAddingCard(bodyData);
			setTimeout(() => {
				setSendTokenReq(false);
			}, 10);
		}
	}, [sendTokenReq]);

	const handleSubmit = async event => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}
		const card = elements.getElement(CardElement);
		dispatch(setLoading());
		const res = await stripe.createToken(card);
		if (res.error) {
			// addAlertItem(dispatch, res.error.message, 'error');
			console.log(res.error.message);
			dispatch(setLoadingComplete());
		} else {
			if (res?.token?.id) {
				setToken(res.token.id);
			}
		}
	};

	useEffect(() => {
		if (token) {
			setSendTokenReq(true);
		}
	}, [token]);

	useEffect(() => {
		console.log(selectedGeeneesWish);
	}, [selectedGeeneesWish]);

	return (
		<>
			<Div className="w-per-100">
				<Form onSubmit={handleSubmit}>
					<Div
						className={cx(
							'br-all-solid-1 p2 br-color-grayBright br-rad-px-5 boxShadowType1'
						)}>
						<Div className="p2 bgInfo w-per-100 textWhite fs-px-14 text-center mb2">
							Spending{' '}
							<span className="textYellow">
								${parseFloat(amount)?.toFixed(2)}
							</span>{' '}
							on my community!
						</Div>
						<Div className="br-all-solid-1 p2 br-rad-px-5">
							<CardElement id="card-element" options={options} />
						</Div>
					</Div>
					{stripe && elements ? (
						<Div className="mt2 w-per-100">
							<Button className={cx('w-per-100')}>Submit</Button>
						</Div>
					) : (
						''
					)}
				</Form>
			</Div>
		</>
	);
};

export default CheckoutFormForGeenees;
