import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Button, Image, Text, Icon, Anchor } from 'troop-design';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

import { getPledgedNeedInfo } from 'root/services/needs';

import styles from '../ListPledges.module.scss';

const NeedCard = ({ need, businessId }) => {
	const toBePledgedNeed = useSelector(state => state.needs.toBePledgedNeed);
	const history = useHistory();

	// const [src, setSrc] = useState('');

	const [pledgedInfo, setPledgedInfo] = useState(null);
	const [pledgersNum, setPledgersNum] = useState(0);
	const [pledgePercent, setPledgePercent] = useState(0);
	const [allPledgedAmount, setAllPledgedAmount] = useState(0);
	const [hitByTarget, setHitByTarget] = useState(false);
	const [monthDate, setMonthDate] = useState(null);

	// I need to remove the following part before merging to develop branch
	// useEffect(() => {
	// 	let url = need.need.image;
	// 	const urlSplitted = url.split('http://localhost:8000/');
	// 	setSrc(`https://api.hitroop.com/${urlSplitted[1]}`);
	// }, [need]);
	//

	useEffect(() => {
		const getPledgedInfo = async () => {
			const data = await getPledgedNeedInfo(businessId, need.id);
			setPledgedInfo(data);
			if (Object?.keys(data?.pledger_employees)?.length) {
				setPledgersNum(Object.keys(data.pledger_employees).length);
			}
			setPledgePercent((data?.collected_money / data?.need_value) * 100);
			setAllPledgedAmount(data?.collected_money);
		};
		if (need && businessId) {
			getPledgedInfo();
		}
	}, [need, businessId, toBePledgedNeed]);

	useEffect(() => {
		setMonthDate(need.monthDate);
	}, [need]);

	return (
		<>
			<div
				className={cx(
					styles.card,
					need.is_vote_winner && !need.need.is_fulfilled
						? styles.cardWinner
						: '',
					hitByTarget && styles.cardHitTargetByPledge,
					need.is_vote_winner && need.need.is_fulfilled
						? styles.cardFulfilled
						: ''
				)}>
				<div className={cx(styles.cardImage)}>
					<div className={cx(styles.cardImagePledgeInfo)}>
						<div>
							{`
								$${parseInt(allPledgedAmount)?.toFixed(2)} of $${parseInt(
								pledgedInfo?.need_value
							).toFixed(2)}
								target`}
						</div>
						<div className={cx(styles.cardImagePledgeInfoPerAll)}>
							<div
								className={cx(styles.cardImagePledgeInfoPerFill)}
								style={{
									width: `${pledgePercent}%`
								}}></div>
						</div>
						<div>
							{pledgersNum > 1
								? `${pledgersNum} Teammates pledged!`
								: `${pledgersNum} Teammate pledged!`}
						</div>
					</div>

					<Image
						src={need.need.image}
						// Need to change src to the above value before merging
						// src={src}
						className={cx(styles.cardImagePhoto)}
					/>
				</div>
				<div className={cx(styles.cardBody)}>
					<div className={cx(styles.cardInfo)}>
						<div>
							<Text>{need.need.charity_name}</Text>
						</div>
						<div>
							<div className={cx(styles.cardInfoBold)}>
								{need.need.community_supported || need.need.name}
							</div>
						</div>
						<div className={cx(styles.moreInfo)}>
							<div>
								<Text>{need.need.short_description} </Text>
								<Icon
									icon="link"
									size="sm"
									color="blue"
									className={cx(styles.pointer)}
									onClick={() =>
										history.push(
											`/businesses/${businessId}/${need.id}/pledge-details`
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
				{need.is_vote_winner && need.need.is_fulfilled ? (
					<div
						className={cx(
							styles.extraInfoToShow,
							styles.extraInfoToShowFulfilled
						)}>
						Fulfilled
					</div>
				) : (
					''
				)}
				{need.is_vote_winner && !need.need.is_fulfilled ? (
					<div
						className={cx(
							styles.extraInfoToShow,
							styles.extraInfoToShowTopVoted
						)}>
						Top Voted
					</div>
				) : (
					''
				)}
				{hitByTarget && (
					<div
						className={cx(
							styles.extraInfoToShow,
							styles.extraInfoToShowHitTargetByPledge
						)}>
						Fulfilled By Employee
					</div>
				)}

				{(dayjs().isAfter(dayjs(`${need.pledge_close_date} 24:00`)) ||
					!need.pledge_close_date) &&
				!need.is_vote_winner &&
				!need.hitByTarget ? (
					<div
						className={cx(
							styles.extraInfoToShow,
							styles.extraInfoToShowNotFulfilled
						)}>
						Not Fulfilled
					</div>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default NeedCard;
