import React, { useState, useEffect, useMemo } from 'react';
import { Table, Button, Icon } from 'troop-design';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';

import { getTeamsLogs } from 'services/teams';

import styles from './TeamsLogs.module.scss';

const TeamsLogs = () => {
	const history = useHistory();
	const [logs, setLogs] = useState([]);
	const [text, setText] = useState([]);

	useEffect(() => {
		const getLogs = async () => {
			const res = await getTeamsLogs();
			setLogs(res);
		};

		getLogs();
	}, []);

	const columns = useMemo(() => {
		let cols = [
			{
				Header: 'Message Type',
				accessor: 'message_type',
				filter: 'equals'
			},
			{
				Header: 'Business',
				accessor: 'business',
				filter: 'equals'
			},
			{
				Header: 'Date',
				accessor: 'date',
				filter: 'equals'
			},
			{
				Header: 'Time',
				accessor: 'time',
				filter: 'equals'
			},
			{
				Header: 'Employee',
				accessor: 'employee',
				filter: 'equals'
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: e => {
					return (
						<div
							onClick={() => setText(e.value.text)}
							className={cx(styles.statusContainer)}>
							<Icon icon={e.value.icon} width={20} />
						</div>
					);
				},
				filter: 'equals'
			}
		];

		return cols;
	}, []);

	const data = useMemo(() => {
		if (logs) {
			return logs.map(log => {
				let showingCols = {};
				showingCols['message_type'] = log.message_type;
				showingCols['business'] = log.business?.name;
				showingCols['date'] = log.time?.split('T')?.[0];
				showingCols['time'] = log.time?.split('T')?.[1]?.split('.')?.[0];
				showingCols['employee'] = log?.employee;
				showingCols['status'] = log?.error?.length
					? { icon: 'crossmark', text: log?.text }
					: { icon: 'checkmark2', text: log?.text };

				return showingCols;
			});
		}
	}, [logs]);

	return (
		<>
			{logs?.length ? (
				<div>
					<Table
						className={styles.employeesTable}
						data={data}
						columns={columns}
						striped={false}
						paginate
						itemsPerPage={20}
					/>
				</div>
			) : (
				''
			)}
			<div className={cx(styles.btnContainer)}>
				<Button
					className={cx(styles.backBtn)}
					onClick={() => history.push(`/`)}>
					Go Back
				</Button>
			</div>
			{text?.length ? (
				<div className={cx(styles.textContainer)}>
					<div className={cx(styles.cross)} onClick={() => setText('')}>
						<Icon icon="close" width={15} color="black" />
					</div>
					<div className={cx(styles.slackMsg)}>{text}</div>
				</div>
			) : (
				''
			)}
		</>
	);
};

export default TeamsLogs;
