import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Close from 'root/baseComponents/Close';
import Button from 'root/baseComponents/Button';

import styles from './GetConfirmationModal.module.scss';

const GetConfirmationModal = ({
	text = 'Are you sure?',
	setShowModal = null,
	confirmationHandler = null
}) => {
	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'fullHightDiv pos-fix pos-fix--lt w-per-100 height-vh-full z-1000',
					styles.fullHeightContainer
				)}>
				<Div
					className={cx(
						'w-per-100 max-w-px-500 bgWhite boxShadowType1 pos-rel p2 of-y-auto',
						styles.modalContainer
					)}>
					<Close onClick={() => setShowModal(false)} />
					<Div className="mt2">{text}</Div>
					<Div className="mt2">
						<Button
							colorType="transparent"
							className="mr2 w-px-100"
							onClick={confirmationHandler}>
							Yes
						</Button>
						<Button
							colorType="transparent"
							className="w-px-100"
							onClick={() => setShowModal(false)}>
							No
						</Button>
					</Div>
				</Div>
			</Div>
		</>
	);
};

export default GetConfirmationModal;
