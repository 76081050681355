import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { getBackendUrl } from 'root/utils/helpers';
import { updateBusiness } from 'services/businesses';
import { sendImpactReportToSlack } from 'services/slack';
import { sendImpactReportToTeams } from 'services/teams';
import {
	Card,
	Anchor,
	Row,
	Col,
	Heading,
	Spinner,
	List,
	ListItem,
	Button,
	Form,
	Input
} from 'troop-design';
import { sendFinalVoteEmail } from 'services/businesses';

import cx from 'classnames';
import Modal from 'components/Modal';
import { showModal, hideModal } from 'reducers/modals';
import ImpactReports from '../../shared/ImpactReports/ImpactReports';
import styles from './BusinessDetails.module.scss';
import useFindBusiness from 'root/hooks/useFindBusiness';
import { updateVoteResult } from 'root/services/businesses';
import {
	buildTaxReceipts,
	buildEmployeeTaxReceipts
} from 'root/services/charity';

import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { addNotification } from 'root/reducers/notifications';

export default function BusinessDetails() {
	const history = useHistory();

	const [businessIdForFinalVote, setBusinessIdForFinalVote] = useState(null);
	const [showEditForm, setShowEditForm] = useState(false);
	const dispatch = useDispatch();
	const params = useParams();
	const business = useFindBusiness({ id: parseInt(params.businessId, 10) });
	const [taxYear, setTaxYear] = useState(2022);

	const triggerFinalVoteEmail = useCallback(() => {
		if (businessIdForFinalVote != null) {
			sendFinalVoteEmail(businessIdForFinalVote);
			dispatch(hideModal('confirmEmail'));
		}
	}, [businessIdForFinalVote]);

	const triggerSlackImpactReport = useCallback(async () => {
		const res = await sendImpactReportToSlack({
			business_id: business.id
		});
		dispatch(hideModal('confirmSlackImpactReport'));
	});

	const triggerTeamsImpactReport = useCallback(async () => {
		dispatch(setLoading());
		const res = await sendImpactReportToTeams({
			business_id: business.id
		});
		dispatch(hideModal('confirmTeamsImpactReport'));
		dispatch(setLoadingComplete());
	});

	const buildBusinessTaxReceipts = async () => {
		dispatch(setLoading());
		try {
			const res = await buildTaxReceipts({
				business_id: business.id,
				tax_year: parseInt(taxYear)
			});
			dispatch(setLoadingComplete());
			dispatch(
				addNotification({
					message: 'Tax receipt is successfuly built!',
					type: 'success'
				})
			);
			dispatch(hideModal('confirmBuildBusinessTaxReceipts'));
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
			dispatch(
				addNotification({
					message: 'Something went wrong, please try again!',
					type: 'danger'
				})
			);
		}
	};

	const buildEmployeeTaxReceiptsFunc = async () => {
		dispatch(setLoading());
		try {
			const res = await buildEmployeeTaxReceipts({
				business_id: business.id,
				tax_year: parseInt(taxYear)
			});
			dispatch(setLoadingComplete());
			dispatch(
				addNotification({
					message: 'Tax receipt is successfuly built!',
					type: 'success'
				})
			);
			dispatch(hideModal('confirmBuildEmployeeTaxReceipts'));
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
			dispatch(
				addNotification({
					message: 'Something went wrong, please try again!',
					type: 'danger'
				})
			);
		}
	};

	if (!business) {
		return <Spinner />;
	}

	const showConfirmation = e => {
		setBusinessIdForFinalVote(e.target.id);
		dispatch(showModal('confirmEmail'));
	};

	const handleUpdate = values => {
		dispatch(updateBusiness(business.id, values));
		console.log(values);
	};

	const renderEditForm = () => {
		return (
			<Card>
				<Form onSubmit={handleUpdate}>
					<Input
						label="Business Name"
						name="businessName"
						value={business.name}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter a valid company name'
							}
						]}
					/>
					<Input
						label="Stree address"
						name="address"
						value={business.address}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter the company address'
							}
						]}
					/>
					<Input
						label="City"
						name="city"
						value={business.city}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter the city'
							}
						]}
					/>
					<Input
						label="Province"
						name="province"
						value={business.province}
						validationRules={[
							{
								rule: 'option.atLeastOne',
								message: 'Please enter the province'
							}
						]}
					/>
					<Input
						label="Postal code"
						name="postal_code"
						value={business.postal_code}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter the postal code'
							}
						]}
					/>
					<Input
						label="Phone number"
						name="phone"
						value={business.phone}
						validationRules={[
							{
								rule: 'string.isRequired',
								message: 'Please enter the phone number'
							}
						]}
					/>
					<Input label="Website" name="website" value={business.website} />
					<Input
						label="Monthly Contribution"
						name="monthly_contribution"
						value={business.monthly_contribution}
					/>
					<Input
						label="Activation Time"
						name="activation_date"
						type="date"
						value={business.activation_date}
					/>
					<Button
						variant="primary"
						size="lg"
						className={cx(styles.loginBtn, 'nowrap mb-3')}>
						Update
					</Button>
				</Form>
				<Button onClick={() => setShowEditForm(false)}>Cancel</Button>
			</Card>
		);
	};

	return (
		<>
			<Heading
				level={1}
				className="mt-0">{`${business?.name} Details`}</Heading>
			<Card>
				<Row>
					<Col xs={12} md={8}>
						<Heading level={2} className="mt-0 d-flex align-items-center">
							Contact Information
							<Button
								size="xs"
								onClick={() => setShowEditForm(true)}
								className="ms-2">
								Edit
							</Button>
						</Heading>
						<List className={cx('unstyled', styles.list)} variant="list-group">
							<ListItem>
								<strong className={cx(styles.label)}>Name:</strong>{' '}
								<span className={styles.listContent}>{business?.name}</span>
							</ListItem>
							<ListItem>
								<strong className={cx(styles.label)}>Address:</strong>{' '}
								<span
									className={
										styles.listContent
									}>{`${business?.address}, ${business?.city}, ${business?.province} ${business?.postal_code}`}</span>
							</ListItem>
							<ListItem>
								<strong className={cx(styles.label)}>Website:</strong>{' '}
								<Anchor
									to={business.website}
									target="_blank"
									className={styles.listContent}>
									{business?.website}
								</Anchor>
							</ListItem>
							<ListItem>
								<strong className={cx(styles.label)}>
									Monthly Contribution:
								</strong>{' '}
								<span className={styles.listContent}>
									${business?.monthly_contribution}
								</span>
							</ListItem>
						</List>
					</Col>
					<Col xs={12} md={4}>
						<div className={styles.actions}>
							<Heading level={2} className="mt-0">
								Actions
							</Heading>
							<Anchor
								variant="primary"
								size="xs"
								component={Link}
								to={`/businesses/${business.id}/build-vote-landing-page`}
								className="mb-2 bgPrimary">
								Build vote
							</Anchor>
							<Anchor
								variant="primary"
								size="xs"
								component={Link}
								to={`/businesses/${business.id}/view_active_votes`}
								className="mb-2 bgPrimary">
								View Active Votes
							</Anchor>
							<Anchor
								variant="danger"
								size="xs"
								component={Link}
								to={`/businesses/${business.id}/employees`}
								className="mb-2 bgWarning">
								Employees
							</Anchor>
							<Anchor
								variant="danger"
								size="xs"
								component={Link}
								to={`/${parseInt(params.businessId, 10)}/business-events`}
								className="mb-2 bgWarning">
								Events
							</Anchor>
							<Anchor
								variant="dark"
								size="xs"
								target="_blank"
								to={`${getBackendUrl()}/admin/troop_app/business/${
									business.id
								}/change`}
								className="mb-2 bgSuccess">
								Update
							</Anchor>
							<Button
								id={`${business.id}`}
								variant="success"
								size="xs"
								className="mb-2 bgDanger"
								onClick={showConfirmation}>
								Vote Reminder
							</Button>
							<Anchor
								component={Link}
								to={`/businesses/${business?.id}/future-vote-landing-pages`}
								className="mb-2 bgSecondary"
								variant="warning"
								size="xs">
								View Next Months Vote Page
							</Anchor>
							<Button
								variant="success"
								size="xs"
								className="mb-2 bgDanger"
								onClick={() =>
									history.push(`/businesses/${business}/list-pledges`)
								}>
								Show Pledges
							</Button>
							<Button
								variant="success"
								size="xs"
								className="mb-2 bgDanger"
								onClick={() =>
									history.push(
										`/employee-reports/${business?.id}/${business?.key}`
									)
								}>
								Employee Impact Report
							</Button>
							<Button
								variant="success"
								size="xs"
								className="mb-2 bgDanger"
								onClick={() => updateVoteResult()}>
								Update Vote Data
							</Button>
							<Button
								variant="success"
								size="xs"
								className="mb-2 bgDanger"
								onClick={() =>
									dispatch(showModal('confirmBuildBusinessTaxReceipts'))
								}>
								Build Business Tax Receipt
							</Button>
							<Button
								variant="success"
								size="xs"
								className="mb-2 bgDanger"
								onClick={() =>
									dispatch(showModal('confirmBuildEmployeeTaxReceipts'))
								}>
								Build Employees Tax Receipt
							</Button>
							{business?.has_activated_slack && (
								<Button
									id={`${business.id}`}
									variant="success"
									size="xs"
									className="mb-2 bgPrimary"
									onClick={() =>
										dispatch(showModal('confirmSlackImpactReport'))
									}>
									Send Slack Impact Report Message
								</Button>
							)}
							{business?.has_activated_teams && (
								<Button
									id={`${business.id}`}
									variant="success"
									size="xs"
									className="mb-2 bgDanger"
									onClick={() =>
										dispatch(showModal('confirmTeamsImpactReport'))
									}>
									Send Teams Impact Report Message
								</Button>
							)}
						</div>
					</Col>
				</Row>
			</Card>
			{showEditForm && renderEditForm()}
			<Card>
				<Heading level={2} className="mt-0">
					Impact Report
				</Heading>
				<ImpactReports businessId={business.id} />
			</Card>
			<Modal reduxKey="confirmEmail" size="md">
				<Heading level={3}>
					Are you sure you want to send an email to employees that have not
					voted?
				</Heading>
				<Button
					className="me-2"
					variant="primary"
					outline
					onClick={triggerFinalVoteEmail}>
					Send
				</Button>
				<Button
					variant="primary"
					outline
					onClick={() => dispatch(hideModal('confirmEmail'))}>
					Cancel
				</Button>
			</Modal>

			<Modal reduxKey="confirmSlackImpactReport" size="md">
				<Heading level={3}>
					Are you sure you want to send impact reports to the current business
					slack work space?
				</Heading>
				<Button
					className="me-2"
					variant="primary"
					outline
					onClick={triggerSlackImpactReport}>
					Send
				</Button>
				<Button
					variant="primary"
					outline
					onClick={() => dispatch(hideModal('confirmSlackImpactReport'))}>
					Cancel
				</Button>
			</Modal>

			<Modal reduxKey="confirmTeamsImpactReport" size="md">
				<Heading level={3}>
					Are you sure you want to send impact reports to the current business
					Microsoft Teams channel?
				</Heading>
				<Button
					className="me-2"
					variant="primary"
					outline
					onClick={triggerTeamsImpactReport}>
					Send
				</Button>
				<Button
					variant="primary"
					outline
					onClick={() => dispatch(hideModal('confirmTeamsImpactReport'))}>
					Cancel
				</Button>
			</Modal>

			<Modal reduxKey="confirmBuildBusinessTaxReceipts" size="md">
				<Heading level={3}>
					Are you sure you want to build tax receipt for {business?.name}?
				</Heading>
				<div className="w-per-100 flex flex--jc--center flex--ai--center my2">
					<div className="mr2">Tax year</div>
					<input
						type="text"
						value={taxYear}
						onChange={e => setTaxYear(e.target.value)}
						className="p1"
					/>
				</div>
				<Button
					className="me-2"
					variant="primary"
					outline
					onClick={buildBusinessTaxReceipts}>
					Yes
				</Button>
				<Button
					variant="primary"
					outline
					onClick={() =>
						dispatch(hideModal('confirmBuildBusinessTaxReceipts'))
					}>
					Cancel
				</Button>
			</Modal>

			<Modal reduxKey="confirmBuildEmployeeTaxReceipts" size="md">
				<Heading level={3}>
					Are you sure you want to build tax receipt for the employees of{' '}
					{business?.name}?
				</Heading>
				<div className="w-per-100 flex flex--jc--center flex--ai--center my2">
					<div className="mr2">Tax year</div>
					<input
						type="text"
						value={taxYear}
						onChange={e => setTaxYear(e.target.value)}
						className="p1"
					/>
				</div>
				<Button
					className="me-2"
					variant="primary"
					outline
					onClick={buildEmployeeTaxReceiptsFunc}>
					Yes
				</Button>
				<Button
					variant="primary"
					outline
					onClick={() =>
						dispatch(hideModal('confirmBuildEmployeeTaxReceipts'))
					}>
					Cancel
				</Button>
			</Modal>
		</>
	);
}
