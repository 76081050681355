import dayjs from 'dayjs';
import { getLandingPages as getLandingPagesRx } from 'reducers/landingPages';
import { getBusiness } from 'reducers/businesses';
import { getBusinessNeeds } from 'reducers/needs';
import { get, post, del } from './http';
import { addNotification } from 'root/reducers/notifications';
import history from 'root/utils/history';

export const getLandingPage = (businessId, date) => dispatch => {
	return get(`/businesses/${businessId}/vote_page/${date}/`).then(resp => {
		let { ...data } = resp;
		const { business } = data?.[0] || {};
		if (business) {
			dispatch(getBusiness(business));
		}
		dispatch(getBusinessNeeds(Object.values(data)));
		return data;
	});
};

export const getLandingPages = businessId => dispatch => {
	return get(`/businesses/${businessId}/landing-pages`).then(resp =>
		dispatch(getLandingPagesRx(resp))
	);
};

export const deleteLandingPage = (businessId, date) => {
	get(`/delete_business_vote_page/${businessId}/${date}/`, {});
};

export const hasEmployeeVoted = (email, businessId, curMonth = '') =>
	get(
		`/businesses/${businessId}/employee_votes/?email=${email}&date=${curMonth}`
	);

export const submitEmployeeVote = (businessId, data) =>
	post(`/businesses/${businessId}/employee_votes/`, data);

export const submitCustomerVote = data => post(`/add-customer-vote/`, data);

export const addLandingPage = (reqData, businessId) => dispatch => {
	return post(`/businesses/${businessId}/create_vote_page/`, {
		...reqData,
		business_id: businessId
	}).then(resp => {
		const { business, ...data } = resp;
		data.business_id = business.id;
		history.push('/businesses');
		dispatch(
			addNotification({
				type: 'success',
				message: `Successfully added the landing page. Click <a href="/vote/${business.id}/${business.key}/${reqData.monthDate}" target="_blank">here</a> to view.`,
				options: {
					autoDismiss: true,
					autoDismissAfter: 15000
				}
			})
		);
	});
};
