import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import GetConfirmationModal from 'root/baseComponents/GetConfirmationModal';
import Modal from 'root/baseComponents/Modal'
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { deleteListOfCustomers } from 'root/services/customers';

import DisplayEmployees from './components/DisplayEmployees';
import Customers from './components/Customers';
import Toggler from './components/Toggler';
import styles from './Employees.module.scss';

function Employees() {
	const dispatch = useDispatch();
	const history = useHistory();

	const [activeTab, setActiveTab] = useState('Employees');
	const [selectedCustomerData, setSelectedCustomerData] = useState([]);
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const [confirmationText, setConfirmationText] = useState('');

	useEffect(() => {
		if (selectedCustomerData.length) {
			let localConfirmationText = 'Are you sure you want to remove';
			selectedCustomerData?.forEach(customer => {
				if (selectedCustomerData?.length > 0) {
					localConfirmationText = `${localConfirmationText}, ${customer.email}`;
				} else {
					localConfirmationText = `${localConfirmationText} ${customer.email}`;
				}
			});
			localConfirmationText = `${localConfirmationText}?`;
			setConfirmationText(localConfirmationText);
		}
	}, [selectedCustomerData]);

	return (
		<>
			<Modal />
			<Div className="mt2 pt2 pl2 pr2">
				<Toggler activeTab={activeTab} setActiveTab={setActiveTab} />
			</Div>
			{activeTab === 'Employees' && (
				<Div className="px2">
					<DisplayEmployees />
				</Div>
			)}
			{activeTab === 'Customers' && (
				<Div className="px2">
					<Customers
						selectedCustomerData={selectedCustomerData}
						setSelectedCustomerData={setSelectedCustomerData}
						setShowConfirmationModal={setShowConfirmationModal}
					/>
				</Div>
			)}

			{showConfirmationModal && (
				<GetConfirmationModal
					setShowModal={setShowConfirmationModal}
					text={confirmationText}
					confirmationHandler={async () => {
						const customerIds = [];
						selectedCustomerData?.forEach(customer => {
							customerIds.push(customer.id);
						});
						dispatch(setLoading());
						try {
							const res = await deleteListOfCustomers({
								customer_ids: customerIds
							});
							dispatch(setLoadingComplete());
							history.go(0);
						} catch (err) {
							dispatch(setLoadingComplete());
						}
					}}
				/>
			)}
		</>
	);
}

export default Employees;
