import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import { login } from 'services/user';
import { showErrorAPIAlert } from 'root/utils/helpers';

import Form from 'root/baseComponents/Form/';
import TextBox from 'root/baseComponents/TextBox';
import Button from 'root/baseComponents/Button/';
import ErrorModal from 'root/pages/PublicRoutes/components/ErrorModal';

import {
	getCharityAdminFromUUID,
	registerCharityAdminFromUUID
} from 'root/services/charity';

import { emailValidators, passwordValidators } from '../validators';
import styles from '../CharityAdminSignup.module.scss';

const SignupForm = ({ uuid }) => {
	const dispatch = useDispatch();

	const [email, setEmail] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	const [password, setPassword] = useState('');
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

	const [errorMessage, setErrorMessage] = useState('');

	const toBeValidatedFields = [
		{
			input_name: 'email',
			validators: emailValidators,
			errorMessageHandler: setEmailErrorMessage
		},

		{
			input_name: 'password',
			validators: passwordValidators,
			errorMessageHandler: setPasswordErrorMessage
		}
	];

	const getCharityAdminFromUUIDData = async () => {
		try {
			const res = await getCharityAdminFromUUID(uuid);
			if (res?.user) {
				setEmail(res.user.email);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (uuid) {
			getCharityAdminFromUUIDData();
		}
	}, [uuid]);

	const handleSubmit = async e => {
		e.preventDefault();
		try {
			const res = await registerCharityAdminFromUUID(uuid, { password });
			if (res?.success) {
				dispatch(login(email.toLowerCase(), password, '/'));
			} else {
				setErrorMessage(res?.message);
			}
		} catch (e) {
			console.log(showErrorAPIAlert(e));
			setErrorMessage(showErrorAPIAlert(e));
		}
	};

	return (
		<>
			<Form toBeValidatedFields={toBeValidatedFields} onSubmit={handleSubmit}>
				<TextBox
					labelText="Email"
					type="text"
					val={email}
					setVal={setEmail}
					errorHandler={setEmailErrorMessage}
					name="email"
					errorMessage={emailErrorMessage}
					inputClassName="w-px-100"
					readOnly
				/>
				<TextBox
					labelText="Password"
					type="password"
					val={password}
					setVal={setPassword}
					errorHandler={setPasswordErrorMessage}
					name="password"
					errorMessage={passwordErrorMessage}
					inputClassName="w-px-100"
				/>
				<Div type="flex" hAlign="center" className="w-per-100">
					<Button type="submit" className={'w-px-150'}>
						Signup
					</Button>
				</Div>
			</Form>
			{errorMessage && (
				<ErrorModal
					errorMessage={errorMessage}
					setErrorMessage={setErrorMessage}
				/>
			)}
		</>
	);
};

export default SignupForm;
