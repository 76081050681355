// VENDOR imports
import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { Route, useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import cx from 'classnames';

// troop design imports
import {
	Form,
	Select,
	Card,
	Line,
	ShowMore,
	Container,
	Text
} from 'troop-design';
import { Div, Heading } from 'basedesign-iswad';
// imports
import { LAST_TWELVE_MONTHS_STRING } from 'root/constants/locale';
import StatsCard from 'root/baseComponents/StatsCard/StatsCard';
import useHistoricalReports from '../hooks/useHistoricalReports';
import useHistoricalReportsData from '../hooks/useHistoricalReportData';
import { format } from 'root/utils/currency';
import useCopyUrl from '../hooks/useCopyUrl';
import Button from 'root/baseComponents/Button';
import Table from 'root/baseComponents/Table';
// style imports
import styles from '../ImpactReports.module.scss';

export default function HistoricalReport({ business }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const reports = useSelector(state => state.impactReports?.reports);

	const urlSearchParams = new URLSearchParams(location.search);

	const period =
		urlSearchParams.get('period') || dayjs().startOf('M').format('YYYY-MM');
	// const currentPeriodDate = dayjs(currentPeriod).format('MMMM YYYY');

	const [reportCopied, setReportCopied] = useState('Copy to clipboard');
	const [voteCopied, setVoteCopied] = useState('Copy to clipboard');
	const [currentPeriod, setCurrentPeriod] = useState(period);

	const year = dayjs().year();
	const to = year;
	const from = dayjs().subtract(12, 'month').format('YYYY');
	const [historicalPeriod, setHistoricalPeriod] = useState(
		LAST_TWELVE_MONTHS_STRING
	);
	const [fromDate, setFromDate] = useState(`${from}-01`);
	const [toDate, setToDate] = useState(`${to}-12`);

	const historicalReports = useHistoricalReports(fromDate, toDate);
	const formattedHistoricalReportData = useHistoricalReportsData(
		historicalReports,
		historicalPeriod
	);

	const activationDate = useMemo(
		() => business?.activation_date || dayjs(),
		[business]
	);

	const handleHistoricalData = useCallback(
		e => {
			setHistoricalPeriod(e.target.value);
			if (e.target.value === LAST_TWELVE_MONTHS_STRING) {
				const today = dayjs();
				setToDate(today.format('YYYY-MM'));
				setFromDate(dayjs().subtract(12, 'month').format('YYYY-MM'));
			} else {
				setFromDate(`${e.target.value}-01`);
				setToDate(`${e.target.value}-12`);
			}
		},
		[setHistoricalPeriod, setFromDate, setToDate]
	);

	const copyVoteUrl = useCopyUrl({
		url: `${window.location.origin}/vote/${business?.id}/${
			business?.key
		}/${dayjs(currentPeriod).format('YYYY-MM-DD')}`,
		currentPeriod,
		business,
		dispatch,
		setReportCopied,
		setVoteCopied,
		reportCopied: false,
		voteCopied: true
	});

	const copyCustomerVoteUrl = useCopyUrl({
		url: `${window.location.origin}/customer-vote/${business?.id}/${
			business?.key
		}/${dayjs(currentPeriod).format('YYYY-MM-DD')}`,
		currentPeriod,
		business,
		dispatch,
		setReportCopied,
		setVoteCopied,
		reportCopied: false,
		voteCopied: true
	});

	const copyReportUrl = useCopyUrl({
		url: `${window.location.origin}/reports/${business?.id}/${business?.key}?period=${currentPeriod}`,
		currentPeriod,
		business,
		dispatch,
		setReportCopied,
		setVoteCopied,
		reportCopied: true,
		voteCopied: false
	});

	//table
	// const [data, setData] = useState([]);
	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const headLines = [
		{
			value: 'organisation',
			display: 'ORGANISATION',
			width: 400,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'need',
			display: 'NEED',
			width: 500,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'month',
			display: 'MONTH',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'contribution',
			display: 'CONTRIBUTIONS',
			width: 300,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		Object.keys(reports)?.map(month => {
			reports[month].needs?.forEach(item => {
				if (item.is_vote_winner) {
					tableData.push({
						organisation: item.charity_name,
						need: {
							value: item.need_name,
							display: (
								<Div
									className={cx('mouse-hand', styles.tableLink)}
									onClick={() => changeUrl(month)}>
									{' '}
									{item.need_name}{' '}
								</Div>
							)
						},
						month: { value: month, display: dayjs(month).format('MMMM YY') },
						contribution: (
							<Div type="flex" hAlign="center" className="w-per-100">
								{' '}
								{'$' + reports[month].need_contribution}{' '}
							</Div>
						)
					});
				}
			});
		});
		console.log(tableData);
		return tableData;
	}, [reports]);

	const changeUrl = month => {
		history.push(
			`/employee-reports/${business?.id}/${business?.key}?date=${dayjs(
				month
			).format('YYYY-MM-DD')}`
		);
	};

	return (
		<>
			<Div
				type="flex"
				vAlign="center"
				className={cx('mr3 mt1', styles.mobContainer)}>
				<Div
					type="flex"
					className="show-flex-in-md-lg employeeHeading min-w-px-150">
					Impact Report
				</Div>

				<Div type="flex" className="show-flex-in-sm-xsm w-per-100 pt1">
					Impact Report
				</Div>
				<Div
					type="flex"
					vAlign="center"
					distributedBetween
					className={cx('pt3 w-per-100 ml1', styles.mobContainer)}>
					<Div type="flex" className="max-w-px-250">
						<Form>
							<Select
								onChange={handleHistoricalData}
								name="historicalPeriodSelect"
								value={historicalPeriod}>
								{[0, 1, 2, 3, 4].map((valueToSubtract, index) => {
									if (index === 0) {
										return (
											<option value={LAST_TWELVE_MONTHS_STRING}>
												Last 12 months
											</option>
										);
									}
									const date = dayjs()
										.subtract(valueToSubtract - 1, 'year')
										.format('YYYY');
									if (
										dayjs(date).isAfter(dayjs(activationDate), 'year') ||
										dayjs(date).isSame(dayjs(activationDate), 'year')
									) {
										return <option value={date}>{date}</option>;
									}
								})}
							</Select>
						</Form>
					</Div>

					<Div type="flex" hAlign="end" className={cx('mb3')}>
						<Container className={styles.copyTooltip}>
							<Button onClick={copyVoteUrl} className={cx(styles.btnURL)}>
								Employee Vote URL
							</Button>
							<Text tag="span" hasWrapper className={styles.copyTooltipText}>
								{voteCopied}
							</Text>
						</Container>

						<Container className={styles.copyTooltip}>
							<Button
								onClick={copyCustomerVoteUrl}
								className={cx(styles.btnURL)}>
								Customer Vote URL
							</Button>
							<Text tag="span" hasWrapper className={styles.copyTooltipText}>
								{voteCopied}
							</Text>
						</Container>

						<Container className={styles.copyTooltip}>
							<Button onClick={copyReportUrl} className={cx(styles.btnURL)}>
								Report URL
							</Button>
							<Text tag="span" hasWrapper className={styles.copyTooltipText}>
								{reportCopied}
							</Text>
						</Container>
					</Div>
				</Div>
			</Div>
			<Div
				type="flex"
				// direction="horizontal"
				hAlign="center"
				className={cx('w-per-100 pr3', styles.statCardsContainer)}>
				<StatsCard
					title="Need Contribution"
					amount={format(
						Math.round(formattedHistoricalReportData?.needContribution || 0),
						'en-CA',
						'CAD',
						0
					)}
					isAdminView={true}
				/>
				<StatsCard
					title="Organizations Supported"
					amount={
						formattedHistoricalReportData?.organizationSupported?.length || 0
					}
					isAdminView={true}
				/>
				<StatsCard
					title="Avg. Employee Participation"
					amount={Math.round(formattedHistoricalReportData?.avgVote || 0)}
					unit="%"
					isAdminView={true}
				/>
			</Div>
			<Div className="card p2 fs-px-18 mr3">
				Employee Vote Participation Rate
			</Div>
			<Div className="card mb3 p2 mr3">
				<Line
					data={formattedHistoricalReportData?.voteParticipation || []}
					label="Participation rate"
					yLabel="Percentage (%)"
					xLabel="Month"
					height={480}
					range={[0, 100]}
				/>
			</Div>
			<Div className="mr3">
				<Div className="employeeHeading mb3 mt2">Organizations Supported</Div>
				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					tableWidth={'1000px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={5}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
}
