import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button/';
import Close from 'root/baseComponents/Close';

import styles from './ErrorModal.module.scss';

function ErrorModal({ errorMessage, setErrorMessage }) {
	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'height-vh-full w-per-100 pos-fix pos-fix--lt',
					styles.container
				)}>
				<Div className="w-per-90 max-w-px-500 bgWhite p2 pos-rel br-rad-px-10">
					<Close onClick={() => setErrorMessage('')} />
					<Div>{errorMessage}</Div>
					<Div className="mt4">
						<Button onClick={() => setErrorMessage('')}>Got it!</Button>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default ErrorModal;
