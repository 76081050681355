import React, { useState, useEffect } from 'react';

import { useParams, useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useRouteMatch } from 'react-router-dom';
import { Nav, NavItem, Header } from 'troop-design';
import SuperAdminContent from 'components/SuperAdminContent';
import BusinessAdminContent from 'components/BusinessAdminContent';
import useSuperAdminLoggedIn from 'hooks/useSuperAdminLoggedIn';
import useBusinessAdminLoggedIn from 'hooks/useBusinessAdminLoggedIn';
import useCurrentBusiness from 'root/hooks/useCurrentBusiness';
import useFeatureFlag from 'root/hooks/useFeatureFlag';
import { toggleUserGroups, setWasBusinessAdmin } from 'root/reducers/users';
import { set } from 'root/utils/storage';
import imagePlaceholder from 'assets/user.png';

function PageHeader(props) {
	const location = useLocation();
	const dispatch = useDispatch();
	const loggedInUser = useSelector(state => state.users.loggedInUser);
	const wasBusinessAdmin = useSelector(state => state.users.wasBusinessAdmin);
	const isSuperAdmin = useSuperAdminLoggedIn();
	const isBusinessAdmin = useBusinessAdminLoggedIn();
	const business = useCurrentBusiness();
	const isSlackEnabled = useFeatureFlag('slack-feature');
	const isTeamsEnabled = useFeatureFlag('teams-feature');

	let isHome = useRouteMatch('/');
	isHome = isHome && isHome.isExact;
	let isDashboard = useRouteMatch('/troop-dashboard');
	isDashboard = isDashboard && isDashboard.isExact;
	let isBusinesses = useRouteMatch('/businesses');
	isBusinesses = isBusinesses && isBusinesses.isExact;
	let isEmployees = useRouteMatch('/employees');
	isEmployees = isEmployees && isEmployees.isExact;
	let isNeeds = useRouteMatch('/writer-needs');
	isNeeds = isNeeds && isNeeds.isExact;
	let isReports = useRouteMatch('/reports');
	isReports = isReports && isReports.isExact;
	let isReferal = useRouteMatch('/refer-a-friend/:id');
	isReferal = isReferal && isReferal.isExact;
	let isEmployeeReferal = useRouteMatch('/employee/invite');
	isEmployeeReferal = isEmployeeReferal && isEmployeeReferal.isExact;

	let isNeedsProfiles = useRouteMatch('/needs-profiles');
	isNeedsProfiles = isNeedsProfiles && isNeedsProfiles.isExact;
	let isActsOfKindness = useRouteMatch('/act-of-kindness');
	isActsOfKindness = isActsOfKindness && isActsOfKindness.isExact;
	let isEvents = useRouteMatch('/events');
	isEvents = isEvents && isEvents.isExact;
	let isBusinessEvents = useRouteMatch('/business-events');
	isBusinessEvents = isBusinessEvents && isBusinessEvents.isExact;
	let isTroopAdminEvents = useRouteMatch('/troop-admin/add-events');
	isTroopAdminEvents = isTroopAdminEvents && isTroopAdminEvents.isExact;
	let isMyReports = useRouteMatch('/employee-reports/:id/:key');
	isMyReports = isMyReports && isMyReports.isExact;
	let isBusinessReports = useRouteMatch('/reports/:id/:key');
	isBusinessReports = isBusinessReports && isBusinessReports.isExact;
	let isFulfilledNeeds = useRouteMatch('/admin-fulfilled-needs');
	isFulfilledNeeds = isFulfilledNeeds && isFulfilledNeeds.isExact;
	let isFeatureFlags = useRouteMatch('/feature-flags');
	isFeatureFlags = isFeatureFlags && isFeatureFlags.isExact;
	let isNeedsToReview = useRouteMatch('/needs-to-review');
	isNeedsToReview = isNeedsToReview && isNeedsToReview.isExact;
	let isSlackLogs = useRouteMatch('/slack-logs');
	isSlackLogs = isSlackLogs && isSlackLogs.isExact;
	let isTeamsLogs = useRouteMatch('/teams-logs');
	isTeamsLogs = isTeamsLogs && isTeamsLogs.isExact;
	let isCreateNewsItems = useRouteMatch('/create-custom-news-item');
	isCreateNewsItems = isCreateNewsItems && isCreateNewsItems.isExact;
	let isAddToSlack = useRouteMatch('/slack-redirect');
	isAddToSlack = isAddToSlack && isAddToSlack.isExact;
	let isAddToTeams = useRouteMatch('/teams-redirect');
	isAddToTeams = isAddToTeams && isAddToTeams.isExact;
	let isViewAllReceipts = useRouteMatch('/view-all-receipts');
	isViewAllReceipts = isViewAllReceipts && isViewAllReceipts.isExact;
	let isViewAllCharities = useRouteMatch('/writer-charities');
	isViewAllCharities = isViewAllCharities && isViewAllCharities.isExact;
	let isPurchasesAndSavings = useRouteMatch('/purchases-and-savings');
	isPurchasesAndSavings =
		isPurchasesAndSavings && isPurchasesAndSavings.isExact;
	let isNewRegisteredCharityAdmins = useRouteMatch(
		'/new-registered-charity-admins'
	);
	isNewRegisteredCharityAdmins =
		isNewRegisteredCharityAdmins && isNewRegisteredCharityAdmins.isExact;
	let isEmailLogs = useRouteMatch('/email-logs');
	isEmailLogs = isEmailLogs && isEmailLogs.isExact;

	const logo = business?.logo || imagePlaceholder;

	useEffect(() => {
		if (isBusinessAdmin) {
			dispatch(setWasBusinessAdmin(true));
		}
	}, [isBusinessAdmin]);

	const {
		navPanelOpened,
		onNavPanelOpened: setNavPanelOpened,
		...otherProps
	} = props;
	const nav = (
		<Nav className="site-nav" linkListClassName="site-nav__link-list">
			<NavItem
				to={isSuperAdmin ? '/troop-dashboard' : '/'}
				currentLink={isHome || isDashboard}
				text="Dashboard"
				icon="dashboard"
				iconType="dashboard-fill"
				linkComponent={Link}
			/>
			<SuperAdminContent>
				<NavItem
					to="/businesses"
					currentLink={isBusinesses}
					text="Businesses"
					icon="website"
					iconType="hand-shake"
					linkComponent={Link}
				/>
			</SuperAdminContent>
			{isBusinessAdmin && (
				<NavItem
					to="/employees"
					currentLink={isEmployees}
					text="Voters"
					icon="contacts"
					iconType="person-fill"
					linkComponent={Link}
				/>
			)}
			{/* {!isBusinessAdmin && !isSuperAdmin ? (
				<NavItem
					to="/employee-profile"
					text="Account"
					icon="account"
					linkComponent={Link}
				/>
			) : (
				''
			)} */}
			{!isBusinessAdmin && !isSuperAdmin ? (
				<NavItem
					to="/needs-profiles"
					text="Needs Profiles"
					icon="filled-heart"
					iconType="heart-fill"
					iconWidth={20}
					linkComponent={Link}
					currentLink={isNeedsProfiles}
				/>
			) : (
				''
			)}

			{!isBusinessAdmin && !isSuperAdmin && business?.has_event_feature ? (
				<NavItem
					to="/events"
					text="Volunteering"
					icon="calendar"
					iconType="calendar"
					iconWidth={20}
					linkComponent={Link}
					currentLink={isEvents}
				/>
			) : (
				''
			)}

			{!isBusinessAdmin && !isSuperAdmin ? (
				<NavItem
					to="/act-of-kindness"
					text="Acts of Kindness"
					icon="filled-star"
					iconType="star-fill"
					iconWidth={20}
					linkComponent={Link}
					currentLink={isActsOfKindness}
				/>
			) : (
				''
			)}

			{/* {!isBusinessAdmin && !isSuperAdmin ? (
				<NavItem
					to="/pledge-history"
					text="Pledging"
					icon="pledge"
					linkComponent={Link}
				/>
			) : (
				''
			)} */}
			<SuperAdminContent>
				<NavItem
					to="/admin-fulfilled-needs"
					currentLink={isFulfilledNeeds}
					text="Fulfilled Needs"
					icon="tasks"
					iconType="hand-holding-heart"
					linkComponent={Link}
				/>
			</SuperAdminContent>
			{isSuperAdmin && (
				<NavItem
					to={'/writer-needs'}
					currentLink={isNeeds}
					text="Needs"
					icon="tasks"
					iconType="heart-fill"
					linkComponent={Link}
				/>
			)}
			<SuperAdminContent>
				<NavItem
					to="/feature-flags"
					currentLink={isFeatureFlags}
					text="Feature Flags"
					icon="tasks"
					iconType="flag"
					linkComponent={Link}
				/>
			</SuperAdminContent>
			<SuperAdminContent>
				<NavItem
					to="/needs-to-review"
					currentLink={isNeedsToReview}
					text="Needs To Review"
					icon="tasks"
					iconType="check-mark"
					linkComponent={Link}
				/>
			</SuperAdminContent>
			{!isSuperAdmin && (
				<NavItem
					to={
						isBusinessAdmin
							? `/reports/${business?.id}/${business?.key}`
							: `/employee-reports/${business?.id}/${business?.key}`
					}
					currentLink={isMyReports || isBusinessReports}
					text="My Impact"
					icon="email"
					iconType="mail-fill"
					linkComponent={Link}
				/>
			)}

			{/* {!isBusinessAdmin && !isSuperAdmin ? (
				<NavItem
					to="/employee/invite"
					text="Share Troop"
					icon="user-plus"
					iconType="user-group"
					iconWidth={20}
					linkComponent={Link}
					currentLink={isEmployeeReferal}
				/>
			) : (
				''
			)} */}

			{isBusinessAdmin ? (
				<NavItem
					to="/business-events"
					currentLink={isBusinessEvents}
					text="Volunteering"
					icon="calendar"
					iconType="calendar"
					linkComponent={Link}
				/>
			) : (
				''
			)}
			{isBusinessAdmin && (
				<NavItem
					to={`/refer-a-friend/${business?.id || ''}`}
					currentLink={isReferal}
					text="Refer a Friend"
					icon="socials"
					iconType="refer-a-friend"
					linkComponent={Link}
				/>
			)}
			{isBusinessAdmin && isSlackEnabled ? (
				<NavItem
					to="/slack-redirect"
					text="Add to slack"
					icon="slack"
					iconType="slack"
					currentLink={isAddToSlack}
					linkComponent={Link}
				/>
			) : (
				''
			)}
			{isBusinessAdmin && isTeamsEnabled ? (
				<NavItem
					to="/teams-redirect"
					text="Add to Teams"
					icon="microsoft-teams"
					iconType="teams"
					linkComponent={Link}
					currentLink={isAddToTeams}
				/>
			) : (
				''
			)}
			{isSuperAdmin ? (
				<NavItem
					to="/slack-logs"
					text="Slack Logs"
					icon="slack"
					iconType="slack"
					currentLink={isSlackLogs}
					linkComponent={Link}
				/>
			) : (
				''
			)}
			{isSuperAdmin ? (
				<NavItem
					to="/troop-admin/volunteer"
					text="Volunteer"
					icon="microsoft-teams"
					iconType="teams"
					currentLink={isTroopAdminEvents}
					linkComponent={Link}
				/>
			) : (
				''
			)}
			{isSuperAdmin ? (
				<NavItem
					to="/teams-logs"
					text="Teams Logs"
					icon="microsoft-teams"
					iconType="teams"
					currentLink={isTeamsLogs}
					linkComponent={Link}
				/>
			) : (
				''
			)}
			{isSuperAdmin && (
				<NavItem
					to="/create-custom-news-item"
					text="Create News Item"
					icon="bell"
					iconType="news"
					currentLink={isCreateNewsItems}
					linkComponent={Link}
				/>
			)}

			<SuperAdminContent>
				<NavItem
					to="/view-all-receipts"
					currentLink={isViewAllReceipts}
					text="View Receipts"
					icon="tasks"
					iconType="hand-holding-heart"
					linkComponent={Link}
				/>
			</SuperAdminContent>

			<SuperAdminContent>
				<NavItem
					to="/writer-charities"
					currentLink={isViewAllCharities}
					text="List of Charities"
					icon="tasks"
					iconType="hand-holding-heart"
					linkComponent={Link}
				/>
			</SuperAdminContent>

			<SuperAdminContent>
				<NavItem
					to="/purchases-and-savings"
					currentLink={isPurchasesAndSavings}
					text="Purchases and Savings"
					icon="tasks"
					iconType="hand-holding-heart"
					linkComponent={Link}
				/>
			</SuperAdminContent>

			<SuperAdminContent>
				<NavItem
					to="/new-registered-charity-admins"
					currentLink={isNewRegisteredCharityAdmins}
					text="Registered Charity Admins"
					icon="tasks"
					iconType="hand-holding-heart"
					linkComponent={Link}
				/>
			</SuperAdminContent>

			<SuperAdminContent>
				<NavItem
					to="/email-logs"
					currentLink={isEmailLogs}
					text="Email Logs"
					icon="tasks"
					iconType="hand-holding-heart"
					linkComponent={Link}
				/>
			</SuperAdminContent>
		</Nav>
	);

	return (
		<>
			{console.log(location.pathname)}
			<Header
				nav={nav}
				opened={navPanelOpened}
				onNavOpen={() => setNavPanelOpened(true)}
				onNavClose={() => setNavPanelOpened(false)}
				onNavChange={setNavPanelOpened}
				linkComponent={Link}
				loggedIn
				loggedInUser={{
					name: isSuperAdmin
						? 'Troop Admin'
						: `${loggedInUser.first_name} ${loggedInUser.last_name}`,
					email: loggedInUser.email,
					avatar: logo,
					userToggle: {
						current: loggedInUser.groups[0],
						toggleTo:
							loggedInUser.groups[0] === 'Business Admin'
								? 'Employee'
								: 'Business Admin',
						toggle: () => {
							dispatch(
								toggleUserGroups({
									groups: [
										loggedInUser.groups[0] === 'Business Admin'
											? 'Employee'
											: 'Business Admin'
									]
								})
							);
							set(
								'userRole',
								loggedInUser.groups[0] === 'Business Admin'
									? 'Employee'
									: 'Business Admin'
							);
						},
						showToggle: wasBusinessAdmin
					},
					userRoleText:
						loggedInUser.groups[0] === 'Business Admin'
							? 'Admin View'
							: 'Employee View',
					toggleToRight:
						loggedInUser.groups[0] === 'Business Admin' ? false : true
				}}
				businessLogo={isBusinessAdmin ? logo : imagePlaceholder}
				textLogoIcon={isSuperAdmin ? '' : business?.name || imagePlaceholder}
				showSettingsNav={isSuperAdmin || isBusinessAdmin}
				showAvatar={isBusinessAdmin || isSuperAdmin ? false : false}
				{...otherProps}
			/>
		</>
	);
}

export default PageHeader;
