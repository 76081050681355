import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';
import Close from 'root/baseComponents/Close/Close';

import styles from '../LoginRegisterForm.module.scss';

function ErrorModal({ errorMessage, setShowErrorMessage }) {
	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'pos-fix pos-fix--lt height-vh-full w-per-100 z-10000',
					styles.modalContainer
				)}>
				<Div
					type="flex"
					direction="vertical"
					distributedBetween
					className="max-w-px-500 w-per-90 card min-height-px-200 pt3 pl2 pr2 pb2 br-rad-px-10 pos-rel">
					<Div>{errorMessage}</Div>
					<Close onClick={() => setShowErrorMessage('')} />
					<Div type="flex" hAlign="end" className="">
						<Button
							className="w-px-100"
							onClick={() => setShowErrorMessage('')}>
							OK
						</Button>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default ErrorModal;
