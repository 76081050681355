import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Div, Row, Column, Image } from 'basedesign-iswad';

import BaseIcon from 'root/baseComponents/Icon';
import { getBackendUrl } from 'root/utils/helpers';

import Text from 'root/baseComponents/Text/Text';
import Close from 'root/baseComponents/Close';
import LimitedSwipableSlider from 'root/baseComponents/LimitedSwipableSlider';
import LimitedSwipableSliderItem from 'root/baseComponents/LimitedSwipableSlider/subs/LimitedSwipableSliderItem';
import styles from '../MyImpact.module.scss';

function KindnessViewModal({ setShowKindnessModal, note, images }) {
	const [moveLeft, setMoveLeft] = useState(false);
	const [moveRight, setMoveRight] = useState(false);
	const [moveToItemWithNum, setMoveToItemWithNum] = useState(1);

	const [visuals, setVisuals] = useState([]);

	useEffect(() => {
		const localVisual = [];
		images?.forEach(pic => {
			localVisual.push(pic);
		});
		setVisuals(localVisual);
	}, [images]);

	return (
		<>
			<Div
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.modalContainer
				)}>
				<Div
					direction="vertical"
					className="pos-fix pos-fix--center card p2 w-per-90 max-w-px-500 min-height-px-200">
					<Close onClick={() => setShowKindnessModal(false)} />

					<Div className="fs-px-14 mb2">
						<Text summerized_max_length={300}>{note}</Text>
					</Div>
					<Div className="w-per-100">
						{images?.length > 0 ? (
							<Div>
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									onClick={setMoveRight}
									className={cx('mouse-hand', styles.rightAngleModal)}>
									<BaseIcon type="arrow" className="z-10000" scale={2} />
								</Div>
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									onClick={setMoveLeft}
									className={cx('mouse-hand', styles.leftAngleModal)}>
									<BaseIcon type="arrow" className="z-10000" scale={2} />
								</Div>
								<LimitedSwipableSlider
									moveLeft={moveLeft}
									setMoveLeft={setMoveLeft}
									moveRight={moveRight}
									setMoveRight={setMoveRight}
									moveToItemWithNum={moveToItemWithNum}
									setMoveToItemWithNum={setMoveToItemWithNum}
									sliderContainerWidthMultiplier={visuals.length}
									minXDifferenceToMove={10}
									notScrollableOnSwipableElement={false}
									transitionDuration={0.3}>
									{visuals.map((item, idx) => (
										<LimitedSwipableSliderItem
											key={idx}
											className={cx('w-per-100 px1')}>
											<Image
												src={item.src}
												className={cx(styles.imageImpact)}
											/>
										</LimitedSwipableSliderItem>
									))}
								</LimitedSwipableSlider>
							</Div>
						) : (
							''
						)}
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default KindnessViewModal;
