import { combineReducers } from 'redux';

import employeesReducer from './employees';
import needsReducer from './needs';
import businessReducer from './businesses';
import usersReducer from './users';
import modalReducer from './modals';
import landingPageReducer from './landingPages';
import impactReportsReducer from './impactReports';
import loadersReducer from './loaders';
import notificationsReducer from './notifications';
import generalReducer from './general';
import featureFlagsReducer from './featureFlags';
import voteCelebration from './voteCelebration';
import isLoggedInToVote from './isLoggedInToVote';
import reminders from './reminders';
import selectedGeeneesWish from './selectedGeeneesWish';
import geenees from './geenees';
import activeDashboardItem from './activeDashboardItem';
import charitySidebarState from './charityReducers/charitySidebarState';
import charityInfo from './charityReducers/charityInfo';
import modalProps from './charityReducers/modalProps';
import modalType from './charityReducers/modalType';
import charityNotifications from './charityReducers/charityNotifications';
import upcomingEvent from './upcomingEvent';
import upcomingEventForPublicUser from './upcomingEventForPublicUser';
import publicModalProps from './publicModalProps';
import publicModalType from './publicModalType';

const rootReducer = combineReducers({
	publicModalProps : publicModalProps,
	publicModalType : publicModalType,
	modalProps: modalProps,
	modalType: modalType,
	charityNotifications: charityNotifications,
	charitySidebarState: charitySidebarState,
	activeDashboardItem: activeDashboardItem,
	charityInfo: charityInfo,
	employees: employeesReducer,
	businesses: businessReducer,
	users: usersReducer,
	modals: modalReducer,
	landingPages: landingPageReducer,
	needs: needsReducer,
	reminders: reminders,
	impactReports: impactReportsReducer,
	loaders: loadersReducer,
	notifications: notificationsReducer,
	general: generalReducer,
	featureFlags: featureFlagsReducer,
	voteCelebration: voteCelebration,
	isLoggedInToVote: isLoggedInToVote,
	selectedGeeneesWish: selectedGeeneesWish,
	geenees: geenees,
	upcomingEvent: upcomingEvent,
	upcomingEventForPublicUser: upcomingEventForPublicUser
});

export default rootReducer;
