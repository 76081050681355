export const businessNameValidators = [
	{ type: 'required', message: 'Business Name is required' },
	{
		type: 'minRequired',
		message: 'Business name must be at least 3 characters',
		minRequired: 3
	},
	{
		type: 'maxRequired',
		message: 'Business name must be less than 100 characters',
		maxRequired: 100
	}
];

export const contactNameValidators = [
	{ type: 'required', message: 'Contact Name is required' },
	{
		type: 'minRequired',
		message: 'Contact name must be at least 3 characters',
		minRequired: 3
	},
	{
		type: 'maxRequired',
		message: 'Contact name must be less than 100 characters',
		maxRequired: 100
	}
];

export const contactEmailValidators = [
	{ type: 'required', message: 'Contact email is required' },
	{
		type: 'email',
		message: 'Must be a valid email address'
	}
];
