import AccountSettings from './AccountSettings/AccountSettings';
import Home from './Home/Home';
import Needs from './Needs/Needs';
import ReferAFriend from './ReferAFriend/ReferAFriend';
import SlackRedirect from './SlackRedirect/SlackRedirect';
import TeamsRedirect from './TeamsRedirect/TeamsRedirect';
import Employees, { AddEmployee } from '../SuperAdmin/Employees';
import Events from './Events/Events';
import SingleEvent from './SingleEvent/SingleEvent';

export const routes = [
	{
		path: '/settings',
		Component: AccountSettings
	},
	{
		path: '/',
		Component: Home
	},
	{
		path: '/refer-a-friend/:businessId',
		Component: ReferAFriend
	},
	{
		path: '/slack-redirect',
		Component: SlackRedirect
	},
	{
		path: '/teams-redirect',
		Component: TeamsRedirect
	},
	{
		path: '/employees',
		Component: Employees
	},
	{
		path: '/businesses/:businessId/add-employee',
		Component: AddEmployee
	},
	{
		path: '/business-events',
		Component: Events
	},
	{
		path: '/business-single-event/:event_id/',
		Component: SingleEvent
	}
].map(route => {
	return {
		...route,
		isPrivate: true
	};
});
