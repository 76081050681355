import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

const initialState = [];

export const notificationSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		addNotification: (state, action) => {
			state.push({
				type: action.payload.type,
				message: action.payload.message,
				options: action.payload.options,
				id: action.payload.id || uuid()
			});
		},
		dismissNotification: (state, action) => {
			return state.filter(notification => notification.id !== action.payload);
		},
		clearNotifications: (state, action) => {
			return [];
		}
	}
});

export default notificationSlice.reducer;
export const { addNotification, dismissNotification, clearNotifications } =
	notificationSlice.actions;
