import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Div, Card, Input, Label } from 'basedesign-iswad';
import { Divider } from 'troop-design';

import Button from 'root/baseComponents/Button/Button';
import CharityWrapper from '../CharityWrapper';
import TextBox from 'root/baseComponents/TextBox/TextBox';
import FormTextArea from 'root/baseComponents/FormTextArea/FormTextArea';
import Form from 'root/baseComponents/Form/Form';
import FormSelect from 'root/baseComponents/FormSelect';
import FormDatePicker from 'root/baseComponents/FormDatePicker';
import { setModalType } from 'root/reducers/charityReducers/modalType';
import { setModalProps } from 'root/reducers/charityReducers/modalProps';
import { needRegisteredByCharity } from 'root/services/charity';

import { monetaryValueValidators, needTitleValidators } from './utils';
import styles from './NewNeed.module.scss';
import FormImagePicker from 'root/baseComponents/FormImagePicker';
import {
	NEED_OWNER_CHOICES,
	MESSAGE_TEXT_AREA_PLACE_HOLDER
} from './constants';

function NewNeed() {
	const location = useLocation();
	// const toBeValidatedFields = [];

	const history = useHistory();
	const dispatch = useDispatch();

	const [pageTitle, setPageTitle] = useState('New Need Setup');

	const [needOwner, setNeedOwner] = useState('');
	const [needOwnerErrorMessage, setNeedOwnerErrorMessage] = useState('');

	const [needRecipient, setNeedRecipient] = useState('');
	const [needRecipientErrorMessage, setNeedRecipientErrorMessage] =
		useState('');

	const [monetaryValue, setMonetaryValue] = useState('');
	const [monetaryValueErrorMessage, setMonetaryValueErrorMessage] =
		useState('');

	const [fundRaisingDate, setFundRaisingDate] = useState('');
	const [fundRaisingDateErrorMessage, setFundRaisingDateErrorMessage] =
		useState('');

	const [coverPhoto, setCoverPhoto] = useState('');
	const [coverPhotoErrorMessage, setCoverPhotoErrorMessage] = useState('');

	const [needTitle, setNeedTitle] = useState('');
	const [needTitleErrorMessage, setNeedTitleErrorMessage] = useState('');

	const [message, setMessage] = useState('');
	const [messageErrorMessage, setMessageErrorMessage] = useState('');

	const [details, setDetails] = useState('');
	const [detailsErrorMessage, setDetailsErrorMessage] = useState('');

	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

	useEffect(() => {
		if (location?.state?.value) {
			setMonetaryValue(location.state.value);
		}
		if (location?.state?.message) {
			setMessage(location.state.message);
		}
		if (location?.state?.title) {
			setNeedTitle(location.state.title);
		}
		if (location?.state?.owner) {
			setNeedOwner(location.state.owner);
		}
		if (location?.state?.pageTitle) {
			setPageTitle(location.state.pageTitle);
		}
	}, [location]);

	const toBeValidatedFields = [];

	const customValidations = () => {
		let validated = true;

		if (!needOwner) {
			setNeedOwnerErrorMessage('Need Owner is required');
			validated = false;
		}

		if (!message) {
			setMessageErrorMessage('You must send a message to us!');
			validated = false;
		}

		if (isNaN(monetaryValue) !== false) {
			setMonetaryValueErrorMessage('Please enter a valid amount');
			validated = false;
		}

		if (!monetaryValue) {
			setMonetaryValueErrorMessage('Monetary Value is required');
			validated = false;
		}

		if (!needTitle) {
			setNeedTitleErrorMessage('Need title is required');
			validated = false;
		}

		if (dayjs(fundRaisingDate).isBefore(dayjs(new Date()))) {
			setFundRaisingDateErrorMessage('The deadline should be in future.');
			validated = false;
		}

		return validated;
	};

	const submitHandler = async e => {
		if (customValidations()) {
			setIsSubmitDisabled(true);
			const formData = new FormData();
			formData.append('need_owner', needOwner);
			formData.append('need_recipient', needRecipient);
			formData.append('monetary_value', monetaryValue);
			if (fundRaisingDate) {
				formData.append(
					'fundraising_date',
					dayjs(fundRaisingDate).format('YYYY-MM-DD')
				);
			}
			formData.append('cover_photo', coverPhoto);
			formData.append('title', needTitle);
			formData.append('message', message);
			formData.append('other_details', details);
			formData.append('charity', 'charityId');
			formData.append('charity_user', 'charityUserId');
			try {
				const res = await needRegisteredByCharity(formData);
				if (res?.id) {
					dispatch(setModalType('success'));
					dispatch(
						setModalProps({
							text: 'Thank you for submitting your need. The Troop team is on the case. We will review and publish your need within 5 business days. If we have any questions, we’ll reach out!',
							header: 'Need submitted!'
						})
					);
					history.push('/');
				} else {
					dispatch(setModalType('danger'));
					dispatch(
						setModalProps({
							text: `${res.message}`,
							header: 'Error!'
						})
					);
					setIsSubmitDisabled(false);
				}
			} catch (err) {
				console.log(err);
				dispatch(setModalType('danger'));
				dispatch(
					setModalProps({
						text: 'Something went wrong; please try again!',
						header: 'Error!'
					})
				);
				setIsSubmitDisabled(false);
			}
		}
	};

	return (
		<>
			<CharityWrapper pageName="">
				<Div className={cx(styles.main)}>
					<Div className="employeeHeader mb2">{pageTitle}</Div>

					<Card className={cx('card pl8 pr8 pb3', styles.card)}>
						<Div className="mt3 flex flex--jc--center flex--ai--center textPrimary fs-px-20 f-b">
							Tell us a bit about your Need
						</Div>
						<Div className="mt1 mb2 flex flex--jc--center flex--ai--center textFaded f-b">
							Let's make it shine! ✨
						</Div>
						<Div className="flex flex--jc--center flex--ai--center">
							<Divider width={200} />
						</Div>
						<Form
							toBeValidatedFields={toBeValidatedFields}
							className={cx(styles.form)}
							onSubmit={submitHandler}>
							<FormSelect
								className={cx('mb4')}
								labelText="Who is this need for"
								options={NEED_OWNER_CHOICES}
								fullWidth
								openOptionsDownWard={true}
								isRequired
								val={needOwner}
								setVal={setNeedOwner}
								errorMessage={needOwnerErrorMessage}
								errorHandler={setNeedOwnerErrorMessage}
							/>

							{needOwner === 'individual' && (
								<TextBox
									labelText="Need recipient (this could be an alias, anything to help keep track if you have needs for multiple people)"
									type="text"
									val={needRecipient}
									setVal={setNeedRecipient}
									errorHandler={setNeedRecipientErrorMessage}
									name="need_recipient"
									errorMessage={needRecipientErrorMessage}
								/>
							)}

							<TextBox
								labelText=" Value (a rough idea of the total cost is all we need here!)"
								type="text"
								val={monetaryValue}
								setVal={setMonetaryValue}
								errorHandler={setMonetaryValueErrorMessage}
								name="monetary_value"
								errorMessage={monetaryValueErrorMessage}
								inputClassName="max-w-px-100"
								hasIcon={true}
								iconType="dollar"
								iconColor="gray"
							/>

							<FormDatePicker
								labelText="When is it needed by? If there is no deadline, leave it blank."
								chosenDate={fundRaisingDate}
								setChosenDate={setFundRaisingDate}
								errorMessage={fundRaisingDateErrorMessage}
								errorHandler={setFundRaisingDateErrorMessage}
								inputClassName={'max-w-px-200'}
								placeHolder="DD-MM-YYYY"
								isRequired={false}
							/>

							<FormImagePicker
								labelText="Add a cover photo (not mandatory!  We’ve got you covered if you don’t have a pic)"
								file={coverPhoto}
								setFile={setCoverPhoto}
								errorMessage={coverPhotoErrorMessage}
								errorHandler={setCoverPhotoErrorMessage}
								id="coverPhotoCharityRegistrationPage"
								hasCropper={true}
								cropInfo={{ aspect: 1.5 }}
							/>

							<TextBox
								labelText="Give your need a title (don’t worry, the Troop team will jump in and make it catchy!)"
								type="text"
								val={needTitle}
								setVal={setNeedTitle}
								errorHandler={setNeedTitleErrorMessage}
								name="need_title"
								errorMessage={needTitleErrorMessage}
								placeholder="e.g. School Supplies for New Canadians"
							/>

							<FormTextArea
								isRequired
								labelText=" Tell us more (give us all the details so we can write up a brief blurb that will get your need the votes it needs to get fulfilled)."
								placeHolder={MESSAGE_TEXT_AREA_PLACE_HOLDER}
								val={message}
								setVal={setMessage}
								errorMessage={messageErrorMessage}
								errorHandler={setMessageErrorMessage}
								id="messageFieldHomePage"
							/>

							<FormTextArea
								labelText="Other details (like links to specific products, size details for clothing, quantity needed… anything like that)."
								placeHolder=""
								val={details}
								setVal={setDetails}
								errorMessage={detailsErrorMessage}
								errorHandler={setDetailsErrorMessage}
								id="detailFieldHomePage"
							/>

							<div className={cx('my2 flex flex--jc--end')}>
								<Button disabled={isSubmitDisabled}>Submit</Button>
							</div>
						</Form>
					</Card>
				</Div>
			</CharityWrapper>
		</>
	);
}

export default NewNeed;
