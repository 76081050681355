import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Div, Row, Column, Image } from 'basedesign-iswad';
import Close from 'root/baseComponents/Close';

import styles from '../MyImpact.module.scss';

function PictureModal({ setShowPictureModal, src }) {
	return (
		<>
			<Div
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.modalContainer
				)}>
				<Div
					className={cx(
						'pos-fix pos-fix--center mt2 card p2 w-per-90 max-w-px-500'
					)}>
					<Close onClick={() => setShowPictureModal(false)} />
					<Image src={src} className={cx('mt1')} />
				</Div>
			</Div>
		</>
	);
}

export default PictureModal;
