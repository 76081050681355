import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import { resetForDemo } from 'root/services/report';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import Button from 'root/baseComponents/Button';
import Close from 'root/baseComponents/Close';

const ResetForDemo = () => {
	const dispatch = useDispatch();

	const [sendReq, setSendReq] = useState(false);
	const [resetDone, setResetDone] = useState(false);
	const [timer, setTimer] = useState(5);

	const resetForDemoData = async () => {
		dispatch(setLoading());
		try {
			const res = await resetForDemo();
			if (res?.success) {
				setResetDone(true);
			}
			dispatch(setLoadingComplete());
		} catch (err) {
			console.log(err);
			dispatch(setLoadingComplete());
		}
	};

	useEffect(() => {
		if (sendReq) {
			resetForDemoData();
		}
	}, [sendReq]);

	useEffect(() => {
		if (resetDone) {
			setTimeout(() => {
				setTimer(timer - 1);
			}, 1000);
		}
	}, [resetDone]);

	useEffect(() => {
		console.log(timer);
		if (timer > 0 && resetDone) {
			setTimeout(() => {
				setTimer(timer - 1);
			}, 1000);
		} else {
			setResetDone(false);
			setTimer(5);
		}
	}, [timer]);

	return (
		<>
			<Button onClick={() => setSendReq(true)}>Reset For The Demo</Button>
			{resetDone && (
				<Div className="bgSuccess p2 my2 textWhite f-b w-per-80 pos-rel">
					<Div>Successfully removed required data for the demo</Div>
					<Close
						onClick={() => {
							setResetDone(false);
							setTimer(5);
						}}
					/>
				</Div>
			)}
		</>
	);
};

export default ResetForDemo;
