import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Div, Heading, Row, Column } from 'basedesign-iswad';

import { retrieveSingleNeed } from 'root/services/needs';

import PageContainer from '../components/PageContainer';

import NeedInfo from './subs/NeedInfo';
import NeedSummary from './subs/NeedSummary';
import FulfilledNeeds from './subs/FulfilledNeeds';
import styles from './SingleNeed.module.scss';

const SingleNeed = () => {
	const params = useParams();

	const { charity_name, need_id } = params;

	const [need, setNeed] = useState({});

	const retrieveSingleNeedData = async () => {
		try {
			const res = await retrieveSingleNeed(
				charity_name,
				need_id.replace('tr-', '')
			);
			if (res?.need) {
				setNeed(res.need);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		console.log(charity_name);
		if (charity_name && need_id) {
			retrieveSingleNeedData();
		}
	}, [charity_name, need_id]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<PageContainer>
				<NeedInfo need={need} />
				<NeedSummary need={need} />
				<FulfilledNeeds />
			</PageContainer>
		</>
	);
};

export default SingleNeed;
