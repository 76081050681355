export const format = (
	money,
	locale = 'en-CA',
	currency = 'CAD',
	minimumFractionDigits = 2
) => {
	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
		minimumFractionDigits
	}).format(money);
};

const exports = {
	format
};

export default exports;
