import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import {
	retrieveNeeds,
	duplicateNewNeed,
	retrieveFilteredNeeds,
	adminUpdateNeedStatus
} from 'root/services/needs';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';
import Search from 'root/baseComponents/Search';
import Icon from 'root/baseComponents/Icon';

import Wrapper from '../components/Wrapper';
import ConfirmModal from './subs/ConfirmModal';
import styles from './Needs.module.scss';

const Charities = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [filters, setFilters] = useState({ page: 1 });
	const [currentPage, setCurrentPage] = useState(1);
	const [needs, setNeeds] = useState([]);
	const [numberOfAllPages, setNumberOfAllPages] = useState(1);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [modalNeedName, setModalNeedName] = useState('');
	const [modalNeedId, setModalNeedId] = useState('');
	const [modalAction, setModalAction] = useState('');

	const retrieveNeedsData = async () => {
		dispatch(setLoading());
		try {
			const res = await retrieveFilteredNeeds(filters);
			dispatch(setLoadingComplete());
			const localNeeds = [];
			res.needs.forEach(item => {
				localNeeds.push({
					id: item.id,
					name: item.name,
					charity: item.charity_name,
					short_description: item.short_description,
					status: item.cur_status,
					charity_slug : item.charity_slug
				});
				console.log(item);
			});
			setNeeds(localNeeds);
			setNumberOfAllPages(parseInt(res.number_of_needs / 10) + 1);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (currentPage) {
			retrieveNeedsData();
		}
	}, [filters]);

	useEffect(() => {
		const localFilters = { ...filters };
		localFilters['page'] = currentPage;
		setFilters(localFilters);
	}, [currentPage]);

	const headLines = [
		{
			value: 'id',
			display: (
				<Div type="flex" vAlign="center">
					<Div>ID</Div>
					<Div>
						<Search
							onChange={e => {
								setCurrentPage(1);
								setTimeout(() => {
									const localFilters = { ...filters };
									localFilters['id'] = parseInt(e.target.value);
									setFilters(localFilters);
								}, 500);
							}}
						/>
					</Div>
				</Div>
			),
			width: 200,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'name',
			display: (
				<Div type="flex" vAlign="center">
					<Div>Name</Div>
					<Div>
						<Search
							onChange={e => {
								setCurrentPage(1);
								setTimeout(() => {
									const localFilters = { ...filters };
									localFilters['name'] = e.target.value;
									setFilters(localFilters);
								}, 500);
							}}
						/>
					</Div>
				</Div>
			),
			width: 250,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'charity_name',
			display: (
				<Div type="flex" vAlign="center">
					<Div>Charity</Div>
					<Div>
						<Search
							onChange={e => {
								setCurrentPage(1);
								setTimeout(() => {
									const localFilters = { ...filters };
									localFilters['charity_name'] = e.target.value;
									setFilters(localFilters);
								}, 500);
							}}
						/>
					</Div>
				</Div>
			),
			width: 250,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'short_description',
			display: (
				<Div type="flex" vAlign="center">
					<Div>Short Description</Div>
					<Div>
						<Search
							onChange={e => {
								setCurrentPage(1);
								setTimeout(() => {
									const localFilters = { ...filters };
									localFilters['short_description'] = e.target.value;
									setFilters(localFilters);
								}, 500);
							}}
						/>
					</Div>
				</Div>
			),
			width: 250,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'status',
			display: 'Status',
			width: 200,
			hasSearch: false,
			isSortable: false
		},
	];

	const data = useMemo(() => {
		if (needs) {
			const tableData = [];
			needs?.forEach(item => {
				tableData.push({
					id: {value: item.id.toString(),
					display:
					 <Div type='flex'>
						<Div className='mr2'>
						{item.id.toString()}
						</Div>
						<Div>
							<Div
								className="mouse-hand mr1"
								onClick={() => history.push(`/writer-needs/${item.id}`)}>
								<Icon type="edit" scale={1.2} />
							</Div>
						</Div>
						<Div>
							<Div
								className="mouse-hand mr1"
								onClick={async () => {
									try {
										const res = await duplicateNewNeed({ need_id: item.id });
										history.go(0);
									} catch (err) {
										console.log(err);
									}
								}}>
								<Icon type="copy"  scale={1.2} />
							</Div>
						</Div>
						<Div>
							<Div
								className="mouse-hand"
								onClick={() => {
									setModalNeedName(item.name);
									setModalAction('delete');
									setModalNeedId(item.id);
									setShowConfirmModal(true);
								}}>
								<Icon type="delete"  scale={1.2}/>
							</Div>
						</Div>
						<Div>
							<Div
								className="mouse-hand mr1 ml1">
								<a href={`https://hitroop.com/needs/${item?.charity_slug}/tr-${item.id}`} target='_blank'>
									<Icon type="link" scale={1.2} />
								</a>
							</Div>
						</Div>
					</Div>},
					name: item.name,
					charity_name: item.charity,
					short_description: item.short_description,
					status: {
						value: item.status,
						display: (
							<>
								{item.status === 'Paused' && (
									<Div type="flex">
										<Div className="mr2">{item.status}</Div>
										<Div
											className="mouse-hand"
											onClick={() => {
												setModalNeedName(item.name);
												setModalAction('live');
												setModalNeedId(item.id);
												setShowConfirmModal(true);
											}}>
											<Icon type="play" />
										</Div>
									</Div>
								)}
								{item.status === 'Live' && (
									<Div type="flex">
										<Div className="mr2">{item.status}</Div>
										<Div
											className="mouse-hand"
											onClick={() => {
												setModalNeedName(item.name);
												setModalAction('pause');
												setModalNeedId(item.id);
												setShowConfirmModal(true);
											}}>
											<Icon type="pause" />
										</Div>
									</Div>
								)}
								{item.status !== 'Live' && item.status !== 'Paused' ? (
									<Div type="flex">
										<Div className="mr2">{item.status}</Div>
									</Div>
								) : (
									''
								)}
							</>
						)
					}
				});
			});
			return tableData;
		} else {
			return [];
		}
	}, [needs]);

	return (
		<>
			<Wrapper pageName="Needs">
				<Div className="p2">
					<Button
						className="w-px-250"
						onClick={() => history.push('/writer-add-need')}>
						Create New Need
					</Button>
				</Div>
				<Div className="pt4 w-per-100 pr2 pl2 flex flex--jc--start of-x-auto scrollType1">
					<Table
						headLines={headLines}
						data={data}
						// colWidth={400}
						// tableWidth={'1000px'}
						isSortable={true}
						isSelectable={false}
						selectedData={selectedData}
						setSelectedData={setSelectedData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={5}
						numberOfAllPages={numberOfAllPages}
					/>
				</Div>
				{showConfirmModal && (
					<ConfirmModal
						modalNeedName={modalNeedName}
						modalNeedId={modalNeedId}
						setShowConfirmModal={setShowConfirmModal}
						modalAction={modalAction}
					/>
				)}
			</Wrapper>
		</>
	);
};

export default Charities;
