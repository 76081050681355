// VENDOR imports
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

const calculateMonthsActive = startDate => {
	dayjs.extend(relativeTime);
	dayjs.extend(updateLocale);
	dayjs.updateLocale('en', {
		relativeTime: {
			s: '0',
			m: '0',
			mm: '0',
			h: '0',
			hh: '0',
			d: '0',
			dd: '0',
			M: '%d',
			MM: '%d',
			y: '%d',
			yy: '%d'
		}
	});
	return startDate.fromNow(true);
};

export { calculateMonthsActive };
