export const organizationNameValidators = [
	{ type: 'required', message: 'Organization name is required' },
	{
		type: 'minRequired',
		message: ' name must be at least 2 characters',
		minRequired: 2
	},
	{
		type: 'maxRequired',
		message: 'Organization name must be less than 100 characters',
		maxRequired: 100
	}
];

export const firstNameValidators = [
	{ type: 'required', message: 'First name is required' },
	{
		type: 'maxRequired',
		message: 'First name must be less than 100 characters',
		maxRequired: 100
	}
];

export const lastNameValidators = [
	{ type: 'required', message: 'Last name is required' },
	{
		type: 'maxRequired',
		message: 'Last name must be less than 100 characters',
		maxRequired: 100
	}
];

export const emailValidators = [
	{ type: 'required', message: 'Email is required' },
	{
		type: 'email',
		message: 'Must be a valid email address'
	}
];

export const passwordValidators = [
	{ type: 'required', message: 'Password is required' },
	{
		type: 'minRequired',
		message: 'Password must be at least 6 characters',
		minRequired: 6
	},
	{
		type: 'maxRequired',
		message: 'Password be less than 100 characters',
		maxRequired: 100
	}
];

// ------------------------------
// ------------------------------
// ------------------------------
// ------------------------------

export const schoolDistrictValidators = [
	{ type: 'required', message: 'School district is required' }
];

export const registeredCharityNameValidators = [
	{ type: 'required', message: 'Registered Charity Name is required' }
];

export const registeredCharityNumberValidators = [
	{ type: 'required', message: 'Registered Charity Number is required' }
];

export const orgWebsiteValidators = [
	{ type: 'required', message: 'Organization Website is required' }
];

export const contactNumberValidators = [
	{ type: 'required', message: 'Contact Number is required' }
];

export const cityValidators = [
	{ type: 'required', message: 'City is required' }
];

export const postalCodeValidators = [
	{ type: 'required', message: 'Postal Code is required' },
	{
		type: 'minRequired',
		message: ' Postal Code must be at least 4 characters',
		minRequired: 4
	},
	{
		type: 'maxRequired',
		message: 'Organization name must be less than 9 characters',
		maxRequired: 9
	}
];

export const monetaryValueValidators = [
	{ type: 'required', message: 'Monetary Value is required' },
	{
		type: 'number',
		message: 'Please enter a valid amount'
	}
];

export const needTitleValidators = [
	{ type: 'required', message: 'Need Title is required' }
];
