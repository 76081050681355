import React, { useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Button, Heading, Icon, Tutorial } from 'troop-design';

import Modal from 'components/Modal';

import { hideModal } from 'reducers/modals';
import { del } from 'utils/storage';
import { createUSerEvent } from 'services/user';

import tutorial1 from 'assets/Tutorial - Community Needs Photo - 1.png';
import tutorial2 from 'assets/Tutorial - Action Board Photo - 2.png';
import tutorial3 from 'assets/Tutorial - Personal Pledging Photo - 3.png';
import tutorial4 from 'assets/Tutorial - Vote Streak Photo - 4.png';
import tutorial5 from 'assets/Tutorial - My Impact Photo - 5.png';

import styles from './PledgeTutorial.module.scss';

const PledgeTutorial = () => {
	const dispatch = useDispatch();

	const [tutorialSlideNumber, setTutorialSlideNumber] = useState(-1);

	const tutorialData = React.useMemo(
		() => [
			{
				imgSrc: tutorial1,
				imgAlt: 'community needs tutorial image',
				modalTitle: 'Community Needs',
				modalContent:
					'Here’s a summary of your do good options for the month.\n\nAfter you vote you can Pledge to help ensure your favorite need gets fulfilled!!',
				modalArrowPosition: 'leftTop',
				modalButtonText: 'Next',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '74%', top: '7.5%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '74%', top: '7.5%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			},
			{
				imgSrc: tutorial2,
				imgAlt: 'action board tutorial image',
				modalTitle: 'Action Board',
				modalContent:
					'Keep up to date with vote and pledge dates, community events, and your workplace activity!',
				modalArrowPosition: 'rightTop',
				modalButtonText: 'Next',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '53%', top: '7.5%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '53%', top: '7.5%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			},
			{
				imgSrc: tutorial3,
				imgAlt: 'personal pledging tutorial image',
				modalTitle: 'Personal Pledging',
				modalContent:
					'See your recent pledges and add funds to fulfill more needs.\n\nIf you make a pledge and the target value isn’t hit, your funds will go back into your account to be used to fulfill more needs in the future.',
				modalArrowPosition: 'bottomRight',
				modalButtonText: 'Next',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '15%', top: '24%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '15%', top: '24%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			},
			{
				imgSrc: tutorial4,
				imgAlt: 'your vote streak image tutorial',
				modalTitle: 'Your Vote Streak',
				modalContent: 'Every vote matters! How high will you go?!',
				modalArrowPosition: 'bottomRight',
				modalButtonText: 'Next',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '40%', top: '40%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '40%', top: '40%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			},
			{
				imgSrc: tutorial5,
				imgAlt: 'alt text for testing',
				modalTitle: 'My Impact',
				modalContent:
					'See your impact up close and personal with impact stories from the people and communities you’ve helped!',
				modalArrowPosition: 'leftTop',
				modalButtonText: 'Done',
				responsive: {
					desktop: {
						breakpoint: { max: 3000, min: 1024 },
						position: { left: '13%', top: '28%' }
					},
					tablet: {
						breakpoint: { max: 1024, min: 464 },
						position: { left: '13%', top: '35%' }
					},
					mobile: {
						breakpoint: { max: 464, min: 0 },
						position: { display: 'none' }
					}
				}
			}
		],
		[]
	);

	return (
		<>
			<Modal
				hasBlackBg={false}
				dismissable={false}
				reduxKey="pledgeTutorial"
				size="xs">
				<div className={cx(styles.modalMainContainer)}>
					<div
						className={cx(styles.modalClose)}
						onClick={() => {
							del('stripe_payment_status');
							dispatch(hideModal('pledgeTutorial'));
						}}>
						<div className={cx(styles.modalCloseContainer)}>
							<div>
								<Icon icon="close" width={15} color="black" />
							</div>
						</div>
					</div>
					<div className={styles.modalHeader}>
						<Heading level={2}>Hello 👋</Heading>
					</div>
					<div className={cx(styles.modalBody)}>
						<div className={cx(styles.modalBodyExplanation)}>
							<p>
								We've got some shiny new features that we’re excited to show
								you! Take a look.
							</p>
						</div>
						<Button
							size="sm"
							variant="primary"
							onClick={() => {
								dispatch(hideModal('pledgeTutorial'));
								setTutorialSlideNumber(tutorialSlideNumber + 1);
							}}>
							Start Tutorial!
						</Button>
					</div>
				</div>
			</Modal>
			<Tutorial
				slides={tutorialData}
				currentSlide={tutorialSlideNumber}
				nextStep={() => {
					if (tutorialData.length === tutorialSlideNumber + 1) {
						setTutorialSlideNumber(-1);
					} else {
						setTutorialSlideNumber(tutorialSlideNumber + 1);
					}
				}}
				closeTutorial={() => {
					setTutorialSlideNumber(-1);
				}}
			/>
		</>
	);
};

export default PledgeTutorial;
