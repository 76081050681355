import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import NeedCard from 'root/baseComponents/NeedCard';
import Wrapper from '../components/Wrapper';

import { getBackendUrl } from 'root/utils/helpers';

import { getInReviewNeeds, getAllCharities } from 'root/services/charity';

import InfoRow from './subs/InfoRow';
import styles from './NeedsToReviewDetails.module.scss';

function NeedsToReviewDetails() {
	const { id } = useParams();

	const [charityInfo, setCharityInfo] = useState({});
	const [approvedNeeds, setApprovedNeeds] = useState([]);

	const getInReviewNeedsData = async () => {
		const res = await getInReviewNeeds({ id, status: 'approved' });
		setApprovedNeeds(res);
	};

	const getCharityInfo = async () => {
		const res = await getAllCharities(id);
		if (res?.[0]) {
			setCharityInfo(res[0]);
		}
	};

	useEffect(() => {
		if (id) {
			getCharityInfo();
			getInReviewNeedsData();
		}
	}, [id]);

	return (
		<>
			<Wrapper pageName="Needs">
				<Div className={cx('pt2 mb2')}>Charity Information</Div>
				<Div className={cx('card p2')}>
					<InfoRow title="Name" info={charityInfo?.name} />
					<InfoRow title="City" info={charityInfo?.city} />
					<InfoRow title="Province" info={charityInfo?.province} />
					<InfoRow title="Postal Code" info={charityInfo?.postal_code} />
					<InfoRow title="Website" info={charityInfo?.website} />
				</Div>
				<Div className={cx('pt2 mb2')}>New Needs</Div>
				<Div>
					{approvedNeeds.map(n => {
						return (
							<Div className="mb2">
								<NeedCard
									needId={n.id}
									imageSrc={`${getBackendUrl()}${n.cover_photo}`}
									needName={n.title}
									target={n.monetary_value}
									closeDate={n.fundraising_date}
									status={n.status}
									isTroopAdmin
								/>
							</Div>
						);
					})}
				</Div>
			</Wrapper>
		</>
	);
}

export default NeedsToReviewDetails;
