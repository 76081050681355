import React from 'react';
import cx from 'classnames';
import { Card, Row, Col, Image, Heading, Text } from 'troop-design';
import logo from 'assets/logo.png';
import styles from './RegisterContainer.module.scss';

export default function RegisterContainer({ children }) {
	return (
		<div className={'container'}>
			<Row className="justify-content-center align-items-center w-100">
				<Col md={10}>
					<Card className={styles.loginContainer}>
						<div className={styles.logoContainer}>
							<Image src={logo} className={styles.logo} />
						</div>
						<Row className={styles.headingContainer}>
							<Heading level={1} className={cx('col-12', styles.heading)}>
								Troop Impact Program Sign Up
							</Heading>
							<Col sm={12}>
								<Text className={'col-12'} tag={'p'}>
									Welcome to Troop! Let's get your Local Impact Program set up
								</Text>
							</Col>
						</Row>
						<Row className={cx('mt-5', styles.formContainer)}>
							<Col md={12}>{children}</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</div>
	);
}
