import { useSelector } from 'react-redux';
import useLoggedIn from './useLoggedIn';

export default function useBusinessAdminLoggedIn() {
	const loggedIn = useLoggedIn();
	const isBusinessAdmin = useSelector(state =>
		state.users.currentUserRoles.includes('Business Admin')
	);
	return loggedIn && isBusinessAdmin;
}
