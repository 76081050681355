import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Alert, Text } from 'troop-design';
import { dismissNotification } from 'reducers/notifications';

export default function useNotifications() {
	const history = useHistory();
	const notifications = useSelector(state => state.notifications);
	const dispatch = useDispatch();

	const dismiss = useCallback(
		notificationId => dispatch(dismissNotification(notificationId)),
		[]
	);

	useEffect(() => {
		return history.listen(location => {
			notifications.map(notification => {
				dispatch(dismissNotification(notification.id));
			});
		});
	}, [history, notifications, dispatch]);

	return notifications.map((notification, i) => (
		<Alert
			key={`${notification.id}`}
			type={notification.type}
			{...notification.options}
			onDismiss={() => dismiss(notification.id)}
			style={{ display: 'flex', top: 0, left: 0, zIndex: 1000 }}>
			<Text hasWrapper isHtml content={notification.message} />
		</Alert>
	));
}
