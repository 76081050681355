import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Close from 'root/baseComponents/Close';
import Button from 'root/baseComponents/Button';

import { deleteNeed, adminUpdateNeedStatus } from 'root/services/needs';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import styles from '../Needs.module.scss';

const ConfirmModal = ({ modalAction, modalNeedName, modalNeedId, setShowConfirmModal }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const pauseNeedbyId = async () => {
		await adminUpdateNeedStatus({
			id: modalNeedId,
			changed_status: 'Paused'
		});
		history.go(0);
	}

	const liveNeedbyId = async () => {
		await adminUpdateNeedStatus({
			id: modalNeedId,
			changed_status: 'Open'
		});
		history.go(0);
	}
	
	const deleteNeedbyId = async () => {
		await deleteNeed(modalNeedId);
		history.go(0);
	}
	
	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'pos-fix pos-fix--lt height-vh-full w-per-100 z-1000',
					styles.modalMainContainer
				)}>
				<Div className="pos-rel bgWhite w-per-80 max-w-px-500 p2">
					<Close onClick={() => setShowConfirmModal(false)} />
					{modalAction === 'delete' && 	
					<Div className="my2">
						Are you sure you want to delete need "{modalNeedName}"?
					</Div>}
					{modalAction === 'live' && 	
					<Div className="my2">
						Are you sure you want to make need "{modalNeedName}" live?
					</Div>}
					{modalAction === 'pause' && 	
					<Div className="my2">
						Are you sure you want to pause need "{modalNeedName}"?
					</Div>}
					<Button
						className="w-px-100 mr2"
						colorType="danger"
						onClick={async () => {
							dispatch(setLoading());
							if (modalAction === 'pause'){
								pauseNeedbyId();
							} else if (modalAction === 'delete') {
								deleteNeedbyId()
							}else if (modalAction === 'live') {
								liveNeedbyId()
							}
							dispatch(setLoadingComplete());
							setShowConfirmModal(false);
						}}>
						Yes
					</Button>
					<Button
						className="w-px-100"
						colorType="transparent"
						onClick={() => setShowConfirmModal(false)}>
						No
					</Button>
				</Div>
			</Div>
		</>
	);
};

export default ConfirmModal;
