import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';

import styles from './EligibleForTax.module.scss';

const EligibleForTax = ({ isEligible = true }) => {
	const [containerIsActive, setContainerIsActive] = useState(false);
	const [showText, setShowText] = useState(false);

	useEffect(() => {
		if (containerIsActive) {
			setShowText(true);
		} else {
			setShowText(false);
		}
	}, [containerIsActive]);

	return (
		<>
			<Div
				type="flex"
				className={cx(
					'of-hidden oneLine',
					styles.container,
					containerIsActive && styles.containerIsActive
				)}
				style={{ backgroundColor: '#ccc', borderRadius: '5px' }}
				onMouseEnter={() => setContainerIsActive(true)}
				onMouseLeave={() => setContainerIsActive(false)}>
				{showText && (
					<Div className="fs-px-10 p1 f-b">
						{isEligible
							? 'Eligible for charity tax receipt'
							: 'Not eligible for tax receipt'}
					</Div>
				)}
				<Div
					type="flex"
					hAlign="center"
					vAlign="center"
					className="pos-rel w-px-20 height-px-30">
					<Icon type="receipt" scale={1.0} color="white" />
					<Div className={cx('pos-abs', styles.checkMarkContainer)}>
						{isEligible ? (
							<Icon type="check" color="green" scale={0.8} />
						) : (
							<Icon type="close" color="red" scale={0.8} />
						)}
					</Div>
				</Div>
			</Div>
		</>
	);
};

export default EligibleForTax;
