import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import TextBox from 'root/baseComponents/TextBox';
import Form from 'root/baseComponents/Form/Form';
import Button from 'root/baseComponents/Button/Button';
import Modal from 'components/Modal';
import { showModal, hideModal } from 'reducers/modals';
import Close from 'root/baseComponents/Close';
import Icon from 'root/baseComponents/Icon';
import { addParticipantToEvent } from 'root/services/event';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import ShowConfirmationModal from 'root/baseComponents/GetConfirmationModal/ShowConfirmationModal';

import styles from './EventForm.module.scss';

const EmployeeProfileForm = ({ useForm = false }) => {
	const dispatch = useDispatch();
	const upcomingEvent = useSelector(state => state.upcomingEvent);
	const curBusiness = useSelector(state => state.businesses?.businesses?.[0]);

	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const [date, setDate] = useState('');
	const [time, setTime] = useState('');
	const [location, setLocation] = useState('');

	const [name, setName] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');

	const [email, setEmail] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	const [eventName, setEventName] = useState('Event_Name');
	const [eventManager, setEventManager] = useState('');
	const [eventDescription, setEventDescription] = useState('');

	useEffect(() => {
		if (myProfile?.id) {
			setName(`${myProfile?.user?.first_name} ${myProfile?.user?.last_name}`);
			setEmail(myProfile?.user?.email);
		}
	}, [myProfile]);

	const handleSubmit = async e => {
		e.preventDefault();
	};

	return (
		<>
			<Modal
				dismissable={false}
				reduxKey="event-form"
				size="md"
				useRegularTheme={false}>
				{!showSuccessModal ? (
					<Div className="pos-rel">
						<Close onClick={() => dispatch(hideModal('event-form'))} />
						<Div
							type="flex"
							vAlign="center"
							hAlign="center"
							className="w-per-100 bgPrimary of-hidden f-b fs-px-24 textWhite height-px-50">
							<Div className="">{upcomingEvent?.name}</Div>
						</Div>
						<Div
							className={cx(
								'pr4 pl4 of-y-auto scrollType1',
								styles.eventContainer
							)}>
							<Div className="text-center mt3 mb1 f-b fs-px-20">
								Sign Me Up!
							</Div>
							<Div className="fs-px-14 textGray text-center mb2">
								{upcomingEvent?.description}
							</Div>
							<Div
								type="flex"
								direction="vertical"
								hAlign="start"
								className="mb2">
								<Div type="flex" vAlign="center" className="mb1">
									<Div>
										<Icon type="date" color="black" />
									</Div>
									<Div className="ml2">{upcomingEvent?.date}</Div>
								</Div>
								<Div type="flex" vAlign="center" className="mb1">
									<Div>
										<Icon type="clock" color="black" />
									</Div>
									<Div className="ml2">
										{upcomingEvent?.time}
										{upcomingEvent?.end_time && ` - ${upcomingEvent?.end_time}`}
									</Div>
								</Div>
								<Div type="flex" vAlign="center" className="mb1">
									<Div>
										<Icon type="location" color="black" />
									</Div>
									<Div className="ml2">{upcomingEvent?.location}</Div>
								</Div>
								<Div className="text-center textGray">
									Managed by {upcomingEvent?.managed_by}
								</Div>
							</Div>
							{upcomingEvent?.register_status === 'upcoming' && (
								<>
									{useForm ? (
										<Form onSubmit={handleSubmit} className="">
											<Div>
												<Div className="w-per-100">
													<TextBox
														labelText="Name"
														type="text"
														val={name}
														setVal={setName}
														errorHandler={setNameErrorMessage}
														name="name"
														errorMessage={nameErrorMessage}
														className={cx('mr2 w-px-100')}
													/>
													<TextBox
														labelText="Email"
														type="text"
														val={email}
														setVal={setEmail}
														errorHandler={setEmailErrorMessage}
														name="email"
														errorMessage={emailErrorMessage}
														className={cx('mr2 w-px-100')}
													/>
												</Div>
											</Div>
											<Div type="flex" hAlign="center">
												<Button type="submit" className="w-px-200 mouse-hand">
													Sign Me Up to Help
												</Button>
											</Div>
										</Form>
									) : (
										<Div
											type="flex"
											direction="vertical"
											className="textGray fs-px-14">
											<Div className="my2">Your Name: {name}</Div>
											<Div className="mb2">Your Email: {email}</Div>

											<Div type="flex" hAlign="center" className="my2">
												<Button
													type="submit"
													className="w-px-200 mouse-hand"
													onClick={async () => {
														const data = { event_id: upcomingEvent?.id };
														dispatch(setLoading());
														try {
															const res = await addParticipantToEvent(data);
															dispatch(setLoadingComplete());
															setShowSuccessModal(true);
															// dispatch(hideModal('event-form'));
														} catch (err) {
															dispatch(setLoadingComplete());
															console.log(err);
														}
													}}>
													Sign Me Up to Help
												</Button>
											</Div>
										</Div>
									)}
								</>
							)}
						</Div>
					</Div>
				) : (
					<ShowConfirmationModal
						text={`${upcomingEvent?.managed_by} will be in touch to
											provide more details closer to the date.`}
						setShowModal={setShowSuccessModal}
						onClose={() => dispatch(hideModal('event-form'))}
					/>
				)}
			</Modal>
		</>
	);
};

export default EmployeeProfileForm;
