import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import MyIpmact from 'root/components/MyImpact';

import styles from './Reports.module.scss';

function Reports() {
	return (
		<>
			<Div className={cx('bgGrayBright', styles.container)}>
				<MyIpmact />
			</Div>
		</>
	);
}

export default Reports;
