import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';
import { Anchor } from 'troop-design';
import dayjs from 'dayjs';

import { retrieveAllTaxReceipts } from 'root/services/charity';
import Table from 'root/baseComponents/Table';
import Icon from 'root/baseComponents/Icon';
import { getBackendUrl } from 'root/utils/helpers';

import styles from '../EmployeeProfile.module.scss';

const EmployeeTaxReceipts = () => {
	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [reciepts, setReciepts] = useState([]);

	const retrieveAllTaxReceiptsData = async () => {
		const res = await retrieveAllTaxReceipts({ is_business: false });
		const localReceipts = [];
		console.log(res);
		res.forEach(item => {
			localReceipts.push({
				date: dayjs(item.issued_date).format('DD-MMM-YY'),
				seq_number: item.seq_receipt_number,
				year: item.year,
				link: item.file_name
					? `${getBackendUrl()}/media/pdfs/${item.file_name}`
					: ''
			});
		});
		setReciepts(localReceipts);
	};

	useEffect(() => {
		retrieveAllTaxReceiptsData();
	}, []);

	const headLines = [
		{
			value: 'date',
			display: 'Date',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'receipt_number',
			display: 'Receipt Number',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'year',
			display: 'Year',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'reciept',
			display: 'Reciept',
			width: 200,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		reciepts.forEach(item => {
			tableData.push({
				date: item.date,
				receipt_number: `R${item.seq_number}`,
				year: item.year,
				reciept: {
					value: item.link,
					display: (
						<Div>
							{item?.link ? (
								<a href={item.link} target="_blank">
									<Icon type="pdf" scale={1.5} />
								</a>
							) : (
								'-'
							)}
						</Div>
					)
				}
			});
		});

		return tableData;
	}, [reciepts]);

	return (
		<>
			{data?.length > 0 && (
				<>
					<Div className="employeeHeading mt3 mb2">Employee Tax Receipts</Div>
					<Div className="w-per-80 pr2 pl2 flex flex--jc--start ">
						<Table
							headLines={headLines}
							data={data}
							colWidth={400}
							tableWidth={'1000px'}
							isSortable={true}
							isSelectable={false}
							selectedData={selectedData}
							setSelectedData={setSelectedData}
							sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
							rowsPerPage={10}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							showDefaultPagination={true}
							numberOfShownPages={3}
						/>
					</Div>
				</>
			)}
		</>
	);
};

export default EmployeeTaxReceipts;
