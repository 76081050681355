import React from 'react';
import cx from 'classnames';
import { Button } from 'troop-design';

import SocialAuth from 'root/components/SocialAuth';
import { googleAuthGetToken, googleAuthHandleToken } from 'root/services/user';
import { GOOGLE_AUTH_URL } from './constants';

import styles from './GoogleAuth.module.scss';

const GoogleAuth = ({ content = 'Continue With Google' }) => {
	return (
		<>
			<div>
				<SocialAuth
					socialAuthGetTokenFunc={googleAuthGetToken}
					socialAuthUrl={GOOGLE_AUTH_URL}>
					<Button className={cx(styles.google)}>{content}</Button>
				</SocialAuth>
			</div>
		</>
	);
};

export default GoogleAuth;
