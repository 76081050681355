import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Chart from 'root/baseComponents/Chart';

// import styles from '../Dashboard.module.scss';

const BAR_CHART_DATA = [
	{ NeedType: 'Underprivileged youth', NeedTypePercentage: 25 },
	// { NeedType: 'At risk BIPOC youth', NeedTypePercentage: 15 },
	{ NeedType: 'Low-income families', NeedTypePercentage: 30 },
	{ NeedType: 'Refugees', NeedTypePercentage: 20 },
	{ NeedType: 'Children escaping domestic violence', NeedTypePercentage: 10 },
	{ NeedType: 'Women experiencing poverty', NeedTypePercentage: 10 }
];

const Y_AXIS_OPTIONS = {
	// ticks: {
	// 	callback: function (value, index, ticks) {
	// 		return '$' + parseFloat(value);
	// 	},
	// 	beginAtZero: true,
	// 	color: 'black',
	// 	stepSize: 250
	// },
	title: {
		display: true,
		text: 'Community Supported',
		font: {
			size: 14
		}
	},
	grid: {
		display: true,
		drawTicks: true,
		drawOnChartArea: true,
		color: 'silver'
	},
	stacked: false
};

const X_AXIS_OPTIONS = {
	ticks: {
		beginAtZero: true,
		color: 'black'
	},
	title: {
		display: true,
		text: '# employees votes',
		font: {
			size: 14
		}
	},
	grid: {
		display: true,
		drawTicks: true,
		drawOnChartArea: true,
		color: 'silver'
	},
	stacked: false
};

const BarChart = () => {

	const data = useMemo(() => {
		if (BAR_CHART_DATA) {
			const x = [];
			const y1 = [];
			BAR_CHART_DATA.forEach(d => {
				y1.push(d['NeedType']);
				x.push(d['NeedTypePercentage']);
			});
			const localData = {
				labels: y1,
				datasets: [
					{
						label: '#vote',
						data: x,
						// borderColor: 'red',
						backgroundColor: '#666dff'
					}
				]
			};
			return localData;
		}
	}, [BAR_CHART_DATA]);
	return (
		<>
			<Chart
				type="bar"
				data={data}
				showLegend={true}
				// titleText="Amount of donation per month"
				yAxisOptions={Y_AXIS_OPTIONS}
				xAxisOptions={X_AXIS_OPTIONS}
				showDataLabels={false}
				barBorderWidth={0}
				indexAxis="y"
			/>
		</>
	);
};

export default BarChart;
