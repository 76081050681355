import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { Div, Row, Column } from 'basedesign-iswad';

import StatsCard from 'root/baseComponents/StatsCard/StatsCard';
import { getCategorizedCharityNeeds } from 'root/services/charity';
import Button from 'root/baseComponents/Button';
import FormSelect from 'root/baseComponents/FormSelect';

import CharityWrapper from '../CharityWrapper';

import BarChart from './subs/BarChart';
import LineChart from './subs/LineChart';
import PieChart from './subs/PieChart';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
	const history = useHistory();

	const [newAddedNeeds, setNewAddedNeeds] = useState([]);
	const [fulfilledNeeds, setFulfilledNeeds] = useState([]);
	const [underVoteNeeds, setUnderVoteNeeds] = useState([]);
	const [activeNeeds, setActiveNeeds] = useState([]);
	const [pausedNeeds, setPausedNeeds] = useState([]);
	const [waitingForFeedback, setWaitingForFeedback] = useState([]);
	const [waitingForImpact, setWaitingForImpact] = useState([]);
	const [allNeeds, setAllNeeds] = useState([]);
	const [filteredNeeds, setFilteredNeeds] = useState([]);
	const [contributionAmount, setContributionAmount] = useState();
	const [needType, setNeedType] = useState('all');
	const [needTypePercentage, setNeedTypePercentage] = useState([
		{ NeedType: 'Waiting for Impact', NeedTypePercentage: 0 },
		{ NeedType: 'In Review', NeedTypePercentage: 0 },
		{ NeedType: 'Under Vote', NeedTypePercentage: 0 },
		{ NeedType: 'Active', NeedTypePercentage: 0 },
		{ NeedType: 'Paused', NeedTypePercentage: 0 },
		{ NeedType: 'Fulfilled', NeedTypePercentage: 0 }
	]);

	const [allYears, setAllyears] = useState({
		value: 2023,
		shownText: '2023'
	});
	const [year, setYear] = useState('last_12_months');

	useEffect(() => {
		const localAllYears = [
			{ value: 'last_12_months', shownText: 'Last 12 months' }
		];
		const currentYear = dayjs().year();
		let numerator = currentYear;
		while (numerator >= 2020) {
			localAllYears.push({
				value: numerator,
				shownText: `${numerator}`
			});
			numerator -= 1;
		}
		setAllyears(localAllYears);
	}, []);

	useEffect(() => {
		if (needType === 'all') {
			setFilteredNeeds(allNeeds);
		} else if (needType === 'fulfilled') {
			setFilteredNeeds(fulfilledNeeds);
		} else if (needType === 'active') {
			setFilteredNeeds(underVoteNeeds);
		} else if (needType === 'live') {
			setFilteredNeeds(activeNeeds);
		} else if (needType === 'awaiting_feedback') {
			setFilteredNeeds(waitingForFeedback);
		} else if (needType === 'paused') {
			setFilteredNeeds(pausedNeeds);
		} else if (needType === 'in_review') {
			setFilteredNeeds(newAddedNeeds);
		}
	}, [
		needType,
		allNeeds,
		filteredNeeds,
		underVoteNeeds,
		activeNeeds,
		waitingForFeedback,
		pausedNeeds,
		newAddedNeeds
	]);

	const goToNewNeedPage = () => {
		history.push('/new-need');
	};

	const goToNewVolunteerPage = () => {
		history.push('/new-volunteer-need');
	};

	const getCategorizedCharityNeedsData = async () => {
		let res;
		if (year === 'last_12_months') {
			res = await getCategorizedCharityNeeds({ is_the_last_12_month: true });
		} else {
			res = await getCategorizedCharityNeeds({ year });
		}
		if (res?.success) {
			console.log(res);
			setNewAddedNeeds(res?.new_added_needs);
			setFulfilledNeeds(res?.fulfilled_needs);
			setWaitingForFeedback(res?.waiting_for_feedback_needs);
			setWaitingForImpact(res?.waiting_for_impact_needs);
			setUnderVoteNeeds(res?.under_vote_needs);
			setPausedNeeds(res?.paused_needs);
			setActiveNeeds(res?.open_needs);
			setAllNeeds([
				...res?.waiting_for_feedback_needs,
				...res?.new_added_needs,
				...res?.under_vote_needs,
				...res?.open_needs,
				...res?.paused_needs,
				...res?.fulfilled_needs
			]);
		}
	};

	useEffect(() => {
		getCategorizedCharityNeedsData();
	}, [year]);

	useEffect(() => {
		if (allNeeds?.length) {
			setNeedTypePercentage([
				{
					NeedType: 'Waiting for Impact',
					NeedTypePercentage: waitingForFeedback?.length / allNeeds.length
				},
				{
					NeedType: 'In Review',
					NeedTypePercentage: newAddedNeeds?.length / allNeeds.length
				},
				{
					NeedType: 'Under Vote',
					NeedTypePercentage: underVoteNeeds?.length / allNeeds.length
				},
				{
					NeedType: 'Active',
					NeedTypePercentage: activeNeeds?.length / allNeeds.length
				},
				{
					NeedType: 'Paused',
					NeedTypePercentage: pausedNeeds?.length / allNeeds.length
				},
				{
					NeedType: 'Fulfilled',
					NeedTypePercentage: fulfilledNeeds?.length / allNeeds.length
				}
			]);
		}
	}, [
		allNeeds,
		waitingForFeedback,
		newAddedNeeds,
		underVoteNeeds,
		activeNeeds,
		pausedNeeds,
		fulfilledNeeds
	]);

	useEffect(() => {
		let sum = 0;
		if (fulfilledNeeds?.length) {
			fulfilledNeeds?.forEach(n => {
				sum += parseFloat(n.contribution_value);
			});
		}
		setContributionAmount(sum);
	}, [fulfilledNeeds]);

	return (
		<>
			<CharityWrapper pageName="Dashboard">
				<Div className={cx(styles.main)}>
					<Div type="flex" className="mb2 fs-px-18">
						Overview(YTD)
					</Div>
					<Div
						type="flex"
						distributedBetween
						vAlign="center"
						className={cx('mb2', styles.infoContainer)}>
						<Div>
							<FormSelect
								className={cx('w-px-200')}
								labelText=""
								options={allYears}
								fullWidth
								openOptionsDownWard={true}
								isRequired
								val={year}
								setVal={setYear}
								initialSelected="Last 12 months"
								// errorMessage={needOwnerErrorMessage}
								// errorHandler={setNeedOwnerErrorMessage}
							/>
						</Div>
						<Div type="flex" className={cx(styles.btnContainer)}>
							<Div className="mb2">
								<Button
									colorType="transparent"
									className={cx('w-px-200', styles.leftBtnContainer)}
									onClick={goToNewVolunteerPage}>
									Volunteer Need +
								</Button>
							</Div>
							<Div className="mb2">
								<Button
									className="mouse-hand w-px-200"
									onClick={goToNewNeedPage}>
									{' '}
									New need +
								</Button>
							</Div>
						</Div>
					</Div>
					<Row className={cx(styles.rowContainer)}>
						<Column xs={12} sm={12} md={12} lg={8}>
							<Div
								type="flex"
								direction="vertical"
								hAlign="center"
								className="w-per-100">
								<Div
									type="flex"
									direction="vertical"
									hAlign="center"
									className="w-per-100 bgWhite p2 br-rad-px-5 boxShadowType1 mb2">
									<BarChart fulfilledNeeds={fulfilledNeeds} year={year} />
								</Div>
								<Div
									type="flex"
									direction="vertical"
									hAlign="center"
									className="w-per-100 bgWhite p2 br-rad-px-5 boxShadowType1">
									<LineChart allNeeds={allNeeds} year={year} />
								</Div>
							</Div>
						</Column>
						<Column xs={12} sm={12} md={12} lg={4}>
							<Div
								type="flex"
								direction="vertical"
								hAlign="center"
								className="w-per-100 mb2">
								<StatsCard
									title="Donated"
									amount={'$' + contributionAmount?.toLocaleString('en-US')}
									isAdminView
								/>
								<StatsCard
									title="Fulfilled Needs"
									amount={fulfilledNeeds?.length}
									isAdminView
								/>
								<StatsCard
									title="Active Needs"
									amount={activeNeeds?.length}
									isAdminView
								/>

								<Div
									type="flex"
									direction="vertical"
									hAlign="center"
									className="w-px-300 bgWhite py2 br-rad-px-5 boxShadowType1">
									<PieChart needTypePercentage={needTypePercentage} />
								</Div>
							</Div>
						</Column>
					</Row>
				</Div>
			</CharityWrapper>
		</>
	);
};

export default Dashboard;
