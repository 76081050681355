import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';
import { Anchor } from 'troop-design';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

import { getEnv } from 'utils/helpers';
import { connectStripe } from 'root/services/geenees';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import Button from 'root/baseComponents/Button';
import TextBox from 'root/baseComponents/TextBox/TextBox';
import Close from 'root/baseComponents/Close';

import styles from './StripeConnection.module.scss';

function StripeConnection() {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const queries = queryString.parse(search);
	const code = queries?.code || false;

	const [title, setTitle] = useState('');
	const [titleErrorMessage, setTitleErrorMessage] = useState('');
	const [successMessage, setSuccessMessage] = useState('');

	const activateAccount = async () => {
		if (!title) {
			setTitleErrorMessage('Title is a required field!');
		} else {
			dispatch(setLoading());
			const res = await connectStripe({ code, account_title: title });
			dispatch(setLoadingComplete());
			if (res?.success) {
				setSuccessMessage(
					"Congrats! You have successfully connected your stripe account with Troop stripe account. Next step is to send an email to 'mohammad@hitroop.com' to proceed with the rest of the Geenees integration."
				);
			} else {
				setSuccessMessage(
					"Ooops! Something went wrong, please send an email to 'mohammad@hitroop.com' to check the issue."
				);
			}
		}
	};

	// useEffect(() => {
	// 	if (code) {
	// 		activateAccount();
	// 	}
	// }, [code]);

	return (
		<>
			<Div type="flex" direction="vertical" hAlign="center" vAlign="center">
				<Div className="m4">Stripe Connection</Div>
				{!code ? (
					<Div
						type="flex"
						direction="vertical"
						hAlign="center"
						className="w-per-100">
						<Div>
							<Div className="mb2">
								In order to connect your stripe account with Troop, you need to
								take the following two steps:
							</Div>
							<Div className="mb2">
								1. Click on the below botton to grant the required permissions.
							</Div>
							<Div className="mb2">
								2. After you grant the permissions you will be redirected to
								this page again, then you need to choose an account name and
								submit the form, in order to activate your account.
							</Div>
						</Div>
						<Div className="w-per-100 mt4">
							<Anchor
								to={`https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${getEnv(
									'STRIPE_CLIENT_ID'
								)}&scope=read_write`}
								target="_blank"
								className="bgPrimary p2 textWhite m2">
								Be a partner with Troop Stripe
							</Anchor>
						</Div>
					</Div>
				) : (
					<Div
						type="flex"
						direction="vertical"
						hAlign="center"
						className="w-per-100">
						<Div type="flex" className="w-per-80">
							<TextBox
								val={title}
								setVal={setTitle}
								labelText="Account title"
								placeholder="Geenees Staging"
								errorMessage={titleErrorMessage}
								errorHandler={setTitleErrorMessage}
							/>
						</Div>
						<Div className="w-per-80">
							<Button onClick={activateAccount}>Activate your account</Button>
						</Div>
					</Div>
				)}
				{successMessage && (
					<Div
						type="flex"
						hAlign="center"
						vAlign="center"
						className={cx(
							'w-per-100 height-vh-full pos-fix pos-fix--lt z-100',
							styles.modalContainer
						)}>
						<Div
							type="flex"
							direction="vertical"
							distributedBetween
							className="card w-per-90 max-w-px-500 boxShaodwType1 br-rad-px-10 p2 pos-rel">
							<Close onClick={() => setSuccessMessage('')} />
							<Div>{successMessage}</Div>
							<Div className="mt2">
								<Button
									onClick={() => setSuccessMessage('')}
									className="w-px-200">
									Got it!
								</Button>
							</Div>
						</Div>
					</Div>
				)}
			</Div>
		</>
	);
}

export default StripeConnection;
