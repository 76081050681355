// VENDOR imports
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useLoggedIn from 'hooks/useLoggedIn';
import Login from 'root/pages/shared/Login/Login';
import { validateUser } from 'root/services/user';

const EmployeeRoute = props => {
	const dispatch = useDispatch();
	const location = useLocation();

	const loggedIn = useLoggedIn();
	useEffect(() => {
		if (!loggedIn) {
			dispatch(validateUser(location));
		}
	}, []);

	return loggedIn ? <props.Component /> : <Login />;
};

export default EmployeeRoute;
