import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Div } from 'basedesign-iswad';
import Close from 'root/baseComponents/Close';

import { sendUserFeedback } from 'root/services/feedback';
import {
	setSeenAddressModal,
	setSeenPledgeModal
} from 'root/reducers/reminders';
import { useHistory } from 'react-router-dom';

import styles from './styles.module.scss';

function PopUpModal({ isAddressModal = false }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const firstName = useSelector(state => state.users.loggedInUser.first_name);
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [showModal, setShowModal] = useState(false);

	const setSeenModal = async () => {
		if (!isAddressModal) {
			const res = await sendUserFeedback({ event: 'seen_pledge_modal' });
			dispatch(setSeenPledgeModal(true));
		} else {
			dispatch(setSeenAddressModal(true));
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setShowModal(true);
		}, 5000);
	}, []);

	return (
		<>
			{showModal && (
				<Div className={cx('w-per-100 height-vh-full pos-fix pos-fix--lt')}>
					<Div
						className={cx(
							'w-per-100 height-vh-full pos-fix pos-fix--lt bgBlack op-30'
						)}></Div>
					<Div
						className={cx(
							'pos-fix pos-fix--center mt2 card p2 w-per-90 max-w-px-500',
							styles.modalContainer
						)}>
						<Close onClick={setSeenModal} />
						<Div className="textPrimary f-b fs-px-20 mt1 mb2 br-bottom-solid-1">
							Reminder!
						</Div>
						<Div className="mb1">
							Hi {firstName},
							<br />
							{isAddressModal ? (
								<Div>
									<Div>
										Complete your address details to receive a charitable tax
										receipt for eligible contributions. Funds left in your
										Account only qualify when used to fulfill a need for a
										registered charity.{' '}
									</Div>
									<Div type="flex">
										<Div
											className={cx(
												styles.updateInfo,
												'textPrimary mt2 mouse-hand'
											)}
											onClick={() => {
												setSeenModal();
												history.push('/employee-profile');
											}}>
											Update my info now
										</Div>
									</Div>
								</Div>
							) : (
								<Div>
									<Div>
										You have ${parseFloat(myProfile?.saving).toFixed(2)} in your
										Do-Good account. You can use these funds to help fulfill a
										need by pledging, or find a wish to fulfill in our{' '}
										<span
											className={cx(styles.link, 'textPrimary', 'mouse-hand')}
											onClick={() => {
												setSeenModal();
												history.push('/act-of-kindness');
											}}>
											Acts of Kindness section!
										</span>{' '}
									</Div>
								</Div>
							)}
						</Div>
					</Div>
				</Div>
			)}
		</>
	);
}

export default PopUpModal;
