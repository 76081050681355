import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Table, Heading } from 'troop-design';
import { showModal } from 'root/reducers/modals';
import SetupInvoicePrompt from './SetupInvoicePrompt';
import { submitPaymentIntent } from 'services/businesses';
import styles from '../BusinessPayment.module.scss';

export default function CheckoutForm({ businessId, payment }) {
	const { monthlyContribution, troopFee, hst, total } = { ...payment };

	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async e => {
		setIsLoading(true);
		submitPaymentIntent(businessId, {}).then(data => {
			setIsLoading(false);
			window.location.href = data.url;
		});
	};

	const columns = useMemo(() => {
		return [
			{
				Header: 'Product',
				accessor: 'product'
			},
			{
				Header: 'Subtotal',
				accessor: 'subtotal'
			}
		];
	}, []);

	const data = useMemo(() => {
		return [
			{
				product: 'Monthly Contribution',
				subtotal: monthlyContribution
			},
			{
				product: 'Troop Fee',
				subtotal: troopFee
			},
			{
				product: 'HST on Troop Fee',
				subtotal: hst
			},
			{
				product: 'Total',
				subtotal: total
			}
		];
	}, [monthlyContribution, troopFee, hst, total]);

	return (
		<>
			<Form id="payment-form" onSubmit={handleSubmit}>
				<Heading level={1}>Make Payment</Heading>
				<Table
					className={styles.businessTable}
					data={data}
					columns={columns}
					striped={false}
					hover
				/>
				<Button className="mt-4 mx-1" variant="primary" size="lg" id="submit">
					{isLoading ? (
						<div className={styles.spinner} id="spinner"></div>
					) : (
						'Pay now'
					)}
				</Button>
			</Form>
			{/* <Button
				className="mt-4 mx-1"
				variant="primary"
				size="lg"
				id="modal"
				onClick={() => dispatch(showModal('setupInvoicePrompt'))}>
				Setup Invoice
			</Button>
			<SetupInvoicePrompt businessId={businessId} payment={payment} /> */}
		</>
	);
}
