import React, { useEffect, useState } from 'react';
import {
	PaymentElement,
	useStripe,
	useElements
} from '@stripe/react-stripe-js';
import cx from 'classnames';
import { Button } from 'troop-design';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { get, set, del } from 'utils/storage';
import { getClientUrl } from 'utils/helpers';
import {
	addMoneyToBeProcessed,
	clearMoneyToBeProcessed,
	createUSerEvent
} from 'services/user';

import styles from '../EmployeeCheckout.module.scss';

export default function CheckoutForm({
	paymentId,
	amount,
	isGeeneesNeed,
	directAddFund
}) {
	const history = useHistory();
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}

		const clientSecret = new URLSearchParams(window.location.search).get(
			'payment_intent_client_secret'
		);

		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case 'succeeded':
					setMessage('Payment succeeded!');
					history.push('/');
					break;
				case 'processing':
					setMessage('Your payment is processing.');
					break;
				case 'requires_payment_method':
					setMessage('Your payment was not successful, please try again.');
					break;
				default:
					setMessage('Something went wrong.');
					break;
			}
		});
	}, [stripe]);

	const handleSubmit = async e => {
		e.preventDefault();
		createUSerEvent({
			event: 'Submit payment to add funds in do-good account (Final step)'
		});

		if (!stripe || !elements) {
			return;
		}

		setIsLoading(true);
		const clientUrl = getClientUrl();
		const res = await addMoneyToBeProcessed({ amount, paymentId });
		set('stripe_payment_status', 'success');
		if (isGeeneesNeed) {
			set('is_geenees_need', true);
		}

		try {
			if (res.success) {
				const { error } = await stripe.confirmPayment({
					elements,
					confirmParams: {
						return_url: `${clientUrl}`
					}
				});

				if (error.type === 'card_error' || error.type === 'validation_error') {
					setMessage(error.message);
					del('stripe_payment_status');
					const res = await clearMoneyToBeProcessed({ amount, paymentId });
				} else {
					setMessage('An unexpected error occured.');
					del('stripe_payment_status');
					const res = await clearMoneyToBeProcessed({ amount, paymentId });
				}
			} else {
				del('stripe_payment_status');
				const res = await clearMoneyToBeProcessed({ amount, paymentId });
				setMessage(
					'Something went wrong; please try again! you might need to refresh the app!'
				);
			}
			setIsLoading(false);
		} catch (err) {
			del('stripe_payment_status');
			const res = await clearMoneyToBeProcessed({ amount, paymentId });
			setMessage(
				'Something went wrong; please try again! you might need to refresh the app!'
			);
			setIsLoading(false);
		}
	};

	const options = {
		theme: 'stripe',
		terms: {
			card: 'never'
		}
	};

	return (
		<form id="payment-form" onSubmit={handleSubmit} className={cx(styles.form)}>
			<div className={cx(styles.paymentInfo)}>
				{myProfile?.saving === 0 || directAddFund ? (
					<>
						Spending{' '}
						<span className={cx(styles.paymentInfoHighlighted)}>
							${parseFloat(amount)?.toFixed(2)}
						</span>{' '}
						on my community!
					</>
				) : (
					<>
						You had{' '}
						<span className={cx(styles.paymentInfoHighlighted)}>
							${myProfile?.saving?.toFixed(2)}
						</span>{' '}
						in your Do Good account that was applied to this purchase. So you’re
						now spending{' '}
						<span className={cx(styles.paymentInfoHighlighted)}>
							${parseFloat(amount)?.toFixed(2)}
						</span>{' '}
						on your community!
					</>
				)}
			</div>
			<PaymentElement id="payment-element" options={options} />
			{stripe && elements ? (
				<Button id="submit" type="submit" className={cx(styles.button)}>
					<span id="button-text">
						{isLoading ? (
							<div className={cx(styles.spinner)} id="spinner"></div>
						) : (
							'Pay now'
						)}
					</span>
				</Button>
			) : (
				''
			)}
			{/* Show any error or success messages */}
			{message && <div id="payment-message">{message}</div>}
		</form>
	);
}
