import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { sendBusinessReferral } from 'services/businesses';
import {
	SHARE_A_CONTACT,
	SHARE_A_CONTACT_BOOK_APPOINTMENT_LINK,
	SHARE_A_CONTACT_DEMO_LINK
} from '../constants';
import {
	Col,
	Card,
	Form,
	Button,
	Text,
	Input,
	Icon,
	Heading,
	Anchor,
	Container
} from 'troop-design';
import Modal from 'root/components/Modal';
import { hideModal } from 'root/reducers/modals';
import styles from '../ReferAFriend.module.scss';

export default function ShareAContactPrompt({ business, user, formData }) {
	const dispatch = useDispatch();

	const shareAContact = useCallback(() => {
		const finalData = new FormData();
		finalData.append('currentBusiness', business.name);
		finalData.append('email', formData.email);
		finalData.append('leadCompany', formData.leadCompany);
		finalData.append('leadName', formData.leadName);
		finalData.append('trigger', 'troop_triggered');
		dispatch(sendBusinessReferral(business.id, finalData));
		dispatch(hideModal('sharePrompt'));
		window.scrollTo(0, 0);
	}, [dispatch, formData, business]);

	return (
		<Modal reduxKey="sharePrompt" size="md">
			<Container className={styles.emailContainer}>
				<Container className={styles.emailScrollArea}>
					<Text tag="p" hasWrapper>
						{`Subject: ${user?.first_name} from ${business?.name} thought you’d want to hear this`}
					</Text>
					<Text tag="p" hasWrapper>
						{`Hi ${formData?.leadName},`}
					</Text>
					<Text tag="p" hasWrapper>
						{`I hope this week has been treating you well!`}
					</Text>
					<Text tag="p" hasWrapper>
						{`${user?.first_name} from ${business?.name} shared your contact details with us. The ${business?.name} team has been working with Troop to implement a unique solution to local impact, and they’re liking our tool so much that they want to tell the world about it (thank you ${business?.name}!).`}
					</Text>
					<Text tag="p" hasWrapper>
						{`Troop runs monthly giving programs where employees receive a curated list of tangible needs from local, neighbourhood charities (like a new mom who needs a baby stroller in Parkdale).  Through our voting platform, employees choose which cause their company will support that month.  We also send out a report with pictures and messages from the charities/neighbours so everyone can see and feel the impact.`}
					</Text>
					<Text tag="p" hasWrapper>
						{`${user?.first_name} thought this might be something ${formData?.leadCompany} would be interested in. Plus, because you’re being referred, we’re offering to waive your first month service fee. So, if you’d like to join our growing list of businesses doing good in their communities, let’s chat!`}
					</Text>
					<Anchor to={SHARE_A_CONTACT_BOOK_APPOINTMENT_LINK} target="_blank">
						Book a call
					</Anchor>
					<Container className={'my-2'}>
						<Text tag="span" hasWrapper>
							{`Check out an on-demand demo of Troop `}
						</Text>
						<Anchor to={SHARE_A_CONTACT_DEMO_LINK} target="_blank">
							(Troop 10min Demo)
						</Anchor>
					</Container>
					<Text tag="p" hasWrapper>
						Kelly
					</Text>
					<Text tag="p" hasWrapper>
						Founder | Troop
					</Text>
				</Container>
			</Container>
			<Button variant="primary" outline onClick={shareAContact}>
				Approve and Send
			</Button>
		</Modal>
	);
}
