import { get, post } from './http';

export const getVoteReport = (date = '') => {
	if (date) {
		return get(`/retrieve-vote-report/?vote_period=${date}`);
	} else {
		return get(`/retrieve-vote-report/`);
	}
};

export const getCommentReport = (date = '', business_id = 0) => {
	if (date) {
		return get(
			`/retrieve-comment-report/?vote_period=${date}&business_id=${business_id}`
		);
	} else {
		return get(`/retrieve-comment-report/?business)id=${business_id}`);
	}
};

export const resetForDemo = () => {
	return post(`/reset-for-demo/`);
};
