import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setCharitySidebarState'] = (state, action) => action.payload;

const slice = createSlice({
	name: 'charitySidebarState',
	initialState: true,
	reducers: reducerObject
});

export const { setCharitySidebarState } = slice.actions;
export default slice.reducer;
