import React from 'react';
import cx from 'classnames';
import { Spinner } from 'troop-design';

import styles from './Loading.module.scss';

const Loading = () => {
	return (
		<>
			<div className={cx(styles.loader)}>
				<Spinner />
			</div>
		</>
	);
};

export default Loading;
