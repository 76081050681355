import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	flags: {},
	featureFlags: []
};

const setFlag = (data, state) => {
	if (data.type === 'app') {
		state.flags[data.flag] = data.enabled;
	}
};

// Data type
/**
 * {
 *      name: 'Slack Integration',
 *      description: 'Slack integration for your business',
 *      flag: 'slack',
 *      enabled: true,
 *      type: 'app' / 'user' / 'business'
 *       associated_to: []
 * }
 */

export const featureFlagSlice = createSlice({
	name: 'featureFlags',
	initialState,
	reducers: {
		getFlags: (state, action) => {
			state.flags = action.payload;
		},
		getFeatureFlags: (state, action) => {
			state.featureFlags = action.payload;
		},
		getFeatureFlag: (state, action) => {
			const getExistingFeatureFlagIndex = state.featureFlags.findIndex(
				featureFlag => featureFlag.id === action.payload.id
			);
			if (getExistingFeatureFlagIndex !== -1) {
				state.featureFlags[getExistingFeatureFlagIndex] = action.payload;
			} else {
				state.featureFlags.push(action.payload);
			}
		},
		addFeatureFlag: (state, action) => {
			state.featureFlags.push(action.payload);
		},
		updateFeatureFlag: (state, action) => {
			const index = state.featureFlags.findIndex(
				featureFlag => featureFlag.id === action.payload.id
			);
			state.featureFlags[index] = action.payload;
		},
		deleteFeatureFlag: (state, action) => {
			const index = state.featureFlags.findIndex(
				featureFlag => featureFlag.id === action.payload.id
			);
			state.featureFlags.splice(index, 1);
		}
	}
});

export const {
	getFeatureFlags,
	getFeatureFlag,
	addFeatureFlag,
	updateFeatureFlag,
	deleteFeatureFlag,
	getFlags
} = featureFlagSlice.actions;
export default featureFlagSlice.reducer;
