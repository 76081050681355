import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { Icon } from 'troop-design';

import FormInput from 'root/baseComponents/FormInput';

import styles from './Select.module.scss';

const Select = React.forwardRef(
	(
		{
			options,
			className,
			defaultViewClassName,
			optionClassName,
			fullWidth,
			children,
			selectValue,
			setSelectValue,
			arrowIconFillColor,
			arrowIconStrokeColor,
			arrowIconScale,
			searchIconFillColor,
			searchIconStrokeColor,
			optinsContainerClassName,
			inputSearchClassName,
			openOptionsDownWard = true,
			initialSelected = '',
			errorHandler,
			hasBorder = true,
			...props
		},
		ref
	) => {
		const [filteredOptions, setFilteredOptions] = useState([]);
		const [curVal, setCurVal] = useState('');
		const [isOptionsActive, setIsOptionsActive] = useState(false);

		useEffect(() => {
			if (initialSelected) {
				setCurVal(initialSelected);
			}
		}, [initialSelected]);

		useEffect(() => {
			setFilteredOptions([...options]);
		}, [options]);

		return (
			<>
				<div
					className={cx(
						styles.clickable,
						isOptionsActive && styles.clickableIsActive
					)}
					onClick={() => setIsOptionsActive(false)}></div>
				<div
					className={cx(
						'pos-rel',
						fullWidth && 'w-per-100',
						className,
						children
					)}
					{...props}
					ref={ref}>
					{!isOptionsActive ? (
						<div
							className={cx(defaultViewClassName)}
							onClick={() => {
								setIsOptionsActive(!isOptionsActive);
								setFilteredOptions(options);
							}}>
							{curVal}
						</div>
					) : (
						<FormInput
							hasBorder={hasBorder}
							hasIcon={true}
							iconType="search"
							iconColor="gray"
							hasMarginBottom={false}
							className={cx(inputSearchClassName)}
							onChange={e =>
								setFilteredOptions(() =>
									options.filter(x =>
										x?.shownText
											?.toLowerCase()
											?.includes(e.target.value?.toLowerCase())
									)
								)
							}
						/>
					)}
					<div
						className={cx(
							styles.optionContainer,
							openOptionsDownWard
								? styles.optionContainerToDown
								: styles.optionContainerToUp,
							isOptionsActive && styles.optionContainerIsActive,
							optinsContainerClassName
						)}>
						{filteredOptions?.map((item, idx) => (
							<div
								className={cx(styles.option, optionClassName)}
								key={idx}
								value={item?.value}
								onClick={() => {
									setSelectValue(item.value);
									setCurVal(item.shownText);
									setIsOptionsActive(false);
									if (errorHandler) {
										errorHandler('');
									}
								}}>
								{item?.shownText}
							</div>
						))}
					</div>
					<div
						className={cx(styles.arrowConrainer)}
						onClick={() => {
							setIsOptionsActive(!isOptionsActive);
							setFilteredOptions(options);
						}}>
						<Icon icon="chevron-down" size="sm" color="#ccc" />
					</div>
				</div>
			</>
		);
	}
);

export default Select;
