import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div, Image } from 'basedesign-iswad';
import { Heading, Icon, Button } from 'troop-design';

import Close from 'root/baseComponents/Close/Close';

import { checkIfUserHasPassword } from 'root/services/user';
import { hideComponent, clearChosenNeed } from 'root/reducers/voteCelebration';
import { get } from 'utils/storage';

import CelebPhoto from 'root/assets/vote_celebration.png';

import styles from '../VoteLandingPageForCustomers.module.scss';

const VoteCelebration = ({ chosenNeed }) => {
	return (
		<>
			<div className={cx(styles.container)}>
				<Div
					className={cx(
						'pos-abs pos-abs--lt w-per-100 height-vh-full bgWhite op-90'
					)}
				/>
				<Div
					className={cx(
						'pos-fix pos-fix--lt w-per-100 height-vh-full',
						styles.celebImage
					)}>
					<img src={CelebPhoto} />
				</Div>
				<div className={cx(styles.card, 'pos-rel')}>
					<Close
						onClick={() => {
							window.location.replace('https://hitroop.com');
						}}
					/>
					<div className={cx(styles.cardLeft)}>
						<div className={cx(styles.cardLeftContainer)}>
							<div className={cx(styles.photoContainer)}>
								<div className={cx(styles.votedIcon)}>
									<div className={cx(styles.votedIconIcon)}>
										<Icon icon="checkmark-voted" color="white" width={50} />
									</div>
									<div>Your Vote</div>
								</div>
								<Image className={cx(styles.photo)} src={chosenNeed?.image} />
							</div>
							<p>{chosenNeed?.charity_name}</p>
							<p>{chosenNeed?.community_supported || chosenNeed?.name}</p>
						</div>
					</div>
					<div className={cx(styles.cardRightNoEmployeeFeature)}>
						<Heading level={2}>Great Choice!</Heading>
						<p className={cx(styles.centredTextNoEmployeeFeature)}>
							Your vote has been counted. Stay tuned to find out which need will
							be fulfilled!
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default VoteCelebration;
