import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button/Button';
import StatsCard from 'root/baseComponents/StatsCard/StatsCard';
import { getCategorizedCharityNeeds } from 'root/services/charity';
import CharityWrapper from '../CharityWrapper/CharityWrapper';

import NeedGrid from '../Dashboard/subs/NeedGrid';
import Filters from '../Dashboard/subs/Filters';
import VolunteerNeedCard from './subs/VolunteerNeedCard';
import styles from './Needs.module.scss';

function Dashboard() {
	const history = useHistory();

	const [newAddedNeeds, setNewAddedNeeds] = useState([]);
	const [fulfilledNeeds, setFulfilledNeeds] = useState([]);
	const [underVoteNeeds, setUnderVoteNeeds] = useState([]);
	const [activeNeeds, setActiveNeeds] = useState([]);
	const [pausedNeeds, setPausedNeeds] = useState([]);
	const [waitingForFeedback, setWaitingForFeedback] = useState([]);
	const [waitingForImpact, setWaitingForImpact] = useState([]);
	const [allNeeds, setAllNeeds] = useState([]);
	const [filteredNeeds, setFilteredNeeds] = useState([]);
	const [volunteerNeeds, setVolunteerNeeds] = useState([]);
	const [contributionAmount, setContributionAmount] = useState();
	const [needType, setNeedType] = useState('all');

	useEffect(() => {
		if (needType === 'all') {
			setFilteredNeeds(allNeeds);
		} else if (needType === 'fulfilled') {
			setFilteredNeeds(fulfilledNeeds);
		} else if (needType === 'active') {
			setFilteredNeeds(underVoteNeeds);
		} else if (needType === 'live') {
			setFilteredNeeds(activeNeeds);
		} else if (needType === 'awaiting_feedback') {
			setFilteredNeeds(waitingForFeedback);
		} else if (needType === 'paused') {
			setFilteredNeeds(pausedNeeds);
		} else if (needType === 'in_review') {
			setFilteredNeeds(newAddedNeeds);
		} else if (needType === 'volunteer') {
			setFilteredNeeds(volunteerNeeds);
		}
	}, [
		needType,
		allNeeds,
		filteredNeeds,
		underVoteNeeds,
		activeNeeds,
		waitingForFeedback,
		pausedNeeds,
		newAddedNeeds
	]);

	const goToNewNeedPage = () => {
		history.push('/new-need');
	};

	const goToNewVolunteerPage = () => {
		history.push('/new-volunteer-need');
	};

	const getCategorizedCharityNeedsData = async () => {
		const res = await getCategorizedCharityNeeds();
		if (res?.success) {
			setNewAddedNeeds(res?.new_added_needs);
			setFulfilledNeeds(res?.fulfilled_needs);
			setWaitingForFeedback(res?.waiting_for_feedback_needs);
			setWaitingForImpact(res?.waiting_for_impact_needs);
			setUnderVoteNeeds(res?.under_vote_needs);
			setPausedNeeds(res?.paused_needs);
			setActiveNeeds(res?.open_needs);
			setVolunteerNeeds(res?.volunteer_needs);
			setAllNeeds([
				...res?.waiting_for_feedback_needs,
				...res?.volunteer_needs,
				...res?.new_added_needs,
				...res?.under_vote_needs,
				...res?.open_needs,
				...res?.paused_needs,
				...res?.fulfilled_needs
			]);
		}
	};

	useEffect(() => {
		getCategorizedCharityNeedsData();
	}, []);

	useEffect(() => {
		let sum = 0;
		if (fulfilledNeeds?.length) {
			fulfilledNeeds?.forEach(n => {
				sum += parseFloat(n.contribution_value);
			});
		}
		setContributionAmount(sum);
	}, [fulfilledNeeds]);

	return (
		<>
			<CharityWrapper pageName="Needs">
				<Div className={cx('', styles.main)}>
					<Div
						showIn={['md', 'lg']}
						type="flex"
						vAlign="center"
						distributedBetween
						className="mt2 mb3 height-px-40">
						<Div>
							<Filters needType={needType} setNeedType={setNeedType} />
						</Div>
						<Div type="flex" className={cx(styles.btnContainer)}>
							<Div className="mb2">
								<Button
									colorType="transparent"
									className={cx('w-px-200', styles.leftBtnContainer)}
									onClick={goToNewVolunteerPage}>
									Volunteer Need +
								</Button>
							</Div>
							<Div className="mb2">
								<Button
									className="mouse-hand w-px-200"
									onClick={goToNewNeedPage}>
									{' '}
									New need +
								</Button>
							</Div>
						</Div>
					</Div>
					
					<Div
						showIn={['xs', 'sm']}
						type="flex"
						vAlign="center"
						hAlign="center"
						direction="vertical"
						className="mb3 mt3">
						<Div>
							<Filters needType={needType} setNeedType={setNeedType} />
						</Div>
					</Div>
					{allNeeds && allNeeds?.length ? (
						<Div className={cx('w-per-100', styles.needGridContainer)}>
							<NeedGrid needs={filteredNeeds} />
						</Div>
					) : (
						''
					)}
				</Div>
			</CharityWrapper>
		</>
	);
}

export default Dashboard;
