import React, { useState } from 'react';
import cx from 'classnames';
import { Div, Heading } from 'basedesign-iswad';
import { useDispatch, useSelector } from 'react-redux';

import Button from 'root/baseComponents/Button';
import Form from 'root/baseComponents/Form';
import TextBox from 'root/baseComponents/TextBox';

import { setModalProps } from 'root/reducers/charityReducers/modalProps';
import { setModalType } from 'root/reducers/charityReducers/modalType';
import Modal from 'root/baseComponents/Modal/Modal';

// import { EMAIL_TEXT } from './constants';
import styles from '../Invite.module.scss';
import {
	contactEmailValidators,
	contactNameValidators,
	businessNameValidators
} from '../validators';

const ReferFriendForm = () => {
	const dispatch = useDispatch();

	const business = useSelector(state => state.businesses.businesses?.[0]);
	const curUser = useSelector(state => state.users?.loggedInUser);

	const [businessName, setBusinessName] = useState('');
	const [businessNameErrorMessage, setBusinessNameErrorMessage] = useState('');

	const [contactName, setContactName] = useState('');
	const [contactNameErrorMessage, setContactNameErrorMessage] = useState('');

	const [contactEmail, setContactEmail] = useState('');
	const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState('');

	const body = `Hi ${contactName},
		\n\nWe recently implemented a social impact program at ${business?.name} and I thought it might be a good fit for you over at ${businessName}.
		\n\nWe’ve partnered with a social enterprise called Troop and their approach to CSR is quite unique. Their solution is a monthly giving program that lets our employees choose which causes we donate to. They focus on tangible needs from local, neighbourhood charities (like a new mom who needs a baby stroller in Parkdale). They even send us a report every month that has pictures and messages from the charities/neighbours so we can see the impact of our contributions.
		\n\nIt’s been well received by our company and the program was super simple to get started, you should give it a go!
		\n\nPlus, because you’re being referred, they’re offering to waive your first month service fee!
		\n\nHere’s how you can get in contact:
		\nKelly Emery, Founder & CEO
		\nkelly@hitroop.com
		\nhttps://www.linkedin.com/in/kelly-emery-b3607875/
		\n\nAll the best,
		\n${curUser?.first_name}
		`;

	const EMAIL_TEXT = (
		<>
			<Div className="fs-px-14">
				<Div className="mb1">Hi {contactName},</Div>
				<Div className="mb1">
					We recently implemented a social impact program at {business?.name}{' '}
					and I thought it might be a good fit for you over at {businessName}.
				</Div>
				<Div className="mb1">
					We’ve partnered with a social enterprise called{' '}
					<a href="http://HiTroop.com" target="_blank">
						Troop
					</a>{' '}
					and their approach to CSR is quite unique. Their solution is a monthly
					giving program that lets our employees choose which causes we donate
					to. They focus on tangible needs from local, neighbourhood charities
					(like a new mom who needs a baby stroller in Parkdale). They even send
					us a report every month that has pictures and messages from the
					charities/neighbours so we can see the impact of our contributions.
				</Div>
				<Div className="mb1">
					It’s been well received by our company and the program was super
					simple to get started, you should give it a go!
				</Div>
				<Div className="mb1">
					Plus, because you’re being referred, they’re offering to waive your
					first month service fee!
				</Div>
				<Div className="mb1">Here’s how you can get in contact:</Div>
				<Div className="mb1">Kelly Emery, Founder & CEO</Div>
				<Div className="mb1">kelly@hitroop.com</Div>
				<Div className="mb1">
					<a href="https://www.linkedin.com/in/kelly-emery-b3607875/">
						https://www.linkedin.com/in/kelly-emery-b3607875/
					</a>
				</Div>

				<Div className="mb1">All the best,</Div>
				<Div className="mb1">{curUser?.first_name}</Div>
			</Div>
		</>
	);

	const toBeValidatedFields = [
		{
			input_name: 'business_name',
			validators: businessNameValidators,
			errorMessageHandler: setBusinessNameErrorMessage
		},

		{
			input_name: 'contact_name',
			validators: contactNameValidators,
			errorMessageHandler: setContactNameErrorMessage
		},
		{
			input_name: 'contact_email',
			validators: contactEmailValidators,
			errorMessageHandler: setContactEmailErrorMessage
		}
	];

	const submitHandler = async () => {
		dispatch(setModalType('refer'));
		dispatch(
			setModalProps({
				text: EMAIL_TEXT,
				email: contactEmail,
				header: 'Share your experience!',
				body
			})
		);
	};

	return (
		<>
			<Modal />
			<Div className="employeeHeading mb1 mt2 ml2">Invite a Friend </Div>
			<Div className="card m2 br-all-hidden-1 br-rad-px-5 p1 w-per-80">
				{/* <Div className="fs-px-20 mb1 ">Header</Div> */}
				<Div className="fs-px-16 mt1 mb1">
					Love Troop? Spread the word! Share your Troop experience and help us
					connect more companies with local causes. Let’s make an even bigger
					impact together!
				</Div>
				<Form
					onSubmit={submitHandler}
					toBeValidatedFields={toBeValidatedFields}>
					<TextBox
						labelText="First Name"
						type="text"
						val={contactName}
						setVal={setContactName}
						errorHandler={setBusinessNameErrorMessage}
						name="contact_name"
						errorMessage={businessNameErrorMessage}
						className={cx('mr2 w-px-100')}
					/>
					<TextBox
						labelText="Email"
						type="text"
						val={contactEmail}
						setVal={setContactEmail}
						errorHandler={setContactEmailErrorMessage}
						name="contact_email"
						errorMessage={contactEmailErrorMessage}
						className={cx('mr2 w-px-100')}
					/>
					<TextBox
						labelText="Name of the company that your friend works at"
						type="text"
						val={businessName}
						setVal={setBusinessName}
						errorHandler={setBusinessNameErrorMessage}
						name="business_name"
						errorMessage={businessNameErrorMessage}
						className={cx('mr2 w-px-100')}
					/>
					<Div type="flex" hAlign="end" className="w-per-100">
						<Button type="submit" className="w-px-250 mouse-hand textWhite">
							Continue
						</Button>
					</Div>
				</Form>
			</Div>
		</>
	);
};

export default ReferFriendForm;
