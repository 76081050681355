import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';

export default function useCurrentPage() {
	const location = useLocation();
	const [currentPage, setCurrentPage] = useState('');

	const getPage = pathname => pathname?.split('/')[1] || '';

	useEffect(() => {
		setCurrentPage(getPage(location.pathname));
	}, [location.pathname]);
	return currentPage;
}
