import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export default function useRedirectTo() {
	const history = useHistory();

	const redirectTo = useCallback(
		path => {
			history.push(path);
		},
		[history]
	);

	return redirectTo;
}
