import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { hideModal, showModal } from 'reducers/modals';
import { Div, Row, Column } from 'basedesign-iswad';
import ReactGA from "react-ga4";

import { checkIfIsFirstLogin } from 'services/user';

import { get } from 'utils/storage';

import EmployeeVoteSection from 'components/EmployeeVoteSection';
import BusinessNewsFeed from 'root/components/BusinessNewsFeed';
import PersonalPledging from 'root/components/PersonalPledging';
import VoteStreak from 'root/components/VoteStreak';
import PledgeTutorial from 'components/PledgeTutorial';
import styles from './EmployeeHome.module.scss';

const EmployeeHome = () => {
	ReactGA.initialize("G-KFJ5WN5S51");
	const history = useHistory();
	const dispatch = useDispatch();
	const isLoggedInToVote = useSelector(state => state.isLoggedInToVote);

	useEffect(() => {
		const checkFirstLogin = async () => {
			const res = await checkIfIsFirstLogin();
			if (res.first_login) {
				setTimeout(() => {
					dispatch(showModal('pledgeTutorial'));
				}, 3000);
			}
		};
		if (!isLoggedInToVote && !get('loginToVote')) {
			checkFirstLogin();
		}
	}, [isLoggedInToVote]);

	return (
		<Row className={styles.main}>
			<Column xs={12} sm={12} md={9} lg={9}>
				<Div
					type="flex"
					direction="vertical"
					distributedBetween
					className={cx('', styles.firstPart)}>
					<div className={cx(styles.voteContainer)}>
						<EmployeeVoteSection isCompacted={true} />
					</div>
					<Row>
						<Column xs={12} sm={12} md={12} lg={6}>
							<Div className={cx(styles.streakContainer)}>
								{/* 
								<Div className="employeeHeading mb2">
									Other Acts of Kindness
								</Div>
								<Div type="flex" direction="horizontal">
									<KindnessCard />
									<KindnessCard />
									<KindnessCard />
								</Div> 
								*/}

								<Div className="employeeHeading mb2">Personal Pledging</Div>
								<PersonalPledging />
							</Div>
						</Column>
						<Column xs={12} sm={12} md={12} lg={6}>
							<Div className={cx(styles.streakContainer)}>
								<Div
									className={cx(
										'employeeHeading mb2',
										styles.mobStreakContainer
									)}>
									Your Vote Streak
								</Div>
								<VoteStreak />
							</Div>
						</Column>
					</Row>
				</Div>
			</Column>
			<Column xs={12} sm={12} md={3} lg={3}>
				<div className={cx(styles.secondPart)}>
					<Div className="mt2 mb2 employeeHeading"> Take Action </Div>
					<BusinessNewsFeed />
				</div>
			</Column>
		<Div showIn={['lg']}>
			<PledgeTutorial />
		</Div>
		
		</Row>
	);
};

export default EmployeeHome;
