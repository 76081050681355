import React from 'react';
import cx from 'classnames';
import { Div, Row, Column } from 'basedesign-iswad';
import Logo from 'root/assets/logo.png';
import Photo from 'root/assets/login.png';

import SignupForm from './subs/SignupForm';
import styles from './CharityAdminSignup.module.scss';

const CharityAdminSignup = ({ uuid }) => {
	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'ml-auto mr-auto mt2 mb2 boxShadowType1 br-all-hidden-1 br-rad-px-5 of-hidden',
					styles.container
				)}>
				<Row>
					<Column xs={0} sm={0} md={7} lg={7}>
						<img src={Photo} className={cx(styles.photo)} />
					</Column>
					<Column xs={12} sm={12} md={5} lg={5}>
						<Div
							type="flex"
							direction="vertical"
							distributedBetween
							className="">
							<Div type="flex" hAlign="center" className="w-per-100 mt3">
								<img src={Logo} className={cx('w-px-100')} />
							</Div>

							<Div className="p2">
								<SignupForm uuid={uuid} />
							</Div>
							<Div className="mt8 p2 fs-px-10 textGray text-center">
								Click "Sign up" to agree to Troop's{' '}
								<a
									href="https://hitroop.com/terms/"
									className="text-underline textGray mouse-hand">
									Terms of Service{' '}
								</a>
								and acknowledge that Troop's{' '}
								<a
									href="https://hitroop.com/privacy/"
									className="text-underline textGray mouse-hand">
									{' '}
									Privacy Policy{' '}
								</a>{' '}
								applies to you.
							</Div>
						</Div>
					</Column>
				</Row>
			</Div>
		</>
	);
};

export default CharityAdminSignup;
