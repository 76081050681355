import { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

export default function useLoader(key, method, methodDependencies = []) {
	const loaderValue = useSelector(state => !!state.loaders[key]);
	const dispatch = useDispatch();
	let response = useRef(new Promise(resolve => resolve('loading')));
	const handleSetLoading = () => dispatch(setLoading(key));
	const handleSetLoadingComplete = () => dispatch(setLoadingComplete(key));

	useEffect(() => {
		handleSetLoading(true);
		response.current = method().then(data => {
			handleSetLoadingComplete();
			return data;
		});
	}, methodDependencies);

	return [
		loaderValue,
		response.current,
		handleSetLoading,
		handleSetLoadingComplete
	];
}
