import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import TextBox from 'root/baseComponents/TextBox';
import Form from 'root/baseComponents/Form/Form';
import Button from 'root/baseComponents/Button/Button';

import styles from '../Events.module.scss';
import FormTextArea from 'root/baseComponents/FormTextArea/index';
import FormDatePicker from 'root/baseComponents/FormDatePicker/index';

const EmployeeProfileForm = () => {
	const dispatch = useDispatch();

	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [date, setDate] = useState('');
	const [time, setTime] = useState('');
	const [location, setLocation] = useState('');

	const [name, setName] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');

	const [email, setEmail] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	const [eventName, setEventName] = useState('');
	const [eventNameErrorMessage, setEventNameErrorMessage] = useState('');
	const [eventManager, setEventManager] = useState('');
	const [eventManagerErrorMessage, setEventManagerErrorMessage] = useState('');
	const [eventDescription, setEventDescription] = useState('');

	useEffect(() => {
		if (myProfile?.id) {
			setName(`${myProfile?.user?.first_name} ${myProfile?.user?.last_name}`);
			setEmail(myProfile?.user?.email);
		}
	}, [myProfile]);

	const handleSubmit = async e => {
		// e.preventDefault();
		// const res = await updateBusinessUserProfile(businessId, {
		// 	user: {
		// 		first_name: firstName,
		// 		last_name: lastName,
		// 		email: myProfile?.user?.email
		// 	}
		// });
		// if (businessId) {
		// 	dispatch(
		// 		addNotification({
		// 			type: 'success',
		// 			message: `Successfully updated your profile`
		// 		})
		// 	);
		// 	getBusinessUserProfile(dispatch, businessId);
		// }
	};

	return (
		<>
			<Div>
				<Div className="pr2 pl2">
					<Div className="text-center mt3 mb1 f-b fs-px-20">Add event form</Div>
					<Form onSubmit={handleSubmit}>
						<Div>
							<Div className="w-per-100">
								<TextBox
									labelText="Event name"
									type="text"
									val={eventName}
									setVal={setEventName}
									errorHandler={setEventNameErrorMessage}
									name="event_name"
									errorMessage={eventNameErrorMessage}
									className={cx('mr2 w-px-100')}
								/>
								<TextBox
									labelText="Event Manager Name"
									type="text"
									val={eventManager}
									setVal={setEventManager}
									errorHandler={setEventManagerErrorMessage}
									name="email"
									errorMessage={eventManagerErrorMessage}
									className={cx('mr2 w-px-100')}
								/>
							</Div>
						</Div>
						<Div>
							<Div className="w-per-100">
								<TextBox
									labelText="Location"
									type="text"
									val={eventName}
									setVal={setEventName}
									errorHandler={setEventNameErrorMessage}
									name="event_name"
									errorMessage={eventNameErrorMessage}
									className={cx('mr2 w-px-100')}
								/>
								<FormDatePicker
									labelText="Date"
									chosenDate={eventManager}
									setChosenDate={setEventManager}
									isRequired
									errorHandler={setEventManagerErrorMessage}
									name="email"
									errorMessage={eventManagerErrorMessage}
									className={cx('mr2 w-px-100')}
								/>
								<TextBox
									labelText="Time"
									type="text"
									placeholder="ex: 15:00:00"
									val={eventManager}
									setVal={setEventManager}
									errorHandler={setEventManagerErrorMessage}
									name="email"
									errorMessage={eventManagerErrorMessage}
									className={cx('mr2 w-px-100')}
								/>
								<FormTextArea
									labelText="Event Description"
									type="text"
									val={eventManager}
									setVal={setEventManager}
									errorHandler={setEventManagerErrorMessage}
									name="email"
									errorMessage={eventManagerErrorMessage}
									className={cx('mr2 w-px-100')}
								/>
							</Div>
						</Div>

						<Div type="flex" hAlign="center">
							<Button type="submit" className="w-px-200 mouse-hand">
								Create Event
							</Button>
						</Div>
					</Form>
				</Div>
			</Div>
		</>
	);
};

export default EmployeeProfileForm;
