import { get, post, put, del } from './http';
import {
	getBusinesses as getBusinessesRx,
	getBusiness as getBusinessRx,
	updateBusiness as updateBusinessRx
} from 'root/reducers/businesses';
import { addNotification } from 'root/reducers/notifications';

export const getBusinesses = () => dispatch => {
	return get('/businesses/')
		.then(data => {
			dispatch(getBusinessesRx(data));
			return data;
		})
		.catch(err => {
			console.log('Get business failed');
		});
};

export const getBusiness = businessId => dispatch => {
	return get(`/businesses/${businessId}/`)
		.then(data => {
			dispatch(getBusinessRx(data));
			return data;
		})
		.catch(err => {
			console.log('Get business failed');
		});
};

export const addBusiness = data => {
	return post('/businesses/', data);
};

export const updateBusiness = (businessId, data) => {
	return dispatch =>
		put(`/businesses/${businessId}/`, data)
			.then(response => {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Account settings updated successfully.',
						options: {
							autoDismiss: true
						}
					})
				);
				dispatch(updateBusinessRx({ id: businessId, data: response[0] }));
			})
			.catch(err => {
				console.log('Error', err);
				dispatch(
					addNotification({
						type: 'danger',
						message: 'There was an error with the submission.',
						options: {
							autoDismiss: true
						}
					})
				);
			});
};

export const deleteBusiness = businessId => {
	return del(`/businesses/${businessId}`);
};

export const searchBusinesses = (keyword, contact) => {
	const params = {};
	if (keyword) {
		params.keyword = keyword;
	}
	if (contact) {
		params.contact = contact;
	}
	return get(`/businesses/`, params);
};

export const sendBusinessReferral = (businessId, data) => dispatch => {
	return post(`/send_business_referral/${businessId}/`, data)
		.then(resp => {
			if (data.get('trigger') == 'troop_triggered') {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Referral was sucessfully sent'
					})
				);
			}
		})
		.catch(error => {
			if (data.get('trigger') == 'troop_triggered') {
				dispatch(
					addNotification({
						type: 'danger',
						message: 'Failed to send referral'
					})
				);
			}
		});
};

// Payment Intent
export const getPaymentIntent = businessId => {
	return get(`/businesses/${businessId}/create_business_payment_intent/`);
};

// Payment Submit
export const submitPaymentIntent = (businessId, data) => {
	return post(
		`/businesses/${businessId}/create_business_payment_checkout/`,
		data
	);
};

export const requestInvoiceSetup = (businessId, data) => {
	return post(
		`/businesses/${businessId}/request_business_invoice_setup/`,
		data
	);
};

// Plan details
export const getPlan = businessId => {
	return get(`/businesses/${businessId}/plan`);
};

// Update plan
export const updatePlan = (businessId, data) => {
	return put(`/businesses/${businessId}/plan`, data);
};

// Get Invoices
export const getInvoices = businessId => {
	return get(`/businesses/${businessId}/invoices`);
};

export const getInvoice = (businessId, invoiceId) => {
	return get(`/businesses/${businessId}/invoices/${invoiceId}`);
};

export const sendFinalVoteEmail = businessId => {
	return post(`/businesses/${businessId}/final_vote_call/`);
};

export const getBusinessNewsFeed = businessId => {
	return get(`/businesses/${businessId}/news_feed/`);
};

export const createCustomNewsItem = data => {
	return post(`/create_news_feed/`, data);
};

export const updateVoteResult = data => {
	return post(`/update-vote-result/`, data);
};

export const getBusinessInfo = businessId => {
	return get(`/businesses/${businessId}/`);
};

export const updateBusinessInfo = (businessId, data) => {
	return put(`/businesses/${businessId}/`, data);
};

export const getSummaryOfImpact = data => {
	return get(
		`/get_summary_of_impact/?date=${data.date}&business_id=${data.business_id}`
	);
};

export const exportVoteHistoryForBusiness = data => {
	return post(`/export-business-vote-history/`, data);
};
