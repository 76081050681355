export const headingInCompactedForm = (
	cx,
	styles,
	dayjs,
	monthDate,
	voteIsOpen,
	activeNeeds,
	Text
) => {
	return (
		<>
			{/* <div className={cx(styles.headLeft)}>
				<div>
					<div>
						<Text>
							<span className={cx(styles.bold)}>
								{dayjs(monthDate).format('MMMM')}'s Needs:
							</span>{' '}
							{voteIsOpen ? 'Vote Open!' : 'Vote Closed'}
						</Text>
					</div>
				</div>
			</div>
			<div>
				<div>
					{voteIsOpen ? (
						<>
							<span className={cx(styles.bold)}>Vote Close Date:</span>{' '}
							{dayjs(activeNeeds?.[0]?.vote_close_date)?.format('MMM Do')}
						</>
					) : (
						<>
							<span className={cx(styles.bold)}>Pledge Close Date:</span>{' '}
							{dayjs(activeNeeds?.[0]?.pledge_close_date)?.format('MMM Do')}
						</>
					)}
				</div>
			</div> */}
			<div>Community Needs</div>
		</>
	);
};

export const headingInDetialedForm = (
	cx,
	styles,
	dayjs,
	votedMonths,
	setCurMonth
) => {
	return (
		<>
			<div className={cx('employeeHeading')}>This Months Local Needs</div>
			<div className="employeeHeading">
				<span>Need Month: </span>
				<select
					className={cx(styles.selectMonth)}
					onChange={e => setCurMonth(e.target.value)}>
					{votedMonths?.map((month, idx) => (
						<option key={idx} value={month}>
							{dayjs(month).format('MMM YYYY')}
						</option>
					))}
				</select>
			</div>
		</>
	);
};
