import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Chart from 'root/baseComponents/Chart';

import styles from '../Dashboard.module.scss';

const Y_AXIS_OPTIONS = {
	ticks: {
		callback: function (value, index, ticks) {
			return '$' + parseFloat(value);
		},
		beginAtZero: true,
		color: 'black',
		stepSize: 250
	},
	title: {
		display: true,
		text: 'CAD',
		font: {
			size: 14
		}
	},
	grid: {
		display: true,
		drawTicks: true,
		drawOnChartArea: true,
		color: 'silver'
	},
	stacked: false
};

const X_AXIS_OPTIONS = {
	ticks: {
		beginAtZero: true,
		color: 'black'
	},
	title: {
		display: true,
		text: 'Date',
		font: {
			size: 14
		}
	},
	grid: {
		display: true,
		drawTicks: true,
		drawOnChartArea: false,
		color: 'black'
	},
	stacked: false
};

const BarChart = ({ fulfilledNeeds, year }) => {
	const [barChartData, setBarCahrtData] = useState([
		{ x: 'Jan', y1: 0 },
		{ x: 'Feb', y1: 0 },
		{ x: 'Mar', y1: 0 },
		{ x: 'Apr', y1: 0 },
		{ x: 'May', y1: 0 },
		{ x: 'Jun', y1: 0 },
		{ x: 'Jul', y1: 0 },
		{ x: 'Aug', y1: 0 },
		{ x: 'Sep', y1: 0 },
		{ x: 'Oct', y1: 0 },
		{ x: 'Nov', y1: 0 },
		{ x: 'Dec', y1: 0 }
	]);

	useEffect(() => {
		let locaBarChartData;
		if (year === 'last_12_months') {
			locaBarChartData = [];
			const today = dayjs();
			for (let i = 11; i >= 0; i--) {
				let curMonth = today.subtract(i, 'month').format('MMM');
				locaBarChartData.push({ x: curMonth, y1: 0 });
			}
		} else {
			locaBarChartData = [
				{ x: 'Jan', y1: 0 },
				{ x: 'Feb', y1: 0 },
				{ x: 'Mar', y1: 0 },
				{ x: 'Apr', y1: 0 },
				{ x: 'May', y1: 0 },
				{ x: 'Jun', y1: 0 },
				{ x: 'Jul', y1: 0 },
				{ x: 'Aug', y1: 0 },
				{ x: 'Sep', y1: 0 },
				{ x: 'Oct', y1: 0 },
				{ x: 'Nov', y1: 0 },
				{ x: 'Dec', y1: 0 }
			];
		}
		fulfilledNeeds?.forEach(item => {
			let fulfilledMonth = dayjs(item.fulfilled_date).format('MMM');
			let curItem = locaBarChartData?.filter(
				item => item.x === fulfilledMonth
			)[0];
			curItem.y1 += item.contribution_value;
		});
		setBarCahrtData(locaBarChartData);
	}, [fulfilledNeeds]);

	const data = useMemo(() => {
		if (barChartData) {
			const x = [];
			const y1 = [];
			barChartData.forEach(d => {
				x.push(d['x']);
				y1.push(d['y1']);
			});
			const localData = {
				labels: x,
				datasets: [
					{
						label: 'Donation',
						data: y1,
						// borderColor: 'red',
						backgroundColor: '#666dff'
					}
				]
			};
			return localData;
		}
	}, [barChartData]);
	return (
		<>
			<Chart
				type="bar"
				data={data}
				showLegend={true}
				titleText="Amount of donation per month"
				yAxisOptions={Y_AXIS_OPTIONS}
				xAxisOptions={X_AXIS_OPTIONS}
				showDataLabels={false}
				barBorderWidth={0}
				indexAxis="x"
			/>
		</>
	);
};

export default BarChart;
