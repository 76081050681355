import React from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
	Div,
	Paragraph,
	Alert as BaseAlert,
	AlertItem
} from 'basedesign-iswad';
import Close from 'root/baseComponents/Close';
import { removeAlertItem } from 'root/utils/notifications';

import styles from './Notification.module.scss';

const Notification = () => {
	const dispatch = useDispatch();
	const charityNotifications = useSelector(state => state.charityNotifications);

	return (
		<>
			<BaseAlert className={cx(styles.container)}>
				{charityNotifications?.map(notif => (
					<AlertItem
						key={notif.key}
						isActive={notif.isActive}
						className={cx(
							'm1 p1',
							notif?.type === 'success' && styles.success,
							notif?.type === 'error' && styles.error,
							notif?.type === 'warning' && styles.warning
						)}>
						<Div
							className="w-per-100"
							type="flex"
							direction="horizontal"
							distributedBetween>
							<Paragraph className="p1">{notif.message}</Paragraph>
							<Close onClick={() => removeAlertItem(dispatch, notif.key)} />
						</Div>
					</AlertItem>
				))}
			</BaseAlert>
		</>
	);
};

export default Notification;
