import { get, post, put, del } from './http';
import {
	getBusinessNeeds as getBusinessNeedsRx,
	getNeeds as getNeedsRx,
	getFulfilledNeeds as getFulfilledNeedsRx,
	pledgeNeed as pledgeNeedRx,
	clearPledgeNeed as clearPledgeNeedRx,
	pledgeAmount as pledgeAmountRx
} from 'reducers/needs';

export const getNeeds = businessId => dispatch => {
	let req;
	if (businessId) {
		req = get(`/businesses/${businessId}/needs/`);
		return req.then(data => {
			dispatch(getBusinessNeedsRx(data));
		});
	} else {
		req = get('/needs/');
		return req.then(data => {
			dispatch(getNeedsRx(data));
		});
	}
};

export function getNeed(needId) {
	return get(`/needs/${needId}/`);
}

export const getFulfilledNeeds = needId => dispatch => {
	return get(`/fulfilled_needs/${needId}/need/`).then(data =>
		dispatch(getFulfilledNeedsRx(data))
	);
};

export const addFulfilledNeedData = data => {
	return post(`/fulfilled_needs/${data.get('need_id')}/need/`, data, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
};

export const getPledgedNeedInfo = (businessId, businessNeedId) => {
	return get(
		`/businesses/${businessId}/needs/${businessNeedId}/collected_money_for_need/`
	).then(data => data);
};

export const pledgeForNeed = data => {
	return post(`/pledged_need/`, data);
};

export const pledgeNeed = data => dispatch => {
	return dispatch(pledgeNeedRx(data));
};

export const clearPledgeNeed = () => dispatch => {
	return dispatch(clearPledgeNeedRx());
};

export const pledgeAmount = data => dispatch => {
	return dispatch(pledgeAmountRx(data));
};

export const getAllPledges = () => {
	return get(`/pledged_need/`);
};

export const getAllNeeds = (
	page_number,
	need_type,
	need_neighbourhood,
	need_province
) => {
	return get(
		`/all-needs/?page_number=${page_number}&need_type=${need_type}&need_neighbourhood=${need_neighbourhood}&need_province=${need_province}`
	);
};

export const getAllFulfilledNeeds = (
	page_number,
	need_type,
	need_neighbourhood
) => {
	return get(
		`/all-fulfilled-needs/?page_number=${page_number}&need_type=${need_type}&need_neighbourhood=${need_neighbourhood}`
	);
};

export const retrieveSingleNeed = (charity_name, need_id) => {
	return get(`/retrieve-single-need/${charity_name}/${need_id}`);
};

export const retrieveFeaturedNeed = () => {
	return get(`/retrieve-featured-need/`);
};

export const retrieveNeeds = (current_page = 0) => {
	if (current_page > 0) {
		return get(`/need-app/?page=${current_page}`);
	} else {
		return get(`/need-app/`);
	}
};

export const retrieveFilteredNeeds = data => {
	return post(`/need-app-filter/`, data);
};

export const createNeed = data => {
	return post(`/need-app/`, data);
};

export const retrieveSingleNeedForWriter = id => {
	return get(`/single-need-app/${id}/`);
};

export const updateNeed = (id, data) => {
	return put(`/single-need-app/${id}/`, data);
};

export const deleteNeed = id => {
	return del(`/single-need-app/${id}/`);
};

export const getOnVotingNeeds = (date = '', business_id = '') => {
	if (!date) {
		return get(`/on_voting_needs/?business_id=${business_id}`);
	} else {
		return get(`/on_voting_needs/?business_id=${business_id}&date=${date}`);
	}
};

export const getBusinessVotedMonths = businessId => {
	return get(`/business_voted_months/${businessId}/`);
};

export const getOnVotingNeedsPublicPage = (business_id, date = '') => {
	if (!date) {
		return get(`/on_voting_needs_public_page/${business_id}/`);
	} else {
		return get(`/on_voting_needs_public_page/${business_id}/?date=${date}`);
	}
};

export const updateNeedByCharityAdmin = (needId, data) => {
	return put(`/need-manager-by-charity-admin/${needId}/`, data);
};

export const deleteNeedByCharityAdmin = needId => {
	return del(`/need-manager-by-charity-admin/${needId}/`);
};

export const getNeedsForVote = () => {
	return get(`/build-vote/`);
};

export const getFilteredNeedsForVote = data => {
	return post(`/build-vote-filtered-needs/`, data);
};

export const getActiveVotes = (business_id, date) => {
	if (date) {
		return get(`/active-votes/${business_id}/?date=${date}`);
	} else {
		return get(`/active-votes/${business_id}/`);
	}
};

export const duplicateNewNeed = data => {
	return post(`/duplicate-new-need/`, data);
};

//
export const adminUpdateNeedStatus = data => {
	return post(`/admin-update-need-status/`, data);
};
