import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Div, Card } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button/Button';
import CharityWrapper from '../CharityWrapper';
import TextBox from 'root/baseComponents/TextBox/TextBox';
import FormTextArea from 'root/baseComponents/FormTextArea/FormTextArea';
import Form from 'root/baseComponents/Form/Form';
import FormSelect from 'root/baseComponents/FormSelect';

import { addAlertItem } from 'root/utils/notifications';
import { addNotification } from 'root/reducers/notifications';

import { updateCharityInfo } from 'root/services/charity';

import Profile from './subs/Profile';
import styles from './Account.module.scss';
import { PROVINCE_CHOICES } from './constants';

function Account() {
	const dispatch = useDispatch();
	const charityInfo = useSelector(state => state.charityInfo);
	const toBeValidatedFields = [];

	const [about, setAbout] = useState('');
	const [aboutErrorMessage, setAboutErrorMessage] = useState('');

	const [organAddress, setOrganAddress] = useState('');
	const [organAddressErrorMessage, setOrganAddressErrorMessage] = useState('');

	const [organProvince, setOrganProvince] = useState('');
	const [organProvinceErrorMessage, setOrganProvinceErrorMessage] =
		useState('');

	const [postalCode, setPostalCode] = useState(charityInfo?.postal_code);
	const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('');

	const [organCity, setOrganCity] = useState(charityInfo?.city);
	const [organCityErrorMessage, setOrganCityErrorMessage] = useState('');

	const customValidations = () => {
		let validated = true;

		if (!about) {
			setAboutErrorMessage('You must write about your organisation!');
			validated = false;
		}

		return validated;
	};

	const submitHandler = async e => {
		if (customValidations()) {
			const data = {
				charity_id: charityInfo?.id,
				about_organization: about,
				address: organAddress,
				city: organCity,
				postal_code: postalCode,
				province: organProvince
			};
			try {
				const res = await updateCharityInfo(data);
				addAlertItem(
					dispatch,
					`Charity information has been updated successfully!`,
					'success'
				);
			} catch (err) {
				console.log(err);
				addAlertItem(
					dispatch,
					`Something went wrong, please try again!`,
					'error'
				);
			}
		}
	};

	useEffect(() => {
		if (charityInfo) {
			setAbout(charityInfo?.about_organization);
			setOrganAddress(charityInfo?.address);
			setOrganCity(charityInfo?.city);
			setPostalCode(charityInfo?.postal_code);
			setOrganProvince(charityInfo?.province);
		}
	}, [charityInfo]);

	return (
		<>
			<CharityWrapper pageName="Account">
				<Div className={cx(styles.main)}>
					<Div className="employeeHeader mb2">Organisation Information</Div>
					<Card className={cx('card pt4 pl8 pr8', styles.card)}>
						<Form
							toBeValidatedFields={toBeValidatedFields}
							onSubmit={submitHandler}>
							<FormTextArea
								labelText="About Your Organisation"
								val={about}
								setVal={setAbout}
								errorMessage={aboutErrorMessage}
								errorHandler={setAboutErrorMessage}
							/>
							<Div type="flex">
								<TextBox
									labelText="Address"
									type="text"
									val={organAddress}
									setVal={setOrganAddress}
									errorHandler={setOrganAddressErrorMessage}
									name="organ_address"
									errorMessage={organAddressErrorMessage}
								/>
								<Div className="w-px-200 ml3">
									<TextBox
										labelText="City"
										type="text"
										val={organCity}
										setVal={setOrganCity}
										errorHandler={setOrganCityErrorMessage}
										name="organ_city"
										errorMessage={organCityErrorMessage}
									/>
								</Div>
							</Div>
							<Div
								type="flex"
								vAlign="end"
								distributedBetween
								className="w-per-100">
								<Div type="flex" vAlign="start">
									<FormSelect
										labelText="Province"
										className={cx('mr2 w-px-100')}
										options={PROVINCE_CHOICES}
										fullWidth
										openOptionsDownWard={true}
										val={organProvince}
										setVal={setOrganProvince}
										initialSelected={charityInfo?.province}
										errorMessage={organProvinceErrorMessage}
										errorHandler={setOrganProvinceErrorMessage}
									/>
									<Div className="mr2 w-px-100">
										<TextBox
											labelText="Postal Code"
											type="text"
											val={postalCode}
											setVal={setPostalCode}
											errorHandler={setPostalCodeErrorMessage}
											name="organ_postalcode"
											errorMessage={postalCodeErrorMessage}
										/>
									</Div>
								</Div>

								<Div type="flex" className="mb4">
									<Button className="w-px-200 hand-mouse">Save</Button>
								</Div>
							</Div>
						</Form>
					</Card>
					<Profile />
				</Div>
			</CharityWrapper>
		</>
	);
}

export default Account;
