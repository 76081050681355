export const DASHBOARD_ITEMS = [
	{
		title: 'Dashboard',
		icon: 'dashboard-fill',
		to: '/',
		user: 'troop_writer'
	},
	{
		title: 'Charities',
		icon: 'heart-fill',
		to: '/writer-charities',
		user: 'troop_writer'
	},
	{
		title: 'Needs',
		icon: 'heart-fill',
		to: '/writer-needs',
		user: 'troop_writer'
	}
];
