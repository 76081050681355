import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setPublicModalType'] = (state, action) => action.payload;
reducerObject['clearPublicModalType'] = (state, action) => '';

const slice = createSlice({
	name: 'publicModalType',
	initialState: '',
	reducers: reducerObject
});

export const { setPublicModalType, clearPublicModalType } = slice.actions;
export default slice.reducer;
