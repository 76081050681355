/**
 * List of provinces in Canada
 */
export const provinces = {
	AB: 'Alberta',
	BC: 'British Columbia',
	MB: 'Manitoba',
	NB: 'New Brunswick',
	NL: 'Newfoundland and Labrador',
	NS: 'Nova Scotia',
	NT: 'Northwest Territories',
	NU: 'Nunavut',
	ON: 'Ontario',
	PE: 'Prince Edward Island',
	QC: 'Québec',
	SK: 'Saskatchewan',
	YT: 'Yukon'
};

/**
 * const for string "Last 12 months"
 */
export const LAST_TWELVE_MONTHS_STRING = 'Last 12 months';
