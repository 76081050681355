import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';
import Button from 'root/baseComponents/Button/Button';
import TextArea from 'root/baseComponents/FormTextArea/FormTextArea';
import Close from 'root/baseComponents/Close';
import Icon from 'root/baseComponents/Icon';
import FormSelect from 'root/baseComponents/FormSelect';
import ReactGA from "react-ga4";

import { sendUserFeedback } from 'root/services/feedback';

import { setSeenFeedbackModal } from 'root/reducers/reminders';

import { FUTURE_CHOICES } from './constants';
import styles from './styles.module.scss';

function FeedbackModal({ setShowPopUpModal }) {
	const dispatch = useDispatch();

	const rates = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	const [feedback, setFeedback] = useState('');
	const [feedbackErrorMessage, setFeedbackErrorMessage] = useState('');
	const [filled, setFilled] = useState(-1);
	const [filledErrorMessage, setFilledErrorMessage] = useState('');
	const [futureChoice, setFutureChoice] = useState(false);
	const [futureChoiceErrorMessage, setFutureChoiceErrorMessage] =
		useState(false);
	const [showModal, setShowModal] = useState(false);

	const setSeenModal = async () => {
		const res = await sendUserFeedback({ event: 'seen_feedback_modal' });
		dispatch(setSeenFeedbackModal(true));
	};

	const sendUserFeedbackText = async () => {
		if (filled > -1) {
			const res = await sendUserFeedback({
				event: 'send_feedback',
				feedback,
				rate: filled,
				selected_future_feature: futureChoice
			});
			console.log(res);
			dispatch(setSeenFeedbackModal(true));
			await sendUserFeedback({ event: 'seen_feedback_modal' });
			dispatch(setSeenFeedbackModal(true));
		} else {
			setFilledErrorMessage('Please rate us to submit your feedback');
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setShowModal(true);
		}, 2000);
	}, []);

	return (
		<>
			{showModal && (
				<Div
					className={cx(
						'w-per-100 height-vh-full pos-fix pos-fix--lt',
						styles.mainContainer
					)}>
					{/* <Div
						className={cx(
							'w-per-100 height-vh-full pos-fix pos-fix--lt bgBlack op-30'
						)}></Div> */}
					<Div
						className={cx(
							'pos-fix pos-fix--center mt2 card br-rad-px-5 of-hidden w-per-90 max-w-px-600',
							styles.modalContainer
						)}>
							<Div type='flex' vAlign='center' className='bgInfo px1'>
								<Close onClick={setSeenModal} />
								<Div className="textWhite f-b py1 ">
									Tell us how you feel
								</Div>
							</Div>
							<Div className='p2'>
							<Div className="mb1 labelForInput">
							How likely are you to recommend Troop to a friend?
						</Div>
						<Div type="flex" hAlign="center" className={cx('flex--wrap')}>
							{rates?.map(count => (
								<Div
									key={count}
									className="mr2 mb2"
									onClick={() => {
										setFilled(count);
										setFilledErrorMessage('');
									}}>
									<Div
										type="flex"
										hAlign="center"
										vAlign="center"
										className={cx(
											'w-px-30 height-px-30 fs-px-12 textWhite mouse-hand br-rad-per-50',
											count === filled ? 'bgInfo' : 'bgPrimary'
										)}>
										{count}
									</Div>
								</Div>
							))}
						</Div>
						<Div
							type="flex"
							distributedBetween
							className="w-per-100 fs-px-10 mb2 textGray">
							<Div>0: Not likely</Div>
							<Div>10: Very likely</Div>
						</Div>
						{filledErrorMessage && (
							<Div className="textRed fs-px-12 my1">{filledErrorMessage}</Div>
						)}
						<FormSelect
							className={cx('mb4')}
							labelText="Tell us what you want to see next from Troop"
							options={FUTURE_CHOICES}
							fullWidth
							openOptionsDownWard={true}
							val={futureChoice}
							setVal={setFutureChoice}
							errorMessage={futureChoiceErrorMessage}
							errorHandler={setFeedbackErrorMessage}
							itemsCenteralized={false}
						/>
						<TextArea
							labelText="Tell us more:"
							val={feedback}
							setVal={setFeedback}
							errorMessage={feedbackErrorMessage}
							errorHandler={setFeedbackErrorMessage}
						/>
						<Div className="flex flex--jc--center">
							<Button onClick={()=> {sendUserFeedbackText(); 
													ReactGA.event({
														event_name: 'send_feedback_clicked',
														category: "Button Click",
														action: "NPS Sent"
													  });}}>SEND FEEDBACK</Button>{' '}
						</Div>
							</Div>
					
					</Div>
				</Div>
			)}
		</>
	);
}

export default FeedbackModal;
