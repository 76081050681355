import { get, post } from './http';

export const slackAppActivate = data => {
	return post(`/slack-app-activate/`, data);
};

export const getSlackBusinesses = () => {
	return get(`/slack-businesses/`);
};

export const getSlackBusiness = business_id => {
	return get(`/slack-businesses/${business_id}/`);
};

export const createSlackbusiness = data => {
	return post(`/slack-businesses/`, data);
};

export const sendImpactReportToSlack = data => {
	return post(`/slack-send-impact-report/`, data);
};

export const getSlackLogs = () => {
	return get(`/slack_logs/`);
};
