import React, { useEffect } from 'react';
import cx from 'classnames';

import ReportCard from './ReportCard';

import styles from '../MyImpact.module.scss';

const EmployeeVoteSection = ({
	activeNeeds,
	fulfilledNeeds,
	activatedFulfilledNeed,
	setActivatedFulfilledNeed
}) => {
	return (
		<>
			<div className={styles.voteSectionRow}>
				<div className={cx(styles.container)}>
					<div className={cx(styles.body)}>
						{activeNeeds.map(need => {
							return (
								<ReportCard
									key={need.need_info.id}
									need={need.need_info}
									fulfilledNeed={need.need_info.id in fulfilledNeeds}
									fulfilledByEmployee={
										need.need_info.id in fulfilledNeeds &&
										fulfilledNeeds[need.need_info.id].byEmployee
									}
									activatedFulfilledNeed={activatedFulfilledNeed}
									setActivatedFulfilledNeed={setActivatedFulfilledNeed}
									voteCounts={need.total_vote_count || need.total_need_votes}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default EmployeeVoteSection;
