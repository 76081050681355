import React, { useState } from 'react';
import cx from 'classnames';
import { Div, Heading } from 'basedesign-iswad';

import styles from './Invite.module.scss';

import ReferFriendForm from './subs/ReferFriendForm';
import ReferCharityForm from './subs/ReferCharityForm';

const Invite = () => {
	return (
		<>
			<Div className={cx(styles.main)}>
				<ReferFriendForm />
				<ReferCharityForm />
			</Div>
		</>
	);
};

export default Invite;
