import { useSelector } from 'react-redux';
import useLoggedIn from './useLoggedIn';

export default function useSuperAdminLoggedIn() {
	const loggedIn = useLoggedIn();
	const isSuperAdmin = useSelector(state =>
		state.users.currentUserRoles.includes('Troop Admin')
	);
	return loggedIn && isSuperAdmin;
}
