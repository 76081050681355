// VENDOR imports
import { useCallback } from 'react';

export default function useCopyUrl({
	url,
	currentPeriod,
	business,
	dispatch,
	setReportCopied,
	setVoteCopied,
	reportCopied,
	voteCopied
}) {
	const copyUrl = useCallback(
		e => {
			const body = document.querySelector('body');
			const area = document.createElement('textarea');
			body.appendChild(area);

			area.value = url;
			area.select();
			document.execCommand('copy');

			body.removeChild(area);
			if (reportCopied) {
				setReportCopied('Copied');
				setVoteCopied('Copy to clipboard');
			}
			if (voteCopied) {
				setReportCopied('Copy to clipboard');
				setVoteCopied('Copied');
			}
		},
		[currentPeriod, business, dispatch, setReportCopied, setVoteCopied]
	);

	return copyUrl;
}
