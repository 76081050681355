import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';
import { Icon } from 'troop-design';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';
import Search from 'root/baseComponents/Search';
import BaseIcon from 'root/baseComponents/Icon';

import { exportVoteHistoryForBusiness } from 'root/services/businesses';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import {
	retrieveAllEmployeesInfo,
	updateEmployeeInfo,
	deleteEmployeeInfo
} from 'root/services/employees';
import useCurrentBusiness from 'root/hooks/useCurrentBusiness';
import useSuperAdminLoggedIn from 'root/hooks/useSuperAdminLoggedIn';

import { clearModal } from 'root/utils/modal';
import { setModalProps } from 'root/reducers/charityReducers/modalProps';
import { setModalType } from 'root/reducers/charityReducers/modalType';

const DisplayEmployees = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const params = useParams();
	const businesses = useSelector(state => state?.businesses?.businesses);

	const isSuperAdmin = useSuperAdminLoggedIn();
	let business = useCurrentBusiness();
	const businessById = useSelector(state =>
		state.businesses.businesses.find(
			business => business.id === parseInt(params.businessId, 10)
		)
	);
	if (isSuperAdmin) {
		business = businessById;
	}

	const [businessId, setBusinessId] = useState(0);
	const [activeMonths, setActiveMonths] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [employees, setEmployees] = useState(0);
	const [numberOfAllPages, setNumberOfAllPages] = useState(1);
	const [filters, setFilters] = useState({ page_number: 1 });
	const [selectedData, setSelectedData] = useState([]);
	const [names, setNames] = useState({});
	const [newAdmins, setNewAdmins] = useState([]);
	const [newEmployees, setNewEmployees] = useState([]);

	const retrieveAllEmployeesInfoData = async () => {
		try {
			dispatch(setLoading());
			const res = await retrieveAllEmployeesInfo(businessId, filters);
			dispatch(setLoadingComplete());
			console.log(res);
			setEmployees([...res.employees]);
			setNumberOfAllPages(parseInt(res.number_of_employees / 10) + 1);
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
		}
	};

	const deleteClickHandler = async (item) => {
			try {
				const res = await deleteEmployeeInfo(item.id);
				let localEmployees = [...employees];
				localEmployees = localEmployees.filter(
					curEmployee => curEmployee.id !== item.id
				);
				setEmployees([...localEmployees]); 
				clearModal(dispatch);
			} catch (err) {
				console.log(err);
			}
	}

	const changeStatusToUserkHandler = async (item) => {
		try {
			const res = await updateEmployeeInfo(item.id, {
				remove_admin: true
			});
			let localNewEmployees = [...newEmployees];
			let localNewAdmins = [...newAdmins];
			localNewEmployees.push(item.id);
			console.log(localNewEmployees);
			localNewAdmins = localNewAdmins.filter(
				curId => curId != item.id
			);
			setNewEmployees([...localNewEmployees]);
			setNewAdmins(localNewAdmins);
			clearModal(dispatch)
		} catch (err) {
			console.log(err);
		}
	}
	const changeStatusToAdminkHandler = async (item) => {
	try {
		const res = await updateEmployeeInfo(item.id, {
			make_admin: true
		});
		let localNewEmployees = [...newEmployees];
		let localNewAdmins = [...newAdmins];
		localNewEmployees = localNewEmployees.filter(
			curId => curId != item.id
		);
		localNewAdmins.push(item.id);
		setNewEmployees([...localNewEmployees]);
		setNewAdmins([...localNewAdmins]);
		clearModal(dispatch)
	} catch (err) {
		console.log(err);
	}
}

	useEffect(() => {
		if (params?.businessId) {
			setBusinessId(params.businessId);
		} else if (businesses?.length === 1) {
			setBusinessId(businesses[0].id);
		}
	}, [businesses, params?.businessId]);

	useEffect(() => {
		if (business && businessId) {
			setActiveMonths({
				0: dayjs(business.lastThreeVoteDates?.[0]).format('MMM'),
				1: dayjs(business.lastThreeVoteDates?.[1]).format('MMM'),
				2: dayjs(business.lastThreeVoteDates?.[2]).format('MMM')
			});
			retrieveAllEmployeesInfoData();
		}
	}, [business, businessId, filters]);

	const headLines = useMemo(() => {
		if (activeMonths?.[0]) {
			return [
				{
					value: 'select',
					display: (
						<Div type="flex" vAlign="center">
							<Div>
								<input
									type="checkbox"
									style={{ transform: 'scale(1.25)' }}
									className="mouse-hand"
									checked={false}
									onChange={e => {
										console.log(e.target.checked);
									}}
								/>
							</Div>
						</Div>
					),
					width: 25,
					hasSearch: false,
					isSortable: false
				},
				{
					value: 'name',
					display: (
						<Div type="flex" vAlign="center">
							<Div>Name</Div>
							<Div>
								<Search
									onChange={e => {
										setCurrentPage(1);
										setTimeout(() => {
											const localFilters = { ...filters };
											localFilters['name'] = e.target.value;
											setFilters(localFilters);
										}, 500);
									}}
								/>
							</Div>
						</Div>
					),
					width: 275,
					hasSearch: false,
					isSortable: false
				},
				{
					value: 'email',
					display: (
						<Div type="flex" vAlign="center">
							<Div>Email</Div>
							<Div>
								<Search
									onChange={e => {
										setCurrentPage(1);
										setTimeout(() => {
											const localFilters = { ...filters };
											localFilters['email'] = e.target.value;
											setFilters(localFilters);
										}, 500);
									}}
								/>
							</Div>
						</Div>
					),
					width: 300,
					hasSearch: false,
					isSortable: false
				},
				{
					value: 'last_voted',
					display: 'Last Voted',
					width: 200,
					hasSearch: false,
					isSortable: false
				},
				{
					value: 'months_ago_0',
					display: activeMonths?.[0],
					width: 100,
					hasSearch: false,
					isSortable: false
				},
				{
					value: 'months_ago_1',
					display: activeMonths?.[1],
					width: 100,
					hasSearch: false,
					isSortable: false
				},
				{
					value: 'months_ago_2',
					display: activeMonths?.[2],
					width: 100,
					hasSearch: false,
					isSortable: false
				}
			];
		} else {
			return [];
		}
	}, [activeMonths]);

	const data = useMemo(() => {
		let tableData = [];
		if (employees?.length) {
			employees?.forEach(item => {
				tableData.push({
					id: item?.id,
					select: {
						value: item.id,
						display: (
							<Div type="flex" vAlign="center">
								<Div>
									<input
										type="checkbox"
										style={{ transform: 'scale(1.25)' }}
										className="mouse-hand"
										checked={selectedData.includes(item.id)}
										onChange={e => {
											let localSelectedData = [...selectedData];
											let localNames = { ...names };
											if (localSelectedData.includes(item.id)) {
												localSelectedData = localSelectedData.filter(
													curItem => curItem !== item.id
												);
												delete localNames[item.id];
											} else {
												localSelectedData.push(item.id);
												localNames[
													item.id
												] = `${item?.user?.first_name} ${item?.user?.last_name}`;
											}
											setSelectedData(localSelectedData);
											setNames(localNames);
										}}
									/>
								</Div>
							</Div>
						)
					},
					name: {
						value: `${item?.user?.first_name} ${item?.user?.last_name}`,
						display: selectedData.includes(item.id) ? (
							<Div type="flex" vAlign="center">
								<Div>
									<input
										type="text"
										// value={names[item.id]}
										className="w-px-150"
										onChange={e => {
											const localNames = { ...names };
											localNames[item.id] = e.target.value;
											setNames(localNames);
										}}
									/>
								</Div>
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									className="w-px-20 height-px-20 ml1 mouse-hand hover-bg-silver br-rad-per-50"
									onClick={async () => {
										try {
											const nameParts = names[item.id].split(' ');
											const firstName = nameParts?.[0];
											const lastName = nameParts?.slice(1)?.join(' ');
											const res = await updateEmployeeInfo(item.id, {
												first_name: firstName,
												last_name: lastName
											});
											let localSelectedData = [...selectedData];
											let localNames = { ...names };
											let localEmployees = [...employees];
											let localEditedEmployees = localEmployees.filter(
												curEmployee => curEmployee.id === item.id
											)?.[0];
											localEditedEmployees.user.first_name = firstName;
											localEditedEmployees.user.last_name = lastName;
											localSelectedData = localSelectedData.filter(
												curSelected => curSelected !== item.id
											);
											delete localNames[item.id];
											setSelectedData([...localSelectedData]);
											setNames({ ...localNames });
										} catch (err) {
											console.log(err);
										}
									}}>
									<BaseIcon type="check" scale={1.25} color="green" />
								</Div>
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									className="w-px-20 height-px-20 ml1 mouse-hand hover-bg-silver br-rad-per-50"
									onClick={() => {
										let localSelectedData = [...selectedData];
										let localNames = { ...names };
										localSelectedData = localSelectedData.filter(
											curSelected => curSelected !== item.id
										);
										delete localNames[item.id];
										setSelectedData([...localSelectedData]);
										setNames({ ...localNames });
									}}>
									<BaseIcon type="close" scale={1.25} color="blue" />
								</Div>
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									className="w-px-20 height-px-20 ml1 mouse-hand hover-bg-silver br-rad-per-50"
									onClick={async ()=>{
										dispatch(setModalType('delete-employee'));
										dispatch(setModalProps({ text: 'Do you want to delete this employee?', header: 'Delete Employee', delete: deleteClickHandler, deleteItem: item}));
									}}
									>
									<BaseIcon type="delete" scale={1.25} color="red" /> 
								</Div>
							</Div>
						) : (
							<Div
								className="w-per-100"
								type="flex"
								distributedBetween
								vAlign="center">
								<Div>{`${item?.user?.first_name} ${item?.user?.last_name}`}</Div>

								{(!newEmployees.includes(item.id) &&
									item?.user?.groups?.includes('Business Admin')) ||
								newAdmins.includes(item.id) ? (
									<Div
										className="ml1 mouse-hand"
										onClick={async ()=>{
											dispatch(setModalType('change-employee-status'));
											dispatch(setModalProps({ text: `Do you want to change status of ${item?.user?.first_name} ${item?.user?.last_name} to a regular employee?`, header: 'Change Status to Employee', changeStatus: changeStatusToUserkHandler, deleteItem: item}));
										}}>
										<BaseIcon type="admin" scale={1.25} color="black" />
									</Div>
								) : (
									<Div
										className="ml1 mouse-hand"
										onClick={async ()=>{
											dispatch(setModalType('change-employee-status'));
											dispatch(setModalProps({ text: `Do you want to change status of ${item?.user?.first_name} ${item?.user?.last_name} to an admin?`, header: 'Change Status to Admin', changeStatus: changeStatusToAdminkHandler, deleteItem: item}));
										}}>
										<BaseIcon type="user" scale={1.25} color="gray" />
									</Div>
								)}
							</Div>
						)
					},
					email: item?.user?.email,
					last_voted: item?.lastVoted,
					months_ago_0: {
						value: item?.historicalVotes?.[0],
						display: (
							<Div>
								{item?.historicalVotes?.[0] == 'Waiting' && (
									<Icon icon="waiting2" />
								)}
								{item?.historicalVotes?.[0] == true && (
									<Icon icon="checkmark2" />
								)}
								{item?.historicalVotes?.[0] == false && (
									<Icon icon="crossmark" />
								)}
							</Div>
						)
					},
					months_ago_1: {
						value: item?.historicalVotes?.[1],
						display: (
							<Div>
								{item?.historicalVotes?.[1] == 'Waiting' && (
									<Icon icon="waiting2" />
								)}
								{item?.historicalVotes?.[1] == true && (
									<Icon icon="checkmark2" />
								)}
								{item?.historicalVotes?.[1] == false && (
									<Icon icon="crossmark" />
								)}
							</Div>
						)
					},
					months_ago_2: {
						value: item?.historicalVotes?.[2],
						display: (
							<Div>
								{item?.historicalVotes?.[2] == 'Waiting' && (
									<Icon icon="waiting2" />
								)}
								{item?.historicalVotes?.[2] == true && (
									<Icon icon="checkmark2" />
								)}
								{item?.historicalVotes?.[2] == false && (
									<Icon icon="crossmark" />
								)}
							</Div>
						)
					}
				});
			});
		} else {
			tableData = [];
		}
		return tableData;
	}, [employees, headLines, selectedData, names, newAdmins, newEmployees]);

	useEffect(() => {
		const localFilters = { ...filters };
		localFilters['page_number'] = currentPage;
		setFilters(localFilters);
	}, [currentPage]);

	return (
		<>
			<Div
				type="flex"
				vAlign="center"
				distributedBetween
				className="flex--wrap my2">
				<Div>
					<Button
						colorType="primary"
						className="flex flex--jc--center flex--ai--center max-height-px-30"
						onClick={async () => {
							dispatch(setLoading());
							try {
								const res = await exportVoteHistoryForBusiness({
									business_id: businessId
								});
								if (res?.success) {
									window.location.replace(res?.file_address);
								}
								dispatch(setLoadingComplete());
							} catch (err) {
								console.log(err);
								dispatch(setLoadingComplete());
							}
						}}>
						Export Vote History
					</Button>
				</Div>
				<Div>
					<Button
						onClick={() =>
							history.push(`/businesses/${businessId}/add-employee`)
						}
						className="w-px-100 flex flex--jc--center flex--ai--center">
						Add
					</Button>
				</Div>
			</Div>
			<Div className="w-per-100 flex flex--jc--start of-x-auto scrollType1">
				{headLines?.length > 0 && (
					<Table
						headLines={headLines}
						data={data}
						colWidth={400}
						isSortable={false}
						isSelectable={false}
						// selectedData={selectedCustomerData}
						// setSelectedData={setSelectedCustomerData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={5}
						numberOfAllPages={numberOfAllPages}
					/>
				)}
			</Div>
		</>
	);
};

export default DisplayEmployees;
