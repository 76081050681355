import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Heading, Table, Anchor } from 'troop-design';

import { getInvoices } from 'root/services/businesses';

export default function BusinessTransactions({ business }) {
	dayjs.extend(isSameOrBefore);
	const [stripeTransactions, setStripeTransactions] = useState([]);

	const columns = useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'date',
				filter: 'equals'
			},
			{
				Header: 'Total',
				accessor: 'total',
				filter: 'equals'
			},
			{
				Header: 'Link to Receipt',
				accessor: 'receipt',
				filter: 'equals'
			}
		],
		[]
	);

	const data = useMemo(() => {
		return stripeTransactions.map(transaction => {
			return {
				date: dayjs.unix(transaction.transaction_date).format('MMM DD, YYYY'),
				total: '$' + transaction.amount.toFixed(2),
				status: 'Credit card transaction',
				receipt: (
					<Anchor target="_blank" to={transaction.receipt_url}>
						View
					</Anchor>
				)
			};
		});
	}, [stripeTransactions]);

	useEffect(() => {
		if (business?.id) {
			getInvoices(business.id).then(data => {
				if (Array.isArray(data)) {
					setStripeTransactions(data);
				}
			});
		}
	}, [business]);

	return (
		<>
			<Heading level={2}>Transaction History</Heading>
			<Table
				paginate
				itemsPerPage={20}
				data={data}
				columns={columns}
				striped={false}
				hover
			/>
		</>
	);
}
