import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Icon from 'root/baseComponents/Icon';
import Button from 'root/baseComponents/Button';
import { getBackendUrl } from 'root/utils/helpers';

import VoidReceiptConfirmation from './subs/VoidConfirmation';
import {
	retrieveAllTaxReceipts,
	makeATaxReceiptVoid
} from 'root/services/charity';

function ViewAllReceipts() {
	const history = useHistory();
	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [reciepts, setReciepts] = useState([]);
	const [showVoidConfirmationPopUp, setShowVoidConfirmationPopUp] =
		useState(false);
	const [receiptNumberToVoid, setReceiptNumberToVoid] = useState('');

	const retrieveAllTaxReceiptsData = async () => {
		const res = await retrieveAllTaxReceipts({ is_business: false });
		const localReceipts = [];
		res.forEach(item => {
			localReceipts.push({
				issued_date: dayjs(item.issued_date).format('DD-MMM-YY'),
				business: item?.business?.name,
				business_user: item?.business_user?.user?.email,
				link: item.file_name
					? `${getBackendUrl()}/media/pdfs/${item.file_name}`
					: '',
				seq_receipt_number: item.seq_receipt_number,
				is_void: item?.is_void || false
			});
		});
		setReciepts(localReceipts);
	};

	useEffect(() => {
		retrieveAllTaxReceiptsData();
	}, []);

	const headLines = [
		{
			value: 'issued_date',
			display: 'Issued Date',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'business',
			display: 'Business',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'business_user',
			display: 'Employee',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'reciept',
			display: 'Reciept',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'void',
			display: 'Make it void',
			width: 200,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		reciepts.forEach(item => {
			console.log(item);
			tableData.push({
				issued_date: item.issued_date,
				business: item?.business || '-',
				business_user: item?.business_user || '-',
				reciept: {
					value: item.link,
					display: (
						<Div>
							{item?.link ? (
								<a href={item.link} target="_blank">
									<Icon type="pdf" scale={1.5} />
								</a>
							) : (
								'-'
							)}
						</Div>
					)
				},
				void: {
					value: item.seq_receipt_number,
					display: (
						<Div>
							{!item?.is_void ? (
								<Button
									onClick={() => {
										setReceiptNumberToVoid(item.seq_receipt_number);
										setShowVoidConfirmationPopUp(true);
									}}>
									Click
								</Button>
							) : (
								<Div>It's Void</Div>
							)}
						</Div>
					)
				}
			});
		});

		return tableData;
	}, [reciepts]);
	return (
		<>
			<Div className="employeeHeading mt3 mb2">EmployeeReceipts</Div>
			<Div className="w-per-80 pr2 pl2 flex flex--jc--start ">
				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					tableWidth={'1000px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={10}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
			{showVoidConfirmationPopUp && (
				<VoidReceiptConfirmation
					setShowVoidConfirmationPopUp={setShowVoidConfirmationPopUp}
					receiptNumberToVoid={receiptNumberToVoid}
					setReceiptNumberToVoid={setReceiptNumberToVoid}
				/>
			)}
		</>
	);
}

export default ViewAllReceipts;
