import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Badge, Table, Spinner } from 'troop-design';
import slugify from 'slugify';
import { getNeeds } from 'services/needs';
import useLoader from 'root/hooks/useLoader';
import currency from 'utils/currency';
import styles from './NeedsAdmin.module.scss';

export default function NeedsAdminView() {
	const dispatch = useDispatch();
	const needs = useSelector(state => state.needs.needs);
	const [needsLoading] = useLoader('needs-admin-loading', () =>
		dispatch(getNeeds())
	);

	useEffect(() => {
		dispatch(getNeeds());
	}, [dispatch]);

	const columns = useMemo(
		() => [
			{
				Header: 'Charity',
				accessor: 'need'
			},
			{
				Header: 'Name',
				accessor: 'name',
				Cell: e => (
					<a
						target="_blank"
						href={`https://hitroop.com/need/${slugify(e.value)}/`}>
						{' '}
						{e.value}{' '}
					</a>
				)
			},
			{
				Header: 'Amount',
				accessor: 'amount'
			},
			{
				Header: 'Status',
				accessor: 'status',
				Cell: e => (
					<Badge variant={e.value} className={styles.badgeFitContent}>
						{e.value.charAt(0).toUpperCase() + e.value.slice(1)}
					</Badge>
				)
			}
		],
		[]
	);

	const data = useMemo(() => {
		return needs.map(need => {
			let variant = 'live';
			if (need.is_fulfilled) {
				variant = 'fulfilled';
			} else if (need.is_vote_winner) {
				variant = 'processing';
			} else if (need.is_voting) {
				variant = 'voting';
			}
			return {
				need: need.charity_name,
				name: need.name,
				amount: currency.format(need.value, 'en-CA', 'CAD', 0),
				status: variant
			};
		});
	}, [needs]);

	return (
		<>
			<Table
				enableFilters
				paginate
				itemsPerPage={20}
				className={styles.employeesTable}
				data={data}
				columns={columns}
				striped={false}
			/>
			{needsLoading && (
				<div className="d-flex justify-content-center align-items-center">
					<Spinner width={128} height={128} />
				</div>
			)}
		</>
	);
}
