// VENDOR imports
import React, { useState, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
	Tab,
	TabItem,
	TabContent,
	Icon,
	Form,
	Select,
	Container,
	// Heading,
	Text,
	Row,
	Col,
	Image
} from 'troop-design';
import { Div, Heading } from 'basedesign-iswad';
// imports
import { get, set } from 'utils/storage';
import useLoggedIn from 'hooks/useLoggedIn';
import { updateEmployeeVoteToRealTime } from 'services/employees';
import useFindBusiness from 'root/hooks/useFindBusiness';
import useCurrentReport from 'root/hooks/useCurrentReport';
import useCopyUrl from './hooks/useCopyUrl';
import useCurrentActiveMonths from 'root/hooks/useCurrentActiveMonths';
import HistoricalReport from './components/HistoricalReport';
import Reports from 'root/components/Reports';
import Button from 'root/baseComponents/Button';
// style import
import styles from './ImpactReports.module.scss';

export default function ImpactReports({ businessId }) {
	dayjs.extend(customParseFormat);
	dayjs.extend(isSameOrBefore);
	const dispatch = useDispatch();
	const loggedIn = useLoggedIn();

	const params = useParams();
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location.search);

	const period =
		urlSearchParams.get('period') || dayjs().startOf('M').format('YYYY-MM');

	const [reportCopied, setReportCopied] = useState('Copy to clipboard');
	const [voteCopied, setVoteCopied] = useState('Copy to clipboard');
	const [currentPeriod, setCurrentPeriod] = useState(period);
	const currentPeriodDate = dayjs(currentPeriod).format('MMMM YYYY');

	const id = params.businessId ? Number(params.businessId) : businessId;
	const business = useFindBusiness({ id });
	const impactReport = useCurrentReport(currentPeriod);
	const activeMonths = useCurrentActiveMonths(
		period,
		dayjs(period).subtract(12, 'month').format('YYYY-MM')
	);

	const sameKey = params.businessKey
		? params.businessKey === business?.key
			? true
			: false
		: true;

	const previousLoggedIn = get('loggedIn');
	const today = dayjs().format('YYYY-MM-DD');
	const todayNoTime = dayjs(today);

	const voteClosingDate = dayjs(impactReport?.vote_closing_date).format(
		'YYYY-MM-DD'
	);
	const votClosingDateNoTime = dayjs(voteClosingDate);

	if (loggedIn) {
		if (!previousLoggedIn) {
			set('loggedIn', loggedIn);
		}
	}

	const isFulfilled = useMemo(() => {
		return impactReport?.needs.some(need => {
			return need.fulfilled_need_data.length > 0;
		});
	}, [impactReport]);

	const updateEmployeeVote = e => {
		dispatch(updateEmployeeVoteToRealTime());
	};

	const handlePeriodSelect = useCallback(
		e => {
			const selected = dayjs(e.target.value, 'MMMM YYYY').format('YYYY-MM');
			setCurrentPeriod(selected);
		},
		[setCurrentPeriod]
	);

	const getOptions = () => {
		return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(value => {
			if (activeMonths[value]) {
				const date = dayjs(activeMonths[value]).format('MMMM YYYY');
				return <option value={date}>{date}</option>;
			}
		});
	};

	return (
		sameKey && (
			<>
				<Div className="container">
					{/* {previousLoggedIn &&
								todayNoTime.isSameOrBefore(votClosingDateNoTime) && (
									<Container className={styles.updateButton}>
										<Button
											onClick={updateEmployeeVote}
											variant="dark"
											size="md"
											className={cx('mb-2', 'bgSecondary', styles.btnURL)}>
											Update data to real time
										</Button>
									</Container>
								)} */}

					<HistoricalReport business={business} />
				</Div>
				{/* <Reports
					impactReport={impactReport}
					currentPeriodDate={currentPeriodDate}
					handlePeriodSelect={handlePeriodSelect}
					getOptions={getOptions}
					currentPeriod={currentPeriod}
				/> */}
			</>
		)
	);
}
