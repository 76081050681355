import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

import { getClientUrl, deleteCookie } from 'utils/helpers';
import {
	registerBusinessEmployee,
	login,
	getEmployeeDetailsFromKey
} from 'services/user';
import { addNotification } from 'reducers/notifications';

import GoogleAuth from 'root/components/GoogleAuth';
import Notifications from 'components/Notifications/index';

import FormInput from 'root/baseComponents/FormInput';
import TextBox from 'root/baseComponents/TextBox/TextBox';
import Form from 'root/baseComponents/Form';
import Button from 'root/baseComponents/Button/Button';

import { loggedInToVote } from 'root/reducers/isLoggedInToVote';

import { emailValidators, passwordValidators } from './validators';

import styles from './LoginRegisterForm.module.scss';

const LoginRegisterForm = ({
	showVoteLink = false,
	isRegisterPage = true,
	isLoginAsModal = false,
	initialEmail = '',
	businessKey = '',
	setShowLoginModal,
	setShowErrorMessage,
	setEmailFromState
}) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const urlSearchParams = new URLSearchParams(location.search);

	const [email, setEmail] = useState(initialEmail);
	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	const [password, setPassword] = useState('');
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

	const toBeValidatedFields = [
		{
			input_name: 'email',
			validators: emailValidators,
			errorMessageHandler: setEmailErrorMessage
		},

		{
			input_name: 'password',
			validators: passwordValidators,
			errorMessageHandler: setPasswordErrorMessage
		}
	];

	const employeeKey = urlSearchParams.get('employee_key');

	useEffect(() => {
		if (employeeKey) {
			getEmployeeDetailsFromKey(employeeKey)
				.then(data => {
					setEmail(data['email']);
				})
				.catch(setEmail(''));
		}
	}, [employeeKey]);

	useEffect(() => {
		if (showVoteLink) {
			deleteCookie('accessToken');
			deleteCookie('refreshToken');
		}
	}, [showVoteLink]);

	const handleRegister = e => {
		e.preventDefault();

		const clientUrl = getClientUrl();

		registerBusinessEmployee({
			email: email.toLowerCase(),
			password,
			businessKey
		})
			.then(() => {
				dispatch(login(email.toLowerCase(), password, '/'));
				history.push('/');
				ReactGA.event({
					event_name: 'employee_registered',
					category: "Button Click",
					action: "Employee register"
				  });
			})
			.catch(e => {
				if (e?.response?.data?.non_field_errors) {
					let curError = e.response.data.non_field_errors[0];
					if (setShowErrorMessage) {
						setShowErrorMessage(curError);
					}
					if (setShowLoginModal) {
						setShowLoginModal(false);
					}
					if (!setShowErrorMessage) {
						dispatch(
							addNotification({
								type: 'danger',
								message: curError,
								options: {
									dismissable: true
								}
							})
						);
						// addAlertItem(dispatch, curError, 'error');
					}
				} else {
					dispatch(
						addNotification({
							type: 'danger',
							message: 'There was an error while signing up. Please try again',
							options: {
								dismissable: true
							}
						})
						// addAlertItem(
						// 	dispatch,
						// 	'There was an error while signing up. Please try again',
						// 	'error'
						// )
					);
				}
			});
	};

	const from = location?.state?.from || { pathname: '/' };

	const handleLogin = e => {
		e.preventDefault();

		dispatch(login(email.toLowerCase(), password, from)).catch(err => {
			dispatch(
				// addAlertItem(
				// 	dispatch,
				// 	'Oh no, something went wrong.  Double check your email and password.  If you are a new employee, you will need to ask your HR leader to register you for Troop.',
				// 	'error'
				// )
				addNotification({
					type: 'danger',
					message:
						'Oh no, something went wrong.  Double check your email and password.  If you are a new employee, you will need to ask your HR leader to register you for Troop.',
					options: {
						dismissable: true
					}
				})
			);
		});
	};

	useEffect(() => {
		if (isLoginAsModal) {
			dispatch(loggedInToVote());
		}
	}, [isLoginAsModal]);

	useEffect(() => {
		if (isRegisterPage) {
			deleteCookie('accessToken');
			deleteCookie('refreshToken');
			localStorage.clear();
		}
	}, [isRegisterPage]);

	return (
		<>
			<Notifications />
			<Form
				toBeValidatedFields={toBeValidatedFields}
				onSubmit={isRegisterPage ? handleRegister : handleLogin}>
				<TextBox
					labelText="Email"
					type="text"
					val={email}
					setVal={setEmail}
					errorHandler={setEmailErrorMessage}
					name="email"
					errorMessage={emailErrorMessage}
					inputClassName="w-px-100"
				/>
				<TextBox
					labelText="Password"
					type="password"
					val={password}
					setVal={setPassword}
					errorHandler={setPasswordErrorMessage}
					name="password"
					errorMessage={passwordErrorMessage}
					inputClassName="w-px-100"
				/>
				{!isRegisterPage && (
					<div className={cx('textBlack', styles.forgotPassword)}>
						<span
							className={cx(styles.link)}
							onClick={() => history.push('/forgot-password')}>
							Forgot password?
						</span>
					</div>
				)}
				<div className={cx(styles.inputContainer)}>
					<Button type="submit" className={cx(styles.submitBtn)}>
						{isRegisterPage ? 'Register' : 'Login'}
					</Button>
				</div>
			</Form>
			<div className={cx('flex flex--jc--center', styles.googleContainer)}>
				<GoogleAuth />
			</div>
			<div className={cx('flex flex--jc--center', styles.small)}>
				{isRegisterPage ? (
					<>
						{' '}
						Already a user?{' '}
						<span
							className={cx('ml1', styles.highlited)}
							onClick={() => history.push('/login')}>
							LOGIN
						</span>{' '}
					</>
				) : (
					<>
						{' '}
						Need an account?{' '}
						<span
							className={cx('ml1', styles.highlited)}
							onClick={() => history.push('/employee-register')}>
							Signup
						</span>{' '}
					</>
				)}
			</div>
			{showVoteLink ? (
				<div
					className={cx('mouse-hand text-center', styles.minor)}
					onClick={() => {
						console.log(history.location);
						const queryInUrl = history.location.search;
						const voteUrl = `${history.location.pathname.replace(
							'register-to-vote',
							'vote'
						)}${queryInUrl}`;
						history.push(voteUrl);
					}}>
					No thanks, take me to the vote
				</div>
			) : (
				''
			)}
		</>
	);
};

export default LoginRegisterForm;
