import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Icon from 'root/baseComponents/Icon';
import { getBackendUrl } from 'root/utils/helpers';

import { getAllPledges } from 'root/services/needs';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

function Purchases() {
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [reciepts, setReciepts] = useState([]);

	const getAllPledgesData = async () => {
		dispatch(setLoading());
		const res = await getAllPledges();
		dispatch(setLoadingComplete());
		const localReceipts = [];
		console.log(res);
		res.forEach(item => {
			localReceipts.push({
				date: dayjs(item.month_date).format('MMM-YY'),
				business: item.business,
				email: item.business_user_email,
				amount: item.amount,
				short_description: item.business_need_short_description,
				is_active: item.is_active
			});
		});
		setReciepts(localReceipts);
	};

	useEffect(() => {
		getAllPledgesData();
	}, []);

	const headLines = [
		{
			value: 'date',
			display: 'Date',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'business',
			display: 'Business',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'email',
			display: 'Email',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'amount',
			display: 'Amount',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'short_description',
			display: 'Need Description',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'is_active',
			display: 'Active',
			width: 150,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		if (reciepts) {
			const tableData = [];
			reciepts.forEach(item => {
				console.log(item);
				tableData.push({
					date: item.date,
					business: item.business,
					email: item.email,
					amount: item.amount,
					short_description: item.short_description,
					is_active: {
						value: item.is_active,
						display: <Div>{item?.is_active ? 'Yes' : 'No'}</Div>
					}
				});
			});

			return tableData;
		}
	}, [reciepts]);
	return (
		<>
			<Div className="w-per-80 pr2 pl2 flex flex--jc--start ">
				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					tableWidth={'1000px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={10}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
}

export default Purchases;
