import React, { useState, useEffect, useCallback } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Heading, Icon } from 'troop-design';
import { useHistory } from 'react-router-dom';

import { Icon as baseIcon } from 'basedesign-iswad';
import { hideModal, showModal } from 'reducers/modals';
import { get, set, del } from 'utils/storage';
import {
	getPledgedNeedInfo,
	pledgeForNeed,
	clearPledgeNeed,
	pledgeAmount
} from 'root/services/needs';
import { getBusinessUserProfile } from 'root/services/user';
import { createUSerEvent } from 'services/user';
import {
	setToBePledgedNeedAfterCheckout,
	clearToBePledgedNeedAfterCheckout
} from 'root/reducers/needs';

import Modal from 'components/Modal';

import styles from './EmployeePledge.module.scss';

const EmployeePledge = ({ need }) => {
	const history = useHistory();

	const dispatch = useDispatch();

	const business = useSelector(state => state.businesses.businesses)?.[0];
	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [pledgedAmount, setPledgedAmount] = useState(null);
	const [pledgedInfo, setPledgedInfo] = useState(null);
	const [pledgersNum, setPledgersNum] = useState(0);
	const [pledgePercent, setPledgePercent] = useState(0);
	const [allPledgedAmount, setAllPledgedAmount] = useState(0);
	const [errorAmount, setErrorAmount] = useState(false);

	useEffect(() => {
		if (need?.initialPledgedAmount) {
			setPledgedAmount(need.initialPledgedAmount);
		}
	}, [need]);

	const getPledgedInfo = async () => {
		const data = await getPledgedNeedInfo(businessId, need.id);
		setPledgedInfo(data);
		if (Object?.keys(data?.pledger_employees)?.length) {
			setPledgersNum(Object.keys(data.pledger_employees).length);
		}
		setPledgePercent((data?.collected_money / data?.need_value) * 100);
		setAllPledgedAmount(data?.collected_money);
	};

	useEffect(() => {
		if (need?.id && businessId) {
			getPledgedInfo();
			getBusinessUserProfile(dispatch, businessId);
		}
	}, [need, businessId]);

	const handlePledgeClick = useCallback(async () => {
		if (pledgedAmount && parseFloat(pledgedAmount) > 1) {
			createUSerEvent({
				event:
					'Click on pledge button in pledge component (Final step in pledge a need)'
			});
			try {
				const data = {
					business_need_id: need.id,
					amount: pledgedAmount,
					amount_paid_from_saving: pledgedAmount
				};
				const res = await pledgeForNeed(data);
				console.log(res);
				dispatch(pledgeAmount(pledgedAmount));
				dispatch(clearPledgeNeed());
				dispatch(hideModal('pledgeNeed'));
				if (business.has_activated_slack) {
					dispatch(showModal('shareSlack'));
				}
				if (business.has_activated_teams) {
					dispatch(showModal('shareTeams'));
				}
				del('pledged_amount');
				del('pledged_need');
			} catch (err) {
				dispatch(
					setToBePledgedNeedAfterCheckout({
						...need,
						amount_paid_from_saving: myProfile?.saving,
						pledged_amount: pledgedAmount,
						do_good_amount: pledgedAmount - myProfile?.saving
					})
				);
				dispatch(clearPledgeNeed());
				dispatch(hideModal('pledgeNeed'));
				history.push({
					pathname: '/stripe-checkout-page',
					state: { type: 'pledge_need' }
				});
				console.log(err);
			}
		} else {
			setErrorAmount(true);
		}
	}, [pledgedAmount, myProfile, need, dispatch]);

	return (
		<Modal dismissable={false} reduxKey="pledgeNeed" size="md">
			<div className={cx(styles.modalMainContainer)}>
				<div
					className={cx(styles.modalClose)}
					onClick={() => {
						dispatch(clearPledgeNeed());
						dispatch(hideModal('pledgeNeed'));
						del('pledged_amount');
						del('pledged_need');
					}}>
					<div className={cx(styles.modalCloseContainer)}>
						<div>
							<Icon icon="close" width={15} color="black" />
						</div>
					</div>
				</div>
				<div className={styles.modalHeader}>
					<Heading level={2}>Help fulfill this need!</Heading>
				</div>
				<div className={cx(styles.modalBody)}>
					<div className={cx(styles.modalBodyTop)}>
						<div className={cx(styles.modalBodyTopLeft)}>
							<div className={styles.bold}>Contribute what you'd like</div>
							<div className={styles.tiny}>
								pssst... you have{' '}
								<span className={styles.purple}>
									${myProfile?.saving?.toFixed(2)}
								</span>{' '}
								in your do-good account
							</div>
						</div>

						<div className={cx(styles.modalBodyTopRight)}>
							$
							<input
								type="number"
								value={pledgedAmount}
								onChange={e => {
									setErrorAmount(false);
									setPledgedAmount(e.target.value);
								}}
							/>
							{errorAmount && (
								<div className={cx(styles.errorAmount)}>
									Amount must be grater than 1.00
								</div>
							)}
						</div>
					</div>

					<div className={cx(styles.modalBodyMiddle)}>
						<div className={cx(styles.modalBodyMiddleTargetInfo)}>
							<span className={cx(styles.bigBold)}>{`$${parseInt(
								allPledgedAmount
							)?.toFixed(2)}`}</span>{' '}
							of {`$${parseInt(pledgedInfo?.need_value).toFixed(2)}`} target
						</div>
						<div className={cx(styles.modalBodyMiddleBarInfo)}>
							<div className={cx(styles.modalBodyMiddleBarInfoAll)}>
								<div
									className={cx(styles.modalBodyMiddleBarInfoFilled)}
									style={{
										width: `${pledgePercent}%`
									}}></div>
							</div>
						</div>
						<div className={cx(styles.modalBodyMiddlePledgersInfo)}>
							<span className={cx(styles.bigBold)}>{pledgersNum}</span>{' '}
							Teammates pledged
						</div>
					</div>
					<div
						className={cx(
							'flex mb1 ml4 mr3 flex--ai--center flex--jc--center',
							styles.exclamation
						)}>
						<div className="flex flex--jc--center flex--ai--center fs-px-26 mb1 ml1 mr2 textWhite">
							!
						</div>
						<div className={cx('fs-px-12 mb1 ml1 mr1')}>
							If the target for this need is not hit, these funds will go into
							your ‘Personal Funds’ account so you can pledge to fulfill another
							need in the future.
						</div>
					</div>

					<div className={cx(styles.modalBodyBottom)}>
						<Button onClick={handlePledgeClick}>Pledge!</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default EmployeePledge;
