import React from 'react';
import cx from 'classnames';
import { Div, Label } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';

import styles from './CheckBox.module.scss';

const CheckBox = ({
	labelText,
	isRequired,
	direction = 'horizontal',
	hAlign = 'start',
	vAlign = 'center',
	distributedBetween = true,
	checked = false,
	className,
	onBoxClick,
	isRadioButton = false,
	...props
}) => {
	return (
		<>
			<Div
				{...props}
				type="flex"
				direction={direction}
				hAlign={hAlign}
				vAlign={vAlign}
				distributedBetween={distributedBetween}
				className={cx('pos-rel w-per-100', className)}>
				<Div
					type="flex"
					hAlign="center"
					vAlign="center"
					className={cx(
						'bgWhite w-px-20 height-px-20 mouse-hand mr1 br-all-solid-1  br-color-silver ',
						isRadioButton ? 'br-rad-px-20' : 'br-rad-px-5'
					)}
					onClick={onBoxClick}>
					{checked && <Icon type="check" color={'orange'} />}
				</Div>
				{labelText && (
					<Div
						className={cx(
							direction === 'horizontal' && 'mr1',
							direction === 'vertical' && 'mb1'
						)}>
						<Label className={cx(isRequired && 'required')}>{labelText}</Label>
					</Div>
				)}
			</Div>
		</>
	);
};

export default CheckBox;
