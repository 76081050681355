export const nameValidators = [
	{ type: 'required', message: 'Name is required' },
	{
		type: 'minRequired',
		message: ' name must be at least 2 characters',
		minRequired: 2
	},
	{
		type: 'maxRequired',
		message: 'Organization name must be less than 256 characters',
		maxRequired: 256
	}
];

export const subtitleValidators = [
	{ type: 'required', message: 'Subtitle is required' }
];

export const communitySupportedValidators = [
	{ type: 'required', message: 'Community Supported is required' }
];

export const targetValidators = [
	{ type: 'required', message: 'Target is required' }
];

export const needTypeValidators = [
	{ type: 'required', message: 'Need Type is required' }
];

export const shortDescriptionValidators = [
	{ type: 'required', message: 'Short Description is required' }
];

export const neighbourhoodValidators = [
	{ type: 'required', message: 'Neighbourhood is required' }
];
