import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Heading } from 'troop-design';
import RegisterContainer from 'components/RegisterContainer';
import { registerBusinessAdmin } from 'services/user';
import { addNotification } from 'reducers/notifications';
import { useDispatch } from 'react-redux';
import Notifications from 'components/Notifications/index';
import styles from './Register.module.scss';

export default function Register() {
	const dispatch = useDispatch();
	const [registerComplete, setRegisterComplete] = useState(false);

	const handleRegister = e => {
		const { first_name, last_name, email, password } = e;

		registerBusinessAdmin({ first_name, last_name, email, password })
			.then(() => {
				dispatch(
					addNotification({
						type: 'success',
						message:
							'Thank you for signing up. Please check your email for the next steps',
						options: {
							dismissable: false
						}
					})
				);
				setRegisterComplete(true);
			})
			.catch(e => {
				if (e?.response?.data?.non_field_errors) {
					let curError = e.response.data.non_field_errors[0];
					dispatch(
						addNotification({
							type: 'danger',
							message: curError,
							options: {
								dismissable: true
							}
						})
					);
				} else {
					dispatch(
						addNotification({
							type: 'danger',
							message: 'There was an error while signing up. Please try again',
							options: {
								dismissable: true
							}
						})
					);
				}
			});
	};
	return (
		<RegisterContainer>
			<Notifications />
			{!registerComplete && (
				<Form onSubmit={handleRegister} resetOnSubmit={false}>
					<Heading className={styles.sectionHeading} level={2}>
						Sign-in Information
					</Heading>

					<Row className="mb-6">
						<Col md={6}>
							<Input
								label="First Name"
								name="first_name"
								validationRules={[
									{
										rule: 'string.isRequired',
										message: 'Please enter your first name'
									}
								]}
							/>
						</Col>
						<Col md={6}>
							<Input
								label="Last Name"
								name="last_name"
								validationRules={[
									{
										rule: 'string.isRequired',
										message: 'Please enter your last name'
									}
								]}
							/>
						</Col>
						<Input
							label="Email Address"
							name="email"
							validationRules={[
								{
									rule: 'string.isEmail',
									message: 'Please enter a valid email address'
								}
							]}
						/>
						<Col>
							<Input
								label="Password"
								name="password"
								type="password"
								id="password"
								validationRules={[
									{
										function: value =>
											value ===
											document.getElementById('passwordConfirm').value,
										message:
											"The password doesn't match with the confirm password field below."
									},
									{
										rule: 'string.isRequired',
										message: 'Please enter a new password'
									}
								]}
							/>
						</Col>
						<Col>
							<Input
								label="Confirm password"
								name="password2"
								type="password"
								id="passwordConfirm"
								validationRules={[
									{
										function: value =>
											value === document.getElementById('password').value,
										message:
											"The password doesn't match with the password field above."
									},
									{
										rule: 'string.isRequired',
										message: 'Please enter a new password'
									}
								]}
							/>
						</Col>
					</Row>
					<div className={styles.loginButtonContainer}>
						<Button variant="primary" size="lg" className={styles.loginBtn}>
							Register
						</Button>
					</div>
				</Form>
			)}
		</RegisterContainer>
	);
}
