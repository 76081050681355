import React, { useEffect, useState, useCallback } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Heading, Icon } from 'troop-design';

import Modal from 'components/Modal';
// import Button from 'baseComponents/Button';
import { hideModal } from 'reducers/modals';
import { get, set, del } from 'utils/storage';
import { employeeShareSlackPledge, createUSerEvent } from 'services/user';

import styles from './EmployeeShareSlack.module.scss';

const EmployeeShareSlack = () => {
	const dispatch = useDispatch();
	const pledgeAmountRx = useSelector(state => state.needs.pledgeAmount);

	const [amount, setAmount] = useState(null);

	const handleClick = useCallback(async () => {
		await employeeShareSlackPledge({ amount });
		del('pledged_amount');
		del('pledged_need');
		del('do-good-amount');
		dispatch(hideModal('shareSlack'));
		createUSerEvent({ event: 'Click on share to slack button' });
	}, [dispatch, employeeShareSlackPledge, amount]);

	useEffect(() => {
		if (pledgeAmountRx > 0) {
			setAmount(pledgeAmountRx);
		} else {
			setAmount(parseFloat(get('pledged_amount')).toFixed(2));
		}
	}, [pledgeAmountRx]);

	return (
		<Modal dismissable={false} reduxKey="shareSlack" size="md">
			<div className={cx(styles.modalMainContainer)}>
				<div
					className={cx(styles.modalClose)}
					onClick={() => {
						dispatch(hideModal('shareSlack'));
					}}>
					<div className={cx(styles.modalCloseContainer)}>
						<div>
							<Icon icon="close" width={15} color="black" />
						</div>
					</div>
				</div>
				<div className={styles.modalHeader}>
					<Heading level={1}>You're Awesome!</Heading>
				</div>
				<div className={cx(styles.modalBody)}>
					<div className="w-per-60 f-b mb2 text-center">
						Thanks for pledging for this need, we will let you know when the
						target is reached!
					</div>
					<p className="w-per-80 f-b">
						{/* Sharing is Caring! Let us send an anonymous kindness alert through
						Microsoft Teams to let your team know that someone just pledged to
						fulfill a local need! */}
						"🌟 Kindness Alert! someone from our team just pledged to fulfill a
						local need! Let's celebrate their generosity and compassion.
						Together, we can make a positive impact in our community! 🤗"
					</p>
					<Button onClick={handleClick}>
						<Icon icon="slack" size="sm" />
						SHARE ON SLACK!
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default EmployeeShareSlack;
