const columns = [
	// {
	//     Header: (<div>
	//         <input type="checkbox" name="selectBusiness" id="selectAll" value="selectAll" />
	//         <label for="selectAll"></label>
	//     </div>),
	//     accessor: 'checkbox',
	//     filter: false
	// },
	{
		Header: 'Company',
		accessor: 'company',
		filter: 'equals'
	},
	{
		Header: 'Employees',
		accessor: 'employees',
		filter: 'equals'
	},
	{
		Header: 'Activation Date',
		accessor: 'activationDate',
		filter: 'equals'
	},
	{
		Header: 'Need Contribution',
		accessor: 'needContribution',
		filter: 'equals'
	},
	{
		Header: 'Fee',
		accessor: 'troopFee',
		filter: 'equals'
	},
	{
		Header: 'Total',
		accessor: 'total',
		filter: 'equals'
	},
	{
		Header: 'Months Active',
		accessor: 'monthsActive',
		filter: 'equals'
	},
	{
		Header: 'Action',
		accessor: 'action',
		filter: false
	}
];

export { columns };
