import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import FormSelect from 'root/baseComponents/FormSelect';

import styles from '../GeneesProduct.module.scss';

function TopBar({
	filters,
	wishType,
	setWishType,
	location,
	setLocation,
	cause,
	setCause
}) {
	const [wishTypesOptions, setWishTypesOptions] = useState([]);
	const [locationOptions, setLocationOptions] = useState([]);
	const [causesOptions, setCausesOptions] = useState([]);

	// console.log(location, price, wishType);
	useEffect(() => {
		const localWishTypesOptions = [];
		filters?.wish_types?.forEach(item => {
			localWishTypesOptions.push({
				value: item.identifier,
				shownText: item.display_name
			});
		});
		setWishTypesOptions(localWishTypesOptions);

		const localLocationOptions = [];
		filters?.locations?.forEach(item => {
			localLocationOptions.push({
				value: item.identifier,
				shownText: item.display_name
			});
		});
		setLocationOptions(localLocationOptions);

		const localCausesOptions = [];
		filters?.causes?.forEach(item => {
			localCausesOptions.push({
				value: item.identifier,
				shownText: item.display_name
			});
		});
		setCausesOptions(localCausesOptions);
	}, [filters]);

	return (
		<>
			<Div type="flex" distributedBetween vAlign="center">
				<Div type="flex" className="employeeHeading show-flex-in-md-lg">
					Acts of Kindness
				</Div>
				<Div type="flex" vAlign="center">
					<Div className="mr1 show-flex-in-md-lg">Filters:</Div>
					<Div type="flex" className={cx(styles.formSelectContainer)}>
						<Div type="flex" className="w-per-100 mb2 show-flex-in-sm-xsm">
							Acts of Kindness
						</Div>
						{/* <Div className="mr1 show-flex-in-xsm-sm">Filters:</Div> */}
						<Div className="height-px-40">
							<FormSelect
								placeHolder="Wish Type"
								options={wishTypesOptions}
								fullWidth
								openOptionsDownWard={true}
								val={wishType}
								setVal={setWishType}
								className={cx('w-px-200')}
								defaultViewClassName="height-px-30 bgWhite boxShadowType1 mr1 fs-px-12 flex flex--ai--center p1"
								initialSelected="All Types"
							/>
						</Div>
						<Div className="height-px-40">
							<FormSelect
								placeHolder="Location"
								options={locationOptions}
								fullWidth
								openOptionsDownWard={true}
								val={location}
								setVal={setLocation}
								className={cx('w-px-200')}
								defaultViewClassName="height-px-30 bgWhite boxShadowType1 mr1 fs-px-12 flex flex--ai--center p1"
								initialSelected="All Locations"
							/>
						</Div>

						<Div className="height-px-40">
							<FormSelect
								placeHolder="Cause"
								options={causesOptions}
								fullWidth
								openOptionsDownWard={true}
								val={cause}
								setVal={setCause}
								className={cx('w-px-200')}
								defaultViewClassName="height-px-30 bgWhite boxShadowType1 mr1 fs-px-12 flex flex--ai--center p1"
								inputSearchClassName=""
								hasMarginBottom={false}
								initialSelected="All Causes"
							/>
						</Div>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default TopBar;
