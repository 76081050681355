import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Div } from 'basedesign-iswad';
import { useHistory } from 'react-router-dom';

import { addActiveCardToStripeCustomer } from 'root/services/stripe';
// import { addAlertItem } from '@/utils/notifications';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import Button from 'root/baseComponents/Button';
import Form from 'root/baseComponents/Form';
import { showModal } from 'root/reducers/modals';

import { options } from '../utils';
import styles from '../StripeTokenizeCharge.module.scss';

const CheckoutFormDirectAddFund = ({ amount }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();

	const [token, setToken] = useState('');
	const bodyData = {
		token,
		amount,
		type: 'direct_add_fund'
	};
	const [sendTokenReq, setSendTokenReq] = useState(false);
	const sendReqToHandleAddingCard = async () => {
		try {
			const res = await addActiveCardToStripeCustomer(bodyData);
			if (res?.success) {
				history.push({
					pathname: '/',
					state: { comingToViewReceipts: true }
				});
				dispatch(setLoadingComplete());
			} else {
				dispatch(setLoadingComplete());
				console.log(res);
			}
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
		}
	};
	useEffect(() => {
		if (sendTokenReq) {
			sendReqToHandleAddingCard(bodyData);
			setTimeout(() => {
				setSendTokenReq(false);
			}, 10);
		}
	}, [sendTokenReq]);

	const handleSubmit = async event => {
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}
		const card = elements.getElement(CardElement);
		dispatch(setLoading());
		const res = await stripe.createToken(card);
		if (res.error) {
			// addAlertItem(dispatch, res.error.message, 'error');
			console.log(res.error.message);
			dispatch(setLoadingComplete());
		} else {
			if (res?.token?.id) {
				setToken(res.token.id);
			}
		}
	};

	useEffect(() => {
		if (token) {
			setSendTokenReq(true);
		}
	}, [token]);

	return (
		<>
			<Div className="w-per-100">
				<Form onSubmit={handleSubmit}>
					<Div
						className={cx(
							'br-all-solid-1 p2 br-color-grayBright br-rad-px-5 boxShadowType1'
						)}>
						<Div className="p2 bgInfo w-per-100 textWhite fs-px-14 text-center mb2">
							Spending{' '}
							<span className="textYellow">
								${parseFloat(amount)?.toFixed(2)}
							</span>{' '}
							on my community!
						</Div>
						<Div className="br-all-solid-1 p2 br-rad-px-5">
							<CardElement id="card-element" options={options} />
						</Div>
					</Div>
					{stripe && elements ? (
						<Div className="mt2 w-per-100">
							<Button className={cx('w-per-100')}>Submit</Button>
						</Div>
					) : (
						''
					)}
				</Form>
			</Div>
		</>
	);
};

export default CheckoutFormDirectAddFund;
