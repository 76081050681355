import { get, post, put, patch, del } from './http';

export const registerCharityAdmin = data => {
	return post(`/charity_admin_register/`, data);
};

export const updateCharityInfo = data => {
	return post(`/charity_update_info/`, data);
};

export const updateCharityUserInfo = data => {
	return post(`/charity_update_info/`, data);
};

export const getCharityUSerInfo = () => {
	return get(`/charity-user-info/`);
};

export const needRegisteredByCharity = data => {
	return post(`/need_registered_by_charity/`, data);
};

export const updateNeedRegisteredByCharity = (id, data) => {
	return patch(`/need_registered_by_charity/${id}/`, data);
};

export const getNeedsRegisteredByCharity = () => {
	return get(`/need_registered_by_charity/`);
};

export const getNeedInfoRegisteredByCharity = id => {
	return get(`/need_registered_by_charity/${id}/`);
};

export const deleteNeedRegisteredByCharity = id => {
	return del(`/need_registered_by_charity/${id}/`);
};

export const getCharityMembers = () => {
	return get(`/get_charity_users/`);
};

export const addCharityMember = data => {
	return post(`/add_charity_user/`, data);
};

export const updateUserInfo = data => {
	return post(`/charity_user_update_info/`, data);
};

export const getInReviewNeeds = data => {
	return post(`/needs_registered_by_charity_for_troop_admin/`, data);
};

export const getAllCharities = id => {
	if (id) {
		return get(`/get_all_charities/?id=${id}`);
	} else {
		return get(`/get_all_charities/`);
	}
};

export const sendFeedback = data => {
	return post(`/send_feedback_for_need/`, data);
};

export const getFeedback = id => {
	return get(`/send_feedback_for_need/?need_id=${id}`);
};

export const deleteFeedback = id => {
	return del(`/send_feedback_for_need/?impact_id=${id}`);
};

export const buildTaxReceipts = data => {
	return post(`/build-tax-receipts/`, data);
};

export const buildEmployeeTaxReceipts = data => {
	return post(`/build-employee-tax-receipts/`, data);
};

export const retrieveAllTaxReceipts = ({ is_business = false }) => {
	if (!is_business) {
		return get(`/retrieve-all-tax-receipts/`);
	} else {
		return get(`/retrieve-all-tax-receipts?is_business=1`);
	}
};

export const retrieveAllCharities = () => {
	return get(`/retrieve-all-charities/`);
};

export const retrieveAllCharitiesGeneral = () => {
	return get(`/retrieve-all-charities-general/`);
};

export const createNewCharity = data => {
	return post(`/retrieve-all-charities/`, data);
};

export const getSingleCharity = id => {
	return get(`/single-charity/${id}`);
};

export const updateSingleCharity = (id, data) => {
	return put(`/single-charity/${id}`, data);
};

export const deleteSingleCharity = id => {
	return del(`/single-charity/${id}`);
};

export const makeATaxReceiptVoid = data => {
	return post(`/make-a-tax-receipt-void/`, data);
};

export const getCategorizedCharityNeeds = data => {
	return post(`/charity-needs/`, data);
};

export const getSingleNeedInfoForCharityAdmin = needId => {
	return get(`/single_need_info_for_charity_admin/${needId}/`);
};

export const getFeedbackSentForTroopAdmin = charityId => {
	return get(`/feedback_sent_for_troop_admin/${charityId}/`);
};

export const getCharityAdminFromUUID = charityUserUUID => {
	return get(`/charity-admin-registration-viewset/${charityUserUUID}/`);
};

export const registerCharityAdminFromUUID = (charityUserUUID, data) => {
	return post(`/charity-admin-registration-viewset/${charityUserUUID}/`, data);
};

export const registerNewVolunteerCharity = data => {
	return post(`/volunteer-charity-view/`, data);
};

export const sendCharityFeedback = data => {
	return post(`/charity_admin_feedback/`, data);
};
// charity-admin-registration-viewset
// volunteer-charity-view
// charity_admin_feedback

export const getRegisteredCharityAdminWithSignUpForm = () => {
	return get(`/register-charity-admin-with-signup-form/`);
};

export const registerCharityAdminWithSignUpForm = data => {
	return post(`/register-charity-admin-with-signup-form/`, data);
};

export const verifyRegisterCharityAdminWithSignUpForm = id => {
	return put(`/update-charity-admin-with-signup-form/${id}/`);
};

export const deleteRegisterCharityAdminWithSignUpForm = id => {
	return del(`/update-charity-admin-with-signup-form/${id}/`);
};
