import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import queryString from 'query-string';

import { login } from 'root/services/user';
import { setCookie } from 'root/utils/helpers';
import { addNotification } from 'root/reducers/notifications';
import {
	googleAuthGetToken,
	microsoftAuthGetToken,
	googleAuthHandleToken,
	microsoftAuthHandleToken
} from 'root/services/user';

import styles from './SocialAuth.module.scss';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

const SocialAuth = ({ socialAuthUrl, children }) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const params = useParams();
	const history = useHistory();

	const platform = params?.platform;

	const queries = queryString.parse(search);
	const code = queries?.code || false;
	const error = queries?.error || false;
	const errorDescription = queries?.error_description || false;
	const from = location?.state?.from || { pathname: '/' };

	const [accessToken, setAccessToken] = useState('');
	const [tokenId, setTokenId] = useState('');

	useEffect(() => {
		if (error && errorDescription) {
			dispatch(
				addNotification({
					type: 'danger',
					message: errorDescription
				})
			);
			history.push('/login');
		}
	}, [error, errorDescription]);

	useEffect(() => {
		const getAuthToken = async () => {
			try {
				let res;
				if (platform === 'google') {
					res = await googleAuthGetToken({ code });
				} else if (platform === 'microsoft') {
					res = await microsoftAuthGetToken({ code });
				}
				if (res) {
					setAccessToken(res['Authorization']['access_token']);
					if ('id_token' in res['Authorization']) {
						setTokenId(res['Authorization']['id_token']);
					} else {
						setTokenId('No token id');
					}
				}
			} catch (err) {
				console.log(err);
			}
		};
		if ((platform, code)) {
			getAuthToken();
		}
	}, [platform, code]);

	useEffect(() => {
		const handleToken = async () => {
			dispatch(setLoading());
			try {
				let res;
				if (platform === 'google') {
					res = await googleAuthHandleToken({
						access_token: accessToken,
						id_token: tokenId
					});
				} else if (platform === 'microsoft') {
					res = await microsoftAuthHandleToken({
						access_token: accessToken,
						id_token: tokenId
					});
				}
				setCookie('accessToken', res?.accessToken);
				setCookie('refreshToken', res?.refreshToken);
				history.push('/');
				dispatch(setLoadingComplete());
			} catch (err) {
				dispatch(setLoadingComplete());
				dispatch(
					addNotification({
						type: 'danger',
						message: err.response.data.Error
					})
				);
				history.push('/login');
			}
		};
		if (accessToken?.length && tokenId?.length) {
			handleToken();
		}
	}, [accessToken, tokenId, platform]);

	return (
		<>
			<div>
				<a className={cx(styles.anchor)} href={`${socialAuthUrl}`}>
					{children}
				</a>
			</div>
		</>
	);
};

export default SocialAuth;
