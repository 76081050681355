import React, { useState } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import FormSelect from 'root/baseComponents/FormSelect/FormSelect';

import styles from '../Dashboard.module.scss';

const NEED_TYPES = [
	{ value: 'all', shownText: 'All Needs' },
	{ value: 'volunteer', shownText: 'Volunteer Needs' },
	{ value: 'awaiting_feedback', shownText: 'Waiting for Impact' },
	{ value: 'in_review', shownText: 'In Review' },
	{ value: 'active', shownText: 'Under Vote' },
	{ value: 'live', shownText: 'Active' },
	{ value: 'paused', shownText: 'Paused' },
	{ value: 'fulfilled', shownText: 'Fulfilled' }
];

const Filters = ({ needType, setNeedType }) => {
	return (
		<>
			<FormSelect
				className={cx('mb4 w-px-250')}
				labelText=""
				options={NEED_TYPES}
				fullWidth
				openOptionsDownWard={true}
				isRequired
				val={needType}
				initialSelected="All Needs"
				setVal={setNeedType}
				itemsCenteralized={false}
			/>
		</>
	);
};

export default Filters;
