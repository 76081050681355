import React, { useEffect, useState, useMemo } from 'react';
import cx from 'classnames';
import VotePage from 'components/VotePage/VotePage';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { Div } from 'basedesign-iswad';
import {
	getLandingPage,
	hasEmployeeVoted,
	submitCustomerVote
} from 'services/landingPages';
import { getEmployeeDetailsFromKey } from 'services/user';
import { getNeeds } from 'services/needs';
import Error404 from 'root/pages/shared/Error404/Error404';
import { Spinner, Heading, Text } from 'troop-design';
import { get, set, del } from 'utils/storage';
import Modal from 'components/Modal';
import { showModal, hideModal } from 'reducers/modals';
import { addNotification } from 'root/reducers/notifications';
import styles from './VoteLandingPageForCustomers.module.scss';
import {
	setChosenNeed,
	setVotedFromBusiness
} from 'root/reducers/voteCelebration';
import SignUpOrLogin from 'root/components/SignUpOrLogin';
import { notLoggedInToVote } from 'root/reducers/isLoggedInToVote';
import ErrorModal from 'root/components/LoginRegisterForm/subs/ErrorModal';
import Form from 'root/baseComponents/Form';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import FormInput from 'root/baseComponents/FormInput/FormInput';
import Button from 'root/baseComponents/Button/Button';
import VoteCelebration from './subs/VoteCelebration';

import { getOnVotingNeedsPublicPage } from 'root/services/needs';

import { firstNameValidators, emailValidators } from './utils';

export default function VoteLandingPageForCustomers() {
	const [showLoginModal, setShowLoginModal] = useState(false);
	const [showCelebration, setShowCelebration] = useState(false);
	const [votedNeed, setVotedNeed] = useState({});
	const [employeeVoted, setEmployeeVoted] = useState(false);
	const [emailFromState, setEmailFromState] = useState('');
	const [emailFromStateErrorMessage, setEmailFromStateErrorMessage] =
		useState('');
	const [firstNameFromState, setFirstNameFromState] = useState('');
	const [firstNameFromStateErrorMessage, setFirstNameFromStateErrroMessage] =
		useState('');
	const [noData, setNoData] = useState(false);
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const dispatch = useDispatch();
	const params = useParams();
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location.search);
	let employee = '';
	let employeeKey = urlSearchParams.get('employee');
	let read_only = urlSearchParams.get('read_only') === 'true';
	const id = parseInt(params.id, 10);
	const date = params.date;
	const businessNeeds = useSelector(state => state.needs.businessNeeds);
	const needs = useSelector(state => state.needs.needs);
	const business = useSelector(state =>
		state.businesses.businesses.find(
			biz => biz.id?.toString() === id?.toString()
		)
	);
	const sameKey = params.businessKey
		? params.businessKey === business?.key
			? true
			: false
		: true;
	const previousEmployee = get('employee');

	if (emailFromState) {
		employee = emailFromState;
	}

	const [curMonth, setCurMonth] = useState('');
	const [activedBusinessNeeds, setActivedBusinessNeeds] = useState([]);
	const [activeNeeds, setActiveNeeds] = useState([]);
	const [selectedNeed, setSelectedNeed] = useState({});
	const [chosenNeedNeedByUser, setChosenNeedNeedByUser] = useState({});
	const [voteSubmitted, setVoteSubmitted] = useState(false);
	const [voteEndingTime, setVoteEndingTime] = useState('17:00');

	/*
	Turning off the employee saving in the local storage and unsetting it to resolve the bug from Feb 8th.
	if (employee) {
		if (!previousEmployee) {
			set('employee', employee);
		} else if (previousEmployee !== employee) {
			set('employee', employee);
		}
	} else if (!employee && previousEmployee) {
		employee = previousEmployee;
	}
	*/
	del('employee');

	useEffect(() => {
		if (date) {
			setCurMonth(date);
		}
	}, [date]);

	const getOnVotingNeedsPublicPageData = async () => {
		dispatch(setLoading());
		try {
			let res;
			if (curMonth) {
				res = await getOnVotingNeedsPublicPage(id, curMonth);
			} else {
				res = await getOnVotingNeedsPublicPage(id);
			}

			dispatch(setLoadingComplete());
			const localNeeds = [];
			res?.forEach(item => {
				localNeeds.push({ ...item.need, need_id: item.need.id, id: item.id });
			});
			if (res?.length && res?.[0]?.vote_close_time) {
				setVoteEndingTime(res[0].vote_close_time);
			}
			setActivedBusinessNeeds(res);
			setActiveNeeds(localNeeds);
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
		}
	};

	useEffect(() => {
		getOnVotingNeedsPublicPageData();
	}, [curMonth]);

	const landingPage = useMemo(() => {
		return {
			heading: activedBusinessNeeds?.[0]?.vote_page_title,
			bodyText: activedBusinessNeeds?.[0]?.vote_page_description,
			voteOpenedDate: activedBusinessNeeds?.[0]?.vote_opened_date,
			voteClosedDate: activedBusinessNeeds?.[0]?.vote_close_date,
			needs: activeNeeds
		};
	}, [activeNeeds, activedBusinessNeeds, curMonth]);

	useEffect(async () => {
		if (
			employee &&
			id &&
			selectedNeed &&
			chosenNeedNeedByUser &&
			voteSubmitted
		) {
			const data = {
				email: employee.toLowerCase(),
				business_id: id,
				business_need_id: selectedNeed,
				first_name: firstNameFromState
			};
			dispatch(setLoading());
			try {
				const res = await submitCustomerVote(data);
				dispatch(setLoadingComplete());
				setShowCelebration(true);
				setVotedNeed(chosenNeedNeedByUser);
				del('loginToVote');
				dispatch(notLoggedInToVote());
			} catch (err) {
				console.log(err);
				dispatch(setLoadingComplete());
			}
		}
	}, [
		selectedNeed,
		chosenNeedNeedByUser,
		firstNameFromState,
		employee,
		id,
		business,
		voteSubmitted
	]);

	useEffect(() => {
		if (business?.id && !business?.has_employee_feature) {
			setShowLoginModal(false);
		}
	}, [business]);

	useEffect(() => {
		dispatch(getLandingPage(id, date)).then(data => {
			if (Object.keys(data).length === 0) {
				setNoData(true);
			}
		});
		dispatch(getNeeds());
	}, [date, id]);

	useEffect(() => {
		if (!employee) {
			return null;
		}
		hasEmployeeVoted(employee, id)
			.then(data => {
				setEmployeeVoted(data.has_voted);
			})
			.catch(() => {
				setEmployeeVoted(true);
			});
	}, [employee, id]);

	// useEffect(() => {
	// 	if (!employeeKey && !showLoginModal && !showErrorMessage) {
	// 		dispatch(showModal('emailPopup'));
	// 	}
	// }, [employeeKey, showLoginModal, showErrorMessage]);

	useEffect(() => {
		if (employeeKey) {
			getEmployeeDetailsFromKey(employeeKey)
				.then(data => {
					setEmailFromState(data['email']);
				})
				.catch(setEmailFromState(''));
		}
	}, [employeeKey]);

	if (!landingPage) {
		return <Error404 />;
	}

	const handleVote = async (need, chosenNeed) => {
		setSelectedNeed(need);
		setChosenNeedNeedByUser(chosenNeed);
		if (!employeeKey && !showLoginModal && !showErrorMessage) {
			dispatch(showModal('emailPopup'));
		} else {
			setVoteSubmitted(true);
		}
	};

	const handleSubmit = () => {
		dispatch(hideModal('emailPopup'));
		setVoteSubmitted(true);
	};

	// Vote landing page loaded, but no data came back
	if (noData) {
		return <Error404 />;
	}

	// Hasn't loaded the data yet
	if (!businessNeeds || !business) {
		return <Spinner type="bar" width="100%" />;
	}

	const toBeValidatedFields = [
		{
			input_name: 'email',
			validators: emailValidators,
			errorMessageHandler: setEmailFromStateErrorMessage
		},

		{
			input_name: 'first_name',
			validators: firstNameValidators,
			errorMessageHandler: setFirstNameFromStateErrroMessage
		}
	];

	return (
		sameKey && (
			<>
				{showErrorMessage && (
					<ErrorModal
						errorMessage={showErrorMessage}
						setShowErrorMessage={setShowErrorMessage}
					/>
				)}
				{showLoginModal && (
					<>
						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className={cx(
								'pos-fix pos-fix--lt w-per-100 height-vh-full z-10',
								styles.blackBg
							)}
						/>

						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className={cx(
								'pos-abs pos-abs--lt w-per-100 z-100',
								styles.signupModal
							)}>
							<SignUpOrLogin
								isRegisterPage={true}
								showBackground={false}
								isLoginAsModal={true}
								setShowLoginModal={setShowLoginModal}
								initialEmail={emailFromState}
								businessKey={params.businessKey}
								setShowErrorMessage={setShowErrorMessage}
								setEmailFromState={setEmailFromState}
							/>
						</Div>
					</>
				)}
				{business?.has_customers && (
					<Div className={cx(styles.mainContainer)}>
						<VotePage
							bodyText={landingPage.bodyText}
							title={landingPage.heading}
							needs={landingPage.needs}
							voteClosedDate={landingPage.voteClosedDate}
							logo={business?.logo}
							onVote={handleVote}
							hasVoted={false}
							read_only={read_only}
							email={employee?.toLowerCase()}
							employeKey={employeeKey}
							voteEndingTime={voteEndingTime}
						/>
					</Div>
				)}
				{/* <Div type="flex" hAlign="center" vAlign="center" className="fs-px-12">Powered by  <a href='https://hitroop.com' target='_blank' className={cx('textBlack', styles.linkContainer)}>Troop</a></Div> */}
				{!read_only && (
					<Modal dismissable={false} reduxKey="emailPopup" size="md">
						<Heading level={2}>
							{business?.has_employee_feature
								? 'You’re almost done! To register your vote and to make sure everyone gets just one vote, please complete the following details:'
								: 'You’re almost done! To register your vote and to make sure everyone gets just one vote, please complete the following details:'}
						</Heading>
						<Form
							onSubmit={handleSubmit}
							toBeValidatedFields={toBeValidatedFields}>
							<FormInput
								labelText={'Email Address'}
								isRequired
								hasBorder={true}
								type="text"
								value={emailFromState}
								onChange={e => {
									setEmailFromState(e.target.value);
								}}
								name="email"
								errorMessage={emailFromStateErrorMessage}
							/>
							<FormInput
								labelText="First Name"
								hasBorder={true}
								isRequired
								type="text"
								value={firstNameFromState}
								onChange={e => {
									setFirstNameFromState(e.target.value);
								}}
								name="first_name"
								errorMessage={firstNameFromStateErrorMessage}
							/>
							<Text hasWrapper className={styles.tooltip}>
								{business?.has_employee_feature
									? 'P.S. Your details will not be shared or used and your vote selection will remain anonymous.'
									: 'P.S. Your details will not be shared or used and your vote selection will remain anonymous.'}
							</Text>
							<Button variant="primary" className="mb-4">
								Continue
							</Button>
						</Form>
					</Modal>
				)}
				{showCelebration && <VoteCelebration chosenNeed={votedNeed} />}
			</>
		)
	);
}
