import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Div, Card, Column, Row, Image } from 'basedesign-iswad';
import { useHistory } from 'react-router-dom';

import Button from 'root/baseComponents/Button/Button';
import TextBox from 'root/baseComponents/TextBox/TextBox';
import FormTextArea from 'root/baseComponents/FormTextArea/FormTextArea';
import Form from 'root/baseComponents/Form/Form';
import FormSelect from 'root/baseComponents/FormSelect';
import FormDatePicker from 'root/baseComponents/FormDatePicker';

import { addNotification } from 'root/reducers/notifications';

// import NeedCard from 'root/baseComponents/NeedCard';
import FormImagePicker from 'root/baseComponents/FormImagePicker';
import {
	getNeedInfoRegisteredByCharity,
	updateNeedRegisteredByCharity
} from 'root/services/charity';
import styles from '../NeedsToReviewDetails.module.scss';

const NEED_OWNER_CHOICES = [
	{ value: 'organization', shownText: 'Our Organization' },
	{ value: 'individual', shownText: 'An Individual' }
];

function EditNeed() {
	const { id } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();

	const [imageChanged, setImageChanged] = useState('');

	const [needOwner, setNeedOwner] = useState('');
	const [needOwnerErrorMessage, setNeedOwnerErrorMessage] = useState('');

	const [needRecipient, setNeedRecipient] = useState('');
	const [needRecipientErrorMessage, setNeedRecipientErrorMessage] =
		useState('');

	const [monetaryValue, setMonetaryValue] = useState('');
	const [monetaryValueErrorMessage, setMonetaryValueErrorMessage] =
		useState('');

	const [fundRaisingDate, setFundRaisingDate] = useState('');
	const [fundRaisingDateErrorMessage, setFundRaisingDateErrorMessage] =
		useState('');

	const [coverPhoto, setCoverPhoto] = useState('');
	const [coverPhotoErrorMessage, setCoverPhotoErrorMessage] = useState('');

	const [initialSrc, setInitialSrc] = useState('');

	const [needTitle, setNeedTitle] = useState('');
	const [needTitleErrorMessage, setNeedTitleErrorMessage] = useState('');

	const [message, setMessage] = useState('');
	const [messageErrorMessage, setMessageErrorMessage] = useState('');

	const [details, setDetails] = useState('');
	const [detailsErrorMessage, setDetailsErrorMessage] = useState('');

	const toBeValidatedFields = [];

	const customValidations = () => {
		let validated = true;

		if (!needOwner) {
			setNeedOwnerErrorMessage('Need Owner is required');
			validated = false;
		}

		if (!message) {
			setMessageErrorMessage('You must send a message to us!');
			validated = false;
		}

		return validated;
	};

	const getNeedDetails = async () => {
		const res = await getNeedInfoRegisteredByCharity(id);
		console.log(res);
		if (res) {
			setNeedOwner(res?.need_owner);
			setNeedRecipient(res?.need_recipient);
			setNeedTitle(res?.title);
			setMonetaryValue(res?.monetary_value);
			if (res?.fundraising_date) {
				setFundRaisingDate(new Date(dayjs(res?.fundraising_date)));
			}
			setMessage(res?.message);
			setDetails(res?.other_details);
			if (res?.cover_photo) {
				setCoverPhoto(res?.cover_photo);
				setInitialSrc(res?.cover_photo);
			}
		}
	};

	useEffect(() => {
		getNeedDetails();
	}, [id]);

	const submitHandler = async e => {
		e.preventDefault();
		if (id) {
			try {
				const fd = new Date(fundRaisingDate);
				let year = fd.toLocaleString('default', { year: 'numeric' });
				let month = fd.toLocaleString('default', { month: '2-digit' });
				let day = fd.toLocaleString('default', { day: '2-digit' });
				let formattedDate = year + '-' + month + '-' + day;
				const formData = new FormData();
				formData.append('need_owner', needOwner);
				formData.append('need_recipient', needRecipient);
				formData.append('monetary_value', monetaryValue);
				if (fundRaisingDate) {
					formData.append(
						'fundraising_date',
						dayjs(fundRaisingDate).format('YYYY-MM-DD')
					);
				}
				if (imageChanged) {
					formData.append('cover_photo', coverPhoto);
				}
				formData.append('title', needTitle);
				formData.append('message', message);
				formData.append('other_details', details);
				const res = await updateNeedRegisteredByCharity(id, formData);
				history.push('/needs-to-review');
				dispatch(
					addNotification({
						type: 'success',
						message: `Need information has been updated successfully!`
					})
				);
			} catch (err) {
				console.log(err);
				dispatch(
					addNotification({
						type: 'danger',
						message: `Something went wrong, please try again!`
					})
				);
			}
		}
	};

	useEffect(() => {
		if (coverPhoto !== initialSrc) {
			setImageChanged(true);
		} else {
			setImageChanged(false);
		}
	}, [coverPhoto, initialSrc]);

	return (
		<>
			<Div className={cx(styles.main2)}>
				<Div className="employeeHeader mb2">Need Information</Div>
				<Card className={cx('card br-rad-px-5 pt3 pl8 pr8 pb3', styles.card)}>
					<Form
						toBeValidatedFields={toBeValidatedFields}
						className={cx(styles.form)}
						onSubmit={submitHandler}>
						<FormSelect
							labelText="Who is this need for"
							className={cx('mb4 w-px-300')}
							options={NEED_OWNER_CHOICES}
							fullWidth
							openOptionsDownWard={true}
							initialSelected={needOwner}
							isRequired
							val={needOwner}
							setVal={setNeedOwner}
							errorMessage={needOwnerErrorMessage}
							errorHandler={setNeedOwnerErrorMessage}
						/>
						{needOwner === 'individual' && (
							<TextBox
								labelText="Need recipient (this could be an alias, anything to help keep track if you have needs for multiple people)"
								type="text"
								val={needRecipient}
								setVal={setNeedRecipient}
								errorHandler={setNeedRecipientErrorMessage}
								name="need_recipient"
								errorMessage={needRecipientErrorMessage}
							/>
						)}

						<TextBox
							labelText="Monetary value of need request?"
							type="text"
							val={monetaryValue}
							setVal={setMonetaryValue}
							errorHandler={setMonetaryValueErrorMessage}
							name="monetary_value"
							errorMessage={monetaryValueErrorMessage}
							inputClassName="max-w-px-100"
							hasIcon={true}
							iconType="dollar"
							iconColor="gray"
						/>

						<TextBox
							labelText="Give your need a title"
							type="text"
							val={needTitle}
							setVal={setNeedTitle}
							errorHandler={setNeedTitleErrorMessage}
							name="need_title"
							errorMessage={needTitleErrorMessage}
							placeholder="e.g. School Supplies for New Canadians"
						/>

						<FormDatePicker
							labelText="When is it needed by? If there is no deadline, leave it blank."
							chosenDate={fundRaisingDate}
							setChosenDate={setFundRaisingDate}
							errorMessage={fundRaisingDateErrorMessage}
							errorHandler={setFundRaisingDateErrorMessage}
							inputClassName={'max-w-px-200'}
							placeHolder="DD-MM-YYYY"
						/>

						<FormImagePicker
							labelText="Add cover photo (optional)"
							file={coverPhoto}
							setFile={setCoverPhoto}
							errorMessage={coverPhotoErrorMessage}
							errorHandler={setCoverPhotoErrorMessage}
							id="coverPhotoCharityRegistrationPage"
							hasCropper={true}
							initialSrc={initialSrc}
							cropInfo={{ aspect: 1.5 }}
						/>

						<FormTextArea
							isRequired
							labelText="Message"
							val={message}
							setVal={setMessage}
							errorMessage={messageErrorMessage}
							errorHandler={setMessageErrorMessage}
							id="messageFieldHomePage"
						/>

						<FormTextArea
							isRequired
							labelText="Other details"
							val={details}
							setVal={setDetails}
							errorMessage={detailsErrorMessage}
							errorHandler={setDetailsErrorMessage}
							id="messageFieldHomePage"
						/>

						<div className={cx('my2 flex flex--jc--end')}>
							<Button type="submit">Submit</Button>
						</div>
					</Form>
				</Card>
			</Div>
		</>
	);
}
export default EditNeed;
