export const DASHBOARD_ITEMS = [
	{
		title: 'Dashboard',
		icon: 'dashboard-fill',
		to: '/',
		user: 'charity'
	},
	{ title: 'Needs', icon: 'heart-fill', to: '/charity-needs', user: 'charity' },
	{
		title: 'Account',
		icon: 'gear',
		to: '/organisation-account',
		user: 'charity'
	},
	{
		title: 'Team',
		icon: 'users',
		to: '/charity-team',
		user: 'charity'
	},
	{
		title: 'Your Feedback',
		icon: 'pencil',
		to: '/charity-admin-feedback',
		user: 'charity'
	}
];
