const localStorage = window.localStorage;

export const get = key => {
	return localStorage.getItem(key);
};

export const set = (key, value) => {
	localStorage.setItem(key, value);
};

export const del = key => {
	localStorage.removeItem(key);
};
