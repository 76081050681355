import React from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Div, Row, Column, Heading } from 'basedesign-iswad';

import EventForm from 'root/components/EventForm';
import Button from 'root/baseComponents/Button';

import styles from './EventSignup.module.scss';

function EventSignup() {
	const dispatch = useDispatch();

	const myProfile = useSelector(state => state.users.businessUserProfile);

	return (
		<>
			<Div className="card bgWhite br-all-hidden-1 br-rad-px-5 pb3">
				<EventForm />
			</Div>
		</>
	);
}

export default EventSignup;
