import React from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Div, Row, Column, Heading } from 'basedesign-iswad';
import { Card } from 'troop-design';

import Button from 'root/baseComponents/Button';
import { showModal } from 'reducers/modals';
import AddFundModal from './subs/AddFundModal';

import { default as PledgeHistoryComp } from 'root/components/PledgeHistory';

import styles from './PledgeHistory.module.scss';

function PledgeHistory() {
	const dispatch = useDispatch();

	const myProfile = useSelector(state => state.users.businessUserProfile);

	return (
		<>
			<Row className={cx(styles.main)}>
				<Column xs={12} sm={12} md={12} lg={3} className="">
					<Div type="flex" direction="vertical" vAlign="start" className="p1">
						<div className="employeeHeading">Personal Pledging</div>
						<Card
							className={cx(
								'flex flex--dir--col flex--jc--center flex--ai--center',
								styles.card
							)}>
							<Div
								type="flex"
								direction="vertical"
								hAlign="center"
								vAlign="center"
								className="">
								<Div className={cx(styles.underline)}>Pledging Balance</Div>
								<Div>
									<Card className={cx(styles.innerCard)}>
										${(myProfile?.saving || 0)?.toFixed(2) || 0}
									</Card>
								</Div>
								<Div className="w-per-100">
									<Button
										className="w-per-100"
										onClick={() => dispatch(showModal('addFundPopup'))}>
										Add Funds
									</Button>
								</Div>
							</Div>
						</Card>
					</Div>
				</Column>
				<Column xs={12} sm={12} md={12} lg={9} className="">
					<Div className="p1">
						<PledgeHistoryComp />
					</Div>
				</Column>
			</Row>
			<AddFundModal />
		</>
	);
}

export default PledgeHistory;
