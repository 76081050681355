import { COLORS } from 'root/constants/vars';
import { loadStripe } from '@stripe/stripe-js';
import { getStripePublishableApiKey } from 'utils/helpers';

const STRIPE_PUBLISHABLE_API_KEY = getStripePublishableApiKey();
export const stripePromise = loadStripe(STRIPE_PUBLISHABLE_API_KEY);

export const options = {
	style: {
		base: {
			color: 'black',
			iconColor: COLORS.grayDark,
			backgroundColor: 'white',
			fontFamily: 'Roboto, sans-serif',
			fontSmoothing: 'antialiased',
			fontSize: '16px',
			'::placeholder': {
				color: COLORS.grayDark
			}
		},
		invalid: {
			color: COLORS.danger,
			iconColor: COLORS.danger
		}
	}
};
