import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Row, Col, Heading, Anchor, Text } from 'troop-design';
import MediaForm from './components/MediaForm';
import Modal from 'components/Modal';
import { hideModal } from 'reducers/modals';
import { getNeeds } from 'services/needs';
import { getBusinesses } from 'services/businesses';
import styles from './FulfilledNeeds.module.scss';
import { Form, Select } from 'troop-design';
import { excludes } from './constants';

export default function FulfilledNeeds() {
	const dispatch = useDispatch();
	const needs = useSelector(state => state.needs.needs);
	const businessNeeds = useSelector(state => state.needs.businessNeeds);
	const businesses = useSelector(state => state.businesses.businesses);
	const [businessId, setBusinessId] = useState('all');
	const [selectedNeeds, setSelectedNeeds] = useState([]);

	useEffect(() => {
		dispatch(getNeeds());
		dispatch(getBusinesses());
	}, [dispatch]);

	useEffect(() => {
		if (!excludes.includes(businessId)) {
			dispatch(getNeeds(Number(businessId)));
			setSelectedNeeds(needs);
		} else {
			setSelectedNeeds(needs);
		}
	}, [businessId]);

	useEffect(() => {
		const arrayOfIds = [];
		const monthDates = {};
		const isWinnerInCurentBusiness = {};
		if (!excludes.includes(businessId)) {
			businessNeeds.forEach(businessNeed => {
				arrayOfIds.push(businessNeed.need_id);
				monthDates[businessNeed.need] = businessNeed.monthDate;
				isWinnerInCurentBusiness[businessNeed.need_id] =
					businessNeed.is_vote_winner;
			});
		} else {
			needs.forEach(need => {
				arrayOfIds.push(need.id);
			});
		}
		const previousMonth = dayjs().subtract(1, 'month');
		const filteredSelectedNeeds = needs.filter(need => {
			if (!excludes.includes(businessId)) {
				return (
					arrayOfIds.includes(need.id) &&
					isWinnerInCurentBusiness[need.id] &&
					!need.is_fulfilled &&
					dayjs(monthDates[need.id]).isAfter(previousMonth)
				);
			} else {
				return arrayOfIds.includes(need.id);
			}
		});
		setSelectedNeeds(filteredSelectedNeeds);
	}, [businessNeeds]);

	return (
		<>
			<Row>
				<Col xs={12}>
					<Heading level={1} className="mt-0">
						Manage Fulfilled Needs
					</Heading>
				</Col>
				<Col xs={12}>
					<div>
						<Form>
							<Select
								onChange={e => setBusinessId(e.target.value)}
								name={`mediaType-`}
								value={businessId}
								label="Business"
								valiationRules={[
									{
										rule: 'option.atLeastOne',
										message: 'Please select an option'
									}
								]}>
								<option value={'all'}>All</option>
								{businesses.map(business => (
									<option key={business.id} value={business.id}>
										{business.name}
									</option>
								))}
								<option value={'none'}>None</option>
							</Select>
						</Form>
					</div>
				</Col>
				{selectedNeeds.map((need, index) => {
					return (need.is_vote_winner && !need.is_fulfilled) ||
						businessId === 'none' ? (
						<Col key={index} xs={6} className="d-flex ju">
							<Card
								className="w-100"
								variant="data"
								heading={need.charity_name}>
								<div className={styles.cardContainer}>
									<div className={styles.needLink}>
										<Anchor
											className={styles.needLink}
											to={need.need_website}
											target="_blank">
											<Heading level={2} className={styles.needHeading}>
												{need.name}
											</Heading>
										</Anchor>
									</div>
									<MediaForm key={index} id={need.id} />
									<div className="flex flex--jc--end mt2 textGray">
										ID: {need.id}
									</div>
								</div>
							</Card>
						</Col>
					) : null;
				})}
			</Row>
			<Modal reduxKey="errorSubmit" size="md">
				<Heading level={2}>Error</Heading>
				<Text tag="p" hasWrapper>
					{`There was an error submitting this form data, please try again.`}
				</Text>
				<Button
					variant="primary"
					outline
					onClick={() => dispatch(hideModal('errorSubmit'))}>
					Dismiss
				</Button>
			</Modal>
			<Modal reduxKey="successSubmit" size="md">
				<Heading level={2}>Success</Heading>
				<Text tag="p" hasWrapper>
					{`Data was successfully submitted.`}
				</Text>
				<Button
					variant="primary"
					outline
					onClick={() => dispatch(hideModal('successSubmit'))}>
					Dismiss
				</Button>
			</Modal>
		</>
	);
}
