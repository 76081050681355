import React from 'react';
import cx from 'classnames';
import { Div, Heading } from 'basedesign-iswad';

import styles from '../Needs.module.scss';

import LandingPhoto from 'root/assets/needsLanding2.png';
import MobileLandingPhotp from 'root/assets/MobileNeedsLanding.png';

const Landing = () => {
	return (
		<>
			<Div className={cx('w-per-100 pos-rel of-hidden', styles.landing)}>
				<Div showIn={['md', 'lg']} className="">
					<img
						src={LandingPhoto}
						className={cx('w-per-100', styles.landingImage)}
					/>{' '}
				</Div>
				<Div showIn={['xs', 'sm']}>
					<img
						src={MobileLandingPhotp}
						className={cx('w-per-100', styles.landingMobilePhoto)}
					/>{' '}
				</Div>
				<Div
					type="flex"
					hAlign="center"
					// vAlign="start"
					direction="vertical"
					className={cx(
						'pos-abs pos-abs--lt w-per-100 textWhite',
						styles.landingContentContainer
					)}>
					<Div
						className={cx('f-b text-center mb1 pl2 pr2', styles.landingHeader)}>
						Local needs to be fulfilled
					</Div>
					<Div className={cx('text-center', styles.landingSubtitle)}>
						Read the stories behind our latest needs from local charities
					</Div>
				</Div>
			</Div>
		</>
	);
};

export default Landing;
