import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';

import { Row } from 'troop-design';

import styles from './AddNewEmployee.module.scss';

import useFindBusiness from 'root/hooks/useFindBusiness';
import { deleteCookie } from 'utils/helpers';
import AddEmployeeForm from './components/AddEmployeeForm';
import Prompt from './components/Prompt';

export default function AddNewEmployee() {
	deleteCookie('accessToken');
	deleteCookie('refreshToken');
	const history = useHistory();
	const [hasTrueUrlKey, setHasTrueUrlKey] = useState(false);
	const params = useParams();
	const id = Number(params.id);
	const urlKey = params.businessKey;
	const business = useFindBusiness({ id });

	const businessName = useMemo(() => {
		return business?.name || false;
	}, [business]);

	const businessKey = useMemo(() => {
		return business?.key || false;
	}, [business]);

	const businessLogo = useMemo(() => {
		return business?.logo || false;
	}, [business]);

	useEffect(() => {
		if (urlKey && businessKey) {
			urlKey === businessKey ? setHasTrueUrlKey(true) : history.push('/404');
		}
	}, [businessKey, urlKey]);

	const [email, setEmail] = useState('');

	return (
		<div className={styles.container}>
			{hasTrueUrlKey ? (
				<>
					<Row>
						<div className={styles.logoContainer}>
							<img className={styles.logoImg} src={businessLogo} />
						</div>
					</Row>
					<Row>
						<h1 className={styles.title}>
							{businessName} has teamed up with Troop to do some good! 🎉
						</h1>
					</Row>
					<Row>
						<p className={styles.incentive}>
							Sign up to join the {businessName} team and support your local
							community! We just need a few details to get you started.
						</p>
					</Row>

					<AddEmployeeForm businessId={id} setEmail={setEmail} />

					<Row className={styles.info}>
						<h1 className={styles.question}>Who's Troop?</h1>
						<p className={styles.paragraph}>
							Troop is a social enterprise in Toronto on a mission to build
							communities where basic needs don’t go unmet. Through partnerships
							with charities and non-profits, Troop is using technology to
							engage businesses and neighbours in lending a helping hand,
							starting with the fulfillment of local, everyday needs.
						</p>
					</Row>
					<Row className={styles.info}>
						<h1 className={styles.question}>How it works?</h1>
						<ol className={styles.list}>
							<li className={styles.listItem}>
								Each month you’ll get an email with a link to a form
								highlighting a few local needs. You get one vote to pick the
								need you want to see fulfilled.
							</li>
							<li className={styles.listItem}>
								Once everyone has had a say, Troop will fulfill the top voted
								need and provide feedback on how it went.
							</li>
							<li className={styles.listItem}>
								At the end of the month, you’ll get a report highlighting your
								impact. Then we start all over again, doing more good next
								month.
							</li>
						</ol>
					</Row>
					<Prompt businessName={businessName} email={email} />
				</>
			) : (
				<p>This link has not been found!</p>
			)}
		</div>
	);
}
