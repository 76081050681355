import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import moment from 'moment-timezone';
import cx from 'classnames';
import {
	Image,
	Heading,
	Text,
	Container,
	Card,
	Row,
	Col,
	Form,
	Select,
	Icon,
	Alert,
	Anchor
} from 'troop-design';
import { Div } from 'basedesign-iswad';

import Notifications from 'components/Notifications/index';
import styles from './VotePage.module.scss';
import ThanksAfterVote from 'components/ThanksAfterVote';
import { getClientUrl } from 'root/utils/helpers';

import Button from 'root/baseComponents/Button';

moment.tz.setDefault('America/New_York');

export default function VotePage({
	logo,
	title,
	bodyText,
	needs,
	onVote,
	hasVoted,
	voteClosedDate,
	read_only,
	email,
	employeeKey,
	voteEndingTime
}) {
	const [selectedNeed, setSelectedNeed] = useState(0);
	const [expanded, setExpanded] = useState(false);
	const excerptLength = 100;
	const hasExpired = voteClosedDate
		? moment(`${voteClosedDate} ${voteEndingTime}`).isBefore(moment())
		: false;

	const handleSelect = needId => {
		if (hasExpired) {
			return;
		}
		if (selectedNeed === needId) {
			setSelectedNeed(0);
		} else {
			document
				?.getElementById('submitContainer')
				?.scrollIntoView({ behavior: 'smooth' });
			setSelectedNeed(needId);
		}
	};

	const renderNeed = need => {
		const selected = selectedNeed === need.id;
		const hasSelectedNeed = selectedNeed !== 0;
		const trimmedContent =
			need.business_description?.length > excerptLength
				? need.business_description?.substring(0, excerptLength) + '...'
				: need.business_description;
		return (
			<Col
				xs={12}
				md={6}
				lg={4}
				className={cx(
					styles.needCardWrapper,
					selected && hasSelectedNeed && styles.selected,
					!selected && hasSelectedNeed && styles.notSelected
				)}
				key={need.id}>
				<Card
					className={cx(styles.needCard)}
					contentClassName={styles.contents}>
					<Div
						type="flex"
						direction="vertical"
						distributedBetween
						className="flex--gr--1">
						<Div>
							<div
								className={styles.imageContainer}
								onClick={() => handleSelect(need.id)}>
								<Image
									className={cx(styles.needImage)}
									src={need.image}
									alt=""
								/>
							</div>
							<Heading level={2} className={cx(styles.needTitle)}>
								{need.name}
							</Heading>
							<Heading
								level={3}
								className={cx('mb1 textFaded', styles.charityName)}>
								{need.charity_name}
								<Anchor
									to={`https://hitroop.com/needs/${need.charity_slug}/tr-${
										need.need_id
									}`}
									target="_blank"
									className={styles.needLink}>
									<Icon icon="link" size="sm" />
								</Anchor>
							</Heading>
							<div
								style={{
									background: 'silver',
									height: '2px',
									width: '50px'
								}}
							/>
							<Div className="mt1 fs-px-14">
								{expanded ? need.business_description : trimmedContent}
								<div
									className={cx('mouse-hand ml1', styles.showMoreLink)}
									onClick={() => setExpanded(!expanded)}>
									<span className="textPrimary text-underline">
										Show {expanded ? 'less>' : 'more>'}
									</span>
								</div>
							</Div>
						</Div>
						{!hasExpired && !read_only ? (
							<Div
								type="flex"
								hAlign="start"
								vAlign="center"
								direction="vertical"
								className="w-per-100">
								{selectedNeed !== need.id ? (
									<Button
										className="w-px-150 mt1"
										onClick={() => handleSelect(need.id)}>
										Vote!
									</Button>
								) : (
									<Div type="flex">
										<Button onClick={handleVote} className="w-per-50 mr1 mt1">
											Submit Vote
										</Button>
										<Button
											className="w-per-50 mt1"
											onClick={() => setSelectedNeed(0)}
											colorType="transparent">
											Cancel
										</Button>
									</Div>
								)}
							</Div>
						) : (
							''
						)}
					</Div>
				</Card>
			</Col>
		);
	};

	const handleVote = e => {
		if (!selectedNeed) {
			alert('Please make a selection first');
			return;
		}
		const chosenNeed = needs.find(need => need.id === selectedNeed);
		onVote(selectedNeed, chosenNeed);
	};

	const alreadyVotedCard = (
		<ThanksAfterVote employeeKey={employeeKey} email={email} />
		// <div>Hello</div>
	);

	return (
		<div className={styles.wrapper}>
			{!hasVoted ? (
				<header className={cx(styles.header)}>
					<Image src={logo} alt="Logo" className={cx(styles.companyLogo)} />
					<Heading level={1} className={cx('pl2 pr2', styles.companySubTitle)}>
						{title}
					</Heading>
					<Div className="px1 fs-px-14">{bodyText}</Div>
					{/* <Div className='px1 fs-px-14'> Swift is actively engaging with the community and wants to involve you in their social good program. Choose the cause that resonate with you the most. </Div>
					<Div className='px1 fs-px-14'>After the voting period ends and the selected cause is fulfilled, Swift will reach out to share impact stories from the chosen organizations. </Div> */}
				</header>
			) : (
				''
			)}
			{hasVoted && !read_only ? (
				alreadyVotedCard
			) : (
				<div className={cx('container', styles.container)}>
					<Container className={cx(styles.needs)} tag="div">
						{/* <Text hasWrapper className={cx(styles.companyBodyText)}>
							{bodyText}
						</Text> */}
						<Row>{needs.map((need, index) => renderNeed(need))}</Row>
					</Container>
					{hasExpired ? (
						<Alert
							dismissable={false}
							className={styles.votePeriodEnded}
							type="danger">
							This vote is closed, we will be in touch with your impact update.
						</Alert>
					) : (
						<Div
							className={cx(
								styles.voteSubmissionContainer,
								selectedNeed && styles.selectionMade
							)}>
							{read_only && hasVoted ? (
								<Alert
									dismissable={false}
									className={styles.votePeriodEnded}
									type="success">
									Thank you for casting your vote this month!
								</Alert>
							) : (
								''
							)}
							<Notifications />
							{!read_only && (
								<>
									{/* <Form>
										<select
											label="I want to fulfill this need: "
											name="selectedNeed"
											value={selectedNeed}
											onChange={e =>
												setSelectedNeed(parseInt(e.target.value, 10))
											}
											type="select"
											className={styles.select}>
											<option value={0}>Please pick a need</option>
											{needs.map((need, idx) => (
												<option key={idx} value={need.id}>
													{need.name}
												</option>
											))}
										</select>
									</Form>
									<Button
										id="submitContainer"
										size="md"
										variant="success"
										onClick={handleVote}
										className="d-flex align-items-center">
										<Icon
											icon="checkmark"
											color="white"
											size="md"
											className="me-2"
										/>
										Submit selected vote
									</Button> */}
								</>
							)}
						</Div>
					)}
				</div>
			)}
		</div>
	);
}

VotePage.propTypes = {
	title: PropTypes.string,
	logo: PropTypes.string,
	needs: PropTypes.array,
	bodyText: PropTypes.string,
	onVote: PropTypes.func,
	hasVoted: PropTypes.bool
};

VotePage.defaultProps = {
	onVote: () => console.log('Submission handler'),
	hasVoted: false
};
