import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';
import Form from 'root/baseComponents/Form';
import TextBox from 'root/baseComponents/TextBox';
import FormTextArea from 'root/baseComponents/FormTextArea';
import FormSelect from 'root/baseComponents/FormSelect';
import FormDatePicker from 'root/baseComponents/FormDatePicker';
import FormImagePicker from 'root/baseComponents/FormImagePicker';
import FormCheckBox from 'root/baseComponents/FormCheckBox';

import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { setModalType } from 'root/reducers/charityReducers/modalType';
import { setModalProps } from 'root/reducers/charityReducers/modalProps';
import { updateSingleCharity } from 'root/services/charity';

import { PROVINCE_CHOICES, ORGANIZATION_TYPE_CHOICES } from '../constants';
import {
	nameValidators,
	addressValidators,
	cityValidators,
	postalCodeValidators
} from '../validators';
import styles from '../CharityEdit.module.scss';

function CharityEditForm({ charity }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const [name, setName] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');

	const [registeredNumber, setRegisteredNumber] = useState('');
	const [registeredNumberErrorMessage, setRegisteredNumberErrorMessage] =
		useState('');

	const [registeredName, setRegisteredName] = useState('');
	const [registeredNameErrorMessage, setRegisteredNameErrorMessage] =
		useState('');

	const [website, setWebsite] = useState('');
	const [websiteErrorMessage, setWebsiteErrorMessage] = useState('');

	const [address, setAddress] = useState('');
	const [addressErrorMessage, setAddressErrorMessage] = useState('');

	const [country, setCountry] = useState('');
	const [countryErrorMessage, setCountryErrorMessage] = useState('');

	const [city, setCity] = useState('');
	const [cityErrorMessage, setCityErrorMessage] = useState('');

	const [province, setProvince] = useState('');
	const [provinceErrorMessage, setProvinceErrorMessage] = useState('');

	const [postalCode, setPostalCode] = useState('');
	const [postalCodeErrorMessage, setPostalCodeErrorMessage] = useState('');

	const [charityType, setCharityType] = useState('');
	const [charityTypeErrorMessage, setCharityTypeErrorMessage] = useState('');

	const [schoolDistrict, setSchoolDistrict] = useState('');
	const [schoolDistrictErrorMessage, setSchoolDistrictErrorMessage] =
		useState('');

	const [aboutOrg, setAboutOrg] = useState('');
	const [aboutOrgErrorMessage, setAboutOrgErrorMessage] = useState('');

	const [coverPhoto, setCoverPhoto] = useState('');
	const [coverPhotoErrorMessage, setCoverPhotoErrorMessage] = useState('');

	const [initialSrc, setInitialSrc] = useState('');
	const [imageChanged, setImageChanged] = useState(false);

	const toBeValidatedFields = [
		{
			input_name: 'name',
			validators: nameValidators,
			errorMessageHandler: setNameErrorMessage
		},
		{
			input_name: 'city',
			validators: cityValidators,
			errorMessageHandler: setCityErrorMessage
		},
		{
			input_name: 'address',
			validators: addressValidators,
			errorMessageHandler: setAddressErrorMessage
		},
		{
			input_name: 'postal_code',
			validators: postalCodeValidators,
			errorMessageHandler: setPostalCodeErrorMessage
		}
	];

	const customValidations = () => {
		let validated = true;

		if (!province) {
			validated = false;
			setCoverPhotoErrorMessage('Province is required');
		}

		if (!charityType) {
			validated = false;
			setCoverPhotoErrorMessage('Charity Type is required');
		}

		return validated;
	};

	const submitHandler = async e => {
		if (customValidations()) {
			const formData = new FormData();
			formData.append('name', name);
			formData.append('registered_name', registeredName);
			formData.append('registered_charity_number', registeredNumber);
			formData.append('about_organization', aboutOrg);
			formData.append('website', website);
			formData.append('address', address);
			formData.append('city', city);
			formData.append('postal_code', postalCode);
			formData.append('province', province);
			formData.append('country', country);
			formData.append('school_district', schoolDistrict);
			formData.append('charity_type', charityType);
			console.log(charityType);
			if (imageChanged) {
				formData.append('logo', coverPhoto);
			}
			dispatch(setLoading());
			try {
				const res = await updateSingleCharity(charity?.id, formData);
				if (res?.id) {
					dispatch(setModalType('success'));
					dispatch(
						setModalProps({
							text: `Charity has been submitted successfully updated`,
							header: 'Need published!'
						})
					);
					history.push('/');
					dispatch(setLoadingComplete());
				} else {
					dispatch(setModalType('danger'));
					dispatch(
						setModalProps({
							text: `${res.message}`,
							header: 'Error!'
						})
					);
					dispatch(setLoadingComplete());
				}
			} catch (err) {
				dispatch(setLoadingComplete());
				dispatch(setModalType('danger'));
				dispatch(
					setModalProps({
						text: `${err}`,
						header: 'Error!'
					})
				);
				console.log(err);
			}
		}
	};

	useEffect(() => {
		if (charity?.id) {
			setName(charity?.name);
			setAddress(charity?.address);
			setCharityType(charity?.charity_type);
			setCountry(charity?.country || 'Canada');
			setCity(charity?.city);
			setRegisteredName(charity?.registered_name);
			setRegisteredNumber(charity?.registered_charity_number);
			setProvince(charity?.province);
			setPostalCode(charity?.postal_code);
			setSchoolDistrict(charity?.school_district);
			setWebsite(charity?.website);
			setCoverPhoto(charity?.logo);
			setInitialSrc(charity?.logo);
			setAboutOrg(charity?.about_organization);
		}
	}, [charity]);

	useEffect(() => {
		if (coverPhoto !== initialSrc) {
			setImageChanged(true);
		} else {
			setImageChanged(false);
		}
	}, [coverPhoto, initialSrc]);

	// ['need_registered_id', 'charity_id', 'charity_user_id', 'category', 'cause', 'status', 'city','province'];

	return (
		<>
			<Form
				toBeValidatedFields={toBeValidatedFields}
				className={cx(styles.form)}
				onSubmit={submitHandler}>
				<TextBox
					isRequired
					labelText="Name"
					type="text"
					val={name}
					setVal={setName}
					errorHandler={setNameErrorMessage}
					name="name"
					errorMessage={nameErrorMessage}
				/>
				<TextBox
					labelText="Registered Name"
					type="text"
					val={registeredName}
					setVal={setRegisteredName}
					errorHandler={setRegisteredNameErrorMessage}
					name="register_name"
					errorMessage={registeredNameErrorMessage}
				/>
				<TextBox
					labelText="Registered Number"
					type="text"
					val={registeredNumber}
					setVal={setRegisteredNumber}
					errorHandler={setRegisteredNumberErrorMessage}
					name="registered_number"
					errorMessage={registeredNumberErrorMessage}
					inputClassName=""
				/>
				<TextBox
					labelText="Website"
					type="text"
					val={website}
					setVal={setWebsite}
					errorHandler={setWebsiteErrorMessage}
					name="website"
					errorMessage={websiteErrorMessage}
					inputClassName=""
				/>
				<TextBox
					isRequired
					labelText="Address"
					type="text"
					val={address}
					setVal={setAddress}
					errorHandler={setAddressErrorMessage}
					name="address"
					errorMessage={addressErrorMessage}
					inputClassName=""
				/>
				<TextBox
					isRequired
					labelText="Country"
					type="text"
					val={country}
					setVal={setCountry}
					errorHandler={setCountryErrorMessage}
					name="country"
					errorMessage={countryErrorMessage}
					inputClassName=""
				/>
				<TextBox
					isRequired
					labelText="City"
					type="text"
					val={city}
					setVal={setCity}
					errorHandler={setCityErrorMessage}
					name="city"
					errorMessage={cityErrorMessage}
					inputClassName=""
				/>
				<TextBox
					isRequired
					labelText="Postal Code"
					type="text"
					val={postalCode}
					setVal={setPostalCode}
					errorHandler={setPostalCodeErrorMessage}
					name="postal_code"
					errorMessage={postalCodeErrorMessage}
					inputClassName=""
				/>
				<FormSelect
					labelText="Province"
					className={cx('mb4')}
					options={PROVINCE_CHOICES}
					fullWidth
					openOptionsDownWard={true}
					initialSelected={province}
					isRequired
					val={province}
					setVal={setProvince}
					errorMessage={provinceErrorMessage}
					errorHandler={setProvinceErrorMessage}
				/>
				<FormSelect
					labelText="Charity Type"
					className={cx('mb4')}
					options={ORGANIZATION_TYPE_CHOICES}
					fullWidth
					openOptionsDownWard={true}
					initialSelected={charityType}
					isRequired
					val={charityType}
					setVal={setCharityType}
					errorMessage={charityTypeErrorMessage}
					errorHandler={setCharityTypeErrorMessage}
				/>
				<TextBox
					labelText="School District"
					type="text"
					val={schoolDistrict}
					setVal={setSchoolDistrict}
					errorHandler={setSchoolDistrictErrorMessage}
					name="school_district"
					errorMessage={schoolDistrictErrorMessage}
					inputClassName=""
				/>
				<FormTextArea
					labelText="About Organization"
					placeHolder={''}
					val={aboutOrg}
					setVal={setAboutOrg}
					errorMessage={aboutOrgErrorMessage}
					errorHandler={setAboutOrgErrorMessage}
					id="messageFieldHomePage"
				/>
				<FormImagePicker
					labelText="Logo"
					file={coverPhoto}
					setFile={setCoverPhoto}
					errorMessage={coverPhotoErrorMessage}
					errorHandler={setCoverPhotoErrorMessage}
					id="coverPhotoCharityRegistrationPage"
					hasCropper={false}
					cropInfo={{ aspect: 1.5 }}
					initialSrc={initialSrc}
					setInitialSrc={setInitialSrc}
				/>

				<Div className={cx('my2 flex flex--jc--end')}>
					<Button>Submit</Button>
				</Div>
			</Form>
		</>
	);
}

export default CharityEditForm;
