import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';
import { Divider } from 'troop-design';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Icon from 'root/baseComponents/Icon';

import { deleteCookie } from 'root/utils/helpers';

import { DASHBOARD_ITEMS } from '../constants';
import DashboardItem from './DashboardItem';
import styles from '../Wrapper.module.scss';

function SdiebarDashboard() {
	const history = useHistory();
	const user = useSelector(state => state.users.loggedInUser);
	const charitySidebarState = useSelector(state => state.charitySidebarState);

	const signOutHandler = () => {
		deleteCookie('accessToken');
		deleteCookie('refreshToken');
		history.push('/login');
	};

	return (
		<>
			<Div
				type="flex"
				className={cx(
					'pos-fix z-100 bgWhite boxShadowType1',
					styles.dashboard,
					charitySidebarState && styles.dashboardIsActive
				)}>
				<Div type="flex" direction="vertical" className="pl2 w-per-100">
					<Div className="mt3 pl1">
						{user?.first_name} {user?.last_name}
					</Div>
					<Div className="fs-px-12 pl1 textFaded mb3">{user?.email}</Div>
					{DASHBOARD_ITEMS.map((item, idx) => {
						if (item.user === 'troop_writer') {
							return (
								<DashboardItem
									key={idx}
									itemName={item.title}
									iconType={item.icon}
									iconColor="gray"
									to={item.to}
								/>
							);
						}
					})}
					<br />
					<Divider />
					<Div
						onClick={signOutHandler}
						type="flex"
						className="p1 w-per-100 mouse-hand">
						<Div className="mr1">
							<Icon type={'sign-out'} color={'gray'} />
						</Div>
						<Div className="textGray">Sign Out</Div>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default SdiebarDashboard;
