import React, { useEffect, useState } from 'react';

import { Heading, Button, Text } from 'troop-design';

import { hideModal } from 'reducers/modals';
import Modal from 'components/Modal';
import { useDispatch } from 'react-redux';

function PermissionSetupPrompt({ success }) {
	const [text, setText] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		if (success) {
			setText(
				'Thanks for installing the Troop app for Microsoft Teams! There are just a few more steps to get your shiny new channel set-up. 🎉'
			);
		} else {
			setText(
				'Something went wrong to properly install Microsoft Teams app to your work space. Please try it again!'
			);
		}
	}, []);

	return (
		<Modal reduxKey="permissionSetupPrompt" size="md">
			<Heading level={2}>Installation Successful</Heading>
			<Text tag="p" hasWrapper>
				{text}
			</Text>
			<Button
				variant="dark"
				outline
				onClick={() => dispatch(hideModal('permissionSetupPrompt'))}>
				Dismiss
			</Button>
		</Modal>
	);
}

export default PermissionSetupPrompt;
