import React from 'react';
import cx from 'classnames';
import { Button as BaseButton } from 'basedesign-iswad';

import styles from './Button.module.scss';

function Button({
	fullWidth = false,
	colorType = 'primary',
	children,
	disabled,
	isSmall,
	btnType = 1,
	className,
	...props
}) {
	return (
		<>
			<BaseButton
				className={cx(
					btnType === 1 && styles.button,
					btnType === 2 && styles.buttonNoPadding,
					colorType === 'primary' && styles.primary,
					colorType === 'success' && styles.success,
					colorType === 'cancel' && styles.cancel,
					colorType === 'danger' && styles.danger,
					colorType === 'transparent' && styles.transparent,
					colorType === 'transparent-success' && styles.transparentSuccess,
					colorType === 'transparent-danger' && styles.transparentDanger,
					colorType === 'transparent-warning' && styles.transparentWarning,
					colorType === 'transparent-info' && styles.transparentInfo,
					colorType === 'silver' && styles.silver,
					fullWidth && 'w-per-100',
					disabled && colorType === 'transparent' && styles.transparentDisabled,
					disabled && colorType !== 'transparent' && styles.disabled,
					isSmall && styles.smallBtn,
					className
				)}
				disabled={disabled}
				{...props}>
				{children}
			</BaseButton>
		</>
	);
}

export default Button;
