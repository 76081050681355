import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setUpcomingEvent'] = (state, action) => action.payload;
reducerObject['clearUpcomingEvent'] = (state, action) => {};

const slice = createSlice({
	name: 'upcomingEvent',
	initialState: {},
	reducers: reducerObject
});

export const { setUpcomingEvent, clearUpcomingEvent } = slice.actions;
export default slice.reducer;
