// VENDOR imports
import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Search from 'root/baseComponents/Search';

import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { getNeedsForVote, getFilteredNeedsForVote } from 'root/services/needs';

// style import
import styles from '../BuildVoteLandingPage.module.scss';

const VoteList = ({ needIds, setNeedIds }) => {
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [filters, setFilters] = useState({ page: 1 });
	const [numberOfAllPages, setNumberOfAllPages] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState([]);

	const getNeedsForVoteData = async () => {
		dispatch(setLoading());
		try {
			const res = await getFilteredNeedsForVote(filters);
			dispatch(setLoadingComplete());
			if (res?.needs?.length) {
				const localTableData = [];
				res.needs.forEach(item => {
					localTableData.push({
						id: item.id,
						charity: item.charity_name,
						short_description: item.short_description,
						target: item?.value,
						city: item.charity_city
					});
				});
				setNumberOfAllPages(parseInt(res.total_needs / 10) + 1);
				setTableData(localTableData);
			}
		} catch (err) {
			console.log(err);
			dispatch(setLoadingComplete());
		}
	};

	useEffect(() => {
		getNeedsForVoteData();
	}, [filters]);

	useEffect(() => {
		const localFilters = { ...filters };
		localFilters['page'] = currentPage;
		setFilters(localFilters);
	}, [currentPage]);

	const headLines = [
		{
			value: 'select',
			display: (
				<Div type="flex" vAlign="center">
					<Div>
						<input
							type="checkbox"
							style={{ transform: 'scale(1.25)' }}
							className="mouse-hand"
							checked={false}
							onChange={e => {
								console.log(e.target.checked);
							}}
						/>
					</Div>
				</Div>
			),
			width: 25,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'id',
			display: (
				<Div type="flex" vAlign="center">
					<Div>ID</Div>
					<Div>
						<Search
							onChange={e => {
								setCurrentPage(1);
								setTimeout(() => {
									const localFilters = { ...filters };
									localFilters['id'] = parseInt(e.target.value);
									setFilters(localFilters);
								}, 500);
							}}
						/>
					</Div>
				</Div>
			),
			width: 250,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'charity',
			display: (
				<Div type="flex" vAlign="center">
					<Div>Charity</Div>
					<Div>
						<Search
							onChange={e => {
								setCurrentPage(1);
								setTimeout(() => {
									const localFilters = { ...filters };
									localFilters['charity_name'] = e.target.value;
									setFilters(localFilters);
								}, 500);
							}}
						/>
					</Div>
				</Div>
			),
			width: 350,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'short_description',
			display: (
				<Div type="flex" vAlign="center">
					<Div>Description</Div>
					<Div>
						<Search
							onChange={e => {
								setCurrentPage(1);
								setTimeout(() => {
									const localFilters = { ...filters };
									localFilters['short_description'] = e.target.value;
									setFilters(localFilters);
								}, 500);
							}}
						/>
					</Div>
				</Div>
			),
			width: 350,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'target',
			display: 'Target',
			width: 250,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'city',
			display: (
				<Div type="flex" vAlign="center">
					<Div>City</Div>
					<Div>
						<Search
							onChange={e => {
								setCurrentPage(1);
								setTimeout(() => {
									const localFilters = { ...filters };
									localFilters['city'] = e.target.value;
									setFilters(localFilters);
								}, 500);
							}}
						/>
					</Div>
				</Div>
			),
			width: 300,
			hasSearch: false,
			isSortable: false
		}
	];

	const data = useMemo(() => {
		if (tableData?.length) {
			const localData = [];
			tableData?.forEach(item => {
				localData.push({
					select: {
						value: item.id,
						display: (
							<Div type="flex" vAlign="center">
								<Div>
									<input
										type="checkbox"
										style={{ transform: 'scale(1.25)' }}
										className="mouse-hand"
										checked={selectedData.includes(item.id)}
										onChange={e => {
											let localSelectedData = [...selectedData];
											if (localSelectedData.includes(item.id)) {
												localSelectedData = localSelectedData.filter(
													curItem => curItem !== item.id
												);
											} else {
												localSelectedData.push(item.id);
											}
											setSelectedData(localSelectedData);
										}}
									/>
								</Div>
							</Div>
						)
					},
					id: item.id,
					charity: item.charity,
					short_description: item.short_description,
					target: item.target,
					city: item.city
				});
			});
			return localData;
		} else {
			return [];
		}
	}, [tableData, selectedData]);

	useEffect(() => {
		const localNeedIds = [];
		selectedData?.forEach(item => {
			localNeedIds.push(item);
		});
		setNeedIds(localNeedIds);
	}, [selectedData]);

	return (
		<>
			<Div className={cx('', styles.main)}>
				<Div className="employeeHeading mb1 mt2 ml2">All Events</Div>
				<Div className="w-per-100 ml1 flex flex--jc--start pt2 of-x-auto scrollType1 mb8">
					<Table
						headLines={headLines}
						data={data}
						// colWidth={400}
						// tableWidth={'1000px'}
						isSortable={true}
						isSelectable={false}
						// selectedData={selectedData}
						// setSelectedData={setSelectedData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={5}
						numberOfAllPages={numberOfAllPages}
					/>
				</Div>
			</Div>
		</>
	);
};

export default VoteList;
