import { get, post } from './http';

export const getGeeneesNeeds = data => {
	return post(`/get-geenees-needs/`, data);
};

export const checkHasEnoughMoneyToFulfillAGeeneesNeed = data => {
	return post(`/check_has_enough_money_to_fulfill_a_geenees_need/`, data);
};

export const fulfillAGeeneesNeed = data => {
	return post(`/fulfill_a_geenees_need/`, data);
};

export const connectStripe = data => {
	return post(`/stripe-connect/`, data);
};

export const retrievePurchaseReceipts = () => {
	return get(`/retrieve-purchase-receipts/`);
};

export const retrieveAllPurchaseReceipts = () => {
	return get(`/retrieve-all-purchase-receipts/`);
};

export const retrieveAllGeeneesImpacts = data => {
	console.log(data.date);
	return get(
		`/get-geenees-impacts/?date=${data.date}&business_id=${data.business_id}`
	);
};
