import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';
import cx from 'classnames';

import Icon from 'root/baseComponents/Icon';
import Close from 'root/baseComponents/Close';
import Button from 'root/baseComponents/Button';
import { clearModalType } from 'root/reducers/charityReducers/modalType';
import { clearModal } from 'root/utils/modal';

import styles from '../Modal.module.scss';

function ReferModal() {
	const dispatch = useDispatch();

	const modalType = useSelector(state => state.modalType);
	const header = useSelector(state => state.modalProps.header);
	const text = useSelector(state => state.modalProps.text);
	const body = useSelector(state => state.modalProps.body);
	const email = useSelector(state => state.modalProps.email);
	const anchorTo = useSelector(state => state.modalProps.anchorTo) || '';

	const [isCopied, setIsCopied] = useState(false);

	const mailto = `mailto:${email}?subject=You need to Learn about Troop!&body=${encodeURIComponent(
		body
	)}`;

	return (
		<>
			<Div
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.modalContainer
				)}>
				<Div
					className={cx(
						'pos-fix pos-fix--center br-rad-px-5 card w-per-90 max-w-px-700',
						styles.heading
					)}>
					<Div
						className={cx(
							'p1 bgPrimary',
							modalType === 'refer' && styles.refer
						)}>
						<Div type="flex" vAlign="center" className="pl1">
							<Icon type="envelope" color="white" scale={1.5} />
							<Div className="textWhite f-b pl2 ">{header}</Div>
						</Div>
						<Close onClick={() => dispatch(clearModalType())} />
					</Div>

					<Div className="pt1 pl3 pr3 pb2 fs-px-14">
						{anchorTo?.length <= 0 ? (
							text
						) : (
							<a href={anchorTo} target="_blank">
								{text}
							</a>
						)}
					</Div>
					<Div type="flex" hAlign="center" className="pb1 w-per-100">
						<Button
							className="mr2"
							colorType="transparent"
							onClick={() => {
								// clearModal(dispatch);
								setIsCopied(true);
								navigator.clipboard.writeText(body);
							}}>
							{isCopied ? 'Copied ✔' : 'Copy Email'}
						</Button>{' '}
						<a href={mailto}>
							<Button onClick={() => clearModal(dispatch)}>
								Send the Email
							</Button>
						</a>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default ReferModal;
