import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';
import { Icon } from 'troop-design';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';

import { exportVoteHistoryForBusiness } from 'root/services/businesses';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import useCurrentBusiness from 'root/hooks/useCurrentBusiness';
import useSuperAdminLoggedIn from 'root/hooks/useSuperAdminLoggedIn';
import { retrieveBusinessCustomers } from 'root/services/customers';

const Customers = ({
	selectedCustomerData,
	setSelectedCustomerData,
	setShowConfirmationModal
}) => {
	const dispatch = useDispatch();
	const params = useParams();
	const isSuperAdmin = useSuperAdminLoggedIn();
	let business = useCurrentBusiness();
	const businessById = useSelector(state =>
		state.businesses.businesses.find(
			business => business.id === parseInt(params.businessId, 10)
		)
	);
	if (isSuperAdmin) {
		business = businessById;
	}

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [customers, setCustomers] = useState([]);
	const [activeMonths, setActiveMonths] = useState({});

	const retrieveBusinessCustomersData = async () => {
		let res;
		if (isSuperAdmin) {
			dispatch(setLoading());
			res = await retrieveBusinessCustomers(parseInt(params.businessId, 10));
			dispatch(setLoadingComplete());
		} else {
			dispatch(setLoading());
			res = await retrieveBusinessCustomers();
			dispatch(setLoadingComplete());
		}
		if (res?.length) {
			setCustomers([...res]);
		}
	};

	useEffect(() => {
		if (business) {
			setActiveMonths({
				0: dayjs(business.lastThreeVoteDate).format('MMM'),
				1: dayjs(business.lastThreeVoteDate).subtract(1, 'month').format('MMM'),
				2: dayjs(business.lastThreeVoteDate).subtract(2, 'month').format('MMM')
			});
			retrieveBusinessCustomersData();
		}
	}, [business]);

	const headLines = useMemo(() => {
		if (activeMonths?.[0]) {
			return [
				{
					value: 'name',
					display: 'Name',
					width: 250,
					hasSearch: true,
					isSortable: true
				},
				{
					value: 'email',
					display: 'Email',
					width: 300,
					hasSearch: true,
					isSortable: true
				},
				{
					value: 'last_voted',
					display: 'Last Voted',
					width: 200,
					hasSearch: false,
					isSortable: true
				},
				{
					value: 'months_ago_0',
					display: activeMonths?.[0],
					width: 100,
					hasSearch: false,
					isSortable: true
				},
				{
					value: 'months_ago_1',
					display: activeMonths?.[1],
					width: 100,
					hasSearch: false,
					isSortable: true
				},
				{
					value: 'months_ago_2',
					display: activeMonths?.[2],
					width: 100,
					hasSearch: false,
					isSortable: true
				}
			];
		} else {
			return [];
		}
	}, [activeMonths]);

	const data = useMemo(() => {
		const tableData = [];
		customers?.forEach(item => {
			tableData.push({
				id: item?.id,
				name: `${item?.first_name} ${item?.last_name}`,
				email: item?.email,
				last_voted: item?.lastVoted,
				months_ago_0: {
					value: item?.historicalVotes?.[0],
					display: (
						<Div>
							{item?.historicalVotes?.[0] == 'Waiting' && (
								<Icon icon="waiting2" />
							)}
							{item?.historicalVotes?.[0] == true && <Icon icon="checkmark2" />}
							{item?.historicalVotes?.[0] == false && <Icon icon="crossmark" />}
						</Div>
					)
				},
				months_ago_1: {
					value: item?.historicalVotes?.[1],
					display: (
						<Div>
							{item?.historicalVotes?.[1] == 'Waiting' && (
								<Icon icon="waiting2" />
							)}
							{item?.historicalVotes?.[1] == true && <Icon icon="checkmark2" />}
							{item?.historicalVotes?.[1] == false && <Icon icon="crossmark" />}
						</Div>
					)
				},
				months_ago_2: {
					value: item?.historicalVotes?.[2],
					display: (
						<Div>
							{item?.historicalVotes?.[2] == 'Waiting' && (
								<Icon icon="waiting2" />
							)}
							{item?.historicalVotes?.[2] == true && <Icon icon="checkmark2" />}
							{item?.historicalVotes?.[2] == false && <Icon icon="crossmark" />}
						</Div>
					)
				}
			});
		});
		return tableData;
	}, [customers, headLines]);

	return (
		<>
			<Div
				type="flex"
				vAlign="center"
				distributedBetween
				className="flex--wrap my2">
				<Div>
					<Button
						colorType="primary"
						className="flex flex--jc--center flex--ai--center max-height-px-30"
						onClick={async () => {
							dispatch(setLoading());
							try {
								const res = await exportVoteHistoryForBusiness({
									business_id: business.id,
									user_type: 'customers'
								});
								console.log(res);
								if (res?.success) {
									window.location.replace(res?.file_address);
								}
								dispatch(setLoadingComplete());
							} catch (err) {
								console.log(err);
								dispatch(setLoadingComplete());
							}
						}}>
						Export Vote History
					</Button>
				</Div>

				<Div>
					<Button
						colorType="transparent"
						disabled={selectedCustomerData.length === 0}
						className={cx(
							'w-px-100 flex flex--jc--center flex--ai--center max-height-px-30'
						)}
						onClick={() => setShowConfirmationModal(true)}>
						Delete
					</Button>
				</Div>
			</Div>
			<Div className="w-per-100 flex flex--jc--start of-x-auto scrollType1">
				{headLines?.length > 0 && (
					<Table
						headLines={headLines}
						data={data}
						colWidth={400}
						isSortable={true}
						isSelectable={true}
						selectedData={selectedCustomerData}
						setSelectedData={setSelectedCustomerData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						rowsPerPage={10}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={3}
					/>
				)}
			</Div>
		</>
	);
};

export default Customers;
