import { useSelector } from 'react-redux';
import useLoggedIn from './useLoggedIn';

export default function useTroopWriterLoggedIn() {
	const loggedIn = useLoggedIn();
	const isTroopWriter = useSelector(state =>
		state.users.currentUserRoles.includes('Troop Writer')
	);
	return loggedIn && isTroopWriter;
}
