import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Icon from 'root/baseComponents/Icon';
import { getBackendUrl } from 'root/utils/helpers';

import { retrieveAllSavings } from 'root/services/employees';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

function Purchases() {
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [reciepts, setReciepts] = useState([]);

	const retrieveAllSavingsData = async () => {
		dispatch(setLoading());
		const res = await retrieveAllSavings();
		dispatch(setLoadingComplete());
		console.log(res);
		const localReceipts = [];
		res.forEach(item => {
			localReceipts.push({
				email: item?.user?.email,
				saving: item?.saving
			});
		});
		setReciepts(localReceipts);
	};

	useEffect(() => {
		retrieveAllSavingsData();
	}, []);

	const headLines = [
		{
			value: 'email',
			display: 'Email',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'saving',
			display: 'Savings',
			width: 350,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		reciepts.forEach(item => {
			tableData.push({
				email: item.email,
				saving: item.saving
			});
		});

		return tableData;
	}, [reciepts]);
	return (
		<>
			<Div className="w-per-80 pr2 pl2 flex flex--jc--start ">
				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					tableWidth={'1000px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={10}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
}

export default Purchases;
