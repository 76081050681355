import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// troop design imports
import {
	Row,
	Col,
	Heading,
	Table,
	Form,
	Switch,
	Spinner,
	Button,
	Badge
} from 'troop-design';
// imports
import useRedirectTo from 'hooks/useRedirectTo';
import {
	getFeatureFlags,
	updateFeatureFlag,
	deleteFeatureFlag
} from 'root/services/featureFlags';
import styles from './FeatureFlags.module.scss';

export default function FeatureFlags() {
	const dispatch = useDispatch();
	const redirectTo = useRedirectTo();
	const dataSet = useSelector(state => state.featureFlags.featureFlags);

	useEffect(() => {
		dispatch(getFeatureFlags());
	}, []);

	const columns = useMemo(
		() => [
			{
				Header: 'Name',
				accessor: 'name',
				filter: 'equals'
			},
			{
				Header: 'Description',
				accessor: 'description',
				filter: 'equals'
			},
			{
				Header: 'Flag name',
				accessor: 'flag',
				filter: 'equals'
			},
			{
				Header: 'Edit',
				accessor: 'edit',
				filter: 'equals'
			},
			{
				Header: 'Delete',
				accessor: 'delete',
				filter: 'equals'
			}
		],
		[]
	);

	const data = useMemo(
		() =>
			dataSet.map(featureFlag => {
				return {
					...featureFlag,
					edit: (
						<Button
							variant="primary"
							onClick={() => {
								redirectTo(`/feature-flags/${featureFlag.id}/edit`);
							}}>
							Edit
						</Button>
					),
					delete: (
						<Button
							id={`delete-${featureFlag.id}`}
							variant="danger"
							onClick={e => {
								const id = e.target.id.split('-')[1];
								if (id !== null) {
									const featureFlag = dataSet.find(
										feature => feature.id === Number(id)
									);
									dispatch(deleteFeatureFlag(id, featureFlag));
								}
							}}>
							Delete
						</Button>
					)
				};
			}),
		[dataSet]
	);

	return (
		<>
			<Row className={styles.headingRow}>
				<Col xs={12} md={8}>
					<Heading level={1} className="mt-0">
						Feature Flags
					</Heading>
				</Col>
				<Col className={styles.addNewButtonContainer} xs={12} md={4}>
					<Button
						variant="success"
						onClick={() => {
							redirectTo('/feature-flags/add/');
						}}>
						Add New
					</Button>
				</Col>
			</Row>
			<Form>
				<Table
					className="mt-3"
					data={data}
					columns={columns}
					striped={false}
					hover
				/>
			</Form>
		</>
	);
}
