import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import styles from '../NeedsToReviewDetails.module.scss';

function InfoRow({ title, info }) {
	return (
		<Div type="flex" className="mb2 w-per-100">
			<Div className="w-px-150">{title}:</Div>
			<Div className="">{info}</Div>
		</Div>
	);
}

export default InfoRow;
