import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Image, Text, Icon, Anchor } from 'troop-design';
import { Div } from 'basedesign-iswad';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { getBackendUrl } from 'root/utils/helpers';

import styles from '../MyImpact.module.scss';

const excerptLength = 100;

const ReportCard = ({
	need,
	fulfilledNeed,
	fulfilledByEmployee,
	activatedFulfilledNeed,
	setActivatedFulfilledNeed,
	key,
	voteCounts
}) => {
	// const [src, setSrc] = useState('');

	// useEffect(() => {
	// 	console.log(fulfilledNeed);
	// }, [fulfilledNeed]);

	// useEffect(() => {
	// 	let url = need.image;
	// 	setSrc(`https://stagingapi.hitroop.com${url}`);
	// }, [need]);

	return (
		<>
			<div
				key={key}
				className={cx(
					'',
					styles.card
					// activatedFulfilledNeed === need?.id && styles.fulfilledActivated
				)}
				onClick={() => {
					if (fulfilledByEmployee || fulfilledNeed) {
						setActivatedFulfilledNeed(need.id);
					}
				}}>
				<Div className="w-per-100" type="flex" direction="vertical">
					<div className={cx(styles.cardImage)}>
						<LazyLoadImage
							src={need.image}
							// Need to change src to the above value before merging
							// src={src}
							className={cx(styles.cardImagePhoto)}
						/>
					</div>
					<Div className="w-per-100 p1 height-px-100">
						<Div type="flex" hAlign="start" className="textBlack fs-px-14 mb1">
							{need.name}
						</Div>
						<Div type="flex" hAlign="start" className="textFaded fs-px-12 mb1">
							{need.charity_name}
						</Div>
						<div
							style={{
								background: 'silver',
								height: '3px',
								width: '50px'
							}}
						/>
					</Div>
					<Div
						type="flex"
						hAlign="start"
						className={'w-per-100 p1 f-b fs-px-14 ml-auto mr-auto textFaded'}>
						{voteCounts} Votes
					</Div>
					<Div
						type="flex"
						hAlign="start"
						className="textInfo ml1 fs-px-16 f-b mb2">
						{fulfilledNeed && !fulfilledByEmployee ? (
							<Div className="">Company Fulfilled</Div>
						) : (
							''
						)}
						{fulfilledNeed && fulfilledByEmployee ? (
							<Div className="">Employee Fulfilled</Div>
						) : (
							''
						)}
						{!fulfilledNeed ? (
							<Div className="textFaded">Not Fulfilled</Div>
						) : (
							''
						)}
					</Div>
				</Div>
			</div>
		</>
	);
};

export default ReportCard;
