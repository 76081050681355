import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import cx from 'classnames';
import {
	Card,
	Row,
	Col,
	Heading,
	Text,
	Input,
	Form,
	Button,
	Select,
	File,
	Radio,
	Spinner
} from 'troop-design';
import {
	addEmployee,
	bulkEmployeeUpload,
	newEmployeeListUpload,
	addNewEmployee
} from 'services/employees';
import { addNotification } from 'root/reducers/notifications';
import { showModal } from 'reducers/modals';
import ConfirmEmployee from './components/ConfirmEmployee';
import styles from './Employees.module.scss';
import useCurrentBusiness from 'root/hooks/useCurrentBusiness';
import useCurrentPage from 'root/hooks/useCurrentPage';

export default function AddEmployee() {
	const [isLoading, setIsLoading] = useState(false);
	const [uploadMethod, setUploadMethod] = useState('0');
	const [employeeList, setEmployeeList] = useState([]);
	const dispatch = useDispatch();
	const params = useParams();
	const currentBusiness = useCurrentBusiness();
	const currentPage = useCurrentPage();
	let businessId = params.businessId;

	if (currentPage === 'employees') {
		businessId = currentBusiness.id;
	}

	const handleAddEmployee = data => {
		data['email'] = data['email'].toLowerCase();
		dispatch(addNewEmployee(businessId, data));
	};

	const handleMultipleUpload = data => {
		dispatch(bulkEmployeeUpload(businessId, data));
	};

	const handleNewEmployeeList = data => {
		setIsLoading(true);
		newEmployeeListUpload(businessId, data).then(resp => {
			if (Array.isArray(resp)) {
				setEmployeeList(resp);
				dispatch(showModal('confirmEmployee'));
			} else {
				dispatch(
					addNotification({
						type: 'danger',
						message: `${resp}`
					})
				);
			}
			setIsLoading(false);
		});
	};

	return (
		<>
			<Card>
				<Row className="px-4">
					<Col xs={12} lg={8}>
						<Heading level={2}>Add Employee</Heading>
						<Form onSubmit={handleAddEmployee}>
							<Row>
								<Col md="6">
									<Input
										label="First name"
										name="firstname"
										validationRules={[
											{
												rule: 'string.isRequired',
												message: 'Firstname is required'
											}
										]}
									/>
								</Col>
								<Col md="6">
									<Input
										label="Lastname"
										name="lastname"
										validationRules={[
											{
												rule: 'string.isRequired',
												message: 'Lastname is required'
											}
										]}
									/>
								</Col>
								<Col md="12">
									<Input
										label="Email"
										name="email"
										type="email"
										validationRules={[
											{
												rule: 'string.isRequired',
												message: 'A valid email address is required'
											}
										]}
									/>
								</Col>
								<Col col="4">
									<Radio
										value="0"
										label="Is Business Admin?"
										name="is_primary_contact"
										validationRules={[
											{
												rule: 'option.atLeastOne',
												message: 'Please make a selection'
											}
										]}
										options={[
											{ label: 'Yes', value: '1' },
											{ label: 'No', value: '0' }
										]}
									/>
								</Col>
								<Col md="4">
									<Select
										label="Status"
										name="status"
										validationRules={[
											{
												rule: 'option.atLeastOne',
												message: 'Please make a selection'
											}
										]}
										value={'ACTIVE'}>
										<option value="ACTIVE">Active</option>
										<option value="INACTIVE">Inactive</option>
										<option value="DISABLED">Disabled</option>
									</Select>
								</Col>
							</Row>
							<div className="my-5">
								<Button variant="primary" size="md">
									Add Employee
								</Button>
							</div>
						</Form>
					</Col>
					<Col xs={12} lg={4}>
						<div className={styles.uploadMethodContainer}>
							<Form>
								<Radio
									value={uploadMethod}
									label="Choose an upload method"
									name="uploadMethod"
									onChange={e => setUploadMethod(e.target.value)}
									options={[
										{ label: 'Add employees', value: '0' },
										{
											label: 'Upload new employee list',
											value: '1'
										}
									]}
								/>
							</Form>
							{uploadMethod === '0' && (
								<>
									<Heading
										level={2}
										className={cx(
											'font-weight-light',
											styles.addMultipleUsersHeading
										)}>
										Add Employees
									</Heading>
									<Text
										tag="p"
										hasWrapper
										className={cx('my-2', styles.addMultipleUsersText)}>
										Use this option to add new employees to your list. Make sure
										the file includes separate columns for{' '}
										<strong>First Name, Last Name and Email Address</strong> (in
										that order).
									</Text>
									<Form onSubmit={handleMultipleUpload}>
										<File
											label="Select a CSV or XLSX File"
											name="file"
											formGroupClassName={styles.uploadField}
											validationRules={[
												{
													rule: 'file.isRequired',
													message: 'You must upload a file to continue..'
												}
											]}
										/>
										<Button variant="dark" size="lg" type="file">
											Start uploading
										</Button>
									</Form>
								</>
							)}
							{uploadMethod === '1' && (
								<>
									<Heading
										level={2}
										className={cx(
											'font-weight-light',
											styles.addMultipleUsersHeading
										)}>
										Upload New Employee List
									</Heading>
									<Text
										tag="p"
										hasWrapper
										className={cx('my-2', styles.addMultipleUsersText)}>
										Use this option to upload an entirely new employee list.
										Make sure the file includes separate columns for{' '}
										<strong>First Name, Last Name and Email Address</strong> (in
										that order).
									</Text>
									<Text tag="p" hasWrapper>
										Please note: New employees will be added to your list
										automatically, but you will have to manually re-select
										existing employees who aren’t on the new list if you would
										like them to remain active.
									</Text>
									<Form onSubmit={handleNewEmployeeList}>
										<File
											label="Select a CSV or XLSX File"
											name="file"
											formGroupClassName={styles.uploadField}
											validationRules={[
												{
													rule: 'file.isRequired',
													message: 'You must upload a file to continue..'
												}
											]}
										/>
										<Button variant="dark" size="lg" type="file">
											{isLoading ? <Spinner /> : 'Start uploading'}
										</Button>
									</Form>
								</>
							)}
						</div>
					</Col>
				</Row>
			</Card>
			<ConfirmEmployee businessId={businessId} employeeList={employeeList} />
		</>
	);
}
