import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';
import ReactGA from "react-ga4";

import Button from 'root/baseComponents/Button';
import Close from 'root/baseComponents/Close';
import Text from 'root/baseComponents/Text';

import { get, set, del } from 'utils/storage';
import {
	clearSelectedGeeneesWish,
	setSelectedGeeneesWish
} from 'root/reducers/selectedGeeneesWish';
import {
	setSelectedNeedToFulfill,
	clearSelectedNeedToFulfill,
	paymentDone
} from 'root/reducers/geenees';
import {
	checkHasEnoughMoneyToFulfillAGeeneesNeed,
	fulfillAGeeneesNeed
} from 'root/services/geenees';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import NeedCard from './NeedCard';
import styles from '../GeneesProduct.module.scss';

function PurchaseModal() {
	const history = useHistory();
	const dispatch = useDispatch();
	const selectedGeeneesWish = useSelector(state => state.selectedGeeneesWish);
	const businessUser = useSelector(state => state.users.businessUserProfile);
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const fulFillTheNeed = async () => {
		const needData = {
			geenees_need_identifier: selectedGeeneesWish?.identifier,
			geenees_need_value: selectedGeeneesWish?.price?.total,
			geenees_need_item_fee: selectedGeeneesWish?.price?.item_price,
			geenees_need_tax_fee: selectedGeeneesWish?.price?.taxes_and_delivery,
			geenees_need_processing_fee: selectedGeeneesWish?.price?.processing_fee,
			supported_organization: selectedGeeneesWish?.organization,
			geenees_need_title: selectedGeeneesWish?.title,
			use_do_good_account_fund: selectedGeeneesWish?.price?.total
		};
		try {
			dispatch(setLoading());
			const needRes = await fulfillAGeeneesNeed(needData);
			dispatch(clearSelectedGeeneesWish());
			if (needRes?.success) {
				dispatch(setSelectedNeedToFulfill(selectedGeeneesWish));
				dispatch(paymentDone());
				history.push('/');
			} else {
				console.log(needRes);
			}
			dispatch(setLoadingComplete());
		} catch (err) {
			dispatch(clearSelectedGeeneesWish());
			dispatch(clearSelectedNeedToFulfill());
			console.log(err);
			dispatch(setLoadingComplete());
		}
	};

	const handleConfirmClick = useCallback(async () => {
		if (
			selectedGeeneesWish?.price?.total &&
			parseFloat(selectedGeeneesWish?.price?.total) > 1
		) {
			set('geenees_need', JSON.stringify(selectedGeeneesWish));
			try {
				const data = {
					geenees_value: selectedGeeneesWish?.price?.total
				};
				const res = await checkHasEnoughMoneyToFulfillAGeeneesNeed(data);
				console.log(res);
				if (res?.success) {
					dispatch(clearSelectedGeeneesWish());
					dispatch(setSelectedNeedToFulfill(selectedGeeneesWish));
					fulFillTheNeed();
				} else {
					dispatch(
						setSelectedGeeneesWish({
							...selectedGeeneesWish,
							use_do_good_account_fund:
								selectedGeeneesWish.price.total - res.required_money
						})
					);
					set(
						'geenees_need',
						JSON.stringify({
							...selectedGeeneesWish,
							use_do_good_account_fund:
								selectedGeeneesWish.price.total - res.required_money
						})
					);
					history.push({
						pathname: '/employee-checkout',
						state: {
							doGoodAmount: parseFloat(res.required_money),
							isGeeneesNeed: true
						}
					});
					ReactGA.event({
						event_name: 'acts_of_kindness_purchase',
						category: "Button Click",
						action: "Act of Kindness purchase clicked"
					  });
					history.push({
						pathname: '/stripe-checkout-page',
						state: {
							type: 'geenees_purchase',
							amount: parseFloat(res.required_money),
							selectedGeeneesWish,
							use_do_good_account_fund:
								selectedGeeneesWish.price.total - res.required_money
						}
					});
				}
			} catch (err) {
				dispatch(clearSelectedGeeneesWish());
				dispatch(clearSelectedNeedToFulfill());
				console.log(err);
			}
		}
	}, [selectedGeeneesWish, history, dispatch]);

	useEffect(() => {
		console.log(selectedGeeneesWish);
	}, [selectedGeeneesWish]);

	return (
		<>
			<Div
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.allClickable
				)}
			/>
			<Div
				type="flex"
				direction="vertical"
				className={cx(
					'card max-w-px-500 w-per-90 pos-fix pos-fix--center pt2 pb2',
					styles.modalContainer
				)}>
				<Close onClick={() => dispatch(clearSelectedGeeneesWish())} />
				<Div className="f-b mb2 text-center">Acts of Kindness</Div>
				<Div className="w-per-80 fs-px-14 ml-auto mr-auto textFaded">
					<Text summerized_max_length={140}>
						{selectedGeeneesWish?.aboutNeed}
					</Text>
				</Div>
				<Div className={cx('w-per-100 my3', styles.separator)} />
				<Div className="f-b w-per-70 ml-auto mr-auto">Review Purchase</Div>
				<Div className="w-per-80 ml-auto mr-auto">
					<NeedCard wish={selectedGeeneesWish} />
					<Div className="fs-px-14 textFaded mt2">
						In the event of market or price fluctuations, the chosen product may
						be substituted for similar alternatives of equal or slightly lesser
						value. Any remaining donations will be transferred to the nonprofit
						organization. For any questions contact us at hello@hitroop.com
					</Div>
					<Div className={cx('w-per-100 my3', styles.separator)} />
					<Div
						type="flex"
						distributedBetween
						vAlign="center"
						className="pl2 pr2">
						<Div className="w-px-150">
							<Div className="mb2">{'Subtotal'}</Div>
							<Div className="mb2">{'Taxes & Delivery'}</Div>
							<Div>{'Processing Fee'}</Div>
						</Div>
						<Div>
							<Div className="mb2">
								${selectedGeeneesWish?.price?.item_price}
							</Div>
							<Div className="mb2">
								${selectedGeeneesWish?.price?.taxes_and_delivery}
							</Div>
							<Div>${selectedGeeneesWish?.price?.processing_fee}</Div>
						</Div>
					</Div>
					<Div className={cx('w-per-100 my3', styles.separator)} />
					<Div type="flex" hAlign="end" className="f-b px2">
						Total: ${selectedGeeneesWish?.price?.total}
					</Div>
					<Div className={cx('fs-px-12 mt1 br-rad-px-5 p1', styles.notif)}>
						You have{' '}
						<span className={cx(styles.paymentInfoHighlighted)}>
							${myProfile?.saving?.toFixed(2)}
						</span>{' '}
						in your Do Good{' '}
						{parseFloat(selectedGeeneesWish?.price?.total - myProfile?.saving) >
						0 ? (
							<>account that will be applied to this purchase. </>
						) : (
							<>
								account, so{' '}
								<span className={cx(styles.paymentInfoHighlighted)}>
									${parseFloat(selectedGeeneesWish?.price?.total)?.toFixed(2)}
								</span>{' '}
								of it will be applied to your purchase!{' '}
							</>
						)}{' '}
						{parseFloat(selectedGeeneesWish?.price?.total - myProfile?.saving) >
							0 && (
							<>
								Your total charge will be{' '}
								<span className={cx(styles.paymentInfoHighlighted)}>
									$
									{parseFloat(
										selectedGeeneesWish?.price?.total - myProfile?.saving
									)?.toFixed(2)}
								</span>{' '}
							</>
						)}
					</Div>
					<Div type="flex" hAlign="end" className="f-b px2 mt2">
						<Button className="w-px-150" onClick={handleConfirmClick}>
							Confirm
						</Button>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default PurchaseModal;
