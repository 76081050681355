import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';
import Icon from 'root/baseComponents/Icon';
import Modal from 'root/baseComponents/Modal'

import {
	getRegisteredCharityAdminWithSignUpForm,
	verifyRegisterCharityAdminWithSignUpForm,
	deleteRegisterCharityAdminWithSignUpForm
} from 'root/services/charity';

import { clearModal } from 'root/utils/modal';
import { setModalProps } from 'root/reducers/charityReducers/modalProps';
import { setModalType } from 'root/reducers/charityReducers/modalType';

const NewRegisteredCharityAdmins = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState([]);

	const getRegisteredCharityAdminWithSignUpFormData = async () => {
		try {
			const res = await getRegisteredCharityAdminWithSignUpForm();
			const localTableData = [];
			res?.forEach(item => {
				localTableData.push({
					id: item.id,
					first_name: item.user.first_name,
					last_name: item.user.last_name,
					email: item.user.email,
					charity: item.charity.name
				});
			});
			console.log(localTableData);
			setTableData(localTableData);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getRegisteredCharityAdminWithSignUpFormData();
	}, []);

	const headLines = [
		{
			value: 'first_name',
			display: 'First Name',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'last_name',
			display: 'Last Name',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'email',
			display: 'Email',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'charity',
			display: 'Charity',
			width: 300,
			hasSearch: false,
			isSortable: false
		},
		{
			value: 'action',
			display: 'Action',
			width: 200,
			hasSearch: false,
			isSortable: false
		}
	];
	const acceptClickHandler = async (item) => {
		try {
			const res =
				await verifyRegisterCharityAdminWithSignUpForm(item.id);

			history.go(0);
			clearModal(dispatch);
		} catch (err) {
			console.log(err);
		}
	}

	const rejectClickHandler = async (item) => {
		try {
			const res =
				await deleteRegisterCharityAdminWithSignUpForm(item.id);

			history.go(0);
			clearModal(dispatch);
		} catch (err) {
			console.log(err);
		}
	}

	const data = useMemo(() => {
		let localData = [];
		if (tableData?.length) {
			tableData?.map(item => {
				localData.push({
					first_name: item.first_name,
					last_name: item.last_name,
					email: item.email,
					charity: item.charity,
					action: {
						value: item.id,
						display: (
							<Div type="flex" vAlign="center">
								<Div
									className="mr1 mouse-hand"
									onClick={async ()=>{
										dispatch(setModalType('accept-charity-admin'));
										dispatch(setModalProps({ text: `Do you want to accept ${ item.first_name + ' ' +item.last_name } sign-up?`, header: 'Accept Request', accept: acceptClickHandler, acceptItem: item}));
									}}>
									<Icon type="check" scale={1.2} color="green" />
								</Div>
								<Div
									className=" mouse-hand"
									onClick={async ()=>{
										dispatch(setModalType('reject-charity-admin'));
										dispatch(setModalProps({ text: `Do you want to reject ${ item.first_name + ' ' +item.last_name } sign-up?`, header: 'Reject Request', reject: rejectClickHandler, rejectItem: item}));
									}}
									>
									<Icon type="close" scale={1.2} color="red" />
								</Div>
							</Div>
						)
					}
				});
			});
		}
		return localData;
	}, [tableData]);

	return (
		<>
		<Modal />
			<div>
				<Div>
					<Table
						headLines={headLines}
						data={data}
						colWidth={400}
						// tableWidth={'900px'}
						// isSortable={true}
						// isSelectable={true}
						// selectedData={selectedData}
						// setSelectedData={setSelectedData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						rowsPerPage={5}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={3}
					/>
				</Div>
			</div>
		</>
	);
};

export default NewRegisteredCharityAdmins;
