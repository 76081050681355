import React, { Children, useState } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import styles from './Tooltip.module.scss';

function Tooltip({ text, width, children }) {
	const [showTooltip, setShowTooltip] = useState(false);

	return (
		<Div
			onMouseOver={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
			className="pos-rel">
			{children}
			{showTooltip && (
				<Div
					type="flex"
					hAlign="center"
					vAlign="center"
					className={cx(
						`p1 bgSilver textWhite op-90 pos-abs pos-abs--lb max-w-px-300 fs-px-12 br-all-hidden-1 br-rad-px-5 w-px-${width}`,
						styles.tooltip
					)}>
					{text}
				</Div>
			)}
		</Div>
	);
}

export default Tooltip;
