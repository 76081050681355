import React, { useEffect, useMemo, useState, useRef } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Tab, TabItem, TabContent, Anchor } from 'troop-design';
import { Div, Row, Column } from 'basedesign-iswad';
import { getBusinessUserProfile } from 'root/services/user';

import AddFundModal from 'root/components/DoGoodFund/subs/AddFundModal';
import { showModal, hideModal } from 'reducers/modals';

import EmployeeProfileForm from 'root/components/EmployeeProfileForm';
import EmployeeTransactions from 'root/components/EmployeeTransactions';
import Button from 'root/baseComponents/Button';
import Table from 'root/baseComponents/Table';
import Icon from 'root/baseComponents/Icon';

import { CHARITABLE_TAX_RECIEPT } from './constants';
import EmployeeReceipts from './subs/EmployeeReceipts';
import EmployeeTaxReceipts from './subs/EmployeeTaxReceipts';
import styles from './EmployeeProfile.module.scss';

const EmployeeProfile = () => {
	const dispatch = useDispatch();
	const tableReceiptsRef = useRef();
	const location = useLocation();

	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;
	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	useEffect(() => {
		if (businessId) {
			getBusinessUserProfile(dispatch, businessId);
		}
	}, [dispatch, businessId]);

	useEffect(() => {
		if (tableReceiptsRef.current && location.state?.comingToViewReceipts) {
			setTimeout(() => {
				tableReceiptsRef.current.scrollIntoView({ behaviour: 'smooth' });
			}, 1000);
		}
	}, [tableReceiptsRef?.current, location?.state]);

	const headLines = [
		{
			value: 'date',
			display: 'Date',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'name',
			display: 'Name',
			width: 250,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'reciept',
			display: 'Reciept',
			width: 150,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		CHARITABLE_TAX_RECIEPT.forEach(item => {
			tableData.push({
				date: item.date,
				name: item.name,
				reciept: (
					<Div>
						<Icon type="pdf" scale={1.5} />
					</Div>
				)
			});
		});
		return tableData;
	}, []);

	return (
		<>
			<div className={cx('pl3 pt3 pb3', styles.container)}>
				<div className="employeeHeading mb2">Personal Pledging </div>
				<div className="card flex flex--jc--center br-rad-px-5 w-px-400 height-px-100 ">
					<Row>
						<Column xs={6} sm={6} md={6} lg={6}>
							<div className="text-center flex flex--dir--col flex--jc--center flex--ai--center mt3">
								<div className="textFaded fs-px-14">Pledging balance </div>
								<div className={cx(styles.balanceAmount)}>
									{' '}
									${(myProfile?.saving || 0)?.toFixed(2) || 0}
								</div>
							</div>
						</Column>
						<Column xs={6} sm={6} md={6} lg={6}>
							<div className=" flex flex--dir--col flex--jc--center flex--ai--center">
								<Button
									className="mouse-hand"
									onClick={() => dispatch(showModal('addFundPopup'))}>
									add Funds
								</Button>
							</div>
						</Column>
					</Row>
				</div>
				<div className="employeeHeading mb2 mt4">Account Information </div>
				<div
					className={cx(
						'card br-rad-px-5 pt3 pl3 pr3 pb3 mr3',
						styles.profileContainer
					)}>
					<EmployeeProfileForm />
				</div>
				{/* <div className={styles.transactionsContainer}>
					<EmployeeTransactions businessUserId={myProfile.id} />
				</div> */}
				<div ref={el => (tableReceiptsRef.current = el)}>
					<EmployeeReceipts />
				</div>

				<EmployeeTaxReceipts />
			</div>
			<AddFundModal />
		</>
	);
};

export default EmployeeProfile;
