export const FUTURE_CHOICES = [
	{
		value: 'volunteer_opportunity',
		shownText: 'I want to see volunteer opportunities'
	},
	{
		value: 'share_needs_with_others',
		shownText: 'I want to share these needs with my friends/family'
	},
	{
		value: 'share_impact_with_others',
		shownText: 'I want to share the impact stories with my friends/family'
	},
	{
		value: 'stronger_partnership_with_charity',
		shownText:
			'I want our company to build a stronger partnership with one local charity'
	},
	{
		value: 'other',
		shownText: 'Other'
	}
];
