export const CHARITABLE_TAX_RECIEPT = [
	{
		date: 2019,
		name: 'Charitable Tax Reciept',
		type: 'geenees',
		amount: 70,
		charity: 'Wellington Pregnancy Care Centre'
	},
	{
		date: 2020,
		name: 'Charitable Tax Reciept',
		type: 'add_fund',
		amount: 50,
		charity: 'Hands in Service'
	},
	{
		date: 2021,
		name: 'Charitable Tax Reciept',
		type: 'pledge',
		amount: 25,
		charity: 'Recovery Acres Calgary Society'
	}
];
