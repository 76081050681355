import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import { Icon } from 'troop-design/';

import { COLORS } from 'root/constants/vars';

import styles from './Search.module.scss';

const Search = React.forwardRef(
	(
		{
			closable,
			iconFillColor,
			iconStrokeColor,
			hasWhiteBg = false,
			className,
			...props
		},
		ref
	) => {
		const [activeSearch, setActiveSearch] = useState(false);

		useEffect(() => {
			if (!closable) {
				setActiveSearch(true);
			}
		}, [closable]);

		return (
			<>
				<div className="flex w-per-100">
					<div
						className={cx(
							'flex flex--jc--center flex--ai--center iswad_search_container'
						)}>
						{closable ? (
							<Icon
								onClick={() => setActiveSearch(!activeSearch)}
								type="search"
								scale={0.8}
								color={'orange'}
								className="mouse-hand"
							/>
						) : (
							<Icon
								icon="search"
								size="lg"
								color={'orange'}
								className={cx(closable && 'mouse-hand')}
							/>
						)}
						<input
							type="search"
							className={cx(
								'iswad_search_input fs-px-16',
								activeSearch && 'iswad_search_input_active',
								!hasWhiteBg ? 'w-px-100' : 'w-px-200',
								!hasWhiteBg ? styles.input : styles.inputWhite,
								className
							)}
							{...props}
						/>
					</div>
				</div>
			</>
		);
	}
);

export default Search;
