import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	businesses: []
};

export const businessSlice = createSlice({
	name: 'businesses',
	initialState,
	reducers: {
		getBusinesses: (state, action) => {
			state.businesses = action.payload;
		},
		getBusiness: (state, action) => {
			const getExistingBusinessIndex = state.businesses.findIndex(
				business => business.id === action.payload.id
			);
			if (getExistingBusinessIndex !== -1) {
				state.businesses[getExistingBusinessIndex] = action.payload;
			} else {
				state.businesses.push(action.payload);
			}
		},
		addBusinesses: (state, action) => {
			state.businesses.push(action.payload);
		},
		updateBusiness: (state, action) => {
			const index = state.businesses.findIndex(
				business => business.id === action.payload.id
			);
			state.businesses[index] = action.payload.data;
		},
		deleteBusiness: (state, action) => {
			const index = state.businesses.findIndex(
				business => business.id === action.payload.id
			);
			state.businesses.splice(index, 1);
		}
	}
});

export const {
	getBusinesses,
	getBusiness,
	addBusinesses,
	updateBusiness,
	deleteBusiness
} = businessSlice.actions;
export default businessSlice.reducer;
