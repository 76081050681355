import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Div } from 'basedesign-iswad';

import CharitySignupModal from './subs/CharitySignupModal';

import styles from './Modal.module.scss';

const Modal = () => {
	const publicModalType = useSelector(state => state.publicModalType);
	const publicModalProps = useSelector(state => state.publicModalProps);
	return (
		<>
			{publicModalType === 'charity-admin-signup' && (
				<CharitySignupModal {...publicModalProps} />
			)}
		</>
	);
};

export default Modal;
