import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setSelectedNeedToFulfill'] = (state, action) => {
	const newState = { ...state, selectedNeedToFulfill: action.payload };
	return newState;
};
reducerObject['clearSelectedNeedToFulfill'] = (state, action) => {
	const newState = { ...state, selectedNeedToFulfill: {} };
	return newState;
};
reducerObject['paymentDone'] = (state, action) => {
	const newState = {
		...state,
		isPaymentDone: true
	};
	return newState;
};
reducerObject['paymentRequired'] = (state, action) => {
	const newState = {
		...state,
		isPaymentDone: false
	};
	return newState;
};

reducerObject['clearGeenees'] = (state, action) => {
	return {
		selectedNeedToFulfill: {},
		showThanksPaymentForSingleFulfill: false
	};
};

const slice = createSlice({
	name: 'geenees',
	initialState: {
		selectedNeedToFulfill: {},
		isPaymentDone: false
	},
	reducers: reducerObject
});

export const {
	setSelectedNeedToFulfill,
	clearSelectedNeedToFulfill,
	paymentDone,
	paymentRequired,
	clearGeenees
} = slice.actions;
export default slice.reducer;
