import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Image, Text, Icon, Anchor } from 'troop-design';
import { Div, Card, CardHeader, CardBody } from 'basedesign-iswad';

import styles from '../Home.module.scss';

const StatsCard = ({ title, amount, unit = '' }) => {
	return (
		<>
			<Card className="bgWhite card mx1 w-per-100 mb2 py3 br-rad-px-5">
				<Div type="flex" direction="vertical" distributedBetween>
					<Div className="text-center min-height-px-40 fs-px-14 mx1">
						{title}
					</Div>
					<Div
						className={cx(
							'text-center f-b fs-px-18 mt1',
							styles.impactReportNums
						)}>
						{amount + unit}
					</Div>
				</Div>
			</Card>
		</>
	);
};

export default StatsCard;
