import React from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Div, Image, Column, Row } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';
import Close from 'root/baseComponents/Close';
import Icon from 'root/baseComponents/Icon';

import { COLORS } from 'root/constants/vars';
import { clearGeenees } from 'root/reducers/geenees';

import styles from '../GeneesProduct.module.scss';

function ThanksPayment() {
	const dispatch = useDispatch();
	const geenees = useSelector(state => state.geenees);

	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'height-vh-full pos-fix pos-fix--lt w-per-100',
					styles.thanksContainer
				)}>
				<Div
					type="flex"
					className={cx('card p2 min-height-px-300 max-w-px-600 m1 pos-rel')}>
					<Close
						onClick={() => {
							dispatch(clearGeenees());
						}}
					/>
					<Row>
						<Column xs={12} sm={12} md={6} lg={6}>
							<Div type="flex" hAlign="center">
								<Div
									type="flex"
									direction="vertical"
									distributedBetween
									hAlign="center"
									vAlign="center"
									className={cx('card p2 mb2', styles.thanksCard)}>
									<Div className="w-px-150 mb2">
										<Image src={geenees?.selectedNeedToFulfill?.image} />
									</Div>
									<Div className="mb2 text-center">
										{geenees?.selectedNeedToFulfill?.title}
									</Div>
									<Div className="textFaded mb1">
										${geenees?.selectedNeedToFulfill?.price?.total}
									</Div>
								</Div>
							</Div>
						</Column>
						<Column xs={12} sm={12} md={6} lg={6}>
							<Div
								type="flex"
								direction="vertical"
								hAlign="center"
								distributedBetween
								className="w-px-350 p2">
								<Div className="f-b mb2">You're Awesome!</Div>
								<Div className="text-center m2">
									Thank you for supporting this act of kindness! We're on it and
									will get this item to its recepient ASAP!
								</Div>
								<Button
									onClick={() => {
										dispatch(clearGeenees());
									}}
									className="w-px-150 mouse-hand">
									Got it!
								</Button>
							</Div>
						</Column>
					</Row>
				</Div>
			</Div>
		</>
	);
}

export default ThanksPayment;
