import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Form from 'root/baseComponents/Form';
import TextBox from 'root/baseComponents/TextBox';
import Button from 'root/baseComponents/Button';
import Close from 'root/baseComponents/Close';

import { updatePassword } from 'root/services/user';
import { addNotification } from 'root/reducers/notifications';

import styles from './UpdatePassModal.module.scss';

function UpdatePassModal({ setShowPassModal }) {
	const dispatch = useDispatch();

	const [password, setPassword] = useState('');
	const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

	const [newPassword, setNewPassword] = useState('');
	const [newPasswordErrorMessage, setNewPasswordErrorMessage] = useState('');

	const submitHandler = async () => {
		const data = {
			old_pass: password,
			new_pass: newPassword
		};
		try {
			const res = await updatePassword(data);
			console.log(res);
			dispatch(
				addNotification({
					type: 'success',
					message: `Your password has been updated successfully!`
				})
			);
			setShowPassModal(false);
		} catch (err) {
			console.log(err);
			dispatch(
				addNotification({
					type: 'danger',
					message: err.response.data.message
				})
			);
		}
	};

	return (
		<>
			<Div
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.modalContainer
				)}>
				<Div className="pos-fix pos-fix--center card p2 w-per-90 max-w-px-500">
					<Close onClick={() => setShowPassModal(false)} />
					<Form onSubmit={submitHandler}>
						<TextBox
							labelText="Current Password"
							type="password"
							val={password}
							setVal={setPassword}
							errorHandler={setPasswordErrorMessage}
							name="password"
							errorMessage={passwordErrorMessage}
						/>
						<TextBox
							labelText="New Password"
							type="password"
							val={newPassword}
							setVal={setNewPassword}
							errorHandler={setNewPasswordErrorMessage}
							name="new_password"
							errorMessage={newPasswordErrorMessage}
						/>
						<Button>Submit</Button>
					</Form>
				</Div>
			</Div>
		</>
	);
}

export default UpdatePassModal;
