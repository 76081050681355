import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import { Router } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import history from 'utils/history';
import { getGtmTag } from 'utils/helpers';
import { Div } from 'basedesign-iswad';

// eslint-disable-next-line no-unused-vars
import 'basedesign-iswad/dist/styles.min.css';
import { styles } from 'troop-design'; // The global stylesheet
import './styles/global.scss';

import App from 'root/pages/App';
import reportWebVitals from './reportWebVitals';

const tagManagerArgs = {
	gtmId: getGtmTag()
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router history={history}>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
