import React, { useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Events from 'root/pages/BusinessAdmin/Events/Events';

import styles from './BusinessEvents.module.scss';

const BusinessEvents = () => {
	const params = useParams();

	const { business_id } = params;

	return (
		<>
			<Div>
				<Events business_id={business_id} />
			</Div>
		</>
	);
};

export default BusinessEvents;
