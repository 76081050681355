import React, { useEffect } from 'react';
import cx from 'classnames';
import { Anchor, Card, Icon } from 'troop-design';

import QuestionCard from './subs/QuestionCard';
import { items } from './subs/QuestionAnswer';

import styles from './EmployeeFAQ.module.scss';

const EmployeeFAQ = () => {
	let parser = new DOMParser();

	return (
		<>
			<div className={cx(styles.container)}>
				{items.map((item, idx) => {
					return (
						<QuestionCard
							question={item.question}
							answer={item.answer}
							key={idx}
						/>
					);
				})}
				{/* <Card className={cx(styles.questionAnswerContainer)}>
					<div className={cx(styles.question)}>
						I'm signed in. Now what?{' '}
						<div>
							<Icon icon="chevron-down" width={20} />
						</div>
					</div>
					<div className={cx(styles.answer)}>
						Once you sign in to your employee dashboard, the magic happens.
						You’ll see the three needs your team is voting on each month, you
						can track the need you voted for, plus pledge personal funds towards
						any needs you really want to see fulfilled. If a need gets enough
						pledges and hits the target (happy days!), we’ll get to work
						fulfilling it and share your impact through the impact report
						section.
					</div>
				</Card>
				<Card className={cx(styles.questionAnswerContainer)}>
					<div className={cx(styles.question)}>
						What happens if the need target I pledged for isn’t fulfilled?
						<div>
							<Icon icon="chevron-down" width={20} />
						</div>
					</div>
					<div className={cx(styles.answer)}>
						If the target isn’t hit, the funds will go back into your ‘do-good’
						account so you can pledge to fulfill another need in the future.
					</div>
				</Card>
				<Card className={cx(styles.questionAnswerContainer)}>
					<div className={cx(styles.question)}>
						Do I get a tax receipt?
						<div>
							<Icon icon="chevron-down" width={20} />
						</div>
					</div>
					<div className={cx(styles.answer)}>
						For need requests from registered charities, Yes! More details will
						follow on this, but you’ll be able to access one charitable tax
						receipt for all of the funds pledged to fulfill local needs for
						registered charities. This will be available in your dashboard.
					</div>
				</Card>
				<Card className={cx(styles.questionAnswerContainer)}>
					<div className={cx(styles.question)}>
						Does Troop charge a fee?
						<div>
							<Icon icon="chevron-down" width={20} />
						</div>
					</div>
					<div className={cx(styles.answer)}>
						Your company pays the Troop fee for their employees. 100% of your
						contribution will go towards fulfilling the need, less a credit card
						payment processing fee of 2.5%.{' '}
					</div>
				</Card>
				<Card className={cx(styles.questionAnswerContainer)}>
					<div className={cx(styles.question)}>
						How do you find your needs?
						<div>
							<Icon icon="chevron-down" width={20} />
						</div>
					</div>
					<div className={cx(styles.answer)}>
						All of the needs on Troop come from registered charities, nonprofits
						or community organizations and fall into one of two categories:{' '}
						<br />
						<p className={cx(styles.numbered)}>
							1) a need for the charity/nonprofit/community organization (items
							that will benefit the organization or a large number of people the
							organization serves) e.g a dishwasher for a crisis centre, a
							stockpile of personal hygiene products for a youth home, etc.
						</p>
						<p className={cx(styles.numbered)}>
							2) a specific need for a person or family supported by the
							charity/nonprofit, e.g a newcomer mom in need of a baby stroller,
							a low-income family in need of a used bike for transportation,
							etc.
						</p>
						<p>(P.S. These are all examples of needs we’ve crushed!)</p>
						<p>
							We work directly with representatives from the
							charity/nonprofit/community organization to confirm the need,
							write up the story, post it on the site and then share it with the
							Troopers. That’s when the magic happens!
						</p>
						<p>
							For more information about how we find, pick, and vet our needs,
							visit{' '}
							<Anchor
								to="https://hitroop.com/faq/"
								target="_blank"
								className={styles.link}>
								https://hitroop.com/faq/
							</Anchor>
							.
						</p>
					</div>
				</Card>
				<Card className={cx(styles.questionAnswerContainer)}>
					<div className={cx(styles.question)}>
						Can we submit needs from charities we support?
						<div>
							<Icon icon="chevron-down" width={20} />
						</div>
					</div>
					<div className={cx(styles.answer)}>
						Yes, please! If you support a local charity that you know has a
						need, let the Troop team know about it. You can email them at
						hello@hitroop.com or get the charity to submit a need request form{' '}
						<Anchor
							to="https://hitroop.com/charity-needs/"
							target="_blank"
							className={styles.link}>
							here
						</Anchor>
						.
					</div>
				</Card>
				<Card className={cx(styles.questionAnswerContainer)}>
					<div className={cx(styles.question)}>
						How do I contact you if I run into an issue?
						<div>
							<Icon icon="chevron-down" width={20} />
						</div>
					</div>
					<div className={cx(styles.answer)}>
						By emailing us at hello@hitroop.com. If you encounter any problems
						or errors, or you have a question we haven't answered here, reach
						out and we’ll do our best to respond in a timely manner.
					</div>
				</Card>
				<Card className={cx(styles.questionAnswerContainer)}>
					<div className={cx(styles.question)}>
						How do I request a refund?
						<div>
							<Icon icon="chevron-down" width={20} />
						</div>
					</div>
					<div className={cx(styles.answer)}>
						You can request a refund by emailing us at hello@hitroop.com. We try
						to have all refund requests handled in 72 hours.
					</div>
				</Card> */}
			</div>
		</>
	);
};

export default EmployeeFAQ;
