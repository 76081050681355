import React, { useState, useRef, useEffect } from 'react';
import cx from 'classnames';
import { Div, Label, Input } from 'basedesign-iswad';

import { COLORS } from 'root/constants/vars';

import Icon from 'root/baseComponents/Icon';
import ImagePreview from './subs/ImagePreview';
import Cropper from './subs/Cropper';

import styles from './ImagePicker.module.scss';

const ImagePicker = ({
	labelText,
	isRequired,
	file,
	setFile,
	hasCropper = true,
	initialSrc = '',
	setInitialSrc = null,
	cropInfo,
	errorMessage,
	errorHandler,
	showDefaultPrev = true,
	placeHolderType = 1,
	placeHolderText = 'Add Image',
	accept = '.jpg,.jpeg,.png',
	className,
	useDefaultClassName = true,
	cropCompleted = false,
	setCropCompleted = null
}) => {
	const inputFileField = useRef();

	const [src, setSrc] = useState('');
	const [fileName, setFileName] = useState('');
	const [showCropper, setShowCropper] = useState(false);

	const fileChangeHandler = e => {
		if (e.target?.files?.[0]) {
			const localFile = e.target.files[0];
			setFile(localFile);
			setFileName(localFile?.name);
			setSrc(URL.createObjectURL(localFile));
			setShowCropper(true);
		}
	};

	useEffect(() => {
		if (initialSrc) {
			setSrc(initialSrc);
		}
	}, [initialSrc]);

	return (
		<>
			{hasCropper && showCropper ? (
				<Cropper
					src={src}
					setSrc={setSrc}
					setFile={setFile}
					fileName={fileName}
					setShowCropper={setShowCropper}
					cropInfo={cropInfo}
					cropCompleted={cropCompleted}
					setCropCompleted={setCropCompleted}
				/>
			) : (
				''
			)}
			<Div
				className={cx(
					useDefaultClassName && 'mainInputContainer',
					'pos-rel',
					className
				)}
				onClick={() => errorHandler('')}>
				{labelText && (
					<Div className={cx('labelForInputContainer')}>
						<Label className={cx(isRequired && 'required', 'labelForInput')}>
							{labelText}
						</Label>
					</Div>
				)}
				<Div
					type="flex"
					hAlign="start"
					vAlign="center"
					className={cx('inputFieldContainer')}>
					<Label>
						<Input
							type="file"
							onChange={fileChangeHandler}
							className="no-display"
							accept={accept}
							ref={el => (inputFileField.current = el)}
						/>
						{placeHolderType === 1 && (
							<Div
								type="flex"
								hAlign="center"
								vAlign="center"
								className={cx(styles.iconContainer)}
								color="#ccc">
								<Icon type="image-upload" color={COLORS.grayDark} scale={4} />
							</Div>
						)}
						{placeHolderType === 2 && (
							<Div
								type="flex"
								hAlign="center"
								vAlign="center"
								className={cx(
									'br-all-solid-2 br-color-primary p1 textPrimary br-rad-px-5 mouse-hand'
								)}>
								<Div>{placeHolderText}</Div>
							</Div>
						)}
					</Label>
					{src && showDefaultPrev ? (
						<ImagePreview
							src={src}
							setSrc={setSrc}
							setFile={setFile}
							setFileName={setFileName}
							inputFileField={inputFileField}
							setInitialSrc={setInitialSrc}
						/>
					) : (
						''
					)}
				</Div>
				<Div
					className={cx(
						'inputErrorMessage',
						errorMessage && 'inputErrorMessageIsActive'
					)}>
					{errorMessage}
				</Div>
			</Div>
		</>
	);
};

export default ImagePicker;
