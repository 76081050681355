// VENDOR imports
import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';

// style import
import styles from './Events.module.scss';

const tableData = [
	{
		event_name: 'personal hygine kit packing',
		date: '2023-01-15',
		status: 'close',
		business: 'MCAN'
	},
	{
		event_name: 'school kit packing',
		date: '2023-06-01',
		status: 'upcoming',
		business: 'MCAN'
	},
	{
		event_name: 'gift packing',
		date: '2023-06-03',
		status: 'upcoming',
		business: 'Home Trust'
	},
	{
		event_name: 'clothes kit packing',
		date: '2023-01-01',
		status: 'close',
		business: 'MCAN'
	}
];

const Events = () => {
	const history = useHistory();
	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const headLines = [
		{
			value: 'event_name',
			display: 'Events',
			width: 400,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'date',
			display: 'Date',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'status',
			display: 'Status',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'details',
			display: ' ',
			width: 200,
			hasSearch: false,
			isSortable: false
		}
	];

	const showDetails = event_name => {
		return (
			<Button
				isSmall={true}
				onClick={() => history.push(`/troop-admin/event-details`)}>
				{' '}
				More Details{' '}
			</Button>
		);
	};

	const data = useMemo(() => {
		let localData = [];
		tableData?.map(item => {
			localData.push({
				event_name: item.event_name,
				date: { value: item.date, display: item.date },
				status: item.status,
				details: showDetails(item.event_name)
			});
		});
		console.log(localData);
		return localData;
	}, []);

	return (
		<>
			<Div className={cx(styles.main)}>
				<Div className="employeeHeading mb3 mt2">Events</Div>
				<Div type="flex" hAlign="start" className="w-per-100 mb2">
					<Button
						className="mr2"
						onClick={() => history.push('/troop-admin/add-event-form')}>
						{' '}
						Add Event
					</Button>
					<Button> Delete Event</Button>
				</Div>

				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					tableWidth={'900px'}
					isSortable={true}
					isSelectable={true}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={5}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
};

export default Events;
