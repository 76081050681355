import React, { useState, useEffect } from 'react';
import Wrapper from 'root/pages/ManagementWrapper/Wrapper';
import { useHistory } from 'react-router-dom';
import { routes } from './routes';

import { getAllCharities } from 'root/services/charity';

const SuperAdminApp = ({ userFullyRegistered }) => {
	const history = useHistory();

	const [totalNeedsInReview, setTotalNeedsInReview] = useState(0);

	const getAllCharitiesData = async () => {
		try {
			const res = await getAllCharities();
			let localTotalNeedsInReview = 0;
			res.forEach(item => {
				localTotalNeedsInReview += item.needs_to_review;
			});
			setTotalNeedsInReview(localTotalNeedsInReview);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getAllCharitiesData();
	}, []);

	return (
		<Wrapper
			routes={routes}
			userFullyRegistered={userFullyRegistered}
			hasAlert={true}
			alertNumber={totalNeedsInReview}
			onAlertClick={() => history.push('/needs-to-review')}
		/>
	);
};

export default SuperAdminApp;
