import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';
import Form from 'root/baseComponents/Form';
import TextBox from 'root/baseComponents/TextBox';
import FormTextArea from 'root/baseComponents/FormTextArea';
import FormSelect from 'root/baseComponents/FormSelect';
import FormDatePicker from 'root/baseComponents/FormDatePicker';
import FormImagePicker from 'root/baseComponents/FormImagePicker';
import FormCheckBox from 'root/baseComponents/FormCheckBox';

import { getClientUrl } from 'root/utils/helpers';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { setModalType } from 'root/reducers/charityReducers/modalType';
import { setModalProps } from 'root/reducers/charityReducers/modalProps';

import { createNeed } from 'root/services/needs';
import { retrieveAllCharities, getSingleCharity } from 'root/services/charity';

import {
	NEED_TYPE_CHOICES,
	MESSAGE_TEXT_AREA_PLACE_HOLDER
} from '../constants';
import {
	nameValidators,
	subtitleValidators,
	communitySupportedValidators,
	targetValidators,
	shortDescriptionValidators,
	neighbourhoodValidators
} from '../validators';
import styles from '../AddNeed.module.scss';

function AddNeedForm({ directAdd = true }) {
	const history = useHistory();
	const dispatch = useDispatch();

	const [needId, setNeedId] = useState(0);

	const [name, setName] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');

	const [subtitle, setSubtitle] = useState('');
	const [subtitleErrorMessage, setSubtitleErrorMessage] = useState('');

	const [communitySupported, setCommunitySupported] = useState('');
	const [communitySupportedErrorMessage, setCommunitySupportedErrorMessage] =
		useState('');

	const [target, setTarget] = useState('');
	const [targetErrorMessage, setTargetErrorMessage] = useState('');

	const [shortDescription, setShortDescription] = useState('');
	const [shortDescriptionErrorMessage, setShortDescriptionErrorMessage] =
		useState('');

	const [needType, setNeedType] = useState('');
	const [needTypeErrorMessage, setNeedTypeErrorMessage] = useState('');

	const [neighbourhood, setNeighbourhood] = useState('');
	const [neighbourhoodErrorMessage, setNeighbourhoodErrorMessage] =
		useState('');

	const [expiryDate, setExpiryDate] = useState('');
	const [expiryDateErrorMessage, setExpiryDateErrorMessage] = useState('');

	const [coverPhoto, setCoverPhoto] = useState('');
	const [coverPhotoErrorMessage, setCoverPhotoErrorMessage] = useState('');

	const [businessDescription, setBusinessDescription] = useState('');
	const [businessDescriptionErrorMessage, setBusinessDescriptionErrorMessage] =
		useState('');

	const [curCharity, setCurCharity] = useState('');
	const [curCharityErrorMessage, setCurCharityErrorMessage] = useState('');

	const [oldCharityDescription, setOldCharityDescription] = useState('');
	const [oldCharityWebsite, setOldCharityWebsite] = useState('');

	const [charityDescription, setCharityDescription] = useState('');
	const [charityDescriptionErrorMessage, setCharityDescriptionErrorMessage] =
		useState('');

	const [charityWebsite, setCharityWebsite] = useState('');
	const [charityWebsiteErrorMessage, setCharityWebsiteErrorMessage] =
		useState('');

	const [charities, setCharities] = useState('');

	const retrieveAllCharitiesData = async () => {
		const res = await retrieveAllCharities();
		if (res?.length > 0) {
			const localCharities = [];
			res?.forEach(charity => {
				localCharities.push({
					value: charity?.id,
					shownText: charity?.name
				});
			});
			setCharities(localCharities);
		}
	};

	const getSingleCharityData = async () => {
		try {
			const res = await getSingleCharity(curCharity);
			setOldCharityDescription(res?.about_organization);
			setCharityDescription(res?.about_organization);
			setCharityWebsite(res?.website);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (curCharity) {
			getSingleCharityData();
		}
	}, [curCharity]);

	useEffect(() => {
		if (directAdd) {
			retrieveAllCharitiesData();
		}
	}, [directAdd]);

	const toBeValidatedFields = [
		{
			input_name: 'name',
			validators: nameValidators,
			errorMessageHandler: setNameErrorMessage
		},

		{
			input_name: 'community_supported',
			validators: communitySupportedValidators,
			errorMessageHandler: setCommunitySupportedErrorMessage
		},

		{
			input_name: 'target',
			validators: targetValidators,
			errorMessageHandler: setTargetErrorMessage
		},

		{
			input_name: 'short_description',
			validators: shortDescriptionValidators,
			errorMessageHandler: setShortDescriptionErrorMessage
		},

		{
			input_name: 'neighbourhood',
			validators: neighbourhoodValidators,
			errorMessageHandler: setNeighbourhoodErrorMessage
		}
	];

	const customValidations = () => {
		let validated = true;

		if (!needType?.length) {
			validated = false;
			setNeedTypeErrorMessage('Need Type is required');
		}

		if (directAdd && !curCharity) {
			validated = false;
			setCurCharityErrorMessage('Charity is required');
		}

		if (!coverPhoto) {
			setCoverPhotoErrorMessage('Photo is required');
		}

		return validated;
	};

	const submitHandler = async e => {
		if (customValidations()) {
			const formData = new FormData();
			formData.append('charity_id', curCharity);
			if (charityDescription && oldCharityDescription !== charityDescription) {
				formData.append('charity_description', charityDescription);
			}
			if (charityWebsite && oldCharityWebsite !== charityWebsite) {
				formData.append('charity_website', charityWebsite);
			}
			if (needId) {
				formData.append('need_id', needId);
			}
			formData.append('name', name);
			formData.append('value', target);
			formData.append('business_description', businessDescription);
			// formData.append('subtitle', subtitle);
			formData.append('need_type', needType);
			formData.append('short_description', shortDescription);
			formData.append('neighbourhood', neighbourhood);
			formData.append('community_supported', communitySupported);
			if (expiryDate) {
				formData.append('expiry_date', dayjs(expiryDate).format('YYYY-MM-DD'));
			}
			formData.append('image', coverPhoto);
			dispatch(setLoading());
			try {
				const res = await createNeed(formData);
				if (res?.id) {
					dispatch(setModalType('success'));
					dispatch(
						setModalProps({
							text: `Need has been submitted successfully, and is now live, click here to see it`,
							header: 'Need published!',
							anchorTo: `https://hitroop.com/needs/${res?.charity_slug}/tr-${
								res?.id
							} `
						})
					);
					history.push('/');
					dispatch(setLoadingComplete());
				} else {
					dispatch(setModalType('danger'));
					dispatch(
						setModalProps({
							text: `${res.message}`,
							header: 'Error!'
						})
					);
					dispatch(setLoadingComplete());
				}
			} catch (err) {
				dispatch(setLoadingComplete());
				dispatch(setModalType('danger'));
				dispatch(
					setModalProps({
						text: `${err}`,
						header: 'Error!'
					})
				);
				console.log(err);
			}
		}
	};

	const saveHandler = async e => {
		e.preventDefault();
		const formData = new FormData();
		formData.append('charity_id', curCharity);
		if (charityDescription && oldCharityDescription !== charityDescription) {
			formData.append('charity_description', charityDescription);
		}
		if (needId) {
			formData.append('need_id', needId);
		}
		formData.append('name', name);
		formData.append('is_draft', true);
		formData.append('business_description', businessDescription);
		// formData.append('subtitle', subtitle);
		if (target) {
			formData.append('value', target);
		}
		formData.append('need_type', needType);
		formData.append('short_description', shortDescription);
		formData.append('neighbourhood', neighbourhood);
		formData.append('community_supported', communitySupported);
		if (expiryDate) {
			formData.append('expiry_date', dayjs(expiryDate).format('YYYY-MM-DD'));
		}
		formData.append('image', coverPhoto);
		dispatch(setLoading());
		try {
			const res = await createNeed(formData);
			if (res?.id) {
				setNeedId(res.id);
				dispatch(setModalType('success'));
				dispatch(
					setModalProps({
						text: `Need has been saved.`,
						header: 'Need published!'
					})
				);
				dispatch(setLoadingComplete());
			} else {
				dispatch(setModalType('danger'));
				dispatch(
					setModalProps({
						text: `${res.message}`,
						header: 'Error!'
					})
				);
				dispatch(setLoadingComplete());
			}
		} catch (err) {
			dispatch(setLoadingComplete());
			dispatch(setModalType('danger'));
			dispatch(
				setModalProps({
					text: `${err}`,
					header: 'Error!'
				})
			);
			console.log(err);
		}
	};

	// ['need_registered_id', 'charity_id', 'charity_user_id', 'category', 'cause', 'status', 'city','province'];

	return (
		<>
			<Form
				toBeValidatedFields={toBeValidatedFields}
				className={cx(styles.form)}
				onSubmit={submitHandler}>
				<TextBox
					isRequired
					labelText="Name"
					type="text"
					val={name}
					setVal={setName}
					errorHandler={setNameErrorMessage}
					name="name"
					errorMessage={nameErrorMessage}
				/>
				<FormTextArea
					isRequired
					labelText="Business Description"
					placeHolder={MESSAGE_TEXT_AREA_PLACE_HOLDER}
					val={businessDescription}
					setVal={setBusinessDescription}
					errorMessage={businessDescriptionErrorMessage}
					errorHandler={setBusinessDescriptionErrorMessage}
					id="messageFieldHomePage"
				/>
				{/* <TextBox
					isRequired
					labelText="Subtitle"
					type="text"
					val={subtitle}
					setVal={setSubtitle}
					errorHandler={setSubtitleErrorMessage}
					name="subtitle"
					errorMessage={subtitleErrorMessage}
				/> */}
				<TextBox
					isRequired
					labelText="Community Supported"
					type="text"
					val={communitySupported}
					setVal={setCommunitySupported}
					errorHandler={setCommunitySupportedErrorMessage}
					name="community_supported"
					errorMessage={communitySupportedErrorMessage}
					inputClassName=""
				/>
				<TextBox
					isRequired
					labelText="Target"
					type="text"
					val={target}
					setVal={setTarget}
					errorHandler={setTargetErrorMessage}
					name="target"
					errorMessage={targetErrorMessage}
					inputClassName=""
					hasIcon={true}
					iconType="dollar"
					iconColor="gray"
				/>
				{directAdd && charities?.length ? (
					<FormSelect
						labelText="Charity"
						className={cx('mb4')}
						options={charities}
						fullWidth
						openOptionsDownWard={true}
						initialSelected={''}
						isRequired
						val={curCharity}
						setVal={setCurCharity}
						errorMessage={curCharityErrorMessage}
						errorHandler={setCurCharityErrorMessage}
					/>
				) : (
					''
				)}
				{curCharity && (
					<>
						<FormTextArea
							labelText="About Charity"
							placeHolder={'Information about charity'}
							val={charityDescription}
							setVal={setCharityDescription}
							errorMessage={charityDescriptionErrorMessage}
							errorHandler={setCharityDescriptionErrorMessage}
							id="charityDescriptionFieldHomePage"
						/>

						<TextBox
							labelText="Charity Website"
							type="text"
							val={charityWebsite}
							setVal={setCharityWebsite}
							errorHandler={setCharityWebsiteErrorMessage}
							name="charity_website"
							errorMessage={charityWebsiteErrorMessage}
							inputClassName=""
						/>
					</>
				)}
				<FormSelect
					labelText="Need Type"
					className={cx('mb4 w-px-300')}
					options={NEED_TYPE_CHOICES}
					fullWidth
					openOptionsDownWard={true}
					initialSelected={''}
					isRequired
					val={needType}
					setVal={setNeedType}
					errorMessage={needTypeErrorMessage}
					errorHandler={setNeedTypeErrorMessage}
				/>
				<TextBox
					isRequired
					labelText="Short Description"
					type="text"
					val={shortDescription}
					setVal={setShortDescription}
					errorHandler={setShortDescriptionErrorMessage}
					name="short_description"
					errorMessage={shortDescriptionErrorMessage}
					inputClassName=""
				/>
				<TextBox
					isRequired
					labelText="Neighbourhood"
					type="text"
					val={neighbourhood}
					setVal={setNeighbourhood}
					errorHandler={setNeighbourhoodErrorMessage}
					name="neighbourhood"
					errorMessage={neighbourhoodErrorMessage}
					inputClassName=""
				/>
				<FormDatePicker
					labelText="Expiry Date"
					chosenDate={expiryDate}
					setChosenDate={setExpiryDate}
					errorMessage={expiryDateErrorMessage}
					errorHandler={setExpiryDateErrorMessage}
					inputClassName={'max-w-px-200'}
					placeHolder="DD-MM-YYYY"
				/>
				<Div className="max-height-px-600">
					<FormImagePicker
						isRequired
						labelText="Image"
						file={coverPhoto}
						setFile={setCoverPhoto}
						errorMessage={coverPhotoErrorMessage}
						errorHandler={setCoverPhotoErrorMessage}
						id="coverPhotoCharityRegistrationPage"
						hasCropper={true}
						cropInfo={{ aspect: 1.5 }}
					/>
				</Div>
				<Div className={cx('my2 flex flex--jc--end')}>
					<Button>Submit</Button>
				</Div>
				<Div className={cx('my2 flex flex--jc--end')}>
					<Button onClick={saveHandler}>Save</Button>
				</Div>
			</Form>
		</>
	);
}

export default AddNeedForm;
