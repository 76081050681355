import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import FormTextArea from 'root/baseComponents/FormTextArea/FormTextArea';
import Form from 'root/baseComponents/Form/Form';
import Button from 'root/baseComponents/Button';

import { sendCharityFeedback } from 'root/services/charity';
import { setModalType } from 'root/reducers/charityReducers/modalType';
import { setModalProps } from 'root/reducers/charityReducers/modalProps';

import CharityWrapper from '../CharityWrapper/CharityWrapper';

import styles from './AdminFeedback.module.scss';

const AdminFeedback = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const [message, setMessage] = useState('');
	const [messageErrorMessage, setMessageErrorMessage] = useState('');

	const customValidations = () => {
		let validated = true;

		if (!message) {
			setMessageErrorMessage('You must send a message to us!');
			validated = false;
		}

		return validated;
	};

	const submitHandler = async () => {
		if (customValidations()) {
			const bodyData = { message };
			try {
				const res = await sendCharityFeedback(bodyData);
				dispatch(setModalType('success'));
				dispatch(
					setModalProps({
						text: 'Thank you for submitting your feedback.',
						header: 'Feedback submitted!'
					})
				);
				history.push('/');
			} catch (err) {
				console.log(err);
				dispatch(setModalType('danger'));
				dispatch(
					setModalProps({
						text: 'Something went wrong; please try again!',
						header: 'Error!'
					})
				);
			}
		}
	};

	return (
		<>
			<CharityWrapper pageName="Your Feedback">
				<Div type="flex" hAlign="center" className={cx(styles.main)}>
					<Div
						type="flex"
						hAlign="center"
						className="p2 w-per-100 max-w-px-500">
						<Form onSubmit={submitHandler}>
							<FormTextArea
								isRequired
								labelText="Message"
								placeHolder={
									'Let us know if you have any suggestions/concerns/issues'
								}
								val={message}
								setVal={setMessage}
								errorMessage={messageErrorMessage}
								errorHandler={setMessageErrorMessage}
								id="messageFieldCharityAdminFeedback"
							/>
							<Button disabled={!message?.length}>Submit</Button>
						</Form>
					</Div>
				</Div>
			</CharityWrapper>
		</>
	);
};

export default AdminFeedback;
