import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import cx from 'classnames';

import { getNeeds } from 'services/needs';
import NeedCard from './subs/NeedCard';

import styles from './ListPledges.module.scss';

const ListPledges = () => {
	const dispatch = useDispatch();
	const params = useParams();

	const businessId = params.businessId;

	const businessNeeds = useSelector(state => state.needs.businessNeeds);

	useEffect(async () => {
		dispatch(getNeeds(businessId));
	}, []);

	return (
		<>
			<div className={cx(styles.body)}>
				{businessNeeds.map(need => (
					<NeedCard need={need} businessId={businessId} />
				))}
			</div>
		</>
	);
};

export default ListPledges;
