import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['setActiveDashboard'] = (state, action) => action.payload;

const slice = createSlice({
	name: 'activeDashboardItem',
	initialState: 'Dashboard',
	reducers: reducerObject
});

export const { setActiveDashboard } = slice.actions;
export default slice.reducer;
