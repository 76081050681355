import React from 'react';
import cx from 'classnames';
import { Div, Card, Column, Row, Image } from 'basedesign-iswad';
import { useHistory } from 'react-router-dom';
import Button from 'root/baseComponents/Button';
import styles from './NeedCard.module.scss';
import Icon from 'root/baseComponents/Icon';

import { updateNeedRegisteredByCharity } from 'root/services/charity';

const NeedCard = ({
	needId,
	imageSrc,
	needName,
	needRecipient = null,
	target,
	closeDate,
	status,
	created_by,
	isTroopAdmin = false,
	isInDashboard = false
}) => {
	const history = useHistory();
	const defineNeedStatus = status => {
		if (status === 'in_review') {
			return (
				<Div type="flex" distributedBetween className="w-per-100 pr3">
					{!isTroopAdmin ? (
						<Div type="flex" className="textPrimary">
							Status: In Review
						</Div>
					) : (
						<Div type="flex">
							<Div className="mr2">
								<Button
									onClick={() => {
										try {
											updateNeedRegisteredByCharity(needId, {
												status: 'approved'
											});
											history.push('/needs-to-review');
										} catch (err) {
											console.log(err);
										}
									}}
									colorType="success"
									type="flex"
									className="textPrimary">
									Approve
								</Button>
							</Div>
							<Div>
								<Button
									onClick={() => {
										try {
											updateNeedRegisteredByCharity(needId, {
												status: 'not_accepted'
											});
											history.push('/needs-to-review');
										} catch (err) {
											console.log(err);
										}
									}}
									colorType="cancel"
									type="flex"
									className="textPrimary">
									Decline
								</Button>
							</Div>
						</Div>
					)}
					<Button
						className="max-height-px-20 fb w-px-90 flex flex--ai--center flex--jc--between"
						onClick={() => {
							if (!isTroopAdmin) {
								history.push(`/edit_need/${needId}`);
							} else {
								history.push(`/edit-need-to-review/${needId}`);
							}
						}}>
						Edit <Icon type="pencil" color="white" className="ml2" />
					</Button>
				</Div>
			);
		} else if (status === 'approved') {
			return (
				<Div type="flex" distributedBetween className="w-per-100 pr3">
					{!isTroopAdmin ? (
						<Div type="flex" className="textPrimary">
							Status: Approved
						</Div>
					) : (
						<Div type="flex">
							<Div className="mr2">
								<Button
									onClick={() => {
										history.push(`/writer-publish-registered-need/${needId}`);
									}}
									colorType="success"
									type="flex"
									className="textPrimary">
									View and Publish
								</Button>
							</Div>
						</Div>
					)}
				</Div>
			);
		}
		// else if (status === 'active') {
		// 	if (!isTroopAdmin) {
		// 		return (
		// 			<Div type="flex" className="textPrimary">
		// 				Status: In Pledging
		// 			</Div>
		// 		);
		// 	} else {
		// 		return (
		// 			<Button
		// 				onClick={() => {
		// 					try {
		// 						updateNeedRegisteredByCharity(needId, {
		// 							status: 'chosen_to_fulfill'
		// 						});
		// 						history.push('/needs-to-review');
		// 					} catch (err) {
		// 						console.log(err);
		// 					}
		// 				}}
		// 				colorType="success"
		// 				type="flex"
		// 				className="textPrimary">
		// 				{'Move to the next step'}
		// 			</Button>
		// 		);
		// 	}
		// } else if (status === 'chosen_to_fulfill') {
		// 	if (!isTroopAdmin) {
		// 		return (
		// 			<Div type="flex" className="textPrimary">
		// 				Status: Processing
		// 			</Div>
		// 		);
		// 	} else {
		// 		return (
		// 			<Button
		// 				onClick={() => {
		// 					try {
		// 						updateNeedRegisteredByCharity(needId, {
		// 							status: 'waiting_for_feedback'
		// 						});
		// 						history.push('/needs-to-review');
		// 					} catch (err) {
		// 						console.log(err);
		// 					}
		// 				}}
		// 				colorType="success"
		// 				type="flex"
		// 				className="textPrimary">
		// 				{'Move to the next step'}
		// 			</Button>
		// 		);
		// 	}
		// } else if (status === 'close') {
		// 	if (!isTroopAdmin) {
		// 		if (!isInDashboard) {
		// 			return (
		// 				<Div type="flex" className="textPrimary">
		// 					Status: Closed
		// 				</Div>
		// 			);
		// 		} else {
		// 			return (
		// 				<Div type="flex" className="textPrimary">
		// 					Status: Feedback sent!
		// 				</Div>
		// 			);
		// 		}
		// 	}
		else if (status === 'feedback_sent') {
			return (
				<Div type="flex" className="textPrimary">
					<Button onClick={() => history.push(`/view-need-feedback/${needId}`)}>
						View Feedback
					</Button>
				</Div>
			);
		}
		// } else if (status === 'waiting_for_feedback') {
		// 	if (!isTroopAdmin) {
		// 		return (
		// 			<Button
		// 				onClick={() => history.push(`/send-feedback/${needId}`)}
		// 				className="max-height-px-20 flex flex--jc--center flex--ai--center">
		// 				Send Thanks!
		// 			</Button>
		// 		);
		// 	} else {
		// 		return (
		// 			<Div type="flex" className="textPrimary">
		// 				Status: Waiting to receive feedback!
		// 			</Div>
		// 		);
		// 	}
		// }
	};
	return (
		<>
			{/* min-w-px-300 max-w-px-600   */}
			<Card
				className={cx(
					'max-height-px-250 mx3 flex flex--jc--center boxShadowType1 p1 bgWhite card br-rad-px-5 ',
					styles.main
				)}>
				<Row className={cx(styles.needCard)}>
					<Column
						xs={6}
						sm={4}
						md={4}
						lg={4}
						className="flex--jc--center flex--ai--center">
						<Div className={cx(styles.imageContainer)}>
							<Image src={imageSrc} className={cx(styles.image)} />{' '}
						</Div>
					</Column>
					<Column xs={6} sm={8} md={8} lg={8} className="">
						<Div
							type="flex"
							direction="vertical"
							vAlign="center"
							className="p1 ml2 fs-px-14">
							<Div className="f-b mb1">{needName}</Div>
							<Div className="mb1"> Target: ${target} </Div>
							<Div className="mb1">Close date: {closeDate} </Div>
							{needRecipient && (
								<Div className="mb1">Recipient: {needRecipient} </Div>
							)}
							{created_by && (
								<Div className="mb1 oneLine">Submitted by: {created_by} </Div>
							)}
							<Div type="flex">{defineNeedStatus(status)}</Div>
						</Div>
					</Column>
				</Row>
			</Card>
		</>
	);
};

export default NeedCard;
