import { createSlice } from '@reduxjs/toolkit';

const reducerObject = {};
reducerObject['loggedInToVote'] = (state, action) => true;
reducerObject['notLoggedInToVote'] = (state, action) => false;

const slice = createSlice({
	name: 'isLoggedInToVote',
	initialState: false,
	reducers: reducerObject
});

export const { loggedInToVote, notLoggedInToVote } = slice.actions;
export default slice.reducer;
