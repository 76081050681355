import React, { useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import PageContainer from '../components/PageContainer';

import CharityAdminSignup from 'root/components/CharityAdminSignup/CharityAdminSignup';

import styles from './CharityAdminRegistration.module.scss';

const CharityAdminRegistration = () => {
	const params = useParams();
	const { uuid } = params;

	return (
		<>
			<PageContainer hasHeader={false} hasFooter={false}>
				<CharityAdminSignup uuid={uuid} />
			</PageContainer>
		</>
	);
};

export default CharityAdminRegistration;
