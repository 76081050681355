import React, { useState } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import styles from '../Employees.module.scss';

const Toggler = ({ activeTab, setActiveTab }) => {
	return (
		<>
			<Div type="flex" vAlign="center">
				<Div
					type="flex"
					hAlign="start"
					className={cx(
						'w-px-50 height-px-20 br-rad-px-10 of-hidden mr1 mouse-hand',
						styles.toggler
					)}
					onClick={() => {
						if (activeTab === 'Employees') {
							setActiveTab('Customers');
						} else {
							setActiveTab('Employees');
						}
					}}>
					<Div
						className={cx(
							'w-px-20 height-px-20 bgPrimary',
							styles.togglerBtn,
							activeTab === 'Customers' && styles.togglerBtnIsOnCustomer
						)}
					/>
				</Div>
				<Div>
					{activeTab === 'Employees' ? 'Employee List' : 'Customer List'}
				</Div>
			</Div>
		</>
	);
};

export default Toggler;
