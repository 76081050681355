import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';

import { retrieveAllCharities } from 'root/services/charity';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

function ListAllCharities() {
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [charities, setCharities] = useState([]);

	const retrieveAllCharitiesData = async () => {
		dispatch(setLoading());
		try {
			const res = await retrieveAllCharities();
			dispatch(setLoadingComplete());
			const localCharities = [];
			res.forEach(item => {
				localCharities.push({
					name: item.name,
					id: item.id,
					registered_name: item.registered_name,
					registered_number: item.registered_charity_number
				});
			});
			setCharities(localCharities);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		retrieveAllCharitiesData();
	}, []);

	const headLines = [
		{
			value: 'name',
			display: 'Name',
			width: 300,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'id',
			display: 'Id',
			width: 100,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'registered_name',
			display: 'Registered Name',
			width: 400,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'registered_number',
			display: 'Registered Number',
			width: 300,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		if (charities) {
			const tableData = [];
			charities.forEach(item => {
				tableData.push({
					name: item.name,
					id: item.id,
					registered_name: item.registered_name,
					registered_number: item.registered_number
				});
			});

			return tableData;
		} else {
			return [];
		}
	}, [charities]);

	return (
		<>
			<Div className="w-per-80 pr2 pl2 flex flex--jc--start ">
				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					tableWidth={'1000px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={10}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
}

export default ListAllCharities;
