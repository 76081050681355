import React from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import styles from '../Footer.module.scss';

const CopyRightSection = () => {
	return (
		<>
			<Div
				showIn={['md', 'lg']}
				type="flex"
				hAlign="start"
				vAlign="center"
				className={cx('textGray fs-px-12 p1', styles.footerCopyRightContainer)}>
				<Div>© {dayjs(new Date()).format('YYYY')} | ALL RIGHTS RESERVED</Div>
			</Div>
			<Div
				showIn={['xs', 'sm']}
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'textGray fs-px-12 p1',
					styles.footerCopyRightContainerMobile
				)}>
				<Div>© {dayjs(new Date()).format('YYYY')} | ALL RIGHTS RESERVED</Div>
			</Div>
		</>
	);
};

export default CopyRightSection;
