import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const modalSlice = createSlice({
	name: 'employees',
	initialState,
	reducers: {
		showModal: (state, action) => {
			state[action.payload] = true;
		},
		hideModal: (state, action) => {
			state[action.payload] = false;
		}
	}
});

export default modalSlice.reducer;
export const { showModal, hideModal } = modalSlice.actions;
