import React from 'react';
import cx from 'classnames';
import { Div, Row, Column } from 'basedesign-iswad';

import AboutSection from './subs/AboutSection';
import LinksSection from './subs/LinksSection';
import SubscribeSection from './subs/SubscribeSection';
// import ServicesSection from './subs/ServicesSection';
// import ContactSection from './subs/ContactSection';
import CopyRightSection from './subs/CopyRightSection';
import styles from './Footer.module.scss';

const Footer = () => {
	return (
		<>
			<Div className={cx('boxShadowType1 of-hidden', styles.footerContainer)}>
				<Div className="">
					<Row>
						<Column xs={12} sm={12} md={6} lg={6} className="textWhite px2">
							<AboutSection />
						</Column>
						<Column xs={12} sm={12} md={6} lg={6} className="textWhite px2">
							<LinksSection />
						</Column>
						{/* <Column xs={12} sm={12} md={4} lg={4} className="textWhite px2">
							<SubscribeSection />
						</Column> */}
					</Row>
				</Div>
				<CopyRightSection />
			</Div>
		</>
	);
};

export default Footer;
