// VENDOR imports
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import useLoggedIn from 'hooks/useLoggedIn';
import useSuperAdminLoggedIn from 'hooks/useSuperAdminLoggedIn';
import { validateUser } from 'root/services/user';

const SuperAdminRoute = props => {
	const location = useLocation();
	const dispatch = useDispatch();
	const isSuperAdmin = useSuperAdminLoggedIn();
	const loggedIn = useLoggedIn();

	useEffect(() => {
		if (!loggedIn) {
			dispatch(validateUser(location));
		}
	}, [dispatch, validateUser, loggedIn]);

	return isSuperAdmin ? (
		<props.Component />
	) : (
		<Redirect to={{ pathname: '/404', state: { from: location } }} />
	);
};

export default SuperAdminRoute;
