import React, { useMemo } from 'react';
import cx from 'classnames';
import styles from './PledgeProgressBar.module.scss';

export default function PledgeProgressBar({
	completedVariant,
	incompleteVariant,
	max,
	currentValue,
	numberOfPledges
}) {
	const completedWidth = useMemo(() => {
		const level = (currentValue / max) * 100;
		return `${level}%`;
	}, [currentValue, max]);

	const incompleteWidth = useMemo(() => {
		const level = ((max - currentValue) / max) * 100;
		return `${level}%`;
	}, [currentValue, max]);

	return (
		<div className={styles.progressBarContainer}>
			<div>
				<strong>{`$${currentValue.toFixed(2)}`}</strong>
				<span>{` of $${max.toFixed(2)} target`}</span>
			</div>
			<div className={styles.progressBar}>
				<div
					className={cx(
						styles.progress,
						styles[`completedProgressBar-${completedVariant}`]
					)}
					style={{ width: completedWidth }}></div>
				<div
					className={cx(
						styles.progress,
						styles[`incompleteProgressBar-${incompleteVariant}`],
						cx(styles[`incompleteProgressBarBorder-${completedVariant}`])
					)}
					style={{ width: incompleteWidth }}></div>
			</div>
			<div>
				<strong>{numberOfPledges}</strong> Troopers Pledged
			</div>
		</div>
	);
}
