import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { Heading, Table, Anchor, Row, Col, Button, Text } from 'troop-design';

import Modal from 'components/Modal';
import VotePage from 'components/VotePage/VotePage';
import useFindBusiness from 'root/hooks/useFindBusiness';
import { showModal, hideModal } from 'reducers/modals';
import { getLandingPage, deleteLandingPage } from 'services/landingPages';

import styles from './FutureVoteLandingPage.module.scss';

export default function FutureVoteLandingPage() {
	const dispatch = useDispatch();
	const params = useParams();
	const businessId = parseInt(params.businessId, 10);
	const business = useFindBusiness({ id: businessId });
	const businessNeeds = useSelector(state => state.needs.businessNeeds);
	const needs = useSelector(state => state.needs.needs);

	const nextMonth = dayjs().add(1, 'month').format('YYYY-MM-DD');

	const landingPage = useMemo(() => {
		return {
			monthDate: businessNeeds?.[0]?.monthDate,
			heading: businessNeeds?.[0]?.vote_page_title,
			bodyText: businessNeeds?.[0]?.vote_page_description,
			voteOpenedDate: businessNeeds?.[0]?.vote_opened_date,
			voteClosedDate: businessNeeds?.[0]?.vote_close_date,
			needs: businessNeeds.map(bn => {
				return {
					...needs.find(n => n.id === bn.need),
					need_id: bn.need,
					id: bn.id
				};
			})
		};
	}, [needs, businessNeeds]);

	const landingPageExists = useMemo(() => {
		return (
			landingPage?.heading ||
			landingPage?.bodyText ||
			landingPage?.voteClosedDate ||
			landingPage?.voteOpenedDate ||
			landingPage?.needs.length > 0
		);
	});

	const deleteLandingPageAction = useCallback(() => {
		deleteLandingPage(businessId, nextMonth);
		dispatch(hideModal('deleteLandingPage'));
	}, [dispatch]);

	useEffect(() => {
		dispatch(getLandingPage(businessId, nextMonth));
	}, [dispatch]);

	return landingPageExists ? (
		<>
			<Row className={'mt-5'}>
				<Col xs={12} md={8} className="d-flex">
					<Heading level={1} className="mt-0">
						{`${business?.name}`} - Vote Landing Page -{' '}
						{`${dayjs(nextMonth).format('MMMM YYYY')}`}
					</Heading>
				</Col>
				<Col className={styles.futureReportsDeleteContainer} xs={12} md={4}>
					<Button
						variant="danger"
						className={'me-4'}
						onClick={() => dispatch(showModal('deleteLandingPage'))}>
						Delete
					</Button>
				</Col>
			</Row>
			<VotePage
				bodyText={landingPage?.bodyText}
				title={landingPage?.heading}
				needs={landingPage?.needs}
				voteClosedDate={landingPage?.voteClosedDate}
				logo={business?.logo}
				onVote={() => {}}
				hasVoted={false}
			/>
			<Modal reduxKey="deleteLandingPage" size="md">
				<Heading level={2}>Confirm Delete</Heading>
				<Text tag="p" hasWrapper>
					{`Delete landing page?`}
				</Text>
				<Button
					className={'mx-2'}
					variant="primary"
					outline
					onClick={deleteLandingPageAction}>
					Yes
				</Button>
				<Button
					className={'mx-2'}
					variant="primary"
					outline
					onClick={() => dispatch(hideModal('deleteLandingPage'))}>
					No
				</Button>
			</Modal>
		</>
	) : (
		<Heading level={1} className="mt-0">
			{`${business?.name}`} - Vote Landing Page Does Not Exist -{' '}
			{`${dayjs(nextMonth).format('MMMM YYYY')}`}
		</Heading>
	);
}
