import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Spinner } from 'troop-design';
import cx from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { get, set, del } from 'utils/storage';
import CheckoutForm from './subs/CheckoutForm';
import { createEmployeeCheckout } from 'services/employees';
import { getStripePublishableApiKey } from 'utils/helpers';
import { addNotification } from 'root/reducers/notifications';

import { getEnv } from 'utils/helpers';

import styles from './EmployeeCheckout.module.scss';

const STRIPE_PUBLISHABLE_API_KEY = getStripePublishableApiKey();
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_API_KEY);

export default function EmployeeCheckout() {
	const dispatch = useDispatch();
	const history = useHistory();
	const geeneesNeed = useSelector(state => state.selectedGeeneesWish);
	const location = useLocation();

	const [clientSecret, setClientSecret] = useState('');
	const [amount, setAmount] = useState(0);
	const [paymentId, setPaymentId] = useState('');
	const [isGeeneesNeed, setIsGeeneesNeed] = useState(false);
	const [directAddFund, setDirectAddFund] = useState(false);

	useEffect(() => {
		console.log(geeneesNeed);
	}, [geeneesNeed]);

	useEffect(() => {
		const createCheckOut = async () => {
			if (location.state.doGoodAmount) {
				if (location.state?.isGeeneesNeed) {
					setIsGeeneesNeed(true);
				}
				try {
					setAmount(location.state.doGoodAmount);
					let res;
					if (location.state?.isGeeneesNeed) {
						res = await createEmployeeCheckout({
							amount: location.state.doGoodAmount,
							isGeeneesNeed: true,
							metadata: {
								total_transferable_amount: location.state.doGoodAmount,
								wish_1: geeneesNeed.identifier,
								total_spent_money: geeneesNeed.price.total,
								item_price: geeneesNeed.price.item_price,
								tax_price: geeneesNeed.price.taxes_and_delivery,
								processing_fee: geeneesNeed.price.processing_fee,
								supported_organization: geeneesNeed.organization
							}
						});
					} else {
						if (location.state.direct_add_fund) {
							setDirectAddFund(true);
							res = await createEmployeeCheckout({
								amount: location.state.doGoodAmount,
								metadata: {
									direct_add_fund: true
								}
							});
						} else {
							res = await createEmployeeCheckout({
								amount: location.state.doGoodAmount,
								metadata: {
									direct_add_fund: false
								}
							});
						}
					}
					setClientSecret(res.clientSecret);
					setPaymentId(res.paymentId);
				} catch (err) {
					console.log(err);
					console.log(err.response.data.message);
					dispatch(
						addNotification({
							message: err.response.data.message,
							type: 'danger'
						})
					);
					history.push('/');
				}
			}
		};
		createCheckOut();
	}, [location.state]);

	const appearance = {
		theme: 'stripe'
	};
	const options = {
		clientSecret,
		appearance
	};

	return (
		<div className={cx(styles.main)}>
			{amount && clientSecret ? (
				<div className={cx(styles.container)}>
					<Elements options={options} stripe={stripePromise}>
						<CheckoutForm
							paymentId={paymentId}
							amount={amount}
							isGeeneesNeed={isGeeneesNeed}
							directAddFund={directAddFund}
						/>
					</Elements>
				</div>
			) : (
				<div className={cx(styles.loadingContainer)}>
					<Spinner type="fade" className="me-2" />
				</div>
			)}
		</div>
	);
}
