import React, { useEffect } from 'react';
import cx from 'classnames';
import { Div, TextArea as BaseTextArea, Label } from 'basedesign-iswad';

import styles from './FormTextArea.module.scss';

const TextArea = ({
	labelText,
	val,
	setVal,
	errorHandler,
	isRequired,
	errorMessage,
	onChange,
	className,
	hasDefaultOnChange = true,
	...props
}) => {
	return (
		<>
			<Div className={cx('mainInputContainer', className)}>
				{labelText && (
					<Div className={cx('labelForInputContainer')}>
						<Label className={cx(isRequired && 'required', 'labelForInput')}>
							{labelText}
						</Label>
					</Div>
				)}

				<Div className={cx('inputFieldContainer')}>
					<BaseTextArea
						className={cx('textAreaField')}
						value={val}
						onChange={e => {
							if (hasDefaultOnChange) {
								setVal(e.target.value);
								errorHandler('');
							}
							if (onChange) {
								onChange(e);
							}
						}}
						{...props}
					/>

					{errorMessage && (
						<Div
							className={cx(
								'iswad_input_errorMessage',
								errorMessage && 'iswad_input_errorMessage_active'
							)}>
							{errorMessage}
						</Div>
					)}
				</Div>
			</Div>
		</>
	);
};

export default TextArea;
