import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div, Row, Column } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button/Button';
import Table from 'root/baseComponents/Table';
import { CHARITY_MEMBER } from './constants';
import { getCharityMembers } from 'root/services/charity';

import CharityWrapper from '../CharityWrapper';
import styles from './Team.module.scss';

function Team() {
	const history = useHistory();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [members, setMembers] = useState([]);

	const getAllMembers = async () => {
		const res = await getCharityMembers();
		setMembers(res.data);
	};

	useEffect(() => {
		getAllMembers();
	}, []);

	const headLines = [
		{
			value: 'name',
			display: 'Name',
			width: 400,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'email',
			display: 'Email',
			width: 400,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		members?.forEach(item => {
			tableData.push({
				name: `${item.user.first_name} ${item.user.last_name}`,
				email: `${item.user.email}`
			});
		});
		console.log(tableData);
		return tableData;
	}, [members]);

	return (
		<>
			<CharityWrapper pageName="Team">
				<Div
					type="flex"
					direction="vertical"
					className={cx('pt3 pl2 pr2 w-per-100', styles.main)}>
					<Div
						type="flex"
						hAlign="center"
						className="mr3 w-per-100 pl4 pr4"
						distributedBetween>
						<Div>
							<Div className="employeeHeading mb2">Charity Team Members</Div>
						</Div>
						<Div
							type="flex"
							vAlign="center"
							hAlign="end"
							direction="horizontal"
							className="mb2">
							<Button
								onClick={() => history.push(`/charity-add-member`)}
								className="w-px-100 flex flex--jc--center flex--ai--center max-height-px-30">
								Add
							</Button>
						</Div>
					</Div>
					<Div
						className={cx(
							'br-rad-px-5 pt2 pb2 pl1 pr1 ml-auto mr-auto w-per-100',
							styles.tableContainer
						)}>
						<Table
							headLines={headLines}
							data={data}
							colWidth={400}
							// tableWidth={'100%'}
							isSortable={true}
							isSelectable={false}
							selectedData={selectedData}
							setSelectedData={setSelectedData}
							sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
							rowsPerPage={9}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							showDefaultPagination={true}
							numberOfShownPages={3}
						/>
					</Div>
				</Div>
			</CharityWrapper>
		</>
	);
}

export default Team;
