export const PROVINCE_CHOICES = [
	{ value: 'AB', shownText: 'AB' },
	{ value: 'BC', shownText: 'BC' },
	{ value: 'MB', shownText: 'MB' },
	{ value: 'NB', shownText: 'NB' },
	{ value: 'NL', shownText: 'NL' },
	{ value: 'NT', shownText: 'NT' },
	{ value: 'NS', shownText: 'NS' },
	{ value: 'NU', shownText: 'NU' },
	{ value: 'ON', shownText: 'ON' },
	{ value: 'PE', shownText: 'PE' },
	{ value: 'QC', shownText: 'QC' },
	{ value: 'YT', shownText: 'YT' }
];

export const ORGANIZATION_TYPE_CHOICES = [
	{ value: 'charity', shownText: 'Charity' },
	{ value: 'school', shownText: 'School' },
	{ value: 'non_profit', shownText: 'Nonprofit' }
];

export const NEED_OWNER_CHOICES = [
	{ value: 'organization', shownText: 'Our Organization' },
	{ value: 'individual', shownText: 'An Individual' }
];

export const MESSAGE_TEXT_AREA_PLACE_HOLDER = `Story Details (any additional information you can provide that will
help us tell the story about this need to Troop members will help
increase the likelihood of fulfillment).`;
