import TroopDashboard from './TroopDashboard/TroopDashboard';
import Businesses from './Businesses/Businesses';
import BusinessDetails from './BusinessDetails/BusinessDetails';
import BuildVoteLandingPage from './BuildVoteLandingPage/BuildVoteLandingPage';
import FutureVoteLandingPage from './FutureVoteLandingPage/FutureVoteLandingPage';
import NeedsAdmin from './NeedsAdmin/index';
import FulfilledNeeds from './FulfilledNeeds/FulfilledNeeds';
import UnknownEmployeeVote from './UnknownEmployeeVote/UnknownEmployeeVote';
import FeatureFlags from './FeatureFlags';
import AddFeatureFlag from './FeatureFlags/AddFeatureFlag';
import { DesignSystem } from 'troop-design';
import DevLibrary from './DevLibrary/DevLibrary';
import Employees, { AddEmployee } from './Employees';
import ListPledges from './ListPledges';
import PledgeDetails from './PledgeDetails';
import UserActivities from './UserActivities';
import SlackLogs from './SlackLogs';
import TeamsLogs from './TeamsLogs';
import CreateCustomNewsFeed from './CreateCustomNewsFeed/index';
import NeedsToReview from './NeedsToReview/NeedsToReview';
import NeedsToReviewDetails from './NeedsToReviewDetails/NeedsToReviewDetails';
import EditNeed from './NeedsToReviewDetails/subs/EditNeed';
import ViewNeedFeedback from './ViewNeedFeedback/ViewNeedFeedback';
import ViewAllReceipts from './ViewAllReceipts/ViewAllReceipts';
import PurchasesAndSavings from './PurchasesAndSavings/PurchasesAndSavings';
import ListAllCharities from './ListAllCharities/ListAllCharities';
import EmailLogs from './EmailLogs/EmailLogs';
import Events from './Events/Events';
import AddEventForm from './Events/subs/AddEventForm';
import EventDetails from './Events/subs/EventDetails';
import ViewActiveVotes from './ViewActiveVotes/ViewActiveVotes';
import BusinessEvents from './BusinessEvents/BusinessEvents';
import SingleEvent from './SingleEvent/SingleEvent';
import NewRegisteredCharityAdmins from './NewRegisteredCharityAdmins/NewRegisteredCharityAdmins';
// import AddEmployee from '../SuperAdmin/Employees/AddEmployees';

export const routes = [
	{
		path: '/troop-dashboard',
		Component: TroopDashboard
	},
	{
		path: '/',
		Component: TroopDashboard
	},
	{
		path: '/businesses',
		Component: Businesses
	},
	{
		path: '/businesses/:businessId',
		Component: BusinessDetails
	},
	{
		path: '/businesses/:businessId/build-vote-landing-page',
		Component: BuildVoteLandingPage
	},
	{
		path: '/businesses/:businessId/view_active_votes',
		Component: ViewActiveVotes
	},
	{
		path: '/businesses/:businessId/future-vote-landing-pages',
		Component: FutureVoteLandingPage
	},
	{
		path: '/needs-admin',
		Component: NeedsAdmin
	},
	{
		path: '/admin-fulfilled-needs',
		Component: FulfilledNeeds
	},
	{
		path: '/businesses/:businessId/employees',
		Component: Employees
	},
	{
		path: '/businesses/:businessId/add-unknown-employee',
		Component: UnknownEmployeeVote
	},
	{
		path: '/feature-flags',
		Component: FeatureFlags
	},
	{
		path: '/feature-flags/add',
		Component: AddFeatureFlag
	},
	{
		path: '/feature-flags/:id/edit',
		Component: AddFeatureFlag
	},
	{
		path: '/design-system',
		Component: () => <DesignSystem showNav={false} />
	},
	{
		path: '/dev-library',
		Component: DevLibrary
	},
	{
		path: '/businesses/:businessId/list-pledges',
		Component: ListPledges
	},
	{
		path: '/businesses/:businessId/:needId/pledge-details',
		Component: PledgeDetails
	},
	{
		path: '/user-activity/:businessId/:businessUserId',
		Component: UserActivities
	},
	{
		path: '/slack-logs',
		Component: SlackLogs
	},
	{
		path: '/teams-logs',
		Component: TeamsLogs
	},
	{
		path: '/create-custom-news-item',
		Component: CreateCustomNewsFeed
	},
	{
		path: '/needs-to-review',
		Component: NeedsToReview
	},
	{
		path: '/needs-to-review/:id',
		Component: NeedsToReviewDetails
	},
	{
		path: '/edit-need-to-review/:id',
		Component: EditNeed
	},
	{
		path: '/view-need-feedback/:id',
		Component: ViewNeedFeedback
	},
	{
		path: '/view-all-receipts',
		Component: ViewAllReceipts
	},
	{
		path: '/purchases-and-savings',
		Component: PurchasesAndSavings
	},
	{
		path: '/view-all-charities',
		Component: ListAllCharities
	},
	{
		path: '/email-logs',
		Component: EmailLogs
	},
	{
		path: '/troop-admin/volunteer',
		Component: Events
	},
	{
		path: '/troop-admin/add-event-form',
		Component: AddEventForm
	},
	{
		path: '/:business_id/business-events',
		Component: BusinessEvents
	},
	{
		path: '/troop-admin/event-details',
		Component: EventDetails
	},
	{
		path: '/:business_id/business-single-event/:event_id/',
		Component: SingleEvent
	},
	{
		path: '/new-registered-charity-admins',
		Component: NewRegisteredCharityAdmins
	}
	// {
	// 	path: '/businesses/:businessId/add-employee',
	// 	Component: AddEmployee
	// }
].map(route => {
	return {
		...route,
		isSuperAdminOnly: true
	};
});
