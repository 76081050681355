// VENDOR imports
import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';
import EventFormForEvent from 'root/components/EventForm/EventFormForEvent';
import { showModal } from 'root/reducers/modals';

import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { getAllBusinessEvents } from 'root/services/event';

// style import
import styles from './Events.module.scss';

// const tableData = [
// 	{
// 		event_name: 'personal hygine kit packing',
// 		date: '2023-01-15',
// 		status: 'close',
// 		action: 'registered'
// 	},
// 	{
// 		event_name: 'school kit packing',
// 		date: '2023-06-01',
// 		status: 'upcoming',
// 		action: 'not-registered'
// 	},
// 	{
// 		event_name: 'gift packing',
// 		date: '2023-06-03',
// 		status: 'upcoming',
// 		action: 'registered'
// 	},
// 	{
// 		event_name: 'clothes kit packing',
// 		date: '2023-01-01',
// 		status: 'close',
// 		action: 'not-registered'
// 	}
// ];

const Events = () => {
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [tableData, setTableData] = useState([]);
	const [curEvent, setCurEvent] = useState({});

	const getAllBusinessEventsData = async () => {
		dispatch(setLoading());
		try {
			const res = await getAllBusinessEvents();
			dispatch(setLoadingComplete());
			if (res?.length) {
				const localTableData = [];
				res?.forEach(item => {
					localTableData.push({
						event_id: item.id,
						event_name: item.name,
						date: item.date,
						status: item?.register_status,
						curEvent: item
					});
				});
				setTableData(localTableData);
			}
		} catch (err) {
			console.log(err);
			dispatch(setLoadingComplete());
		}
	};

	useEffect(() => {
		getAllBusinessEventsData();
	}, []);

	const headLines = [
		{
			value: 'event_name',
			display: 'Event',
			width: 300,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'date',
			display: 'Date',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'status',
			display: 'Status',
			width: 200,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'details',
			display: 'Details',
			width: 200,
			hasSearch: false,
			isSortable: false
		}
	];

	const actionHandler = status => {
		if (status === 'upcoming') {
			return <Div> Upcoming </Div>;
		} else if (status === 'registered') {
			return <Div className=""> Registered ✅</Div>;
		} else {
			return <Div className=""> Completed </Div>;
		}
	};

	const data = useMemo(() => {
		if (tableData?.length) {
			const localData = [];
			tableData?.forEach(item => {
				localData.push({
					event_name: item.event_name,
					date: {
						value: item.date,
						display: dayjs(item.date).format('DD MMM YYYY')
					},
					status: {
						value: item.status,
						display: actionHandler(item.status)
					},
					details: {
						value: item.status,
						display: (
							<Button
								onClick={() => {
									setCurEvent(item?.curEvent);
									dispatch(showModal('event-form-for-event'));
								}}>
								View
							</Button>
						)
					}
				});
			});
			return localData;
		} else {
			return [];
		}
	}, [tableData]);

	return (
		<>
			<Div className={cx('p2', styles.main)}>
				<Div className="employeeHeading mb1 mt2 ml2">All Events</Div>
				<Div className="w-per-100 ml1 flex flex--jc--start pt2 of-x-auto scrollType1">
					<Table
						headLines={headLines}
						data={data}
						// colWidth={400}
						// tableWidth={'1000px'}
						isSortable={true}
						isSelectable={false}
						selectedData={selectedData}
						setSelectedData={setSelectedData}
						sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
						rowsPerPage={5}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						showDefaultPagination={true}
						numberOfShownPages={3}
					/>
				</Div>
			</Div>
			<EventFormForEvent curEvent={curEvent} />
		</>
	);
};

export default Events;
