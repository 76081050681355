import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getBusiness } from 'services/businesses';

export default function useFindBusiness({ id }) {
	const dispatch = useDispatch();
	const business = useSelector(state =>
		state.businesses.businesses.find(business => business.id === id)
	);

	useEffect(() => {
		if (!business && id) {
			dispatch(getBusiness(id));
		}
	}, [id]);

	return business;
}
