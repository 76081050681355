import React, { useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import GetConfirmationModal from 'root/baseComponents/GetConfirmationModal/GetConfirmationModal';
import {
	updateNeedByCharityAdmin,
	deleteNeedByCharityAdmin
} from 'root/services/needs';
import { deleteNeedRegisteredByCharity } from 'root/services/charity';

import VerticalNeedCard from 'root/baseComponents/NeedCard/VerticalNeedCard';
import VolunteerNeedCard from '../../Needs/subs/VolunteerNeedCard';

import styles from '../Dashboard.module.scss';

const NeedGrid = ({ needs }) => {
	const history = useHistory();

	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [confirmModalText, setConfirmModalText] = useState('');
	const [action, setAction] = useState({});

	return (
		<>
			{showConfirmModal && (
				<GetConfirmationModal
					text={confirmModalText}
					setShowModal={setShowConfirmModal}
					confirmationHandler={async () => {
						if (action?.action === 'delete') {
							let res = await deleteNeedByCharityAdmin(action.needId);
							history.go(0);
						}
						if (action?.action === 'reactivate') {
							let res = await updateNeedByCharityAdmin(action.needId, {
								status: 'open'
							});
							history.go(0);
						}
						if (action?.action === 'pause') {
							let res = await updateNeedByCharityAdmin(action.needId, {
								status: 'paused'
							});
							history.go(0);
						}
						if (action?.action === 'delete-registered-by-charity') {
							let res = await deleteNeedRegisteredByCharity(action.needId);
							history.go(0);
						}
					}}
				/>
			)}
			{needs?.map((item, idx) => {
				if (item?.is_volunteer_opportunity) {
					return (
						<Div key={idx} className="mx1">
							<VolunteerNeedCard
								need={item}
								showConfirmModal={showConfirmModal}
								setShowConfirmModal={setShowConfirmModal}
								setConfirmModalText={setConfirmModalText}
								setAction={setAction}
							/>
						</Div>
					);
				} else {
					return (
						<Div key={idx} className="mx1">
							<VerticalNeedCard
								need={item}
								status={
									item?.cur_status || item?.need?.cur_status || 'in_review'
								}
								showConfirmModal={showConfirmModal}
								setShowConfirmModal={setShowConfirmModal}
								setConfirmModalText={setConfirmModalText}
								setAction={setAction}
							/>
						</Div>
					);
				}
			})}
		</>
	);
};

export default NeedGrid;
