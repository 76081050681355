import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';

import { del } from 'utils/storage';

import { toggleUserGroups, setWasBusinessAdmin } from 'root/reducers/users';

import EmployeeVoteSection from 'root/components/EmployeeVoteSection';

import { notLoggedInToVote } from 'root/reducers/isLoggedInToVote';

import styles from './NeedsProfiles.module.scss';

const NeedsProfiles = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		del('loginToVote');
		dispatch(notLoggedInToVote());
	}, []);

	return (
		<>
			<div className={cx(styles.container)}>
				<EmployeeVoteSection />
			</div>
		</>
	);
};

export default NeedsProfiles;
