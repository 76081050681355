import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import useCurrentBusiness from 'hooks/useCurrentBusiness';
import useSuperAdminLoggedIn from 'root/hooks/useSuperAdminLoggedIn';
import styles from './AccountSettings.module.scss';
import { Alert, Tab, TabItem, TabContent, Icon } from 'troop-design';
import BusinessAdminForm from './components/BusinessAdminForm';
import TaxReceipts from './components/TaxReceipts';
import BusinessAdminTransactions from './components/BusinessAdminTransactions';
import { Div } from 'basedesign-iswad';

export default function AccountSettings() {
	const business = useCurrentBusiness();
	const user = useSelector(state => state.users.loggedInUser);
	const isSuperAdmin = useSuperAdminLoggedIn();

	const businessData = useMemo(() => {
		return {
			firstName: user?.first_name ? user.first_name : '',
			name: business?.name ? business.name : '',
			address: business?.address ? business.address : '',
			city: business?.city ? business.city : '',
			province: business?.province ? business.province : '',
			postalCode: business?.postal_code ? business.postal_code : '',
			phone: business?.phone ? business.phone : ''
		};
	}, [business, user]);

	if (isSuperAdmin) {
		return (
			<Alert type="danger" dismissable={false}>
				You are logged in as a super admin. You do not have access to this page.
			</Alert>
		);
	}

	return (
		<>
			<Tab openedTabIndex={0} tabColor="teal" iconStyle="horizontal">
				<TabItem>
					<Icon size="lg" icon="user" color="white" />
					Account Settings
				</TabItem>
				<TabItem>
					<Icon size="lg" icon="credit-card-billing" color="white" />
					Transactions
				</TabItem>
				<TabContent>
					<Div type="flex" direction="vertical" className="w-per-100">
						<Div className={'w-per-100 card pt1 pb1 pl2 pr2'}>
							<BusinessAdminForm
								business={business}
								businessData={businessData}
							/>
						</Div>
						<Div className="w-per-100 mt2 pb1 pt1 pl2 pr2">
							<TaxReceipts />
						</Div>
					</Div>
				</TabContent>
				<TabContent>
					<div className={styles.transactionsContainer}>
						<BusinessAdminTransactions business={business} />
					</div>
				</TabContent>
			</Tab>
		</>
	);
}
