import React, { useState, useEffect } from 'react';
import { Heading, Icon, Button, Card } from 'troop-design';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { checkIfUserAlreadyLoggedIn } from 'root/services/user';
import GoogleAuth from 'root/components/GoogleAuth';
import { showComponent } from 'root/reducers/voteCelebration';

import SignUpOrLogin from 'components/SignUpOrLogin';

import logo from 'assets/logo.png';

import styles from './ThanksAfterVote.module.scss';

const SignUpOrLoginItems = [
	'Fulfill local needs',
	'Track your company votes',
	'Read letters from the heart',
	'Get community updates'
];
const SignUpOrLoginIncentiveQuotes = [
	'Get the Ball Rolling!',
	'Signup to Vote & More!'
];

const ThanksAfterVote = ({ email, employeeKey }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const business = useSelector(state => state.businesses.businesses)?.[0];
	const voteCelebration = useSelector(state => state.voteCelebration);

	const [alreadyLoggedIn, setAlreadyLoggedIn] = useState(false);
	const [redirectUrl, setRedirectUrl] = useState(`/employee-register`);

	useEffect(() => {
		const checkUserStatus = async email => {
			try {
				const res = await checkIfUserAlreadyLoggedIn({ email });
				if (res?.already_logged_in) {
					setAlreadyLoggedIn(true);
					setRedirectUrl('/');
				}
			} catch (err) {
				console.log(err);
				setAlreadyLoggedIn(false);
			}
		};
		if (email) {
			checkUserStatus(email);
		}
	}, [email]);

	useEffect(() => {
		if (employeeKey) {
			setRedirectUrl(`/employee-register?employee=${employeeKey}`);
		}
	}, [employeeKey]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if (Object?.keys(voteCelebration['chosenNeed'])?.length) {
			dispatch(showComponent());
		}
	}, [voteCelebration]);

	return (
		<>
			{business?.has_employee_feature ? (
				<div className={cx(styles.mainContainer)}>
					<SignUpOrLogin
						isRegisterPage={true}
						ctaWord="Signup"
						useGoToVoteTheme={true}
						useRegisterTheme={false}
						incentiveQuotes={SignUpOrLoginIncentiveQuotes}
						items={SignUpOrLoginItems}
						isThanksAfterVote={true}
					/>
				</div>
			) : (
				<div
					className={cx(
						'container',
						'my-4',
						styles.containerNoEmployeeFeature
					)}>
					<p>
						Your vote has been successfully submitted, you can close this tab!
					</p>
				</div>
			)}
		</>
	);
};

export default ThanksAfterVote;
