import React, { useMemo, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import cx from 'classnames';
import PageHeader from 'components/PageHeader';
import LoggedInContent from 'components/LoggedInContent';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useLoggedIn from 'hooks/useLoggedIn';
import Notifications from 'root/components/Notifications/Notifications';
import { handleLogout } from 'root/services/user';
import useCurrentPage from 'root/hooks/useCurrentPage';
import Routes from 'root/components/Routes/Routes';
import packageFile from '../../../package.json';
import styles from './Wrapper.module.scss';
import { getCookie, setCookie } from 'root/utils/helpers';
import EmployeePledge from 'components/EmployeePladge';
import ThanksPayment from 'components/ThanksPayment';
import EmployeeShareSlack from 'components/EmployeeShareSlack';
import EmployeeShareTeams from 'components/EmployeeShareTeams';
import PledgeTutorial from 'components/PledgeTutorial';
import { hideModal, showModal } from 'root/reducers/modals';
import { get, set, del } from 'utils/storage';
import { getBusinessUserProfile, createUSerEvent } from 'services/user';
import { loggedInToVote } from 'root/reducers/isLoggedInToVote';
import ThanksPaymentGeenees from 'root/components/GeneesProduct/subs/ThanksPayment';
import { fulfillAGeeneesNeed } from 'root/services/geenees';
import {
	clearSelectedNeedToFulfill,
	paymentDone,
	setSelectedNeedToFulfill
} from 'root/reducers/geenees';
import { clearSelectedGeeneesWish } from 'root/reducers/selectedGeeneesWish';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { getUserReminders } from 'root/services/feedback';
import {
	setMustShowPledgePopUpModal,
	setMustshowAddressPopUpModal,
	setMustShowFeedbackModal
} from 'root/reducers/reminders';

import { Div } from 'basedesign-iswad';
import FeedbackModal from 'root/components/Reminders/FeedbackModal';
import PopUpModal from 'root/components/Reminders/PopUpModal';
import EventForm from 'root/components/EventForm';
import { getUpcomingEvent } from 'root/services/event';
import { setUpcomingEvent } from 'root/reducers/upcomingEvent';

export default function Wrapper({ routes }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const loggedInUser = useSelector(state => state.users.loggedInUser);
	const isLoggedInToVote = useSelector(state => state.isLoggedInToVote);
	const geenees = useSelector(state => state.geenees);
	const reminders = useSelector(state => state.reminders);

	const toBePledgedNeed = useSelector(state => state.needs.toBePledgedNeed);
	const userFullyRegistered = useSelector(
		state => state.users.userFullyRegistered
	);

	const [isDesktop, setIsDesktop] = useState(true);
	const [showThanksPayment, setShowThanksPayment] = useState(true);

	const getUpcomingEventData = async () => {
		const res = await getUpcomingEvent();
		console.log(res);
		if (res?.event_found) {
			dispatch(setUpcomingEvent({ ...res?.data }));
		}
	};

	useEffect(() => {
		if (window.innerWidth <= 900) {
			setIsDesktop(false);
		}
	}, []);

	// useEffect(() => {
	// 	dispatch(showModal('shareSlack'));
	// }, []);

	useEffect(() => {
		document.querySelector('body').classList.add('employee-page');

		return () => {
			document.querySelector('body').classList.remove('employee-page');
		};
	}, []);

	useEffect(() => {
		if (isLoggedInToVote) {
			history.push('needs-profiles');
		}
	}, [isLoggedInToVote]);

	useEffect(() => {
		if (get('loginToVote')) {
			dispatch(loggedInToVote());
		}
	}, []);

	const [showAddressModal, setShowAddressModal] = useState(false);
	const [showPleadgeModal, setShowPledgeModal] = useState(false);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);

	const getUserRemindersData = async () => {
		const res = await getUserReminders();
		if (res?.address_modal_must_be_shown) {
			dispatch(setMustshowAddressPopUpModal(true));
		} else {
			dispatch(setMustshowAddressPopUpModal(false));
		}
		if (res?.plege_modal_must_be_shown) {
			dispatch(setMustShowPledgePopUpModal(true));
		} else {
			dispatch(setMustShowPledgePopUpModal(false));
		}
		if (res?.get_feedback_modal_must_be_shown) {
			dispatch(setMustShowFeedbackModal(true));
		} else {
			dispatch(setMustShowFeedbackModal(false));
		}
	};

	useEffect(() => {
		getUserRemindersData();
	}, []);

	useEffect(() => {
		if (
			reminders?.mustShowAddressPopUpModal &&
			!reminders?.seenAddressModal &&
			!reminders?.mustShowPledgePopUpModal
		) {
			setShowAddressModal(true);
		} else {
			setShowAddressModal(false);
		}
		if (reminders?.mustShowPledgePopUpModal && !reminders?.seenPledgeModal) {
			setShowPledgeModal(true);
		} else {
			setShowPledgeModal(false);
		}
		if (reminders?.mustShowFeedbackModal && !reminders?.seenFeedbackModal) {
			setShowFeedbackModal(true);
		} else {
			setShowFeedbackModal(false);
		}
	}, [reminders]);

	const showNav = useSelector(state => state.general.showNav) !== false;
	const [navPanelOpened, setNavPanelOpened] = useState(true);
	const isLoggedIn = useLoggedIn();
	const currentPage = useCurrentPage();
	let showNavPanel = true;

	useEffect(() => {
		const navPanelSaved =
			getCookie('navPanelOpened') !== ''
				? getCookie('navPanelOpened') === 'true'
				: isDesktop;
		setNavPanelOpened(navPanelSaved);
	}, [isDesktop]);

	showNavPanel = showNavPanel && userFullyRegistered && showNav;

	const handleNavPanelToggle = e => {
		setCookie('navPanelOpened', e, 365);
		setNavPanelOpened(e);
	};

	const handleSignOut = e => {
		e.preventDefault();
		dispatch(handleLogout());
	};

	const sendHelpEmail = () => {
		document.location = `mailto:hello@HiTroop.com?subject=${'Troop Help Ticket'}`;
	};

	const showTutorialModal = () => {
		dispatch(showModal('pledgeTutorial'));
	};

	const headerData = useMemo(() => {
		return [
			{
				type: 'link',
				text: 'Account',
				link: '/employee-profile'
			},
			{
				type: 'link',
				text: 'FAQ',
				link: '/employee-faq'
			},
			{
				type: 'button',
				text: 'Help',
				onClick: sendHelpEmail
			},
			{
				type: 'button',
				text: 'Tutorial',
				onClick: showTutorialModal
			}
		];
	});

	const headerMobileData = useMemo(() => {
		return [
			{
				type: 'link',
				text: 'Account',
				link: '/employee-profile'
			},
			{
				type: 'link',
				text: 'FAQ',
				link: '/employee-faq'
			},
			{
				type: 'button',
				text: 'Help',
				onClick: sendHelpEmail
			}
		];
	});

	// useEffect(() => {
	// 	if (toBePledgedNeed?.id) {
	// 		dispatch(hideModal('thanksPayment'));
	// 		del('stripe_payment_status');
	// 		dispatch(showModal('pledgeNeed'));
	// 	}
	// }, [toBePledgedNeed]);

	const fulFillGeeneesNeed = async () => {
		const geeneesNeed = JSON.parse(get('geenees_need'));
		del('is_geenees_need');
		del('geenees_need');
		del('stripe_payment_status');
		if (geeneesNeed?.identifier) {
			const needData = {
				geenees_need_identifier: geeneesNeed?.identifier,
				geenees_need_value: geeneesNeed?.price?.total,
				geenees_need_item_fee: geeneesNeed?.price?.item_price,
				geenees_need_tax_fee: geeneesNeed?.price?.taxes_and_delivery,
				geenees_need_processing_fee: geeneesNeed?.price?.processing_fee,
				supported_organization: geeneesNeed?.organization,
				geenees_need_title: geeneesNeed?.title,
				use_do_good_account_fund: geeneesNeed?.use_do_good_account_fund
			};
			try {
				dispatch(setLoading());
				const needRes = await fulfillAGeeneesNeed(needData);
				dispatch(clearSelectedGeeneesWish());
				if (needRes?.success) {
					setShowThanksPayment(false);
					dispatch(setSelectedNeedToFulfill(geeneesNeed));
					dispatch(paymentDone());
				}
				dispatch(setLoadingComplete());
			} catch (err) {
				dispatch(clearSelectedNeedToFulfill());
				dispatch(setLoadingComplete());
				console.log(err);
			}
		}
	};

	useEffect(() => {
		const stripe_payment_status = get('stripe_payment_status');
		const isGeeneesNeed = Boolean(get('is_geenees_need'));
		if (stripe_payment_status === 'success') {
			if (!isGeeneesNeed) {
				dispatch(showModal('thanksPayment'));
				setShowThanksPayment(true);
			} else {
				// console.log('Test');
				fulFillGeeneesNeed();
			}
		}
	}, []);

	// useEffect(() => {
	// 	console.log(showThanksPayment);
	// }, [showThanksPayment]);

	const businessId = useSelector(state => state.businesses.businesses)?.[0]?.id;

	useEffect(() => {
		if (dispatch && businessId) {
			getBusinessUserProfile(dispatch, businessId);
		}
	}, [dispatch, businessId, toBePledgedNeed]);

	useEffect(() => {
		getUpcomingEventData();
	}, []);

	return (
		<>
			{showNavPanel && (
				<LoggedInContent>
					<Div showIn={['lg']}>
						<PageHeader
							navPanelOpened={navPanelOpened}
							onNavPanelOpened={handleNavPanelToggle}
							className={styles.siteHeader}
							onSignOut={handleSignOut}
							data={headerData}
							showReferFriend
							referFriendUrl="/employee/invite"
							referFriendText="Share Troop"
						/>
					</Div>

					<Div showIn={['md', 'sm', 'xs']}>
						<PageHeader
							navPanelOpened={navPanelOpened}
							onNavPanelOpened={handleNavPanelToggle}
							className={styles.siteHeader}
							onSignOut={handleSignOut}
							data={headerMobileData}
							showReferFriend
							referFriendUrl="/employee/invite"
							referFriendText="Share Troop"
						/>
					</Div>
				</LoggedInContent>
			)}
			<main
				className={cx(
					styles.pageContent,
					navPanelOpened && showNavPanel && styles.navOpened,
					styles[`page-${currentPage}`],
					!isLoggedIn && styles.notLoggedIn,
					!showNav && styles.noNavbar
				)}>
				{isLoggedIn && <Notifications />}
				<Routes userFullyRegistered={userFullyRegistered} routes={routes} />
				{/* <footer className={styles.footer}>
					<div className={styles.version}>
						<span>Troop ver. {packageFile.version}</span>
					</div>
				</footer> */}
				<div className={styles.footerVersion}>
					<span>Troop ver. {packageFile.version}</span>
				</div>
				{showAddressModal && !showPleadgeModal ? (
					<PopUpModal isAddressModal={true} />
				) : (
					''
				)}
				{showPleadgeModal ? <PopUpModal isAddressModal={false} /> : ''}
				{showFeedbackModal ? <FeedbackModal /> : ''}
				<Div showIn={['lg']}>
					<PledgeTutorial />
				</Div>
				<EmployeePledge need={toBePledgedNeed} />
				<EmployeeShareSlack />
				<EmployeeShareTeams />
				<EventForm />
				<ThanksPayment setShowThanksPayment={setShowThanksPayment} />
				{geenees?.selectedNeedToFulfill?.identifier &&
				geenees?.isPaymentDone ? (
					<ThanksPaymentGeenees />
				) : (
					''
				)}
			</main>
		</>
	);
}
