import React from 'react';
import cx from 'classnames';

import styles from './SliderDot.module.scss';

function SliderDot({ isActive = false }) {
	return (
		<>
			<div className={cx(styles.main, isActive && styles.mainActive)}></div>
		</>
	);
}

export default SliderDot;
