import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFlags } from 'services/featureFlags';

export default function useFeatureFlag(flag) {
	const dispatch = useDispatch();
	const flags = useSelector(state => state.featureFlags.flags);
	const isFlagEnabled = useSelector(
		state => !!state?.featureFlags?.flags?.[flag]
	);

	useEffect(() => {
		dispatch(getFlags());
	}, []);

	return isFlagEnabled;
}
