// VENDOR imports
import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

// Troop Design language import
import { Card, Row, Col, Line, Text, Spinner } from 'troop-design';
import { getAdminData } from 'services/user';
import useLoader from 'root/hooks/useLoader';
import { extractDataFromAdmin } from './utils/extractDataFromAdmin';

// styles import
import styles from './TroopDashboard.module.scss';

const TroopDashboard = () => {
	dayjs.extend(isSameOrBefore);
	const [totalBusinesses, setTotalBusinesses] = useState(0);
	const [totalEmployees, setTotalEmployees] = useState(0);
	const [mrr, setMrr] = useState(0);
	const [needContribution, setNeedContribution] = useState(0);
	const [avgEmployeeEngagement, setAvgEmployeeEngagement] = useState(0);
	const [dashboardLoader, getAdminDataPromise] = useLoader(
		'dashboardLoader',
		getAdminData
	);
	const [monthlyRevenueData, setMonthlyRevenueData] = useState({});
	const [minRange, setMinRange] = useState(0);
	const [maxRange, setMaxRange] = useState(100);
	useEffect(() => {
		getAdminDataPromise.then(data => {
			if (data === 'loading') {
				return;
			}
			extractDataFromAdmin(
				data,
				setTotalBusinesses,
				setTotalEmployees,
				setMrr,
				setNeedContribution,
				setAvgEmployeeEngagement
			);
			setMonthlyRevenueData(historicalReportData(data));
		});
	}, [getAdminDataPromise]);

	const period = dayjs().startOf('M').format('YYYY-MM');
	const historicalReportData = data => {
		let minVal = 0;
		let maxVal = 0;
		let monthlyFees = [];
		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach(value => {
			const report =
				data[dayjs(period).subtract(value, 'month').format('YYYY_MM')];
			if (report) {
				monthlyFees.push({
					x: dayjs(period).subtract(value, 'month').format('MMM'),
					Fees: report['sum_troop_revenue'],
					Impact: report['sum_need_contribution']
				});
				maxVal = Math.max(
					report['sum_troop_revenue'],
					report['sum_need_contribution'],
					maxVal
				);
				minVal = Math.min(
					report['sum_troop_revenue'],
					report['sum_need_contribution'],
					maxVal,
					minVal
				);
			}
		});
		setMaxRange(maxVal);
		setMinRange(minVal);
		return monthlyFees.reverse();
	};

	return (
		<>
			<Row>
				<Col className="d-flex ju" lg={4}>
					<Card className="w-100" variant="data" heading="Troop Monthly Fees">
						{dashboardLoader ? <Spinner type="bar" /> : `$${mrr}`}
					</Card>
				</Col>
				<Col className="d-flex ju" lg={4}>
					<Card
						className="w-100"
						variant="data"
						heading="Monthly Need Contribution">
						{dashboardLoader ? <Spinner type="bar" /> : `$${needContribution}`}
					</Card>
				</Col>
				<Col className="d-flex ju" lg={4}>
					<Card
						className="w-100"
						variant="data"
						heading="Avg Employee Engagement">
						{dashboardLoader ? (
							<Spinner type="bar" />
						) : (
							`${parseInt(avgEmployeeEngagement * 100)}%`
						)}
					</Card>
				</Col>
			</Row>
			<Row className="mt-2">
				<Col xs={12} lg={8}>
					<Card heading="Troop Monthly Fees">
						{dashboardLoader ? (
							<div className="d-flex justify-content-center align-items-center">
								<Spinner width={256} height={256} />
							</div>
						) : (
							<Line
								data={monthlyRevenueData}
								dataKeys={['Fees', 'Impact']}
								height={480}
								showPercentage={false}
								range={[minRange, maxRange]}
								showLegend
								strokeColors={['red', 'blue']}
								xLabel="Month"
								yLabel="Fees(CAD)"
							/>
						)}
					</Card>
				</Col>
				<Col lg={4}>
					<Card
						className={cx('w-100', styles.cardContainer)}
						variant="data"
						heading="Businesses">
						{dashboardLoader ? (
							<Spinner type="bar" />
						) : (
							<Text hasWrapper tag="span">
								{totalBusinesses}
							</Text>
						)}
					</Card>
					<Card
						className={cx('w-100', styles.cardContainer)}
						variant="data"
						heading="Employees">
						{dashboardLoader ? (
							<Spinner type="bar" />
						) : (
							<Text hasWrapper tag="span">
								{totalEmployees}
							</Text>
						)}
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default TroopDashboard;
