import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Div } from 'basedesign-iswad';

import SimpleModal from './subs/SimpleModal';
import ReferModal from './subs/ReferModal';
import DeleteEmployeeModal from './subs/DeleteEmployeeModal'
import EmployeeStatusModal from './subs/EmployeeStatusModal'
import CharitySignupModal from './subs/CharitySignupModal'
import RejectCharityAdminModal from './subs/RejectCharityAdminModal'
import AcceptCharityAdminModal from './subs/AcceptCharityAdminModal'
import styles from './Modal.module.scss'; 

const Modal = () => {
	const modalType = useSelector(state => state.modalType);
	const modalProps = useSelector(state => state.modalProps);
	return (
		<>
			{(modalType === 'danger' ||
				modalType === 'warning' ||
				modalType === 'email' ||
				modalType === 'info' ||
				modalType === 'success') && <SimpleModal {...modalProps} />}
			{modalType === 'refer' && <ReferModal {...modalProps} />}
			{modalType === 'delete-employee' && <DeleteEmployeeModal {...modalProps} />}
			{modalType === 'change-employee-status' && <EmployeeStatusModal {...modalProps} />}
			{modalType === 'charity-admin-signup' && <CharitySignupModal {...modalProps} />}
			{modalType === 'accept-charity-admin' && <AcceptCharityAdminModal {...modalProps} />}
			{modalType === 'reject-charity-admin' && <RejectCharityAdminModal {...modalProps} />}
		</>
	);
};

export default Modal;
