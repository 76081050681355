import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';

import { getBackendUrl } from 'root/utils/helpers';

import styles from './Card.module.scss';

const NeedCard = ({
	photoUrl,
	province,
	name,
	neighbourhood,
	city,
	value,
	charityName,
	charitySlug,
	shortDescription,
	slug,
	needId,
	is_fulfilled,
	...props
}) => {
	const history = useHistory();

	return (
		<>
			<Div
				type="flex"
				direction="vertical"
				className={cx('bgWhite w-px-350 p1 mouse-hand', styles.cardContainer)}
				{...props}
				onClick={() => {
					history.push(`/needs/${charitySlug}/tr-${needId}`);
					window.scrollTo(0, 0);
				}}>
				<Div className="w-per-100 mb2 pos-rel">
					<img
						src={photoUrl}
						className="w-per-100 height-px-200 object-fit-cover"
					/>
					{is_fulfilled && (
						<Div className={cx('pos-abs', styles.isFulfilledIconContainer)}>
							<Icon color="orange" type="circle-check" />
						</Div>
					)}
				</Div>
				<Div className="min-height-px-50 twoLine w-per-100 f-b text-center mb1">
					{name}
				</Div>
				<Div
					type="flex"
					direction="vertical"
					hAlign="end"
					className="w-per-100 fs-px-12 text-rtl textGray mb2 pr1">
					<Div className="oneLine w-per-100">{`${city}, ${province}`}</Div>
					<Div className="oneLine w-per-100">
						{shortDescription && value ? `${shortDescription} - $${value}` : ''}
						{shortDescription && !value ? `${shortDescription}` : ''}
						{!shortDescription && value ? `${value}` : ''}
					</Div>
					<Div className="oneLine w-per-100">{charityName}</Div>
				</Div>
			</Div>
		</>
	);
};

export default NeedCard;
