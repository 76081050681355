import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Div, Text as BaseText } from 'basedesign-iswad';

import styles from './Text.module.scss';

const Text = ({
	children,
	className,
	summerized_max_length = 150,
	...props
}) => {
	const [curText, setCurText] = useState('');
	const [showAbstractText, setshowAbstractText] = useState(true);
	const [displayShowMore, setDisplayShowMore] = useState(false);
	const [displayShowLess, setDisplayShowLess] = useState(false);
	const [noNeedToAbstract, setNoNeedToAbstract] = useState(false);

	useEffect(() => {
		setCurText('');
		setshowAbstractText(true);
		setDisplayShowMore(false);
		setDisplayShowLess(false);
		setNoNeedToAbstract(false);
	}, [children]);

	useEffect(() => {
		if (children?.length <= summerized_max_length) {
			setNoNeedToAbstract(true);
		} else {
			if (children?.length > summerized_max_length) {
				setDisplayShowMore(true);
			}

			if (children?.length > summerized_max_length && showAbstractText) {
				setCurText(children.slice(0, summerized_max_length));
			}

			if (!showAbstractText) {
				setCurText(children.slice(0));
			}
		}
		console.log(children);
	}, [children, showAbstractText]);

	return (
		<>
			{displayShowMore && showAbstractText ? (
				<Div className={cx(styles.text, className)} {...props}>
					{curText}
					<BaseText
						className={cx('ml1 mouse-hand', styles.showMore)}
						onClick={() => {
							setshowAbstractText(false);
							setDisplayShowLess(true);
							setDisplayShowMore(false);
						}}>
						{'Show More >>'}
					</BaseText>
				</Div>
			) : (
				''
			)}
			{displayShowLess && !showAbstractText ? (
				<Div className={cx(styles.text, className)} {...props}>
					{curText}
					<BaseText
						className={cx('ml1 mouse-hand', styles.showMore)}
						onClick={() => {
							setshowAbstractText(true);
							setDisplayShowLess(false);
							setDisplayShowMore(true);
						}}>
						{' << Show Less'}
					</BaseText>
				</Div>
			) : (
				''
			)}
			{noNeedToAbstract && children}
		</>
	);
};

export default Text;
