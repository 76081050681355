import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import PageContainer from '../components/PageContainer/PageContainer';

import Landing from './subs/Landing';
import Info from './subs/Info';
import AllNeeds from './subs/AllNeeds';
import FulfilledNeeds from './subs/FulfilledNeeds';
import Social from './subs/Social';
import styles from './Needs.module.scss';

const Needs = () => {
	return (
		<>
			<PageContainer>
				<Landing />
				<Info />
				<Div className="my8">
					<AllNeeds />
				</Div>
				<FulfilledNeeds />
				{/* <Social /> */}
			</PageContainer>
		</>
	);
};

export default Needs;
