import axios from 'axios';
import { getCookie, getBackendUrl } from 'utils/helpers';
import { handleRenewAccessToken } from 'services/user';

function applyLoginHeaders(options) {
	const accessToken = getCookie('accessToken');
	const headers = {};
	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`;
	}
	options.headers = { ...options.headers, ...headers };
}

/**
 * Given the response from the server, we check if the status is timed out session,
 * if so, then we send the refresh token to receive the new token
 * @param {Promise} response The response promise
 * @returns Promise
 */
function handleRequest(axiosCall, requestOptions) {
	return axiosCall
		.catch(error => {
			// This implies that the session timed out
			if (error.response?.status === 401 && getCookie('accessToken')) {
				console.log(error.response);
				return handleRenewAccessToken().then(() => {
					// Applying the new access tokens here
					applyLoginHeaders(requestOptions);
					return handleRequest(axios(requestOptions), requestOptions);
				});
			}
			throw error;
		})
		.then(response => {
			return response.status ? response.data : response;
		});
}

/**
 * Sends the request to the given url with the given payload and returns a promise
 * @param {string} url Url endpoint
 * @param {string} method Request type
 * @param {object} data Data/Params object
 * @returns Promise containing the request
 */
export default function request(url, data = {}, method = 'GET', options = {}) {
	const backendUrl = getBackendUrl();
	url = url.substr(0, 1) !== '/' ? url : `${backendUrl}/api${url}`;
	const requestOptions = {
		method,
		url,
		...options
	};
	applyLoginHeaders(requestOptions);
	if (method.toLowerCase() === 'get') {
		requestOptions.params = data;
	} else {
		requestOptions.data = data;
	}
	return handleRequest(axios(requestOptions), requestOptions);
}

/**
 * Makes a GET request and returns a promise
 * @param {string} url Endpoint url
 * @param {object} params Query params
 * @returns Result Promise
 */
export function get(url, params) {
	return request(url, params, 'GET');
}

/**
 * Makes a POST request and returns a promise
 * @param {string} url Endpoint url
 * @param {object} data Payload data
 * @param {object} options Additional options
 * @returns Response promise
 */
export function post(url, data, options = {}) {
	return request(url, data, 'POST', options);
}

/**
 * Given the endpoint url and the data to update, we send a PUT request to update
 * @param {string} url Endpoint URL
 * @param {object} data Payload data to update
 * @returns Response promise
 */
export function put(url, data) {
	return request(url, data, 'PUT');
}

/**
 * Delete the given resource
 * @param {string} url Endpoint URL
 * @returns Response promise
 */
export function del(url) {
	return request(url, {}, 'DELETE');
}

export function patch(url, data) {
	return request(url, data, 'PATCH');
}
