import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Form from 'root/baseComponents/Form/';
import TextBox from 'root/baseComponents/TextBox';
import FormSelect from 'root/baseComponents/FormSelect';
import Button from 'root/baseComponents/Button/';

import {
	retrieveAllCharitiesGeneral,
	registerCharityAdminWithSignUpForm
} from 'root/services/charity';

import { setModalProps } from 'root/reducers/charityReducers/modalProps';
import { setModalType } from 'root/reducers/charityReducers/modalType';

import {
	firstNameValidators,
	lastNameValidators,
	emailValidators
} from '../validators';

const CHARITY_TYPE_CHOICES = [
	{ value: 'charity1', shownText: 'Charity 1' },
	{ value: 'charity2', shownText: 'Charity 2' }
];

const SignupForm = () => {
	const dispatch = useDispatch();

	const [firstName, setFirstName] = useState('');
	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');

	const [lastName, setLastName] = useState('');
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');

	const [email, setEmail] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	const [charity, setCharity] = useState('');
	const [charityErrorMessage, setCharityErrorMessage] = useState('');

	const [charityOptions, setCharityOptions] = useState([]);

	const toBeValidatedFields = [
		{
			input_name: 'firstName',
			validators: firstNameValidators,
			errorMessageHandler: setFirstNameErrorMessage
		},
		{
			input_name: 'lastName',
			validators: lastNameValidators,
			errorMessageHandler: setLastNameErrorMessage
		},
		{
			input_name: 'email',
			validators: emailValidators,
			errorMessageHandler: setEmailErrorMessage
		}
	];

	const handleSubmit = async () => {
		const data = {
			first_name: firstName,
			last_name: lastName,
			email: email.toLowerCase(),
			charity_id: charity
		};
		const res = await registerCharityAdminWithSignUpForm(data);
		dispatch(setModalType('charity-admin-signup'));
		dispatch(
			setModalProps({
				text: 'Thank you for signing up! You can expect to receive a confirmation from us within the next 24 hours.',
				header: 'Your information submitted successfuly!'
			})
		);
	};

	const retrieveAllCharitiesData = async () => {
		const res = await retrieveAllCharitiesGeneral();
		const localCharityOptions = [];
		res?.forEach(c => {
			localCharityOptions.push({ value: c.id, shownText: c.name });
		});
		setCharityOptions(localCharityOptions);
	};

	useEffect(() => {
		retrieveAllCharitiesData();
	}, []);

	return (
		<>
			<Form toBeValidatedFields={toBeValidatedFields} onSubmit={handleSubmit}>
				<TextBox
					isRequired
					labelText="First Name"
					type="text"
					val={firstName}
					setVal={setFirstName}
					errorHandler={setFirstNameErrorMessage}
					name="firstName"
					errorMessage={firstNameErrorMessage}
					inputClassName="w-px-100"
				/>
				<TextBox
					isRequired
					labelText="Last Name"
					type="text"
					val={lastName}
					setVal={setLastName}
					errorHandler={setLastNameErrorMessage}
					name="lastName"
					errorMessage={lastNameErrorMessage}
					inputClassName="w-px-100"
				/>
				<TextBox
					isRequired
					labelText="Email"
					type="text"
					val={email}
					setVal={setEmail}
					errorHandler={setEmailErrorMessage}
					name="email"
					errorMessage={emailErrorMessage}
					inputClassName="w-px-100"
				/>
				<FormSelect
					className={cx('mb4')}
					labelText="Charity"
					options={charityOptions}
					fullWidth
					openOptionsDownWard={true}
					isRequired
					val={charity}
					setVal={setCharity}
					errorMessage={charityErrorMessage}
					errorHandler={setCharityErrorMessage}
					itemsCenteralized={false}
				/>
				<Div type="flex" hAlign="center" className="w-per-100">
					<Button type="submit" className={'w-px-150'}>
						Signup
					</Button>
				</Div>
			</Form>
		</>
	);
};

export default SignupForm;
