import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Close from 'root/baseComponents/Close';
import Button from 'root/baseComponents/Button';

import styles from './GetConfirmationModal.module.scss';

const ShowConfirmationModal = ({
	text = 'Are you sure?',
	setShowModal = null,
	onClose = null,
	showOkBtn = true
}) => {
	return (
		<>
			<Div
				className={cx('w-per-100 bgWhite boxShadowType1 pos-rel p2 of-y-auto')}>
				<Close
					onClick={() => {
						onClose();
						setShowModal(false);
					}}
				/>
				<Div className="mt2">{text}</Div>
				{showOkBtn && (
					<Div className="mt2">
						<Button
							colorType="transparent-warning"
							className="w-px-100"
							onClick={() => {
								onClose();
								setShowModal(false);
							}}>
							OK
						</Button>
					</Div>
				)}
			</Div>
		</>
	);
};

export default ShowConfirmationModal;
