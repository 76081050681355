import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';
import { Icon } from 'troop-design';

import styles from './Close.module.scss';

const Close = ({ className, ...props }) => {
	return (
		<>
			<Div
				type="flex"
				hAlign="center"
				vAlign="center"
				className={cx(
					'w-px-15 height-px-15 br-rad-px-5 pos-abs pos-abs--rt mouse-hand br-all-solid-1 br-color-black',
					styles.close,
					className
				)}
				{...props}>
				<Icon icon="close" color="black" width={18} />
			</Div>
		</>
	);
};

export default Close;
