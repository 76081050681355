import { get, post } from './http';
import {
	getImpactReport as getImpactReportRx,
	getImpactReports as getImpactReportsRx,
	getActiveMonths as getActiveMonthsRx
} from 'reducers/impactReports';
export function getImpactReports(businessId, fromDate, toDate) {
	return dispatch =>
		get(`/businesses/${businessId}/reports/`, { fromDate, toDate }).then(data =>
			dispatch(
				getImpactReportsRx({
					data
				})
			)
		);
}

export function getImpactReport(businessId, period) {
	return dispatch =>
		get(`/businesses/${businessId}/reports/`, { period }).then(data =>
			dispatch(
				getImpactReportRx({
					period,
					data
				})
			)
		);
}

export function getActiveMonths(businessId, fromDate, toDate) {
	return dispatch =>
		get(`/businesses/${businessId}/active_months/`, { fromDate, toDate }).then(
			data =>
				dispatch(
					getActiveMonthsRx({
						data
					})
				)
		);
}

export const getCurrentReport = data => {
	return post(`/get_current_report/`, data);
};

export const getCurrentRevenueReport = data => {
	return post(`/get_current_revenue_report/`, data);
};
