import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import { retrieveSingleNeedForWriter } from 'root/services/needs';

import Wrapper from '../components/Wrapper';

import EditNeedForm from './subs/EditNeedForm';
import styles from './EditNeed.module.scss';

const EditNeed = () => {
	const { id } = useParams();

	const [curNeed, setCurNeed] = useState({});

	const retrieveSingleNeedForWriterData = async () => {
		const res = await retrieveSingleNeedForWriter(id);
		setCurNeed(res);
	};

	useEffect(() => {
		if (id) {
			retrieveSingleNeedForWriterData();
		}
	}, [id]);

	return (
		<>
			<Wrapper pageName="Needs">
				<Div className="w-per-80 max-w-px-500 p2 bgWhite boxShadowType1 ml-auto mr-auto">
					<EditNeedForm needDetails={curNeed} />
				</Div>
			</Wrapper>
		</>
	);
};

export default EditNeed;
