import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div, Row, Column } from 'basedesign-iswad';

import Button from 'root/baseComponents/Button';

import { retrieveFeaturedNeed } from 'root/services/needs';
import { getBackendUrl } from 'root/utils/helpers';

import InfoPhoto from 'root/assets/needs-info.png';

import styles from '../Needs.module.scss';

const Info = () => {
	const history = useHistory();

	const [featuredNeed, setFeaturedNeed] = useState({});

	const retrieveFeaturedNeedData = async () => {
		try {
			const res = await retrieveFeaturedNeed();
			if (res?.id) {
				setFeaturedNeed(res);
			}
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		retrieveFeaturedNeedData();
	}, []);

	return (
		<>
			<Div className={cx('py4', styles.infoContainer)}>
				<Div className={cx('ml-auto mr-auto', styles.infoContentContainer)}>
					<Row>
						<Column xs={12} sm={12} md={6} lg={6} className="">
							<Div className="w-per-100">
								<Div className="mx4">
									<img src={featuredNeed?.image} className="w-per-100" />
								</Div>
							</Div>
						</Column>
						<Column xs={12} sm={12} md={6} lg={6} className="">
							<Div
								type="flex"
								direction="vertical"
								distributedBetween
								className="w-per-100">
								<Div className="w-per-100 max-w-px-450 px4 ml-auto mr-auto">
									<Div className={cx('textGray text-center fs-px-20')}>
										{featuredNeed?.charity_name}
									</Div>
									<Div className={cx('f-b text-center my2', styles.infoTitle)}>
										{featuredNeed?.short_description}
									</Div>
									<Div className={cx('textGray my2', styles.description)}>
										{featuredNeed?.business_description ||
											featuredNeed?.description}
									</Div>
								</Div>
								<Div className="mb2 px4 w-per-100 max-w-px-450 ml-auto mr-auto">
									<Button
										onClick={() =>
											history.push(
												`/needs/${featuredNeed?.charity_slug}/tr-${featuredNeed?.id}`
											)
										}>
										Read More
									</Button>
								</Div>
							</Div>
						</Column>
					</Row>
				</Div>
			</Div>
		</>
	);
};

export default Info;
