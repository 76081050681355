import { get, post, put, del } from './http';
import {
	getEmployees as getRx,
	addEmployees as bulkAddRx,
	addEmployee as addRx,
	deleteEmployee as deleteEmployeeRx,
	employeeUploadFailed as employeeUploadFailedRx
} from 'root/reducers/employees';
import { addNotification } from 'root/reducers/notifications';

export const getEmployees = businessId => dispatch => {
	const employeesPath = businessId
		? `/businesses/${businessId}/employees/`
		: '/employees/';
	return get(employeesPath)
		.then(data => {
			dispatch(getRx(data));
			return data;
		})
		.catch(err => {
			console.log('Get Employees failed', err);
		});
};

export const addEmployee = (businessId, data) => dispatch => {
	const req = {
		user: {
			first_name: data.firstname,
			last_name: data.lastname,
			email: data.email,
			groups: ['Business Employee']
		},
		title: data.title,
		phone: data.phone,
		is_primary_contact: data.is_primary_contact === '1',
		is_volunteer: data.is_volunteer === '1',
		status: data.status,
		business: businessId
	};
	return post(`/businesses/${businessId}/employees/`, req).then(resp => {
		dispatch(addRx(resp));
		dispatch(
			addNotification({
				type: 'success',
				message: `Successfully added the employee (${resp?.user.first_name} ${resp?.user.last_name}).`
			})
		);
		return resp;
	});
};

export const addNewEmployee = (businessId, data) => dispatch => {
	const req = {
		first_name: data.firstname,
		last_name: data.lastname,
		email: data.email.toLowerCase(),
		groups: ['Business Employee'],
		title: data.title,
		phone: data.phone,
		business_id: parseInt(businessId),
		is_primary_contact: data.is_primary_contact === '1'
	};
	return post(`/add-new-employee/`, req).then(resp => {
		console.log(resp);
		dispatch(addRx(resp));
		dispatch(
			addNotification({
				type: 'success',
				message: `Successfully added the employee (${resp?.user.first_name} ${resp?.user.last_name}).`
			})
		);
		return resp;
	});
};

export const bulkEmployeeUpload =
	(businessId, data, showNotification = true) =>
	dispatch => {
		const formData = new FormData();
		formData.append('file', data.file);
		return post(`/businesses/${businessId}/bulk_employee_upload/`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then(resp => {
			if (Array.isArray(resp)) {
				dispatch(bulkAddRx(resp));
				showNotification &&
					dispatch(
						addNotification({
							type: 'success',
							message: `Your team is growing! New employees successfully added.`
						})
					);
			} else {
				dispatch(
					addNotification({
						type: 'danger',
						message: `${resp}`
					})
				);
			}
		});
	};

export const newEmployeeListUpload = (
	businessId,
	data,
	showNotification = true
) => {
	const formData = new FormData();
	formData.append('file', data.file);
	return post(`/businesses/${businessId}/new_employee_list_upload/`, formData, {
		headers: { 'Content-Type': 'multipart/form-data' }
	});
};

export const confirmEmployeeList = (businessId, data) => dispatch => {
	return post(
		`/businesses/${businessId}/confirm_new_employee_list/`,
		data
	).then(resp => {
		dispatch(
			addNotification({
				type: 'success',
				message: `Your team is growing! New employees successfully added.`
			})
		);
	});
};

export const addEmployeeAndUpdateVote = (businessId, data) => dispatch => {
	return post(`/businesses/${businessId}/confirm_unknown_employee_votes/`, data)
		.then(resp => {
			dispatch(
				addNotification({
					type: 'success',
					message: 'Successfully added employee and counted their vote'
				})
			);
		})
		.catch(error => {
			dispatch(
				addNotification({
					type: 'danger',
					message: 'Failed to add employee and update vote'
				})
			);
		});
};

export const updateEmployeeVoteToRealTime = () => dispatch => {
	return post(`/run_employee_vote_cron/`).then(resp => {
		window.location.reload();
	});
};

export const updateEmployee = (businessId, employeeId, data) => dispatch => {
	return put(`/businesses/${businessId}/employees/${employeeId}/`, data);
};

export const deleteEmployee = (businessId, employeeId) => dispatch => {
	return del(`/businesses/${businessId}/employees/${employeeId}`).then(data =>
		dispatch(deleteEmployeeRx(employeeId))
	);
};

export const searchEmployees = (businessId, keyword) => dispatch => {
	return get(`/businesses/${businessId}/employees`, { keyword });
};

export const createEmployeeCheckout = data => {
	return post(`/customer-payment-intent/`, data);
};

export const retrieveAllSavings = () => {
	return get(`/retrieve-all-savings/`);
};

export const retrieveAllEmployeesInfo = (business_id, data) => {
	return post(`/employees-info/${business_id}/`, data);
};

export const updateEmployeeInfo = (business_user_id, data) => {
	return put(`/update-employee-info/${business_user_id}/`, data);
};

export const deleteEmployeeInfo = business_user_id => {
	return del(`/update-employee-info/${business_user_id}/`);
};
