import React from 'react';

import EmployeeCheckoutComponent from 'components/EmployeeCheckout';

const EmployeeCheckout = () => {
	return (
		<>
			<EmployeeCheckoutComponent />
		</>
	);
};

export default EmployeeCheckout;
