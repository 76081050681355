import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';
import cx from 'classnames';

import Icon from 'root/baseComponents/Icon';
import Close from 'root/baseComponents/Close';
import Button from 'root/baseComponents/Button';
import { clearModalType } from 'root/reducers/charityReducers/modalType';
import { clearModal } from 'root/utils/modal';

import styles from '../Modal.module.scss';

function SimpleModal() {
	const dispatch = useDispatch();
	const modalType = useSelector(state => state.modalType);
	const header = useSelector(state => state.modalProps.header);
	const text = useSelector(state => state.modalProps.text);
	const anchorTo = useSelector(state => state.modalProps.anchorTo) || '';

	const [btnColor, setBtnColor] = useState('success');
	const [iconType, setIconType] = useState('success');

	const setColor = modalType => {
		if (modalType === 'success' || modalType === 'submitNeed') {
			setIconType('circle-check');
			setBtnColor('transparent-success');
		} else if (modalType === 'danger') {
			setIconType('circle-xmark');
			setBtnColor('transparent-danger');
		} else if (modalType === 'warning') {
			setIconType('circle-exclamation');
			setBtnColor('transparent-warning');
		} else if (modalType === 'info') {
			setIconType('circle-info');
			setBtnColor('transparent-info');
		}
	};

	useEffect(() => {
		setColor(modalType);
	}, [modalType]);

	return (
		<>
			<Div
				className={cx(
					'w-per-100 height-vh-full pos-fix pos-fix--lt',
					styles.modalContainer
				)}>
				<Div
					className={cx(
						'pos-fix pos-fix--center br-rad-px-5 card w-per-90 max-w-px-500',
						styles.heading
					)}>
					<Div
						className={cx(
							'p1',
							modalType === 'success' && styles.success,
							modalType === 'warning' && styles.warning,
							modalType === 'info' && styles.info,
							modalType === 'danger' && styles.danger
						)}>
						<Div type="flex" vAlign="center">
							<Icon type={iconType} color="white" scale={1.5} />
							<Div className="textWhite f-b pl2 ">{header}</Div>
						</Div>

						<Close onClick={() => dispatch(clearModalType())} />
					</Div>

					<Div className="pt1 pl3 pr3 pb2 fs-px-14">
						{anchorTo?.length <= 0 ? (
							text
						) : (
							<a href={anchorTo} target="_blank">
								{text}
							</a>
						)}
					</Div>
					<Div type="flex" hAlign="center" className="pb1">
						<Button colorType={btnColor} onClick={() => clearModal(dispatch)}>
							Got it!
						</Button>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default SimpleModal;
