import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Wrapper from '../components/Wrapper';

import AddNeedForm from './subs/AddNeedForm';
import styles from './AddNeed.module.scss';

const AddNeed = () => {
	return (
		<>
			<Wrapper pageName="Needs">
				<Div className="p2 bgWhite w-per-80 max-w-px-500 boxShadowType1 ml-auto mr-auto">
					<AddNeedForm />
				</Div>
			</Wrapper>
		</>
	);
};

export default AddNeed;
