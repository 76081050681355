import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import DesktopNav from './subs/DesktopNav';
import MobileHeader from './subs/MobileHeader';
import styles from './Header.module.scss';

const Header = () => {
	return (
		<>
			<Div showIn={['lg']}>
				<DesktopNav />
			</Div>

			<Div showIn={['xs', 'sm', 'md']}>
				<MobileHeader />
			</Div>
		</>
	);
};

export default Header;
