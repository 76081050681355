import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Heading, Table, Anchor } from 'troop-design';

import { getEmployeePledgeTransactions } from 'root/services/user';

export default function PledgeHistory({ businessUserId }) {
	dayjs.extend(isSameOrBefore);

	const myProfile = useSelector(state => state.users.businessUserProfile);

	const [pledgeTransactions, setPledgeTransactions] = useState([]);

	const columns = useMemo(
		() => [
			{
				Header: 'Date',
				accessor: 'date',
				filter: 'equals'
			},
			{
				Header: 'Charity',
				accessor: 'charity',
				filter: 'equals'
			},
			{
				Header: 'Amount',
				accessor: 'amount',
				filter: 'equals'
			},
			{
				Header: 'Status',
				accessor: 'status',
				filter: 'equals'
			},
			{
				Header: 'Receipt',
				accessor: 'receipt',
				filter: 'equals'
			}
		],
		[]
	);

	const data = useMemo(() => {
		const pledge = pledgeTransactions.map(transaction => {
			return {
				date: dayjs(transaction.transaction_date),
				charity: transaction.charity,
				amount: '$' + transaction.amount.toFixed(2),
				status: dayjs().isSameOrBefore(transaction.pledge_close_date)
					? 'Pledge in progress'
					: transaction.hit_target_by_pledge
					? `Need Fulfilled ${dayjs(transaction.pledge_close_date)
							.add(1, 'day')
							.format('MMM DD YYYY')}`
					: 'Returned to Funds',
				receipt: '-'
			};
		});
		const combinedStripePledge = [...pledge].sort((a, b) => {
			return dayjs(a.date).isAfter(b.date) ? -1 : 1;
		});
		return combinedStripePledge.map(transaction => {
			return {
				...transaction,
				date: dayjs(transaction.date).format('MMM DD, YYYY')
			};
		});
	}, [pledgeTransactions]);

	useEffect(() => {
		if (myProfile?.id) {
			getEmployeePledgeTransactions(myProfile.id).then(data => {
				if (Array.isArray(data)) {
					setPledgeTransactions(data);
				}
			});
		}
	}, [myProfile]);

	return (
		<>
			<div className="employeeHeading">Transaction History</div>
			<Table
				paginate
				itemsPerPage={20}
				data={data}
				columns={columns}
				striped={false}
				hover
			/>
		</>
	);
}
