import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { Div, Image } from 'basedesign-iswad';

import { setSelectedGeeneesWish } from 'root/reducers/selectedGeeneesWish';

import Button from 'root/baseComponents/Button';

import styles from '../GeneesProduct.module.scss';

function NeedCard({ wish, aboutNeed, key, organization }) {
	const dispatch = useDispatch();

	return (
		<>
			<Div
				type="flex"
				vAlign="center"
				key={key}
				className="w-per-100 card p1 my1 height-px-100">
				<Div className="w-px-80 mr1" type="flex" vAlign="center">
					<Image src={wish?.image} />
				</Div>
				<Div className="w-per-100">
					<Div className="fs-px-12 twoLine f-b textFaded mb1">
						{wish?.title}
					</Div>
					{/* <Div
						onClick={() =>
							dispatch(setSelectedGeeneesWish({ ...wish, aboutNeed }))
						}
						className="fs-px-12 textInfo text-underline mouse-hand">
						Link to item
					</Div> */}
				</Div>

				<Div
					type="flex"
					hAlign="center"
					vAlign="start"
					direction="vertical"
					className="w-px-100">
					<Div className="fs-px-12 f-b mb2 textFaded mb1">
						${wish?.price?.item_price}
					</Div>
					<Div>
						<Button
							btnType={2}
							className="w-px-70 fs-px-10 height-px-20 mouse-hand"
							onClick={() =>
								dispatch(setSelectedGeeneesWish({ ...wish, aboutNeed, organization }))
							}>
							Fulfill
						</Button>
					</Div>
				</Div>
			</Div>
		</>
	);
}

export default NeedCard;
