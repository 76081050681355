import EmployeeHome from './EmployeeHome/EmployeeHome';
import EmployeeReports from './EmployeeReports/EmployeeReports';
import Reports from './Reports/Reports';
import EmployeeCheckout from './EmployeeCheckout';
import EmployeeFAQ from './EmployeeFAQ';
import EmployeeProfile from './EmployeeProfile';
import NeedsProfiles from './NeedsProfiles';
import PledgeHistory from './PledgeHistory';
import ActOfKindness from './ActOfKindness';
import TestNewStripe from './TestNewStripe';
import EventSignup from './EventSignup';
import Events from './Events';
import Invite from './Invite';

export const routes = [
	{
		path: '/',
		Component: EmployeeHome,
		isEmployee: true
	},
	// {
	// 	path: '/employee-register',
	// 	Component: EmployeeRegistration,
	// 	isPrivate: false,
	// 	showNav: false
	// },
	// {
	// 	path: '/vote/:id/:businessKey/:date',
	// 	Component: VoteLandingPage,
	// 	showNav: false
	// },
	{
		path: '/employee-reports/:businessId/:businessKey',
		Component: Reports,
		isEmployee: true,
		showNav: true
	},
	{
		path: '/employee-checkout',
		Component: EmployeeCheckout,
		isEmployee: true
	},
	{
		path: '/employee-faq',
		Component: EmployeeFAQ,
		isEmployee: true
	},
	{
		path: '/employee-profile',
		Component: EmployeeProfile,
		isEmployee: true
	},
	{
		path: '/needs-profiles',
		Component: NeedsProfiles,
		isEmployee: true
	},
	{
		path: '/pledge-history',
		Component: PledgeHistory,
		isEmployee: true
	},
	{
		path: '/act-of-kindness',
		Component: ActOfKindness,
		isEmployee: true
	},
	{
		path: '/stripe-checkout-page',
		Component: TestNewStripe,
		isEmployee: true
	},
	{
		path: '/sign-up-in-event',
		Component: EventSignup,
		isEmployee: true
	},
	{
		path: '/events',
		Component: Events,
		isEmployee: true
	},
	{
		path: '/employee/invite',
		Component: Invite,
		isEmployee: true
	}
];
