import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

import { Form, Input, Button } from 'troop-design';

import styles from '../AddNewEmployee.module.scss';

import { getEnv } from 'utils/helpers';
import { addEmployee } from 'services/employees';
import { checkIsHuman } from 'services/user';
import { showModal } from 'reducers/modals';

import Modal from './Prompt';

function AddEmployeeForm({ businessId, setEmail }) {
	const dispatch = useDispatch();
	const SITE_KEY =
		getEnv('RECAPTCHA_SITE_KEY') || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI';
	const [isHuman, setIsHuman] = useState(false);

	const submitHandler = useCallback(async e => {
		const submittedData = {
			firstname: e.first_name,
			lastname: e.last_name,
			email: e.email.toLowerCase(),
			title: '',
			phone: '',
			is_volunteer: false,
			is_primary_contact: false,
			status: 'ACTIVE'
		};
		if (isHuman) {
			setEmail(e.email.toLowerCase());
			const res = await dispatch(addEmployee(businessId, submittedData));
			res?.id && dispatch(showModal('addEmployee'));
		}
	});

	const recaptchaChangeHandler = async token => {
		const res = await checkIsHuman({ token: token });
		const is_human = JSON.parse(res)?.success || false;
		setIsHuman(is_human);
	};

	return (
		<div className={styles.formContainer}>
			<Form onSubmit={submitHandler}>
				<Input
					className={styles.input}
					label="First Name"
					name="first_name"
					type="text"
					validationRules={[
						{
							rule: 'string.isRequired',
							message: 'Enter your first name'
						}
					]}
				/>
				<Input
					label="Last Name"
					name="last_name"
					type="las_name"
					validationRules={[
						{
							rule: 'string.isRequired',
							message: 'Enter your last name'
						}
					]}
				/>
				<Input
					label="Email"
					name="email"
					type="email"
					validationRules={[
						{
							rule: 'string.isRequired',
							message: 'Enter your email'
						}
					]}
				/>
				<div className={styles.recaptcha}>
					<ReCAPTCHA sitekey={SITE_KEY} onChange={recaptchaChangeHandler} />
				</div>
				<Button
					type="submit"
					variant="primary"
					className={styles.submit}
					onSubmit={submitHandler}
					disabled={!isHuman}>
					Sign up
				</Button>
			</Form>
		</div>
	);
}

AddEmployeeForm.propTypes = {
	businessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	setFormSubmitted: PropTypes.func
};

export default AddEmployeeForm;
