import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Div, Card, Column, Row, Image } from 'basedesign-iswad';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import Button from 'root/baseComponents/Button';
import styles from './NeedCard.module.scss';
import Icon from 'root/baseComponents/Icon';
import Tooltip from 'root/baseComponents/Tooltip';

import { getBackendUrl } from 'root/utils/helpers';

import { updateNeedRegisteredByCharity } from 'root/services/charity';

const VerticalNeedCard = ({
	setConfirmModalText,
	showConfirmModal,
	setShowConfirmModal,
	setAction,
	status,
	need,
	...props
}) => {
	const history = useHistory();

	const [photo, setPhoto] = useState('');
	const [name, setName] = useState('');
	const [value, setValue] = useState('');
	const [businessDescription, setBusinessDescription] = useState('');
	const [shortDescription, setShortDescription] = useState('');
	const [needId, setNeedId] = useState('');

	const tagRepresentative = (need, tag) => {
		if (need?.is_fulfilled) {
			return ['Fulfilled', 'Fulfilled'];
		}
		if (tag === 'Live') {
			return ['Active', 'Open'];
		}
		if (tag === 'in_review') {
			return ['In Review', 'InReview'];
		}
		if (tag === 'approved') {
			return ['Approved', 'Approved'];
		}
		if (tag === 'Waiting to be fulfilled') {
			return ['Waiting for Impact', 'WaitingForFeedback'];
		}
		if (tag === 'Under vote in the current month') {
			return ['Under Vote', 'Active'];
		}
		if (tag === 'Fulfilled') {
			return ['Fulfilled', 'Fulfilled'];
		}
		if (tag === 'Closed' || tag === 'Paused') {
			return ['Paused', 'Paused'];
		}
		return tag;
	};

	useEffect(() => {
		if (
			status !== 'in_review' &&
			status !== 'Waiting to be fulfilled' &&
			status !== 'Under vote in the current month'
		) {
			setPhoto(need?.image);
			setNeedId(need?.id);
			setName(need?.name);
			setShortDescription(need?.short_description);
			setBusinessDescription(need?.business_description);
			if (need?.contribution_value) {
				setValue(need.contribution_value);
			} else {
				setValue(need?.value);
			}
		} else if (
			status === 'Waiting to be fulfilled' ||
			status === 'Under vote in the current month'
		) {
			setPhoto(need?.need?.image);
			setNeedId(need?.need?.id);
			setName(need?.need?.name);
			setShortDescription(need?.short_description);
			setBusinessDescription(need?.business_description);
			if (need?.need?.contribution_value) {
				setValue(need.need.contribution_value);
			} else {
				setValue(need?.need?.value);
			}
		} else {
			setPhoto(`${getBackendUrl()}${need?.cover_photo}`);
			setNeedId(need?.id);
			setName(need?.title);
			setValue(need?.monetary_value);
		}
	}, [status, need]);

	return (
		<>
			<Card
				{...props}
				className={cx(
					'boxShadowType1 bgWhite card br-rad-px-5 w-px-250 pb1 height-px-300',
					styles.verticalNeedCard
				)}>
				<Div
					type="flex"
					direction="vertical"
					distributedBetween
					className="height-px-300">
					<Div>
						<Div
							className={cx(
								'of-hidden mouse-hand br-top-hidden-1 br-rad-px-5 pos-rel',
								styles.imageContainer
							)}
							onClick={() => {
								if (status !== 'in_review') {
									history.push(`/individual_need/${needId}`);
								} else if (status === 'in_review') {
									history.push(`/individual_new_need/${needId}`);
								}
							}}>
							<Div
								className={cx(
									'pl1 pr1 br-all-hidden-1 br-rad-px-10 mr1 pos-abs mt1 ml1 fs-px-12',
									styles[`tag${tagRepresentative(need, status)?.[1]}`]
								)}>
								{tagRepresentative(need, status)?.[0]}
							</Div>
							<Image src={photo} className={cx('w-per-100', styles.newImage)} />
						</Div>
						<Div
							type="flex"
							direction="vertical"
							vAlign="center"
							className="pt1 pr2 pl2 text-center fs-px-14">
							<Div
								className={cx(
									'f-b mb1 oneLine text-underline mouse-hand',
									styles.link
								)}
								onClick={() => {
									if (status !== 'in_review') {
										history.push(`/individual_need/${needId}`);
									} else if (status === 'in_review') {
										history.push(`/individual_new_need/${needId}`);
									}
								}}>
								{name}
							</Div>
							{need?.cur_status === 'Fulfilled' && (
								<Div className="mb2"> Donation: ${value} </Div>
							)}
							{need?.cur_status !== 'Fulfilled' && (
								<Div className="mb2"> Target: ${value} </Div>
							)}

							{status === 'Waiting to be fulfilled' && (
								<Div
									type="flex"
									hAlign="center"
									vAlign="center"
									className="w-per-100">
									<Button
										isSmall
										className=""
										onClick={() => history.push(`/send-feedback/${needId}`)}>
										Send Thanks!
									</Button>
								</Div>
							)}
						</Div>
					</Div>
					<Div
						type="flex"
						direction="vertical"
						hAlign="end"
						className="w-per-100 textGray fs-px-12 px1 mt1">
						{/* {need?.created_by && (
							<Div className="oneLine">Written by: {need.created_by}</Div>
						)} */}

						<Div type="flex" hAlign="end" className="w-per-100">
							<Div>
								{' '}
								Submitted: {dayjs(need.created_at).format('MMM DD, YY')}
							</Div>
						</Div>

						<Div
							type="flex"
							vAlign="start"
							distributedBetween
							className="w-per-100">
							<Div type="flex" vAlign="center" className="w-px-70">
								{status === 'in_review' && (
									<>
										<Tooltip text="Delete" width={80}>
											<Div
												colorType="transparent"
												isSmall
												onClick={() => {
													setConfirmModalText(
														`Are you sure you want to delete this need?`
													);
													setAction({
														action: 'delete-registered-by-charity',
														needId
													});
													setShowConfirmModal(true);
												}}
												className="mouse-hand">
												<Icon type="delete" color="#f5891f" scale={1.5} />
											</Div>
										</Tooltip>
										<Tooltip text="Copy" width={80}>
											<Div
												colorType="transparent"
												isSmall
												onClick={() => {
													history.push({
														pathname: '/new-need',
														state: {
															title: need?.title,
															value: need?.monetary_value,
															message: need?.message,
															need_owner: need?.need_owner,
															pageTitle: `${need?.title} - duplicate`
														}
													});
												}}
												className="mouse-hand ml1">
												<Icon type="copy" color="#f5891f" scale={1.5} />
											</Div>
										</Tooltip>
									</>
								)}
								{status === 'Paused' && (
									<>
										<Tooltip text="Delete" width={80}>
											<Div
												type="flex"
												colorType="transparent"
												isSmall
												className="mouse-hand"
												onClick={() => {
													setConfirmModalText(
														`Are you sure you want to delete this need?`
													);
													setAction({
														action: 'delete',
														needId
													});
													setShowConfirmModal(true);
												}}>
												<Icon type="delete" color="#f5891f" scale={1.5} />
											</Div>
										</Tooltip>
										<Tooltip text="Copy" width={80}>
											<Div
												colorType="transparent"
												isSmall
												onClick={() => {
													history.push({
														pathname: '/new-need',
														state: {
															title: need?.name,
															value: need?.value,
															message:
																need?.business_description || need?.description,
															pageTitle: `${need?.name} - duplicate`
														}
													});
												}}
												className="mouse-hand ml1">
												<Icon type="copy" color="#f5891f" scale={1.5} />
											</Div>
										</Tooltip>
									</>
								)}
								{status === 'Paused' && (
									<Tooltip text="Reactivate" width={80}>
										<Div type="flex" className="mouse-hand ml1 w-px-20">
											<Div
												// isSmall
												className="w-px-100 z-100"
												onClick={() => {
													setConfirmModalText(
														`Are you sure you want to reactivate this need?`
													);
													setAction({
														action: 'reactivate',
														needId
													});
													setShowConfirmModal(true);
												}}>
												<Icon type="refresh" color="#f5891f" scale={1.5} />
											</Div>
										</Div>
									</Tooltip>
								)}

								{(status === 'Draft' && !need?.is_fulfilled) ||
								(status === 'Live' && !need?.is_fulfilled) ? (
									<>
										<Tooltip text="Copy" width={80}>
											<Div
												colorType="transparent"
												isSmall
												onClick={() => {
													history.push({
														pathname: '/new-need',
														state: {
															title: need?.name,
															value: need?.value,
															message:
																need?.business_description || need?.description,
															pageTitle: `${need?.name} - duplicate`
														}
													});
												}}
												className="mouse-hand ml1">
												<Icon type="copy" color="#f5891f" scale={1.5} />
											</Div>
										</Tooltip>
									</>
								) : (
									''
								)}

								{(status === 'Waiting to be fulfilled' &&
									!need?.is_fulfilled) ||
								(status === 'Under vote in the current month' &&
									!need?.is_fulfilled) ? (
									<>
										<Tooltip text="Copy" width={80}>
											<Div
												colorType="transparent"
												isSmall
												onClick={() => {
													history.push({
														pathname: '/new-need',
														state: {
															title: need?.need?.name,
															value: need?.need?.value,
															message:
																need?.need?.business_description ||
																need?.need?.description,
															pageTitle: `${need?.need?.name} - duplicate`
														}
													});
												}}
												className="mouse-hand ml1">
												<Icon type="copy" color="#f5891f" scale={1.5} />
											</Div>
										</Tooltip>
									</>
								) : (
									''
								)}

								{need?.is_fulfilled ? (
									<>
										<Tooltip text="Copy" width={80}>
											<Div
												colorType="transparent"
												isSmall
												onClick={() => {
													history.push({
														pathname: '/new-need',
														state: {
															title: need?.name,
															value: need?.value,
															message:
																need?.business_description || need?.description,
															pageTitle: `${need?.name} - duplicate`
														}
													});
												}}
												className="mouse-hand ml1">
												<Icon type="copy" color="#f5891f" scale={1.5} />
											</Div>
										</Tooltip>
									</>
								) : (
									''
								)}
							</Div>

							<Div type="flex" hAlign="end" className="w-per-100">
								<Div>
									{' '}
									ID: {status === 'in_review' ? `R${needId}` : needId}{' '}
								</Div>
							</Div>
						</Div>
					</Div>
				</Div>
			</Card>
		</>
	);
};

export default VerticalNeedCard;
