import React, { useState } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import Purchases from './subs/Purchases';
import Savings from './subs/Savings';
import Pledges from './subs/Pledges';
import Votes from './subs/Votes';
import Comments from './subs/Comments';
import ResetForDemo from './subs/ResetForDemo';

function PurchasesAndSavings() {
	const [currentTab, setCurrentTab] = useState('Purchases');
	return (
		<>
			<Div type="flex" hAlign="start" vAlign="center" className="pt2 mb2">
				<Div
					className={cx(
						'p2 mr2 mouse-hand',
						currentTab === 'Purchases' && 'bgSilver'
					)}
					onClick={() => setCurrentTab('Purchases')}>
					Purchases
				</Div>
				<Div
					className={cx(
						'p2 mr2 mouse-hand',
						currentTab === 'Savings' && 'bgSilver'
					)}
					onClick={() => setCurrentTab('Savings')}>
					Savings
				</Div>
				<Div
					className={cx(
						'p2 mr2 mouse-hand',
						currentTab === 'Pledges' && 'bgSilver'
					)}
					onClick={() => setCurrentTab('Pledges')}>
					Pledges
				</Div>
				<Div
					className={cx(
						'p2 mr2 mouse-hand',
						currentTab === 'Votes' && 'bgSilver'
					)}
					onClick={() => setCurrentTab('Votes')}>
					Votes
				</Div>
				<Div
					className={cx(
						'p2 mr2 mouse-hand',
						currentTab === 'Comments' && 'bgSilver'
					)}
					onClick={() => setCurrentTab('Comments')}>
					Comments
				</Div>
				<Div
					className={cx(
						'p2 mr2 mouse-hand',
						currentTab === 'reset-for-demo' && 'bgSilver'
					)}
					onClick={() => setCurrentTab('reset-for-demo')}>
					Reset For Demo
				</Div>
			</Div>
			{currentTab === 'Purchases' && <Purchases />}
			{currentTab === 'Savings' && <Savings />}
			{currentTab === 'Pledges' && <Pledges />}
			{currentTab === 'Votes' && <Votes />}
			{currentTab === 'Comments' && <Comments />}
			{currentTab === 'reset-for-demo' && <ResetForDemo />}
		</>
	);
}

export default PurchasesAndSavings;
