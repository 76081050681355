import React, { useEffect } from 'react';
import cx from 'classnames';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearNotifications } from 'root/reducers/notifications';

import SignUpOrLogin from 'components/SignUpOrLogin';

import styles from './EmployeeRegistration.module.scss';

const EmployeeRegistration = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const urlSearchParams = new URLSearchParams(location.search);
	const email = urlSearchParams.get('email', '');

	useEffect(() => {
		dispatch(clearNotifications());
	}, []);
	return (
		<>
			<SignUpOrLogin
				isRegisterPage={true}
				headingWord="Signup"
				ctaWord="Signup"
				initialEmail={email}
			/>
		</>
	);
};

export default EmployeeRegistration;
