// VENDOR imports
import React, { useState, useMemo, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import { getEventParticipants } from 'root/services/event';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

import { exportBusinessEventParticipant } from 'root/services/event';

import Table from 'root/baseComponents/Table';
import Button from 'root/baseComponents/Button';

// style import
import styles from './SingleEvent.module.scss';

const Events = () => {
	const dispatch = useDispatch();
	const params = useParams();

	const { event_id } = params;

	const [tableData, setTableData] = useState([]);
	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);

	const headLines = [
		{
			value: 'employee_name',
			display: 'Name',
			width: 300,
			hasSearch: true,
			isSortable: true
		},
		{
			value: 'email',
			display: 'Email',
			width: 300,
			hasSearch: true,
			isSortable: true
		}
	];

	const getEventParticipantsData = async event_id => {
		try {
			dispatch(setLoading());
			const res = await getEventParticipants(event_id);
			setTableData([...res]);
			dispatch(setLoadingComplete());
		} catch (err) {
			dispatch(setLoading());
			console.log(err);
			dispatch(setLoadingComplete());
		}
	};

	useEffect(() => {
		if (event_id) {
			getEventParticipantsData(event_id);
		}
	}, [event_id]);

	const data = useMemo(() => {
		if (tableData?.length) {
			let localData = [];
			tableData?.map(item => {
				localData.push({
					employee_name: item.name,
					email: item.email
				});
			});
			return localData;
		} else {
			return [];
		}
	}, [tableData]);

	return (
		<>
			<Div className={cx(styles.main)}>
				<Div className="employeeHeading mb3 mt2">Participants</Div>
				<Div type="flex" hAlign="end" className="w-px-600">
					<Button
						className="mb2"
						onClick={async () => {
							dispatch(setLoading());
							try {
								const res = await exportBusinessEventParticipant({
									event_id
								});
								console.log(res);
								if (res?.success) {
									window.location.replace(res?.file_address);
								}
								dispatch(setLoadingComplete());
							} catch (err) {
								console.log(err);
								dispatch(setLoadingComplete());
							}
						}}>
						{' '}
						Export Data{' '}
					</Button>
				</Div>

				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					tableWidth={'600px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={10}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
};

export default Events;
