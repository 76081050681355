import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Div, Image } from 'basedesign-iswad';
import NeedCard from './NeedCard';
import styles from '../GeneesProduct.module.scss';

import Text from 'root/baseComponents/Text';
import { ACTS_OF_KINDNESS } from './constants';

function WishTypeCard({
	imageSrc,
	numberOfWishes,
	applicant,
	isCharity,
	hasReciept,
	aboutNeed,
	sponsoredBy,
	wishes,
	isOrganization,
	has_reciept,
	key
}) {
	const [items, setItems] = useState([]);

	useEffect(() => {
		if (wishes?.length >= 1) {
			setItems(wishes.slice(0, 1));
		}
	}, [wishes]);

	const showMoreClickHandler = () => {
		let localItems = [];
		const lastIdx = items.length - 1;
		const remainingItems = wishes.slice(lastIdx);
		if (remainingItems?.length >= 3) {
			localItems = [...items, ...remainingItems.slice(0, 3)];
		} else {
			localItems = [...items, ...remainingItems];
		}
		setItems(localItems);
	};

	const showLessClickHandler = () => {
		setItems(wishes.slice(0, 1));
	};

	return (
		<>
			<Div
				type="flex"
				direction="vertical"
				className={cx('bgWhite boxShadowType1 p1 mx1 mb3', styles.card)}
				key={key}>
				<Div type="flex" hAlign="center" className="height-px-200">
					<Image src={imageSrc} />
				</Div>
				<Div className="fs-px-12 text-center mt3">
					{numberOfWishes} Wishes | {has_reciept && 'Tax Reciept |'} {applicant}
				</Div>

				<div className="fs-px-14 min-height-px-150 text-center mt2">
					{/* {aboutNeed}{' '} */}
					<Text summerized_max_length={220}>{aboutNeed}</Text>
					<span>
						{isOrganization ? 'Initiated by' : 'Sponsored by'}{' '}
						<span className="text-underline"> {sponsoredBy} </span>
					</span>
				</div>

				<Div className="text-center mt3 f-b">Our Wishlist</Div>
				<Div>
					{items?.map((wish, idx) => {
						return (
							<NeedCard
								key={idx}
								wish={wish}
								title={wish?.title}
								imageSrc={wish?.image}
								link={wish?.link}
								amount={wish?.price?.item_price}
								aboutNeed={aboutNeed}
								organization={sponsoredBy}
							/>
						);
					})}

					{wishes?.length > 1 ? (
						<Div
							type="flex"
							hAlign="center"
							vAlign="center"
							className="w-per-100 textInfo f-b height-px-20 text-underline">
							{items?.length > 1 && (
								<Div className="mouse-hand mr2" onClick={showLessClickHandler}>
									{'Show Less'}
								</Div>
							)}
							{items?.length < wishes?.length && (
								<Div className="mouse-hand" onClick={showMoreClickHandler}>
									{'Show More'}
								</Div>
							)}
						</Div>
					) : (
						<Div className="w-per-100 textInfo f-b height-px-20 text-underline" />
					)}
				</Div>
			</Div>
		</>
	);
}

export default WishTypeCard;
