import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Table from 'root/baseComponents/Table';
import Icon from 'root/baseComponents/Icon';
import { getBackendUrl } from 'root/utils/helpers';

import { retrieveAllPurchaseReceipts } from 'root/services/geenees';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';

function Purchases() {
	const dispatch = useDispatch();

	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [reciepts, setReciepts] = useState([]);

	const retrieveAllPurchaseReceiptsData = async () => {
		dispatch(setLoading());
		const res = await retrieveAllPurchaseReceipts();
		dispatch(setLoadingComplete());
		const localReceipts = [];
		res.forEach(item => {
			localReceipts.push({
				date: dayjs(item.issued_date).format('DD-MMM-YY'),
				type: item.receipt_type,
				link: item.file_name
					? `${getBackendUrl()}/media/pdfs/${item.file_name}`
					: '',
				amount: item.amount,
				supported_organization: item.supported_organization,
				business: item.business_name,
				employee: item.employee_email
			});
		});
		setReciepts(localReceipts);
	};

	useEffect(() => {
		retrieveAllPurchaseReceiptsData();
	}, []);

	const setRecieptType = value => {
		if (value === 'geenees_payment') {
			return <Div>Payment: Acts of Kindness</Div>;
		} else if (value === 'geenees_no_payment') {
			return <Div>{'Moved Funds: Account ---> Acts of Kindness'}</Div>;
		} else if (value === 'pledge_payment') {
			return <Div>Payment: Pledged Funds</Div>;
		} else if (value === 'pledge_no_payment') {
			return <Div>{'Moved Funds: Account ---> Pledge'}</Div>;
		} else if (value === 'add_fund') {
			return <Div>Payment: Funds Added</Div>;
		} else if (value === 'pledge_approved') {
			return <Div>{'Moved Funds: Pledged ---> Fulfilled Need'}</Div>;
		} else if (value === 'pledge_not_approved') {
			return <Div>{'Moved Funds: Pledged ---> Account'}</Div>;
		}
	};

	const headLines = [
		{
			value: 'date',
			display: 'Date',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'type',
			display: 'Status',
			width: 350,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'amount',
			display: 'Amount',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'charity',
			display: 'Charity',
			width: 400,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'business',
			display: 'Business',
			width: 400,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'employee',
			display: 'Employee',
			width: 400,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'reciept',
			display: 'Reciept',
			width: 150,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		reciepts.forEach(item => {
			tableData.push({
				date: item.date,
				type: { value: item.type, display: setRecieptType(item.type) },
				reciept: {
					value: item.link,
					display: (
						<Div>
							{item?.link ? (
								<a href={item.link} target="_blank">
									<Icon type="pdf" scale={1.5} />
								</a>
							) : (
								'-'
							)}
						</Div>
					)
				},
				amount: {
					value: item.amount || 0,
					display: (
						<Div>
							${item?.amount ? parseFloat(item?.amount)?.toFixed(2) : '-'}
						</Div>
					)
				},
				charity: item?.supported_organization || '-',
				business: item?.business || '-',
				employee: item?.employee || '-'
			});
		});

		return tableData;
	}, [reciepts]);
	return (
		<>
			<Div className="w-per-80 pr2 pl2 flex flex--jc--start ">
				<Table
					headLines={headLines}
					data={data}
					colWidth={400}
					tableWidth={'1000px'}
					isSortable={true}
					isSelectable={false}
					selectedData={selectedData}
					setSelectedData={setSelectedData}
					sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
					rowsPerPage={10}
					currentPage={currentPage}
					setCurrentPage={setCurrentPage}
					showDefaultPagination={true}
					numberOfShownPages={3}
				/>
			</Div>
		</>
	);
}

export default Purchases;
