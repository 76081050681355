import { useSelector } from 'react-redux';
import useLoggedIn from './useLoggedIn';

export default function useBusinessEmployeeLoggedIn() {
	const loggedIn = useLoggedIn();
	const isCharityAdmin = useSelector(state =>
		state.users.currentUserRoles.includes('Charity Admin')
	);
	return loggedIn && isCharityAdmin;
}
