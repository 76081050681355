import React from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';

import GeneesProduct from 'root/components/GeneesProduct';

import styles from './ActOfKindness.module.scss';

function ActOfKindness() {
	return (
		<>
			<GeneesProduct />
		</>
	);
}

export default ActOfKindness;
