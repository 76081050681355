export const NEED_TYPE_CHOICES = [
	{ value: 'Appliences', shownText: 'Appliences' },
	{ value: 'Baby Items', shownText: 'Baby Items' },
	{ value: 'Birthday Gifts', shownText: 'Birthday Gifts' },
	{ value: 'Cleaning Products', shownText: 'Cleaning Products' },
	{ value: 'Electronics', shownText: 'Electronics' },
	{ value: 'Furniture', shownText: 'Furniture' },
	{ value: 'Health care products', shownText: 'Health care products' },
	{ value: 'Holiday gifts', shownText: 'Holiday gifts' },
	{ value: 'Household items', shownText: 'Household items' },
	{ value: 'Meal kits', shownText: 'Meal kits' },
	{ value: 'Food support', shownText: 'Food support' },
	{
		value: 'Personal hygiene products',
		shownText: 'Personal hygiene products'
	},
	{ value: 'School supplies', shownText: 'School supplies' },
	{ value: 'Winter clothing', shownText: 'Winter clothing' },
	{ value: 'Clothing', shownText: 'Clothing' },
	{ value: 'Other', shownText: 'Other' }
];

export const MESSAGE_TEXT_AREA_PLACE_HOLDER = `Story Details (any additional information you can provide that will
help us tell the story about this need to Troop members will help
increase the likelihood of fulfillment).`;
