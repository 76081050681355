import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const generalSlice = createSlice({
	name: 'general',
	initialState,
	reducers: {
		add: (state, action) => {
			state[action.payload.key] = action.payload.value;
		},
		update: (state, action) => {
			state[action.payload.key] = action.payload.value;
		},
		deleteKey: (state, action) => {
			delete state[action.payload.key];
		}
	}
});

export default generalSlice.reducer;
export const { add, update, deleteKey } = generalSlice.actions;
