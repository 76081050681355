import React, { useState, useEffect, useMemo } from 'react';
import cx from 'classnames';
import { Div } from 'basedesign-iswad';
import { Anchor } from 'troop-design';
import dayjs from 'dayjs';

import { retrievePurchaseReceipts } from 'root/services/geenees';
import { CHARITABLE_TAX_RECIEPT } from '../constants';
import Table from 'root/baseComponents/Table';
import Icon from 'root/baseComponents/Icon';
import { getBackendUrl } from 'root/utils/helpers';

import styles from '../EmployeeProfile.module.scss';

const EmployeeReceipts = () => {
	const [selectedData, setSelectedData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [reciepts, setReciepts] = useState([]);

	const retrievePurchaseReceiptsData = async () => {
		const res = await retrievePurchaseReceipts();
		const localReceipts = [];
		res.forEach(item => {
			localReceipts.push({
				date: dayjs(item.issued_date).format('DD-MMM-YY'),
				type: item.receipt_type,
				link: item.file_name
					? `${getBackendUrl()}/media/pdfs/${item.file_name}`
					: '',
				amount: item.amount,
				supported_organization: item.supported_organization
			});
		});
		setReciepts(localReceipts);
	};

	useEffect(() => {
		retrievePurchaseReceiptsData();
	}, []);

	const setRecieptType = value => {
		if (value === 'geenees_payment') {
			return <Div>Payment: Acts of Kindness</Div>;
		} else if (value === 'geenees_no_payment') {
			return <Div>{'Moved Funds: Account ---> Acts of Kindness'}</Div>;
		} else if (value === 'pledge_payment') {
			return <Div>Payment: Pledged Funds</Div>;
		} else if (value === 'pledge_no_payment') {
			return <Div>{'Moved Funds: Account ---> Pledge'}</Div>;
		} else if (value === 'add_fund') {
			return <Div>Payment: Funds Added</Div>;
		} else if (value === 'pledge_approved') {
			return <Div>{'Moved Funds: Pledged ---> Fulfilled Need'}</Div>;
		} else if (value === 'pledge_not_approved') {
			return <Div>{'Moved Funds: Pledged ---> Account'}</Div>;
		}
	};

	const headLines = [
		{
			value: 'date',
			display: 'Date',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'type',
			display: 'Status',
			width: 350,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'amount',
			display: 'Amount',
			width: 150,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'charity',
			display: 'Charity',
			width: 400,
			hasSearch: false,
			isSortable: true
		},
		{
			value: 'reciept',
			display: 'Reciept',
			width: 150,
			hasSearch: false,
			isSortable: true
		}
	];

	const data = useMemo(() => {
		const tableData = [];
		reciepts.forEach(item => {
			console.log(item);
			tableData.push({
				date: item.date,
				type: { value: item.type, display: setRecieptType(item.type) },
				reciept: {
					value: item.link,
					display: (
						<Div>
							{item?.link ? (
								<a href={item.link} target="_blank">
									<Icon type="pdf" scale={1.5} />
								</a>
							) : (
								'-'
							)}
						</Div>
					)
				},
				amount: {
					value: item.amount || 0,
					display: (
						<Div>
							${item?.amount ? parseFloat(item?.amount)?.toFixed(2) : '-'}
						</Div>
					)
				},
				charity: item?.supported_organization || '-'
			});
		});

		return tableData;
	}, [reciepts]);

	return (
		<>
			{CHARITABLE_TAX_RECIEPT.length > 0 && (
				<>
					<Div className="employeeHeading mt3 mb2">Employee Receipts</Div>
					<Div className="w-per-80 pr2 pl2 flex flex--jc--start ">
						<Table
							headLines={headLines}
							data={data}
							colWidth={400}
							tableWidth={'1000px'}
							isSortable={true}
							isSelectable={false}
							selectedData={selectedData}
							setSelectedData={setSelectedData}
							sortIconColors={{ ASC: 'green', DESC: 'red', REG: 'silver' }}
							rowsPerPage={10}
							currentPage={currentPage}
							setCurrentPage={setCurrentPage}
							showDefaultPagination={true}
							numberOfShownPages={3}
						/>
					</Div>
				</>
			)}
		</>
	);
};

export default EmployeeReceipts;
