import React, { useState, useEffect, useMemo, useRef } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Row, Col, Line, ShowMore, Heading } from 'troop-design';
import { Div } from 'basedesign-iswad';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { set } from 'root/utils/storage';

import Curve1 from 'root/assets/png/bell1.png';
import Curve2 from 'root/assets/png/bell2.png';
import Curve3 from 'root/assets/png/bell3.png';
import Curve4 from 'root/assets/png/bell4.png';
import Curve5 from 'root/assets/png/bell5.png';
import Curve6 from 'root/assets/png/bell6.png';
import BadgePhoto from 'root/assets/png/badge.png';
import InfoPhoto from 'root/assets/png/info.png';
import { getBusinesses } from 'services/businesses';
import { getNeeds } from 'services/needs';
import VotingNeed from './components/VotingNeed';
import FulfilledNeedCard from './components/FulfilledNeedCard';
import { getImpactReports } from 'root/services/impactReports';
import { format } from 'root/utils/currency';
import { get } from 'utils/storage';
import { loggedInToVote } from 'root/reducers/isLoggedInToVote';
import StatsCard from './components/StatsCard';

import {
	toggleUserGroups,
	changeToEmployeeOnLanding,
	setWasBusinessAdmin
} from 'root/reducers/users';

import styles from './Home.module.scss';
import PieChart from './components/Piechart';
import CheckBox from 'root/baseComponents/CheckBox/CheckBox';
import SingleCheckBox from 'root/baseComponents/SingleCheckBox/SingleCheckBox';
import BarChart from './components/BarChart';

export default function Home() {
	const history = useHistory();
	dayjs.extend(isSameOrBefore);
	const card1Ref = useRef();
	const card2Ref = useRef();
	const card3Ref = useRef();
	const dispatch = useDispatch();
	const businessNeeds = useSelector(state => state.needs.businessNeeds);
	const needs = useSelector(state => state.needs.needs);
	const business = useSelector(state => state.businesses.businesses);
	const isLoggedInToVote = useSelector(state => state.isLoggedInToVote);
	const historicalReports = useSelector(state => state.impactReports?.reports);
	const [fulfilledNeeds, setFulfilledNeeds] = useState([]);
	const [selected, setSelected] = useState(true);
	const [isParticipationRate, setIsParticipationRate] = useState(true);
	const period = dayjs().startOf('M').format('YYYY-MM');
	const loggedInUser = useSelector(state => state?.users?.loggedInUser);
	const notYetChangedToEmployeeOnLanding = useSelector(
		state => state?.users?.notYetChangedToEmployeeOnLanding
	);

	const changeRole = () => {
		if (loggedInUser && notYetChangedToEmployeeOnLanding) {
			dispatch(changeToEmployeeOnLanding());
			dispatch(setWasBusinessAdmin(true));

			dispatch(
				toggleUserGroups({
					groups: ['Employee']
				})
			);
			set('userRole', 'Employee');
		}
	};

	useEffect(() => {
		if (loggedInUser && notYetChangedToEmployeeOnLanding) {
			changeRole();
		}
	}, [loggedInUser, notYetChangedToEmployeeOnLanding]);

	const getNodes = (equalizerComponent, equalizerElement) => {
		return [card1Ref.current, card2Ref.current, card3Ref.current];
	};

	const needContribution = useMemo(() => {
		return business.length &&
			business?.[0] &&
			business?.[0]?.monthly_contribution
			? business[0].monthly_contribution
			: 0;
	}, [business]);

	const totalEmployees = useMemo(() => {
		return business.length > 0 && business?.[0] && business?.[0]?.employee_count
			? business[0].employee_count
			: 0;
	}, [business]);

	const liveVotePercentage = useMemo(() => {
		if (historicalReports) {
			const report = historicalReports[period];
			if (report) {
				return Math.round(report.total_votes_percentage, 2);
			}
		}
		return null;
	}, [historicalReports, period]);

	const report = useMemo(() => {
		if (historicalReports && historicalReports[period]) {
			return historicalReports[period];
		}
	}, [historicalReports, period]);

	const isVoteClosed = useMemo(() => {
		if (historicalReports && historicalReports[period]) {
			const voteClosingDate = historicalReports[period].vote_closing_date;
			if (dayjs().isSameOrBefore(dayjs(voteClosingDate))) {
				return true;
			}
		}
		return false;
	}, [historicalReports, period]);

	const historicalReportData = useMemo(() => {
		let voteParticipationData = [];
		[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].forEach(value => {
			const report =
				historicalReports[
					dayjs(period).subtract(value, 'month').format('YYYY-MM')
				];
			if (report) {
				voteParticipationData.push({
					x: dayjs(period).subtract(value, 'month').format('MMM'),
					y: Math.round(report.total_votes_percentage, 2)
				});
			}
		});
		return voteParticipationData.reverse();
	}, [historicalReports, period]);

	useEffect(() => {
		dispatch(getBusinesses());
		dispatch(getNeeds());
	}, [dispatch]);

	useEffect(() => {
		if (business.length > 0) {
			const businessId = business[0].id;
			dispatch(getNeeds(businessId));
			dispatch(getImpactReports(businessId));
		}
	}, [business, dispatch]);

	useEffect(() => {
		if (businessNeeds.length > 0) {
			const needList = [];
			businessNeeds.forEach(businessNeed => {
				const need = needs.filter(need => {
					return need.id === businessNeed.need_id;
				});
				if (businessNeed.is_vote_winner === true) {
					needList.push({
						...need[0],
						is_vote_winner: true,
						vote_opened_date: businessNeed.vote_opened_date
					});
				}
			});
			setFulfilledNeeds(needList);
		}
	}, [businessNeeds, needs, setFulfilledNeeds]);

	useEffect(() => {
		if (isLoggedInToVote) {
			history.push('needs-profiles');
			dispatch(changeToEmployeeOnLanding());
			dispatch(setWasBusinessAdmin(true));

			dispatch(
				toggleUserGroups({
					groups: ['Employee']
				})
			);
			set('userRole', 'Employee');
		}
	}, [isLoggedInToVote]);

	useEffect(() => {
		if (get('loginToVote')) {
			dispatch(loggedInToVote());
		}
	}, []);

	return (
		<>
			<Row>
				<Col xs={12} lg={8}>
					<Div className="employeeHeading mb2 mt2" level={3}>
						Current Vote Report
					</Div>
					<Div className="mr2">
						<Div
							type="flex"
							hAlign="center"
							className={cx('w-per-100 mr2', styles.statCardsContainer)}>
							<StatsCard
								title="Monthly Need Contribution"
								amount={format(needContribution, 'en-CA', 'CAD', 0)}
							/>
							<StatsCard title="Active Employees" amount={totalEmployees} />
							<StatsCard
								title="Vote Status"
								amount={
									!isVoteClosed ? 'Closed' : `${liveVotePercentage}% Voted`
								}
							/>
						</Div>
					</Div>
					<Row className="mt-4">
						<Div className="employeeHeading mb2">Participation Rate</Div>
						<Col>
							<Card className="mr2">
								<Line
									data={historicalReportData}
									label="Participation rate"
									yLabel="Percentage (%)"
									xLabel="Month"
									height={480}
									range={[0, 100]}
								/>
							</Card>
						</Col>
					</Row>
					{/* <Div className="employeeHeading mb2 mt4">Employees Report</Div>
					<Div className="mr2">
						<Div
							type="flex"
							hAlign="center"
							className={cx('w-per-100 mr2', styles.statCardsContainer)}>
							<StatsCard title="Kit packing participators" amount={'70%'} />
							<StatsCard title="Personal Donation" amount={'5%'} />
							<StatsCard title="Personal Donation value" amount={'$100'} />
						</Div>
					</Div> */}
				</Col>

				<Col xs={12} lg={4} className={cx(styles.fulfilmentHistoryContainer)}>
					<Div className={cx('pr3 pl2')}>
						<Div className="employeeHeading mb2 mt2">Fulfilment History</Div>
						{isVoteClosed ? (
							report?.needs.map((need, index) => {
								return (
									<VotingNeed
										key={index}
										fulfillDate={need.fulfilled_date || need.vote_opened_date}
										need={need}
										isVotingClosed
									/>
								);
							})
						) : (
							<>
								<ShowMore initialComponentsCount={5} itemsPerPage={3}>
									{fulfilledNeeds.map((need, index) => {
										return (
											need.is_vote_winner && (
												<FulfilledNeedCard
													key={index}
													needHeading={need.name}
													needType={need.need_type}
													fulfillDate={
														need.fulfilled_date || need.vote_opened_date
													}
													communitySupported={need.community_supported || ''}
													isFulfilled={
														need.is_fulfilled &&
														(need.is_vote_winner || need.status === 'INACTIVE')
															? true
															: false
													}
												/>
											)
										);
									})}
								</ShowMore>
							</>
						)}
						{/* <Div className='employeeHeading mb2 mt2'>Proportion of supported communities </Div>			
						<Div
							type="flex"
							direction="vertical"
							hAlign="center"
							className="card w-per-100 br-rad-px-5 mb2 p1">
							<PieChart />
						</Div> */}

						{/* <Div className='card br-rad-px-5 mb2 mt3 pos-rel'>
							<img src={BadgePhoto} className={cx('pos-abs',styles.badge)} width={40}/>
							<Div className='w-per-100 bgInfo p2 fs-px-14 textWhite f-b br-rad-px-5'>
								You are underprivileged youth supporter!
							</Div>
							<Div className='w-per-100 p2 fs-px-14'>
								Your business helps underprivileged youth more than 
								our other partners! So you are their best support!  
							</Div>
						</Div> */}

						{/* <Div className='card br-rad-px-5 of-hidden p2'>
							<Div type='flex' vAlign='center' className='mb1'>
								<img src={InfoPhoto} className={cx()} width={35}/>
								<Div className='textInfo f-b px1'>Updates!</Div>
							</Div>
							<Div className='w-per-100 fs-px-14'>
							you've been with Troop for 12 months, your employee
							vote participation is X, Y% of your employees are
							super engaged (voted for 6+ consecutive months), 
							based on this, Troop has helped you reduce your
							turnover costs by $Z.  
							</Div>
						</Div> */}
					</Div>
				</Col>
			</Row>
			<Row>
				<Div type='flex' className='w-per-100'>
					<Div  className='w-per-50 mx1'>
						<Div className="employeeHeading mb2 mt4">Your Benchmark</Div>
						<Div
							type="flex"
							hAlign="center"
							direction="vertical"
							className="w-per-100 ">
							<Div
								type="flex"
								direction="vertical"
								showIn={['md', 'lg']}
								hAlign="center"
								className="card br-rad-px-5 pt3 pb3 pr1 pl1">
									<CheckBox
										labelText="Participation Rate"
										isRequired={false}
										className="mb1 textFaded  fs-px-14"
										onBoxClick={() => setIsParticipationRate(!isParticipationRate)}
										checked={isParticipationRate}
										distributedBetween={false}
										isRadioButton={true}
									/>
									<CheckBox
										labelText="Charitable Donation"
										isRequired={false}
										className="mb3 textFaded  fs-px-14"
										onBoxClick={() => setIsParticipationRate(!isParticipationRate)}
										checked={!isParticipationRate}
										distributedBetween={false}
										isRadioButton={true}
									/>
									{isParticipationRate ? (
										<>
											<Div>
												<img src={Curve4} width={600} />
											</Div>
											<Div className="textGray fs-px-14 mt1">
												Participation Rate{' '}
											</Div>
											<Div
												type="flex"
												hAlign="start"
												className="w-per-100 mt1 fs-px-14 pl2 pr2 ">
												Your employees participation rate is 80%. It means that your
												employees engaged more than than 70% of our other partners
												employees.
											</Div>
										</>
									) : (
										<>
											<Div>
												<img src={Curve3} width={600} />
											</Div>
											<Div className="textGray fs-px-14 mt1">
												Charitable Donation
											</Div>
											<Div
												type="flex"
												hAlign="start"
												className="w-per-100 mt1 fs-px-14 pl2 pr2 ">
												Your charitable donation amount is 2000$. It means that your
												business donated more than than 40% of our other partners
												employees.
											</Div>
										</>
									)}
								</Div>
					</Div>
					</Div>
					<Div  type='flex' direction='vertical' className='w-per-50 mx1'>
						<Div type="flex" hAlign="start" className="w-per-100">
							<Div className="employeeHeading mt4">
								Your Supported Communities{' '}
							</Div>
						</Div>
						<Div
							type="flex"
							hAlign="center"
							className="card p2 br-rad-px-5 mt2 w-per-90">
							<BarChart />
						</Div>
					</Div>
				</Div>
			</Row>
		</>
	);
}
