export const nav = [
	{
		identifier: 'services',
		title: 'Services',
		hasSubs: true,
		subContainerWidth: '130px',
		subContainerTranslateX: 0
	},
	{
		identifier: 'slack-app',
		title: 'Slack App',
		url: '/slack',
		hasSubs: false,
		toWordpress: true,
		isLocal: true
	},
	{
		identifier: 'about-us',
		title: 'About Us',
		url: '/about',
		hasSubs: false,
		toWordpress: true,
		isLocal: true
	},
	{
		identifier: 'resources',
		title: 'Resources',
		hasSubs: true,
		subContainerWidth: '450px',
		subContainerTranslateX: -50
	},
	{
		identifier: 'login',
		title: 'Login',
		url: '/login',
		hasSubs: false,
		toWordpress: false,
		isLocal: true
	}
];

export const subnav = {
	services: [
		{
			identifier: 'businesses',
			title: 'Businesses',
			url: '/business',
			hasSubs: false,
			toWordpress: true,
			isLocal: true
		},
		{
			identifier: 'local-needs',
			title: 'Local Needs',
			url: '/needs',
			hasSubs: false,
			toWordpress: false,
			isLocal: true
		}
	],
	resources: [
		{
			identifier: 'social-impact-guide-for-small-midsize-businesses',
			title: 'Social Impact Guide for Small-Midsize Businesses',
			url: '/social-impact-guide',
			hasSubs: false,
			toWordpress: true,
			isLocal: true
		},
		{
			identifier: 'referral-program',
			title: 'Referral Program',
			url: '/referral-program',
			hasSubs: false,
			toWordpress: true,
			isLocal: true
		},
		{
			identifier: 'purpose-at-work-newsletter',
			title: 'Purpose at Work Newsletter',
			url: '/newsletter-signup',
			hasSubs: false,
			toWordpress: true,
			isLocal: true
		},
		{
			identifier: 'blog',
			title: 'Blog',
			url: '/blog',
			hasSubs: false,
			toWordpress: true,
			isLocal: true
		}
	]
};
