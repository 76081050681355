import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Div } from 'basedesign-iswad';

import FormDatePicker from 'root/baseComponents/FormDatePicker';

import { getActiveVotes } from 'root/services/needs';

import VoteCard from './subs/VoteCard';

function ViewActiveVotes() {
	const params = useParams();

	const [chosenDate, setChosenDate] = useState(new Date());
	const [chosenDateErrorMessage, setChosenDateErrorMessage] = useState('');

	const businessId = parseInt(params.businessId, 10);

	const [activeVotes, setActiveVotes] = useState([]);

	const getActiveVotesData = async () => {
		try {
			const res = await getActiveVotes(
				businessId,
				dayjs(chosenDate).format('YYYY-MM-DD')
			);
			console.log(res);
			setActiveVotes(res);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		if (businessId) {
			getActiveVotesData();
		}
	}, [businessId, chosenDate]);

	return (
		<>
			<Div
				type="flex"
				direction="vertical"
				hAlign="center"
				className="w-per-100 p2">
				<Div className="w-per-80 max-w-px-300 pr2 pl2">
					<FormDatePicker
						chosenDate={chosenDate}
						setChosenDate={setChosenDate}
						errorMessage={chosenDateErrorMessage}
						errorHandler={setChosenDateErrorMessage}
						labelText="Date:"
						dateFormat="MM-yyyy"
					/>
				</Div>
				{activeVotes?.map((item, idx) => (
					<Div key={idx}>
						<VoteCard
							src={item?.need?.image}
							short_description={item?.need?.short_description}
							value={item?.need?.value}
							open_date={item?.vote_opened_date}
							close_date={item?.vote_close_date}
							mid_vote_reminder_date={item?.mid_vote_reminder_date}
							pledge_close_date={item?.pledge_close_date}
							is_vote_winner={item?.is_vote_winner}
							is_fulfilled={item?.need?.is_fulfilled}
						/>
					</Div>
				))}
			</Div>
		</>
	);
}

export default ViewActiveVotes;
