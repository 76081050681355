import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router';
import { Heading, Row } from 'troop-design';
import useFindBusiness from 'root/hooks/useFindBusiness';
import TellAFriend from './components/TellAFriend';
import ShareAContact from './components/ShareAContact';
import ShareAContactPrompt from './components/ShareAContactPrompt';

export default function ReferAFriend() {
	const params = useParams();
	const id = Number(params.businessId);
	const business = useFindBusiness({ id });
	const user = useSelector(state => state.users.loggedInUser);
	const [formData, setFormData] = useState({});

	return (
		<>
			<Heading level={1}>Refer a friend </Heading>
			<Row>
				<TellAFriend business={business} user={user} />
				<ShareAContact setFormData={setFormData} />
			</Row>
			<ShareAContactPrompt
				business={business}
				user={user}
				formData={formData}
			/>
		</>
	);
}
