import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Div } from 'basedesign-iswad';

import Form from 'root/baseComponents/Form/Form';
import TextBox from 'root/baseComponents/TextBox';
import Button from 'root/baseComponents/Button';

import { updateUserInfo, getCharityUSerInfo } from 'root/services/charity';
import { addAlertItem } from 'root/utils/notifications';

import UpdatePassModal from './UpdatePassModal';
import styles from '../Account.module.scss';

function Profile() {
	const dispatch = useDispatch();

	const [showPassModal, setShowPassModal] = useState(false);

	const [charityUser, setCharityUser] = useState({});

	const [firstName, setFirstName] = useState('');
	const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');

	const [lastName, setLastName] = useState('');
	const [lastNameErrorMessage, setLastNameErrorMessage] = useState('');

	const [phone, setPhone] = useState('');
	const [phoneErrorMessage, setPhoneErrorMessage] = useState('');

	const getUserInfo = async () => {
		try {
			const res = await getCharityUSerInfo();
			setCharityUser(res.data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getUserInfo();
	}, []);

	useEffect(() => {
		if (charityUser?.user) {
			setFirstName(charityUser.user?.first_name);
			setLastName(charityUser?.user?.last_name);
		}
		if (charityUser?.phone) {
			setPhone(charityUser.phone);
		}
	}, [charityUser]);

	const submitHandler = async () => {
		try {
			const res = await updateUserInfo({
				first_name: firstName,
				last_name: lastName,
				phone
			});
			addAlertItem(
				dispatch,
				`Your information has been updated successfully!`,
				'success'
			);
		} catch (err) {
			console.log(err);
			addAlertItem(dispatch, err.response.data.message, 'error');
		}
	};

	return (
		<>
			<Div className={cx('mt6')}>
				<Div className="employeeHeader mb2">Personal Information</Div>
				<Form
					onSubmit={submitHandler}
					className={cx('card pl7 pl7 pt2 pb2', styles.card)}>
					<TextBox
						labelText="Your First Name"
						type="text"
						val={firstName}
						setVal={setFirstName}
						errorHandler={setFirstNameErrorMessage}
						name="first_name"
						errorMessage={firstNameErrorMessage}
					/>
					<TextBox
						labelText="Your Last Name"
						type="text"
						val={lastName}
						setVal={setLastName}
						errorHandler={setLastNameErrorMessage}
						name="organ_address"
						errorMessage={lastNameErrorMessage}
					/>
					<TextBox
						labelText="Your Phone Number"
						type="text"
						val={phone}
						setVal={setPhone}
						errorHandler={setPhoneErrorMessage}
						name="organ_address"
						errorMessage={phoneErrorMessage}
					/>
					<Div type="flex" distributedBetween className="mb2">
						<Button
							onClick={e => {
								e.preventDefault();
								setShowPassModal(true);
							}}
							colorType={'transparent'}
							className="w-px-200">
							Update Password
						</Button>
						<Div>
							<Button className="w-px-200">Submit</Button>
						</Div>
					</Div>
				</Form>
				{showPassModal && (
					<UpdatePassModal setShowPassModal={setShowPassModal} />
				)}
			</Div>
		</>
	);
}

export default Profile;
