import React, { useEffect } from 'react';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { Div } from 'basedesign-iswad';

import Icon from 'root/baseComponents/Icon';
import Tooltip from 'root/baseComponents/Tooltip';
import VolunteerPhoto from 'root/assets/volunteer.png';

import styles from '../Needs.module.scss';

const VolunteerNeedCard = ({ need }) => {
	const history = useHistory();

	useEffect(() => {
		console.log(need);
	}, [need]);

	return (
		<>
			<Div
				type="flex"
				direction="vertical"
				className={cx("w-px-250 height-px-300 bgWhite boxShadowType1 br-rad-px-5 p1",styles.volunteerNeedCard)}>
				<Div
					className={cx(
						'w-per-100 br-rad-px-5 of-hidden mb1',
						styles.volunteerPhotoContainer
					)}>
					<img
						src={VolunteerPhoto}
						className={cx('w-per-100', styles.volunteerPhotoContainer)}
					/>
				</Div>
				<Div
					type="flex"
					direction="vertical"
					distributedBetween
					className={cx('w-per-100', styles.volunteerTextContainer)}>
					<Div
						className={cx("text-underline oneLine w-px-250 px2 f-b my1  mouse-hand fs-px-14",styles.link)}
						onClick={() =>
							history.push({
								pathname: `/volunteer-need-details/${need?.id}`,
								state: { need }
							})
						}>
						{need?.name}
					</Div>
					{/* <Div>
						{need?.address && need.address} {need?.city && `, ${need.city}`}{' '}
						{need?.province && `, ${need.province}`}{' '}
						{need?.postal_code && `, ${need.postal_code}`}
					</Div> */}
					{/* <Div>{need?.start_date && `Start Date: ${need.start_date}`}</Div>
					<Div>{need?.end_date && `End Date: ${need.end_date}`}</Div> */}
					{/* <Div>
						{need?.contact_email && `Contact Email: ${need.contact_email}`}
					</Div> */}
					<Div
						type="flex"
						vAlign="center"
						distributedBetween
						className="w-per-100">
							<Div type="flex" vAlign="center" className="w-px-70">
								<Tooltip text="Delete" width={80}>
									<Div
										type="flex"
										colorType="transparent"
										isSmall
										className="mouse-hand"
										// onClick={() => {
										// 	setConfirmModalText(
										// 		`Are you sure you want to delete this need?`
										// 	);
										// 	setAction({
										// 		action: 'delete',
										// 		needId
										// 	});
										// 	setShowConfirmModal(true);
										// }}
										>
										<Icon type="delete" color="#f5891f" scale={1.2} />
									</Div>
								</Tooltip>
								<Tooltip text="Copy" width={80}>
									<Div
										colorType="transparent"
										isSmall
										// onClick={() => {
										// 	history.push({
										// 		pathname: '/new-need',
										// 		state: {
										// 			title: need?.name,
										// 			value: need?.value,
										// 			message:
										// 				need?.business_description || need?.description,
										// 			pageTitle: `${need?.name} - duplicate`
										// 		}
										// 	});
										// }}
										className="mouse-hand ml1">
										<Icon type="copy" color="#f5891f" scale={1.2} />
									</Div>
								</Tooltip>
								<Tooltip text="Edit" width={80}>
									<Div
										type="flex"
										colorType="transparent"
										isSmall
										className="mouse-hand ml1"
										// onClick={() => {
										// 	setConfirmModalText(
										// 		`Are you sure you want to delete this need?`
										// 	);
										// 	setAction({
										// 		action: 'delete',
										// 		needId
										// 	});
										// 	setShowConfirmModal(true);
										// }}
										>
										<Icon type="edit" color="#f5891f" scale={1.2} />
									</Div>
								</Tooltip>
							</Div>
					<Div type="flex" hAlign="end" vAlign='center' className="w-per-100 fs-px-12">
						<Div className="textGray"> ID: v{need?.id}</Div>
					</Div>
				</Div>
				</Div>
			</Div>
		</>
	);
};

export default VolunteerNeedCard;
