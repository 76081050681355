import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { Div } from 'basedesign-iswad';

import TextBox from 'root/baseComponents/TextBox';
import Form from 'root/baseComponents/Form/Form';
import Button from 'root/baseComponents/Button/Button';
import Modal from 'components/Modal';
import { showModal, hideModal } from 'reducers/modals';
import Close from 'root/baseComponents/Close';
import Icon from 'root/baseComponents/Icon';
import { addPublicParticipantToEvent } from 'root/services/event';
import { setLoading, setLoadingComplete } from 'root/reducers/loaders';
import { get, set, del } from 'utils/storage';

import ShowConfirmationModal from 'root/baseComponents/GetConfirmationModal/ShowConfirmationModal';

import {
	setChosenNeed,
	setVotedFromBusiness
} from 'root/reducers/voteCelebration';
import { notLoggedInToVote } from 'root/reducers/isLoggedInToVote';

import styles from './EventForm.module.scss';

const EventFormForPublic = ({
	useForm = false,
	curName,
	curEmail,
	curBusiness,
	setEmployeeVoted = null,
	chosenNeed
}) => {
	const dispatch = useDispatch();
	const upcomingEventForPublicUser = useSelector(
		state => state.upcomingEventForPublicUser
	);

	const [showSuccessModal, setShowSuccessModal] = useState(false);

	const [name, setName] = useState('');
	const [nameErrorMessage, setNameErrorMessage] = useState('');

	const [email, setEmail] = useState('');
	const [emailErrorMessage, setEmailErrorMessage] = useState('');

	useEffect(() => {
		if (curName) {
			setName(curName);
		}
	}, [curName]);

	useEffect(() => {
		if (curEmail) {
			setEmail(curEmail);
		}
	}, [curEmail]);

	const handleSubmit = async e => {
		e.preventDefault();
		const data = {
			event_id: upcomingEventForPublicUser?.id,
			name,
			email: email.toLowerCase()
		};
		dispatch(setLoading());
		try {
			const res = await addPublicParticipantToEvent(data);
			dispatch(setLoadingComplete());
			setShowSuccessModal(true);
		} catch (err) {
			dispatch(setLoadingComplete());
			console.log(err);
		}
	};

	return (
		<>
			<Modal
				dismissable={false}
				reduxKey="event-form-for-public-user"
				size="md"
				useRegularTheme={false}>
				{!showSuccessModal ? (
					<Div className="pos-rel">
						<Close
							onClick={() => {
								setEmployeeVoted(true);
								dispatch(setVotedFromBusiness(curBusiness));
								dispatch(setChosenNeed(chosenNeed));
								del('loginToVote');
								dispatch(notLoggedInToVote());
								dispatch(hideModal('event-form-for-public-user'));
							}}
						/>
						<Div
							type="flex"
							vAlign="center"
							hAlign="center"
							className="w-per-100 bgPrimary of-hidden f-b fs-px-24 textWhite height-px-50">
							<Div className="">{upcomingEventForPublicUser?.name}</Div>
						</Div>
						<Div
							className={cx(
								'pr4 pl4 of-y-auto scrollType1',
								styles.eventContainer
							)}>
							<Div className="text-center mt3 mb1 f-b fs-px-20">
								Sign Me Up!
							</Div>
							<Div className="fs-px-14 textGray text-center mb2">
								{upcomingEventForPublicUser?.description}
							</Div>
							<Div
								type="flex"
								direction="vertical"
								hAlign="start"
								className="mb2">
								<Div type="flex" vAlign="center" className="mb1">
									<Div>
										<Icon type="date" color="black" />
									</Div>
									<Div className="ml2">{upcomingEventForPublicUser?.date}</Div>
								</Div>
								<Div type="flex" vAlign="center" className="mb1">
									<Div>
										<Icon type="clock" color="black" />
									</Div>
									<Div className="ml2">
										{upcomingEventForPublicUser?.time}{' '}
										{upcomingEventForPublicUser?.end_time &&
											` - ${upcomingEventForPublicUser?.end_time}`}
									</Div>
								</Div>
								<Div type="flex" vAlign="center" className="mb1">
									<Div>
										<Icon type="location" color="black" />
									</Div>
									<Div className="ml2">
										{upcomingEventForPublicUser?.location}
									</Div>
								</Div>
								<Div className="text-center textGray">
									Managed by {upcomingEventForPublicUser?.managed_by}
								</Div>
							</Div>

							<>
								{useForm ? (
									<Form onSubmit={handleSubmit} className="">
										<Div>
											<Div className="w-per-100">
												<TextBox
													labelText="Name"
													type="text"
													val={name}
													setVal={setName}
													errorHandler={setNameErrorMessage}
													name="name"
													errorMessage={nameErrorMessage}
													className={cx('mr2 w-px-100')}
												/>
												<TextBox
													labelText="Email"
													type="text"
													val={email}
													setVal={setEmail}
													errorHandler={setEmailErrorMessage}
													name="email"
													errorMessage={emailErrorMessage}
													className={cx('mr2 w-px-100')}
												/>
											</Div>
										</Div>
										<Div type="flex" hAlign="center" className="my2">
											<Button type="submit" className="w-px-200 mouse-hand">
												Sign Me Up to Help
											</Button>
										</Div>
									</Form>
								) : (
									<Div
										type="flex"
										direction="vertical"
										className="textGray fs-px-14">
										<Div className="my2">Your Name: {name}</Div>
										<Div className="mb2">Your Email: {email}</Div>

										<Div type="flex" hAlign="center" className="my2">
											<Button
												type="submit"
												className="w-px-200 mouse-hand"
												// onClick={async () => {
												// 	const data = {
												// 		event_id: upcomingEventForPublicUser?.id
												// 	};
												// 	dispatch(setLoading());
												// 	try {
												// 		const res = await addParticipantToEvent(data);
												// 		dispatch(setLoadingComplete());
												// 		dispatch(hideModal('event-form-for-public-user'));
												// 	} catch (err) {
												// 		dispatch(setLoadingComplete());
												// 		console.log(err);
												// 	}
												//     }}
											>
												Sign Me Up to Help
											</Button>
										</Div>
									</Div>
								)}
							</>
						</Div>
					</Div>
				) : (
					<ShowConfirmationModal
						text={`${upcomingEventForPublicUser?.managed_by} will be in touch to
											provide more details closer to the date.`}
						setShowModal={setShowSuccessModal}
						onClose={() => {
							setEmployeeVoted(true);
							dispatch(setVotedFromBusiness(curBusiness));
							dispatch(setChosenNeed(chosenNeed));
							del('loginToVote');
							dispatch(notLoggedInToVote());
							dispatch(hideModal('event-form-for-public-user'));
						}}
					/>
				)}
			</Modal>
		</>
	);
};

export default EventFormForPublic;
