import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';
import { Card, Icon } from 'troop-design';
import cx from 'classnames';
import { hideModal } from 'reducers/modals';
import styles from './Modal.module.scss';

export default function Modal({
	reduxKey,
	children,
	size,
	dismissable,
	hasBlackBg = true,
	useRegularTheme = true
}) {
	const [modalState, setModalState] = useState(0); // 0 opening, 1 opened, 2 closing
	const showModal = useSelector(state => state.modals[reduxKey]);
	const transitionTime = 500;

	const dispatch = useDispatch();

	useEffect(() => {
		setTimeout(() => {
			setModalState(1);
		}, transitionTime);
	}, [showModal]);

	const handleClose = e => {
		if (dismissable) {
			setModalState(2);
			dispatch(hideModal(reduxKey));
		}
	};

	const classNameMap = {
		0: 'opening',
		1: 'opened',
		2: 'closed'
	};
	const stateClassName = styles[classNameMap[modalState]];

	return (
		<CSSTransition
			classNames={{
				appear: styles['appear'],
				appearActive: styles['active-appear'],
				appearDone: styles['done-appear'],
				enter: styles['enter'],
				enterActive: styles['active-enter'],
				enterDone: styles['done-enter'],
				exit: styles['exit'],
				exitActive: styles['active-exit'],
				exitDone: styles['done-exit']
			}}
			timeout={transitionTime}
			unmountOnExit
			in={showModal}>
			<div key="modal-contents">
				<div
					className={cx(
						styles.overlay,
						!hasBlackBg && styles.withoutBlackBg,
						stateClassName
					)}
					style={{ transition: `${transitionTime / 1000}s` }}
					onClick={handleClose}
				/>
				{useRegularTheme ? (
					<Card
						className={cx(styles.modalCard, styles[size], stateClassName)}
						key="modal-children"
						style={{ transition: `${transitionTime / 1000}s` }}>
						{dismissable && (
							<button className={cx(styles.closeIcon)} onClick={handleClose}>
								<Icon icon="close" size="lg" />
							</button>
						)}
						{children}
					</Card>
				) : (
					<div
						className={cx(
							'bgWhite boxShadowType1 br-rad-px-5',
							styles.modalCard,
							styles[size],
							stateClassName
						)}
						key="modal-children"
						style={{ transition: `${transitionTime / 1000}s` }}>
						{dismissable && (
							<button className={cx(styles.closeIcon)} onClick={handleClose}>
								<Icon icon="close" size="lg" />
							</button>
						)}
						{children}
					</div>
				)}
			</div>
		</CSSTransition>
	);
}

Modal.defaultProps = {
	dismissable: true,
	size: 'lg'
};

Modal.propTypes = {
	dismissable: PropTypes.bool,
	reduxKey: PropTypes.string.isRequired,
	size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'full'])
};
