export const NEEDS = [
	{
		needName: 'Giving Back with Backpacks',
		imageSrc: 'https://picsum.photos/id/2/300/100',
		target: '$500',
		closeDate: '01/02/2022',
		status: 'waitForFeedback'
	},
	{
		needName: 'Giving Back with Backpacks',
		imageSrc: 'https://picsum.photos/300/100',
		target: '$500',
		closeDate: '01/03/2022',
		status: 'fulfilled'
	},
	{
		needName: 'Giving Back with Backpacks',
		imageSrc: 'https://picsum.photos/300/100',
		target: '$500',
		closeDate: '01/07/2022',
		status: 'notFulfilled'
	},
	{
		needName: 'Giving Back with Backpacks',
		imageSrc: 'https://picsum.photos/300/100',
		target: '$500',
		closeDate: '01/08/2022',
		status: 'review'
	},
	{
		needName: 'Giving Back with Backpacks',
		imageSrc: 'https://picsum.photos/id/5/300/100',
		target: '$300',
		closeDate: '01/02/2022',
		status: 'feedbackSent'
	},
	{
		needName: 'Giving Back with Backpacks',
		imageSrc: 'https://picsum.photos/id/253/300/100',
		target: '$500',
		closeDate: '01/05/2022',
		status: 'processing'
	},
	{
		needName: 'Giving Back with Backpacks',
		imageSrc: 'https://picsum.photos/id/237/300/100',
		target: '$500',
		closeDate: '01/04/2022',
		status: 'active'
	}
];

export const NEED_OWNER_CHOICES = [
	{ value: 'organization', shownText: 'Our Organization' },
	{ value: 'individual', shownText: 'An Individual' }
];
