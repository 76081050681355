import { get, post, put, del } from './http';

export const retrieveBusinessCustomers = business_id => {
	if (business_id) {
		return get(`/business_customer_view/?business_id=${business_id}`);
	}
	return get(`/business_customer_view/`);
};

export const deleteListOfCustomers = data => {
	return post(`/delete-list-of-customers/`, data);
};
