import { get, post } from './http';

export const activateTeamsChannelForBusiness = data => {
	return post(`/teams-app-activate/`, data);
};

export const getTeamsLogs = () => {
	return get(`/teams_logs/`);
};

export const sendImpactReportToTeams = data => {
	return post(`/teams-send-impact-report/`, data);
};
