import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateBusiness } from 'services/businesses';

export default function SucessPayment() {
	const location = useLocation();
	const dispatch = useDispatch();
	const urlSearchParams = new URLSearchParams(location.search);
	const businessId = urlSearchParams.get('business');

	useEffect(() => {
		if (businessId) {
			const formData = new FormData();

			formData.append('business_id', businessId);
			formData.append('payment_made', 'True');

			dispatch(updateBusiness(businessId, formData));
		}
	}, [dispatch, businessId]);

	return <h1>Payment Sucessful</h1>;
}
